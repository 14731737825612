define("api-dashboard/adapters/totalizador-nfe-cnpj", ["exports", "api-dashboard/adapters/application-panel"], function (_exports, _applicationPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationPanel.default.extend({
    findAll: function findAll(store, type, sinceToken, snapshotRecordArray) {
      var self;
      self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ajaxParams;
        ajaxParams = {
          type: "GET"
        };
        return self.get("store").externalAjax(ajaxParams, {
          apiURL: "/panel_api/empresas/estatisticas"
        }, function (sucesso, data, ajaxCallbackParams) {
          var tca, tnc, tne;

          if (sucesso) {
            tne = data["estatisticas"]["notas_emitidas"];
            tnc = data["estatisticas"]["notas_canceladas"];
            tca = data["estatisticas"]["empresas_ativas"];
            return resolve({
              "totalizador-nfe-cnpj": {
                id: 1,
                totalNotasEmitidas: tne,
                totalNotasCanceladas: tnc,
                totalCnpjAtivos: tca
              }
            });
          } else {
            return reject(data, ajaxCallbackParams);
          }
        });
      });
    }
  });

  _exports.default = _default;
});