define("api-dashboard/templates/components/sales/receipts-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ovgwpr/C",
    "block": "{\"symbols\":[\"e\",\"pl\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"posEntries\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-chevron-right\"],[8],[0,\" \"],[9],[0,\" \\n        \"],[1,[23,1,[\"typeValueDescription\"]],false],[0,\"\\n        \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"isCarnet\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n          \"],[7,\"table\",true],[10,\"class\",\"table table-striped table-hover\"],[8],[0,\"\\n            \"],[7,\"thead\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Vencimento\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Valor\"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"lancamento\",\"parcelasLancamento\"]]],null,{\"statements\":[[0,\"                \"],[7,\"tr\",true],[8],[0,\"\\n                  \"],[7,\"td\",true],[8],[1,[23,2,[\"dataVencimentoFormatted\"]],false],[9],[0,\"\\n                  \"],[7,\"td\",true],[8],[1,[28,\"format/big\",[[23,2,[\"valor\"]]],null],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/sales/receipts-tab.hbs"
    }
  });

  _exports.default = _default;
});