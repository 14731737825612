define("api-dashboard/components/inputs/input-cnpj", ["exports", "api-dashboard/components/inputs/base-masked-field", "api-dashboard/mixins/utils"], function (_exports, _baseMaskedField, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend(_utils.default, {
    label: 'CNPJ',
    pattern: '00.000.000 0000-00',
    placeholder: 'Cadastro Nacional de Pessoa Jurídica',
    validate: function validate() {
      return this.validarCnpjInterno();
    },
    validarCnpjInterno: function validarCnpjInterno() {
      return this.validarCNPJ(this.obterValorSemMascara());
    }
  });

  _exports.default = _default;
});