define("api-dashboard/templates/partials/components/input-autocomplete/new-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bv2kYafk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"action\"]],\"bla\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"btn-new-record\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"  Criar novo registro\"],[9]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/input-autocomplete/new-record.hbs"
    }
  });

  _exports.default = _default;
});