define("api-dashboard/templates/components/inputs/input-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZgOgIcDB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-xs-12 col-sm-12 col-md-6\"],[8],[0,\"\\n  \"],[7,\"label\",true],[11,\"class\",[28,\"if\",[[24,[\"required\"]],\"required\",\"\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Período\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"inputs/input-select\",null,[[\"prompt\",\"value\",\"options\"],[\"Selecione ou preencha ao lado\",[24,[\"period\"]],[24,[\"periodOptions\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-lg-3 col-md-6 col-xs-6\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[8],[0,\"de\"],[9],[0,\"\\n\\t\"],[1,[28,\"inputs/input-date\",null,[[\"val\",\"class\",\"error\",\"displayFormat\",\"maxZoomLevel\"],[[24,[\"from\"]],\"p--0\",[24,[\"errorFrom\"]],[24,[\"displayFormat\"]],[24,[\"maxZoomLevel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-lg-3 col-md-6 col-xs-6\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[8],[0,\"até\"],[9],[0,\"\\n\\t\"],[1,[28,\"inputs/input-date\",null,[[\"val\",\"class\",\"error\",\"displayFormat\",\"maxZoomLevel\"],[[24,[\"to\"]],\"p--0\",[24,[\"errorTo\"]],[24,[\"displayFormat\"]],[24,[\"maxZoomLevel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-period.hbs"
    }
  });

  _exports.default = _default;
});