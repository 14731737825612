define("api-dashboard/models/lancamento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    constants: Ember.inject.service(),
    dataVencimento: null,
    createdAt: _emberData.default.attr('date'),
    dataCancelamento: _emberData.default.attr('date'),
    dataCompetencia: _emberData.default.attr('date'),
    descricao: _emberData.default.attr('string'),
    estimado: _emberData.default.attr('boolean'),
    eventId: _emberData.default.attr('number'),
    eventType: _emberData.default.attr('string'),
    numDocumento: _emberData.default.attr('string'),
    numParcelas: _emberData.default.attr('number'),
    tipo: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    valor: _emberData.default.attr('big'),
    accountEntry: _emberData.default.belongsTo('conta-contabil'),
    cardCompany: _emberData.default.belongsTo('card-company'),
    costCenter: _emberData.default.belongsTo('cost-center'),
    domain: _emberData.default.belongsTo('domain'),
    notaFiscal: _emberData.default.belongsTo('nota-fiscal'),
    notaFiscalServico: _emberData.default.belongsTo('nota-fiscal-servico'),
    parcelasLancamento: _emberData.default.hasMany('parcelaLancamento', {
      inverse: 'lancamento',
      async: false
    }),
    person: _emberData.default.belongsTo('person', {
      inverse: 'lancamentos',
      async: false
    }),
    posEntry: _emberData.default.belongsTo('pos-entry'),
    receivables: _emberData.default.hasMany('receivable', {
      inverse: 'lancamento'
    }),
    rps: _emberData.default.belongsTo('nota-fiscal-servico'),
    openDetailsDiv: false,
    openServicesDiv: false,
    lastDueDate: Ember.computed('parcelasLancamento', function () {
      var lastDate;
      lastDate = null;
      this.get('parcelasLancamento').forEach(function (parc) {
        return parc.get('boletos2').forEach(function (boleto) {
          var ref;

          if (lastDate === null || boleto.get('dataVencimento') < lastDate && (ref = boleto.get('status')) === 'cancelado') {
            return lastDate = boleto.get('dataVencimento');
          }
        });
      });
      return lastDate;
    }),
    canIssueBankBill: Ember.computed('parcelasLancamento', function () {
      var bank_bills;
      bank_bills = this.get('parcelasLancamento').filter(function (pl) {
        return pl.get('boletos2').filter(function (b) {
          return b.get('status') !== 'baixado';
        });
      });
      return bank_bills.length > 0 && this.get('status') !== 'Pago' && this.get('status') !== 'Cancelado';
    }),
    pendente: Ember.computed('status', function () {
      return this.get('status') === 'Pendente';
    }),
    canReissue: Ember.computed('status', function () {
      return this.get('status') !== 'Pago' && this.get('status') !== 'Cancelado';
    }),
    status: Ember.computed('parcelasLancamento.@each.dataBaixado', 'parcelasLancamento.@each.dataRealizado', 'parcelasLancamento.@each.dataCancelado', function () {
      var algumCancelado, algumPago, algumPendente, s;
      algumPago = false;
      algumCancelado = false;
      algumPendente = false;
      s = '';
      this.get('parcelasLancamento').forEach(function (parc) {
        if (parc.get('dataCancelamento') != null) {
          return algumCancelado = true;
        } else if (parc.get('dataBaixado') != null) {
          return algumPago = true;
        } else {
          return algumPendente = true;
        }
      });

      if (!(algumPago || algumPendente) && algumCancelado) {
        s = 'Cancelado';
      } else if (algumPago && algumPendente) {
        s = 'Parcialmente Pago';
      } else if (algumPago) {
        s = 'Pago';
      } else if (algumPendente) {
        s = 'Pendente';
      }

      return s;
    }),
    canIssueNFSe: Ember.computed('rps', function () {
      return !this.get('rps.numero') && this.get('status') !== 'Cancelado';
    }),
    canDispute: Ember.computed('status', function () {
      return this.get('status') !== 'Pago' && this.get('status') !== 'Cancelado';
    }),
    mesFmt: Ember.computed('dataCompetencia', function () {
      return moment(this.get('dataCompetencia')).format('MMM YYYY');
    })
  }, _defineProperty(_DS$Model$extend, "dataVencimento", Ember.computed({
    get: function get(key) {
      var nextVencimento, parcelas, self;
      self = this;
      nextVencimento = null;

      if (!this.get('__dataVencimento')) {
        if (this.get('parcelasLancamento.length') > 0) {
          parcelas = this.get('parcelasLancamento').sortBy('dataVencimento').reverse();
          parcelas.forEach(function (pl) {
            return nextVencimento = pl.get('dataVencimento');
          });
        }
      }

      this.set('__dataVencimento', nextVencimento);
      return this.get('__dataVencimento');
    },
    set: function set(key, value) {
      if (value.constructor.name !== 'Date') {
        throw 'Erro ao setar a data de vencimento';
      }

      this.set('__dataVencimento', value);
      return this.get('__dataVencimento');
    }
  })), _defineProperty(_DS$Model$extend, "baixado", Ember.computed('parcelasLancamento.@each.dataBaixado', {
    get: function get(key) {
      var baixado;

      if (!this.get('__baixado')) {
        baixado = this.get('parcelasLancamento').every(function (p, index) {
          return p.get('dataBaixado') !== null && typeof p.get('dataBaixado.getMonth') === 'function';
        });
        baixado = this.get('parcelasLancamento.length') > 0 && baixado;
        this.set('__baixado', baixado);
      }

      return this.get('__baixado');
    },
    set: function set(key, value) {
      this.set('__baixado', value);
      return this.get('__baixado');
    }
  })), _defineProperty(_DS$Model$extend, "dataRealizado", Ember.computed('parcelasLancamento.@each.dataRealizado', function () {
    var parcelasLancamento;
    parcelasLancamento = this.get('parcelasLancamento');

    if (parcelasLancamento && parcelasLancamento.length === 1) {
      return parcelasLancamento.objectAt(0).dataRealizado;
    }
  })), _defineProperty(_DS$Model$extend, "tipoDescription", Ember.computed('tipo', function () {
    var self, tipo;
    self = this;
    tipo = self.get('constants.lancamentoTypes').find(function (t) {
      return t.value === self.get('tipo');
    });

    if (tipo) {
      return tipo.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "valorTotalJuros", Ember.computed('parcelasLancamento.@each.valorJuros', function () {
    var received, sumMethod;

    sumMethod = function sumMethod(sum, item) {
      return sum.add(item.get('valorJuros') || 0);
    };

    return received = this.get('parcelasLancamento').reduce(sumMethod, new Big(0));
  })), _defineProperty(_DS$Model$extend, "valorTotalDesconto", Ember.computed('parcelasLancamento.@each.valorDesconto', function () {
    var received, sumMethod;

    sumMethod = function sumMethod(sum, item) {
      return sum.add(item.get('valorDesconto') || 0);
    };

    return received = this.get('parcelasLancamento').reduce(sumMethod, new Big(0));
  })), _defineProperty(_DS$Model$extend, "save", function save() {
    this.__baixarParcelas();

    this.__corrigirValorTotal();

    return this._super(arguments);
  }), _defineProperty(_DS$Model$extend, "__baixarParcelas", function __baixarParcelas() {
    var p, self;
    self = this;

    if (this.get('isNew') && this.get('parcelasLancamento').length === 0) {
      p = this.get('store').createRecord('parcela-lancamento');
      p.set('lancamento', this);
      p.set('domain', this.get('domain'));
      p.set('dataVencimento', this.get('dataVencimento'));
      p.set('valor', this.get('valor'));
      this.get('parcelasLancamento').addObject(p);
    }

    if (this.get('parcelasLancamento').length === 1) {
      return this.get('parcelasLancamento').forEach(function (p) {
        if (self.get('baixado') && !p.get('dataRealizado')) {
          return p.set('dataRealizado', new Date());
        }
      });
    }
  }), _defineProperty(_DS$Model$extend, "__corrigirValorTotal", function __corrigirValorTotal() {
    var parcelas, total;
    parcelas = this.get('parcelasLancamento');
    total = parcelas.reduce(function (acc, cur) {
      if (!cur.get('_destroy')) {
        acc += parseFloat(cur.get('valor'));
      }

      return acc;
    }, 0);

    if (total !== parseFloat(this.get('valor'))) {
      this.set('valorCorrigido', true);
      return this.set('valor', total);
    }
  }), _DS$Model$extend));

  _exports.default = _default;
});