define("api-dashboard/models/configuracao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    classeCalculoImpostos: _emberData.default.attr('string'),
    classeCalculoTotais: _emberData.default.attr('string'),
    calculoImpostosPorItem: _emberData.default.attr('boolean'),
    defaultInformacoesAdicionais: _emberData.default.attr('string'),
    mostrarDetalheTelaPrincipal: _emberData.default.attr('boolean'),
    obterDadosEntrada: _emberData.default.attr('boolean'),
    mostrarTipoVenda: _emberData.default.attr('boolean'),
    icmsAliquotaCreditoSimples: _emberData.default.attr('number'),
    mostrarNcm: _emberData.default.attr('string'),
    empresaVeiculos: _emberData.default.attr('boolean'),
    usaCamposTaxasImportacao: _emberData.default.attr('boolean'),
    mostrarAbaImportacao: _emberData.default.attr('boolean'),
    aliquotaIcmsImportacao: _emberData.default.attr('number'),
    contaContabilJurosReceitaId: _emberData.default.attr('number'),
    contaContabilJurosDespesaId: _emberData.default.attr('number'),
    habilitaFinanceiroAvancado: _emberData.default.attr('boolean'),
    domain: _emberData.default.belongsTo('domain')
  });

  _exports.default = _default;
});