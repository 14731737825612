define("api-dashboard/adapters/empresa", ["exports", "api-dashboard/adapters/application-panel"], function (_exports, _applicationPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationPanel.default.extend();

  _exports.default = _default;
});