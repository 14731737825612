define("api-dashboard/components/download-files/download-file-index", ["exports", "api-dashboard/components/base/base-index", "api-dashboard/mixins/utils"], function (_exports, _baseIndex, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseIndex.default.extend(_utils.default, {
    model: null,
    title: 'Download de Arquivos',
    titlePlural: 'Download de Arquivos',
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    },
    didInsertElement: function didInsertElement() {
      return $('#downloadNotasFiscais div.collapse').first().collapse();
    },
    actions: {
      downloadXml: function downloadXml(url) {
        var self;
        self = this;
        this.set('downloadingXml', true);
        return this.downloadURL(url, 'xml.xml', function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadDanfe: function downloadDanfe(url) {
        var self;
        self = this;
        this.set('downloadingDanfe', true);
        return this.downloadURL(url, 'danfe.pdf', function (_this) {
          return function () {
            return self.set('downloadingDanfe', false);
          };
        }(this));
      }
    }
  });

  _exports.default = _default;
});