define("api-dashboard/templates/components/tag-toggle-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "31Pcvi+H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"action\",[[23,0,[]],\"actToggleClass\"],null]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"exemplos\"]]],null,{\"statements\":[[2,\"\\n{{#tag-toggle-class tagName=\\\"li\\\" as |actionToggle|}}<a href=\\\"#\\\" {{action actionToggle}}>Teste</a>{{/tag-toggle-class}}\\n\"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/tag-toggle-class.hbs"
    }
  });

  _exports.default = _default;
});