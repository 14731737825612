define("api-dashboard/templates/components/sales/fiscal-nfce-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "usipjBI+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"Número/Série NFCe:\"],[9],[0,\" \"],[1,[24,[\"model\",\"numeroNfceFormatted\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"Chave NFCe:\"],[9],[0,\" \"],[1,[24,[\"model\",\"chaveNfce\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/sales/fiscal-nfce-info.hbs"
    }
  });

  _exports.default = _default;
});