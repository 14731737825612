define("api-dashboard/components/base/base-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    onClickAction: null,
    actions: {
      onClick: function onClick() {
        if (this.get('onClickAction')) {
          return this.sendAction("onClickAction");
        }
      }
    }
  });

  _exports.default = _default;
});