define("api-dashboard/models/contact-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    removable: true,
    value: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    contactType: _emberData.default.attr('string'),
    _destroy: _emberData.default.attr('boolean'),
    contactTypeLabel: Ember.computed('contactType', function () {
      switch (this.get('contactType')) {
        case 'phone':
          return "Telefone";

        case 'email':
          return "Email";

        case 'cellphone':
          return "Celular";

        case 'fax':
          return "Fax";

        case 'person':
          return "Pessoa";

        case 'other':
          return "Outros";

        default:
          return this.get('contactType');
      }
    }),
    isTypeTelefone: Ember.computed('contactType', function () {
      return ['phone', 'cellphone', 'fax'].includes(this.get('contactType'));
    }),
    isTypeEmail: Ember.computed('contactType', function () {
      return ['email'].includes(this.get('contactType'));
    })
  });

  _exports.default = _default;
});