define("api-dashboard/components/inputs/type-delay", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend({
    actionOnCancelPreviousActions: function (_this) {
      return function () {
        return {};
      };
    }(void 0),
    actionOnStopTyping: function (_this) {
      return function () {
        return {};
      };
    }(void 0),
    allowEmpty: true,
    previousValue: null,
    timeoutBeforeStartAction: 200,
    timeoutObject: null,
    minLetters: 2,
    shouldStartTimeout: Ember.computed('val', function () {
      var value;
      value = this.get('val');

      if (!value) {
        return false;
      }

      return value.toString().length >= this.get("minLetters");
    }),
    obsValue: Ember.observer('val', function () {
      this.cancelCurrentTimeout();
      return this.setNewTimeout();
    }),
    cancelCurrentTimeout: function cancelCurrentTimeout() {
      var timeoutObject;

      try {
        this.sendAction("actionOnCancelPreviousActions");
        timeoutObject = this.get("timeoutObject");
        this.set("previousValue", null);

        if (!timeoutObject) {
          return;
        }

        return clearTimeout(timeoutObject);
      } catch (error) {}
    },
    setNewTimeout: function setNewTimeout() {
      var self, timeout;
      self = this;
      timeout = setTimeout(function () {
        var previousValue;
        previousValue = self.get('val');
        self.set("previousValue", previousValue);
        return self.actionOnStopTyping({
          value: previousValue
        });
      }, this.get("timeoutBeforeStartAction"));
      return this.set("timeoutObject", timeout);
    }
  });

  _exports.default = _default;
});