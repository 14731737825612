define("api-dashboard/components/base/modals/modal-confirmacao-generica", ["exports", "api-dashboard/components/base/modals/modal-generico", "api-dashboard/mixins/components/transicao-div"], function (_exports, _modalGenerico, _transicaoDiv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = BaseModalsModalGenerico.extend(_transicaoDiv.default, {
    actionOnConfirmar: null,
    actionOnCancelar: null,
    partialMensagemConfirmacaoPrincipal: null,
    partialMensagemConfirmacaoCancelada: null,
    partialIndicadorLoading: null,
    partialMensagemSucessoConfirmacao: null,
    partialMensagemErro: null,
    divLoading: null,
    divConfirmacaoPrincipal: null,
    divConfirmacaoCancelada: null,
    divErroConfirmacao: null,
    divConfirmacaoSucesso: null,
    didInsertElement: function didInsertElement() {
      var self;
      self = this;

      this._super();

      this.mapearDivsAtributos();
      this.inicializarEstadoDivs();
      return this.$(".modal").on("show.bs.modal", function () {
        return self.inicializarEstadoDivs();
      });
    },
    mapearDivsAtributos: function mapearDivsAtributos() {
      this.set("divLoading", this.$(".modal-confirmacao-spinner"));
      this.set("divConfirmacaoPrincipal", this.$(".modal-confirmacao-inicial"));
      this.set("divConfirmacaoCancelada", this.$(".modal-confirmacao-cancelada"));
      this.set("divErroConfirmacao", this.$(".modal-confirmacao-erro"));
      return this.set("divConfirmacaoSucesso", this.$(".modal-confirmacao-sucesso"));
    },
    inicializarEstadoDivs: function inicializarEstadoDivs() {
      this.get("divLoading").hide();
      this.get("divConfirmacaoPrincipal").hide();
      this.get("divConfirmacaoCancelada").hide();
      this.get("divErroConfirmacao").hide();
      this.get("divConfirmacaoSucesso").hide();
      return this.alternarJanela({
        proximaDiv: this.get("divConfirmacaoPrincipal")
      });
    },
    cancelar: function cancelar() {
      this.$(".modal").modal("hide");
      return this.sendAction("actionOnCancelar");
    },
    confirmar: function confirmar() {
      var self;
      self = this;
      return this.mostrarLoading({}, function () {
        var callbackOnAjaxComplete;
        return self.sendAction("actionOnConfirmar", callbackOnAjaxComplete = function callbackOnAjaxComplete(sucesso, mensagemErro) {
          if (sucesso) {
            return self.alternarJanela({
              proximaDiv: self.get("divConfirmacaoSucesso")
            });
          } else {
            return self.alternarJanela({
              proximaDiv: self.get("divErroConfirmacao")
            });
          }
        });
      });
    },
    actions: {
      actConfirmar: function actConfirmar() {
        return this.confirmar();
      },
      actCancelar: function actCancelar() {
        return this.cancelar();
      }
    }
  });

  _exports.default = _default;
});