define("api-dashboard/components/notas-fiscais/documento-importacao-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    finish: function finish(nf) {
      return this.get('router').transitionTo('notas-fiscais/edit', nf.get('id'));
    },
    actions: {
      save: function save() {
        var self;
        self = this;
        document.activeElement.blur();

        if (!this.get('model').get('isValid')) {
          this.get('model').send('becameValid');
        }

        return this.get('model').save().then(function (obj) {
          return self.finish(self.get('model.item.notaFiscal'));
        }, function (reason) {});
      },
      cancel: function cancel() {
        var notaFiscal;

        if (confirm("Sair sem salvar alterações?")) {
          notaFiscal = this.get('model.item.notaFiscal');

          if (!this.get('model.id')) {
            this.get('store').unloadRecord(this.get('model'));
          }

          return this.finish(notaFiscal);
        }
      }
    }
  });

  _exports.default = _default;
});