define("api-dashboard/templates/components/base/dropdown-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p2MY0FV9",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-default dropdown-toggle\"],[10,\"data-toggle\",\"dropdown\"],[10,\"type\",\"button\"],[8],[0,\"\\t\"],[1,[22,\"titulo\"],false],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n\\n  \"],[7,\"li\",true],[8],[7,\"a\",true],[8],[7,\"b\",true],[8],[1,[22,\"titulo\"],false],[9],[9],[9],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"arrayLabelValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"actEscolherOpcao\",[23,2,[]]]],[8],[1,[23,1,[\"label\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/dropdown-list.hbs"
    }
  });

  _exports.default = _default;
});