define("api-dashboard/components/grid-empresas-ativas", ["exports", "api-dashboard/mixins/requests/empresa", "api-dashboard/mixins/utils"], function (_exports, _empresa, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_empresa.default, _utils.default, {
    empresas: [],
    totalRegistrosPossiveis: null,
    search: null,
    isSearch: Ember.computed("search", function () {
      if (!this.get("search")) {
        return null;
      }

      return true;
    }),
    didInsertElement: function didInsertElement() {
      return this.carregarDadosIniciais();
    },
    carregarDadosIniciais: function carregarDadosIniciais(options) {
      var self;

      if (options == null) {
        options = {};
      }

      self = this;
      this.mostrarEstadoCarregamento(options);
      return this.obterEmpresasAtivas(this, {}, function (sucesso, dados, httpCode) {
        if (sucesso) {
          self.set("empresas", dados);

          if (dados.get("length") > 0) {
            self.set("totalRegistrosPossiveis", dados.meta.total);
            return self.mostrarJanelaDados(options);
          } else {
            return self.mostrarJanelaSemDados(options);
          }
        } else {
          return self.mostrarJanelaErros();
        }
      });
    },
    mostrarEstadoCarregamento: function mostrarEstadoCarregamento(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      if (!this.get("rendered")) {
        return;
      }

      if (!params["manterFormularioBusca"]) {
        this.$("#div-formulario-pesquisa-empresa").hide();
      }

      this.$("#grid-empresas-ativas-tabela").hide();
      this.$("#grid-empresas-ativas-sem-dados").hide();
      this.$("#grid-empresas-ativas-erro").hide();
      return this.$("#grid-empresas-ativas-spinner").show();
    },
    mostrarJanelaDados: function mostrarJanelaDados(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      if (!this.get("rendered")) {
        return;
      }

      this.$("#div-formulario-pesquisa-empresa").fadeIn();
      this.$("#grid-empresas-ativas-tabela").fadeIn();
      this.$("#grid-empresas-ativas-sem-dados").hide();
      this.$("#grid-empresas-ativas-erro").hide();
      return this.$("#grid-empresas-ativas-spinner").hide();
    },
    mostrarJanelaSemDados: function mostrarJanelaSemDados(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      if (!this.get("rendered")) {
        return;
      }

      if (!params["manterFormularioBusca"]) {
        this.$("#div-formulario-pesquisa-empresa").hide();
      }

      this.$("#grid-empresas-ativas-tabela").hide();
      this.$("#grid-empresas-ativas-sem-dados").show();
      this.$("#grid-empresas-ativas-erro").hide();
      return this.$("#grid-empresas-ativas-spinner").hide();
    },
    mostrarJanelaErros: function mostrarJanelaErros(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      if (!this.get("rendered")) {
        return;
      }

      if (!params["manterFormularioBusca"]) {
        this.$("#div-formulario-pesquisa-empresa").hide();
      }

      this.$("#grid-empresas-ativas-tabela").hide();
      this.$("#grid-empresas-ativas-sem-dados").hide();
      this.$("#grid-empresas-ativas-erro").show();
      return this.$("#grid-empresas-ativas-spinner").hide();
    },
    carregarMaisEmpresas: function carregarMaisEmpresas(callbackOnLoadComplete) {
      var self;
      self = this;
      return this.carregarMaisEmpresasOffset(this, {
        offset: this.get("empresas").get("length")
      }, function (sucesso, dados) {
        callbackOnLoadComplete();

        if (sucesso) {
          self.set("totalRegistrosPossiveis", dados.meta.total);
          return self.set("empresas", self.get("empresas").toArray().pushObjects(dados.toArray()));
        }
      });
    },
    atualizar: function atualizar() {
      var empresas, self;
      self = this;
      empresas = this.get("empresas");

      if (!empresas) {
        empresas = [];
      } else {
        empresas = empresas.toArray();
      }

      this.mostrarEstadoCarregamento();
      return this.obterEmpresasAtivas(this, {}, function (sucesso, dados) {
        var shouldUpdateAttr;

        if (sucesso) {
          if (dados.get("length") === 0) {
            if (empresas.get("length") === 0) {
              self.mostrarJanelaSemDados();
            } else {
              self.mostrarJanelaDados();
            }

            return;
          }

          shouldUpdateAttr = false;
          dados.forEach(function (registro, index) {
            if (!empresas.findBy("id", registro.get("id"))) {
              empresas.pushObject(registro);
              return shouldUpdateAttr = true;
            }
          });

          if (shouldUpdateAttr) {
            self.set("totalRegistrosPossiveis", dados.meta.total);
            self.set("empresas", empresas);
          }

          return self.mostrarJanelaDados();
        } else {
          return self.mostrarJanelaErros();
        }
      });
    },
    carregarDownloads: function carregarDownloads(empresa, classIndex, retry) {
      var botao, divDados, divErro, divSemDados, divSpinner, quit;
      botao = this.$("#btn-download-arquivo-" + classIndex);
      divSpinner = this.$("#downloads-empresa-" + classIndex + "-spinner");
      divDados = this.$("#downloads-empresa-" + classIndex + "-dados");
      divSemDados = this.$("#downloads-empresa-" + classIndex + "-sem-dados");
      divErro = this.$("#downloads-empresa-" + classIndex + "-erro");
      quit = false;
      [divSpinner, divDados, divSemDados, divErro].every(function (item) {
        if (retry) {
          item.hide();
          return true;
        }

        if (item.css("display") !== "none") {
          quit = true;
          return false;
        }

        return true;
      });

      if (quit) {
        return;
      }

      divSpinner.show();
      return this.obterBackups(this, {
        empresa_id: empresa.get("id")
      }, function (sucesso, dados, httpStatus) {
        var divExibir;

        if (sucesso) {
          if (dados.length === 0) {
            divExibir = divSemDados;
          } else {
            empresa.set('backups', dados);
            divExibir = divDados;
          }
        } else {
          divExibir = divErro;
        }

        return divSpinner.fadeOut("fast", function () {
          return divExibir.fadeIn("fast");
        });
      });
    },
    stopTyping: function stopTyping(params) {
      var promise, self;
      self = this;

      if (params["value"].trim().length === 0) {
        return this.carregarDadosIniciais({
          manterFormularioBusca: true
        });
      }

      this.mostrarEstadoCarregamento({
        manterFormularioBusca: true
      });
      promise = this.obterEmpresaPorNomeOuCnpj(this, {
        search: params["value"],
        returnAsPromise: true
      }, function () {});
      promise.then(function (dados) {
        if (this !== self.get("promiseBuscaEmpresa")) {
          return;
        }

        if (dados.get("length") > 0) {
          self.set("empresas", dados);
          self.set("totalRegistrosPossiveis", dados.meta.total);
          return self.mostrarJanelaDados();
        } else {
          return self.mostrarJanelaSemDados({
            manterFormularioBusca: true
          });
        }
      }, function (errors) {
        return self.mostrarJanelaErros({
          manterFormularioBusca: true
        });
      });
      return this.set("promiseBuscaEmpresa");
    },
    stopPreviousActions: function stopPreviousActions() {
      return this.set("promiseBuscaEmpresa", null);
    },
    limparBusca: function limparBusca() {
      if (!this.get("isSearch")) {
        return;
      }

      this.carregarDadosIniciais({
        manterFormularioBusca: true
      });
      return this.$("#txt-busca-empresa").val("");
    },
    actions: {
      actCarregarMaisEmpresas: function actCarregarMaisEmpresas(callbackOnLoadComplete) {
        return this.carregarMaisEmpresas(callbackOnLoadComplete);
      },
      actAtualizar: function actAtualizar() {
        return this.atualizar();
      },
      actCarregarDownloads: function actCarregarDownloads(empresa, classIndex, retry) {
        if (retry == null) {
          retry = false;
        }

        return this.carregarDownloads(empresa, classIndex, retry);
      },
      actStopTyping: function actStopTyping(params) {
        return this.stopTyping(params);
      },
      actStopPreviousActions: function actStopPreviousActions() {
        return this.stopPreviousActions();
      },
      actLimparBusca: function actLimparBusca() {
        return this.limparBusca();
      }
    }
  });

  _exports.default = _default;
});