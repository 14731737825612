define("api-dashboard/components/formularios/upload-certificado", ["exports", "api-dashboard/components/formularios/formulario-generico", "api-dashboard/mixins/requests/empresa"], function (_exports, _formularioGenerico, _empresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FormulariosUploadCertificadoComponent;
  FormulariosUploadCertificadoComponent = _formularioGenerico.default.extend(_empresa.default, {
    empresa: null,
    divMensagensFormulario: null,
    extensoesPermitidas: ["PFX", "P12"],
    arquivoCertificado: false,
    senhaCertificado: null,
    certificadoValido: false,
    inputSenhaValido: false,
    didInsertElement: function didInsertElement() {
      this.set("divMensagensFormulario", this.$(".alert"));
      return this.$("#empresa-upload").val(this.get("empresa").get("nome"));
    },
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      var erros;
      erros = [];

      if (!this.get("certificadoValido")) {
        erros.push("O certificado não foi anexado ou não tem formato válido.");
      }

      if (!this.get("inputSenhaValido")) {
        erros.push("A senha do certificado deve ser preenchida.");
        this.$(":password").focus();
      }

      if (erros.length > 0) {
        erros = this.arrayMensagensToUlLiTag(erros);
        return this.mostrarMensagemErro(erros, function () {
          return callbackOnValidacaoCompleta(false);
        });
      } else {
        return this.esconderMensagensErro(function () {
          return callbackOnValidacaoCompleta(true);
        });
      }
    },
    mostrarMensagemErro: function mostrarMensagemErro(mensagem, callbackOnAnimationComplete) {
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "erro"
      }, callbackOnAnimationComplete);
    },
    esconderMensagensErro: function esconderMensagensErro(callbackOnAnimationComplete) {
      return this.esconderMensagensEmExibicao(this.get("divMensagensFormulario"), {}, callbackOnAnimationComplete);
    },
    mostrarMensagemSucesso: function mostrarMensagemSucesso(params, callbackOnAnimationComplete) {
      var mensagem;

      if (params == null) {
        params = {};
      }

      mensagem = "Upload realizado com sucesso!";
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "sucesso"
      }, callbackOnAnimationComplete);
    },
    fazerUploadCertificadoInterno: function fazerUploadCertificadoInterno(params, callbackOnAjaxComplete) {
      return this.fazerUploadCertificado(this, params, function (sucesso, dados, httpCode) {
        return callbackOnAjaxComplete(sucesso, dados);
      });
    },
    submeterFormulario: function submeterFormulario() {
      var self;
      self = this;
      return this.indicarUploadingCertificado({
        indicar: true
      }, function () {
        var parametros;
        parametros = {};
        parametros["arquivo"] = self.get("arquivoCertificado");
        parametros["senha"] = self.get("senhaCertificado");
        parametros["empresa"] = self.get("empresa");
        return self.fazerUploadCertificadoInterno(parametros, function (sucesso, dados, httpCode) {
          return self.indicarUploadingCertificado({
            indicar: false
          }, function () {
            var mensagem, textoInput;

            if (sucesso) {
              self.mostrarMensagemSucesso({}, function () {});
              textoInput = dados["nomeEmpresa"] + " " + dados["cnpj"];
              /*
              #Mostra o CNPJ e o nome da empresa no input.
              self.$("#empresa-upload").val(textoInput)
              self.$("#empresa-upload").focus()
               */

              self.$("#btn-novo-upload-certificado").show();
              self.$("#btn-upload-certificado").hide();
              self.$(":password").attr("disabled", true);
              self.$("#btn-anexo-certificado").attr("disabled", true);
              return self._super();
            } else {
              mensagem = dados["mensagemUsuario"];

              if (!mensagem) {
                mensagem = "Ocorreu um erro inesperado ao realizar o upload do certificado.";
              }

              if (dados["erroInterno"] === "SENHA") {
                self.$(":password").focus();
                self.set("senhaCertificado", "");
                self.set("inputSenhaValido", false);
              }

              return self.mostrarMensagemErro(mensagem, function () {});
            }
          });
        });
      });
    },
    indicarUploadingCertificado: function indicarUploadingCertificado(params, callbackOnAnimationComplete) {
      var disabled, divLoading;
      disabled = true;
      divLoading = this.$("#upload-certificado-loading");

      if (params["indicar"]) {
        disabled = true;
        divLoading.show();
      } else {
        disabled = false;
        divLoading.hide();
      }

      this.$("#btn-anexo-certificado").attr("disabled", disabled);
      this.$(":password").attr("disabled", disabled);
      this.$("#btn-upload-certificado").attr("disabled", disabled);
      return callbackOnAnimationComplete();
    },
    prepararElementosParaNovoUpload: function prepararElementosParaNovoUpload() {
      this.$("#btn-anexo-certificado").attr("disabled", false);
      this.$(":password").attr("disabled", false);
      this.$("#btn-upload-certificado").attr("disabled", false);
      /*
      #Inicializa o campo empresa.
      @$("#empresa-upload").val("")
       */

      this.$("#btn-novo-upload-certificado").hide();
      this.$("#btn-upload-certificado").show();
      this.set("arquivoCertificado", null);
      this.set("senhaCertificado", "");
      this.set("certificadoValido", false);
      this.set("inputSenhaValido", false);
      this.esconderMensagensErro(function () {});
      return this.$(".icon-upload").hide();
    },
    certificadoAnexadoComSucesso: function certificadoAnexadoComSucesso(arquivo, callback) {
      this.$(".icon-upload").show();
      this.set("certificadoValido", true);
      this.set("arquivoCertificado", arquivo);
      this.esconderMensagensErro(function () {});
      return callback();
    },
    certificadoComFormatoInvalido: function certificadoComFormatoInvalido(callback) {
      this.$(".icon-upload").hide();
      this.set("certificadoValido", false);
      this.mostrarMensagemErro("Formato do certificado é inválido", function () {});
      return callback();
    },
    validacaoCampoSenha: function validacaoCampoSenha(options) {
      this.set("inputSenhaValido", options["valido"]);

      if (!options["valido"]) {
        return this.mostrarMensagemErro("Você deve preencher a senha para fazer o upload do certificado", function () {});
      }
    },
    actions: {
      actCertificadoAnexadoComSucesso: function actCertificadoAnexadoComSucesso(arquivo, callback) {
        return this.certificadoAnexadoComSucesso(arquivo, callback);
      },
      actCertificadoComFormatoInvalido: function actCertificadoComFormatoInvalido(callback) {
        return this.certificadoComFormatoInvalido(callback);
      },
      actValidacaoCampoSenha: function actValidacaoCampoSenha(options) {
        return this.validacaoCampoSenha(options);
      },
      actFazerNovoUpload: function actFazerNovoUpload() {
        return this.prepararElementosParaNovoUpload();
      }
    }
  });
  var _default = FormulariosUploadCertificadoComponent;
  _exports.default = _default;
});