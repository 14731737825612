define("api-dashboard/mixins/requests/util", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    obterTotalizadoresNotasCnpj: function obterTotalizadoresNotasCnpj(context, params, callback) {
      var store;
      store = context.get("store");
      return store.findAll("totalizador-nfe-cnpj", {
        reload: params["reload"]
      }).then(function (dados) {
        return callback(true, dados.objectAt(0), 200);
      }, function (erros) {
        return callback(false, erros, 200);
      });
    },
    getAddressFromPostalCode: function getAddressFromPostalCode(context, params, callback) {
      var host;
      host = "https://viacep.com.br/ws/" + params["cep"] + "/json";
      return $.ajax({
        url: host,
        method: 'GET',
        success: function success(data, textStatus, jqXHR) {
          return callback(true, data, 200);
        },
        error: function error(jqXHR) {
          if (jqXHR.status === 200) {
            return callback(false, null, 404);
          } else {
            return callback(false, jqXHR, jqXHR.status);
          }
        },
        timeout: 8000
      });
    }
  });

  _exports.default = _default;
});