define("api-dashboard/components/pos-reports/pos-report-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    spin: Ember.inject.service(),
    application: Ember.inject.service(),
    model: null,
    searching_tree: false,
    searching_edit: false,
    report_tree: [],
    bt_save_classes: 'fa fa-save',
    init: function init() {
      this.get_report_tree();
      return this._super.apply(this, arguments);
    },
    bt_save_disable_observer: Ember.observer('bt_save_disabled', function () {
      if (this.get('bt_save_disabled')) {
        this.set('bt_save_classes', 'fa fa-refresh fa-spin');
        return this.set('spin.buttonSpin', true);
      } else {
        this.set('bt_save_classes', 'fa fa-save');
        return this.set('spin.buttonSpin', false);
      }
    }),
    get_report_tree: function get_report_tree() {
      var report_tree, self;
      self = this;
      self.set('searching_tree', true);
      report_tree = [];
      return this.get('store').findRecord('posReportFileConfig', 1).then(function (obj) {
        self.set('model', obj);
        report_tree.push(self.normalize_array('Vendas', obj.get('vendaReports')));
        report_tree.push(self.normalize_array('DANFe e NFCe', obj.get('danfeNfceReports')));
        report_tree.push(self.normalize_array('CFe S@T', obj.get('cfeSatReports')));
        report_tree.push(self.normalize_array('Listagem de Vendas', obj.get('listVendasReports')));
        report_tree.push(self.normalize_array('Carnês', obj.get('carneReports')));
        report_tree.push(self.normalize_array('Movimentação Diária', obj.get('movDiariaReports')));
        report_tree.push(self.normalize_array('Recibo', obj.get('reciboReports')));
        report_tree.push(self.normalize_array('Movimentação de Caixa', obj.get('movimentoCaixaReports')));
        report_tree.push(self.normalize_array('Fechamento de Caixa', obj.get('fechamentoCaixaReports')));
        report_tree.push(self.normalize_array('Consignação', obj.get('consignacaoReports')));
        report_tree.push(self.normalize_array('Lista de Consignações Abertas', obj.get('consigAbertasReports')));
        self.set('report_tree', report_tree);
        return self.set('searching_tree', false);
      });
    },
    normalize_array: function normalize_array(title, a) {
      return {
        name: title,
        children: a.map(function (x) {
          return x;
        })
      };
    },
    actions: {
      onSubmit: function onSubmit() {
        var record, self;
        this.set('bt_save_disabled', true);
        self = this;
        this.get('model').save().then(function (obj) {
          self.get('application').showAlert('Configurações dos Relatórios do PDV salvas com sucesso!', 'success');
          return self.set('bt_save_disabled', false);
        }, function () {
          self.get('application').showAlert('Erro ao salvar as Configurações dos Relatórios do PDV', 'error');
          return self.set('bt_save_disabled', false);
        });
        record = this.get('model');
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});