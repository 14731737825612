define("api-dashboard/routes/stores-config/index", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    application: Ember.inject.service(),
    afterModel: function afterModel(transition) {
      var store;

      if (this.get('application.currentDomain.visibleStores.length') === 1) {
        store = this.get('application.currentDomain.visibleStores').objectAt(0);
        this.transitionTo('stores-config/edit', store);
      }

      return this._super(transition);
    },
    model: function model() {
      return this.get('store').findAll('fStore');
    }
  });

  _exports.default = _default;
});