define("api-dashboard/components/formularios/login-principal", ["exports", "api-dashboard/components/formularios/formulario-generico"], function (_exports, _formularioGenerico) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formularioGenerico.default.extend({
    userSession: Ember.inject.service(),
    actionOnFormularioSubmetido: null,
    actionOnClickEsqueciSenha: null,
    divMensagensFormulario: null,
    didInsertElement: function didInsertElement() {
      return this.set("divMensagensFormulario", this.$(".alert"));
    },
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      var erros, mensagensErro;
      mensagensErro = [];

      if (!this.get("campoEmailValido")) {
        mensagensErro.push("O campo e-mail deve ser preenchido corretamente.");
        this.$("input").not(":password").focus();
      }

      if (!this.get("campoSenhaValido")) {
        mensagensErro.push("O campo senha deve ser preenchido corretamente.");
        this.$(":password").focus();
      }

      if (mensagensErro.length > 0) {
        erros = this.arrayMensagensToUlLiTag(mensagensErro);
        return this.mostrarMensagemErro(erros, function () {
          return callbackOnValidacaoCompleta(false);
        });
      } else {
        return this.esconderMensagensErro(function () {
          return callbackOnValidacaoCompleta(true);
        });
      }
    },
    submeterFormulario: function submeterFormulario() {
      var self;
      self = this;
      this.indicarLoading(true);
      return this.get("userSession").authenticate({
        login: this.get("emailLogin"),
        senha: this.get("senhaLogin")
      }).then(function (sucesso, dados) {
        self.indicarLoading(false);

        if (!sucesso) {
          self.set("senhaLogin", "");
          self.set("campoSenhaValido", false);
          self.$(":password").focus();
          return self.mostrarMensagemErro(dados["mensagem"], function () {});
        }
      }, function (error) {
        self.indicarLoading(false);
        return self.mostrarMensagemErro(error, function () {});
      });
    },
    mostrarMensagemErro: function mostrarMensagemErro(mensagem, callbackOnAnimationComplete) {
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "erro"
      }, callbackOnAnimationComplete);
    },
    esconderMensagensErro: function esconderMensagensErro(callbackOnAnimationComplete) {
      return this.esconderMensagensEmExibicao(this.get("divMensagensFormulario"), {}, callbackOnAnimationComplete);
    },
    indicarLoading: function indicarLoading(indicar) {
      var componentes, spinner;
      spinner = this.$("#loading");
      componentes = this.$("input").add("button");

      if (indicar) {
        spinner.show();
        return componentes.attr("disabled", true);
      } else {
        spinner.hide();
        return componentes.attr("disabled", false);
      }
    },
    actions: {
      actOnValidacaoCampoLogin: function actOnValidacaoCampoLogin(params, callback) {
        this.set("campoEmailValido", params["valido"]);
        return callback();
      },
      actOnValidacaoCampoSenha: function actOnValidacaoCampoSenha(params, callback) {
        this.set("campoSenhaValido", params["valido"]);
        return callback();
      },
      actRecuperarSenha: function actRecuperarSenha() {
        return this.sendAction("actionOnClickEsqueciSenha");
      }
    }
  });

  _exports.default = _default;
});