define("api-dashboard/components/formularios/cadastro-empresa", ["exports", "api-dashboard/components/formularios/formulario-generico", "api-dashboard/mixins/requests/empresa", "api-dashboard/mixins/utils"], function (_exports, _formularioGenerico, _empresa, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formularioGenerico.default.extend(_empresa.default, _utils.default, {
    divMensagensFormulario: null,
    empresa: null,
    modoEdicao: false,
    cnpj: null,
    cnpjValido: false,
    razaoSocialValido: false,
    fantasiaValido: false,
    inscricaoEstadualValido: false,
    inscricaoMunicipalValido: false,
    regimeTributarioValido: false,
    emailValido: false,
    telefone: null,
    telefoneValido: false,
    cep: null,
    cepValido: false,
    numeroEnderecoValido: false,
    complementoValido: false,
    cpfResponsavel: null,
    cpfResponsavelValido: false,
    nomeResponsavelValido: false,
    senhaReceitaPRValido: false,
    cpfCnpjContabilidade: null,
    cpfCnpjContabilidadeValido: null,
    nomeContabilidadeValido: false,
    telefoneContabilidade: null,
    telefoneContabilidadeValido: false,
    emailContabilidadeValido: false,
    nfeAtivo: false,
    serieNfeHomologacaoValido: false,
    proximoNumeroNfeHomologacaoValido: false,
    serieNfeValido: false,
    proximoNumeroNfeValido: false,
    nfseAtivo: false,
    proximoRpsHomologacaoValido: false,
    proximoRpsValido: false,
    serieNfseHomologacaoValido: false,
    serieNfseValido: false,
    nfceAtivo: false,
    idTokenValido: false,
    idTokenHomologacaoValido: false,
    proximoNumeroNfceValido: false,
    proximoNumeroNfceHomologacaoValido: false,
    serieNfceValido: false,
    serieNfceHomologacaoValido: false,
    numeroCscValido: false,
    numeroCscHomologacaoValido: false,
    novaLogoAnexada: false,
    arquivoLogoAnexada: null,
    regimesTributarioDropDown: [{
      label: "Simples Nacional",
      value: 1
    }, {
      label: "Simples Nacional - excesso de sublimite de receita bruta",
      value: 2
    }, {
      label: "Regime Normal",
      value: 3
    }],
    empresaPR: Ember.computed("empresa.uf", function () {
      return this.get("empresa.uf") === "PR";
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var empresa, oldEmpresa;
      empresa = this.get("empresa");
      oldEmpresa = this.get("oldEmpresa");

      if (empresa !== oldEmpresa && empresa) {
        this.inicializarEstadoComponente();

        if (this.$()) {
          this.inicializarEstadoComponenteDOM();
        }
      }

      return this._super();
    },
    inicializarEstadoComponente: function inicializarEstadoComponente() {
      var flags, self;

      if (this.get("modoEdicao")) {
        flags = ["cnpjValido", "razaoSocialValido", "fantasiaValido", "inscricaoEstadualValido", "inscricaoMunicipalValido", "regimeTributarioValido", "emailValido", "telefoneValido", "cepValido", "numeroEnderecoValido", "complementoValido", "cpfResponsavelValido", "nomeResponsavelValido", "cpfCnpjContabilidadeValido"];
        flags.push("serieNfeValido");
        flags.push("proximoNumeroNfeValido");
        flags.push("serieNfeHomologacaoValido");
        flags.push("proximoNumeroNfeHomologacaoValido");
        flags.push("proximoRpsValido");
        flags.push("proximoRpsHomologacaoValido");
        flags.push("serieNfseHomologacaoValido");
        flags.push("serieNfseValido");
        flags.push("idTokenValido");
        flags.push("idTokenHomologacaoValido");
        flags.push("proximoNumeroNfceValido");
        flags.push("proximoNumeroNfceHomologacaoValido");
        flags.push("serieNfceValido");
        flags.push("serieNfceHomologacaoValido");
        flags.push("numeroCscValido");
        flags.push("numeroCscHomologacaoValido");
        self = this;
        return flags.forEach(function (f) {
          return self.set(f, true);
        });
      }
    },
    inicializarEstadoComponenteDOM: function inicializarEstadoComponenteDOM() {
      var arrayIds, empresa, inputsToken, self;
      self = this;
      inputsToken = this.$("#tokens");

      if (this.get("modoEdicao")) {
        inputsToken.show();
        this.$("#logradouro").val(this.get("empresa.logradouro"));
        this.$("#bairro").val(this.get("empresa.bairro"));
        this.$("#cidade").val(this.get("empresa.municipio"));
        this.$("#uf").val(this.get("empresa.uf"));

        if (this.get("empresa.possuiLogo")) {
          this.$("#img-logo-upload").attr("src", this.get("empresa.caminhoCompletoLogo"));
        }
      } else {
        inputsToken.hide();
      }

      arrayIds = [{
        domId: "txt-serie-nfe",
        attrName: "serieNfeProducao"
      }, {
        domId: "txt-proximo-numero-nfe",
        attrName: "proximoNumeroNfeProducao"
      }, {
        domId: "txt-serie-nfe-homologacao",
        attrName: "serieNfeHomologacao"
      }, {
        domId: "txt-proximo-numero-nfe-homologacao",
        attrName: "proximoNumeroNfeHomologacao"
      }, {
        domId: "txt-proximo-rps-nfse",
        attrName: "proximoNumeroNfseProducao"
      }, {
        domId: "txt-serie-nfse",
        attrName: "serieNfseProducao"
      }, {
        domId: "txt-proximo-rps-nfse-homologacao",
        attrName: "proximoNumeroNfseHomologacao"
      }, {
        domId: "txt-serie-nfse-homologacao",
        attrName: "serieNfseHomologacao"
      }, {
        domId: "txt-proximo-numero-nfce",
        attrName: "proximoNumeroNfceProducao"
      }, {
        domId: "txt-serie-nfce",
        attrName: "serieNfceProducao"
      }, {
        domId: "txt-proximo-numero-nfce-homologacao",
        attrName: "proximoNumeroNfceHomologacao"
      }, {
        domId: "txt-serie-nfce-homologacao",
        attrName: "serieNfceHomologacao"
      }];
      empresa = this.get("empresa");
      return arrayIds.forEach(function (item) {
        if (!empresa.get(item["attrName"])) {
          return self.$("#" + item["domId"]).val(1).trigger("input").trigger("change");
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.set("divMensagensFormulario", this.$(".alert"));
      return this.inicializarEstadoComponenteDOM();
    },
    toggleDocumentoFiscal: function toggleDocumentoFiscal(seletorJquery, value, callback) {
      var self;
      self = this;
      return seletorJquery.slideToggle(function () {
        /*
        #Se o toggle foi acionado para ON:
        if value
          self.moverScrollComAnimacao(seletorJquery)
         */
        if (callback) {
          return callback();
        }
      });
    },
    atribuirRegimeTributario: function atribuirRegimeTributario(regime) {
      this.set("regimeTributarioValido", true);
      return this.get("empresa").set("regimeTributario", regime);
    },
    validarCnpj: function validarCnpj(params, callbackOnAjaxComplete) {
      var self;
      self = this;
      return this.verificarCNPJExistente(this, {
        cnpj: params["valueSemMascara"]
      }, function (sucesso, dados, httpCode) {
        var modoEdicao;
        modoEdicao = self.get("modoEdicao");

        if (sucesso && dados.get("length") > 0 && (!modoEdicao || self.get("empresa") !== dados.get("firstObject"))) {
          sucesso = false;
        }

        return callbackOnAjaxComplete({
          valido: sucesso
        });
      });
    },
    atribuirCampoPosValidacao: function atribuirCampoPosValidacao(nomeAtributo, params, callbackOnFimValidacao) {
      this.set(nomeAtributo + "Valido", params["valido"]);

      if (params["valido"]) {
        if (params.hasOwnProperty("valueSemMascara")) {
          this.get("empresa").set(nomeAtributo, params["valueSemMascara"]);
        }
      } else {
        this.get("empresa").set(nomeAtributo, null);
      }

      return callbackOnFimValidacao();
    },
    atribuirCepPosValidacao: function atribuirCepPosValidacao(params, callbackOnFimValidacao) {
      var empresa;

      if (params["valido"]) {
        this.$("#cidade").val(params["endereco"]["localidade"]);
        this.$("#uf").val(params["endereco"]["uf"]);
        empresa = this.get("empresa");
        empresa.set("municipio", params["endereco"]["localidade"]);
        empresa.set("codigoMunicipio", params["endereco"]["ibge"]);
        empresa.set("codigoUf", params["endereco"]["uf"]);
        empresa.set("uf", params["endereco"]["uf"]);

        if (params["endereco"]["logradouro"].length === 0) {
          empresa.set("logradouro", "");
          this.$("#logradouro").val("");
          this.$("#logradouro").attr("disabled", false);
          this.$("#logradouro").focus();
        } else {
          empresa.set("logradouro", params["endereco"]["logradouro"]);
          this.$("#logradouro").val(params["endereco"]["logradouro"]);
          this.$("#logradouro").attr("disabled", true);
          this.$("#txt-numero-endereco").focus();
        }

        if (params["endereco"]["bairro"].length === 0) {
          empresa.set("bairro", "");
          this.$("#bairro").val("");
          this.$("#bairro").attr("disabled", false);
        } else {
          empresa.set("bairro", params["endereco"]["bairro"]);
          this.$("#bairro").val(params["endereco"]["bairro"]);
          this.$("#bairro").attr("disabled", true);
        }
      } else {
        this.$("#logradouro").val("");
        this.$("#bairro").val("");
        this.$("#cidade").val("");
        this.$("#uf").val("");
      }

      return this.atribuirCampoPosValidacao("cep", params, callbackOnFimValidacao);
    },
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      var mensagensErro, modoEdicao, opcoesCorrecaoMensagem;

      if (callbackOnValidacaoCompleta == null) {
        callbackOnValidacaoCompleta = function callbackOnValidacaoCompleta() {};
      }

      mensagensErro = [];
      opcoesCorrecaoMensagem = [];
      modoEdicao = this.get("modoEdicao");

      if (!this.get("cnpjValido")) {
        mensagensErro.pushObject("O <b>CNPJ</b> informado é inválido ou já está cadastrado.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-cnpj")
        });
      }

      if (!this.get("razaoSocialValido")) {
        mensagensErro.pushObject("A <b>Razão Social</b> informada é inválida.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-razao-social")
        });
      }

      if (!this.get("fantasiaValido")) {
        mensagensErro.pushObject("A <b>Fantasia</b> informada é inválida.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-fantasia")
        });
      }

      if (!this.get("inscricaoEstadualValido") && (this.get("empresa.habilitaNfce") || this.get("empresa.habilitaNfe"))) {
        mensagensErro.pushObject("A <b>Inscrição Estadual</b> informada é inválida.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-inscricao-estadual")
        });
      }

      if (!this.get("inscricaoMunicipalValido") && this.get("empresa.habilitaNfse")) {
        mensagensErro.pushObject("A <b>Inscrição Municipal</b> informada é inválida.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-inscricao-municipal")
        });
      }

      if (!this.get("regimeTributarioValido")) {
        mensagensErro.pushObject("Deve ser escolhido um <b>Regime Tributário</b>.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#cmb-regime-tributario")
        });
      }

      if (!this.get("cepValido")) {
        mensagensErro.pushObject("O <b>CEP do endereço</b> não é válido.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-cep-endereco")
        });
      }

      if (!this.get("numeroEnderecoValido")) {
        mensagensErro.pushObject("O <b>número do endereço</b> não é válido.");
        opcoesCorrecaoMensagem.pushObject({
          componenteRef: this.$("#txt-numero-endereco")
        });
      }

      if (mensagensErro.length === 0) {
        return this.esconderMensagensErro(function () {
          return callbackOnValidacaoCompleta(true);
        });
      } else {
        mensagensErro = this.arrayMensagensToUlLiTag(mensagensErro);
        return this.mostrarMensagensErro(mensagensErro, opcoesCorrecaoMensagem, function () {
          return callbackOnValidacaoCompleta(false);
        });
      }

      return callbackOnValidacaoCompleta(false);
    },
    esconderMensagensErro: function esconderMensagensErro(callbackOnAnimacaoCompleta) {
      return this.esconderMensagensEmExibicao(this.get("divMensagensFormulario"), {}, callbackOnAnimacaoCompleta);
    },
    mostrarMensagemSucesso: function mostrarMensagemSucesso(mensagem, callbackOnAnimationComplete) {
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "sucesso"
      }, callbackOnAnimationComplete);
    },
    mostrarMensagensErro: function mostrarMensagensErro(mensagem, opcoesCorrecaoMensagem, callbackOnAnimacaoCompleta) {
      var params;
      params = {
        tipoMensagem: "erro",
        mensagem: mensagem
      };
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), params, callbackOnAnimacaoCompleta);
    },
    toggleFormNfe: function toggleFormNfe(value, callback) {
      this.set("nfeAtivo", value);
      this.set("empresa.habilitaNfe", value);
      return this.toggleDocumentoFiscal(this.$("#form-nfe"), value, callback);
    },
    toggleFormNfse: function toggleFormNfse(value, callback) {
      this.set("nfseAtivo", value);
      this.set("empresa.habilitaNfse", value);
      return this.toggleDocumentoFiscal(this.$("#form-nfse"), value, callback);
    },
    toggleFormNfce: function toggleFormNfce(value, callback) {
      this.set("nfceAtivo", value);
      this.set("empresa.habilitaNfce", value);
      return this.toggleDocumentoFiscal(this.$("#form-nfce"), value, callback);
    },
    submeterFormulario: function submeterFormulario() {
      var metodo, modoEdicao, self;
      self = this;
      modoEdicao = this.get("modoEdicao");

      if (modoEdicao) {
        metodo = this.get("editarEmpresa");
      } else {
        metodo = this.get("cadastrarEmpresa");
      }

      this.indicarSubmitEmProgresso(true);
      return metodo(this, {
        empresa: this.get("empresa")
      }, function (sucesso, dados, httpCode) {
        var mensagem;
        self.indicarSubmitEmProgresso(false);

        if (sucesso) {
          if (modoEdicao) {
            mensagem = "Alterações salvas com sucesso!";
          } else {
            mensagem = "Empresa criada com sucesso!";
          }

          if (!self.get("novaLogoAnexada")) {
            return self.mostrarMensagemSucesso(mensagem, function () {
              return setTimeout(function () {
                return self.sendAction("actionOnFormularioSubmetido");
              }, 3000);
            });
          } else {
            return self.fazerUploadLogo(self, {
              arquivo: self.get("arquivoLogoAnexada"),
              empresa: self.get("empresa")
            }, function (sucesso, data, httpCode) {
              if (sucesso) {
                return self.mostrarMensagemSucesso(mensagem, function () {
                  return setTimeout(function () {
                    return self.sendAction("actionOnFormularioSubmetido");
                  }, 3000);
                });
              } else {
                return self.mostrarMensagensErro("Ocorreu um problema ao anexar a logo.", {}, function () {});
              }
            });
          }
        } else {
          if (modoEdicao) {
            mensagem = "Ocorreu um erro ao salvar os dados da empresa.";
          } else {
            mensagem = "Ocorreu um erro na criação da empresa.";
          }

          return self.mostrarMensagensErro(mensagem, {}, function () {});
        }
      });
    },
    indicarSubmitEmProgresso: function indicarSubmitEmProgresso(indicar) {
      var spinner;
      this.$(":submit").attr("disabled", indicar);
      spinner = this.$(":submit").find("i");

      if (indicar) {
        return spinner.show();
      } else {
        return spinner.hide();
      }
    },
    onImagemAnexada: function onImagemAnexada(arquivo) {
      this.set("novaLogoAnexada", true);
      this.set("arquivoLogoAnexada", arquivo);
      return this.$("#img-logo-upload").attr("src", arquivo);
    },
    onImagemAnexadaInvalida: function onImagemAnexadaInvalida(callback) {
      var imagemAnterior;
      this.set("novaLogoAnexada", false);
      this.set("arquivoLogoAnexada", null);

      if (this.get("empresa.possuiLogo")) {
        imagemAnterior = this.get("empresa.caminhoCompletoLogo");
      } else {
        imagemAnterior = "imagens/business-logo.png";
      }

      this.$("#img-logo-upload").attr("src", imagemAnterior);
      return alert("Formato inválido.");
    },
    actions: {
      actToggleFormNfe: function actToggleFormNfe(value, callback) {
        return this.toggleFormNfe(value, callback);
      },
      actToggleFormNfse: function actToggleFormNfse(value, callback) {
        return this.toggleFormNfse(value, callback);
      },
      actToggleFormNfce: function actToggleFormNfce(value, callback) {
        return this.toggleFormNfce(value, callback);
      },
      actValidarCnpj: function actValidarCnpj(params, callbackOnAjaxComplete) {
        return this.validarCnpj(params, callbackOnAjaxComplete);
      },
      actAtribuirCnpjPosValidacao: function actAtribuirCnpjPosValidacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("cnpj", params, callbackOnFimValidacao);
      },
      actAtribuirRazaoSocial: function actAtribuirRazaoSocial(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("razaoSocial", params, callbackOnFimValidacao);
      },
      actAtribuirFantasia: function actAtribuirFantasia(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("fantasia", params, callbackOnFimValidacao);
      },
      actAtribuirInscricaoEstadual: function actAtribuirInscricaoEstadual(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("inscricaoEstadual", params, callbackOnFimValidacao);
      },
      actAtribuirInscricaoMunicipal: function actAtribuirInscricaoMunicipal(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("inscricaoMunicipal", params, callbackOnFimValidacao);
      },
      actAtribuirRegimeTributario: function actAtribuirRegimeTributario(regime) {
        return this.atribuirRegimeTributario(regime);
      },
      actAtribuirEmailContato: function actAtribuirEmailContato(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("email", params, callbackOnFimValidacao);
      },
      actAtribuirTelefoneContato: function actAtribuirTelefoneContato(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("telefone", params, callbackOnFimValidacao);
      },
      actAtribuirCepPosValidacao: function actAtribuirCepPosValidacao(params, callbackOnFimValidacao) {
        return this.atribuirCepPosValidacao(params, callbackOnFimValidacao);
      },
      actAtribuirNumeroEndereco: function actAtribuirNumeroEndereco(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("numeroEndereco", params, callbackOnFimValidacao);
      },
      actAtribuirComplemento: function actAtribuirComplemento(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("complemento", params, callbackOnFimValidacao);
      },
      actAtribuirCpfResponsavel: function actAtribuirCpfResponsavel(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("cpfResponsavel", params, callbackOnFimValidacao);
      },
      actAtribuirNomeResponsavel: function actAtribuirNomeResponsavel(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("nomeResponsavel", params, callbackOnFimValidacao);
      },
      actAtribuirSenhaReceitaPR: function actAtribuirSenhaReceitaPR(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("senhaReceitaPR", params, callbackOnFimValidacao);
      },
      actAtribuirCnpjCpfContabilidade: function actAtribuirCnpjCpfContabilidade(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("cpfCnpjContabilidade", params, callbackOnFimValidacao);
      },
      actAtribuirNomeContabilidade: function actAtribuirNomeContabilidade(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("nomeContabilidade", params, callbackOnFimValidacao);
      },
      actAtribuirTelefoneContabilidade: function actAtribuirTelefoneContabilidade(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("telefoneContabilidade", params, callbackOnFimValidacao);
      },
      actAtribuirEmailContabilidade: function actAtribuirEmailContabilidade(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("emailContabilidade", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfeHomologacao: function actAtribuirSerieNfeHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfeHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirProximoNumeroNfeHomologacao: function actAtribuirProximoNumeroNfeHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoNumeroNfeHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfe: function actAtribuirSerieNfe(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfe", params, callbackOnFimValidacao);
      },
      actAtribuirNumeroNfe: function actAtribuirNumeroNfe(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoNumeroNfe", params, callbackOnFimValidacao);
      },
      actAtribuirProximoRpsHomologacao: function actAtribuirProximoRpsHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoRpsHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirProximoRps: function actAtribuirProximoRps(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoRps", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfse: function actAtribuirSerieNfse(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfse", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfseHomologacao: function actAtribuirSerieNfseHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfseHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirIdTokenCsc: function actAtribuirIdTokenCsc(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("idToken", params, callbackOnFimValidacao);
      },
      actAtribuirIdTokenHomologacao: function actAtribuirIdTokenHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("idTokenHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirSerieCsc: function actAtribuirSerieCsc(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieCsc", params, callbackOnFimValidacao);
      },
      actAtribuirNumeroCsc: function actAtribuirNumeroCsc(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("numeroCsc", params, callbackOnFimValidacao);
      },
      actAtribuirNumeroCscHomologacao: function actAtribuirNumeroCscHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("numeroCscHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirProximoNumeroNfce: function actAtribuirProximoNumeroNfce(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoNumeroNfce", params, callbackOnFimValidacao);
      },
      actAtribuirProximoNumeroNfceHomologacao: function actAtribuirProximoNumeroNfceHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("proximoNumeroNfceHomologacao", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfce: function actAtribuirSerieNfce(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfce", params, callbackOnFimValidacao);
      },
      actAtribuirSerieNfceHomologacao: function actAtribuirSerieNfceHomologacao(params, callbackOnFimValidacao) {
        return this.atribuirCampoPosValidacao("serieNfceHomologacao", params, callbackOnFimValidacao);
      },
      actOnImagemAnexada: function actOnImagemAnexada(imagem, callback) {
        this.onImagemAnexada(imagem);
        return callback();
      },
      actOnImagemAnexadaInvalida: function actOnImagemAnexadaInvalida(callback) {
        this.onImagemAnexadaInvalida();
        return callback();
      }
    }
  });

  _exports.default = _default;
});