define("api-dashboard/routes/pos-reports/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    actions: {
      afterSave: function afterSave(posReport) {
        return this.transitionTo('pos-reports/edit');
      },
      onCancel: function onCancel(posReport) {
        return this.transitionTo('pos-reports/edit');
      }
    }
  });

  _exports.default = _default;
});