define("api-dashboard/models/saas-cnpj-band-price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    from: _emberData.default.attr('number'),
    to: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    _destroy: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    saasPrice: _emberData.default.belongsTo('saas-price')
  });

  _exports.default = _default;
});