define("api-dashboard/models/sale", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    received: new Big(0),
    toReceive: new Big(0),
    totalSalePrice: new Big(0),
    discountReais: new Big(0),
    cart: null,
    currentEntryType: null,
    cancelMotive: _emberData.default.attr('string'),
    cancelTimestamp: _emberData.default.attr('date'),
    cashDrawerOperatorId: _emberData.default.attr('number'),
    cashSale: _emberData.default.attr('boolean'),
    ccf: _emberData.default.attr('number'),
    chaveNfce: _emberData.default.attr('string'),
    chaveSat: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    consumerNfNumber: _emberData.default.attr('string'),
    consumerNfSerie: _emberData.default.attr('string'),
    coo: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    credit: _emberData.default.attr('big'),
    danfeNfcePath: _emberData.default.attr('string'),
    danfePath: _emberData.default.attr('string'),
    dataHoraProtocoloNfce: _emberData.default.attr('date'),
    descricaoNfce: _emberData.default.attr('string'),
    didCancelFiscalDocument: _emberData.default.attr('boolean'),
    discount: _emberData.default.attr('big'),
    donationAllowed: _emberData.default.attr('boolean'),
    fingerprint: _emberData.default.attr('string'),
    freightType: _emberData.default.attr('number'),
    freightValue: _emberData.default.attr('number'),
    isWebNfce: _emberData.default.attr('boolean'),
    netValue: _emberData.default.attr('big'),
    nfceContingencia: _emberData.default.attr('boolean'),
    nfceContingenciaEfetivada: _emberData.default.attr('boolean'),
    nfceStatus: _emberData.default.attr('string'),
    nfeKey: _emberData.default.attr('string'),
    nfeNumber: _emberData.default.attr('string'),
    nfeSerie: _emberData.default.attr('string'),
    nfeStatus: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    numeroCfeSat: _emberData.default.attr('number'),
    numeroNfce: _emberData.default.attr('number'),
    originalId: _emberData.default.attr('number'),
    protocoloNfce: _emberData.default.attr('string'),
    receipts: _emberData.default.attr(),
    sefazMessage: _emberData.default.attr('string'),
    sefazStatus: _emberData.default.attr('string'),
    serieNfce: _emberData.default.attr('number'),
    soldAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string', {
      alwaysDirty: 'true'
    }),
    taxCouponCnpj: _emberData.default.attr('string'),
    taxCouponCpf: _emberData.default.attr('string'),
    taxCouponName: _emberData.default.attr('string'),
    totalCanceled: _emberData.default.attr('big'),
    totalCostReturned: _emberData.default.attr('big'),
    totalCostSold: _emberData.default.attr('big'),
    totalPaid: _emberData.default.attr('big'),
    totalPriceReturned: _emberData.default.attr('big'),
    totalPriceSold: _emberData.default.attr('big'),
    totalReimbursement: _emberData.default.attr('big'),
    updatedAt: _emberData.default.attr('date'),
    urlXmlCancelamentoNfce: _emberData.default.attr('string'),
    urlXmlNfce: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    xmlPath: _emberData.default.attr('string'),
    cancelPosUser: _emberData.default.belongsTo('pos-user'),
    customer: _emberData.default.belongsTo('customer'),
    domain: _emberData.default.belongsTo('domain'),
    fStore: _emberData.default.belongsTo('f-store'),
    pos: _emberData.default.belongsTo('pos'),
    posEntries: _emberData.default.hasMany('pos-entry', {
      async: false
    }),
    posUser: _emberData.default.belongsTo('pos-user'),
    returnedItems: _emberData.default.hasMany('trade-item', {
      async: false
    }),
    saleType: _emberData.default.belongsTo('sale-type'),
    soldItems: _emberData.default.hasMany('trade-item', {
      async: false
    }),
    currentTotal: Ember.computed('soldItems.@each', function () {
      var reduceFn;

      reduceFn = function reduceFn(sum, item) {
        return sum.add(item.get('price').times(item.get('quantity')).round(2));
      };

      return this.get('soldItems').reduce(reduceFn, new Big(0));
    }),
    currentToReceive: Ember.computed('posEntries.@each', 'soldItems.@each', 'discountReais', function () {
      return this.get('currentTotal').sub(this.get('received')).sub(this.get('discountReais'));
    }),
    pendingValuesToReceive: Ember.computed('currentToReceive', function () {
      return this.get('currentToReceive').gt(0);
    }),
    numberFormatted: Ember.computed('number', function () {
      var num_str;

      if (this.get('number')) {
        num_str = this.get('number').toString();
        return num_str.substring(0, num_str.length - 1) + '-' + num_str.substring(num_str.length - 1, num_str.length);
      }
    }),
    numeroNfceFormatted: Ember.computed('numeroNfce', 'serieNfce', function () {
      if (!this.get('numeroNfce')) {
        return '';
      }

      return this.get('numeroNfce') + '/' + this.get('serieNfce');
    }),
    isCanceled: Ember.computed('status', function () {
      return this.get('status') === 'canceled' || this.get('status') === 'canceled_pos';
    }),
    isUnfinished: Ember.computed('status', function () {
      return this.get('status') === 'unfinished';
    }),
    isRealized: Ember.computed('status', function () {
      return this.get('status') === 'realized';
    }),
    canDownloadXml: Ember.computed('status', function () {
      return this.get('status') === 'realized' || this.get('isCanceled');
    }),
    isFiscal: Ember.computed('coo', 'ccf', function () {
      return this.get('coo') && this.get('ccf');
    }),
    isNFCe: Ember.computed('numeroNfce', function () {
      return this.get('numeroNfce');
    }),
    isNFCeNormal: Ember.computed('isNFCe', 'nfceContingencia', function () {
      return this.get('isNFCe') && !this.get('nfceContingencia');
    }),
    isNFCeContingenciaEfetivada: Ember.computed('numeroNfce', 'nfceContingencia', 'nfceContingenciaEfetivada', function () {
      return this.get('isNFCe') && this.get('nfceContingencia') && this.get('nfceContingenciaEfetivada');
    }),
    isNFCeContingenciaNaoEfetivada: Ember.computed('numeroNfce', 'nfceContingencia', 'nfceContingenciaEfetivada', function () {
      return this.get('isNFCe') && this.get('nfceContingencia') && !this.get('nfceContingenciaEfetivada');
    }),
    isSAT: Ember.computed('numeroCfeSat', function () {
      return this.get('numeroCfeSat');
    }),
    isNotaManual: Ember.computed('consumerNfNumber', function () {
      return this.get('consumerNfNumber');
    }),
    semDocumentoFiscal: Ember.computed('isFiscal', 'isNFCe', 'isSAT', 'isNotaManual', function () {
      return !this.get('isFiscal') && !this.get('isNFCe') && !this.get('isSAT') && !this.get('isNotaManual');
    }),
    statusFormatted: Ember.computed('status', function () {
      var statusValues;
      statusValues = {
        'realized': 'Realizada',
        'canceled': 'Cancelada',
        'canceled_pos': 'Cancelada no PDV',
        'unfinished': 'Não finalizada'
      };

      try {
        return statusValues[this.get('status')];
      } catch (error) {
        return '';
      }
    }),
    paymentMethodText: Ember.computed('cashSale', function () {
      if (this.get('cashSale')) {
        return 'A Vista';
      } else {
        return 'A Prazo';
      }
    }),
    customerNameOrConsumer: Ember.computed('customer', function () {
      if (this.get('customer')) {
        return this.get('customer.name');
      } else {
        return 'Venda ao Consumidor';
      }
    }),
    discountReaisObserver: Ember.observer('discountReais', function () {
      if (!this.get('changingDiscount')) {
        if (this.get('discountReais') != null && this.get('discountReais').gt(this.get('currentTotal').sub(this.get('received')))) {
          alert('Desconto não pode ser maior do que o total a receber');
          return this.set('discountReais', new Big(0));
        }
      }
    }),
    isCheckoutCash: Ember.computed('currentEntryType', function () {
      return this.get('currentEntryType') === 'cash';
    }),
    isCheckoutMoneyCheck: Ember.computed('currentEntryType', function () {
      return this.get('currentEntryType') === 'check';
    }),
    isCheckoutCard: Ember.computed('currentEntryType', function () {
      return this.get('currentEntryType') === 'card';
    }),
    isCheckoutCarnet: Ember.computed('currentEntryType', function () {
      return this.get('currentEntryType') === 'carnet';
    }),
    formattedCurrentEntryType: Ember.computed('currentEntryType', function () {
      switch (this.get('currentEntryType')) {
        case 'cash':
          return 'Dinheiro';

        case 'check':
          return 'Cheque';

        case 'card':
          return 'Cartão';

        case 'carnet':
          return 'Carnê';
      }
    }),
    hasError: Ember.computed('status', function () {
      return this.get('isCanceled') || this.get('isUnfinished') || ['erro_autorizacao', 'erro_cancelamento', 'denegada'].includes('status');
    }),
    totalSalePriceObserver: Ember.observer('soldItems.@each.quantity', 'soldItems.@each.price', function () {
      var discount, sumMethod, toReceive, totalSalePrice;

      sumMethod = function sumMethod(sum, entry) {
        var entryPrice;

        if (entry) {
          entryPrice = Ember.get(entry, 'totalPrice') || 0;
          return sum.add(new Big(entryPrice));
        }

        return sum.add(new Big(0));
      };

      totalSalePrice = this.get('soldItems').reduce(sumMethod, new Big(0));
      this.set('totalSalePrice', totalSalePrice);
      discount = this.get('discount') || new Big(0);
      toReceive = totalSalePrice.sub(this.get('received')).sub(discount);
      return this.set('toReceive', toReceive);
    }),
    receivedObserver: Ember.observer('posEntries.@each.value', function () {
      var discount, received, sumMethod, toReceive;

      sumMethod = function sumMethod(sum, item) {
        return sum.add(item.get('value') || 0);
      };

      received = this.get('posEntries').reduce(sumMethod, new Big(0));
      this.set('received', received);
      discount = this.get('discount') || new Big(0);
      toReceive = this.get('totalSalePrice').sub(this.get('received')).sub(discount);

      if (toReceive.lte(new Big(0))) {
        toReceive = new Big(0);
      }

      return this.set('toReceive', toReceive);
    }),
    moneyBackObserver: Ember.observer('posEntries.@each.value', 'soldItems.@each.quantity', 'toReceive', function () {
      var moneyBack, sumMethod;

      sumMethod = function sumMethod(sum, entry) {
        return sum.add(new Big(entry.get('value') || 0));
      };

      moneyBack = this.get('posEntries').reduce(sumMethod, new Big(0));
      moneyBack = moneyBack.sub(this.get('totalSalePrice'));

      if (moneyBack.lte(0)) {
        moneyBack = new Big(0);
      }

      return this.set('moneyBack', moneyBack);
    }),
    save: function save() {
      this.__normalizeModel();

      return this._super();
    },
    __normalizeModel: function __normalizeModel() {
      var self;
      self = this;
      return this.get('soldItems').forEach(function (item, index) {
        item.set('cost', item.get('product.averageCost'));
        item.set('number', index + 1);
        item.set('taxType', 'I');
        item.set('taxRate', 0);
        item.set('promotionId', null);
        item.set('status', 'realized');
        return item.set('pos', self.get('pos'));
      });
    },
    validate: function validate() {
      var errors;
      errors = this.get('errors');
      errors.clear();

      if (!this.get('posUser.id')) {
        errors.add('posUser', 'O Vendedor precisa ser preenchido!');
      }

      if (!this.get('pos.id')) {
        errors.add('pos', 'O Ponto de Venda precisa ser preenchido!');
      }

      if (this.get('soldItems').length === 0) {
        errors.add('soldItems', 'Os itens da venda precisam ser preenchidos!');
      }

      if (this.get('posEntries').length === 0) {
        errors.add('posEntries', 'Os Pagamentos da venda precisam ser preenchidos!');
      }

      return errors.length === 0;
    }
  });

  _exports.default = _default;
});