define("api-dashboard/components/invoices/saas-contracts/saas-contracts-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    title: 'Contrato',
    titlePlural: 'Contratos',
    contracts: [],
    searching: true,
    offset: 0,
    limit: 6,
    append: false,
    totalRecords: 0,
    onlyActive: true,
    showNotActive: false,
    init: function init(args) {
      this.set('append', false);
      this.set('showNotActive', false);
      this.get_contracts(false);
      return this._super(args);
    },
    get_contracts: function get_contracts() {
      var params, self;
      self = this;
      self.set('searching', true);
      params = {
        offset: this.get('offset'),
        limit: this.get('limit'),
        onlyActive: this.get('onlyActive')
      };
      return this.get('store').query('saas-contract', params).then(function (result) {
        var objects;
        self.set('totalRecords', result.get('meta.total'));
        objects = self.get('contracts');

        if (self.get('append')) {
          objects.addObjects(result.toArray());
        } else {
          objects = result.toArray();
        }

        self.set('contracts', objects);
        return self.set('searching', false);
      });
    },
    loadMoreContracts: function loadMoreContracts() {
      this.set('append', true);
      this.set('offset', this.get('offset') + this.get('limit'));
      return this.get_contracts();
    },
    cancelContract: function cancelContract(contract) {
      var self;
      self = this;

      if (contract.status === 'C') {
        return alert('Contrato já consta como cancelado');
      } else {
        self.set('searching', true);
        return contract.save().then(function () {
          self.set('contracts', []);
          alert('Contrato cancelado');
          return self.get_contracts();
        })["catch"](function (e) {
          alert(e.errors[0]);
          return self.set('searching', false);
        });
      }
    },
    toggleContract: function toggleContract(value, callback) {
      this.set('contracts', []);
      this.set('offset', 0);
      this.set('limit', 6);

      if (this.get('showNotActive') === false) {
        this.set('showNotActive', true);
        this.set('onlyActive', false);
      } else {
        this.set('showNotActive', false);
        this.set('onlyActive', true);
      }

      return this.get_contracts();
    },
    hasRecords: Ember.computed('totalRecords', 'limit', 'offset', function () {
      return this.get('totalRecords') - this.get('limit') - this.get('offset') > 0;
    }),
    actions: {
      actToggleFormContract: function actToggleFormContract(value, callback) {
        return this.toggleContract(value, callback);
      }
    }
  });

  _exports.default = _default;
});