define("api-dashboard/utils/acesso-pis-cofins", ["exports", "api-dashboard/utils/acesso"], function (_exports, _acesso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _acesso.default.extend({
    item: null,
    acessoType: null,
    situacaoTributaria: Ember.computed('item', 'item.pisSituacaoTributaria', 'item.cofinsSituacaoTributaria', function (key, value) {
      var situacaoTributariaFN;
      situacaoTributariaFN = 'item.' + this.get('acessoType') + 'SituacaoTributaria';

      if (this.get('item') && this.get(situacaoTributariaFN)) {
        return this.get(situacaoTributariaFN);
      }

      if (arguments.length > 1) {
        return value;
      }

      return '';
    }),
    situacoesTributariasOutros: ['49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
    acessos: {
      baseCalculo: {
        mostrar: ['01', '02', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: ['01', '02']
      },
      aliquotaPorcentual: {
        mostrar: ['01', '02', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: ['01', '02']
      },
      quantidadeVendida: {
        mostrar: ['03', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: ['03']
      },
      aliquotaValor: {
        mostrar: ['03', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: ['03']
      },
      valor: {
        mostrar: ['01', '02', '03', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: ['01', '02', '03', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99']
      },
      st: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      },
      baseCalculoSt: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      },
      aliquotaPorcentualSt: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      },
      quantidadeVendidaSt: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      },
      aliquotaValorSt: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      },
      valorSt: {
        mostrar: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '49', '50', '51', '52', '53', '54', '55', '56', '60', '61', '62', '63', '64', '65', '66', '67', '70', '71', '72', '73', '74', '75', '98', '99'],
        obrigar: []
      }
    },
    mostrarBaseCalculo: false,
    obrigarBaseCalculo: false,
    mostrarAliquotaPorcentual: false,
    obrigarAliquotaPorcentual: false,
    mostrarQuantidadeVendida: false,
    obrigarQuantidadeVendida: false,
    mostrarAliquotaValor: false,
    obrigarAliquotaValor: false,
    mostrarValor: false,
    obrigarValor: false,
    mostrarSt: false,
    mostrarBaseCalculoSt: false,
    obrigarBaseCalculoSt: false,
    mostrarAliquotaPorcentualSt: false,
    obrigarAliquotaPorcentualSt: false,
    mostrarQuantidadeVendidaSt: false,
    obrigarQuantidadeVendidaSt: false,
    mostrarAliquotaValorSt: false,
    obrigarAliquotaValorSt: false,
    mostrarValorSt: false,
    obrigarValorSt: false,
    updateAcessos: Ember.observer('item', 'situacaoTributaria', function () {
      var situacaoTributaria;
      situacaoTributaria = this.get('situacaoTributaria');
      this.set('mostrarBaseCalculo', this.mostrar(situacaoTributaria, 'baseCalculo'));
      this.set('obrigarBaseCalculo', this.obrigar(situacaoTributaria, 'baseCalculo'));
      this.set('mostrarAliquotaPorcentual', this.mostrar(situacaoTributaria, 'aliquotaPorcentual'));
      this.set('obrigarAliquotaPorcentual', this.obrigar(situacaoTributaria, 'aliquotaPorcentual'));
      this.set('mostrarQuantidadeVendida', this.mostrar(situacaoTributaria, 'quantidadeVendida'));
      this.set('obrigarQuantidadeVendida', this.obrigar(situacaoTributaria, 'quantidadeVendida'));
      this.set('mostrarAliquotaValor', this.mostrar(situacaoTributaria, 'aliquotaValor'));
      this.set('obrigarAliquotaValor', this.obrigar(situacaoTributaria, 'aliquotaValor'));
      this.set('mostrarValor', this.mostrar(situacaoTributaria, 'valor'));
      this.set('obrigarValor', this.obrigar(situacaoTributaria, 'valor'));
      this.set('mostrarSt', this.mostrar(situacaoTributaria, 'st'));
      this.set('mostrarBaseCalculoSt', this.mostrar(situacaoTributaria, 'baseCalculoSt'));
      this.set('obrigarBaseCalculoSt', this.obrigar(situacaoTributaria, 'baseCalculoSt'));
      this.set('mostrarAliquotaPorcentualSt', this.mostrar(situacaoTributaria, 'aliquotaPorcentualSt'));
      this.set('obrigarAliquotaPorcentualSt', this.obrigar(situacaoTributaria, 'aliquotaPorcentualSt'));
      this.set('mostrarQuantidadeVendidaSt', this.mostrar(situacaoTributaria, 'quantidadeVendidaSt'));
      this.set('obrigarQuantidadeVendidaSt', this.obrigar(situacaoTributaria, 'quantidadeVendidaSt'));
      this.set('mostrarAliquotaValorSt', this.mostrar(situacaoTributaria, 'aliquotaValorSt'));
      this.set('obrigarAliquotaValorSt', this.obrigar(situacaoTributaria, 'aliquotaValorSt'));
      this.set('mostrarValorSt', this.mostrar(situacaoTributaria, 'valorSt'));
      return this.set('obrigarValorSt', this.obrigar(situacaoTributaria, 'valorSt'));
    })
  });

  _exports.default = _default;
});