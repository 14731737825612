define("api-dashboard/components/inputs/product/product-list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    field: null,
    classNames: [],
    colClasses: Ember.computed('field', function () {
      var classes, field;
      field = this.get('field');
      classes = [];

      if (field === 'price' || field === 'itemPrice' || field === 'quantity' || field === 'totalPrice' || field === 'averageCost' || field === 'totalAverageCost') {
        classes.push('text-right');
      }

      return classes.join(" ");
    }),
    label: Ember.computed('field', function () {
      var field;
      field = this.get('field');

      switch (field) {
        case 'brand':
          return 'Marca';

        case 'code':
          return 'Ref.';

        case 'description':
          return 'Descrição';

        case 'price':
          return 'Preço Un.';

        case 'itemPrice':
          return 'Preço Un.';

        case 'quantity':
          return 'Qtd.';

        case 'totalPrice':
          return 'Total';

        case 'averageCost':
          return 'Custo';

        case 'totalAverageCost':
          return 'Custo Total';

        case 'priceWithoutDiscount':
          return 'Preço Atual';

        case 'priceWithDiscount':
          return 'Novo Preço';

        case 'promotionItemLabel':
          return 'Item';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});