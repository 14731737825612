define("api-dashboard/templates/components/inputs/product/product-list-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xmQJnMUA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isBig\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[22,\"colClasses\"]],[8],[1,[28,\"format/big\",[[24,[\"value\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isBigEditable\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-money\",null,[[\"val\"],[[24,[\"item\",\"price\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isInputInt\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"editable\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inputs/input-number\",null,[[\"val\",\"class\",\"min\"],[[24,[\"item\",\"quantity\"]],\"text-right\",1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[22,\"colClasses\"]],[8],[1,[24,[\"item\",\"quantity\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isDefaultType\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[22,\"colClasses\"]],[8],[1,[22,\"value\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/product/product-list-body.hbs"
    }
  });

  _exports.default = _default;
});