define("api-dashboard/models/contract", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    status: _emberData.default.attr('string', {
      defaultValue: 'A'
    }),
    activeFrom: _emberData.default.attr('date'),
    activeTo: _emberData.default.attr('date'),
    cancelAvoidable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    cancelMotive: _emberData.default.attr('string'),
    contractType: _emberData.default.attr('string'),
    nfceEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    apiEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    saasPrice: _emberData.default.belongsTo('saasPrice', {
      inverse: 'contracts'
    }),
    customer: _emberData.default.belongsTo('customer', {
      inverse: 'contracts'
    }),
    emitentes: _emberData.default.hasMany('emitente', {
      inverse: 'contract'
    }),
    receivables: _emberData.default.hasMany('receivable', {
      inverse: 'contract'
    }),
    stores: _emberData.default.hasMany('f_store', {
      inverse: 'contract'
    }),
    storeId: _emberData.default.attr('number'),
    fStore: Ember.computed('stores.length', {
      get: function get() {
        return this.get('stores').objectAt(0);
      },
      set: function set(key, value) {
        this.set('storeId', value.get('id'));
        return value;
      }
    }),
    contractTypeDescription: Ember.computed('contractType', function () {
      var contractType, elm;
      contractType = this.get('contractType');
      elm = this.get('constants').contractTypes.find(function (ct) {
        return ct.value === contractType;
      });

      if (elm != null) {
        return elm.description;
      }

      return '';
    }),
    statusDescription: Ember.computed('status', function () {
      var elm, status;
      status = this.get('status');
      elm = this.get('constants').contractStatusValues.find(function (ct) {
        return ct.value === status;
      });

      if (elm != null) {
        return elm.description;
      }

      return '';
    }),
    showStore: Ember.computed('contractType', function () {
      return this.get('isLojas') || this.get('isTelaNFCe');
    }),
    isLojas: Ember.computed('contractType', function () {
      return this.get('contractType') === 'LOJAS';
    }),
    isTelaNFCe: Ember.computed('contractType', function () {
      return this.get('contractType') === 'TELANFCE';
    }),
    isRevendaNfe: Ember.computed('contractType', function () {
      return this.get('contractType') === 'REVENDANFE';
    }),
    usaEmitentes: Ember.computed('contractType', function () {
      var contractType;
      contractType = this.get('contractType');
      return contractType === 'DFE' || contractType === 'REVENDANFE' || contractType === 'CTE' || contractType === 'DFE' || contractType === 'MDE' || contractType === 'APINFCE';
    }),
    statusCancelled: Ember.computed('status', function () {
      return this.get('status') === 'C';
    }),
    emitentesText: Ember.computed('emitentes.length', function () {
      var text;
      text = [];
      this.get('emitentes').forEach(function (emitente) {
        var str;
        str = emitente.get('nome') + " [" + emitente.get('cnpj') + "]";
        return text.push(str);
      });
      return text.join('<br/>');
    }),
    addEmitente: function addEmitente(e) {
      if (this.get('emitentes') === null) {
        this.set('emitentes', []);
      }

      return this.get('emitentes').pushObject(e);
    },
    validate: function validate() {
      var errors;
      errors = this.get('errors');
      errors.clear();

      if (!this.get('customer')) {
        errors.add('customer', 'precisa ser fornecido');
      }

      if (!this.get('activeFrom')) {
        errors.add('activeFrom', 'precisa ser fornecida');
      }

      if (!this.get('contractType')) {
        errors.add('contractType', 'precisa ser fornecido');
      }

      if (!this.get('status')) {
        return errors.add('status', 'precisa ser fornecida');
      }
    }
  });

  _exports.default = _default;
});