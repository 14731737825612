define("api-dashboard/routes/invoices/show", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').findRecord('lancamento', params['id']);
    },
    actions: {
      cancel: function cancel() {
        return this.transitionTo("invoices/index");
      }
    }
  });

  _exports.default = _default;
});