define("api-dashboard/mixins/utils", ["exports", "api-dashboard/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_fileSaver.default, {
    store: Ember.inject.service(),
    validarCPF: function validarCPF(cpf) {
      var add, i, rev;

      if (!cpf) {
        return false;
      }

      cpf = new String(cpf);
      cpf = cpf.replace(/[^\d]+/g, '');

      if (cpf === '') {
        return false;
      }

      if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') {
        return false;
      }

      add = 0;
      i = 0;

      while (i < 9) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
        i++;
      }

      rev = 11 - add % 11;

      if (rev === 10 || rev === 11) {
        rev = 0;
      }

      if (rev !== parseInt(cpf.charAt(9))) {
        return false;
      }

      add = 0;
      i = 0;

      while (i < 10) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
        i++;
      }

      rev = 11 - add % 11;

      if (rev === 10 || rev === 11) {
        rev = 0;
      }

      if (rev !== parseInt(cpf.charAt(10))) {
        return false;
      }

      return true;
    },
    validarCNPJ: function validarCNPJ(cnpj) {
      var digitos, i, numeros, pos, resultado, soma, tamanho;

      if (!cnpj) {
        return false;
      }

      cnpj = new String(cnpj).replace(/[^\d]+/g, '');

      if (cnpj === '') {
        return false;
      }

      if (cnpj.length !== 14) {
        return false;
      }

      if (cnpj === '00000000000000' || cnpj === '11111111111111' || cnpj === '22222222222222' || cnpj === '33333333333333' || cnpj === '44444444444444' || cnpj === '55555555555555' || cnpj === '66666666666666' || cnpj === '77777777777777' || cnpj === '88888888888888' || cnpj === '99999999999999') {
        return false;
      }

      tamanho = cnpj.length - 2;
      numeros = cnpj.substring(0, tamanho);
      digitos = cnpj.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;
      i = tamanho;

      while (i >= 1) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
          pos = 9;
        }

        i--;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      i = tamanho;

      while (i >= 1) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
          pos = 9;
        }

        i--;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado !== parseInt(digitos.charAt(1))) {
        return false;
      }

      return true;
    },
    validarTelefone: function validarTelefone(telefone) {
      var length;

      if (!telefone) {
        return false;
      }

      telefone = new String(telefone);
      length = telefone.length;
      return !isNaN(telefone) && length <= 11 && length > 9;
    },
    validarCEP: function validarCEP(cep) {
      var length;

      if (!cep) {
        return false;
      }

      cep = new String(cep);
      length = cep.length;
      return !isNaN(cep) && length === 8;
    },
    validarEmail: function validarEmail(email) {
      var expressaoRegular;

      if (!email) {
        return false;
      }

      email = new String(email);
      expressaoRegular = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{2})?)$/i;
      return expressaoRegular.test(email);
    },
    intForArray: function intForArray(numInt) {
      var i, retorno;
      retorno = [];

      if (!numInt) {
        return retorno;
      }

      numInt = parseInt(numInt);
      i = 1;

      while (i <= numInt) {
        retorno.push(i);
        i++;
      }

      return retorno;
    },
    moverScrollComAnimacao: function moverScrollComAnimacao(target, params) {
      if (target == null) {
        target = 0;
      }

      if (params == null) {
        params = {};
      }

      if (target !== 0) {
        target = target.offset().top;
      }

      return $("html, body").animate({
        scrollTop: target
      }, "slow");
    },
    fileToBase64: function fileToBase64(params, callback) {
      var e, exception, fr;
      exception = false;

      try {
        fr = new FileReader();
        fr.readAsDataURL(params["file"]);

        fr.onload = function () {
          return callback(true, fr.result);
        };

        fr.onerror = function (error) {
          return callback(false, error);
        };
      } catch (error1) {
        e = error1;
        exception = true;
      }

      if (exception) {
        return callback(false, e);
      }
    },
    intMonthToDescription: function intMonthToDescription(intMonth) {
      intMonth = parseInt(intMonth);

      switch (intMonth) {
        case 1:
          return "Janeiro";

        case 2:
          return "Fevereiro";

        case 3:
          return "Março";

        case 4:
          return "Abril";

        case 5:
          return "Maio";

        case 6:
          return "Junho";

        case 7:
          return "Julho";

        case 8:
          return "Agosto";

        case 9:
          return "Setembro";

        case 10:
          return "Outubro";

        case 11:
          return "Novembro";

        case 12:
          return "Dezembro";
      }
    },
    validarChaveNfe: function validarChaveNfe(chave44) {
      var chave, dv, i, m, multiplicadores, resto, soma_ponderada;

      if (typeof chave44 !== "string") {
        return false;
      }

      chave = chave44.replace(/[^\d]/g, '');

      if (chave.length !== 44) {
        return false;
      }

      multiplicadores = [2, 3, 4, 5, 6, 7, 8, 9];
      soma_ponderada = 0;
      i = 42;

      while (i >= 0) {
        m = 0;

        while (m < multiplicadores.length && i >= 0) {
          soma_ponderada += parseInt(chave[i]) * multiplicadores[m];
          m += 1;
          i -= 1;
        }
      }

      resto = soma_ponderada % 11;

      if (resto === 0 || resto === 1) {
        dv = 0;
      } else {
        dv = 11 - resto;
      }

      return dv === parseInt(chave[43]);
    },
    downloadURL: function downloadURL(addr, report_name, callback) {
      return this.downloadURLWithParams(addr, {}, report_name, callback);
    },
    downloadURLWithParams: function downloadURLWithParams(addr, params, report_name, callback) {
      var ajaxParams, self;
      self = this;
      ajaxParams = {
        method: 'GET',
        dataType: 'blob',
        data: params
      };
      return this.get("store").externalAjax(ajaxParams, {
        apiURL: addr
      }, function (success, content, data) {
        var content_type, disposition, fileName, filenameRegex, matches;

        if (success) {
          if (data.jqXHR.status === 204) {
            alert('O Relatório gerado não tem conteúdo.');
          } else {
            content_type = data.jqXHR.getResponseHeader('contentType');
            fileName = report_name;
            disposition = data.jqXHR.getResponseHeader('content-disposition');

            if (disposition && disposition.indexOf('filename') !== -1) {
              filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              matches = filenameRegex.exec(disposition);

              if (matches !== null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }

            self.saveFileAs(fileName, content, content_type);
          }
        } else {
          alert('Não foi possível gerar o relatório. Por favor contate a equipe de suporte.');
        }

        return callback.apply(null, arguments);
      });
    },
    scrollToElement: function scrollToElement(container, elm, duration) {
      var pos;
      pos = this._getRelativePos(elm);
      return this._scrollTo(container, pos.top, duration);
    },
    findDuplicatesInArray: function findDuplicatesInArray(propertyNames, arr) {
      var dupes, seen;
      seen = {};
      dupes = [];
      arr.forEach(function (o) {
        var key;
        Ember.set(o, '___isDuplicate', false);
        key = propertyNames.map(function (k) {
          return o.get(k);
        }).join(':');

        if (!seen[key]) {
          return seen[key] = o;
        } else {
          Ember.set(o, '___isDuplicate', true);
          return dupes.addObject(o);
        }
      });
      return dupes;
    },
    _getRelativePos: function _getRelativePos(elm) {
      var cPos, pPos, parentNode, pos;
      parentNode = elm.parents('.painelFocus__content');
      pPos = parentNode.get(0).getBoundingClientRect();
      cPos = elm.get(0).getBoundingClientRect();
      pos = {};
      pos.top = cPos.top - pPos.top + parentNode.get(0).scrollTop;
      pos.right = cPos.right - pPos.right;
      pos.bottom = cPos.bottom - pPos.bottom;
      pos.left = cPos.left - pPos.left;
      return pos;
    },
    _scrollTo: function _scrollTo(element, to, duration, onDone) {
      var _animateScroll, change, elapsed, now, self, start, startTime, t, val;

      self = this;
      start = element.get(0).scrollTop;
      change = to - start;
      startTime = performance.now();
      val = null;
      now = null;
      elapsed = null;
      t = null;

      _animateScroll = function animateScroll() {
        now = performance.now();
        elapsed = (now - startTime) / 1000;
        t = elapsed / duration;
        element.get(0).scrollTop = start + change * self._easeInOutQuad(t);

        if (t < 1) {
          return window.requestAnimationFrame(_animateScroll);
        } else {
          return onDone && onDone();
        }
      };

      return _animateScroll();
    },
    _easeInOutQuad: function _easeInOutQuad(t) {
      if (t < 0.5) {
        return 2 * t * t;
      } else {
        return -1 + (4 - 2 * t) * t;
      }
    }
  });

  _exports.default = _default;
});