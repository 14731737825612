define("api-dashboard/components/products/product-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    suppliers: Ember.computed('application.currentDomain.suppliers', function () {
      return this.get('application.currentDomain.suppliers');
    }),
    sortedValoresParametrosCriteria: ['parametro.descricao:asc'],
    sortedValoresParametros: Ember.computed.sort('model.valoresParametros', 'sortedValoresParametrosCriteria'),
    currentDomain: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain');
    }),
    shouldShowFiscalConfigs: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain.fiscalEnabled');
    }),
    optanteSimples: Ember.computed('application.currentDomain', function () {
      if (this.get('application.currentDomain')) {
        return this.get('application.currentDomain.visibleStores').isEvery('optanteSimples', true);
      }
    }),
    isNfeAndLojas: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain.isNfe') && this.get('application.currentDomain.isLojas');
    }),
    gtinEfetivo: Ember.computed('model.gtin', 'model.barcode', function () {
      if (this.get('model.gtin')) {
        if (this.get('globalLookups').isValidBarcode(this.get('model.gtin'))) {
          return this.get('model.gtin');
        } else {
          return this.get('model.gtin') + " - GTIN inválido!";
        }
      } else if (this.get('globalLookups').isValidBarcode(this.get('model.barcode'))) {
        return this.get('model.barcode') + " - código de barras já é um GTIN válido";
      } else {
        return "Sem GTIN informado";
      }
    }),
    onSubmit: function onSubmit(product) {
      return this.sendAction('afterSave', product);
    },
    actions: {
      onCancel: function onCancel(product) {
        if (product == null) {
          product = null;
        }

        return this.sendAction('onCancel', product);
      },
      activate: function activate() {
        this.set('model.active', true);
        return this.send('save');
      },
      deactivate: function deactivate() {
        var msg;
        msg = 'Tem certeza que deseja desativar este produto?';

        if (this.get('application.currentDomain.isErp')) {
          msg += ' Toda a quantidade em estoque será removida.';
        }

        if (confirm(msg)) {
          this.set('model.active', false);
          return this.send('save');
        }
      }
    }
  });

  _exports.default = _default;
});