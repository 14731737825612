define("api-dashboard/components/base/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: [],
    className: "btn btn--default btn--success",
    tagName: 'span',
    init: function init() {
      this.assignSelectedOption();
      return this._super.apply(this, arguments);
    },
    isMultiOption: Ember.computed('options.[]', function () {
      return this.get('options.length') > 1;
    }),
    optionsObserver: Ember.observer('options', function () {
      this.assignSelectedOption();
      return this.rerender();
    }),
    assignSelectedOption: function assignSelectedOption() {
      var opt;

      if (this.get('options.length') > 0) {
        opt = this.get('options').objectAt(0);
        return this.set('_selectedOption', opt);
      }
    },
    actions: {
      changeSelectedOption: function changeSelectedOption(opt) {
        return this.set('_selectedOption', opt);
      },
      _selectedAction: function _selectedAction() {
        if (this.get('_selectedOption.action')) {
          return this.get('_selectedOption').action();
        }
      }
    }
  });

  _exports.default = _default;
});