define("api-dashboard/components/transportadores/transportador-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    transporterMunicipioObserver: Ember.observer('transporterMunicipio', function () {
      var municipio;
      municipio = this.get('transporterMunicipio');
      this.get('model.defaultAddress');
      return this.set('model.defaultAddress.municipio', municipio);
    }),
    init: function init() {
      var municipio;
      municipio = this.get('model.defaultAddress.municipio');

      if (Ember.get(municipio, 'id')) {
        this.set('transporterMunicipio', municipio);
      }

      return this._super.apply(this, arguments);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      deactivate: function deactivate() {
        var self;
        self = this;

        if (confirm('Confirma a DESATIVAÇÃO da Transportadora?')) {
          this.set('model.active', false);
          return this.get('model').save().then(function (obj) {
            return self.get('application').showAlert('Transportadora desativada com sucesso!', 'success');
          }, function () {
            return self.get('application').showAlert('Erro ao desativar a Transportadora!', 'error');
          });
        }
      },
      activate: function activate() {
        var self;
        self = this;

        if (confirm('Confirma a ATIVAÇÃO da Transportadora?')) {
          this.set('model.active', true);
          return this.get('model').save().then(function (obj) {
            return self.get('application').showAlert('Transportadora ativada com sucesso!', 'success');
          }, function () {
            return self.get('application').showAlert('Erro ao ativar a Transportadora!', 'error');
          });
        }
      }
    }
  });

  _exports.default = _default;
});