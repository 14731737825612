define("api-dashboard/templates/components/inputs/tree-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/FXDnsQ",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-caret-right\"],[8],[9],[0,\" \"],[1,[24,[\"node\",\"title\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"is_expanded\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"_show_children\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"node\",\"children\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"inputs/tree-node\",null,[[\"node\",\"onRowClick\",\"class\"],[[23,1,[]],[24,[\"onRowClick\"]],\"tree-node-child\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/tree-node.hbs"
    }
  });

  _exports.default = _default;
});