define("api-dashboard/components/cancel/cancel-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    cancelReason: null,
    actions: {
      onSubmit: function onSubmit(e) {
        return e.preventDefault();
      }
    }
  });

  _exports.default = _default;
});