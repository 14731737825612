define("api-dashboard/components/lancamentos/lancamentos-filters", ["exports", "api-dashboard/components/lancamentos/lancamento-index"], function (_exports, _lancamentoIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _lancamentoIndex.default.extend({
    actions: {
      showMoreFilters: function showMoreFilters() {
        return this.set('showMoreFilters', !this.get('showMoreFilters'));
      }
    }
  });

  _exports.default = _default;
});