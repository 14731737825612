define("api-dashboard/templates/cadastro-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DxVmN9Nu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"animated fadeInUp\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"clear-50\"],[8],[9],[0,\"\\n\\t\"],[1,[28,\"grid-empresas-ativas\",null,[[\"empresas\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[7,\"br\",true],[8],[9],[0,\"\\n\\t    \"],[7,\"div\",true],[10,\"class\",\"text-left\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--default btn--primary\",\"nova-empresa\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t Adicionar Empresa\\n\"]],\"parameters\":[]},null],[0,\"\\t    \"],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t  \"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/cadastro-empresa.hbs"
    }
  });

  _exports.default = _default;
});