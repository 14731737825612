define("api-dashboard/adapters/backup", ["exports", "ember-data", "api-dashboard/adapters/application-panel", "api-dashboard/config/environment"], function (_exports, _emberData, _applicationPanel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationPanel.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var empresaId, ret;
      ret = this._super.apply(this, arguments);

      if (query.hasOwnProperty("id")) {
        empresaId = query["id"];
        delete query["id"];
        ret = ret.replace(modelName, "empresas/" + empresaId + "/" + modelName);
      }

      return ret;
    }
  });

  _exports.default = _default;
});