define("api-dashboard/components/charts/base-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    tagName: 'span',
    argumentField: void 0,
    dataSource: void 0,
    options: void 0,
    title: void 0,
    valueField: void 0,
    baseOptions: Ember.computed(function () {
      return {
        title: {
          text: this.get('title'),
          margin: {
            bottom: 30
          }
        },
        adaptiveLayout: {
          keepLabels: true,
          height: 80
        },
        argumentAxis: {
          label: {
            visible: false
          }
        },
        dataSource: this.get('dataSource'),
        legend: {
          horizontalAlignment: "right",
          margin: 0,
          verticalAlignment: "top",
          visible: this.get('showLegend')
        },
        panes: {
          backgroundColor: "none",
          border: {
            color: "#d3d3d3",
            dashStyle: "dot",
            top: true,
            right: true,
            bottom: true,
            left: true,
            visible: false,
            width: 2
          }
        },
        series: {
          argumentField: this.get('argumentField'),
          label: {
            visible: true,
            customizeText: function customizeText(point) {
              return point.argumentText + ": " + point.valueText;
            }
          },
          valueField: this.get('valueField')
        }
      };
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var self;
      self = this;

      if (this.get('dataSource')) {
        return self.get('buildComponent').call(self);
      }
    }
  });

  _exports.default = _default;
});