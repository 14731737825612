define("api-dashboard/templates/components/formularios/product-parameter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+cZ1AKE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"valorParametro\",\"parametro\",\"isBoolean\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-toggle\",null,[[\"label\",\"value\"],[[24,[\"valorParametro\",\"parametro\",\"descricao\"]],[24,[\"valorParametro\",\"valorTyped\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"valorParametro\",\"parametro\",\"isString\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\"],[[24,[\"valorParametro\",\"parametro\",\"descricao\"]],[24,[\"valorParametro\",\"valor\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"valorParametro\",\"parametro\",\"isDecimal\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-big\",null,[[\"prefix\",\"label\",\"val\",\"cols\"],[\"%\",[24,[\"valorParametro\",\"parametro\",\"descricao\"]],[24,[\"valorParametro\",\"valor\"]],12]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"valorParametro\",\"parametro\",\"isIcmsOrigem\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"options\",\"value\"],[[24,[\"valorParametro\",\"parametro\",\"descricao\"]],[24,[\"constants\",\"icmsOrigemValues\"]],[24,[\"valorParametro\",\"valor\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"valorParametro\",\"parametro\",\"isIcmsSituacaoTributaria\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"options\",\"value\",\"cols\"],[[24,[\"valorParametro\",\"parametro\",\"descricao\"]],[24,[\"constants\",\"icmsSituacaoTributariaNormalPossibleValues\"]],[24,[\"valorParametro\",\"valor\"]],4]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/formularios/product-parameter.hbs"
    }
  });

  _exports.default = _default;
});