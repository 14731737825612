define("api-dashboard/templates/components/suppliers/id-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2kZhnUyZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"isNatural\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-cpf\",null,[[\"val\",\"maxLength\",\"cols\"],[[24,[\"model\",\"cpf\"]],14,\"6\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-id\",null,[[\"val\",\"maxlength\",\"cols\"],[[24,[\"model\",\"rg\"]],11,\"6\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-select\",null,[[\"value\",\"options\",\"cols\",\"label\"],[[24,[\"model\",\"gender\"]],[24,[\"constants\",\"genders\"]],\"6\",\"Sexo\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-date\",null,[[\"val\",\"required\",\"cols\",\"label\"],[[24,[\"model\",\"birthday\"]],false,\"6\",\"Data de nascimento\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-cnpj\",null,[[\"val\",\"maxLength\",\"cols\"],[[24,[\"model\",\"cnpj\"]],18,\"6\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-ie\",null,[[\"val\",\"cols\"],[[24,[\"model\",\"inscricaoEstadual\"]],\"6\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-corporate-name\",null,[[\"val\"],[[24,[\"model\",\"razaoSocial\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/suppliers/id-form.hbs"
    }
  });

  _exports.default = _default;
});