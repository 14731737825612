define("api-dashboard/components/inputs/input-textarea", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    @Deprecated: User {{textarea}} instead. There a bug that does not bind the value typed by the User
      to the value of the {{textarea}} in this component.
   */
  var _default = _baseTextField.default.extend({
    layoutName: 'components/inputs/input-textarea'
  });

  _exports.default = _default;
});