define("api-dashboard/transforms/json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value, options) {
      return JSON.stringfy(value);
    },
    deserialize: function deserialize(value, options) {
      return JSON.parse(value);
    }
  });

  _exports.default = _default;
});