define("api-dashboard/templates/components/stores-config/stores-config-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NcliDgVX",
    "block": "{\"symbols\":[\"fStore\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Configurações das Lojas\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"class\",\"title\",\"route\",\"model\"],[\"pointer\",\"Editar\",\"stores-config/edit\",[23,1,[\"id\"]]]],{\"statements\":[[1,[23,1,[\"name\"]],false],[0,\" \"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/stores-config/stores-config-index.hbs"
    }
  });

  _exports.default = _default;
});