define("api-dashboard/components/configuracao-boleto/configuracao-boleto-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    constants: Ember.inject.service(),
    model: null
  });

  _exports.default = _default;
});