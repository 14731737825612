define("api-dashboard/adapters/config-definition", ["exports", "ember-data", "api-dashboard/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var baseUrl;
      baseUrl = this.buildURL();
      return baseUrl + "/config_definitions_values";
    }
  });

  _exports.default = _default;
});