define("api-dashboard/routes/documentos-importacao/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      itemId: {
        refreshModel: false
      }
    }
  }, {
    model: function model(params) {
      var doc;
      doc = this.get('store').createRecord('documento-importacao');
      this.get('store').findRecord('item', params['itemId']).then(function (obj) {
        return doc.set('item', obj);
      });
      return doc;
    }
  });

  _exports.default = _default;
});