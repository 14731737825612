define("api-dashboard/components/inputs/input-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["type"],
    tagName: "input",
    type: "file",
    deveValidarTipoArquivo: Ember.computed("extensoesPermitidas.length", function () {
      return this.get("extensoesPermitidas").length > 0;
    }),
    inputAccept: Ember.computed("deveValidarTipoArquivo", function () {
      if (!this.get("deveValidarTipoArquivo")) {
        return "";
      }

      return this.arrayExtensoesToInputAccept(this.get("extensoesPermitidas"));
    }),
    extensoesPermitidas: [],
    actionOnAnexadoSucesso: null,
    actionOnTipoArquivoNaoPermitido: null,
    didInsertElement: function didInsertElement() {
      if (this.get("deveValidarTipoArquivo")) {
        return this.$().attr("accept", this.get("inputAccept"));
      }
    },
    change: function change(e) {
      var callback, self;
      self = this;

      if (!(this.get("deveValidarTipoArquivo") && this.validarTipoArquivo())) {
        this.sendAction("actionOnTipoArquivoNaoPermitido", callback = function callback() {});
        return false;
      }

      return this.tratarPosAnexoSucesso();
    },
    tratarPosAnexoSucesso: function tratarPosAnexoSucesso(callback) {
      var self;

      if (callback == null) {
        callback = function callback(options) {
          if (options == null) {
            options = {};
          }
        };
      }

      self = this;
      return this.sendAction("actionOnAnexadoSucesso", this.$().prop("files")[0], callback = function callback() {
        return self.$().val("");
      });
    },
    validarTipoArquivo: function validarTipoArquivo() {
      var arquivo, arquivos, formato, split;
      arquivos = this.$().prop("files");

      if (arquivos.length === 0) {
        return false;
      }

      arquivo = arquivos[0];
      split = arquivo.name.split(".");
      formato = split[split.length - 1].toUpperCase();
      return this.get("extensoesPermitidas").indexOf(formato) > -1;
    },
    arrayExtensoesToInputAccept: function arrayExtensoesToInputAccept(arrayExtensoes) {
      var retorno;
      retorno = "";
      arrayExtensoes.forEach(function (extensao, index) {
        if (index > 0) {
          retorno = retorno + ",";
        }

        return retorno = retorno + "." + extensao;
      });
      return retorno;
    }
  });

  _exports.default = _default;
});