define("api-dashboard/models/configuracao-boleto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cobrancaRegistrada: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    localImpressao: _emberData.default.attr('string'),
    codigoCedente: _emberData.default.attr('string'),
    multaAtraso: _emberData.default.attr('number'),
    juroDiarioAtraso: _emberData.default.attr('number'),
    maxDiasAtraso: _emberData.default.attr('number'),
    proxNumero: _emberData.default.attr('number'),
    carteira: _emberData.default.attr('string'),
    variacaoCarteira: _emberData.default.attr('string'),
    domain: _emberData.default.belongsTo('domain'),
    contaBancaria: _emberData.default.belongsTo('contaBancaria')
  });

  _exports.default = _default;
});