define("api-dashboard/templates/partials/components/grid-empresas/mensagens-erro/empresas-nao-encontradas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aW08KDrq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n  \"],[7,\"strong\",true],[8],[0,\"Nenhuma empresa foi encontrada!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/grid-empresas/mensagens-erro/empresas-nao-encontradas.hbs"
    }
  });

  _exports.default = _default;
});