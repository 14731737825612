define("api-dashboard/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5I6HSGLf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"spinnerFocus\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"spinner-default-aplicacao\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});