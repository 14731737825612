define("api-dashboard/models/nota-fiscal-servico", ["exports", "ember-data", "api-dashboard/mixins/formatters", "ember-inflector"], function (_exports, _emberData, _formatters, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend(_formatters.default, (_DS$Model$extend = {
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    cancelReason: null,
    serviceCode: null,
    aliquota: _emberData.default.attr('number'),
    codigoCnae: _emberData.default.attr('string'),
    codigoTributarioMunicipio: _emberData.default.attr('string'),
    codigoItemListaServico: _emberData.default.attr('string'),
    codigoVerificacao: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    dataEmissao: _emberData.default.attr('date'),
    descontoCondicionado: _emberData.default.attr('number'),
    descontoIncondicionado: _emberData.default.attr('number'),
    discriminacao: _emberData.default.attr('string'),
    incentivoCultural: _emberData.default.attr('boolean'),
    mensagemPrefeitura: _emberData.default.attr('string'),
    naturezaOperacao: _emberData.default.attr('string'),
    numero: _emberData.default.attr('string'),
    numeroRps: _emberData.default.attr('number'),
    optanteSimplesNacional: _emberData.default.attr('boolean'),
    regimeEspecialTributacao: _emberData.default.attr('string'),
    saoPauloIssRetido: _emberData.default.attr('boolean'),
    serieRps: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    statusPrefeitura: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    valorCofins: _emberData.default.attr('number'),
    valorCsll: _emberData.default.attr('number'),
    valorDeducoes: _emberData.default.attr('number'),
    valorInss: _emberData.default.attr('number'),
    valorIr: _emberData.default.attr('number'),
    valorIss: _emberData.default.attr('number'),
    valorIssRetido: _emberData.default.attr('number'),
    valorLiquido: _emberData.default.attr('number'),
    valorPis: _emberData.default.attr('number'),
    valorServicos: _emberData.default.attr('number'),
    urlDanfse: _emberData.default.attr('string'),
    itemListaServico: _emberData.default.belongsTo('item-lista-servico'),
    prestador: _emberData.default.belongsTo('prestador', {
      inverse: 'notaFiscalServico'
    }),
    tomador: _emberData.default.belongsTo('customer'),
    integrationMappings: _emberData.default.hasMany('integration-mapping', {
      async: false
    }),
    ready: function ready() {
      var prestador;

      if (this.get('isNew')) {
        if (!!this.get('prestador')) {
          prestador = this.get('application.currentDomain.prestadores').objectAt(0);
          this.set('prestador', prestador);
          this.set('dataEmissao', new Date());
        }
      }

      if (!!this.get('codigoServicoSaoPaulo.id')) {
        this.set('serviceCode', this.get('codigoServicoSaoPaulo'));
      }

      if (!!this.get('itemListaServico.id')) {
        return this.set('serviceCode', this.get('itemListaServico'));
      }
    },
    descricaoNumero: Ember.computed('numero', function () {
      if (this.get('numero')) {
        return this.get('numero');
      }

      return this.get('intl').t('globals.messages.service_invoice_null_number');
    }),
    descricaoNaturezaOperacao: Ember.computed('naturezaOperacao', function () {
      var locOp, op;
      op = this.get('constants.nfseNaturezaOperacaoValues.abrasf').findBy('value', this.get('naturezaOperacao'));
      locOp = this.get('intl').t("constants.nfseNaturezaOperacaoValues.abrasf." + decamelize(op.value));

      if (op) {
        return op.value + " - " + locOp;
      }

      return '';
    }),
    descricaoCodigoVerificacao: Ember.computed('codigoVerificacao', function () {
      if (this.get('codigoVerificacao')) {
        return this.get('codigoVerificacao');
      }

      return this.get('intl').t('globals.messages.service_invoice_null_verification_code');
    }),
    nomeTomador: Ember.computed('tomador', function () {
      if (this.get('tomador')) {
        return this.get('tomador.name');
      }

      return '';
    }),
    nomePrestador: Ember.computed('prestador', function () {
      if (this.get('prestador')) {
        return this.get('prestador.nome');
      }

      return '';
    }),
    descricaoStatus: Ember.computed('status', function () {
      return this.get('intl').t("globals.status." + this.get('status'));
    }),
    canDelete: Ember.computed('status', function () {
      return ['agendada', 'pendente', 'erro_autorizacao'].includes(this.get('status'));
    }),
    canEdit: Ember.computed('status', 'isNew', function () {
      return ['agendada', 'pendente', 'erro_autorizacao'].includes(this.get('status')) || this.get('isNew');
    }),
    cannotEdit: Ember.computed('canEdit', function () {
      return !this.get('canEdit');
    }),
    canCancel: Ember.computed('status', function () {
      return ['autorizada', 'erro_cancelamento'].includes(this.get('status'));
    }),
    canDuplicate: Ember.computed('status', function () {
      return true;
    }),
    canGetXml: Ember.computed('status', function () {
      return ['autorizada', 'cancelada', 'erro_cancelamento'].includes(this.get('status'));
    }),
    canGetNfUrl: Ember.computed('status', function () {
      return ['autorizada', 'erro_cancelamento'].includes(this.get('status'));
    }),
    canSend: Ember.computed('status', function () {
      return ['agendada', 'pendente', 'erro_autorizacao'].includes(this.get('status')) || !this.get('status');
    }),
    canSearch: Ember.computed('status', function () {
      return ['agendada', 'enviando', 'em_processamento', 'enviando_cancelamento', 'processando_cancelamento'].includes(this.get('status'));
    }),
    isCanceled: Ember.computed('status', function () {
      return ['cancelada'].includes(this.get('status'));
    }),
    valorServicosFormatted: Ember.computed('valorServicos', function () {
      return this.formatBig(this.get('valorServicos'));
    }),
    valorDeducoesFormatted: Ember.computed('valorDeducoes', function () {
      return this.formatBig(this.get('valorDeducoes'));
    }),
    valorPisFormatted: Ember.computed('valorPis', function () {
      return this.formatBig(this.get('valorPis'));
    }),
    valorCofinsFormatted: Ember.computed('valorCofins', function () {
      return this.formatBig(this.get('valorCofins'));
    }),
    valorInssFormatted: Ember.computed('valorInss', function () {
      return this.formatBig(this.get('valorInss'));
    }),
    valorCsllFormatted: Ember.computed('valorCsll', function () {
      return this.formatBig(this.get('valorCsll'));
    }),
    valorIrFormatted: Ember.computed('valorIr', function () {
      return this.formatBig(this.get('valorIr'));
    }),
    dataEmissaoFormatted: Ember.computed('dataEmissao', function () {
      return this.formatDate(this.get('dataEmissao'));
    }),
    summary: Ember.computed('numero', 'tomador', 'nomeTomador', function () {
      var description, nomeTomador;
      description = this.get('numero') || this.get('dataEmissaoFormatted');

      if (this.get('tomador')) {
        nomeTomador = this.get('nomeTomador');
        description = description + "  p/ dest. " + nomeTomador.substr(0, 40);

        if (nomeTomador.length <= 40) {
          description += '...';
        }
      }

      return description;
    }),
    naturezaOperacaoValues: Ember.computed(function () {
      return '';
    })
  }, _defineProperty(_DS$Model$extend, "descricaoNaturezaOperacao", Ember.computed(function () {
    return '';
  })), _defineProperty(_DS$Model$extend, "normalizedServiceCode", Ember.computed('serviceCode', 'codigoServicoSaoPaulo', 'itemListaServico', 'codigoItemListaServico', 'prestador', function () {
    if ('iss_sp' === this.get('prestador.municipio.itemListaServicoType')) {
      return this.get('codigoServicoSaoPaulo.descricaoCompleta');
    }

    if (this.get('itemListaServico')) {
      return this.get('itemListaServico.descricaoCompleta');
    }

    return this.get('codigoItemListaServico');
  })), _defineProperty(_DS$Model$extend, "cancelar", function cancelar(onSuccess, onError) {
    var ajaxParams;

    if (!this.get('cancelReason')) {
      return;
    }

    ajaxParams = {
      type: "PUT",
      data: {
        justificativa: this.get('cancelReason')
      },
      dataType: "json"
    };
    return this.get("store").externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais_servico/" + this.get('id') + "/cancelar.json"
    }, function (sucesso, textStatus, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (sucesso && onSuccess) {
        onSuccess(res);
      }

      if (!sucesso && onError) {
        return onError(res);
      }
    });
  }), _defineProperty(_DS$Model$extend, "enviar", function enviar(onSuccess, onError) {
    var ajaxParams;
    ajaxParams = {
      type: "PUT",
      data: {},
      dataType: "json"
    };
    return this.get("store").externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais_servico/" + this.get('id') + "/enviar.json"
    }, function (success, status, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (success && onSuccess) {
        onSuccess(res);
      }

      if (!success && onError) {
        return onError(res);
      }
    });
  }), _defineProperty(_DS$Model$extend, "sendInvoice", function sendInvoice(onSuccess, onError) {
    var ajaxParams;
    ajaxParams = {
      type: 'PUT',
      dataType: 'json',
      contentType: 'application/json'
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais_servico/" + this.get('id') + "/send_invoice.json"
    }, function (success, status, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (success && onSuccess) {
        onSuccess(res);
      }

      if (!success && onError) {
        return onError(res);
      }
    });
  }), _defineProperty(_DS$Model$extend, "cancelInvoice", function cancelInvoice(onSuccess, onError) {
    var ajaxParams;
    ajaxParams = {
      type: 'PUT',
      data: {
        cancel_reason: this.get('cancelReason')
      },
      dataType: 'json'
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais_servico/" + this.get('id') + "/cancel_invoice.json"
    }, function (success, status, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (success && onSuccess) {
        onSuccess(res);
      }

      if (!success && onError) {
        return onError(res);
      }
    });
  }), _defineProperty(_DS$Model$extend, "emailInvoice", function emailInvoice(recipients, onSuccess, onError) {
    var ajaxParams;
    ajaxParams = {
      type: 'POST',
      dataType: 'json',
      data: {
        recipients: recipients
      }
    };
    return this.get('store').externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais_servico/" + this.get('id') + "/email_invoice.json"
    }, function (success, status, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (success && onSuccess) {
        onSuccess(res);
      }

      if (!success && onError) {
        return onError(res);
      }
    });
  }), _DS$Model$extend));

  _exports.default = _default;
});