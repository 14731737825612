define("api-dashboard/components/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var hash, self;
      self = this;

      if (self.get('attrsHash')) {
        hash = self.get('attrsHash');
        return Object.keys(hash).forEach(function (key) {
          return self.set(key, hash[key]);
        });
      }
    }
  });

  _exports.default = _default;
});