define("api-dashboard/components/empresas/empresa-form", ["exports", "api-dashboard/mixins/formatters", "api-dashboard/mixins/requests/empresa", "api-dashboard/mixins/utils"], function (_exports, _formatters, _empresa, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _empresa.default, _utils.default, {
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    logradouroDisabled: true,
    bairroDisabled: true,
    cidadeDisabled: true,
    ufDisabled: true,
    cnpj: null,
    init: function init() {
      var cpf_or_cnpj;
      cpf_or_cnpj = this.get('model.isNatural') ? this.get('model.cpf') : this.get('model.cnpj');
      this.set('cnpj', cpf_or_cnpj);

      if (!this.get('model.caminhoCompletoLogo')) {
        this.set('model.caminhoCompletoLogo', '/imagens/business-logo.png');
      }

      return this._super.apply(this, arguments);
    },
    isEmpresaPR: Ember.computed('empresa.uf', function () {
      return this.get('empresa.uf') === 'PR';
    }),
    validateClient: function validateClient() {
      var isValid, messages, model;
      isValid = true;
      messages = [];
      model = this.get('model');

      if (!this.get('model.isNatural')) {
        if (!this.get('model.cnpj')) {
          isValid = false;
          messages.push('O CNPJ precisa ser informado');
        } else {
          if (!this.validarCNPJ(this.get('model.cnpj'))) {
            isValid = false;
            messages.push('O CNPJ informado é inválido');
          }
        }

        if (!this.get('model.nomeFantasia')) {
          isValid = false;
          messages.push('O Nome Fantasia precisa ser preenchido');
        }
      }

      if (this.get('model.isNatural')) {
        if (!this.get('model.cpf')) {
          isValid = false;
          messages.push('O CPF precisa ser informado');
        } else {
          if (!this.validarCPF(this.get('model.cpf'))) {
            isValid = false;
            messages.push('O CPF informado é inválido');
          }
        }
      }

      if (!this.get('model.nome')) {
        isValid = false;
        messages.push('Razão Social precisa ser preenchido');
      }

      if (!this.get('model.inscricaoEstadual') && (this.get('model.habilitaNfce') || this.get('model.habilitaNfe'))) {
        isValid = false;
        messages.push('A Inscrição Estadual precisa ser preenchido');
      }

      if (!this.get('model.inscricaoMunicipal') && this.get('model.habilitaNfse')) {
        isValid = false;
        messages.push('A Inscrição Municipal precisa ser preenchido');
      }

      if (!this.get('model.regimeTributario')) {
        isValid = false;
        messages.push('O Regime Tributário precisa ser preenchido');
      }

      if (!this.get('model.cep')) {
        isValid = false;
        messages.push('O CEP precisa ser preenchido');
      }

      if (!this.get('model.numero')) {
        isValid = false;
        messages.push('O Número do Endereço precisa ser preenchido');
      }

      if (isValid) {
        return [true, model];
      } else {
        return [false, {
          "errorMessage": "Erros de Validação",
          "errorMessages": messages
        }];
      }
    },
    actions: {
      onSubmit: function onSubmit(model) {
        var self;
        self = this;
        this.fazerUploadLogo(self, {
          arquivo: self.get('arquivoLogoAnexada'),
          empresa: self.get('model')
        }, function (success, data, httpCode) {
          if (success) {
            return self.get('application').showAlert('Empresa salva com sucesso!', 'success');
          } else {
            return self.get('application').showAlert('Erro ao salvar a empresa!', 'error');
          }
        });
        return this.sendAction('afterSave', model);
      },
      onCancel: function onCancel(model) {
        if (model == null) {
          model = null;
        }

        return this.sendAction('onCancel', model);
      },
      validateClient: function validateClient() {
        return this.validateClient();
      },
      onAttachImage: function onAttachImage(img, callback) {
        this.set('novaLogoAnexada', true);
        this.set('arquivoLogoAnexada', img);
        $('#img-logo-upload')[0].setAttribute('src', img);
        return callback();
      },
      onInvalidAttachImage: function onInvalidAttachImage(callback) {
        var previousImage;
        this.set('novaLogoAnexada', false);
        this.set('arquivoLogoAnexada', null);

        if (this.get('empresa.possuiLogo')) {
          previousImage = this.get('model.caminhoCompletoLogo');
        } else {
          previousImage = 'imagens/business-logo.png';
        }

        $('#img-logo-upload')[0].setAttribute('src', previousImage);
        alert('Formato inválido.');
        return callback();
      },
      fillAddressFields: function fillAddressFields(params) {
        var bairro, cidade, logradouro, uf;

        if (params['valido']) {
          logradouro = params['endereco']['logradouro'];
          bairro = params['endereco']['bairro'];
          cidade = params['endereco']['localidade'];
          uf = params['endereco']['uf'];
          this.set('model.municipio', cidade);
          this.set('model.codigoMunicipio', params['endereco']['ibge']);
          this.set('model.codigoUf', uf);
          this.set('model.uf', uf);
          this.set('model.logradouro', logradouro);
          this.set('model.bairro', bairro);
          this.set('model.numero', '');
          this.set('model.complemento', '');
          this.set('logradouroDisabled', !!logradouro);
          this.set('bairroDisabled', !!bairro);
          this.set('cidadeDisabled', !!cidade);
          return this.set('ufDisabled', !!uf);
        }
      }
    }
  });

  _exports.default = _default;
});