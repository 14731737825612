define("api-dashboard/templates/components/snippets/last-15-day-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2BRssMJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-lg-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-heading panel-heading-fundo-grafico\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"titulo-preto\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-bar-chart-o fa-fw\"],[8],[9],[0,\" \"],[7,\"b\",true],[8],[0,\"Vendas dos ultimos 15 dias\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"graph\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/snippets/last-15-day-sale.hbs"
    }
  });

  _exports.default = _default;
});