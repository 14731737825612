define("api-dashboard/mixins/components/nota-fiscal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberMixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create((_EmberMixin$create = {
    modalsComponent: 'notas-fiscais/index-modals',
    showCartasCorrecao: false,
    cartaCorrecaoComponent: 'cartas-correcao/carta-correcao-form',
    modalsProperties: null,
    init: function init() {
      var modalsProperties;
      modalsProperties = {
        cartaCorrecao: {
          modalComponent: {},
          notaFiscal: {},
          onCancelModal: {}
        }
      };
      this.set('modalsProperties', modalsProperties);
      return this._super.apply(this, arguments);
    }
  }, _defineProperty(_EmberMixin$create, "showCartasCorrecao", function showCartasCorrecao(selectedItem) {
    var modalsProperties;
    modalsProperties = {
      cartaCorrecao: {
        modalComponent: this.get('modalsProperties.cartaCorrecao.modalComponent'),
        notaFiscal: selectedItem,
        onCancelModal: this.get('onCancelCartaCorrecaoForm')
      }
    };
    this.set('modalsProperties', modalsProperties);
    return this.get('modalsProperties.cartaCorrecao.modalComponent').show();
  }), _defineProperty(_EmberMixin$create, "onCancelCartaCorrecaoForm", function onCancelCartaCorrecaoForm() {
    return this.set('modalsProperties', null);
  }), _EmberMixin$create));

  _exports.default = _default;
});