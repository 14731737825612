define("api-dashboard/components/inputs/product/product-list-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modelType: 'trade_item',
    showQuantityBefore: false,
    formQuantity: 1,
    items: [],
    classNames: [],
    productPromises: [],
    init: function init() {
      this.cleanProductPromises();
      return this._super.apply(this, arguments);
    },
    cleanProductPromises: function cleanProductPromises() {
      if (this) {
        return this.set('productPromises', []);
      }
    },
    actions: {
      addProduct: function addProduct(product, formQuantity) {
        var found, item, store;
        found = false;
        this.get('items').forEach(function (item) {
          if (item.get('product.id') === product.get('id')) {
            item.set('quantity', parseInt(item.get('quantity')) + parseInt(formQuantity));
            found = true;
          }
        });

        if (!found) {
          store = this.get('store');
          item = store.createRecord(this.get('modelType'));
          Ember.set(item, 'product', product);
          Ember.set(item, 'quantity', parseInt(formQuantity));
          Ember.set(item, 'price', product.get('price'));
          this.get('items').addObject(item);
        }

        return this.$('#input-barcode').focus();
      }
    }
  });

  _exports.default = _default;
});