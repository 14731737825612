define("api-dashboard/components/conta-bancaria/conta-bancaria-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    bancos: Ember.computed(function () {
      return this.get('store').findAll('banco');
    }),
    emitentes: Ember.computed(function () {
      return this.get('store').findAll('emitente');
    }),
    actions: {
      addConfigBoleto: function addConfigBoleto() {
        return this.get('model.addNewConfigBoleto').call(this.get('model'));
      },
      onSubmit: function onSubmit(config) {
        return this.sendAction('afterSave', config);
      },
      onCancel: function onCancel(config) {
        if (config == null) {
          config = null;
        }

        return this.sendAction('onCancel', config);
      }
    }
  });

  _exports.default = _default;
});