define("api-dashboard/services/bayeux", ["exports", "api-dashboard/config/environment", "ember-inflector"], function (_exports, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    client: null,
    initOk: true,
    init: function init() {
      var error, fn;

      this._super.apply(this, arguments);

      try {
        this.set('client', new Faye.Client(_environment.default.bayeux.URL, _environment.default.bayeux.options));
      } catch (error1) {
        error = error1;
        this.set('initOk', false);
        this.set('client', null);
        return;
      }

      this.get('client').disable('autodisconnect');
      fn = this.get('onMessage').bind(this);
      return this.get('client').subscribe("/channel_" + this.get('application.currentDomain.id'), fn);
    },
    onMessage: function onMessage(data, channel) {
      var bayeuxMessageId, currentRouteId, currentRouteInstance, currentRouteName, response;

      if (!data['push_event']) {
        return;
      }

      response = data['push_event'];
      currentRouteId = null;

      if (this.get('router.currentRoute.params.id')) {
        currentRouteId = this.get('router.currentRoute.params.id').toString();
      }

      bayeuxMessageId = response['model_id'].toString();

      if (this.get('router.currentRoute.name').startsWith('notas-fiscais') && currentRouteId === bayeuxMessageId) {
        currentRouteName = this.get('router.currentRouteName');
        currentRouteInstance = Ember.getOwner(this).lookup("route:" + currentRouteName);
        currentRouteInstance.currentModel.reload();
      }

      if (this.isResponseStatusSuccess(response)) {
        return this.handleNotification(response);
      } else {
        return this.handleAlert(response);
      }
    },
    isResponseStatusSuccess: function isResponseStatusSuccess(data) {
      return ['autorizada', 'em_processamento', 'enviando', 'pendente', 'enviando_cancelamento', 'cancelada'].includes(data['model_status']);
    },
    handleMessage: function handleMessage(data) {
      var anchor, modelClass, modelId, modelInvoiceNumber, msg, route, status, text, url;
      modelId = data['model_id'];
      modelClass = this.get('intl').t("globals.models." + data['model_class']);
      modelInvoiceNumber = data['model_invoice_number'];
      text = " " + modelInvoiceNumber + " " + this.get('intl').t('notifications.to_customer') + " " + data['model_customer_name'];
      status = this.get('intl').t("globals.status." + data['model_status']);
      url = data['url'];
      route = "/" + this.getUrl(data['model_class']) + "/" + modelId + "/edit";
      msg = "<a class=\"badge--text--link\" href=\"" + route + "\">" + modelClass + " " + text + ": " + status + ".</a>";
      anchor = "<div class=\"clear-10\"></div><i class=\"fas fa-caret-right\"></i> ";
      anchor = "<a href=\"" + url + "\" target='\"_blank\"'>" + this.get('intl').t('notifications.show') + " " + modelClass + "</a>";

      if (url) {
        msg += "<br/>" + anchor;
      }

      return msg;
    },
    handleNotification: function handleNotification(data) {
      return this.get('application').addNotificationMessage(this.handleMessage(data));
    },
    handleAlert: function handleAlert(data) {
      return this.get('application').addAlertMessage(this.handleMessage(data));
    },
    getUrl: function getUrl(modelClass) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelClass));
    }
  });

  _exports.default = _default;
});