define("api-dashboard/models/waybill-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    application: Ember.inject.service(),
    codeDirty: false,
    ___isDuplicate: false,
    useDimensions: _emberData.default.attr('boolean'),
    code: _emberData.default.attr('string'),
    barcode: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    cost: _emberData.default.attr('big'),
    price: _emberData.default.attr('big'),
    codigoNcm: _emberData.default.attr('string'),
    waybill: _emberData.default.belongsTo('waybill'),
    product: _emberData.default.belongsTo('product'),
    supplier: _emberData.default.belongsTo('supplier'),
    department: _emberData.default.belongsTo('department'),
    productType: _emberData.default.belongsTo('productType'),
    collection: _emberData.default.belongsTo('collection'),
    dimension1: _emberData.default.belongsTo('dimension'),
    dimension2: _emberData.default.belongsTo('dimension'),
    dimension3: _emberData.default.belongsTo('dimension'),
    dimension1Value: _emberData.default.belongsTo('dimension-value'),
    dimension2Value: _emberData.default.belongsTo('dimension-value'),
    dimension3Value: _emberData.default.belongsTo('dimension-value'),
    mainWaybillItem: _emberData.default.belongsTo('waybill-item', {
      inverse: 'items'
    }),
    items: _emberData.default.hasMany('waybill-item', {
      inverse: 'mainWaybillItem',
      async: false
    }),
    quantities: _emberData.default.hasMany('waybill-item-quantity', {
      async: false
    }),
    ready: function ready() {
      if (!this.get('quantities')) {
        return this.set('quantities', []);
      }
    },
    quantity: Ember.computed('quantities.@each.quantity', 'useDimensions', 'mainWaybillItem', 'items.@each.quantity', function () {
      var sum;
      sum = 0;

      if (this.get('useDimensions')) {
        this.get('items').forEach(function (wi) {
          return sum += isNaN(wi.get('quantity')) ? 0 : Number(wi.get('quantity'));
        });
      } else {
        this.get('quantities').forEach(function (qtd) {
          return sum += isNaN(qtd.get('quantity')) ? 0 : Number(qtd.get('quantity'));
        });
      }

      return sum;
    }),
    zeroQuantity: Ember.computed('quantity', function () {
      return this.get('quantity') <= 0;
    }),
    totalCost: Ember.computed('cost', 'items.@each.quantity', 'quantities.@each.quantity', function () {
      var sum, unitCost;
      sum = new Big(0);
      unitCost = new Big(this.get('cost') || 0);

      if (this.get('useDimensions')) {
        this.get('items').forEach(function (wi) {
          var quantity;
          quantity = new Big(wi.get('quantity') || 0);
          return sum = sum.add(unitCost.times(quantity));
        });
      } else {
        this.get('quantities').forEach(function (qtd) {
          var quantity;
          quantity = new Big(qtd.get('quantity') || 0);
          return sum = sum.add(unitCost.times(quantity));
        });
      }

      return sum;
    }),
    totalPrice: Ember.computed('price', 'items.@each.quantity', 'quantities.@each.quantity', function () {
      var sum, unitPrice;
      sum = new Big(0);
      unitPrice = new Big(this.get('price') || 0);

      if (this.get('useDimensions')) {
        this.get('items').forEach(function (wi) {
          var quantity;
          quantity = new Big(wi.get('quantity') || 0);
          return sum = sum.add(unitPrice.times(quantity));
        });
      } else {
        this.get('quantities').forEach(function (qtd) {
          var quantity;
          quantity = new Big(qtd.get('quantity') || 0);
          return sum = sum.add(unitPrice.times(quantity));
        });
      }

      return sum;
    }),
    hasProduct: Ember.computed('product', function () {
      return this.get('product') !== null;
    }),
    unchanged: Ember.computed('isNew', 'code', function () {
      var ch;

      if (!this.get('isNew')) {
        return false;
      }

      ch = this.changedAttributes();

      if (Object.keys(ch).length > 2 || this.get('code') !== '') {
        return false;
      }

      return true;
    }),
    dimensionsComplete: Ember.computed('useDimensions', 'mainWaybillItem', 'dimension1', 'dimension1Value', 'dimension2Value', 'dimension3Value', 'items.@each.dimensionsComplete', 'numberOfDimensions', function () {
      var dims, i, items, result;

      if (this.get('mainWaybillItem.id')) {
        dims = this.get('numberOfDimensions');
        i = 1;

        while (i <= dims) {
          if (!this.get("dimension" + i + "Value.value")) {
            break;
          }

          i++;
        }

        return i === dims + 1;
      } else {
        if (!this.get('useDimensions')) {
          return true;
        }

        if (this.get('dimension1') == null || this.get('items.length') === 0) {
          return false;
        }

        items = this.get('items');
        result = true;
        items.forEach(function (item) {
          if (!item.get('dimensionsComplete')) {
            return result = false;
          }
        });
        return result;
      }
    }),
    numberOfDimensions: Ember.computed('dimension1', 'dimension2', 'dimension3', 'mainWaybillItem', 'mainWaybillItem.numberOfDimensions', function () {
      var dim, dims, i;

      if (this.get('mainWaybillItem.id')) {
        return this.get('mainWaybillItem.numberOfDimensions');
      } else {
        dims = 0;
        i = 3;

        while (i > 0) {
          dim = this.get("dimension" + i + ".id");

          if (dim != null) {
            dims = i;
            break;
          }

          i--;
        }

        return dims;
      }
    }),
    editDisabled: Ember.computed('product', function () {
      return !!this.get('product.id');
    }),
    useDimensionsDisabled: Ember.computed('product', function () {
      return !!this.get('product.id');
    }),
    dimension1Disabled: Ember.computed('product', 'mainWaybillItem.dimension1', function () {
      return !!this.get('product.id');
    }),
    dimension2Disabled: Ember.computed('product', 'dimension1.id', 'mainWaybillItem.dimension1.id', function () {
      return !!this.get('product.id') || !this.get('dimension1.id') && !this.get('mainWaybillItem.dimension1.id');
    }),
    dimension3Disabled: Ember.computed('product', 'dimension2.id', 'mainWaybillItem.dimension2.id', function () {
      return !!this.get('product.id') || !this.get('dimension2.id') && !this.get('mainWaybillItem.dimension2.id');
    }),
    dimensionDescription: Ember.computed('dimension1Value', 'dimension2Value', 'dimension3Value', function () {
      var dims;
      dims = [];

      if (this.get('dimension1Value') != null) {
        dims.push(this.get('dimension1Value.value'));
      }

      if (this.get('dimension2Value') != null) {
        dims.push(this.get('dimension2Value.value'));
      }

      if (this.get('dimension3Value') != null) {
        dims.push(this.get('dimension3Value.value'));
      }

      if (dims.length > 0) {
        return "(" + dims.join(',') + ")";
      } else {
        return '';
      }
    }),
    descriptionWithDimensions: Ember.computed('description', 'dimension1Value', 'dimension2Value', 'dimension3Value', 'mainWaybillItem', function () {
      var desc, mainWi;
      mainWi = this.get('mainWaybillItem');
      desc = '';

      if (mainWi != null) {
        desc = mainWi.get('description');
      } else {
        desc = this.get('description');
      }

      return desc + this.get('dimensionDescription');
    }),
    isDirtyAndEditing: Ember.computed('isDirty', 'isEditing', function () {
      return this.get('isDirty') && this.get('isEditing');
    }),
    storesQuantities: Ember.computed('quantities.@each.quantity', 'quantities.@each.fStore', 'items.length', function () {
      var result, stores;
      stores = {};
      this.get('quantities').forEach(function (q) {
        return stores[q.get('fStore.abbrName')] = q;
      });

      if (!Ember.isEmpty(this.get('items'))) {
        this.get('items').forEach(function (i) {
          return i.get('quantities').forEach(function (q) {
            return stores[q.get('fStore.abbrName')] = q;
          });
        });
      }

      result = [];
      Object.keys(stores).sort().forEach(function (k) {
        return result.addObject(stores[k]);
      });
      return result;
    }),
    codeObserver: Ember.observer('code', function () {
      return this.set('codeDirty', true);
    }),
    dirtyObserver: Ember.observer('isDirty', function () {
      var mainWaybillItem;

      if (this.get('isDirty')) {
        mainWaybillItem = this.get('mainWaybillItem');

        if (mainWaybillItem != null) {
          return mainWaybillItem.send('becomeDirty');
        }
      }
    })
  }, _defineProperty(_DS$Model$extend, "dirtyObserver", Ember.observer('isDirty', 'mainWaybillItem', function () {
    var waybillItem;
    waybillItem = this.get('mainWaybillItem');

    if (waybillItem != null && this.get('isDirty')) {
      return waybillItem.send('becomeDirty');
    }
  })), _defineProperty(_DS$Model$extend, "init", function init() {
    return this.set('codeDirty', false);
  }), _defineProperty(_DS$Model$extend, "rollback", function rollback() {
    this._super();

    this.get('quantities').forEach(function (quantity) {
      return quantity.rollback();
    });
    return this.get('items').forEach(function (item) {
      return item.rollback();
    });
  }), _defineProperty(_DS$Model$extend, "validate", function validate() {
    var codigoNcm, cost, errors, isValid, itemsAreValid, price, quantity;
    isValid = true;
    errors = this.get('errors');
    errors.clear();
    this.send('becomeDirty');
    cost = new Big(this.get('cost') || 0);
    price = new Big(this.get('price') || 0);
    quantity = new Big(this.get('quantity') || 0);

    if (Ember.isEmpty(this.get('description'))) {
      errors.add('description', 'O campo Descrição do Item não está preenchido!');
      isValid = false;
    }

    if (cost.eq(0)) {
      errors.add('cost', 'O campo Preço de Aquisição do Item não está preenchido!');
      isValid = false;
    }

    if (price.eq(0)) {
      errors.add('price', 'Preço de Venda do Item não está preenchido!');
      isValid = false;
    }

    if (Ember.isEmpty(this.get('supplier.id')) && Ember.isEmpty(this.get('mainWaybillItem.supplier.id')) && Ember.isEmpty(this.get('waybill.supplier.id'))) {
      errors.add('supplier', 'O campo Fornecedor do Item, ou da Entrada de Produtos deve ser preenchido!');
      isValid = false;
    }

    codigoNcm = this.get('codigoNcm');

    if (!Ember.isEmpty(codigoNcm) && !codigoNcm.match(/^[0-9]{8}$/)) {
      errors.add('codigoNcm', 'O campo Código NCM do Item não está preenchido corretamente!');
      isValid = false;
    }

    if (quantity.lte(0)) {
      errors.add('quantity', 'O campo Quantidade do Item deve ser maior que 0 (zero)!');
      isValid = false;
    }

    if (!this.get('dimensionsComplete')) {
      errors.add('dimensionsComplete', 'Os campos das variações devem ser preenchidos!');
      isValid = false;
    }

    itemsAreValid = this.validateItems();
    isValid = new Boolean(isValid & itemsAreValid);
    return isValid;
  }), _defineProperty(_DS$Model$extend, "validateItems", function validateItems() {
    var errors, isValid, itemIndex;
    errors = this.get('errors');
    isValid = true;
    itemIndex = 1;
    this.get('items').forEach(function (i) {
      itemIndex = i.get('code') || i.get('barcode') || itemIndex;

      if (i.get('dimension1.id') && !i.get('dimension1Value.id')) {
        isValid = false;
        errors.add("item" + itemIndex, "A primeira Variação precisa ser escolhida para o Item " + itemIndex);
      }

      if (i.get('dimension2.id') && !i.get('dimension2Value.id')) {
        isValid = false;
        errors.add("item" + itemIndex, "A segunda Variação precisa ser escolhida para o Item " + itemIndex);
      }

      if (i.get('dimension2.id') && !i.get('dimension2Value.id')) {
        isValid = false;
        errors.add("item" + itemIndex, "A terceira Variação precisa ser escolhida para o Item " + itemIndex);
      }

      return itemIndex += 1;
    });
    return isValid;
  }), _defineProperty(_DS$Model$extend, "__showErrors", function __showErrors() {
    var self;
    self = this;
    return this.get('errors').forEach(function (e) {
      return self.get('application').showAlert(e.message, 'error');
    });
  }), _DS$Model$extend));

  _exports.default = _default;
});