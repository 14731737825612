define("api-dashboard/components/invoices/invoices-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/models/lancamento"], function (_exports, _omnisearchIndex, _lancamento) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    invoices: [],
    title: 'Fatura',
    titlePlural: 'Faturas',
    searching: true,
    searchingInvoices: false,
    showInvoices: true,
    showContracts: false,
    invoiceShowModal: null,
    issueBankBillModal: null,
    currentInvoice: null,
    currentIssueBankBill: null,
    currentCustomer: null,
    currentBoleto: null,
    disputeInvoiceModal: null,
    disputeInvoiceMessage: '',
    issueBankBillMinDate: new Date(),
    totalActiveContracts: '',
    lastInvoice: null,
    enableEditDueDate: false,
    possibleDueDates: [5, 10, 20],
    offset: 0,
    limit: 6,
    append: false,
    totalRecords: 0,
    init: function init(args) {
      this.set('showContracts', false);
      this.set('showInvoices', true);
      this.set('append', false);
      this.get_domain_customers();
      this.getSaasConfig();
      this.get_invoices();
      this.get_total_active_contracts();
      return this._super(args);
    },
    get_domain_customers: function get_domain_customers() {
      var ajaxParams, domain, self;
      self = this;
      self.set('searching', true);
      domain = this.get('application.currentDomain');
      ajaxParams = {
        method: 'GET',
        data: {},
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/domains/customer"
      }, function (success, textStatus, data) {
        var customers, message, result, toastType;

        if (success) {
          customers = data.data['customers'];
          self.set('customers', customers);

          if (customers.length === 1) {
            self.get('store').pushPayload('customer', data.data);
            result = self.get('store').peekRecord('customer', customers[0].id);
            self.set('currentCustomer', result);
          } else {
            toastType = 'error';
            message = "Não há usuários cadastrados nesse domínio.";
            self.get('application').showAlert(message, toastType);
          }
        }

        return self.set('searching', false);
      });
    },
    getSaasConfig: function getSaasConfig() {
      var self;
      self = this;
      return this.get('store').findAll('saas-commercial-config').then(function (obj) {
        return self.set('modelSaasConfig', obj.objectAt(0));
      });
    },
    get_invoices: function get_invoices() {
      var params, self;
      self = this;
      self.set('searchingInvoices', true);
      params = {
        limit: self.get('limit'),
        offset: self.get('offset'),
        invoice_search: 1
      };
      return this.get('store').query('lancamento', params).then(function (result) {
        var objects;
        objects = self.get('invoices');

        if (self.get('append')) {
          objects.addObjects(result.toArray());
        } else {
          objects = result.toArray();
        }

        self.set('invoices', objects);
        self.set('lastInvoice', self.get('invoices').objectAt(0));
        self.set('totalRecords', result.get('meta.total'));
        return self.set('searchingInvoices', false);
      });
    },
    get_total_active_contracts: function get_total_active_contracts() {
      var params, self;
      self = this;
      params = {
        offset: self.get('offset'),
        limit: self.get('limit'),
        onlyActive: true
      };
      return self.get('store').query('saas-contract', params).then(function (result) {
        return self.set('totalActiveContracts', result.meta.total);
      });
    },
    hasRecords: Ember.computed('totalRecords', 'limit', 'offset', function () {
      return this.get('totalRecords') - this.get('limit') - this.get('offset') > 0;
    }),
    on_change_current_customer: function on_change_current_customer(previousValue, value, evt) {
      return this.set('currentCustomer', value);
    },
    dispute_invoice_internal: function dispute_invoice_internal() {
      var ajaxParams, self;

      if (!!this.get('currentInvoice.id')) {
        self = this;
        ajaxParams = {
          method: 'POST',
          data: {
            dispute_message: self.get('disputeInvoiceMessage')
          },
          global: false
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: "/web_api/lancamentos/" + self.get('currentInvoice.id') + "/dispute"
        }, function (success, textStatus, data) {
          var message, toastType;

          if (success) {
            toastType = 'success';
            message = "Fatura de " + self.get('currentInvoice.mesFmt') + " contestada com sucesso!" + " A equipe de suporte da Acras entrará em contato em breve.";
            self.set('disputeInvoiceMessage', '');
            self.get('disputeInvoiceModal').hide();
          } else {
            toastType = 'error';
            message = "Não foi possível contestar a Fatura de " + self.get('currentInvoice.mesFmt') + " automaticamente! Por favor, entre em contato através do email suporte@acras.com.br";
          }

          return self.get('application').showAlert(message, toastType);
        });
      }
    },
    saveDueDate: function saveDueDate(dueDate) {
      var message, self, toastType;
      self = this;

      if (dueDate !== '5' && dueDate !== '10' && dueDate !== '15') {
        toastType = 'error';
        message = 'Data de vencimento inválida!';
        self.get('application').showAlert(message, toastType);
        return;
      }

      self.set('modelSaasConfig.dueDay', dueDate);
      return this.get('modelSaasConfig').save().then(function (obj) {
        toastType = 'success';
        message = 'Condição Comercial salva com sucesso!';
        self.set('searchingSaasCommercialConfig', false);
        return self.get('application').showAlert(message, toastType);
      }, function (obj) {
        toastType = 'error';
        message = 'Erro ao salvar Condição Comercial!';
        self.set('searchingSaasCommercialConfig', false);
        return self.get('application').showAlert(message, toastType);
      });
    },
    showBankBillModal: function showBankBillModal(boleto) {
      var self;
      self = this;
      self.set('currentBoleto', boleto);
      self.set('currentIssueBankBill', {
        date: new Date()
      });
      return self.get('issueBankBillModal').show();
    },
    showInvoicePdf: function showInvoicePdf(boleto) {
      return window.open(boleto.get('pdfUrl'));
    },
    showDisputeInvoiceModal: function showDisputeInvoiceModal(invoice) {
      this.set('currentInvoice', invoice);
      return this.get('disputeInvoiceModal').show();
    },
    onSaveBankBill: function onSaveBankBill() {
      var ajaxParams, due_date, maxDate, message, momentDueDate, self;
      self = this;
      due_date = self.get('currentIssueBankBill.date') || new Date();
      maxDate = moment().add(5, 'days');
      momentDueDate = moment(due_date);

      if (momentDueDate.format('DD/MM/YYYY') < moment().format('DD/MM/YYYY') || momentDueDate > maxDate) {
        message = 'A nova data de vencimento não pode ser inferior a data atual ou superior a 5 dias úteis';
        return self.get('application').showAlert(message, 'error', 30000, false);
      } else {
        ajaxParams = {
          method: 'POST',
          data: {
            new_due_date: due_date,
            boleto_id: self.get('currentBoleto.id'),
            motive: self.get('currentIssueBankBill.reason')
          },
          global: false
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: "/web_api/boletos/" + self.get('currentBoleto.id') + "/recreate"
        }, function (success, textStatus, data) {
          var result, toastType;

          if (success) {
            toastType = 'success';
            message = "Boleto reemitido com sucesso!";
            self.get('issueBankBillModal').hide();
            self.get('store').pushPayload('boleto', data.data);
            result = self.get('store').peekRecord('boleto', data.data.boleto.id);
            self.showInvoicePdf(result);
            self.get_invoices();
          } else {
            toastType = 'error';
            message = data.jqXHR.responseJSON.msg;
          }

          return self.get('application').showAlert(message, toastType, 30000, false);
        });
      }
    },
    onCancelBankBill: function onCancelBankBill(modal) {
      this.set('currentIssueBankBill', {});
      return modal.hide();
    },
    disputeInvoice: function disputeInvoice() {
      return this.dispute_invoice_internal();
    },
    onCancelDisputeInvoice: function onCancelDisputeInvoice(modal) {
      this.set('disputeInvoiceMessage', '');
      this.set('currentInvoice', {});
      return modal.hide();
    },
    loadMoreInvoices: function loadMoreInvoices() {
      this.set('append', true);
      this.set('offset', this.get('offset') + this.get('limit'));
      return this.get_invoices();
    },
    actions: {
      show_invoice_modal: function show_invoice_modal(invoice) {
        this.set('currentInvoice', invoice);
        return this.get('invoiceShowModal').show();
      },
      showContractCancelmodal: function showContractCancelmodal() {
        return this.get('contractCancelModal').show();
      },
      onSaveCancelContract: function onSaveCancelContract() {
        var ajaxParams, self;
        self = this;
        ajaxParams = {
          method: 'POST',
          data: {
            cancel_message: self.get('cancelContractReason')
          },
          global: false
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: "/contracts/cancel_request"
        }, function (success, textStatus, data) {
          var message, toastType;

          if (success) {
            toastType = 'success';
            message = "Solicitação de cancelamento de contrato realizada com sucesso!" + " A equipe de suporte da Acras entrará em contato em breve.";
            self.set('cancelContractReason', '');
            self.get('contractCancelModal').hide();
          } else {
            toastType = 'error';
            message = "Não foi possível solicitar o cancelamento do seu contrato" + " automaticamente! Por favor, entre em contato através do email suporte@acras.com.br";
          }

          return self.get('application').showAlert(message, toastType);
        });
      },
      onBackCancelContract: function onBackCancelContract() {
        this.set('cancelContractReason', '');
        return this.get('contractCancelModal').hide();
      },
      loadInvoices: function loadInvoices() {
        this.set('showContracts', false);
        this.set('showInvoices', true);
        return this.get_invoices();
      },
      get_contracts: function get_contracts() {
        this.set('showInvoices', false);
        return this.set('showContracts', true);
      },
      editDueDate: function editDueDate() {
        return this.set('enableEditDueDate', true);
      },
      onChangeDueDate: function onChangeDueDate(dueDate) {
        this.set('enableEditDueDate', false);
        return this.saveDueDate(dueDate);
      }
    }
  });

  _exports.default = _default;
});