define("api-dashboard/components/formularios/product-parameter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-sm-6'],
    constants: Ember.inject.service(),
    valorParametro: null
  });

  _exports.default = _default;
});