define("api-dashboard/models/transfer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    number: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    trade: _emberData.default.belongsTo('trade'),
    statusFormatted: Ember.computed('status', function () {
      var elm, status;
      status = this.get('status');
      elm = this.get('constants.transferStatuses').find(function (e) {
        return e.value === status;
      });

      if (elm != null) {
        return elm.description;
      } else {
        return '';
      }
    }),
    ready: function ready() {
      if (!this.get('trade.content')) {
        return this.set('trade', this.get('store').createRecord('trade'));
      }
    }
  });

  _exports.default = _default;
});