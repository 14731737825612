define("api-dashboard/models/config-definition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    store: Ember.inject.service(),
    code: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    configType: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    lft: _emberData.default.attr('number'),
    rgt: _emberData.default.attr('number'),
    concernTo: _emberData.default.attr('string'),
    concernToPos: _emberData.default.attr('boolean'),
    presentationOrder: _emberData.default.attr('number'),
    defaultValue: _emberData.default.attr('string'),
    indent: _emberData.default.attr('number'),
    idConfigDefinition: _emberData.default.attr('number'),
    version: _emberData.default.attr('string'),
    idConfigValue: _emberData.default.attr('number'),
    domain: _emberData.default.belongsTo('domain'),
    fStore: _emberData.default.belongsTo('f-store'),
    emitente: _emberData.default.belongsTo('emitente'),
    parent: _emberData.default.belongsTo('config-definition', {
      inverse: 'children'
    }),
    children: _emberData.default.hasMany('config-definition', {
      inverse: 'parent'
    }),
    valueObject: Ember.computed('value', {
      get: function get(k) {
        return this.castValue();
      },
      set: function set(k, v) {
        this.set('value', v);
        return this.uncastValue(v);
      }
    }),
    isNode: Ember.computed('configType', function () {
      return ['NODE'].includes(this.get('configType'));
    }),
    isVariant: Ember.computed('configType', function () {
      return ['STORE', 'EMITENTE', 'DOMAIN'].includes(this.get('concernTo'));
    }),
    isVariantOrNode: Ember.computed('isNode', 'isVariant', function () {
      return this.get('isNode') || this.get('isVariant');
    }),
    isRootNode: Ember.computed(function () {
      return this.get('parent') === null;
    }),
    castValue: function castValue() {
      switch (this.get('configType')) {
        case 'BOOLEAN':
          return Boolean(parseInt(this.get('value')));

        case 'INTEGER':
        case 'MINUTES':
        case 'HOURS':
          return parseInt(this.get('value'));

        case 'FLOAT':
        case 'PERCENTAGE':
          return parseFloat(this.get('value'));

        case 'DATE':
          return Date.parse(this.get('value'));

        case 'AUTOCOMPLETE_CITY':
          return this.getModelObject('municipio');

        case 'AUTOCOMPLETE_STORE':
          return this.getModelObject('fStore');

        case 'AUTOCOMPLETE_COLLECTION':
          return this.getModelObject('collection');

        case 'AUTOCOMPLETE_SALESPEOPLE':
          return this.getModelObject('person');

        case 'AUTOCOMPLETE_COST_CENTER':
          return this.getModelObject('cost-center');

        default:
          return this.get('value');
      }
    },
    getModelObject: function getModelObject(modelName) {
      var normalizedObj, value;
      value = this.get('value');

      if (value) {
        if (value.constructor.isModel) {
          value = this.get('value');
        } else {
          value = JSON.parse(this.get('value'));
        }

        if (!value.id) {
          value = {
            id: value
          };
        }

        normalizedObj = this.get('store').normalize(modelName, value);
        return this.get('store').push(normalizedObj);
      }
    },
    uncastValue: function uncastValue(v) {
      var value;

      value = function () {
        switch (this.get('configType')) {
          case 'BOOLEAN':
            return v * 1;

          default:
            return v;
        }
      }.call(this);

      return this.set('value', value);
    },
    getValueForUpdate: function getValueForUpdate() {
      var value;
      value = this.get('value');

      if (!!value) {
        if (!value.constructor.isModel && value.constructor === String && value.startsWith('{')) {
          value = JSON.parse(value);
        }

        if (value.id) {
          value = value.id;
        }
      }

      return value;
    }
  });

  _exports.default = _default;
});