define("api-dashboard/routes/inscricao-estadual/index", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    model: function model(params, transition) {
      return this.get('store').findAll('inscricao-estadual');
    }
  });

  _exports.default = _default;
});