define("api-dashboard/templates/components/suppliers/address-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ojeNdenB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-cep\",null,[[\"label\",\"val\",\"actionOnFimValidacao\",\"maxlength\",\"cols\",\"fetching\"],[\"CEP\",[24,[\"model\",\"defaultAddress\",\"cep\"]],[28,\"action\",[[23,0,[]],[24,[\"fillAddressFields\"]]],null],7,\"3\",[24,[\"fetchingCep\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"label\",\"cols\",\"placeholder\"],[\"Logradouro\",[24,[\"model\",\"defaultAddress\",\"logradouro\"]],255,\"Logradouro\",\"6\",\"Rua, Av., Travessa, etc\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-number\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\"],[\"Número\",[24,[\"model\",\"defaultAddress\",\"numero\"]],10,\"3\",\"Número\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\"],[\"Complemento\",[24,[\"model\",\"defaultAddress\",\"complemento\"]],50,\"4\",\"Informações Complementares\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\"],[\"Bairro\",[24,[\"model\",\"defaultAddress\",\"bairro\"]],255,\"4\",\"Bairro\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"modelName\",\"suggestionField\",\"selectedRecord\",\"cols\",\"placeholder\"],[\"Cidade\",\"municipio\",\"nomeAndUf\",[24,[\"model\",\"defaultAddress\",\"municipio\"]],\"4\",\"Cidade ou Município\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/suppliers/address-form.hbs"
    }
  });

  _exports.default = _default;
});