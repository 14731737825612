define("api-dashboard/templates/components/modelos-notas-fiscais/modelo-nota-fiscal-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7iaf7Sb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Novo\"]],\"parameters\":[]},null],[0,\" Cheque\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],\"onSubmit\",\"onCancel\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Nome\",[24,[\"model\",\"nome\"]],6]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"value\",\"options\",\"cols\"],[\"Status\",[24,[\"model\",\"tipo\"]],[24,[\"constants\",\"tipoDocumentoValues\"]],6]]],false],[0,\"\\n      \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Natureza da Operação\",[24,[\"model\",\"naturezaOperacao\"]],6]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"Informações Adicionais\"],[9],[0,\"\\n        \"],[1,[28,\"textarea\",null,[[\"rows\",\"type\",\"value\",\"class\"],[\"3\",\"text\",[24,[\"model\",\"informacoesAdicionaisContribuinte\"]],\"form-control\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/modelos-notas-fiscais/modelo-nota-fiscal-form.hbs"
    }
  });

  _exports.default = _default;
});