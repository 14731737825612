define("api-dashboard/mixins/requests/empresa", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RequestsEmpresaMixin;
  RequestsEmpresaMixin = Ember.Mixin.create(_utils.default, {
    store: Ember.inject.service(),
    obterEmpresasAtivas: function obterEmpresasAtivas(context, params, callback) {
      var store;
      store = context.get("store");
      return store.query("empresa", {
        status: "ativa"
      }).then(function (empresas) {
        return callback(true, empresas, 200);
      }, function (erros) {
        return callback(false, erros, 200);
      });
    },
    obterEmpresasInativas: function obterEmpresasInativas(context, params, callback) {
      var store;
      return callback(true, [], 200);
      store = context.get("store");
      return store.query("empresa", {
        status: "inativa"
      }).then(function (empresas) {
        return callback(true, empresas, 200);
      }, function (erros) {
        return callback(false, erros, 200);
      });
    },
    cadastrarEmpresa: function cadastrarEmpresa(context, params, callback) {
      return params["empresa"].save().then(function (dados) {
        return callback(true, dados, 200);
      }, function (erros) {
        return callback(false, erros, 200);
      });
    },
    editarEmpresa: function editarEmpresa(context, params, callback) {
      return params["empresa"].save().then(function (dados) {
        return callback(true, dados, 200);
      }, function (erros) {
        return callback(false, erros, 200);
      });
    },
    fazerUploadCertificado: function fazerUploadCertificado(context, params, callback) {
      var self;
      self = context;
      return context.fileToBase64({
        file: params["arquivo"]
      }, function (sucesso, dados) {
        var ajaxParams, data;

        if (!sucesso) {
          return callback(false, dados);
        }

        dados = dados.split(',').pop();
        data = {
          empresa: {
            arquivo_certificado_base64: dados,
            senha_certificado: params["senha"]
          }
        };
        data = JSON.stringify(data);
        ajaxParams = {
          contentType: "application/json; charset=utf-8",
          type: "PUT",
          data: data,
          dataType: "json"
        };
        return self.get("store").externalAjax(ajaxParams, {
          apiURL: "/panel_api/empresas/" + params["empresa"].get("id")
        }, function (sucesso, text_data, callbackParams) {
          var httpCode, mensagemApi;
          data = callbackParams.jqXHR.responseJSON;

          if (!sucesso && data.hasOwnProperty("erros")) {
            if (data["erros"][0]["codigo"] === "erro_validacao") {
              mensagemApi = data["erros"][0]["mensagem"];

              if (mensagemApi.includes("senha")) {
                data["mensagemUsuario"] = "A senha do certificado está incorreta.";
                data["erroInterno"] = "SENHA";
              }

              if (mensagemApi.includes("CNPJ")) {
                data["mensagemUsuario"] = "O certificado utilizado não pertence à esta empresa.";
                data["erroInterno"] = "CNPJ";
              }

              if (mensagemApi.includes("vencido")) {
                data["mensagemUsuario"] = "O certificado está com prazo de validade vencido";
                data["erroInterno"] = "SENHA";
              }
            }
          }

          httpCode = callbackParams["jqXHR"]["status"];
          return callback(sucesso, data, httpCode);
        });
      });
    },
    ativarEmpresa: function ativarEmpresa(context, params, callback) {
      return setTimeout(function () {
        return callback(true, {}, 200);
      }, 500);
    },
    desativarEmpresa: function desativarEmpresa(context, params, callback) {
      return setTimeout(function () {
        return callback(true, {}, 200);
      }, 500);
    },
    verificarCNPJExistente: function verificarCNPJExistente(context, params, callback) {
      var cnpj;
      cnpj = params["cnpj"];
      return this.get("store").query("empresa", {
        search_cnpj: [cnpj]
      }).then(function (empresas) {
        return callback(true, empresas, 200);
      }, function (errors) {
        return callback(false, errors, 200);
      });
      /*
      setTimeout(
        ->
          return callback(true, {}, 200)
        500
      )
       */
    },
    carregarMaisEmpresasOffset: function carregarMaisEmpresasOffset(context, lastParamsWithOffset, callback) {
      return this.get("store").query("empresa", lastParamsWithOffset).then(function (emissoes) {
        return callback(true, emissoes, 200);
      }, function (errors) {
        return callback(false, errors, 200);
      });
    },
    fazerUploadLogo: function fazerUploadLogo(context, params, callback) {
      var ajaxParams, dados, data, self;
      self = context;
      dados = params["arquivo"] || '';
      dados = dados.split(',').pop();
      data = {
        empresa: {
          arquivo_logo_base64: dados
        }
      };
      data = JSON.stringify(data);
      ajaxParams = {
        contentType: "application/json; charset=utf-8",
        type: "PUT",
        data: data,
        dataType: "json"
      };
      return self.get("store").externalAjax(ajaxParams, {
        apiURL: "/panel_api/empresas/" + params["empresa"].get("id")
      }, function (sucesso, data, callbackParams) {
        var httpCode;
        httpCode = callbackParams["jqXHR"]["status"];
        return callback(sucesso, data, httpCode);
      });
    },
    obterBackups: function obterBackups(context, params, callback) {
      var ajaxParams;
      ajaxParams = {
        contentType: "application/json; charset=utf-8",
        type: "GET",
        dataType: "json"
      };
      return this.get("store").externalAjax(ajaxParams, {
        apiURL: "/panel_api/empresas/" + params["empresa_id"] + "/backups"
      }, function (success, data, params) {
        var res;

        if (success) {
          res = Ember.A([]);
          data = Ember.A(data['backups']);
          data.forEach(function (item) {
            if (item['mes']) {
              return res.push({
                mes: item['mes'].substring(4, 6),
                ano: item['mes'].substring(0, 4),
                xmls: item['xmls'],
                danfes: item['danfes']
              });
            }
          });
          return callback(true, res);
        } else {
          return callback(false, data);
        }
      });
    },
    obterEmpresaPorNomeOuCnpj: function obterEmpresaPorNomeOuCnpj(context, params, callback) {
      var promise;
      promise = this.get("store").query("empresa", {
        search: params["search"]
      });

      if (params["returnAsPromise"]) {
        return promise;
      }

      return promise.then(function (empresas) {
        return callback(true, empresas, 200);
      }, function (errors) {
        return callback(false, errors, errors.status);
      });
    }
  });
  var _default = RequestsEmpresaMixin;
  _exports.default = _default;
});