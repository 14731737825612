define("api-dashboard/components/stores-config/stores-config-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    actions: {
      onCancel: function onCancel() {
        return this.sendAction('cancelAction');
      }
    }
  });

  _exports.default = _default;
});