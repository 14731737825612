define("api-dashboard/models/pos", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    enableWebSale: _emberData.default.attr('boolean'),
    enableWebNfce: _emberData.default.attr('boolean'),
    label: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    version: _emberData.default.attr('number'),
    ecfSerialNumber: _emberData.default.attr('string'),
    storeConfigEmiteNfce: _emberData.default.attr('boolean'),
    storeConfigPermiteNaoEmitirNfce: _emberData.default.attr('boolean'),
    domain: _emberData.default.belongsTo('domain'),
    fStore: _emberData.default.belongsTo('f-store'),
    description: Ember.computed('number', 'label', function () {
      var description;
      description = this.get('number');

      if (this.get('label')) {
        description += " - " + this.get('label');
      }

      description += " - " + (this.get('fStore.shortName') || this.get('fStore.name'));
      return description;
    }),
    isWeb: Ember.computed('enableWebSale', 'enableWebNfce', function () {
      return this.get('enableWebSale') || this.get('enableWebNfce');
    })
  });

  _exports.default = _default;
});