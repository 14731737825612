define("api-dashboard/components/inventories/inventories", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    tableName: null,
    inventories: null,
    dimensions: null,
    init: function init(args) {
      var self;
      self = this;
      self.get('store').findRecord('product', args.model[1]).then(function (obj) {
        return self.set('tableName', obj.get('supplier.name') + ' - ' + obj.code + ' - ' + obj.description);
      });
      this.getInventory(args.model[0]);
      return this._super(args);
    },
    didRender: function didRender(args) {
      return this.$("#inventory-modal").modal('show');
    },
    willDestroyElement: function willDestroyElement(args) {
      return this.$("#inventory-modal").modal('hide');
    },
    getInventory: function getInventory(inventory) {
      this.set('inventories', inventory);
      return this.set('dimensions', inventory.stores[0]);
    },
    actions: {
      onCancel: function onCancel() {
        return this.get('router').transitionTo('products/index');
      }
    }
  });

  _exports.default = _default;
});