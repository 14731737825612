define("api-dashboard/components/charts/pie", ["exports", "api-dashboard/components/charts/chart"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chart.default.extend({
    type: 'pie',
    options: Ember.computed(function () {
      return this.get('baseOptions');
    })
  });

  _exports.default = _default;
});