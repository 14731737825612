define("api-dashboard/models/cost-center", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    descricao: _emberData.default.attr('string'),
    rateavel: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domain: _emberData.default.belongsTo('domain'),
    modelosLancamento: _emberData.default.hasMany('modelo-lancamento'),
    sourceRatioCriterions: _emberData.default.hasMany('ratio-criterion', {
      async: false,
      inverse: 'destinationCostCenter'
    }),
    destinationRatioCriterions: _emberData.default.hasMany('ratio-criterion', {
      async: false,
      inverse: 'sourceCostCenter'
    })
  });

  _exports.default = _default;
});