define("api-dashboard/components/inputs/tree-grid", ["exports", "api-dashboard/mixins/dev-express", "api-dashboard/mixins/utils"], function (_exports, _devExpress, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_devExpress.default, _utils.default, {
    classNameBindings: ['colsClassName'],
    classNames: [''],
    componentReference: null,
    dataSource: null,
    disabled: false,
    label: null,
    layoutName: 'components/inputs/tree-grid',
    onCellClick: null,
    childrenExpression: 'children',
    showBorders: true,
    hiddenColumns: [],
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    allowedHideColumns: Ember.computed('columns.@each.visible', function () {}),
    didInsertElement: function didInsertElement() {
      return this.buildComponent();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      return this.buildComponent();
    },
    _onCellClick: function _onCellClick(e) {
      var comp, self;
      self = this;
      comp = this.get('componentReference');

      if (!e.data && comp.columnOption(e.column.index, 'allowHiding')) {
        comp.columnOption(e.column.index, 'visible', false);
        this.get('hiddenColumns').push(e.column.index);
        return;
      }

      if (!(e.event.target.parentElement.classList.contains("dx-treelist-expanded") || e.event.target.parentElement.classList.contains("dx-treelist-collapsed"))) {
        if (!comp.isRowExpanded(e.key)) {
          comp.expandRow(e.key);
        } else {
          comp.collapseRow(e.key);
        }
      }

      if (self.get('onCellClick')) {
        return self.get('onCellClick')(e.previousValue, e.value, comp, e);
      }
    },
    buildComponent: function buildComponent() {
      var comp, opts, self;
      self = this;

      this._normalizeColumns();

      opts = {
        dataSource: self.get('dataSource'),
        itemsExpr: self.get('childrenExpression'),
        dataStructure: 'tree',
        columns: self.get('columns'),
        editing: {
          mode: 'popup',
          popup: {
            showTitle: false
          }
        },
        showRowLines: true,
        showBorders: self.get('showBorders'),
        columnAutoWidth: true,
        disabled: self.get('disabled'),
        loadPanel: {
          enabled: false
        },
        onCellClick: function onCellClick(e) {
          return self.get('_onCellClick').call(self, e);
        }
      };
      comp = $(self.element).find('#treegridDiv').dxTreeList(opts).dxTreeList("instance");
      return this.set('componentReference', comp);
    },
    _normalizeColumns: function _normalizeColumns(columns) {
      var c, cols, i, j, len, results, self;
      self = this;
      cols = columns ? columns : this.get('columns');
      results = [];

      for (i = j = 0, len = cols.length; j < len; i = ++j) {
        c = cols[i];

        if (c.columns && c.columns.length > 0) {
          this._normalizeColumns(c.columns);
        }

        results.push(c.headerCellTemplate = function (header, info) {
          if (info.column.allowHiding) {
            return $('<span>').html(info.column.caption + "&nbsp;<i class='fa fa-eye-slash' title='Ocultar coluna'></i>").css('font-size', '16px').appendTo(header);
          } else {
            return $('<span>').html(info.column.caption).css('font-size', '16px');
          }
        });
      }

      return results;
    },
    actions: {
      hidePercentageColumns: function hidePercentageColumns() {
        var comp, self;
        self = this;
        comp = this.get('componentReference');
        return comp.getVisibleColumns().forEach(function (c) {
          if (c.allowHiding) {
            self.get('hiddenColumns').push(c.index);
            return comp.columnOption(c.index, 'visible', false);
          }
        });
      },
      showHiddenColumns: function showHiddenColumns() {
        var columnIndex, comp, j, len, ref, results;
        comp = this.get('componentReference');
        ref = this.get('hiddenColumns');
        results = [];

        for (j = 0, len = ref.length; j < len; j++) {
          columnIndex = ref[j];
          results.push(comp.columnOption(columnIndex, 'visible', true));
        }

        return results;
      },
      collapseAll: function collapseAll() {
        var comp;
        comp = this.get('componentReference');
        return comp.forEachNode(function (node) {
          return comp.collapseRow(node.key);
        });
      },
      expandAll: function expandAll() {
        var comp;
        comp = this.get('componentReference');
        return comp.forEachNode(function (node) {
          return comp.expandRow(node.key);
        });
      }
    }
  });

  _exports.default = _default;
});