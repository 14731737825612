define("api-dashboard/components/inputs/input-big", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Input para preenchiemnto de valores decimais.
  
    @value: valor decimal para bind.
    @scale: indica o número de casas decimais que o input irá permitir.
        Importante: se o valor inicial deste input tiver mais casas decimais que o
        o número de casas decimais que o input prevê (@scale), o input fará o
        arredondamento para o número de casas que foi configurado.
  
    @allowBlank: permite valor em branco, por default true
  
    @label: texto do label, se não for setado não haverá label
  
    @cols: número de colunas que serão ocupadas, padrão 6.
   */
  var _default = _baseTextField.default.extend({
    allowBlank: true,
    allowEmpty: true,
    attributeBindings: ['isRequired:required', 'size', 'pattern', 'min', 'max', 'step', 'allowBlank'],
    cols: 6,
    label: null,
    layoutName: 'components/inputs/input-big',
    max: 99999999999,
    min: 0,
    pattern: null,
    scale: 2,
    size: null,
    step: "any",
    type: "number",
    autoScale: Ember.computed("val", function () {
      var r;
      r = 0;

      try {
        r = parseFloat(this.get("val")).toString().split(".")[1].length;
      } catch (error) {}

      return r;
    }),
    internalScale: Ember.computed("scale", "autoScale", function () {
      var autoScale, scale;
      scale = this.get("scale");

      if (scale !== null) {
        return scale;
      }

      autoScale = this.get("autoScale");

      if (autoScale) {
        return autoScale;
      }

      return 0;
    }),
    change: function change(e) {
      return this.roundWithScale();
    },
    roundWithScale: function roundWithScale() {
      var v, value;
      value = this.get("val");

      if (isNaN(value)) {
        return;
      }

      if (!value) {
        if (this.get('allowBlank')) {
          this.set("val", '');
          return;
        } else {
          value = 0;
        }
      }

      v = new Big(value);
      v = v.toFixed(parseInt(this.get("internalScale")));
      return this.set("val", v);
    },
    didRender: function didRender() {
      if (!this.get("wasInit")) {
        return this.initVal();
      }
    },
    initVal: function initVal() {
      this.roundWithScale();
      return this.set("wasInit", true);
    }
  });

  _exports.default = _default;
});