define("api-dashboard/templates/nova-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CvvdYKgB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"principal-tit text-center\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"modoEdicao\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"h1\",true],[8],[0,\"Nova Empresa\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"h1\",true],[8],[0,\"Editar empresa \"],[1,[24,[\"model\",\"nome\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"devMode\"]]],null,{\"statements\":[[0,\"\\tSão utilizados dois componentes pois, como os componentes são singleton, caso\\n\\to usuário navegue entre a rota editar -> cadastrar nova empresa, seria\\n\\tnecessário um tratamento para que os valores fossem reinicializados e descartar\\n\\tos atributos e estados atribuídos na rota anterior.\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"modoEdicao\"]]],null,{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"formularios/cadastro-empresa\",null,[[\"class\",\"empresa\",\"modoEdicao\"],[\"box-nova-empresa animated fadeInUp\",[24,[\"model\"]],true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"formularios/cadastro-empresa\",null,[[\"class\",\"actionOnFormularioSubmetido\",\"empresa\",\"modoEdicao\"],[\"box-nova-empresa animated fadeInUp\",\"actRedirecionarAposNovaEmpresa\",[24,[\"model\"]],false]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"clear-50\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/nova-empresa.hbs"
    }
  });

  _exports.default = _default;
});