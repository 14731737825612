define("api-dashboard/templates/components/pagina-nao-encontrada", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qjxHmyRg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"error-template\"],[8],[0,\"\\n                \"],[7,\"h1\",true],[8],[0,\"\\n                    Ops... Página não encontrada\"],[9],[0,\"\\n                \"],[7,\"h2\",true],[8],[0,\"\\n                    404 Not Found\"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"error-details\"],[8],[0,\"\\n                    Parece que a página que você tentou acessar não existe. Verifique se o endereço foi digitado corretamente.\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"error-actions\"],[8],[0,\"\\n                  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-primary btn-lg\",\"index\"]],{\"statements\":[[0,\" Continuar \"]],\"parameters\":[]},null],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/pagina-nao-encontrada.hbs"
    }
  });

  _exports.default = _default;
});