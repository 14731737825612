define("api-dashboard/templates/empresas/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Sh3zi/B",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"empresas/empresa-form\",null,[[\"model\",\"afterSave\",\"onCancel\"],[[24,[\"model\"]],\"afterSave\",\"onCancel\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/empresas/edit.hbs"
    }
  });

  _exports.default = _default;
});