define("api-dashboard/adapters/application", ["exports", "ember-data", "api-dashboard/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    host: _environment.default.host,
    hostNfe: _environment.default.hostNfe,
    authorize: function authorize(xhr) {
      var self, token;
      self = this;
      token = this.get('session.data.authenticated').token;

      if (token) {
        return xhr.setRequestHeader('Authorization', "Token token=" + token);
      }
    },
    headers: Ember.computed(function () {
      var token;
      token = this.get('session.data.authenticated').token;
      return {
        'X-Requested-With': 'XMLHttpRequest',
        'Client-App': 'NewEmber',
        'Authorization': "Token token=" + token
      };
    }),
    ajaxOptions: function ajaxOptions(url, type, options) {
      var self;

      if (options == null) {
        options = {};
      }

      self = this;

      if (self.get('session.data.currentDomainId')) {
        options["data"] || (options["data"] = {});
        options["data"]["domain_id"] = self.get('session.data.currentDomainId');
      }

      return this._super(url, type, options);
    },
    pathForType: function pathForType(type) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(type.replace(/-/g, ' ')));
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var erros, finalErrors;

      if (new String(status)[0] !== "2" || payload.hasOwnProperty("errors") || payload.hasOwnProperty("erros")) {
        finalErrors = [];
        erros = payload["errors"] || payload["erros"];

        if (erros) {
          Object.keys(erros).forEach(function (attrName) {
            var er, i, len, ref, results;

            if (erros[attrName]) {
              ref = erros[attrName];
              results = [];

              for (i = 0, len = ref.length; i < len; i++) {
                er = ref[i];
                results.push(finalErrors.push({
                  detail: er,
                  source: {
                    pointer: "/data/attributes/" + attrName.replace('.', '/').camelize()
                  }
                }));
              }

              return results;
            }
          });
        } else {
          finalErrors.push(payload['message']);
        }

        return new _emberData.default.InvalidError(finalErrors);
      }

      return this._super.apply(this, arguments);
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var ret;

      if (query == null) {
        query = {};
      }

      query['domain_id'] = this.get('session.data.currentDomainId');
      ret = this._super(modelName, id, snapshot, requestType, query);
      return ret;
    }
  });

  _exports.default = _default;
});