define("api-dashboard/models/waybill-item-quantity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    quantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    waybillItem: _emberData.default.belongsTo('waybill-item', {
      inverse: 'quantities'
    }),
    fStore: _emberData.default.belongsTo('f-store'),
    explained: Ember.computed('quantity', 'fStore', 'waybillItem', function () {
      var self;
      self = this;
      return this.get('intl').t('waybill-item-quantity.explained', {
        locale: 'pt-br',
        items: self.get('quantity'),
        dimensionDescription: self.get('waybillItem.descriptionWithDimensions'),
        storeName: self.get('fStore.name')
      });
    }),
    dirtyObserver: Ember.observer('quantity', function () {
      var waybillItem;

      if (this.get('isDirty')) {
        waybillItem = this.get('waybillItem');

        if (waybillItem != null) {
          return waybillItem.send('becomeDirty');
        }
      }
    }),
    formattedQuantity: Ember.computed('quantity', function () {
      if (this.get('quantity') === 0) {
        return '-';
      }

      return this.get('quantity');
    })
  });

  _exports.default = _default;
});