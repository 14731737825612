define("api-dashboard/models/gift-coupon", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    validUntil: _emberData.default.attr('date'),
    usedAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string', {
      defaultValue: 'valid'
    }),
    value: _emberData.default.attr('big'),
    description: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    customer: _emberData.default.belongsTo('customer'),
    sale: _emberData.default.belongsTo('sale'),
    domain: _emberData.default.belongsTo('domain'),
    fStore: _emberData.default.belongsTo('f_store'),
    statusFormatted: Ember.computed('status', function () {
      var self;
      self = this;
      return this.get('constants.giftCouponStatus').find(function (i) {
        return i.value === self.get('status');
      }).description;
    }),
    codeFormatted: Ember.computed('code', function () {
      var s;
      s = this.get('code');
      return s.substring(0, 4) + '-' + s.substring(4, 8) + '-' + s.substring(8, 12);
    }),
    isUsed: Ember.computed('status', function () {
      return this.get('status') === 'used';
    })
  });

  _exports.default = _default;
});