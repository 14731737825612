define("api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/erro/ativar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gsvtGoJE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[0,\"Ativação de Empresa\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n    Ocorreu um erro na ativação da empresa \"],[1,[24,[\"empresa\",\"nome\"]],false],[0,\". Tente novamente mais tarde.\\n\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"data-dismiss\",\"modal\"],[10,\"type\",\"button\"],[8],[0,\"Continuar\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/erro/ativar-empresa.hbs"
    }
  });

  _exports.default = _default;
});