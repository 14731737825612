define("api-dashboard/components/cost-centers/cost-center-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    costCenters: null,
    shareDestinationTotalsCSSClass: '',
    shareDestinationTotal: 0.0,
    init: function init() {
      this.setupForm();
      return this._super.apply(this, arguments);
    },
    hasSourceShares: Ember.computed('model.destinationRatioCriterions', function () {
      return this.get('model.sourceRatioCriterions.length') > 0;
    }),
    shareDestinationTotalObserver: Ember.observer('destinationShares.@each.proporcao', function () {
      var destinationShares, i, j, k, len, s, total;
      total = parseFloat(0.0);
      destinationShares = this.get('destinationShares');

      for (i = k = 0, len = destinationShares.length; k < len; i = ++k) {
        j = destinationShares[i];
        s = destinationShares.objectAt(i);

        if (!isNaN(parseFloat(s.proporcao))) {
          total = parseFloat(total) + parseFloat(s.proporcao);
        }

        this.get('model.destinationRatioCriterions').addObject(s);
      }

      this.set('shareDestinationTotal', total.toFixed(2));

      if (!!this.get('model.rateavel') && total !== 100) {
        this.set('shareDestinationTotalsCSSClass', 'centro-custo-percent-success');
        return this.set('shareTotalsFull', false);
      } else {
        this.set('shareDestinationTotalsCSSClass', 'centro-custo-percent-error');
        return this.set('shareTotalsFull', true);
      }
    }),
    setupForm: function setupForm() {
      var self;
      self = this;
      return this.getCostCenters().then(function (costCenters) {
        return self.getDestinationShares(costCenters);
      });
    },
    getCostCenters: function getCostCenters() {
      return this.get('store').findAll('cost-center', {
        reload: true,
        include: 'destinationRatioCriterions'
      });
    },
    getDestinationShares: function getDestinationShares(costCenters) {
      var c, existingCostCenterIds, i, j, k, len, newCostCenters, newRatio, newShares, self, shares, sortedShares;
      self = this;
      shares = this.get('model.destinationRatioCriterions');
      existingCostCenterIds = shares.map(function (o) {
        return o.get('destinationCostCenter.id');
      });
      newShares = Ember.A();

      if (costCenters.length > 0) {
        newCostCenters = costCenters.filter(function (c) {
          return !existingCostCenterIds.includes(c.get('id')) && c.get('id') !== self.get('model.id');
        });

        for (i = k = 0, len = newCostCenters.length; k < len; i = ++k) {
          j = newCostCenters[i];
          c = newCostCenters.objectAt(i);
          newRatio = self.get('store').createRecord('ratio-criterion');
          newRatio.set('destinationCostCenter', c);
          newRatio.set('proporcao', 0);
          newShares.pushObject(newRatio);
        }
      }

      newShares.pushObjects(shares.toArray());
      sortedShares = newShares.toArray().sort(function (a, b) {
        if (a.proporcao === b.proporcao) {
          return b.descricao - a.descricao;
        }

        return b.proporcao - a.proporcao;
      });
      return self.set('destinationShares', sortedShares);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        this.get('model').rollbackAttributes();
        return this.sendAction('onCancel', record);
      },
      beforeValidate: function beforeValidate() {
        var i, j, k, len, results, s, shares;
        shares = this.get('model.destinationRatioCriterions');
        results = [];

        for (i = k = 0, len = shares.length; k < len; i = ++k) {
          j = shares[i];
          s = shares.objectAt(i);

          if (!s.get('proporcao') || s.get('proporcao') <= 0 || !this.get('model.rateavel')) {
            s.set('proporcao', 0);
            results.push(s.set('_destroy', true));
          } else {
            results.push(void 0);
          }
        }

        return results;
      },
      validateClient: function validateClient() {
        if (this.get('model.rateavel') && !this.get('shareTotalsFull')) {
          return [false, {
            errorMessages: ['A soma dos rateios deve ser 100%.']
          }];
        }

        return [true, {}];
      }
    }
  });

  _exports.default = _default;
});