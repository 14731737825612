define("api-dashboard/routes/supplier-categories/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('supplierCategory');
    },
    actions: {
      afterSave: function afterSave(record) {
        return this.transitionTo('supplier-categories/index');
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('supplier-categories/index');
      }
    }
  });

  _exports.default = _default;
});