define("api-dashboard/mixins/keyboard-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyDown_EnterAsTab: function keyDown_EnterAsTab(e, mainDiv) {
      var div, focusable, next;

      if ((e.ctrlKey || e.altKey || e.shiftKey) && (e.keyCode === 13 || e.key === 'Enter')) {
        return;
      }

      if (e.keyCode === 13 || e.key === 'Enter') {
        if (Ember.$(e.target).hasClass('js-exclude-tab')) {
          return true;
        }

        div = Ember.$(mainDiv);
        focusable = div.find('input,a,select,textarea,button').filter(':visible');
        next = focusable.eq(focusable.index(e.target) + 1);

        if (next.length) {
          next.focus();
          return e.preventDefault();
        }
      }
    }
  });

  _exports.default = _default;
});