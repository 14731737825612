define("api-dashboard/templates/components/customers/contact-info-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gketr2lN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-select\",null,[[\"value\",\"allowEmpty\",\"options\",\"displayField\",\"cols\"],[[24,[\"model\",\"contactType\"]],false,[24,[\"constants\",\"contactInfo\",\"typesWithLabel\"]],\"description\",\"3\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"isTypeTelefone\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inputs/input-number\",null,[[\"placeholder\",\"val\",\"cols\",\"maxLength\"],[[24,[\"label\"]],[24,[\"model\",\"value\"]],4,14]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"inputs/base-text-field\",null,[[\"placeholder\",\"val\",\"cols\"],[[24,[\"label\"]],[24,[\"model\",\"value\"]],\"4\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[28,\"inputs/base-text-field\",null,[[\"placeholder\",\"val\",\"cols\"],[\"Observação\",[24,[\"model\",\"comment\"]],\"4\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/customers/contact-info-form.hbs"
    }
  });

  _exports.default = _default;
});