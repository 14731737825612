define("api-dashboard/components/notas-fiscais/nota-fiscal-itens-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    item: null,
    product: null,
    errors: null,
    productInputAutocomplete: null,
    totalItemAmount: new Big(0.0),
    productCommentMaxLength: 100,
    init: function init() {
      this.createNewItem();
      return this._super.apply(this, arguments);
    },
    camposDisabled: Ember.computed('notaFiscal.isSaving', function () {
      return this.get('notaFiscal.isSaving');
    }),
    createNewItem: function createNewItem() {
      var item;
      item = this.get('store').createRecord('item');
      this.set('item', item);
      return this.set('errors', null);
    },
    productObserver: Ember.observer('item.product', function () {
      return this.setarValor(this.get('item.product'));
    }),
    notaFiscalObserver: Ember.observer('notaFiscal', function () {
      return this.set('item.notaFiscal', this.get('notaFiscal'));
    }),
    setarValor: function setarValor(product) {
      var i;

      if (product == null) {
        product = this.get('item.product');
      }

      if (product != null && product.get('price') != null) {
        this.set('item.valorUnitarioComercial', new Big(product.get('price')));
      }

      if (this.get('notaFiscal.emitente.obterDadosEntrada') && this.get('notaFiscal.tipoDocumento') === 1) {
        i = this.get('item').itemEntrada(product);

        if (i != null) {
          this.set('item.detalhe', new Big(i.get('detalhe')));
          return this.set('item.valorEntrada', new Big(i.get('valorBruto')));
        } else {
          this.set('item.detalhe', null);
          return this.set('item.valorEntrada', null);
        }
      }
    },
    cfopMessage: Ember.computed('item', 'item.cfop', 'notaFiscal', function () {
      var cfop, cfopMessage;
      cfop = this.get('item.cfop');

      if (cfop == null) {
        return false;
      }

      cfopMessage = cfop.validationMessage(this.get('notaFiscal'));

      if (cfopMessage == null || cfopMessage === '') {
        return false;
      }

      return cfopMessage;
    }),
    productCommentMaxLengthObserver: Ember.observer('item.product', function () {
      var maxLength, productDescription;

      if (this.get('item.product')) {
        productDescription = this.get('item.product.description');
        maxLength = 119 - productDescription.length > 100 ? 100 : 119 - productDescription.length;
        return this.set('productCommentMaxLength', maxLength);
      }
    }),
    updateModelProperties: function updateModelProperties() {
      var item;
      item = this.get('item');
      item.set('quantidadeTributavel', item.get('quantidadeComercial'));
      item.set('valorUnitarioTributavel', item.get('valorUnitarioComercial'));

      if (item.get('valorUnitarioComercial') != null && item.get('quantidadeComercial') != null) {
        item.set('valorBruto', item.get('quantidadeComercial') * item.get('valorUnitarioComercial'));
      }

      return item.set('notaFiscal', this.get('notaFiscal'));
    },
    actions: {
      inserir: function inserir() {
        var item, onFail, onSuccess, self;
        self = this;
        self.set('camposTotaisDisabled', true);
        self.set('camposDisabled', true);

        if (this.get('notaFiscal.naoCalcularImpostos') || this.get('notaFiscal.emitente.classeCalculoImpostos') === "") {
          item = this.get('item');
          return this.get('router').transitionTo('itens/new', {
            queryParams: {
              notaFiscalId: this.get('notaFiscal.id'),
              productId: item.get('product.id'),
              cfopId: item.get('cfop.id'),
              detalhe: item.get('detalhe'),
              quantidadeComercial: item.get('quantidadeComercial'),
              valorUnitarioComercial: item.get('valorUnitarioComercial'),
              valorEntrada: item.get('valorEntrada')
            }
          });
        } else {
          this.updateModelProperties();

          onSuccess = function onSuccess() {
            self.createNewItem();
            self.sendAction('saveNotaAction');
            self.get('productInputAutocomplete').setFocus();
            self.set('camposTotaisDisabled', false);
            return self.set('camposDisabled', false);
          };

          onFail = function onFail(reason) {
            var errors;
            errors = reason.errors;
            errors.forEach(function (err) {
              var msg;
              msg = err.source.pointer.split('/').pop() + ": " + err.detail;
              return self.get('application').showAlert(msg, 'error');
            });
            self.set('camposTotaisDisabled', false);
            return self.set('camposDisabled', false);
          };

          if (!this.get('item').get('isValid')) {
            this.get('item').send('becameValid');
          }

          return this.get('item').save().then(onSuccess, onFail);
        }
      },
      limpar: function limpar() {
        return this.createNewItem();
      },
      detalhes: function detalhes() {
        var item;
        item = this.get('item');
        this.updateModelProperties();

        if (item.get('isNew')) {
          return this.get('router').transitionTo('itens/new', {
            queryParams: {
              notaFiscalId: item.get('notaFiscal.id'),
              productId: item.get('product.id'),
              cfopId: item.get('cfop.id'),
              detalhe: item.get('detalhe'),
              quantidadeComercial: item.get('quantidadeComercial'),
              valorUnitarioComercial: item.get('valorUnitarioComercial'),
              valorEntrada: item.get('valorEntrada')
            }
          });
        } else {
          return this.get('router').transitionTo('itens/edit', item);
        }
      },
      editItem: function editItem(item) {
        return this.get('router').transitionTo('itens/edit', item);
      },
      destroyItem: function destroyItem(model) {
        var self;
        self = this;

        if (!this.get('camposDisabled') && window.confirm('Deseja realmente excluir este item?')) {
          self.set('camposTotaisDisabled', true);
          model.set('notaFiscal', null);
          return model.destroyRecord().then(function (_this) {
            return function (obj) {
              _this.sendAction('saveNotaAction');

              return self.set('camposTotaisDisabled', false);
            };
          }(this), function (obj) {
            model.rollbackAttributes();
            self.set('camposTotaisDisabled', false);
            return alert('Não foi possível excluir este item. Verifique se ele não esta sendo utilizado em outras áreas do sistema');
          });
        }
      }
    }
  });

  _exports.default = _default;
});