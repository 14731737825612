define("api-dashboard/controllers/nova-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NovaEmpresaController;
  NovaEmpresaController = Ember.Controller.extend({
    modoEdicao: false
  });
  var _default = NovaEmpresaController;
  _exports.default = _default;
});