define("api-dashboard/models/card-brand", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    createdAt: _emberData.default.attr('date'),
    debitTefParameter: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    tefParameter: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    cardCompanyBrands: _emberData.default.hasMany('card-company-brand', {
      inverse: 'cardBrand'
    }),
    cardInfos: _emberData.default.hasMany('card-info')
  });

  _exports.default = _default;
});