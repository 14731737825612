define("api-dashboard/components/inputs/input-radio-group", ["exports", "api-dashboard/components/base/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    className: 'row',
    options: null,
    oneliner: false,
    translationPrefix: '',
    computedOptions: Ember.computed('options', function () {
      var description, i, intl, j, o, options, ref, translationKey;
      options = this.get('options');
      intl = this.get('intl');

      for (i = j = 0, ref = options.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
        o = options.objectAt(i);
        translationKey = this.get('translationPrefix') + "." + Ember.String.decamelize(o.value);
        description = intl.exists(translationKey) ? intl.t(translationKey) : o.description;
        Ember.set(o, 'description', description);
      }

      return options;
    })
  });

  _exports.default = _default;
});