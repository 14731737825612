define("api-dashboard/components/spinners/bootstrap-pulse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SpinnersBootstrapPulseComponent;
  SpinnersBootstrapPulseComponent = Ember.Component.extend();
  var _default = SpinnersBootstrapPulseComponent;
  _exports.default = _default;
});