define("api-dashboard/models/ac-filter-def", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    reportTemplates: _emberData.default.hasMany('report-template'),
    acFilters: _emberData.default.hasMany('ac-filter')
  });

  _exports.default = _default;
});