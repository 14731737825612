define("api-dashboard/models/related-person", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    birthDay: _emberData.default.attr('number'),
    birthMonth: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    person: _emberData.default.belongsTo('customer', {
      inverse: 'relatedPeople'
    }),
    relationshipType: _emberData.default.belongsTo('relationshipType'),
    _destroy: _emberData.default.attr('boolean'),
    birthDate: null,
    removable: true
  });

  _exports.default = _default;
});