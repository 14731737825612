define("api-dashboard/components/base/dropdown-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  console.warn("Componente dropdown-list depreciado em favor de inputs/input-select ou inputs/input-select");

  var _default = Ember.Component.extend({
    classNames: ["dropdown"],
    actionOnOpcaoEscolhida: null,
    arrayLabelValue: [{
      label: "Opção 1",
      value: 1
    }, {
      label: "Opção 2",
      value: 2
    }],
    titulo: "Teste",
    valorInicial: null,
    didInsertElement: function didInsertElement() {
      return this.tratarValorInicial();
    },
    tratarValorInicial: function tratarValorInicial() {
      if (this.get("valorInicial")) {
        return this.$(".dropdown-toggle").text(this.get("arrayLabelValue").findBy("value", parseInt(this.get("valorInicial")))["label"]);
      }
    },
    escolherOpcao: function escolherOpcao(indexArrayLabelValue) {
      var labelValue;
      labelValue = this.get("arrayLabelValue").objectAt(indexArrayLabelValue);
      this.$(".dropdown-toggle").text(labelValue["label"]);
      return this.sendAction("actionOnOpcaoEscolhida", labelValue["value"]);
    },
    actions: {
      actEscolherOpcao: function actEscolherOpcao(indexArrayLabelValue) {
        return this.escolherOpcao(indexArrayLabelValue);
      }
    }
  });

  _exports.default = _default;
});