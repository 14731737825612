define("api-dashboard/models/promotion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    application: Ember.inject.service(),
    discountType: 'price',
    discountTypeSetInitial: true,
    name: _emberData.default.attr('string'),
    validFrom: _emberData.default.attr('date'),
    validTo: _emberData.default.attr('date'),
    active: _emberData.default.attr('boolean'),
    validAllStores: _emberData.default.attr('boolean'),
    itemType: _emberData.default.attr('string', {
      defaultValue: 'product'
    }),
    discountRate: _emberData.default.attr('big'),
    version: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    items: _emberData.default.hasMany('promotion-item', {
      inverse: 'promotion'
    }),
    promotionStores: _emberData.default.hasMany('promotion-store', {
      inverse: 'promotion'
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isDiscountRate: Ember.computed('discountType', function () {
      return false;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isDiscountPrice: Ember.computed('discountType', function () {
      return true;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    discountTypeDisabled: Ember.computed('itemType', 'items.length', function () {
      return true;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    itemTypeDisabled: Ember.computed('items.length', 'discountType', function () {
      return true;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isItemTypeProduct: Ember.computed('itemType', function () {
      return true;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isItemTypeCollection: Ember.computed('itemType', function () {
      return false;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isItemTypeSupplier: Ember.computed('itemType', function () {
      return false;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isItemTypeDepartment: Ember.computed('itemType', function () {
      return false;
    }),

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    isItemTypeProductType: Ember.computed('itemType', function () {
      return false;
    }),
    ready: function ready() {
      this.createEmptyPromotionStores();

      if (this.get('validAllStores') === void 0 || this.get('validAllStores') === null) {
        return this.set('validAllStores', false);
      }
    },
    createEmptyPromotionStores: function createEmptyPromotionStores() {
      var promotionStores, self, stores;
      self = this;

      if (!this.get('validAllStores')) {
        promotionStores = this.get('promotionStores');
        stores = this.get('application.currentDomain.visibleStores');

        if (promotionStores.get('length') === stores.get('length')) {
          return;
        }

        stores.forEach(function (store) {
          var promotionStore;
          promotionStore = promotionStores.find(function (item) {
            return item.get('fStore.id') === store.get('id');
          });

          if (!promotionStore) {
            return promotionStores.createRecord({
              promotion: self,
              fStore: store,
              active: false
            });
          }
        });
        return promotionStores.sortBy('fStore.name');
      }
    },

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */
    validAllStoresObserver: Ember.observer('validAllStores', function () {
      if (!this.get('validAllStores')) {
        return this.createEmptyPromotionStores();
      }
    })
    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */

    /* Deprecated. See: https://redmine.acras.com.br/issues/6067 */

  });

  _exports.default = _default;
});