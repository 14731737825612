define("api-dashboard/components/notas-fiscais-servico/notas-fiscais-servico-form", ["exports", "api-dashboard/mixins/components/nota-fiscal-servico"], function (_exports, _notaFiscalServico) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notaFiscalServico.default, {
    constants: Ember.inject.service(),
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    partialActions: 'components/notas-fiscais-servico/form-actions',
    buttonSpinning: false,
    itemListaServico: null,
    loadingSomething: false,
    issueNfseDisabled: false,
    init: function init() {
      var itemListaServicoMunicipio;
      itemListaServicoMunicipio = this.get('model.prestador.municipio.itemListaServicoType');

      if (!itemListaServicoMunicipio) {
        this.set('missing_item_lista_servico_type', true);

        this._super.apply(this, arguments);

        return;
      }

      this._prestadorObserver();

      return this._super.apply(this, arguments);
    },
    _prestadorObserver: Ember.observer('model.prestador', 'model.prestador.municipio', function () {
      var fiscalNature, itemListaServicoMunicipio;

      if (this.get('model.canEdit')) {
        itemListaServicoMunicipio = this.get('model.prestador.municipio.itemListaServicoType');
        fiscalNature = this.get("constants.nfseNaturezaOperacaoValues." + itemListaServicoMunicipio);
        fiscalNature || (fiscalNature = this.get("constants.nfseNaturezaOperacaoValues.abrasf"));
        this.set('fiscalNature', fiscalNature);
        this.set('naturezaOperacaoTranslationPrefix', "constants.nfseNaturezaOperacaoValues." + itemListaServicoMunicipio);
        this.set('itemListaServicoConstraint', itemListaServicoMunicipio);
        this.set('showCnaeCode', itemListaServicoMunicipio !== 'iss_sp');
        this.set('showCodigoTributarioMunicipio', itemListaServicoMunicipio !== 'iss_sp');
        return this.set('model.naturezaOperacao', itemListaServicoMunicipio === 'iss_sp' ? 'T' : '1');
      }
    }),
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      showCancelModal: function showCancelModal() {
        return this.get('cancelNFSeModal').show();
      },
      issueNfse: function issueNfse() {
        var msg, self;
        self = this;
        self.set('buttonSpinning', true);
        self.set('loadingSomething', true);
        self.set('issueNfseDisabled', true);
        msg = self.get('intl').t('globals.messages.confirm_send_service_invoice');

        if (confirm(msg)) {
          return this.get('model').save().then(function (success) {
            return self.get('model').sendInvoice(function (result) {
              msg = self.get('intl').t('globals.messages.alert_success_send_service_invoice');
              self.get('application').showAlert(msg, 'success');
              self.set('buttonSpinning', false);
              self.set('loadingSomething', false);
              self.get('model').reload();
              return self.set('issueNfseDisabled', false);
            }, function (error) {
              var error_msg;

              if (error) {
                error_msg = error['mensagem'];
                msg = self.get('intl').t('globals.messages.alert_error_send_service_invoice', {
                  error: error_msg
                });
              } else {
                msg = "Ocorreu um erro ao emitir a nota. Por favor contate o suporte técnico";
              }

              self.get('application').showAlert(msg, 'error');
              self.set('buttonSpinning', false);
              self.set('loadingSomething', false);
              return self.set('issueNfseDisabled', false);
            });
          }, function (errors) {
            msg = '';

            if (Array.isArray(errors)) {
              if (e.length > 0) {
                errors.errors.forEach(function (e) {
                  var field;
                  field = e.source.pointer.split("/").pop();
                  return msg += "Campo " + field + ": " + e.detail + " <br/>";
                });
              } else {
                msg = 'Ocorreu um erro ao emitir a NFS-e. Por favor contate o <b>suporte@acras.com.br</b>';
              }
            } else {
              msg = errors;
            }

            self.get('application').showAlert(msg, 'error');
            self.set('buttonSpinning', false);
            self.set('loadingSomething', false);
            return self.set('issueNfseDisabled', false);
          });
        }
      },
      searchNfse: function searchNfse() {
        var self;
        self = this;
        return self.set('model', this.get('store').findRecord('nota-fiscal-servico', this.get('model.id'), {
          reload: true
        }));
      }
    }
  });

  _exports.default = _default;
});