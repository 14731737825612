define("api-dashboard/serializers/trade-item", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      trade: {
        embedded: 'ids'
      },
      product: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});