define("api-dashboard/helpers/format-time.js", [], function () {
  "use strict";

  Ember.Helper.helper('format-date', function (value, options) {
    var format, m, utcOffset;
    utcOffset = null;

    try {
      format = options.hash.format || 'L';
    } catch (error) {
      format = 'L';
    }

    utcOffset = options.hash.utcOffset;
    m = moment(value);

    if (utcOffset != null) {
      m = m.utcOffset(utcOffset);
    }

    return m.format(format);
  });
});