define("api-dashboard/helpers/datetime/dd-mm-yyyy-hh-mm-ss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.datetimeDdMmYyyyHhMmSs = void 0;
  var DatetimeDdMmYyyyHhMmSsHelper, datetimeDdMmYyyyHhMmSs;
  _exports.datetimeDdMmYyyyHhMmSs = datetimeDdMmYyyyHhMmSs;

  _exports.datetimeDdMmYyyyHhMmSs = datetimeDdMmYyyyHhMmSs = function datetimeDdMmYyyyHhMmSs(params, namedParams) {
    var datetime, day, finalVal, hours, minutes, month, seconds, separador, year;
    console.warn("Helper datetime/dd-mm-yyyy-hh-mm-ss depreciado em favor de format/time");
    finalVal = "";

    try {
      finalVal = namedParams["datetime"];
      datetime = new Date(namedParams["datetime"]);
      day = datetime.getDate().toString();
      month = (datetime.getMonth() + 1).toString();
      year = datetime.getFullYear().toString();
      month = month.length === 1 ? "0" + month : month;
      day = day.length === 1 ? "0" + day : day;
      finalVal = day + "/" + month + "/" + year;
      hours = datetime.getHours().toString();
      minutes = datetime.getMinutes().toString();
      seconds = datetime.getSeconds().toString();
      hours = hours.length === 1 ? "0" + hours : hours;
      minutes = minutes.length === 1 ? "0" + minutes : minutes;
      seconds = seconds.length === 1 ? "0" + seconds : seconds;
      separador = !namedParams["separarPor"] ? " " : " " + namedParams["separarPor"] + " ";
      finalVal = finalVal + ("" + separador + hours + ":" + minutes + ":" + seconds);
    } catch (error) {}

    return finalVal;
  };

  DatetimeDdMmYyyyHhMmSsHelper = Ember.Helper.helper(datetimeDdMmYyyyHhMmSs);
  var _default = DatetimeDdMmYyyyHhMmSsHelper;
  _exports.default = _default;
});