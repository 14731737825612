define("api-dashboard/initializers/bayeux", ["exports", "api-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'bayeux',
    initialize: function initialize(application) {
      var app;
      app = application;
      return function (d, script) {
        script = d.createElement('script');
        script.type = 'text/javascript';
        script.async = true;

        script.onload = function () {};

        script.src = _environment.default.bayeux.URL + '/client.js';
        return d.getElementsByTagName('head')[0].appendChild(script);
      }(document);
    }
  };
  _exports.default = _default;
});