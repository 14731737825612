define("api-dashboard/components/inputs/input-time", ["exports", "ember-data", "api-dashboard/components/inputs/base-text-field"], function (_exports, _emberData, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend({
    intl: Ember.inject.service(),
    attributeBindings: ["isRequired:required, colsClassName"],
    classNames: ['col-xs-12'],
    disabled: false,
    displayFormat: 'HH:mm',
    isRequired: false,
    label: null,
    layoutName: 'components/inputs/input-time',
    max: void 0,
    min: void 0,
    showIcon: true,
    type: 'time',
    val: null,
    init: function init() {
      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self, val;
      self = this;
      val = this.get('val');

      if (val) {
        if (val.constructor === _emberData.default.PromiseObject) {
          val.then(function (o) {
            if (o) {
              return self.set('val', o);
            } else {
              return self.set('val', null);
            }
          });
        } else {
          self.set('val', val);
        }
      }

      return self.get('buildComponent').call(self);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var self;
      self = this;
      return self.get('buildComponent').call(self);
    },
    buildComponent: function buildComponent() {
      var opts, self;
      DevExpress.localization.locale(this.get('intl.primaryLocale'));
      self = this;
      opts = {
        invalidDateMessage: "Horário inválido!",
        max: this.get('max'),
        maxLength: 10,
        min: this.get('min'),
        opened: false,
        disabled: this.get('disabled'),
        onValueChanged: function onValueChanged(e) {
          return self.get('internalChange').call(self, e);
        },
        type: 'time',
        pickerType: 'rollers',
        value: self.get('val'),
        width: true
      };
      return this.$('#time-box').dxDateBox(opts).dxDateBox('instance');
    },
    internalChange: function internalChange(e) {
      return this.set('val', e.value);
    }
  });

  _exports.default = _default;
});