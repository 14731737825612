define("api-dashboard/templates/components/supplier-categories/supplier-category-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hfm44a4K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Cadastrando novo supplierCategory\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Editando supplierCategory\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],\"onSubmit\",\"onCancel\"]],{\"statements\":[[0,\"  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"cols\"],[\"Nome\",[24,[\"model\",\"name\"]],\"12\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/supplier-categories/supplier-category-form.hbs"
    }
  });

  _exports.default = _default;
});