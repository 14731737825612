define("api-dashboard/components/base/plus-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'plus-toggle',
    opened: false,
    click: function click(event) {
      return this.set('opened', !this.get('opened'));
    }
  });

  _exports.default = _default;
});