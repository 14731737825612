define("api-dashboard/components/inputs/input-money", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend({
    prefix: 'R$',
    acceptsSign: false,
    min: 0.00
  });

  _exports.default = _default;
});