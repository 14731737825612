define("api-dashboard/models/enabled-card-company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    _destroy: _emberData.default.attr('boolean'),
    cardCompanyConfig: _emberData.default.belongsTo('card-company-config'),
    cardBrand: _emberData.default.belongsTo('card-brand'),
    fStore: _emberData.default.belongsTo('f-store')
  });

  _exports.default = _default;
});