define("api-dashboard/helpers/long-string-shy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.longStringShy = void 0;
  var LongStringShyHelper, longStringShy;
  _exports.longStringShy = longStringShy;

  _exports.longStringShy = longStringShy = function longStringShy(params, namedArgs) {
    var limite, novoArray, palavras, resultado, string;
    limite = namedArgs["breakAt"];
    string = namedArgs["string"];
    palavras = new String(string).split(" ");
    novoArray = [];
    palavras.forEach(function (palavra) {
      var cont, novaPalavra, numShy, palavraShy, restoShy;
      novaPalavra = new String(palavra);

      if (novaPalavra.length > limite) {
        numShy = Math.floor(novaPalavra.length / limite);
        restoShy = novaPalavra.length % limite;
        palavraShy = "";
        cont = 0;

        while (cont < numShy) {
          palavraShy = palavraShy + novaPalavra.substring(cont * limite, limite * (cont + 1)) + "&shy;";
          cont++;
        }

        if (restoShy > 0) {
          palavraShy = palavraShy + novaPalavra.substring(limite * numShy, limite * numShy + restoShy);
        }

        novaPalavra = palavraShy;
      }

      return novoArray.push(novaPalavra);
    });
    resultado = novoArray.join(" ");
    return Ember.String.htmlSafe(resultado);
  };

  LongStringShyHelper = Ember.Helper.helper(longStringShy);
  var _default = LongStringShyHelper;
  _exports.default = _default;
});