define("api-dashboard/components/ac/general/plus-toggle", ["exports", "ember-ac-components/components/ac/general/plus-toggle"], function (_exports, _plusToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _plusToggle.default;
    }
  });
});