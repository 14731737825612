define("api-dashboard/helpers/before-comma", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.beforeComma = void 0;
  var beforeComma;
  _exports.beforeComma = beforeComma;

  _exports.beforeComma = beforeComma = function beforeComma(params, namedParams) {
    var value;
    value = params[0];

    if (!Ember.isEmpty(value)) {
      return ', ' + value;
    } else {
      return '';
    }
  };

  var _default = Ember.Helper.helper(beforeComma);

  _exports.default = _default;
});