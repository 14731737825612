define("api-dashboard/models/retorno-boleto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    accessToken: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    parseExceptionMessage: _emberData.default.attr('string'),
    mensagemErro: _emberData.default.attr('string'),
    mensagemAdvertencia: _emberData.default.attr('string'),
    conteudo: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    boletos: _emberData.default.hasMany('boleto'),
    contaBancaria: _emberData.default.belongsTo('conta-bancaria'),
    domain: _emberData.default.belongsTo('domain')
  });

  _exports.default = _default;
});