define("api-dashboard/templates/components/inputs/input-toggle-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gX5/Lbea",
    "block": "{\"symbols\":[\"o\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[22,\"label\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"inputs/input-toggle\",null,[[\"label\",\"value\",\"name\",\"class\"],[[23,1,[\"_display\"]],[23,1,[\"value\"]],[23,1,[\"name\"]],\"col-xs-12 col-sm-6 col-md-4\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-toggle-group.hbs"
    }
  });

  _exports.default = _default;
});