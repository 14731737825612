define("api-dashboard/components/base/base-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    intl: Ember.inject.service(),
    formattedLabel: Ember.computed(function () {
      if (this.get('intl').exists(this.get('label'))) {
        return this.get('intl').t(this.get('label'));
      }

      return this.get('label');
    })
  });

  _exports.default = _default;
});