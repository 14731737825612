define("api-dashboard/serializers/application", ["exports", "ember-data", "ember-inflector", "@ember-data/store"], function (_exports, _emberData, _emberInflector, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    store: Ember.inject.service(),
    _irregularKeys: [{
      key: 'store',
      model: 'f-store'
    }, {
      key: 'tomador',
      model: 'customer'
    }],
    normalize: function normalize(modelClass, hash) {
      var fields;
      fields = Object.keys(hash);
      fields.forEach(function (payloadField) {
        var deleteField, modelField, type;

        if (payloadField === 'id') {
          return;
        }

        modelField = Ember.String.camelize(payloadField);
        type = Ember.get(modelClass, 'fields').get(modelField);

        if (!type) {
          modelField = modelField.replace(/(id(s)?)$/gi, '');

          if (payloadField.endsWith('_ids') && !/(\d+$)/.test(modelField)) {
            modelField = (0, _emberInflector.pluralize)(modelField);
          }

          type = Ember.get(modelClass, 'fields').get(modelField);
        }

        deleteField = type !== 'belongsTo';

        if (type === 'attribute' && modelField === payloadField) {
          return;
        }

        if (type === 'hasMany') {
          deleteField = false;

          if (!hash.hasOwnProperty(payloadField)) {
            payloadField = (0, _emberInflector.pluralize)(payloadField.slice(0, -4));
          }
        }

        hash[modelField] = hash[Ember.String.camelize(payloadField)] || hash[payloadField];

        if (deleteField) {
          return delete hash[payloadField];
        }
      });
      return this._super(modelClass, hash);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var changedAttrs, model, value;
      model = snapshot.record;
      value = snapshot.record.get(key);

      if (model.get("isNew")) {
        if (value === null || value === void 0) {
          return;
        }
      } else {
        if (!attribute.options['alwaysDirty']) {
          changedAttrs = Object.keys(model.changedAttributes());

          if (changedAttrs.length === 0 || changedAttrs.indexOf(key) === -1) {
            return;
          }
        }
      }

      return this._super(snapshot, json, key, attribute);
    },
    keyForAttribute: function keyForAttribute(key, method) {
      if (method === "serialize") {
        return key.underscore();
      }

      return key.camelize();
    },
    keyForRelationship: function keyForRelationship(key, typeClass, method) {
      var result;

      if (!method) {
        return key;
      }

      if (method === 'deserialize') {
        return this._super(key, typeClass, method);
      }

      result = Ember.String.underscore(key);

      if (method === 'serialize') {
        if (typeClass === 'belongsTo') {
          result = key === 'fStore' ? 'store_id' : result + "_id";
        }

        if (typeClass === 'hasMany') {
          result = result + "_attributes";
        }
      }

      return result;
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      var irregularKey, normalizedKey, splittedKeys;
      splittedKeys = (0, _store.normalizeModelName)(key).split('-');
      splittedKeys.forEach(function (k, i) {
        return splittedKeys[i] = (0, _emberInflector.singularize)(k);
      });
      normalizedKey = splittedKeys.join('-');
      irregularKey = this.get('_irregularKeys').find(function (k) {
        return k.key === normalizedKey;
      });

      if (irregularKey) {
        return irregularKey.model;
      }

      return normalizedKey;
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var root;
      root = Ember.String.underscore(typeClass.modelName);
      return hash[root] = this.serialize(snapshot, options);
    }
  });

  _exports.default = _default;
});