define("api-dashboard/models/conta-bancaria", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    completeDescription: _emberData.default.attr('string'),
    agencia: _emberData.default.attr('string'),
    conta: _emberData.default.attr('string'),
    nomeCedente: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    banco: _emberData.default.belongsTo('banco', {
      async: false
    }),
    domain: _emberData.default.belongsTo('domain'),
    empresa: _emberData.default.belongsTo('emitente', {
      async: false
    }),
    configuracoesBoletos: _emberData.default.hasMany('configuracao-boleto'),
    addNewConfigBoleto: function addNewConfigBoleto() {
      var r;
      r = this.store.createRecord('configuracaoBoleto');
      r.set('domain', this.get('application.currentDomain'));
      r.set('cobrancaRegistrada', false);
      return this.get('configuracoesBoletos').addObject(r);
    }
  });

  _exports.default = _default;
});