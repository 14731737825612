define("api-dashboard/components/inputs/input-date", ["exports", "ember-data", "api-dashboard/components/inputs/base-text-field", "api-dashboard/mixins/keyboard-utils"], function (_exports, _emberData, _baseTextField, _keyboardUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend(_keyboardUtils.default, {
    intl: Ember.inject.service(),
    attributeBindings: ["isRequired:required, colsClassName"],
    classNames: [''],
    componentReference: null,
    disabled: false,
    displayFormat: 'dd/MM/yyyy',
    enterAsTab: false,
    isRequired: false,
    label: null,
    layoutName: 'components/inputs/input-date',
    max: void 0,
    maxZoomLevel: 'month',
    min: void 0,
    minZoomLevel: 'century',
    type: 'date',
    val: null,
    init: function init() {
      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self, val;
      self = this;
      val = this.get('val');

      if (val) {
        if (val.constructor === _emberData.default.PromiseObject) {
          val.then(function (o) {
            if (o) {
              return self.set('val', o);
            } else {
              return self.set('val', null);
            }
          });
        } else {
          self.set('val', val);
        }
      }

      return self.get('buildComponent').call(self);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var self;
      self = this;
      return self.get('buildComponent').call(self);
    },
    buildComponent: function buildComponent() {
      var comp, opts, self;
      self = this;
      opts = {
        displayFormat: this.get('displayFormat'),
        invalidDateMessage: "Data inválida!",
        max: this.get('max'),
        maxLength: 10,
        min: this.get('min'),
        opened: false,
        onKeyDown: function onKeyDown(e) {
          if (self.get('enterAsTab')) {
            return self.keyDown_EnterAsTab(e, 'div.painelFocus__container');
          }
        },
        onValueChanged: function onValueChanged(e) {
          return self.get('__onValueChange').call(self, e);
        },
        type: self.get('type'),
        useMaskBehavior: true,
        value: self.get('val'),
        width: true,
        calendarOptions: {
          maxZoomLevel: this.get('maxZoomLevel'),
          minZoomLevel: this.get('minZoomLevel')
        }
      };
      comp = this.$('#date-box').dxDateBox(opts).dxDateBox('instance');
      return this.set('componentReference', comp);
    },
    __onValueChange: function __onValueChange(event) {
      var m1, m2;
      m1 = moment(event.value);
      m2 = moment(this.get('val'));

      if (m1.diff(m2) !== 0) {
        return this.set('val', event.value);
      }
    }
  });

  _exports.default = _default;
});