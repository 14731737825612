define("api-dashboard/components/base/form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mostrarBotaoFechar: true,
    didInsertElement: function didInsertElement() {
      var alert;

      if (this.get("mostrarBotaoFechar")) {
        alert = this.$(".alert");
        return this.$(".close").on("touchstart click", function () {
          return alert.fadeOut("fast");
        });
      }
    }
  });

  _exports.default = _default;
});