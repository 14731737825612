define("api-dashboard/models/pos-report-file-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    posReportFiles: _emberData.default.hasMany('posReportFile'),
    vendaReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'VENDA'),
    danfeNfceReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'DANFE_NFCE'),
    cfeSatReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'SAT_CFE'),
    listVendasReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'LISTA_VENDAS'),
    carneReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'CARNE'),
    movDiariaReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'MOVIMENTACAO_DIARIA'),
    reciboReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'RECIBO'),
    movimentoCaixaReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'MOVIMENTO_CAIXA'),
    fechamentoCaixaReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'FECHAMENTO_CAIXA'),
    consignacaoReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'CONSIGNACAO'),
    consigAbertasReports: Ember.computed.filterBy('posReportFiles', 'reportType', 'LISTA_CONSIGNACOES_ABERTAS'),
    hasOneReport: function hasOneReport(report) {
      return this.get(report).length === 1;
    },
    hasOneVenda: Ember.computed('vendaReports', function () {
      return this.hasOneReport('vendaReports');
    }),
    hasOneDanfeNfce: Ember.computed('danfeNfceReports', function () {
      return this.hasOneReport('danfeNfceReports');
    }),
    hasOneCfeSat: Ember.computed('cfeSatReports', function () {
      return this.hasOneReport('cfeSatReports');
    }),
    hasOneListVendas: Ember.computed('listVendasReports', function () {
      return this.hasOneReport('listVendasReports');
    }),
    hasOneCarne: Ember.computed('carneReports', function () {
      return this.hasOneReport('carneReports');
    }),
    hasOneMovDiaria: Ember.computed('movDiariaReports', function () {
      return this.hasOneReport('movDiariaReports');
    }),
    hasOneRecibo: Ember.computed('reciboReports', function () {
      return this.hasOneReport('reciboReports');
    }),
    hasOneMovimentoCaixa: Ember.computed('movimentoCaixaReports', function () {
      return this.hasOneReport('movimentoCaixaReports');
    }),
    hasOneFechamentoCaixa: Ember.computed('fechamentoCaixaReports', function () {
      return this.hasOneReport('fechamentoCaixaReports');
    }),
    hasOneConsignacao: Ember.computed('consignacaoReports', function () {
      return this.hasOneReport('consignacaoReports');
    }),
    hasOneconsigAbertas: Ember.computed('consigAbertasReports', function () {
      return this.hasOneReport('consigAbertasReports');
    })
  });

  _exports.default = _default;
});