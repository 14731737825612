define("api-dashboard/models/cash-drawer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    closureTime: _emberData.default.attr('date'),
    openingTime: _emberData.default.attr('date'),
    domain: _emberData.default.belongsTo('domain'),
    pos: _emberData.default.belongsTo('pos'),
    posUser: _emberData.default.belongsTo('pos-user'),
    posEntries: _emberData.default.hasMany('pos-entry'),
    cashDrawerBalances: _emberData.default.hasMany('cash-drawer-balance'),
    csvUrl: Ember.computed('id', function () {
      return '/cash_drawers/' + this.get('id') + '.csv';
    })
  });

  _exports.default = _default;
});