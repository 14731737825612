define("api-dashboard/models/promotion-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    itemType: _emberData.default.attr('string'),
    price: _emberData.default.attr('big'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    version: _emberData.default.attr('number'),
    domainId: _emberData.default.attr('number'),
    _destroy: _emberData.default.attr('boolean'),
    promotion: _emberData.default.belongsTo('promotion', {
      inverse: 'items'
    }),
    collection: _emberData.default.belongsTo('collection'),
    department: _emberData.default.belongsTo('department'),
    product: _emberData.default.belongsTo('product'),
    productType: _emberData.default.belongsTo('product-type'),
    supplier: _emberData.default.belongsTo('supplier'),
    discountType: Ember.computed('promotion.discountType', function () {
      return this.get('promotion.discountType');
    }),
    discountRate: Ember.computed('promotion.discountRate', function () {
      return this.get('promotion.discountRate');
    }),
    label: Ember.computed('itemType', 'collection', 'department', 'product', 'productType', 'supplier', function () {
      switch (this.get('itemType')) {
        case 'Collection':
          return this.get('collection.name');

        case 'Department':
          return this.get('department.name');

        case 'Product':
          return this.get('product.descriptionWithDimensions');

        case 'ProductType':
          return this.get('productType.name');

        case 'Supplier':
          return this.get('supplier.name');

        default:
          return '';
      }
    }),
    priceWithoutDiscount: Ember.computed('price', 'product', function () {
      if (this.get('product') != null) {
        return this.get('product.price');
      } else {
        return 0;
      }
    }),
    priceWithDiscount: Ember.computed('price', 'product', 'discountRate', 'discountType', function () {
      var discountRate;

      if (this.get('discountType') === 'rate') {
        discountRate = this.get('discountRate');

        if (this.get('product') != null) {
          return this.get('product.price').times(1 - discountRate / 100);
        } else {
          return 0;
        }
      }

      return this.get('price');
    })
  });

  _exports.default = _default;
});