define("api-dashboard/models/modelo-nota-fiscal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    nome: _emberData.default.attr('string'),
    tipo: _emberData.default.attr('number'),
    naturezaOperacao: _emberData.default.attr('string'),
    informacoesAdicionaisContribuinte: _emberData.default.attr('string'),
    domain: _emberData.default.belongsTo('domain'),
    typeDescription: Ember.computed('tipo', function () {
      var tipo;
      tipo = this.get('tipo');
      return this.get('constants.tipoDocumentoValues').find(function (t) {
        return t.value === tipo;
      }).description;
    })
  });

  _exports.default = _default;
});