define("api-dashboard/routes/invoices/config/company/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var ajaxParams, self;
      self = this;
      ajaxParams = {
        method: 'GET',
        data: {},
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/customers/" + params['id']
      }, function (success, textStatus, data) {
        if (success) {
          self.get('store').pushPayload('customer', data.data);
          return self.get('store').peekRecord('customer', params['id']);
        } else {
          return {};
        }
      });
    }
  });

  _exports.default = _default;
});