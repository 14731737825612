define("api-dashboard/routes/busca-referencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BuscaReferenciaRoute;
  BuscaReferenciaRoute = Ember.Route.extend();
  var _default = BuscaReferenciaRoute;
  _exports.default = _default;
});