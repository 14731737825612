define("api-dashboard/components/pos-users/pos-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    init: function init() {
      this.set('searchingToggles', true);
      this.set('searchingStores', true);
      this.getStores();
      return this._super.apply(this, arguments);
    },
    getStores: function getStores() {
      var posUserStores, self, stores;
      self = this;
      posUserStores = self.get('model.posUserStores');
      stores = [];
      this.get('application.currentDomain.visibleStores').forEach(function (o) {
        var opt, posUserStore;
        posUserStore = posUserStores.find(function (store) {
          return parseInt(store.get('fStore.id')) === parseInt(o.get('id'));
        });
        opt = {};
        Ember.set(opt, 'id', o.get('id'));
        Ember.set(opt, 'name', o.get('name'));
        Ember.set(opt, 'store', o);
        Ember.set(opt, 'posUserStore', posUserStore);

        if (posUserStore) {
          Ember.set(opt, 'isManager', posUserStore.get('isManager'));
          Ember.set(opt, 'isSalesperson', posUserStore.get('isSalesperson'));
          Ember.set(opt, 'isCashDrawerOperator', posUserStore.get('isCashDrawerOperator'));
        } else {
          Ember.set(opt, 'isManager', false);
          Ember.set(opt, 'isSalesperson', false);
          Ember.set(opt, 'isCashDrawerOperator', false);
        }

        Ember.set(opt, 'value', !!posUserStore);
        return stores.push(opt);
      });
      self.set('stores', stores);
      return self.set('searchingStores', false);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      deactivatePosUser: function deactivatePosUser() {
        var msg;
        msg = "Tem certeza que deseja desativar Colaborador " + this.get('model.label') + "?";

        if (confirm(msg)) {
          this.set('model.active', false);
          return this.get('model').save();
        }
      },
      activatePosUser: function activatePosUser() {
        this.set('model.active', true);
        return this.get('model').save();
      },
      beforeValidate: function beforeValidate() {
        var i, j, new_pos_stores, opt, ref, results, selected_stores, user_store;
        new_pos_stores = [];
        selected_stores = this.get('stores');
        results = [];

        for (i = j = 0, ref = selected_stores.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
          opt = selected_stores.objectAt(i);

          if (opt.posUserStore) {
            user_store = opt.posUserStore;

            if (!opt.value) {
              Ember.set(user_store, '_destroy', true);
            }
          } else {
            if (!opt.value) {
              continue;
            }

            user_store = this.get('store').createRecord('pos-user-store');
            Ember.set(user_store, 'posUser', this.get('model'));
            Ember.set(user_store, 'fStore', opt.store);
          }

          Ember.set(user_store, 'isManager', opt.isManager);
          Ember.set(user_store, 'isSalesperson', opt.isSalesperson);
          Ember.set(user_store, 'isCashDrawerOperator', opt.isCashDrawerOperator);
          new_pos_stores.push(user_store);
          results.push(this.set('model.posUserStores', new_pos_stores));
        }

        return results;
      }
    }
  });

  _exports.default = _default;
});