define("api-dashboard/models/conta-contabil", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    codigo: _emberData.default.attr('string'),
    codigoCompleto: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    descricao: _emberData.default.attr('string'),
    direto: _emberData.default.attr('boolean'),
    fixo: _emberData.default.attr('boolean'),
    informarBoleto: _emberData.default.attr('boolean'),
    informarCheque: _emberData.default.attr('boolean'),
    informarNome: _emberData.default.attr('boolean'),
    informarPagador: _emberData.default.attr('boolean'),
    informarRegistroCliente: _emberData.default.attr('boolean'),
    lft: _emberData.default.attr('number'),
    nome: _emberData.default.attr('string'),
    obrigarboleto: _emberData.default.attr('boolean'),
    obrigarCheque: _emberData.default.attr('boolean'),
    obrigarNome: _emberData.default.attr('boolean'),
    obrigarPagador: _emberData.default.attr('boolean'),
    obrigarRegistroCliente: _emberData.default.attr('boolean'),
    operacional: _emberData.default.attr('boolean'),
    permiteRecebimentoPdv: _emberData.default.attr('boolean'),
    rgt: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    tipo: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    domain: _emberData.default.belongsTo('domain'),
    contaContabilPai: _emberData.default.belongsTo('conta-contabil', {
      inverse: null
    }),
    modelosLancamento: _emberData.default.hasMany('modelo-lancamento'),
    completeDescription: Ember.computed('codigoCompleto', 'nome', function () {
      if (this.get('isNew')) {
        return 'Nova Conta Contábil';
      }

      return this.get('codigoCompleto') + " - " + this.get('nome');
    })
  });

  _exports.default = _default;
});