define("api-dashboard/controllers/selecao-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    selecionandoDomain: false,
    actions: {
      actSelecionarDomain: function actSelecionarDomain(r) {
        var self;
        self = this;
        return this.get('application').setCurrentDomainId(r['id'], function (d) {
          return self.transitionToRoute("index");
        });
      }
    }
  });

  _exports.default = _default;
});