define("api-dashboard/serializers/domain", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      visibleStores: {
        embedded: "always"
      },
      allStores: {
        embedded: "always"
      },
      productTypes: {
        embedded: "always"
      },
      dimensions: {
        embedded: "always"
      },
      departments: {
        embedded: "always"
      },
      parametros: {
        embedded: "always"
      },
      pos: {
        embedded: "always"
      },
      collections: {
        embedded: "always"
      },
      suppliers: {
        embedded: "always"
      },
      emitentes: {
        embedded: 'always'
      },
      labelModels: {
        embedded: 'always'
      },
      prestadores: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});