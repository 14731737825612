define("api-dashboard/templates/components/busca-referencias/busca-referencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I7bkXYzy",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"busca-referencias/form-busca-referencias\",null,[[\"actionOnSubmeterFormulario\"],[\"actFazerNovaBusca\"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"clear-20\"],[8],[9],[0,\"\\n\"],[1,[28,\"busca-referencias/grid-referencias\",null,[[\"parametrosBusca\"],[[24,[\"parametrosBusca\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/busca-referencias/busca-referencias.hbs"
    }
  });

  _exports.default = _default;
});