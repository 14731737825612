define("api-dashboard/helpers/raw-to-user/month-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.rawToUserMonthDescription = void 0;
  var RawToUserMonthDescriptionHelper, rawToUserMonthDescription;
  _exports.rawToUserMonthDescription = rawToUserMonthDescription;

  _exports.rawToUserMonthDescription = rawToUserMonthDescription = function rawToUserMonthDescription(params, namedArgs) {
    var func, monthDescription;
    console.warn("Helper datetime/dd-mm-yyyy depreciado em favor de nova nomenclatura");
    func = namedArgs["function"];

    try {
      monthDescription = func(parseInt(params[0]));

      if (namedArgs["toLowerCase"]) {
        monthDescription = monthDescription.toLowerCase();
      }
    } catch (error) {
      monthDescription = params[0];
    }

    return monthDescription;
  };

  RawToUserMonthDescriptionHelper = Ember.Helper.helper(rawToUserMonthDescription);
  var _default = RawToUserMonthDescriptionHelper;
  _exports.default = _default;
});