define("api-dashboard/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    logradouro: _emberData.default.attr('string'),
    numero: _emberData.default.attr('string'),
    complemento: _emberData.default.attr('string'),
    bairro: _emberData.default.attr('string'),
    cep: _emberData.default.attr('string'),
    addressType: _emberData.default.attr('string'),
    _destroy: _emberData.default.attr('boolean'),
    domain: _emberData.default.belongsTo('domain'),
    municipio: _emberData.default.belongsTo('municipio'),
    pais: _emberData.default.belongsTo('pais', {
      defaultValue: {
        id: 30,
        nome: 'BRASIL'
      }
    }),
    person: _emberData.default.belongsTo('person', {
      inverse: 'addresses'
    }),
    customer: _emberData.default.belongsTo('customer', {
      inverse: 'addresses'
    }),
    enderecoCompleto: Ember.computed('logradouro', 'numero', 'complemento', 'bairro', 'municipio.nomeMunicipio', 'municipio.siglaUf', function () {
      var bairro, cidade, complemento, estado, s;
      complemento = this.get('complemento');
      bairro = this.get('bairro');
      cidade = this.get('municipio.nomeMunicipio');
      estado = this.get('municipio.siglaUf');
      s = this.get('logradouro') + ', ' + this.get('numero');

      if (!Ember.isBlank(complemento)) {
        s += ", " + complemento;
      }

      if (!Ember.isBlank(bairro)) {
        s += ", " + bairro;
      }

      if (!Ember.isBlank(cidade)) {
        s += " - " + cidade;
      }

      if (!Ember.isBlank(estado)) {
        return s += "/" + estado;
      }
    }),
    enderecoCompletoSemUf: Ember.computed('logradouro', 'numero', 'complemento', 'bairro', function () {
      var bairro, complemento, s;
      complemento = this.get('complemento');
      bairro = this.get('bairro');
      s = this.get('logradouro') + ', ' + this.get('numero');

      if (!Ember.isBlank(complemento)) {
        s += ", " + complemento;
      }

      if (!Ember.isBlank(bairro)) {
        return s += ", " + bairro;
      }
    })
  });

  _exports.default = _default;
});