define("api-dashboard/models/cash-drawer-balance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    balanceType: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    informedValue: _emberData.default.attr('number'),
    systemValue: _emberData.default.attr('number'),
    typeDescription: _emberData.default.attr('string'),
    value: _emberData.default.attr('number'),
    cashDrawer: _emberData.default.belongsTo('cash-drawer'),
    isClosure: Ember.computed('balanceType', function () {
      return this.get('balanceType') === 'closure';
    })
  });

  _exports.default = _default;
});