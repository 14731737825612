define("api-dashboard/templates/sales/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cla15i12",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"sales/sales-form\",null,[[\"model\",\"afterSave\",\"onCancel\",\"afterCreateNfe\"],[[24,[\"model\"]],\"afterSave\",\"onCancel\",\"afterCreateNfe\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/sales/edit.hbs"
    }
  });

  _exports.default = _default;
});