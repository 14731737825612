define("api-dashboard/models/parcela-lancamento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dataBaixado: _emberData.default.attr('date'),
    dataCancelamento: _emberData.default.attr('date'),
    dataRealizado: _emberData.default.attr('date'),
    dataVencimento: _emberData.default.attr('date'),
    entryType: _emberData.default.attr('string'),
    numParcela: _emberData.default.attr('number'),
    valor: _emberData.default.attr('big'),
    valorDesconto: _emberData.default.attr('big'),
    valorJuros: _emberData.default.attr('big'),
    _destroy: _emberData.default.attr('boolean'),
    boletos2: _emberData.default.hasMany('boleto', {
      inverse: 'parcelasLancamento'
    }),
    boletos: _emberData.default.hasMany('boleto', {
      inverse: 'parcelaLancamento'
    }),
    check: _emberData.default.belongsTo('check'),
    domain: _emberData.default.belongsTo('domain'),
    fStore: _emberData.default.belongsTo('f-store'),
    lancamento: _emberData.default.belongsTo('lancamento', {
      inverse: 'parcelasLancamento'
    }),
    payingPosEntry: _emberData.default.belongsTo('pos-entry'),
    person: _emberData.default.belongsTo('person'),
    pos: _emberData.default.belongsTo('pos'),
    posEntry: _emberData.default.belongsTo('pos-entry'),
    sale: _emberData.default.belongsTo('sale'),
    ultimoBoletoValido: _emberData.default.belongsTo('boleto'),
    saldo: Ember.computed('valor', 'valorDesconto', 'valorJuros', function () {
      return this.get('valor') - this.get('valorDesconto') + this.get('valorJuros');
    }),
    valorLiquido: Ember.computed('valor', 'valorDesconto', 'valorJuros', function () {
      return parseFloat(this.get('valor')) - parseFloat(this.get('valorDesconto')) + parseFloat(this.get('valorJuros'));
    }),
    status: Ember.computed('dataRealizado', 'dataBaixado', 'dataCancelado', function () {
      var status;
      status = 'Pendente';

      if (this.get('dataRealizado')) {
        status = 'Pago';
      } else if (this.get('dataCancelado')) {
        status = 'Cancelado';
      }

      return status;
    }),
    dataVencimentoFormatted: Ember.computed('dataVencimento', function () {
      return moment(this.get('dataVencimento')).utc().format('DD/MM/YYYY');
    }),
    descricaoFormatted: Ember.computed('lancamento.descricao', 'lancamento.numParcelas', 'numParcela', function () {
      if (this.get('lancamento.numParcelas') > 1) {
        return (this.get('lancamento.descricao') || "") + " (" + this.get('numParcela') + "/" + this.get('lancamento.numParcelas') + ")";
      } else {
        return this.get('lancamento.descricao');
      }
    })
  });

  _exports.default = _default;
});