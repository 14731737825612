define("api-dashboard/models/tax-rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    siglaUf: _emberData.default.attr('string'),
    taxType: _emberData.default.attr('string'),
    taxRate: _emberData.default.attr('number'),
    _destroy: _emberData.default.attr('boolean'),
    readOnly: false,
    taxTypeChanged: Ember.observer('taxType', function () {
      switch (this.get('taxType')) {
        case "N":
        case "I":
        case "F":
          this.set('taxRate', 0);
          return this.set('readOnly', true);

        default:
          return this.set('readOnly', false);
      }
    })
  });

  _exports.default = _default;
});