define("api-dashboard/models/trade-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cost: _emberData.default.attr('number'),
    number: _emberData.default.attr('number'),
    price: _emberData.default.attr('big'),
    quantity: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    taxRate: _emberData.default.attr('number'),
    taxType: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    promotionId: _emberData.default.attr('number'),
    checkAttributes: _emberData.default.attr(),
    returnSaleId: _emberData.default.attr('number'),
    trade: _emberData.default.belongsTo('trade', {
      inverse: 'items'
    }),
    product: _emberData.default.belongsTo('product'),
    _destroy: _emberData.default.attr('boolean'),
    totalPrice: Ember.computed('price', 'quantity', function () {
      var price, qtd;
      price = this.get('price');

      if (price == null) {
        return null;
      }

      qtd = this.get('quantity');
      return new Big(price).times(qtd).round(2);
    }),
    totalAverageCost: Ember.computed('product.averageCost', 'quantity', function () {
      var averageCost, qtd;
      averageCost = this.get('product.averageCost');

      if (averageCost == null) {
        return null;
      }

      qtd = this.get('quantity');
      return new Big(averageCost).times(qtd).round(2);
    }),
    statusFormatted: Ember.computed('status', function () {
      var text;

      text = function () {
        switch (this.get('status')) {
          case 'realized':
            return 'Realizado';

          case 'canceled':
            return 'Cancelado';

          case 'canceled_pos':
            return 'Cancelado no PDV';

          default:
            return '';
        }
      }.call(this);

      return text;
    }),
    createdAtFormatted: Ember.computed('createdAt', function () {
      var d;
      d = this.get('createdAt');
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    }),
    increase: Ember.computed('kind', function () {
      return this.get('kind') === 'increase';
    }),
    decrease: Ember.computed('kind', function () {
      return this.get('kind') === 'decrease';
    })
  });

  _exports.default = _default;
});