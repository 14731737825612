define("api-dashboard/components/customers/customers-index", ["exports", "ember-ac-components/components/ac/views/omnisearch-index", "api-dashboard/mixins/components/customer"], function (_exports, _omnisearchIndex, _customer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_customer.default, {
    application: Ember.inject.service(),
    model: null,
    searchModelName: 'customer',
    title: 'Cliente',
    titlePlural: 'Clientes',
    newRoute: 'customers/new',
    editRoute: 'customers/edit',
    partialActions: 'components/customers/index-actions',
    customSearchButton: true,
    blockCustomerModal: null,
    selectedCustomer: null,
    loadingIndexAction: false,
    allowDelete: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }, {
      field: 'document',
      label: 'Documento'
    }],
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Cadastro De',
        param: 'created_at_from',
        value: null,
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        }
      }, {
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Cadastro Até',
        param: 'created_at_to',
        value: null,
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        }
      }, {
        component: 'inputs/input-select',
        displayField: 'name',
        field: 'fStore',
        label: 'Loja de Origem',
        options: self.get('application.currentDomain.visible_stores'),
        param: 'store_id',
        valueField: 'id'
      }, {
        field: 'razaoSocial',
        label: 'Razão Social',
        param: 'razao_social'
      }, {
        component: 'inputs/input-toggle',
        field: 'active',
        label: 'Incluir Inativos',
        param: 'incluir_inativos',
        value: false
      }];
    }),
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});