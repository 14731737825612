define("api-dashboard/components/sales/sales-pos-web", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    pageTitle: 'NFC-e',
    _productListColumns: ['description', 'quantity', 'priceEditable', 'totalPrice'],
    dropdownButtonSelectedAction: null,
    formActionsPartial: 'components/sales/sales-pos-web-form-actions',
    showSaleTypes: false,
    dropdownButtonActions: Ember.computed('model.pos', function () {
      var pos;
      pos = this.get('model.pos');

      if (pos.get('enableWebNfce')) {
        return [{
          label: 'Emitir NFCe',
          icon: 'fas fa-file-invoice-dollar',
          action: Ember.run.bind(this, this.get('actions.placeOrderWithNfce'))
        }];
      }

      if (!pos.get('enableWebNfce')) {
        if (pos.get('enableWebSale') && pos.get('storeConfigEmiteNFce') && pos.get('storeConfigPermiteNaoEmitirNfce')) {
          return [{
            label: 'Efetuar Venda (Sem NFC-e)',
            icon: 'fas fa-cash-register',
            action: Ember.run.bind(this, this.get('actions.placeOrder'))
          }, {
            label: 'Efetuar Venda (Com NFC-e)',
            icon: 'fas fa-file-invoice-dollar',
            action: Ember.run.bind(this, this.get('actions.placeOrderWithNfce'))
          }];
        }

        if (pos.get('enableWebSale') && pos.get('storeConfigEmiteNFce')) {
          return [{
            label: 'Efetuar Venda',
            icon: 'fas fa-file-invoice-dollar',
            action: Ember.run.bind(this, this.get('actions.placeOrderWithNfce'))
          }];
        }

        if (pos.get('enableWebSale')) {
          this.set('pageTitle', 'Venda');
          return [{
            label: 'Efetuar Venda',
            icon: 'fas fa-cash-register',
            action: Ember.run.bind(this, this.get('actions.placeOrder'))
          }];
        }
      }

      return [{
        label: 'Selecione o PDV',
        icon: 'fas fa-cash-register'
      }];
    }),
    modelPosObserver: Ember.observer('model.pos', function () {
      this.get('dropdownButtonActions');
      return this.__getCardCompanies();
    }),
    showPos: Ember.computed('globalLookups.webNfcePos.length', function () {
      return this.get('globalLookups.webNfcePos.length') > 1;
    }),
    showPosUsers: Ember.computed('globalLookups.salesPeople.length', function () {
      return this.get('globalLookups.salesPeople.length') > 1;
    }),
    paymentTypes: Ember.computed(function () {
      var k, paymentTypes, ref, v;
      paymentTypes = [];
      ref = this.get('constants.saleCheckoutPaymentTypes');

      for (k in ref) {
        v = ref[k];
        paymentTypes.addObject(v);
      }

      return paymentTypes;
    }),
    init: function init() {
      var self;
      window.addEventListener('wheel', {
        passive: false
      });
      self = this;

      if (this.get('application.currentDomain.hasWebPos') && !this.get('showPos')) {
        this.get('globalLookups.webNfcePos').then(function (objs) {
          return self.set('model.pos', objs.objectAt(0));
        });
      }

      if (!this.get('showPosUsers')) {
        this.get('globalLookups.salesPeople').then(function (objs) {
          return self.set('model.posUser', objs.objectAt(0));
        });
      }

      this.get('globalLookups.saleTypes').then(function (objs) {
        if (objs.length === 1) {
          self.set('model.saleType', objs.objectAt(0));
          return self.set('showSaleTypes', false);
        } else {
          return self.set('showSaleTypes', objs.length > 0);
        }
      });
      return this._super.apply(this, arguments);
    },
    __getCardCompanies: function __getCardCompanies() {
      var self;
      self = this;

      if (self.get('model.pos.id')) {
        return self.get('globalLookups').cardInfos(self.get('model.pos.id')).then(function (objs) {
          var cardCompanies;
          cardCompanies = [];
          objs.map(function (o) {
            var exists;
            exists = cardCompanies.find(function (c) {
              return c.get('id') === o.get('cardCompany.id');
            });

            if (!exists) {
              return cardCompanies.addObject(o.get('cardCompany'));
            }
          });
          return self.set('cardCompanies', cardCompanies);
        });
      }
    },
    __getCardCompanyInfos: function __getCardCompanyInfos(posEntry) {
      var self;
      self = this;

      if (posEntry.get('cardCompany.id')) {
        return self.get('globalLookups').cardInfos(self.get('model.pos.id')).then(function (objs) {
          var cardInfos, filterMethod, mapMethod;
          cardInfos = [];

          filterMethod = function filterMethod(item) {
            return item.get('cardCompany.id') === posEntry.get('cardCompany.id');
          };

          mapMethod = function mapMethod(item) {
            var auxArr, maxNumInstallments;
            auxArr = [];

            if (item.get('acceptsDebit') || item.get('acceptsInstallmentDebit')) {
              maxNumInstallments = item.get('maxNumInstallments');
              maxNumInstallments = item.get('acceptsInstallmentDebit') ? maxNumInstallments : 1;
              auxArr.addObject({
                cardInfo: item,
                description: item.get('cardBrand.name').toUpperCase() + ' | Débito',
                cardType: 'D',
                acceptsInstallments: item.get('acceptsInstallmentDebit'),
                maxNumInstallments: maxNumInstallments
              });
            }

            if (item.get('acceptsRevolvingCredit') || item.get('acceptsInstallmentCredit')) {
              maxNumInstallments = item.get('maxNumInstallments');
              maxNumInstallments = item.get('acceptsInstallmentCredit') ? maxNumInstallments : 1;
              auxArr.addObject({
                cardInfo: item,
                description: item.get('cardBrand.name').toUpperCase() + ' | Crédito',
                cardType: 'C',
                acceptsInstallments: item.get('acceptsInstallmentCredit'),
                maxNumInstallments: maxNumInstallments
              });
            }

            return auxArr;
          };

          cardInfos = [].concat.apply([], objs.filter(filterMethod).map(mapMethod));
          self.set('cardInfos', cardInfos);
          return self.set('onChangeCardCompanyCalled', false);
        });
      }
    },
    __validateSale: function __validateSale() {
      var isValid, self;
      self = this;
      isValid = this.get('model').validate();
      this.get('model.errors').forEach(function (err) {
        return self.get('application').showAlert(err.message, 'error');
      });

      if (!isValid) {
        return isValid;
      }

      isValid = this.__validateSoldItems();

      if (!isValid) {
        return isValid;
      }

      return this.__validateEntries();
    },
    __validateEntries: function __validateEntries() {
      var errors, self;
      self = this;
      errors = [];
      this.get('model.posEntries').forEach(function (entry) {
        if (!entry.validate()) {
          return errors.addObjects(entry.errors);
        }
      });
      errors.forEach(function (err) {
        return self.get('application').showAlert(err.message, 'error');
      });
      return errors.length === 0;
    },
    __validateSoldItems: function __validateSoldItems() {
      var errors, self;
      self = this;
      errors = [];
      this.get('model.soldItems').forEach(function (item) {
        if (item.get('totalPrice') < 0.01) {
          return errors.addObject({
            message: "O valor do item " + item.get('product.description') + " não pode ser zero!"
          });
        }
      });
      errors.forEach(function (err) {
        return self.get('application').showAlert(err.message, 'error');
      });
      return errors.length === 0;
    },
    __placeOrder: function __placeOrder() {
      var self;
      self = this;
      self.set('issuingNfce', true);

      if (!this.__validateSale()) {
        self.set('issuingNfce', false);
        return;
      }

      self.set('model.status', 'realized');
      self.get('model.posEntries').forEach(function (e) {
        e.set('date', new Date());
        e.set('pos', self.get('model.pos'));
        return e.set('sale', self.get('model'));
      });
      return self.get('model').save().then(function (obj) {
        self.set('issuingNfce', false);
        return self.get('router').transitionTo('sales/edit', obj.get('id'));
      }, function (obj) {
        var msg;
        msg = "Erro ao realizar a Operação! por favor, corrija os erros ou ";
        msg += "consulte a equipe de suporte! Erros: ";
        msg += "<br/>";

        if (obj.errors) {
          obj.errors.forEach(function (e) {
            if (typeof e === 'string') {
              return msg += "<li>" + e + "</li>";
            } else {
              return msg += "<li>" + e[1] + "</li>";
            }
          });
        } else {
          msg += obj.msg;
        }

        self.set('issuingNfce', false);
        return self.get('application').showAlert(msg, 'error');
      });
    },
    actions: {
      addPaymentMethod: function addPaymentMethod() {
        var paymentMethod;

        if (!this.get('model.pos.id')) {
          this.get('application').showAlert('É necessário escolher o Ponto de Venda antes de adicionar ' + 'os pagamentos!', 'error');
          return;
        }

        paymentMethod = this.get('model.posEntries').createRecord('pos-entry');
        return paymentMethod.set('pos', this.get('model.pos'));
      },
      addMoneyCheck: function addMoneyCheck(paymentMethod) {
        var moneyCheck;
        moneyCheck = this.get('store').createRecord('check');
        moneyCheck.set('posEntry', paymentMethod);
        return paymentMethod.get('check').addObject(moneyCheck);
      },
      removeAllPayments: function removeAllPayments() {
        return this.set('model.posEntries.length', 0);
      },
      removePaymentMethod: function removePaymentMethod(paymentMethod) {
        return this.get('model.posEntries').removeObject(paymentMethod);
      },
      cancel: function cancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      placeOrder: function placeOrder() {
        return this.__placeOrder();
      },
      placeOrderWithNfce: function placeOrderWithNfce() {
        this.set('model.isWebNfce', true);
        return this.__placeOrder();
      },
      onChangePos: function onChangePos() {
        return this.__getCardCompanies();
      },
      onChangeCardCompany: function onChangeCardCompany(posEntry) {
        if (!this.get('onChangeCardCompanyCalled')) {
          this.set('onChangeCardCompanyCalled', true);
          return this.__getCardCompanyInfos(posEntry);
        }
      }
    }
  });

  _exports.default = _default;
});