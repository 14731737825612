define("api-dashboard/components/inputs/tree-view", ["exports", "api-dashboard/mixins/dev-express", "ember-data"], function (_exports, _devExpress, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_devExpress.default, {
    classNameBindings: ['colsClassName'],
    classNames: ['col-xs-12 col-sm-12 col-md-5 treeView--scroll scrollStyle'],
    cols: 6,
    componentReference: null,
    dataSource: null,
    didUpdateAttrs: function didUpdateAttrs() {
      var self;
      return self = this;
    },
    _internal_on_change: function _internal_on_change(e) {
      var self;
      self = this;

      if (e.previousValue !== e.value) {
        this.set('value', e.value);
      }

      if (self.get('onChange')) {
        return self.get('onChange')(e.previousValue, e.value, e);
      }
    }
  });

  _exports.default = _default;
});