define("api-dashboard/components/totais-nf-cnpj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TotaisNfCnpjComponent;
  TotaisNfCnpjComponent = Ember.Component.extend();
  var _default = TotaisNfCnpjComponent;
  _exports.default = _default;
});