define("api-dashboard/components/notas-fiscais/nota-fiscal-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/utils", "api-dashboard/mixins/components/nota-fiscal"], function (_exports, _omnisearchIndex, _utils, _notaFiscal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_utils.default, _notaFiscal.default, {
    router: Ember.inject.service(),
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    title: 'Nota Fiscal Eletrônica (NFe)',
    titlePlural: 'Notas Fiscais Eletrônicas (NFe)',
    newRoute: 'notas-fiscais/new',
    editRoute: 'notas-fiscais/edit',
    layoutColumns: 12,
    partialActions: 'components/notas-fiscais/-index-more-actions',
    partialAfterTitle: 'components/notas-fiscais/-index-cancel-nfe',
    searchModelName: 'nota-fiscal',
    hideDefaultActionButtons: true,
    newDropdownRoutes: [],
    dropdownButtonSelectedAction: null,
    columns: Ember.computed(function () {
      var r;
      r = [{
        field: 'numero',
        label: 'Número'
      }, {
        field: 'serie',
        label: 'Série'
      }, {
        field: 'descricaoStatus',
        label: 'Status'
      }];
      r.push({
        field: 'emitente.nameAndDocument',
        label: 'Emitente'
      });
      r.push({
        field: 'person.nameAndDocument',
        label: 'Destinatário'
      });
      r.push({
        field: 'valorTotal',
        label: 'Valor',
        format: 'big'
      });
      r.push({
        field: 'dataEmissao',
        label: 'Data de Emissão',
        format: 'date'
      });
      r.push({
        field: 'createdAt',
        label: 'Data de Criação',
        format: 'date'
      });
      return r;
    }),
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        label: 'Número',
        field: 'numero',
        param: 'numero',
        cols: 6
      }, {
        label: 'Status',
        field: 'status',
        param: 'status',
        displayField: 'description',
        valueField: 'value',
        component: 'inputs/input-select',
        options: self.get('constants.nfeStatus'),
        value: null,
        cols: 6
      }, {
        label: 'Data Emissão De',
        field: 'dataEmissaoIni',
        param: 'data_emissao_ini',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        },
        component: 'inputs/input-date',
        cols: 6
      }, {
        label: 'Data Emissão Até',
        field: 'dataEmissaoFim',
        param: 'data_emissao_fim',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        },
        component: 'inputs/input-date',
        cols: 6
      }, {
        label: 'Destinatário',
        field: 'destinatario.id',
        param: 'person_id',
        component: 'inputs/input-autocomplete',
        modelName: 'person',
        suggestionField: 'name',
        selectedRecord: null,
        value: null,
        cols: 6
      }, {
        label: 'Emitente',
        field: 'emitenteId',
        param: 'emitente_id',
        component: 'inputs/input-autocomplete',
        modelName: 'emitente',
        suggestionField: 'nome',
        selectedRecord: null,
        value: null,
        cols: 6
      }];
    }),
    init: function init() {
      this.getModelosNfe();
      return this._super.apply(this, arguments);
    },
    getModelosNfe: function getModelosNfe() {
      var modelos, self;
      self = this;
      modelos = [];
      return this.get('store').findAll('modelo-nota-fiscal').then(function (objs) {
        var i, j, obj, ref, results;
        results = [];

        for (i = j = 0, ref = objs.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
          obj = objs.objectAt(i);
          modelos.addObject({
            label: obj.get('nome'),
            action: function action() {
              return self.get('router').transitionTo('notas-fiscais/new', {
                queryParams: {
                  template: obj.get('id')
                }
              });
            }
          });
          results.push(self.set('newDropdownRoutes', modelos));
        }

        return results;
      });
    },
    actions: {
      downloadXml: function downloadXml(nf) {
        var self;
        self = this;
        this.set('downloadingXml', true);
        return this.downloadURL("/notas_fiscais/" + nf.get('id') + ".xml", nf.get('chaveNfe') + ".xml", function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadXmlCancelamento: function downloadXmlCancelamento(nf) {
        var self;
        self = this;
        this.set('downloadingXml', true);
        return this.downloadURL("/notas_fiscais/" + nf.get('id') + ".xml?cancelamento=1", nf.get('chaveNfe') + "-can.xml", function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadPdf: function downloadPdf(nf) {
        var self;
        self = this;
        this.set('downloadingPdf', true);
        return this.downloadURL("/notas_fiscais/" + nf.get('id') + ".pdf", nf.get('chaveNfe') + ".pdf", function (_this) {
          return function () {
            return self.set('downloadingPdf', false);
          };
        }(this));
      },
      cancelNfe: function cancelNfe(nf) {
        return this.get('cancelModal').cancelarNota(nf);
      },
      duplicateNfe: function duplicateNfe(nf) {
        var ajaxParams, self;
        self = this;
        self.set('searching', true);
        ajaxParams = {
          type: "PUT",
          data: {},
          dataType: "json"
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: "/notas_fiscais/" + nf.get('id') + "/duplicar.json"
        }, function (sucesso, textStatus, data) {
          var res;
          res = data.jqXHR.responseJSON;

          if (sucesso) {
            self.get('router').transitionTo('notas-fiscais/edit', res.nota_fiscal.id);
          }

          if (!sucesso && onError) {
            self.get('application').showAlert('Ocorreu um erro ao duplicar a NF-e. Por favor, tente novamente.', 'error');
          }

          return self.set('searching', false);
        });
      }
    }
  });

  _exports.default = _default;
});