define("api-dashboard/models/promotion-store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    promotion: _emberData.default.belongsTo('promotion', {
      inverse: 'promotionStores'
    }),
    fStore: _emberData.default.belongsTo('f-store')
  });

  _exports.default = _default;
});