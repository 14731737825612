define("api-dashboard/models/kit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    barcode: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    codeFactoryUsed: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    originalId: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('date'),
    version: _emberData.default.attr('number'),
    items: _emberData.default.hasMany('kit-item', {
      async: false
    }),
    activeDescription: Ember.computed('active', function () {
      if (!this.get('active')) {
        return "Não";
      }

      return "Sim";
    })
  });

  _exports.default = _default;
});