define("api-dashboard/templates/components/sales/sales-pos-web-form-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJEI1h22",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"posWeb__baseForm\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"A Receber: \"],[7,\"span\",true],[8],[0,\"R$ \"],[1,[24,[\"model\",\"toReceive\"]],false],[9],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Total da Venda: \"],[7,\"span\",true],[8],[0,\"R$ \"],[1,[24,[\"model\",\"totalSalePrice\"]],false],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"base/dropdown-button\",null,[[\"options\",\"_selectedOption\"],[[24,[\"dropdownButtonActions\"]],[24,[\"dropdownButtonSelectedAction\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/sales/sales-pos-web-form-actions.hbs"
    }
  });

  _exports.default = _default;
});