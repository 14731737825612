define("api-dashboard/models/supplier", ["exports", "ember-data", "api-dashboard/models/person"], function (_exports, _emberData, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    commission: _emberData.default.attr('number'),
    markup: _emberData.default.attr('number'),
    supplierCategory: _emberData.default.belongsTo('supplier_category'),
    department: _emberData.default.belongsTo('department'),
    productType: _emberData.default.belongsTo('product_type')
  });

  _exports.default = _default;
});