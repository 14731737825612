define("api-dashboard/models/valor-parametro", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    valor: _emberData.default.attr('string'),
    parametro: _emberData.default.belongsTo('parametro'),
    valorTyped: null,
    calcValorTyped: function calcValorTyped() {
      if (this.get('parametro.tipo') === 'boolean') {
        return this.set('valorTyped', !(this.get('valor') === 'false' || this.get('valor') === '0' || this.get('valor') === false));
      } else {
        return this.set('valorTyped', this.get('valor'));
      }
    },
    onValorTypedChanged: Ember.observer('valorTyped', function () {
      return this.set('valor', this.get('valorTyped'));
    })
  });

  _exports.default = _default;
});