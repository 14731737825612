define("api-dashboard/components/bolinha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mensagem: 'olá, tudo bem?'
  });

  _exports.default = _default;
});