define("api-dashboard/templates/components/inputs/input-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IpLQ6igD",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[24,[\"value\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"],[7,\"label\",true],[10,\"for\",\"switch-notification\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"style\",[22,\"styleSpan\"]],[8],[0,\"\\n    \"],[1,[22,\"formattedLabel\"],false],[0,\"\\n    \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\" * \"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-toggle.hbs"
    }
  });

  _exports.default = _default;
});