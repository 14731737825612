define("api-dashboard/templates/components/formularios/cadastro-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E8iCWihe",
    "block": "{\"symbols\":[],\"statements\":[[15,\"partials/components/formularios/cadastro-empresa/secao-dados-basicos\",[]],[0,\"\\n\"],[15,\"partials/components/formularios/cadastro-empresa/secao-documentos-fiscais\",[]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row btn-nova-empresa-cadastrar\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-lg btn-success\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"modoEdicao\"]]],null,{\"statements\":[[0,\"        Salvar\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Cadastrar nova empresa\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[10,\"style\",\"display:none;\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[22,\"base/form-alert\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/formularios/cadastro-empresa.hbs"
    }
  });

  _exports.default = _default;
});