define("api-dashboard/components/pagina-nao-encontrada", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaginaNaoEncontradaComponent;
  PaginaNaoEncontradaComponent = Ember.Component.extend();
  var _default = PaginaNaoEncontradaComponent;
  _exports.default = _default;
});