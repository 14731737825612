define("api-dashboard/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.service(),
    mostrarTitulo: Ember.computed(function () {
      return this.get('application.currentDomain.isPainelApiNfe');
    })
  });

  _exports.default = _default;
});