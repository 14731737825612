define("api-dashboard/mixins/requests/autenticacao", ["exports", "api-dashboard/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    fazerLogin: function fazerLogin(context, params) {
      var r, reqParams, url;
      r = {};
      reqParams = {
        username: params["login"],
        password: params["senha"],
        token: params["token"],
        domainId: params["domainId"],
        clientApp: 'NewEmber'
      };
      url = context.get("store").adapterFor("application").get("host") + "/panel_api/authentication";
      $.ajax({
        async: false,
        url: url,
        type: "POST",
        data: reqParams,
        success: function success(data, status, response) {
          return r = {
            success: true,
            data: data
          };
        },
        error: function error(xhr, response, thrownError) {
          var mensagem;

          if (xhr.status === 404) {
            mensagem = "Usuário ou senha incorretos.";
          } else {
            mensagem = "Ocorreu um problema ao validar a sua senha, por favor entre em contato com o suporte técnico.";
          }

          return r = {
            success: false,
            message: mensagem
          };
        }
      });
      return r;
    },
    fazerLogout: function fazerLogout(context, params, callback) {
      return setTimeout(function () {
        return callback(true, {}, 200);
      }, 500);
    },
    verificarTokenAindaValido: function verificarTokenAindaValido(context, params, callback) {
      return setTimeout(function () {
        return callback(true, {}, 200);
      }, 500);
    },
    enviarEmailRecuperacaoSenha: function enviarEmailRecuperacaoSenha(context, params, callback) {
      var ajaxParams;
      ajaxParams = {
        method: 'PUT',
        data: {
          email: params["email"]
        },
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: '/panel_api/authentication/password'
      }, function (success, textStatus, data) {
        var mensagem;

        if (success) {
          return callback(true, data, 200);
        } else {
          if (data.jqXHR.status === 404) {
            mensagem = "Este e-mail não está cadastrado no sistema.";
          } else {
            mensagem = "Ocorreu um problema ao validar a sua senha, por favor entre em contato com o suporte técnico.";
          }

          return callback(false, {
            xhr: data.jqXHR,
            mensagem: mensagem
          }, data.jqXHR.status);
        }
      });
    }
  });

  _exports.default = _default;
});