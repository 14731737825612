define("api-dashboard/helpers/upper-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.upperCase = void 0;
  var upperCase;
  _exports.upperCase = upperCase;

  _exports.upperCase = upperCase = function upperCase(params, namedParams) {
    var value;
    value = params[0];

    if (!Ember.isEmpty(value)) {
      return value.toUpperCase();
    } else {
      return value;
    }
  };

  var _default = Ember.Helper.helper(upperCase);

  _exports.default = _default;
});