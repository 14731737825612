define("api-dashboard/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    application: Ember.inject.service(),
    _destroy: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    adquiridoComSt: _emberData.default.attr('boolean'),
    averageCost: _emberData.default.attr('big'),
    barcode: _emberData.default.attr('string'),
    cfopDevolucaoConsignacao: _emberData.default.attr('string'),
    cfopDevolucaoFornecedorDentro: _emberData.default.attr('string'),
    cfopDevolucaoFornecedorFora: _emberData.default.attr('string'),
    cfopRemessaConsignacao: _emberData.default.attr('string'),
    cfopVendaConsigDentroEstado: _emberData.default.attr('string'),
    cfopVendaConsigForaEstado: _emberData.default.attr('string'),
    cfopVendaDentroEstado: _emberData.default.attr('string'),
    cfopVendaForaEstado: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    codeAndDescription: _emberData.default.attr('string'),
    codigoBeneficioFiscal: _emberData.default.attr('string'),
    codigoCest: _emberData.default.attr('string'),
    codigoExTipi: _emberData.default.attr('string'),
    codigoNcm: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    ecommercePrice: _emberData.default.attr('number'),
    enableEcommerce: _emberData.default.attr('boolean'),
    floatingQuantities: _emberData.default.attr('boolean'),
    fullDescription: _emberData.default.attr('string'),
    genero: _emberData.default.attr('string'),
    iat: _emberData.default.attr('string'),
    icmsOrigem: _emberData.default.attr('number'),
    ignoreStockControl: _emberData.default.attr('boolean'),
    ippt: _emberData.default.attr('string'),
    keepSeparated: _emberData.default.attr('boolean'),
    labelCode: _emberData.default.attr('string'),
    measurementUnit: _emberData.default.attr('string'),
    operatorMessage: _emberData.default.attr('string'),
    price: _emberData.default.attr('big'),
    priceLabel: _emberData.default.attr('string'),
    taxableBarcode: _emberData.default.attr('string'),
    taxableMeasurementUnit: _emberData.default.attr('string'),
    taxablePrice: _emberData.default.attr('big'),
    totalTaxRate: _emberData.default.attr('number'),
    useDimensions: _emberData.default.attr('boolean'),
    useDimensionsPrice: _emberData.default.attr('boolean', {
      alwaysDirty: true
    }),
    wholesalePrice: _emberData.default.attr('big'),
    capituloNcm: _emberData.default.belongsTo('capitulo_ncm'),
    collection: _emberData.default.belongsTo('collection'),
    department: _emberData.default.belongsTo('department'),
    dimension1: _emberData.default.belongsTo('dimension'),
    dimension1Value: _emberData.default.belongsTo('dimensionValue'),
    dimension2: _emberData.default.belongsTo('dimension'),
    dimension2Value: _emberData.default.belongsTo('dimensionValue'),
    dimension3: _emberData.default.belongsTo('dimension'),
    dimension3Value: _emberData.default.belongsTo('dimensionValue'),
    inventories: _emberData.default.hasMany('inventory', {
      inverse: 'product',
      async: true
    }),
    items: _emberData.default.hasMany('item'),
    mainProduct: _emberData.default.belongsTo('product', {
      inverse: 'products'
    }),
    products: _emberData.default.hasMany('product', {
      inverse: 'mainProduct',
      async: false
    }),
    productType: _emberData.default.belongsTo('product_type'),
    supplier: _emberData.default.belongsTo('supplier'),
    taxRates: _emberData.default.hasMany('tax_rate'),
    valoresParametros: _emberData.default.hasMany('valorParametro'),
    inventoriesSorting: ['fStore.globalOrder', 'fStore.name'],
    sortedInventories: Ember.computed.sort('inventories', 'inventoriesSorting'),
    ipptValues: [{
      value: 'T',
      description: 'Terceirizada'
    }, {
      value: 'P',
      description: 'Própria'
    }],
    iatValues: [{
      value: 'A',
      description: 'Arredondar'
    }, {
      value: 'T',
      description: 'Truncar'
    }],
    mainId: Ember.computed('id', 'mainProduct.id', function () {
      if (this.get('mainProduct.id')) {
        return this.get('mainProduct.id');
      } else {
        return this.get('id');
      }
    }),
    mainObject: Ember.computed('id', 'mainProduct.id', function () {
      if (this.get('mainProduct.id')) {
        return this.get('mainProduct');
      } else {
        return this;
      }
    }),
    hasFiscalError: Ember.computed('errors', function () {
      return this.get('errors.codigoNcm') || this.get('errors.capituloNcmId');
    }),
    computedCode: Ember.computed('code', 'codigoNcm', 'productType.codigoNcm', function () {
      var code;
      code = this.get('code');

      if (!code) {
        code = this.get('codigoNcm');
      }

      if (!code) {
        code = this.get('productType.codigoNcm');
      }

      return code;
    }),
    descricaoCodigoNcmEfetivo: Ember.computed('codigoNcm', 'application.currentDomain.systemParam.codigoNcm', 'productType.codigoNcm', function () {
      if (this.get('codigoNcm')) {
        return this.get('codigoNcm');
      } else if (this.get('productType.codigoNcm')) {
        return this.get('productType.codigoNcm') + (" - herdado do tipo " + this.get('productType.name'));
      } else if (this.get('application.currentDomain.systemParam.codigoNcm')) {
        return this.get('application.currentDomain.systemParam.codigoNcm') + " - herdado das configurações do sistema";
      } else {
        return "";
      }
    }),
    dimensionDescription: Ember.computed('dimension1Value', 'dimension2Value', 'dimension3Value', function () {
      var dims;
      dims = [];

      if (this.get('dimension1Value') && this.get('dimension1Value.content')) {
        dims.push(this.get('dimension1Value.value'));
      }

      if (this.get('dimension2Value') && this.get('dimension2Value.content')) {
        dims.push(this.get('dimension2Value.value'));
      }

      if (this.get('dimension3Value') && this.get('dimension3Value.content')) {
        dims.push(this.get('dimension3Value.value'));
      }

      if (dims.length > 0) {
        return dims.join(',');
      } else {
        return '';
      }
    }),
    descriptionWithDimensions: Ember.computed('description', 'dimension1Value', 'dimension2Value', 'dimension3Value', function () {
      var dd;
      dd = this.get('dimensionDescription');

      if (dd.length > 0) {
        return this.get('description') + ' (' + this.get('dimensionDescription') + ')';
      } else {
        return this.get('description');
      }
    }),
    inventoryChanges: 0,
    observeInventory: Ember.observer('inventories.@each', function () {
      return this.incrementProperty('inventoryChanges');
    }),
    fillParametros: function fillParametros() {
      var self;
      self = this;
      return this.get('application.currentDomain.parametros').forEach(function (parametro) {
        var vp;
        vp = self.get('valoresParametros').findBy('parametro.id', parametro.get('id'));

        if (!vp) {
          vp = self.get('valoresParametros').createRecord({
            parametro: parametro,
            valor: parametro.get('valorDefault')
          });
        }

        return vp.calcValorTyped();
      });
    },
    addNewVariant: function addNewVariant() {
      return this.get('products').createRecord({});
    }
  });

  _exports.default = _default;
});