define("api-dashboard/models/transporter", ["exports", "ember-data", "api-dashboard/models/person"], function (_exports, _emberData, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend();

  _exports.default = _default;
});