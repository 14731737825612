define("api-dashboard/components/inputs/input-tax-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'name', 'checked', 'value'],
    type: 'radio',
    name: 'taxType',
    checked: false,
    value: '',
    click: function click() {
      return this.get('parentView').send('radioClicked', this.get('value'));
    }
  });

  _exports.default = _default;
});