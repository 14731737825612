define("api-dashboard/helpers/stores-dashboard-index/get-icon-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getIconClass = void 0;
  var getIconClass;
  _exports.getIconClass = getIconClass;

  _exports.getIconClass = getIconClass = function getIconClass(params) {
    var status;
    status = params[0];

    if (status === 'red') {
      return 'glyphicon-remove-sign';
    } else if (status === 'yellow') {
      return 'glyphicon-alert-sign';
    } else {
      return 'glyphicon-ok-sign';
    }
  };

  var _default = Ember.Helper.helper(getIconClass);

  _exports.default = _default;
});