define("api-dashboard/components/products/products-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    limit: 20,
    searchModelName: 'product',
    title: 'Produto',
    titlePlural: 'Produtos',
    layoutNoRecords: 'products/none-registered',
    newRoute: 'products/new',
    editRoute: 'products/edit',
    partialAfterTitle: null,
    layoutColumns: 12,
    customSearchButton: true,
    refinementsSearch: true,
    allowDelete: false,
    allowShowInventory: true,
    columns: [{
      field: 'supplier.name',
      label: 'Fornecedor'
    }, {
      field: 'code',
      label: 'Código'
    }, {
      field: 'descriptionWithDimensions',
      label: 'Descrição'
    }, {
      field: 'averageCost',
      label: 'Custo'
    }, {
      field: 'price',
      label: 'Preço de Venda'
    }],
    metaLabels: [{
      label: 'Quantidade de produtos encontrados: ',
      field: 'total',
      type: ''
    }],
    searchParams: [{
      label: 'Fornecedor',
      field: 'supplierId',
      param: 'supplier_id',
      component: 'inputs/input-autocomplete',
      modelName: 'supplier',
      suggestionField: 'name',
      selectedRecord: null,
      value: null
    }, {
      field: 'code',
      param: 'code',
      label: 'Código'
    }, {
      field: 'description',
      param: 'description',
      label: 'Descrição'
    }, {
      label: 'Departamento',
      field: 'departmentId',
      param: 'department_id',
      component: 'inputs/input-autocomplete',
      modelName: 'department',
      suggestionField: 'name',
      selectedRecord: null,
      value: null
    }, {
      label: 'Coleção',
      field: 'collectionId',
      param: 'collection_id',
      component: 'inputs/input-autocomplete',
      modelName: 'collection',
      suggestionField: 'name',
      selectedRecord: null,
      value: null
    }, {
      label: 'Tipo',
      field: 'productTypeId',
      param: 'product_type_id',
      component: 'inputs/input-autocomplete',
      modelName: 'product-type',
      suggestionField: 'name',
      selectedRecord: null,
      value: null
    }, {
      label: 'Apenas ativos',
      field: 'active',
      param: 'active',
      component: 'inputs/input-toggle',
      value: true
    }, {
      label: 'Apenas produtos principais',
      field: 'main_product',
      param: 'main_product',
      component: 'inputs/input-toggle',
      value: false
    }, {
      label: 'Somente sem código NCM',
      field: 'only_without_ncm',
      param: 'only_without_ncm',
      component: 'inputs/input-toggle',
      value: false
    }],
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});