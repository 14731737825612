define("api-dashboard/components/users/user-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'user',
    title: 'Colaborador (Retaguarda)',
    titlePlural: 'Colaboradores (Retaguarda)',
    layoutNoRecords: 'users/none-registered',
    newRoute: 'users/new',
    editRoute: 'users/edit',
    partialStats: null,
    columns: [{
      field: 'email',
      label: 'E-mail'
    }],
    searchParams: Ember.computed(function () {
      return [{
        component: 'inputs/base-text-field',
        field: 'email',
        label: 'Email',
        param: 'email'
      }, {
        component: 'inputs/input-toggle',
        field: 'admin',
        label: 'Admin',
        param: 'admin'
      }, {
        component: 'inputs/input-toggle',
        field: 'permBasics',
        label: 'Básica',
        param: 'permBasics'
      }, {
        component: 'inputs/input-toggle',
        field: 'permNfe',
        label: 'Nfe',
        param: 'permNfe'
      }, {
        component: 'inputs/input-toggle',
        field: 'permSales',
        label: 'Vendas',
        param: 'permSales'
      }, {
        component: 'inputs/input-toggle',
        field: 'permFin',
        label: 'Financeiro',
        param: 'permFin'
      }, {
        component: 'inputs/input-toggle',
        field: 'permStock',
        label: 'Stoque',
        param: 'permStock'
      }, {
        component: 'inputs/input-toggle',
        field: 'permStockTransfer',
        label: 'Trans. de Estoque',
        param: 'permStockTransfer'
      }];
    }),
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});