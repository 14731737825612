define("api-dashboard/components/inputs/product/product-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Input para Lista de Produtos.
    O usuário pode buscar um Produto, ou utilizar um leitor de código de barras, e adicionar
    o Produto na lista, que então é mostrado abaixo do input. Os Produtos escolhidos podem ser
    utilizados posteriormente para várias funcionalidades, como geração de etiquetas, relatórios,
    contagem de estoque, etc.
  
    Params:
      @addButtonDisabled: Define se o botão de adicionar produto [na lista de Produtos] está
                          habilitado ou desabilitado. Esse botão só deve aparecer quando um Produto
                          E uma dimensão (caso haja) do Produto estão devidamente escolhidos.
  
      @classNames: Classes CSS da div que engloba esse componente.
  
      @columns: Colunas a serem mostradas na lista de Produtos.
                Os possíveis valores são:
                  brand: Marca do Produto
                  code: Código do Produto
                  description: Descrição do Produto com as Dimensões (caso existam)
                  price: Preço do Produto
                  quantity: Quantidade escolhida do Produto (editável)
                  totalPrice: Preço do Produto multiplicado pela quantidade
                  averageCost: Custo médio do Produto
                  totalAverageCost: Custo Médio do Produto multiplicado pela quantidade
                  priceWithoutDiscount: Preço do Produto sem desconto
                  priceWithDiscount: Preço do Produto com desconto
  
      @duplicateEntry: Define se um Produto pode ou não ser adicionado mais de uma vez à Lista de
                       Produtos.
                       Os possíveis valores são:
                          allow: Permite adicionar o Produto múltiplas vezes à Lista de Produtos.
                          add: Ao invés de adicionar o Produto na Lista de Produtos múltiplas vezes,
                               adiciona à quantidade do Produto (sem duplicá-lo na Lista).
                          deny: Mostra uma mensagem de erro, informando que o Produto já foi adicionado
                                à Lista de Produtos.
  
      @editable: Define se a Lista de Produtos é editável - ou seja, é possível adicionar mais Produtos
                  à Lista, ou não.
  
      @formPrice: Em alguns casos é possível adicionar o Produto à Lista de Produtos informando o
                  preço. Esse campo guarda o preço do Produto atual.
  
      @formProductDimension: Em alguns casos é possível adicionar o Produto à Lista de Produtos
                              informando as dimensões do Produto. Esse campo guarda o ID da Variação
                              escolhida.
  
      @formProduct: O Produto escolhido atualmente pelo Usuário.
  
      @formQuantity: A Quantidade do Produto escolhido atualmente pelo Usuário.
  
      @ignoreDimension: Define se as dimensões do Produto podem ser ignoradas ou não.
  
      @inputType: Define qual é o tipo de input aceito pelo componente.
                  Os possíveis valores são:
                  autocomplete-only: Permite que o Usuário apenas adicione Produtos buscando os
                                      Produtos utilizando o componente `inputs/input-autocomplete`.
                  barcode-only: Permite que o Usuário apenas adicione Produtos buscando os Produtos
                                utilizando códigos de barras, com o componente `inputs/input-barcode`.
                  both: Permite que o Usuário possa escolher adicionar os Produtos buscando com o
                        `inputs/input-autocomplete` ou utilizando códigos de barras, com o componente
                        `inputs/input-barcode`.
  
      @inputTypeSelected: Define qual é o input que aparecerá por padrão na tela quando o parâmetro
                          `@inputType` é `both`. O valor padrão é `autocomplete`.
  
      @items: Os items da Lista de Produtos/
  
      @modelType: O Model (Ember) utilizado na Lista de Produtos.
  
      @promotionDiscountRate: O desconto a ser aplicado no Preço do Produto. (Não utilizado)
  
      @promotionDiscountType: O tipo de desconto a ser aplicado no Produto. (Não utilizado)
  
      @searchedProducts: Os Produtos mostrados no modal de busca de Produtos.
  
      @searchSupplier: O Fornecedor informado para buscar os Produtos no modal de busca de Produtos.
  
      @searchValue: O valor informado para buscar os Produtos no modal de busca de Produtos.
  
      @showPesquisaAvancada: Define se a pesquisa avançada (modal de busca) de Produtos é mostrada ou
                              não.
  
      @showPrice: Define se o input de preço deve ser mostrado ou não.
  
      @showQuantityBefore: Define se o campo `Quantidade` deve ser mostrado antes do campo de busca
                           de Produto.
  
      @showQuantity: Define se o campo `Quantidade` deve ser mostrado ou não.
  
      @showTotalCost: Define se o custo total dos Produtos na Lista deve ser mostrado ou não.
  
      @showTotalValue: Define se o preço total dos Produtos na Lista deve ser mostrado ou não.
  
      @validationMessage: Mensagem interna de validação
  
      @advancedSearchModal: O componente de busca avançada de Produto.
  
      @showInStockQuantity: Define se será mostrado via AJAX a quantidade em estoque do produto
      @fStore: Loja de onde será buscada a quantidade em estoque (obrigatório se showInStockQuantity)
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    addButtonDisabled: false,
    advancedSearchModal: null,
    classNames: ['products-select'],
    columns: ['brand', 'code', 'description', 'itemPrice', 'quantity', 'totalPrice'],
    duplicateEntry: 'allow',
    editable: true,
    formPrice: null,
    formProduct: null,
    formProductDimension: null,
    formQuantity: 1,
    ignoreDimension: false,
    inputType: 'both',
    inputTypeSelected: 'autocomplete',
    items: [],
    modelType: 'trade-item',
    nextRecordsMessage: 'Próximos 20 Produtos',
    nextRecordsVisible: false,
    promotionDiscountRate: null,
    promotionDiscountType: null,
    refinedSearchLimit: 20,
    refinedSearchOffset: 0,
    searchedProducts: [],
    searchSupplier: null,
    searchValue: null,
    selectedProduct: null,
    showPesquisaAvancada: false,
    showPrice: false,
    showQuantity: true,
    showQuantityBefore: true,
    showTotalCost: false,
    showTotalValue: true,
    showInStockQuantity: false,
    fStore: null,
    validationMessage: '',
    stockInfoSet: false,
    inputSelectionOptions: [{
      description: 'Leitor de Código de Barras',
      value: 'barcode'
    }, {
      description: 'Pesquisa por Nome ou Código do Produto',
      value: 'autocomplete'
    }],
    formPriceObserver: Ember.observer('selectedProduct', function () {
      return this.set('formPrice', this.get('selectedProduct.price'));
    }),
    totalValue: Ember.computed('items.length', 'items.@each.quantity', 'items.@each._destroy', function () {
      var items, totalValue;
      totalValue = 0;
      items = this.get('items');
      items.forEach(function (item) {
        if (item && !item.get('_destroy')) {
          return totalValue += item.get('price') * parseInt(item.get('quantity'));
        }
      });
      return totalValue;
    }),
    totalAverageCost: Ember.computed('items.length', 'items.@each.quantity', 'items.@each._destroy', function () {
      var items, totalAverageCost;
      totalAverageCost = 0;
      items = this.get('items');
      items.forEach(function (item) {
        if (item && !item.get('_destroy')) {
          return totalAverageCost += item.get('product.averageCost') * parseInt(item.get('quantity'));
        }
      });
      return totalAverageCost;
    }),
    totalQuantity: Ember.computed('items.@each.quantity', 'items.@each._destroy', function () {
      var items, quantity;
      quantity = 0;
      items = this.get('items');
      items.forEach(function (item) {
        if (item && !item.get('_destroy')) {
          return quantity += parseFloat(item.get('quantity'));
        }
      });
      return quantity;
    }),
    totalProducts: Ember.computed('items.length', 'items.@each._destroy', function () {
      var items, total;
      total = 0;
      items = this.get('items');
      items.forEach(function (item) {
        if (item && !item.get('_destroy')) {
          return total += 1;
        }
      });
      return total;
    }),
    barcodeSelectionVisible: Ember.computed('inputType', function () {
      return this.get('inputType') === 'both';
    }),
    barcodeInputVisible: Ember.computed('inputType', 'inputTypeSelected', function () {
      var inputType;
      inputType = this.get('inputType');
      return inputType === 'barcode-only' || inputType === 'both' && this.get('inputTypeSelected') === 'barcode';
    }),
    autocompleteInputVisible: Ember.computed('inputType', 'inputTypeSelected', function () {
      var inputType;
      inputType = this.get('inputType');
      return inputType === 'autocomplete-only' || inputType === 'both' && this.get('inputTypeSelected') === 'autocomplete';
    }),
    productDimensions: Ember.computed('formProduct', function () {
      if (this.get('formProduct.useDimensions')) {
        return this.get('store').query('product', {
          main_product_id: this.get('formProduct.id')
        });
      }

      return [];
    }),
    showDimensionInput: Ember.computed('formProduct.useDimensions', 'ignoreDimension', function () {
      return this.get('formProduct.useDimensions') && !this.get('ignoreDimension');
    }),
    stockInfoObserver: Ember.observer('showInStockQuantity', 'selectedProduct', 'fStore', 'formProductDimension', function () {
      var ajaxParams, productId, self, storeId;
      productId = null;
      storeId = null;

      if (this.get('showInStockQuantity') && this.get('fStore') && this.get('selectedProduct')) {
        storeId = this.get('fStore.id');

        if (!this.get('selectedProduct.useDimensions')) {
          productId = this.get('selectedProduct.id');
        } else if (this.get('selectedProduct.useDimensions') && this.get('formProductDimension')) {
          productId = this.get('formProductDimension');
        }
      }

      if (productId && storeId) {
        self = this;
        ajaxParams = {
          method: 'GET',
          data: {
            store_id: storeId
          }
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: '/products/' + productId + '/in_stock_quantity'
        }).then(function (data, status, jqXHR) {
          self.set('stockInfo', Number(data.in_stock_quantity));
          return self.set('stockInfoSet', true);
        });
      } else {
        this.set('stockInfo', null);
        return this.set('stockInfoSet', false);
      }
    }),
    getSelectedProduct: function getSelectedProduct() {
      var productId, self;
      self = this;

      if (this.get('formProductDimension') || this.get('formProduct.id')) {
        productId = this.get('formProductDimension') ? this.get('formProductDimension') : this.get('formProduct.id');
        return this.get('store').findRecord('product', productId, {
          reload: true
        }).then(function (obj) {
          self.set('selectedProduct', obj);
          return self.set('formProduct', obj);
        });
      }
    },
    validateForm: function validateForm() {
      var formPrice, formProduct, formProductDimension, formQuantity;
      formProduct = this.get('formProduct');
      formQuantity = this.get('formQuantity');
      formProductDimension = this.get('formProductDimension');
      formPrice = this.get('formPrice');

      if (formProduct == null) {
        this.set('validationMessage', 'É necessário escolher o produto');
        this.$('.autocomplete-input').focus();
        return false;
      }

      if (formProduct.get('useDimensions') && !this.get('ignoreDimension') && formProductDimension == null) {
        this.set('validationMessage', 'É preciso determinar a variação do produto');
        this.$('#ps_dimension').focus();
        return false;
      }

      if ((this.get('showQuantity') || this.get('showQuantityBefore')) && (isNaN(parseInt(formQuantity)) || parseInt(formQuantity) <= 0)) {
        this.set('validationMessage', 'É preciso determinar a quantidade');
        this.$('#ps_quantity').focus();
        return false;
      }

      if (this.get('showPrice') && formPrice == null) {
        this.set('validationMessage', 'É preciso determinar o preço');
        this.$('#ps_price').focus();
        return false;
      }

      return true;
    },
    openAdvancedSearch: function openAdvancedSearch() {
      return this.get('advancedSearchModal').show();
    },
    closeAdvancedSearch: function closeAdvancedSearch() {
      this.set('searchValue', '');
      this.set('searchSupplier', null);
      return this.get('advancedSearchModal').hide();
    },
    keyDown: function keyDown(evt) {
      if (evt.keyCode === 13) {
        return evt.preventDefault();
      }
    },
    actions: {
      addProduct: function addProduct() {
        var formPrice, self;

        if (!this.validateForm()) {
          return false;
        }

        self = this;
        this.set('searchingProduct', true);
        formPrice = self.get('formPrice');
        return this.getSelectedProduct().then(function (obj) {
          var existingItem, formQuantity, items, modelType, newItem, pojo, quantity, selectedProduct;
          modelType = self.get('modelType');
          selectedProduct = obj;
          pojo = false;
          existingItem = null;
          items = self.get('items');
          items.forEach(function (item) {
            if (item.get('product') === selectedProduct) {
              return existingItem = item;
            }
          });

          if (existingItem != null) {
            switch (self.get('duplicateEntry')) {
              case 'deny':
                self.set('validationMessage', 'Um produto só pode ser adicionado uma única vez');
                return;

              case 'add':
                quantity = parseInt(self.get('formQuantity'));

                if (!quantity || isNaN(quantity) || quantity === 0) {
                  quantity = 1;
                }

                Ember.set(existingItem, 'quantity', existingItem.get('quantity') + quantity);
                self.set('formProduct', null);
                self.set('formQuantity', 1);
                self.set('formProductDimension', null);
                self.set('validationMessage', '');
                return;
            }
          }

          if (Ember.isEmpty(modelType)) {
            newItem = Ember.Object.create();
            pojo = true;
          } else {
            newItem = self.get('store').createRecord(self.get('modelType'));
          }

          formQuantity = self.get('formQuantity');
          Ember.set(newItem, 'product', selectedProduct);

          if (Ember.isEmpty(formQuantity)) {
            Ember.set(newItem, 'quantity', 1);
          } else {
            Ember.set(newItem, 'quantity', parseFloat(self.get('formQuantity')));
          }

          Ember.set(newItem, 'price', formPrice);

          if (pojo) {
            Ember.set(newItem, 'totalPrice', formPrice.times(formQuantity).round(2));
          }

          items.addObject(newItem);
          self.set('formProduct', null);
          self.set('selectedProduct', null);
          self.set('stockInfo', null);
          self.set('stockInfoSel', false);
          self.set('formQuantity', 1);
          self.set('formProductDimension', null);
          self.set('validationMessage', '');
          return self.set('searchingProduct', false);
        });
      },
      deleteItem: function deleteItem(item) {
        var items;

        if (item.get('id') == null) {
          items = this.get('items');
          items.removeObject(item);
          item.deleteRecord();
        } else {
          Ember.set(item, '_destroy', true);
        }

        return this.set('validationMessage', '');
      },
      searchProduct: function searchProduct(append) {
        var searchSupplierId, searchValue, self;
        self = this;

        if (!append) {
          this.set('refinedSearchOffset', 0);
        }

        this.set('searchingProduct', true);
        searchValue = this.get('searchValue');
        searchSupplierId = this.get('searchSupplier.id');
        return this.get('store').query('product', {
          search: searchValue,
          supplier_id: searchSupplierId,
          active: true,
          include_grid_item_code: true,
          exact_search: false,
          main_product_id: 'null',
          limit: 20,
          offset: self.get('refinedSearchOffset')
        }).then(function (objs) {
          self.set('nextRecordsVisible', objs.get('length') === self.get('refinedSearchLimit'));
          self.set('searchedProducts', objs);
          return self.set('searchingProduct', false);
        });
      },
      loadMoreProducts: function loadMoreProducts() {
        this.set('refinedSearchOffset', this.get('refinedSearchOffset') + this.get('refinedSearchLimit'));
        return this.send('searchProduct', true);
      },
      addProductPopup: function addProductPopup(product) {
        var modelType, newItem, quantity;
        modelType = this.get('modelType');

        if (Ember.isEmpty(modelType)) {
          newItem = Ember.Object.create();
        } else {
          newItem = this.get('store').createRecord(this.get('modelType'));
        }

        quantity = parseInt(this.get('formQuantity'));

        if (isNaN(quantity)) {
          quantity = 1;
        }

        this.set('formProduct', product);
        this.set('formProductDimension', null);
        return this.closeAdvancedSearch();
      },
      focusNext: function focusNext() {
        var focusNextCallback;
        clearTimeout(window.resId);

        focusNextCallback = function focusNextCallback() {
          if (this.get('showQuantityBefore') && !this.get('formProduct')) {
            $('.autocomplete-input').focus();
          }

          if (this.get('formProduct.useDimensions') && this.get('formProductDimension') == null) {
            return $('#ps_dimension').focus();
          } else if (this.get('showQuantity') && parseInt(this.get('formQuantity')) === 1) {
            return $('#ps_quantity').focus();
          } else if (this.get('showPrice') && !this.get('formPrice')) {
            return $('#ps_price').focus();
          }
        };

        return window.resId = setTimeout(focusNextCallback.bind(this), 100);
      },
      onSelect: function onSelect(record) {
        this.set('formProduct', record.record);
        this.set('formProductDimension', null);
        this.getSelectedProduct();
        return this.send('focusNext');
      }
    }
  });

  _exports.default = _default;
});