define("api-dashboard/routes/rotas-abstratas/rota-autenticavel", ["exports", "api-dashboard/routes/rotas-abstratas/rota-generica", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _rotaGenerica, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaGenerica.default.extend(_authenticatedRouteMixin.default, {
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    isUserAuthorized: function isUserAuthorized(user) {
      return true;
    },
    currentUserObserver: Ember.observer('application.currentUser.id', function () {
      if (this.get('application.currentUser.id')) {
        return this.checkAuthorization(this.get('application.currentUser'));
      }
    }),
    checkAuthorization: function checkAuthorization() {
      if (!this.isUserAuthorized(this.get('applicatioon.currentUser'))) {
        alert('Seu usuário não possui permissão para acessar esta área do sistema.');
        this.transitionTo('index');
      }
    },
    beforeModel: function beforeModel(transition) {
      var domainId, selecaoDomainController, self;
      self = this;

      this._super(transition);

      if (self.get("session.isAuthenticated")) {
        if (self.get('application.currentUser')) {
          this.checkAuthorization(self.get('application.currentUser'));
        } else {
          self.get('application').setCurrentUserId(self.get('session.data.authenticated.userId'));
        }

        if (!self.get('application.currentDomain') && self.get("routeName") !== "selecao-domain") {
          domainId = self.get('session.data.currentDomainId');

          if (domainId > 0) {
            return self.get('application').setCurrentDomainId(domainId);
          } else {
            selecaoDomainController = self.controllerFor("selecao-domain");
            selecaoDomainController.set("transitionIntent", transition);
            return self.transitionTo("selecao-domain");
          }
        }
      }
    }
  });

  _exports.default = _default;
});