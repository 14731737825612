define("api-dashboard/templates/components/lancamentos/lancamentos-index-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYTFvfu4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"title\",\"route\",\"model\",\"class\"],[\"Editar\",\"lancamentos/edit\",[24,[\"item\",\"lancamento\",\"id\"]],\"btn btn--default btn--primary\"]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-edit\"],[8],[9],[0,\" Editar\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"apiPanel__button--box\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"class\",\"btn btn--default btn--danger\"],[12,\"title\",\"Excluir\"],[3,\"action\",[[23,0,[]],\"delete\",[24,[\"item\"]]]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\" Excluir\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/lancamentos/lancamentos-index-actions.hbs"
    }
  });

  _exports.default = _default;
});