define("api-dashboard/components/charts/info", ["exports", "api-dashboard/components/charts/base-chart"], function (_exports, _baseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseChart.default.extend({
    classNames: 'dashboard__card--info',
    value: null,
    tagName: 'div',
    didReceiveAttrs: function didReceiveAttrs() {
      return this.set('value', this.get('dataSource'));
    }
  });

  _exports.default = _default;
});