define("api-dashboard/helpers/format/cpf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatCpf = void 0;
  var formatCpf;
  _exports.formatCpf = formatCpf;

  _exports.formatCpf = formatCpf = function formatCpf(params, namedParams) {
    var value;
    value = params[0];

    if (!Ember.isEmpty(value)) {
      value = value.toString().replace(/[_\. -]/g, '');
      return value.substr(0, 3) + "." + value.substr(3, 3) + "." + value.substr(6, 3) + "-" + value.substr(9, 2);
    } else {
      return '';
    }
  };

  var _default = Ember.Helper.helper(formatCpf);

  _exports.default = _default;
});