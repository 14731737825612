define("api-dashboard/components/formularios/recuperacao-senha", ["exports", "api-dashboard/components/formularios/formulario-generico"], function (_exports, _formularioGenerico) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formularioGenerico.default.extend({
    userSession: Ember.inject.service(),
    actionOnFormularioSubmetido: null,
    actionOnCliqueVoltar: null,
    campoEmail: null,
    campoEmailValido: true,
    divMensagensFormulario: null,
    didInsertElement: function didInsertElement() {
      return this.set("divMensagensFormulario", this.$(".alert"));
    },
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      var erros, mensagensErro;
      mensagensErro = [];

      if (!this.get("campoEmailValido")) {
        mensagensErro.push("O campo e-mail deve ser preenchido corretamente.");
        this.$("input").not(":password").focus();
      }

      if (mensagensErro.length > 0) {
        erros = this.arrayMensagensToUlLiTag(mensagensErro);
        return this.mostrarMensagemErro(erros, function () {
          return callbackOnValidacaoCompleta(false);
        });
      } else {
        return this.esconderMensagensErro(function () {
          return callbackOnValidacaoCompleta(true);
        });
      }
    },
    submeterFormulario: function submeterFormulario() {
      var self;
      self = this;
      this.indicarLoading(true);
      return this.get("userSession").recoverPassword({
        email: this.get("campoEmail")
      }, function (sucesso, dados) {
        self.sendAction("actionOnFormularioSubmetido", {
          sucesso: sucesso
        }, function () {});
        self.indicarLoading(false);

        if (!sucesso) {
          self.mostrarMensagemErro(dados["mensagem"], function () {});
        } else {
          self.mostrarMensagemSucesso("Encaminhamos um e-mail com os procedimentos para a recuperação de sua senha.", function () {});
        }

        self.set("campoEmail", "");
        self.set("campoEmailValido", false);
        return self.$("input").focus();
      });
    },
    mostrarMensagemErro: function mostrarMensagemErro(mensagem, callbackOnAnimationComplete) {
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "erro"
      }, callbackOnAnimationComplete);
    },
    mostrarMensagemSucesso: function mostrarMensagemSucesso(mensagem, callbackOnAnimationComplete) {
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), {
        mensagem: mensagem,
        tipoMensagem: "sucesso"
      }, callbackOnAnimationComplete);
    },
    esconderMensagensErro: function esconderMensagensErro(callbackOnAnimationComplete) {
      return this.esconderMensagensEmExibicao(this.get("divMensagensFormulario"), {}, callbackOnAnimationComplete);
    },
    indicarLoading: function indicarLoading(indicar) {
      var componentes, spinner;
      spinner = this.$("#loading");
      componentes = this.$("input").add("button");

      if (indicar) {
        spinner.show();
        return componentes.attr("disabled", true);
      } else {
        spinner.hide();
        return componentes.attr("disabled", false);
      }
    },
    actions: {
      actMostrarLogin: function actMostrarLogin() {
        return this.sendAction("actionOnCliqueVoltar");
      },
      actOnValidacaoCampoEmail: function actOnValidacaoCampoEmail(params, callback) {
        return this.set("campoEmailValido", params["valido"]);
      }
    }
  });

  _exports.default = _default;
});