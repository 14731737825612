define("api-dashboard/components/base/toast-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toaster: Ember.inject.service('toast'),
    message: null,
    type: null,
    closeOnClick: false,
    closeOnSwipe: false,
    templateRendered: false,
    typeBs: Ember.computed('type', function () {
      return this.get('type');
    }),
    didRender: function didRender() {
      var internalOptions, message, self, template;
      self = this;

      if (!this.get('templateRendered')) {
        this.set('templateRendered', true);
        return;
      }

      message = this.get('message');
      template = this.$('.dx-toast-template').first().css('display', 'inline');
      internalOptions = {
        closeOnClick: self.get('closeOnClick'),
        closeOnSwipe: self.get('closeOnSwipe'),
        displayTime: self.get('displayTime') || 5000,
        type: 'custom',
        contentTemplate: function contentTemplate() {
          return template;
        },
        onHidden: function onHidden(e) {
          return self.set('message', null);
        }
      };
      this.$().dxToast(internalOptions);

      if (!message) {
        return;
      }

      return this.$().dxToast('show');
    }
  });

  _exports.default = _default;
});