define("api-dashboard/mixins/components/autocomplete-advanced-search-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      if (!this.get('asColumns')) {
        this.set('asColumns', this.__configColumnsForModel());
      }

      return this._super.apply(this, arguments);
    },
    __configColumnsForModel: function __configColumnsForModel() {
      return this.get("__getColumnsFor_" + this.get('modelName'));
    },
    __getColumnsFor_product: Ember.computed(function () {
      return [{
        label: 'Código',
        field: 'code'
      }, {
        label: 'Descrição',
        field: 'description'
      }];
    }),
    __getColumnsFor_customer: Ember.computed(function () {
      return [{
        label: 'Razão Social',
        field: 'nomeOuRazaoSocial'
      }, {
        label: 'Documento',
        field: 'document'
      }];
    }),
    __getColumnsFor_cfop: Ember.computed(function () {
      return [{
        label: 'Código',
        field: 'codigo'
      }, {
        label: 'Descrição',
        field: 'descricao'
      }];
    }),
    __getColumnsFor_ContaContabil: Ember.computed(function () {
      return [{
        label: 'Plano de Conta',
        field: 'completeDescription'
      }];
    })
  });

  _exports.default = _default;
});