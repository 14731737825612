define("api-dashboard/models/card-company-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    acceptsRevolvingCredit: _emberData.default.attr('boolean'),
    revolvingCreditInterest: _emberData.default.attr('number'),
    acceptsInstallmentCredit: _emberData.default.attr('boolean'),
    installmentCreditUniqueInterest: _emberData.default.attr('boolean'),
    installmentCreditInterest: _emberData.default.attr('number'),
    acceptsDebit: _emberData.default.attr('boolean'),
    debitInterest: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    version: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('date'),
    updatedOn: _emberData.default.attr('date'),
    maxNumInstallments: _emberData.default.attr('number'),
    acceptsInstallmentDebit: _emberData.default.attr('boolean'),
    installmentDebitInterest: _emberData.default.attr('number'),
    domainId: _emberData.default.attr('number'),
    enabledCardBrands: _emberData.default.hasMany('enabled-card-brand'),
    cardCompany: _emberData.default.belongsTo('card-company'),
    name: Ember.computed('cardCompany', function () {
      var brands, enabledCardBrands, str;
      str = this.get('cardCompany.name');
      enabledCardBrands = this.get('enabledCardBrands');

      if (enabledCardBrands.get('length') > 0) {
        brands = [];
        enabledCardBrands.forEach(function (enabledCardBrand) {
          if (enabledCardBrand) {
            return brands.push(enabledCardBrand.get('cardBrand.name'));
          }
        });
        str += " (" + brands.uniq().join(', ') + ")";
      }

      return str;
    })
  });

  _exports.default = _default;
});