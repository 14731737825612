define("api-dashboard/components/base/btn-form-copy", ["exports", "api-dashboard/components/base/abstract-input-form-container"], function (_exports, _abstractInputFormContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractInputFormContainer.default.extend({
    classNames: '',
    classNameBindings: ['colsClassName'],
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    didInsertElement: function didInsertElement() {
      this._super();

      this.$("input").attr("readonly", true);
      return this.configurarBotaoCopia();
    },
    configurarBotaoCopia: function configurarBotaoCopia() {
      var botaoCopiar, inputConteudoCopia;
      botaoCopiar = this.$("button")[0];
      inputConteudoCopia = this.$("input")[0];
      return new ClipboardJS(botaoCopiar, {
        target: function target(trigger) {
          return inputConteudoCopia;
        }
      });
    }
  });

  _exports.default = _default;
});