define("api-dashboard/mixins/formatters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    format: function format(params) {
      var format, options, value;
      value = params[0];
      format = params[1];
      options = params[2] || {};

      switch (format) {
        case 'date':
          return this.formatDate(value, options);

        case 'big':
          return this.formatBig(value, format);

        case 'cnpj':
          return this.formatCnpj(value);

        case 'currency':
          return "R$ " + this.formatBig(value, format);

        case 'simnao':
          if (value) {
            return 'Sim';
          } else {
            return 'Não';
          }

        default:
          return value;
      }
    },
    formatBig: function formatBig(value, scale) {
      if (!(scale != null && !isNaN(parseInt(scale)))) {
        scale = 2;
      }

      if (value == null) {
        return "";
      }

      value = parseFloat(value);

      if (isNaN(value)) {
        return "";
      }

      if (scale == null || isNaN(parseInt(scale))) {
        scale = 2;
      } else {
        scale = parseInt(scale);
      }

      return value.toFixed(scale).replace('.', ',');
    },
    formatDate: function formatDate(value, options) {
      var format, m;
      options || (options = {});

      if (!value) {
        return options.whenNull || '';
      }

      try {
        format = options.format || 'DD/MM/YYYY';
      } catch (error) {
        format = 'DD/MM/YYYY';
      }

      m = moment(value);
      return m.format(format);
    },
    formatTime: function formatTime(value, options) {
      var format, m, utcOffset;
      utcOffset = null;

      try {
        format = options.hash.format || 'L';
      } catch (error) {
        format = 'L';
      }

      m = moment(value);

      if (utcOffset != null) {
        m = m.utcOffset(utcOffset);
      }

      return m.format(format);
    },
    formatCnpj: function formatCnpj(value, options) {
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    formatCpf: function formatCpf(value) {
      if (!Ember.isEmpty(value)) {
        value = value.toString().replace(/[_\. -]/g, '');
        return value.substr(0, 3) + "." + value.substr(3, 3) + "." + value.substr(6, 3) + "-" + value.substr(9, 2);
      } else {
        return '';
      }
    }
  });

  _exports.default = _default;
});