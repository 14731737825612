define("api-dashboard/components/snippets/last-nfe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      return this.getLastNFeData();
    },
    getLastNFeData: function getLastNFeData() {
      var ajaxParams, self;
      self = this;
      console.log('getLastNFeData');
      ajaxParams = {
        method: 'GET'
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: '/snippets/last_nfe'
      }, function (success, content, data) {
        var nfs, tbody;

        if (success) {
          nfs = eval(data.data);
          tbody = $('#ultimas_notas')[0];
          return $.each(nfs, function (k, v) {
            var td, tr;
            tr = document.createElement('tr');
            td = document.createElement('td');
            td.innerHTML = 'Serie: ' + v.serie + '<br/>Nº: ' + v.numero;
            tr.appendChild(td);
            td = document.createElement('td');
            td.innerHTML = v.name;
            tr.appendChild(td);
            td = document.createElement('td');
            td.innerHTML = self.fd(v.data_emissao);
            tr.appendChild(td);
            td = document.createElement('td');
            td.style.textAlign = 'right';
            td.innerHTML = self.fc(Math.floor(v.valor_total));
            tr.appendChild(td);
            return tbody.appendChild(tr);
          });
        }
      });
    },
    fd: function fd(x) {
      var _centralOffset, _date, _userOffset, d;

      d = new Date(x);
      _userOffset = d.getTimezoneOffset() * 60 * 1000;
      _centralOffset = 6 * 60 * 60 * 1000;
      _date = new Date(d.getTime() - _userOffset + _centralOffset);
      return _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear();
    },
    fc: function fc(x) {
      var r;

      if (typeof x === "undefined") {
        r = '0,00';
      } else {
        r = this.formatReal(x);
      }

      return r;
    },
    formatReal: function formatReal(int) {
      var tmp;
      tmp = int + '';
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

      if (tmp.length > 6) {
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }

      return tmp;
    }
  });

  _exports.default = _default;
});