define("api-dashboard/models/person", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    constants: Ember.inject.service(),
    active: _emberData.default.attr('boolean'),
    birthday: _emberData.default.attr('date'),
    blocked: _emberData.default.attr('boolean'),
    cep: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    cnpjEmissao: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    contactName: _emberData.default.attr('string')
  }, _defineProperty(_DS$Model$extend, "contactName", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "cpf", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "createdAt", _emberData.default.attr('date')), _defineProperty(_DS$Model$extend, "enableNfe", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "enderecoCompleto", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "gender", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "inscricaoEstadual", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "inscricaoEstadualEmissao", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "inscricaoMunicipal", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "inscricaoSuframa", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "isentoInscricaoEstadual", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "isentoInscricaoEstadualEmissao", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "legalType", _emberData.default.attr('string', {
    defaultValue: 'NATURAL'
  })), _defineProperty(_DS$Model$extend, "name", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "operatorMessage", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerCpf1", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerCpf2", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerCpf3", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerName1", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerName2", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerName3", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerRg1", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerRg2", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "partnerRg3", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "razaoSocial", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "reasonBlocked", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "regimeSimplesNacional", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "rg", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "special", _emberData.default.attr('boolean')), _defineProperty(_DS$Model$extend, "type", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "updatedAt", _emberData.default.attr('date')), _defineProperty(_DS$Model$extend, "webAddress", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "contactInfos", _emberData.default.hasMany('contact-info', {
    async: false
  })), _defineProperty(_DS$Model$extend, "addresses", _emberData.default.hasMany('address', {
    inverse: 'person',
    async: false
  })), _defineProperty(_DS$Model$extend, "saleType", _emberData.default.belongsTo('sale-type')), _defineProperty(_DS$Model$extend, "relatedPeople", _emberData.default.hasMany('related-person', {
    inverse: 'person'
  })), _defineProperty(_DS$Model$extend, "fStore", _emberData.default.belongsTo('f-store')), _defineProperty(_DS$Model$extend, "lancamentos", _emberData.default.hasMany('lancamento', {
    inverse: 'person'
  })), _defineProperty(_DS$Model$extend, "nameAndDocument", _emberData.default.attr('string', {
    serialize: false
  })), _defineProperty(_DS$Model$extend, "nfeAddressUf", _emberData.default.attr('string', {
    serialize: false
  })), _defineProperty(_DS$Model$extend, "shippingAddress", Ember.computed('addresses', function () {
    return this.findOrCreateAddressByType('shipping');
  })), _defineProperty(_DS$Model$extend, "defaultAddress", Ember.computed('addresses', function () {
    return this.findOrCreateAddressByType('default');
  })), _defineProperty(_DS$Model$extend, "billingAddress", Ember.computed('addresses', function () {
    return this.findOrCreateAddressByType('billing');
  })), _defineProperty(_DS$Model$extend, "document", Ember.computed('cnpj', 'cpf', function () {
    var cnpj, cpf;

    if (this.get('cnpj')) {
      cnpj = this.get('cnpj');
      return cnpj.substr(0, 2) + '.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '/' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2);
    } else if (this.get('cpf')) {
      cpf = this.get('cpf');
      return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2);
    }
  })), _defineProperty(_DS$Model$extend, "isLegal", Ember.computed('legalType', function () {
    return this.get('legalType') === 'LEGAL';
  })), _defineProperty(_DS$Model$extend, "isNatural", Ember.computed('legalType', function () {
    return this.get('legalType') === 'NATURAL';
  })), _defineProperty(_DS$Model$extend, "createDefaultContactInfos", function createDefaultContactInfos() {
    var c, i, len, ref, results, type;
    ref = this.get('constants.contactInfo.types');
    results = [];

    for (i = 0, len = ref.length; i < len; i++) {
      type = ref[i];

      if ((c = this.getFirstContactInfoByType(type)) === void 0) {
        results.push(this.get('contactInfos').addObject(this.store.createRecord('contactInfo', {
          contactType: type,
          value: "",
          removable: false
        })));
      } else {
        results.push(c.set('removable', false));
      }
    }

    return results;
  }), _defineProperty(_DS$Model$extend, "addNewContactInfo", function addNewContactInfo() {
    return this.get('contactInfos').addObject(this.store.createRecord('contactInfo'));
  }), _defineProperty(_DS$Model$extend, "getFirstContactInfoByType", function getFirstContactInfoByType(type) {
    return this.get('contactInfos').findBy('contactType', type);
  }), _defineProperty(_DS$Model$extend, "findOrCreateAddressByType", function findOrCreateAddressByType(type) {
    var addr, self;
    self = this;
    addr = this.get('addresses').find(function (e) {
      return e && e.get('addressType') === type;
    });

    if (!addr) {
      addr = this.store.createRecord('address', {
        addressType: type,
        person: self
      });
      this.store.find('pais', 30).then(function (obj) {
        return addr.set('pais', obj);
      });
      this.get('addresses').addObject(addr);
    }

    return addr;
  }), _defineProperty(_DS$Model$extend, "nomeOuRazaoSocial", Ember.computed('name', function () {
    return this.get('razaoSocial') || this.get('name');
  })), _defineProperty(_DS$Model$extend, "municipio", Ember.computed('defaultAddress', function () {
    return this.get('defaultAddress.municipio');
  })), _defineProperty(_DS$Model$extend, "isentoInscricaoEstadualObserver", Ember.observer('isentoInscricaoEstadual', function () {
    if (this.get('isentoInscricaoEstadual')) {
      return this.set('inscricaoEstadual', null);
    }
  })), _defineProperty(_DS$Model$extend, "isentoInscricaoEstadualEmissaoObserver", Ember.observer('isentoInscricaoEstadualEmissao', function () {
    if (this.get('isentoInscricaoEstadualEmissao')) {
      return this.set('inscricaoEstadualEmissao', null);
    }
  })), _defineProperty(_DS$Model$extend, "legalTypeObserver", Ember.observer('legalType', function () {
    var legalType;

    if (this.get('currentState.isLoading')) {
      return;
    }

    legalType = this.get('legalType');

    switch (legalType) {
      case this.get('constants').person.LEGAL_TYPE_LEGAL:
        this.set('cpf', null);
        this.set('rg', null);
        this.set('gender', null);
        return this.set('birthday', null);

      case this.get('constants').person.LEGAL_TYPE_NATURAL:
        this.set('cnpj', null);
        return this.set('inscricaoEstadualEmissao', null);
    }
  })), _defineProperty(_DS$Model$extend, "phone", Ember.computed('contactInfos', function () {
    return this.getContactInfoValue('phone');
  })), _defineProperty(_DS$Model$extend, "email", Ember.computed('contactInfos', function () {
    return this.getContactInfoValue('email');
  })), _defineProperty(_DS$Model$extend, "getContactInfoValue", function getContactInfoValue(contactInfoType) {
    var contactInfo;
    contactInfo = this.get('contactInfos').find(function (c) {
      return c.get('contactType') === contactInfoType;
    });

    if (contactInfo) {
      return contactInfo.get('value');
    }

    return null;
  }), _defineProperty(_DS$Model$extend, "invalidAddressesForNFe", Ember.computed(function () {
    var billingAddress, defaultAddress, errors;
    billingAddress = this.get('billingAddress');
    defaultAddress = this.get('defaultAddress');
    errors = [];

    if (!billingAddress && !defaultAddress) {
      errors.addObject("Endereço não cadastrado");
    } else {
      if (Ember.isEmpty(billingAddress.get('logradouro'))) {
        errors.addObject('Campo Logradouro');
      }

      if (Ember.isEmpty(billingAddress.get('numero'))) {
        errors.addObject('Campo Número');
      }

      if (Ember.isEmpty(billingAddress.get('bairro'))) {
        errors.addObject('Campo Bairro');
      }

      if (Ember.isEmpty(billingAddress.get('municipio.id'))) {
        errors.addObject('Campo Município');
      }

      if (Ember.isEmpty(billingAddress.get('pais.id'))) {
        errors.addObject('Campo País');
      }

      if (errors.length > 0) {
        errors = [];

        if (Ember.isEmpty(defaultAddress.get('logradouro'))) {
          errors.addObject('Campo Logradouro');
        }

        if (Ember.isEmpty(defaultAddress.get('numero'))) {
          errors.addObject('Campo Número');
        }

        if (Ember.isEmpty(defaultAddress.get('bairro'))) {
          errors.addObject('Campo Bairro');
        }

        if (Ember.isEmpty(defaultAddress.get('municipio.id'))) {
          errors.addObject('Campo Município');
        }

        if (Ember.isEmpty(defaultAddress.get('pais.id'))) {
          errors.addObject('Campo País');
        }
      }
    }

    if (errors.length === 0) {
      return null;
    }

    return errors;
  })), _DS$Model$extend));

  _exports.default = _default;
});