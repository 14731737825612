define("api-dashboard/components/inputs/input-chave-nfe", ["exports", "api-dashboard/mixins/utils", "api-dashboard/components/inputs/base-text-field"], function (_exports, _utils, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend(_utils.default, {
    label: 'Chave NFE',
    pattern: "0000.0000.0000.0000.0000.0000.0000.0000.0000.0000.0000",
    placeholder: 'Chave de Acesso da Nota Fiscal Eletrônica',
    validarValorMascara: function validarValorMascara() {
      return this.validarChaveNfe(this.obterValorSemMascara());
    }
  });

  _exports.default = _default;
});