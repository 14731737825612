define("api-dashboard/templates/busca-referencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gWce0e1n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"principal-tit text-center\"],[8],[0,\"\\n\\t\"],[7,\"h1\",true],[8],[0,\"Referências\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[28,\"busca-referencias/busca-referencias\",null,[[\"class\"],[\"box-busca-nf animated fadeInUp\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/busca-referencia.hbs"
    }
  });

  _exports.default = _default;
});