define("api-dashboard/components/notas-fiscais/nota-fiscal-edit", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    application: Ember.inject.service(),
    bayeux: Ember.inject.service(),
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    camposTotaisDisabled: false,
    closingTabs: false,
    faturaDataPrimeiraParcela: null,
    faturaNumeroParcelas: null,
    faturaParcelamentoIntervalo: null,
    faturaParcelamentoTipoIntervalo: null,
    faturasTabOpen: null,
    identificacaoComplete: false,
    identificacaoTabOpen: null,
    importacaoTabOpen: null,
    issueErrors: [],
    itensTotaisTabOpen: null,
    pendingSave: false,
    referenciada1A: null,
    referenciada: null,
    referenciadaActiveForm: null,
    referenciadaChaveExterna: null,
    referenciadaInterna: null,
    referenciadaNfProdutor: null,
    showCapataziaTaxaSiscomexFields: false,
    showEmitente: false,
    showImportTab: false,
    showNotasReferenciadas: false,
    transportesTabOpen: null,
    contexto: 1,
    init: function init() {
      this.__getShowImportTab();

      this.__getShowCapataziaTaxaSiscomexFields();

      this.get('.valido');
      this.set('showNotasReferenciadas', this.get('model.referenciadas.length') > 0);
      this.set('informarRetencaoIcms', this.setInformarRetencaoIcms());
      this.set('informarLocalEntrega', this.setInformarLocalEntrega());
      this.set('informarLocalRetirada', this.setInformarLocalRetirada());
      return this._super.apply(this, arguments);
    },
    faturaParcelamentoFormUnfilled: Ember.computed('faturaNumeroParcelas', 'faturaDataPrimeiraParcela', 'faturaParcelamentoIntervalo', 'faturaParcelamentoTipoIntervalo', function () {
      return this.get('faturaNumeroParcelas') === null || this.get('faturaDataPrimeiraParcela') === null || this.get('faturaParcelamentoIntervalo') === null || this.get('faturaParcelamentoTipoIntervalo') === null;
    }),
    mostrarTipoVenda: Ember.computed('model.emitente', function () {
      return this.get('model.emitente.mostrarTipoVenda') || false;
    }),
    emitentesForCombo: Ember.computed(function () {
      var emitentes;
      emitentes = this.get('globalLookups.emitentes').toArray();
      emitentes.unshift(this.get('store').createRecord('emitente', {
        id: null,
        nome: 'Todos'
      }));
      return emitentes;
    }),
    itemsAmmountMsg: Ember.computed('model.items.length', function () {
      var desc, itemsAmmount;
      desc = 'Nenhum item';
      itemsAmmount = this.get('model.persistedItems.length');

      if (itemsAmmount === 1) {
        desc = '1 item';
      } else if (itemsAmmount > 1) {
        desc = '' + itemsAmmount + ' itens';
      }

      return desc;
    }),
    onShowNotasReferenciadasChange: Ember.observer('showNotasReferenciadas', function () {
      $('#emissaonfe-notasreferenciadas').slideToggle('fast');
      return this.createNewReferenciada();
    }),
    dataEntradaSaidaLabel: Ember.computed('model.tipoDocumento', function () {
      var tipoDocumento;
      tipoDocumento = this.get('model.tipoDocumento');

      switch (tipoDocumento) {
        case 0:
          return 'Data de entrada';

        case 1:
          return 'Data de saída';

        default:
          return 'Data de entrada/saída';
      }
    }),
    horaEntradaSaidaLabel: Ember.computed('model.tipoDocumento', function () {
      var tipoDocumento;
      tipoDocumento = this.get('model.tipoDocumento');

      switch (tipoDocumento) {
        case 0:
          return 'Hora de entrada';

        case 1:
          return 'Hora de saída';

        default:
          return 'Hora de entrada/saída';
      }
    }),
    classIdentificacaoTab: Ember.computed('model.estadoIdentificacao', function () {
      return this.get('model.estadoIdentificacao');
    }),
    classItensTab: Ember.computed('model.estadoItens', function () {
      return this.get('model.estadoItens');
    }),
    classImportacaoTab: Ember.computed('model.estadoImportacao', function () {
      return this.get('model.estadoImportacao');
    }),
    classCobrancaTab: Ember.computed('model.estadoCobranca', function () {
      return this.get('model.estadoCobranca');
    }),
    classTransporteTab: Ember.computed('model.estadoTransporte', function () {
      return this.get('model.estadoTransporte');
    }),
    identificacaoNextDisabled: Ember.computed('model.estadoIdentificacao', function () {
      return this.get('model.estadoIdentificacao') !== 'success';
    }),
    itemsNextDisabled: Ember.computed('model.estadoItens', function () {
      return this.get('model.estadoItens') !== 'success';
    }),
    importacaoNextDisabled: Ember.computed('model.estadoImportacao', function () {
      return this.get('model.estadoImportacao') !== 'success';
    }),
    cobrancaNextDisabled: Ember.computed('model.estadoCobranca', function () {
      return this.get('model.estadoCobranca') !== 'success';
    }),
    formIncomplete: Ember.computed('model.estadoIdentificacao', 'model.estadoItens', 'model.estadoCobranca', 'model.estadoTransporte', function () {
      return this.get('model.estadoIdentificacao') !== 'success' || this.get('model.estadoItens') !== 'success' || this.get('model.estadoCobranca') !== 'success' || this.get('model.estadoTransporte') !== 'success';
    }),
    localEntregaCamposRequired: Ember.computed('model.logradouroEntrega', 'model.numeroEntrega', 'model.bairroEntrega', 'model.municipioEntrega', function () {
      return !Ember.isEmpty(this.get('model.logradouroEntrega')) || !Ember.isEmpty(this.get('model.numeroEntrega')) || !Ember.isEmpty(this.get('model.bairroEntrega')) || this.get('model.municipioEntrega') != null;
    }),
    localRetiradaCamposRequired: Ember.computed('model.logradouroRetirada', 'model.numeroRetirada', 'model.bairroRetirada', 'model.municipioRetirada', function () {
      return !Ember.isEmpty(this.get('model.logradouroRetirada')) || !Ember.isEmpty(this.get('model.numeroRetirada')) || !Ember.isEmpty(this.get('model.bairroRetirada')) || this.get('model.municipioRetirada') != null;
    }),
    naturezaOperacaoObserver: Ember.observer('model.naturezaOperacao', function () {
      var naturezaOperacao;
      naturezaOperacao = this.get('model.naturezaOperacao');

      if (naturezaOperacao != null && naturezaOperacao.match(/devolu[cçÇ][aãÃ]o/i)) {
        return this.set('model.finalidadeEmissao', 4);
      }
    }),
    emitenteObserver: Ember.observer('model.emitente.defaultInformacoesAdicionais', function () {
      var defaultInformacoesAdicionais, informacoesAdicionais;
      defaultInformacoesAdicionais = this.get('model.emitente.defaultInformacoesAdicionais');

      if (defaultInformacoesAdicionais != null && defaultInformacoesAdicionais !== "") {
        informacoesAdicionais = this.get('model.informacoesAdicionaisContribuinte');

        if (informacoesAdicionais == null || informacoesAdicionais === "") {
          return this.set('model.informacoesAdicionaisContribuinte', defaultInformacoesAdicionais);
        }
      }
    }),
    valorRestanteFatura: Ember.computed('model.duplicatas.[]', 'model.duplicatas.@each.valor', 'model.valorLiquidoFatura', 'model.duplicatas.@each._destroy', function () {
      var valorLiquido, valorRestante;
      valorLiquido = this.get('model.valorLiquidoFatura');
      valorRestante = valorLiquido;
      this.get('model.duplicatas').forEach(function (duplicata) {
        if (duplicata && !Ember.get(duplicata, '_destroy')) {
          return valorRestante -= duplicata.get('valor');
        }
      });

      if (valorRestante < 0) {
        valorRestante = 0;
      }

      return valorRestante;
    }),
    valorRestanteFaturaZero: Ember.computed('valorRestanteFatura', function () {
      return this.get('valorRestanteFatura') <= 0;
    }),
    showDuplicatas: Ember.computed('valorRestanteFatura', 'model.duplicatas.length', function () {
      return this.get('valorRestanteFatura') > 0 || this.get('model.duplicatas.length') > 0;
    }),
    referenciadaFormInternaActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'nfe_interna';
    }),
    referenciadaFormExternaActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'nfe_externa';
    }),
    referenciadaFormCteActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'cte';
    }),
    referenciadaForm1AActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'nf_um_ou_um_a';
    }),
    referenciadaFormNfProdutorActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'nf_produtor';
    }),
    referenciadaFormCupomFiscalActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'cupom_fiscal';
    }),
    referenciadaNfceActive: Ember.computed('referenciada.tipo', function () {
      return this.get('referenciada.tipo') === 'nfce';
    }),
    __getShowImportTab: function __getShowImportTab() {
      var domainConfigs, value;
      domainConfigs = this.get('application.currentDomain.configsJson');
      value = false;

      if (domainConfigs) {
        value = Ember.get(domainConfigs, 'nfe.importacao.habilitado');
      }

      return this.set('showImportTab', Boolean(parseInt(value)));
    },
    __getShowCapataziaTaxaSiscomexFields: function __getShowCapataziaTaxaSiscomexFields() {
      var domainConfigs, value;
      domainConfigs = this.get('application.currentDomain.configsJson');
      value = false;

      if (domainConfigs) {
        value = Ember.get(domainConfigs, 'nfe.importacao.usa_campos_taxas');
      }

      return this.set('showCapataziaTaxaSiscomexFields', Boolean(parseInt(value)));
    },
    newReferenciada: function newReferenciada(tipo) {
      var ref;
      ref = this.get('store').createRecord('referenciada');

      if (tipo != null) {
        ref.set('tipo', tipo);
      }

      return this.set('referenciada', ref);
    },
    createNewReferenciada1A: function createNewReferenciada1A() {
      var ref;
      ref = this.get('store').createRecord('referenciada');
      ref.set('tipo', 'nf_um_ou_um_a');
      return this.set('referenciada1A', ref);
    },
    createNewReferenciadaNfProdutor: function createNewReferenciadaNfProdutor() {
      var ref;
      ref = this.get('store').createRecord('referenciada');
      ref.set('tipo', 'nf_produtor');
      return this.set('referenciadaNfProdutor', ref);
    },
    createNewReferenciada: function createNewReferenciada() {
      return this.set('referenciada', this.get('store').createRecord('referenciada'));
    },
    salvarParcialDisabled: Ember.computed('identificacaoComplete', 'model.isSaving', 'model.isIssuing', 'model.isAutorizada', function () {
      return !this.get('identificacaoComplete') || this.get('model.isSaving') || this.get('model.isIssuing') || this.get('model.isAutorizada');
    }),
    emitirDisabled: Ember.computed('formIncomplete', 'model.isSaving', 'model.isIssuing', 'model.isAutorizada', function () {
      return this.get('formIncomplete') || this.get('model.isSaving') || this.get('model.isIssuing') || this.get('model.isAutorizada');
    }),
    showErrorMessage: Ember.observer('model.mensagemSefaz', function () {
      if (this.get('model.mensagemSefaz') !== 'Autorizado o uso da NF-e') {
        return window.scrollTo(0, 0);
      }
    }),
    changeContexto: function changeContexto(newContexto) {
      var contexto, etapas;
      contexto = this.get('contexto');

      if (contexto !== newContexto && this.get('model.hasDirtyAttributes') && this.get('identificacaoComplete') || contexto !== newContexto && [4, 5].includes(newContexto) || newContexto === 6) {
        etapas = ['identificacao', 'itens', 'documentos_importacao', 'faturas', 'transportes', 'finalizacoes'];
        this.set('model.etapa', etapas[contexto - 1]);
        this.set('model.proximaEtapa', etapas[newContexto - 1]);
        this.send('save');
      }

      this.set('contexto', newContexto);
      return true;
    },
    identificacaoCompleteObserver: Ember.observer('model.estadoIdentificacao', function () {
      var newIdentificacaoComplete, oldIdentificacaoComplete;
      newIdentificacaoComplete = this.get('model.estadoIdentificacao') === 'success';
      oldIdentificacaoComplete = this.get('identificacaoComplete');
      this.set('identificacaoComplete', newIdentificacaoComplete);

      if (oldIdentificacaoComplete && !newIdentificacaoComplete) {
        this.set('closingTabs', true);

        if (this.get('itensTotaisTabOpen')) {
          $('#panel-itenstotais').collapse('hide');
        }

        if (this.get('faturasTabOpen')) {
          $('#panel-cobranca').collapse('hide');
        }

        if (this.get('importacaoTabOpen')) {
          $('#panel-importacao').collapse('hide');
        }

        if (this.get('transportesTabOpen')) {
          $('#panel-transporte').collapse('hide');
        }

        return this.set('closingTabs', false);
      }
    }),
    setTabState: function setTabState(tabLabel, state) {
      var collapseState;
      collapseState = state === 'open' ? 'show' : 'hide';

      switch (tabLabel) {
        case 'identificacao':
          return $('#panel-identificacao').collapse(collapseState);

        case 'itenstotais':
          return $('#panel-itenstotais').collapse(collapseState);

        case 'cobranca':
          return $('#panel-cobranca').collapse(collapseState);

        case 'importacao':
          return $('#panel-importacao').collapse(collapseState);

        case 'transporte':
          return $('#panel-transporte').collapse(collapseState);
      }
    },
    checkIdentificacaoCompleteForTabs: function checkIdentificacaoCompleteForTabs() {
      if (!this.get('identificacaoComplete') && !this.get('closingTabs')) {
        alert('Por favor preencha os dados de identificação da nota');
        $('#naturezaOperacao').focus();
        return false;
      }

      return true;
    },
    checkDuplicatasComplete: function checkDuplicatasComplete() {
      var duplicatasComplete;

      if (this.get('model.duplicatas.length')) {
        duplicatasComplete = true;
        !this.get('model.duplicatas').forEach(function (d) {
          duplicatasComplete = !!d.get('numero') && !!d.get('dataVencimento') && !!d.get('valor');

          if (!duplicatasComplete) {
            return false;
          }
        });

        if (!duplicatasComplete) {
          this.get('application').showAlert('Por favor, preencha todos os campos das Duplicatas antes ' + 'de prosseguir', 'error', 10000);
          return false;
        }
      }

      return true;
    },
    valorFaturaObserver: Ember.observer('model', 'model.valorOriginalFatura', 'model.valorDescontoFatura', 'model.valorDesconto', function () {
      var valorDesconto, valorLiquido, valorTotal;

      if (this.get('model').changedAttributes && this.get('model').changedAttributes().valorDesconto && this.get('model').changedAttributes().valorDesconto[0] !== null && this.get('model').changedAttributes().valorDesconto[1] !== "") {
        this.set('model.valorOriginalFatura', null);
        this.set('model.valorLiquidoFatura', null);
        this.set('model.valorDescontoFatura', null);
        return;
      }

      valorTotal = this.get('model.valorOriginalFatura') || 0;
      valorDesconto = this.get('model.valorDescontoFatura') || 0;
      valorLiquido = valorTotal - valorDesconto;
      return this.set('model.valorLiquidoFatura', new Big(valorLiquido).toFixed(2));
    }),
    formaPagamentoObserver: Ember.observer('model.formaPagamento', function () {
      return this.set('model.valorOriginalFatura', this.get('model.valorTotal'));
    }),
    valorDescontoFocusOut: function valorDescontoFocusOut() {
      var desconto, valorTotal;
      this.set('errors', []);
      desconto = new Big(this.get('model.valorDesconto'));
      valorTotal = new Big(this.get('model.valorTotal'));

      if (desconto.gt(valorTotal)) {
        this.get('application').showAlert('O valor do desconto não pode ser maior do que o valor total da NFe', 'error');
        this.get('errors').addObject({
          valorDesconto: 'O valor do desconto não pode ser maior do que o valor total da NFe'
        });
        this.set('model.valorDesconto', new Big(0));
      }

      return this.send('save');
    },
    didInsertElement: function didInsertElement(evt) {
      var self;
      self = this;
      this.set("insertedElement", true);
      $('#tab a:first').tab('show');
      $('.nfe-total-input').on('change', null, null, function (_this) {
        return function () {
          _this.send('pendSave');

          return _this.set('model.etapa', 'itens');
        };
      }(this)).on('blur', null, null, function (_this) {
        return function () {
          return setTimeout(function () {
            return self.send('doPendingSave');
          }, 100);
        };
      }(this));
      $('#panel-identificacao').on('show.bs.collapse', function (_this) {
        return function () {
          return _this.set('identificacaoTabOpen', true);
        };
      }(this));
      $('#panel-itenstotais').on('show.bs.collapse', function (_this) {
        return function () {
          if (!_this.checkIdentificacaoCompleteForTabs()) {
            return false;
          }

          return _this.set('itensTotaisTabOpen', true);
        };
      }(this));
      $('#panel-cobranca').on('show.bs.collapse', function (_this) {
        return function () {
          if (!_this.checkIdentificacaoCompleteForTabs()) {
            return false;
          }

          if (_this.checkItensETotaisCompleteForTabs()) {
            _this.setTotaisFaturaWhenNull();

            _this.set('faturasTabOpen', true);

            return true;
          }

          return _this.set('faturasTabOpen', false);
        };
      }(this));
      $('#panel-transporte').on('show.bs.collapse', function (_this) {
        return function () {
          if (!_this.checkIdentificacaoCompleteForTabs() || !_this.checkDuplicatasComplete()) {
            return false;
          }

          return _this.set('transportesTabOpen', _this.checkItensETotaisCompleteForTabs());
        };
      }(this));
      $('#panel-identificacao').on('hide.bs.collapse', function (_this) {
        return function () {
          if (!_this.get('identificacaoComplete')) {
            alert('Preencha todos os dados obrigatórios antes de continuar');
            return false;
          }

          _this.set('identificacaoTabOpen', false);

          return true;
        };
      }(this));
      $('#panel-itenstotais').on('hide.bs.collapse', function (_this) {
        return function () {
          _this.set('itensTotaisTabOpen', false);

          return _this.checkItensETotaisCompleteForTabs();
        };
      }(this));
      $('#panel-cobranca').on('hide.bs.collapse', function (_this) {
        return function () {
          _this.set('faturasTabOpen', false);

          return true;
        };
      }(this));
      $('#panel-transporte').on('hide.bs.collapse', function (_this) {
        return function () {
          _this.set('transportesTabOpen', false);

          return true;
        };
      }(this));

      if (!this.get('identificacaoComplete')) {
        $('#panel-identificacao').collapse('show');
      }

      $('#panel-identificacao').click(function (_this) {
        return function () {
          return _this.changeContexto(1);
        };
      }(this));
      $('#panel-items').click(function (_this) {
        return function () {
          return _this.changeContexto(2);
        };
      }(this));
      $('#panel-faturas').click(function (_this) {
        return function () {
          if (_this.checkItensETotaisCompleteForTabs()) {
            return _this.changeContexto(4);
          }
        };
      }(this));
      $('#panel-transportation').click(function (_this) {
        return function () {
          if (_this.checkItensETotaisCompleteForTabs()) {
            return _this.changeContexto(5);
          }
        };
      }(this));
      $('#nextIdentificacao').click(function (_this) {
        return function () {
          var fn;

          _this.setTabState('identificacao', 'close');

          fn = function fn() {
            $('#panel-itenstotais').collapse('show');

            _this.changeContexto(2);

            return _this.scrollToElement('#panel-items');
          };

          setTimeout(fn, 100);
          return false;
        };
      }(this));
      $('#nextItems').click(function (_this) {
        return function () {
          var fn;

          _this.setTabState('itenstotais', 'close');

          fn = function fn() {
            if (_this.checkItensETotaisCompleteForTabs()) {
              if (_this.get('showImportTab')) {
                $('#panel-docsimportacao').collapse('show');

                _this.changeContexto(3);

                return _this.scrollToElement('#panel-importacao');
              } else {
                $('#panel-cobranca').collapse('show');

                _this.changeContexto(4);

                return _this.scrollToElement('#panel-faturas');
              }
            }
          };

          setTimeout(fn, 100);
          return false;
        };
      }(this));
      $('#nextCobranca').click(function (_this) {
        return function () {
          var fn;

          fn = function fn() {
            if (_this.checkDuplicatasComplete()) {
              _this.setTabState('cobranca', 'close');

              $('#panel-transporte').collapse('show');

              _this.changeContexto(5);

              return _this.scrollToElement('#panel-transportation');
            }
          };

          setTimeout(fn, 100);
          return false;
        };
      }(this));
      return $('#nextFinalizar').click(function (_this) {
        return function () {
          var fn;

          _this.setTabState('transporte', 'close');

          fn = function fn() {
            $('#panel-transporte').collapse('hide');
            return _this.changeContexto(6);
          };

          setTimeout(fn, 100);
          return false;
        };
      }(this));
    },
    nextImportacaoClick: function nextImportacaoClick() {
      var self;
      self = this;
      this.setTabState('importacao', 'close');
      setTimeout(function () {
        self.$('#panel-cobranca').collapse('show');
        self.setTabState('importacao', 'hide');
        self.$('#panel-docsimportacao').collapse('hide');
        self.changeContexto(4);
        return self.scrollToElement('#panel-faturas');
      }, 100);
      return true;
    },
    divImportacaoClick: function divImportacaoClick() {
      if (!this.$('#panel-importacao a').hasClass('collapsed')) {
        this.$('#panel-importacao a').addClass('collapsed');
        this.$('#panel-importacao a').attr('aria-expanded', 'false');
        this.$('#panel-importacao #panel-docsimportacao').removeClass('in');
        this.setTabState('importacao', 'hide');
        this.$('#panel-docsimportacao').collapse('hide');
        return this.$('#panel-importacao').collapse('hide');
      } else {
        if (this.checkItensETotaisCompleteForTabs()) {
          this.$('#panel-importacao a').removeClass('collapsed');
          this.$('#panel-importacao a').attr('aria-expanded', 'true');
          this.$('#panel-importacao #panel-docsimportacao').addClass('in');
          this.setTabState('importacao', 'show');
          return this.$('#panel-docsimportacao').collapse('show');
        }
      }
    },
    scrollToElement: function scrollToElement(elmSelector) {
      return $('html, body').animate({
        scrollTop: $(elmSelector).offset().top - 52
      }, 500);
    },
    validateReferenciadaValues: function validateReferenciadaValues() {
      return false;
    },
    checkItensETotaisCompleteForTabs: function checkItensETotaisCompleteForTabs() {
      if (this.get('model.items.length') > 0) {
        return true;
      }

      this.get('application').showAlert('Por favor, revise a seção "Itens e Totais" antes de prosseguir', 'error');
      return false;
    },
    onBeforeChangeTipoReferenciada: function onBeforeChangeTipoReferenciada(previousValue, currentValue, event) {
      return this.createNewReferenciada();
    },
    setTotaisFaturaWhenNull: function setTotaisFaturaWhenNull() {
      if (!this.get('model.valorLiquidoFatura') || this.get('model.valorLiquidoFatura') < 0.01) {
        this.set('model.valorLiquidoFatura', this.get('model.valorTotal'));
        return this.set('model.valorOriginalFatura', this.get('model.valorTotal'));
      }
    },
    setInformarLocalEntrega: function setInformarLocalEntrega() {
      return this.get('model.logradouroEntrega') || this.get('model.numeroEntrega') || this.get('model.complementoEntrega') || this.get('model.bairroEntrega') || this.get('model.municipioEntrega.id') || this.get('model.cnpjEntrega') || this.get('model.cpfEntrega');
    },
    informarLocalEntregaObserver: Ember.observer('informarLocalEntrega', function () {
      if (!this.get('informarLocalEntrega')) {
        this.set('model.logradouroEntrega', null);
        this.set('model.numeroEntrega', null);
        this.set('model.complementoEntrega', null);
        this.set('model.bairroEntrega', null);
        this.set('model.municipioEntrega', null);
        this.set('model.cnpjEntrega', null);
        return this.set('model.cpfEntrega', null);
      }
    }),
    setInformarLocalRetirada: function setInformarLocalRetirada() {
      return this.get('model.logradouroRetirada') || this.get('model.numeroRetirada') || this.get('model.complementoRetirada') || this.get('model.bairroRetirada') || this.get('model.municipioRetirada.id') || this.get('model.cnpjRetirada') || this.get('model.cpfRetirada');
    },
    informarLocalRetiradaObserver: Ember.observer('informarLocalRetirada', function () {
      if (!this.get('informarLocalRetirada')) {
        this.set('model.logradouroRetirada', null);
        this.set('model.numeroRetirada', null);
        this.set('model.complementoRetirada', null);
        this.set('model.bairroRetirada', null);
        this.set('model.municipioRetirada', null);
        this.set('model.cnpjRetirada', null);
        return this.set('model.cpfRetirada', null);
      }
    }),
    setInformarRetencaoIcms: function setInformarRetencaoIcms() {
      return this.get('model.transporteIcmsMunicipio.id') || this.get('model.transporteIcmsCfop.id') || this.get('model.transporteIcmsServico') || this.get('model.transporteIcmsBaseCalculo') || this.get('model.transporteIcmsAliquota') || this.get('model.transporteIcmsValor');
    },
    informarRetencaoIcmsObserver: Ember.observer('informarRetencaoIcms', function () {
      if (!this.get('informarRetencaoIcms')) {
        this.set('model.transporteIcmsMunicipio', null);
        this.set('model.transporteIcmsCfop', null);
        this.set('model.transporteIcmsServico', null);
        this.set('model.transporteIcmsBaseCalculo', null);
        this.set('model.transporteIcmsAliquota', null);
        return this.set('model.transporteIcmsValor', null);
      }
    }),
    actions: {
      downloadXml: function downloadXml() {
        return this.downloadURL("/notas_fiscais/" + this.get('model.id') + ".xml", this.get('model.chaveNfe') + ".xml");
      },
      downloadPdf: function downloadPdf() {
        return this.downloadURL("/notas_fiscais/" + this.get('model.id') + ".pdf", this.get('model.chaveNfe') + ".pdf");
      },
      addReferenciada: function addReferenciada() {
        var referenciada;
        referenciada = this.get('referenciada');

        if (referenciada.get('valido')) {
          if (referenciada.get('firstEdit')) {
            this.get('model.referenciadas').pushObject(referenciada);
          }

          referenciada.set('editing', false);
          referenciada.set('firstEdit', false);
          referenciada.set('notaFiscal', this.get('model'));
          return this.createNewReferenciada();
        } else {
          return this.get('application').showAlert('Os dados das Notas Referenciadas devem ser preenchidos ' + 'corretamente', 'error');
        }
      },
      showReferenciadaForm: function showReferenciadaForm(type) {
        if (type === 'nf_um_ou_um_a') {
          this.createNewReferenciada1A();
        }

        if (type === 'nf_produtor') {
          this.createNewReferenciadaNfProdutor();
        }

        return this.set('referenciadaActiveForm', type);
      },
      pendSave: function pendSave() {
        return this.set('pendingSave', true);
      },
      doPendingSave: function doPendingSave(a, b) {
        if (this.get('pendingSave')) {
          this.send('save');
          return this.set('pendingSave', false);
        }
      },
      editReferenciada: function editReferenciada(referenciada) {
        if (!this.get('referenciada.editing')) {
          referenciada.set('editing', true);
          return this.set('referenciada', referenciada);
        }
      },
      deleteReferenciada: function deleteReferenciada(referenciada) {
        if (!this.get('model.isSaving')) {
          if (referenciada.get('isNew')) {
            if (referenciada.get('editing')) {
              this.createNewReferenciada();
            }

            return referenciada.deleteRecord();
          } else {
            if (confirm('Deseja mesmo apagar esta nota referenciada?')) {
              referenciada.set('_destroy', true);

              if (referenciada.get('editing')) {
                return this.createNewReferenciada();
              }
            }
          }
        }
      },
      editDuplicata: function editDuplicata(duplicata) {
        if (!this.get('model.isSaving')) {
          return duplicata.set("editing", true);
        }
      },
      deleteDuplicata: function deleteDuplicata(duplicata) {
        return duplicata.set('_destroy', true);
      },
      newDuplicata: function newDuplicata() {
        if (!this.get('model.isSaving')) {
          return this.get('model.duplicatas').createRecord({
            editing: true
          });
        }
      },
      editVolume: function editVolume(volume) {
        if (!this.get('model.isSaving')) {
          return volume.set("editing", true);
        } else {
          return this.get('application').showAlert('Não é possível editar o registro nesse momento. ' + 'Aguarde até que o Rascunho da Nota Fiscal seja salvo.', 'warning');
        }
      },
      deleteVolume: function deleteVolume(volume) {
        if (!this.get('model.isSaving')) {
          return volume.set('_destroy', true);
        }
      },
      newVolume: function newVolume() {
        if (!this.get('model.isSaving')) {
          return this.get('model.volumes').createRecord({
            editing: true
          });
        }
      },
      gerarDuplicatas: function gerarDuplicatas() {
        var accumulated, dataParcela, dataPrimeiraParcela, diferenca, duplicatas, numeroParcelas, parcela, parcelamentoIntervalo, results, tipoIntervalo, valorParcela, valorTotal;

        if (this.get('faturaParcelamentoFormUnfilled')) {
          return alert('Precisa preencher dados para parcelamento.');
        } else {
          valorTotal = new Big(this.get('valorRestanteFatura'));
          numeroParcelas = new Big(this.get('faturaNumeroParcelas'));
          dataPrimeiraParcela = this.get('faturaDataPrimeiraParcela');
          parcelamentoIntervalo = new Big(this.get('faturaParcelamentoIntervalo'));
          tipoIntervalo = this.get('faturaParcelamentoTipoIntervalo');
          valorParcela = valorTotal.div(numeroParcelas).round(2);
          diferenca = valorTotal.minus(valorParcela.times(numeroParcelas)).round(2);
          parcela = 1;
          duplicatas = this.get('model.duplicatas');
          accumulated = 0.0;
          results = [];

          while (parcela <= numeroParcelas) {
            switch (tipoIntervalo) {
              case 'dias':
                dataParcela = moment(dataPrimeiraParcela).add(parcela * parcelamentoIntervalo, 'd')._d;
                break;

              case 'semanas':
                dataParcela = moment(dataPrimeiraParcela).add(parcela * parcelamentoIntervalo, 'w')._d;
                break;

              case 'meses':
                dataParcela = moment(dataPrimeiraParcela).add(parcela * parcelamentoIntervalo, 'M')._d;
            }

            if (parseInt(parcela) === parseInt(numeroParcelas)) {
              valorParcela = valorParcela.plus(diferenca).round(2);
            }

            duplicatas.createRecord({
              numero: parcela,
              dataVencimento: dataParcela,
              valor: valorParcela.valueOf()
            });
            accumulated += valorParcela;
            results.push(parcela++);
          }

          return results;
        }
      },
      issue: function issue() {
        var self;
        self = this;

        if (!this.get('model').get('isValid')) {
          this.get('model').send('becameValid');
        }

        this.set('camposTotaisDisabled', true);
        this.set('model.isIssuing', true);
        this.set('model.js', true);
        return this.get('model').save().then(function (obj) {
          var ajaxParams;
          self.set('model.shouldShowErrors', false);
          ajaxParams = {
            contentType: "application/json; charset=utf-8",
            type: "PUT"
          };
          return self.get("store").externalAjax(ajaxParams, {
            apiURL: "/notas_fiscais/" + self.get('model.id') + "/send_invoice.json"
          }, function (sucesso, data, callbackParams) {
            var errors;

            if (sucesso) {
              self.set('issueErrors', []);
              self.get('application').showAlert('Sua nota fiscal foi enviada e estamos enviando ' + 'para a receita. Você será notificado quando a nota for autorizada ou se houver ' + 'algum erro, mesmo que esteja em outra tela do sistema.', 'success');
              self.get('store').pushPayload(data);
              self.get('model').reload();
            } else {
              errors = callbackParams.jqXHR.responseJSON;

              if (errors.errors.base) {
                errors = errors.errors.base[0].split(',');
                self.set('issueErrors', errors);
              } else {
                self.set('issueErrors', 'Contate o suporte técnico (erro desconhecido)');
              }
            }

            self.set('camposTotaisDisabled', false);
            return self.set('model.isIssuing', false);
          });
        });
      },
      save: function save() {
        var self;
        self = this;
        document.activeElement.blur();

        if (!this.get('model').get('isValid')) {
          this.get('model').send('becameValid');
        }

        this.set('camposTotaisDisabled', true);
        this.set('model.js', true);
        return this.get('model').save().then(function (obj) {
          self.set('model', obj);
          self.set('camposTotaisDisabled', false);
          return self.get('application').showAlert('Rascunho salvo com sucesso', 'success');
        }, function (error) {
          var msg;
          self.set('camposTotaisDisabled', false);
          msg = "Erro ao salvar rascunho da NF-e: " + error;
          return self.get('application').showAlert(msg, 'error');
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();

        if (!this.get('model.id')) {
          this.get('store').unloadRecord(this.get('model'));
        }

        return this.sendAction('cancelAction');
      },
      newDocImportacao: function newDocImportacao(item) {
        var doc;
        doc = item.get('documentosImportacao').createRecord();
        return this.sendAction('editDocImportacaoAction', doc);
      },
      editDocImportacao: function editDocImportacao(doc) {
        return this.sendAction('editDocImportacaoAction', doc);
      },
      deleteDocImportacao: function deleteDocImportacao(doc) {
        var self;

        if (!this.get('model.isSaving')) {
          if (window.confirm('Deseja realmente excluir este documento de importação?')) {
            self = this;

            if (doc) {
              doc.deleteRecord();
              return doc.save().then(function (obj) {}, function (obj) {
                doc.rollbackAttributes();
                self.get('store').unloadRecord(doc);
                return alert('Não foi possível excluir este documento de importação. Por favor tente ' + 'novamente em outro momento ou comunique o administrador do sistema.');
              });
            }
          }
        }
      },
      newAdicao: function newAdicao(doc) {
        return doc.get('adicoes').createRecord();
      },
      editAdicao: function editAdicao(adicao) {
        return adicao.set('editing', true);
      },
      finishEditAdicao: function finishEditAdicao(adicao) {
        return adicao.save().then(function (_this) {
          return function (obj) {
            return adicao.set('editing', false);
          };
        }(this), function (_this) {
          return function () {
            return alert('Não foi possível salvar esta adição. Verifique os campos necessários e tente novamente.');
          };
        }(this));
      },
      deleteAdicao: function deleteAdicao(adicao) {
        if (!this.get('model.isSaving')) {
          if (window.confirm('Deseja realmente excluir esta adição ao documento?')) {
            adicao.deleteRecord();
            return adicao.save().then(function (obj) {}, function (obj) {
              adicao.rollbackAttributes();
              this.get('store').unloadRecord(adicao);
              return alert('Não foi possível excluir esta adição. Por favor tente novamente em outro ' + 'momento ou comunique o administrador do sistema.');
            });
          }
        }
      },
      onSelectDestinatario: function onSelectDestinatario(record) {
        var errors, person;
        person = record.record;
        errors = person.get('invalidAddressesForNFe');
        errors = errors || person.get('invalidAddressesForNFe');

        if (errors && errors.length > 0) {
          this.get('application').showAlert('Este destinatário não pode ser utilizado pois os seus ' + 'dados não estão completos!', 'error');
          return this.get('issueErrors').addObject("Erro nos dados de endereço do Destinatário: " + ("" + errors.join(', ')));
        } else {
          return this.set('issueErrors.length', 0);
        }
      }
    }
  });

  _exports.default = _default;
});