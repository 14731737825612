define("api-dashboard/components/spinners/animacao-quadrado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SpinnersAnimacaoQuadradoComponent;
  SpinnersAnimacaoQuadradoComponent = Ember.Component.extend();
  var _default = SpinnersAnimacaoQuadradoComponent;
  _exports.default = _default;
});