define("api-dashboard/components/inputs/input-file-image", ["exports", "api-dashboard/components/inputs/input-file", "api-dashboard/mixins/utils"], function (_exports, _inputFile, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inputFile.default.extend(_utils.default, {
    extensoesPermitidas: ["JPEG", "JPG", "PNG", "GIF"],
    tratarPosAnexoSucesso: function tratarPosAnexoSucesso(callback) {
      var self;

      if (callback == null) {
        callback = function callback(options) {
          if (options == null) {
            options = {};
          }
        };
      }

      self = this;
      return this.fileToBase64({
        file: this.$().prop("files")[0]
      }, function (sucesso, data) {
        if (sucesso) {
          return self.sendAction("actionOnAnexadoSucesso", data, function () {});
        } else {
          return self.sendAction("actionOnTipoArquivoNaoPermitido");
        }
      });
    }
  });

  _exports.default = _default;
});