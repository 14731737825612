define("api-dashboard/helpers/format", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_formatters.default, {
    intl: Ember.inject.service(),
    compute: function compute(params) {
      var intlKey, newParams, normalizedKey;

      if (params[2] && params[2]['intlKey']) {
        if (params[0]) {
          normalizedKey = Ember.String.underscore(params[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toLowerCase();
        } else {
          normalizedKey = '';
        }

        intlKey = params[2]['intlKey'] + "." + normalizedKey;
        newParams = params.slice();

        if (this.get('intl').exists(intlKey)) {
          newParams[0] = this.get('intl').t(intlKey);
        }

        params = newParams;
      }

      return this.format(params);
    }
  });

  _exports.default = _default;
});