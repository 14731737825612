define("api-dashboard/routes/rotas-abstratas/rota-autenticavel-token", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    beforeModel: function beforeModel(transition) {
      var domainId, token;

      if (transition.queryParams) {
        token = transition.queryParams['token'];
        domainId = transition.queryParams['domain_id'];

        if (token) {
          this.get("userSession").authenticate({
            token: token,
            domainId: domainId
          }, function (sucesso, dados) {
            if (sucesso) {} else {}
          });
          return this._super(transition);
        }
      }

      return this._super(transition);
    }
  });

  _exports.default = _default;
});