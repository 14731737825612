define("api-dashboard/utils/acesso-icms", ["exports", "api-dashboard/utils/acesso"], function (_exports, _acesso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _acesso.default.extend({
    item: null,
    mostrarAliquota: false,
    obrigarAliquota: false,
    mostrarAliquotaCreditoSimples: false,
    obrigarAliquotaCreditoSimples: false,
    mostrarAliquotaFinal: false,
    obrigarAliquotaFinal: false,
    mostrarAliquotaSt: false,
    obrigarAliquotaSt: false,
    mostrarBaseCalculo: false,
    obrigarBaseCalculo: false,
    mostrarBaseCalculoDestinoSt: false,
    obrigarBaseCalculoDestinoSt: false,
    mostrarBaseCalculoRetidoSt: false,
    obrigarBaseCalculoRetidoSt: false,
    mostrarBaseCalculoSt: false,
    obrigarBaseCalculoSt: false,
    mostrarModalidadeBaseCalculo: false,
    obrigarModalidadeBaseCalculo: false,
    mostrarModalidadeBaseCalculoSt: false,
    obrigarModalidadeBaseCalculoSt: false,
    mostrarMotivoDesoneracao: false,
    obrigarMotivoDesoneracao: false,
    mostrarPartilhaOrigemDestino: false,
    obrigarPorcentualBaseCalculoOpPropria: false,
    mostrarPorcentualBaseCalculoOpPropria: false,
    obrigarPorcentualMargemValorAdicionadoSt: false,
    mostrarPorcentualMargemValorAdicionadoSt: false,
    obrigarPorcentualReducaoBaseCalculo: false,
    mostrarPorcentualReducaoBaseCalculo: false,
    obrigarPorcentualReducaoBaseCalculoSt: false,
    mostrarPorcentualReducaoBaseCalculoSt: false,
    mostrarUfSt: false,
    obrigarUfSt: false,
    mostrarValor: false,
    obrigarValor: false,
    mostrarValorCreditoSimples: false,
    obrigarValorCreditoSimples: false,
    mostrarValorDesonerado: false,
    obrigarValorDesonerado: false,
    mostrarValorDestinoSt: false,
    obrigarValorDestinoSt: false,
    mostrarValorRetidoSt: false,
    obrigarValorRetidoSt: false,
    mostrarValorSt: false,
    obrigarValorSt: false,
    mostrarValorSubstituto: false,
    obrigarValorSubstituto: false,
    mostrarRetencaoOrigemSt: false,
    mostrarSt: false,
    mostrarValorDiferido: false,
    obrigarValorDiferido: false,
    mostrarValorOperacao: false,
    obrigarValorOperacao: false,
    mostrarPercentualDiferimento: false,
    obrigarPercentualDiferimento: false,
    acessos: {
      icmsAliquota: {
        mostrar: ['00', '10', '20', '51', '70', '90', '900'],
        obrigar: ['00', '10', '20', '70']
      },
      icmsAliquotaCreditoSimples: {
        mostrar: ['101', '201', '900'],
        obrigar: ['101', '201']
      },
      icmsAliquotaFinal: {
        mostrar: ['60', '500'],
        obrigar: []
      },
      icmsAliquotaSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: ['10', '30', '70', '201', '202', '203']
      },
      icmsBaseCalculo: {
        mostrar: ['00', '10', '20', '51', '70', '90', '900'],
        obrigar: ['00', '10', '20', '70']
      },
      icmsBaseCalculoDestinoSt: {
        mostrar: [],
        obrigar: []
      },
      icmsBaseCalculoRetidoSt: {
        mostrar: ['60', '500'],
        obrigar: []
      },
      icmsBaseCalculoSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: ['10', '30', '70', '201', '202', '203']
      },
      icmsModalidadeBaseCalculo: {
        mostrar: ['00', '10', '20', '51', '70', '90', '900'],
        obrigar: ['00', '10', '20', '70']
      },
      icmsModalidadeBaseCalculoSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: ['10', '30', '70', '201', '202', '203']
      },
      icmsMotivoDesoneracao: {
        mostrar: ['40', '41', '50'],
        obrigar: []
      },
      icmsPartilhaOrigemDestino: {
        mostrar: ['10', '90'],
        obrigar: []
      },
      icmsPorcentualBaseCalculoOpPropria: {
        mostrar: [],
        obrigar: []
      },
      icmsPorcentualMargemValorAdicionadoSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: []
      },
      icmsPorcentualReducaoBaseCalculo: {
        mostrar: ['20', '51', '70', '90', '900'],
        obrigar: ['20', '70']
      },
      icmsPorcentualReducaoBaseCalculoSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: []
      },
      icmsRetencaoOrigemSt: {
        mostrar: ['41'],
        obrigar: []
      },
      icmsSt: {
        mostrar: ['10', '30', '60', '70', '90', '201', '202', '203', '500', '900'],
        obrigar: []
      },
      icmsUfSt: {
        mostrar: [],
        obrigar: []
      },
      icmsValor: {
        mostrar: ['00', '10', '20', '51', '70', '90', '900'],
        obrigar: ['00', '10', '20', '70']
      },
      icmsValorCreditoSimples: {
        mostrar: ['101', '201', '900'],
        obrigar: ['101', '201']
      },
      icmsValorDesonerado: {
        mostrar: ['40', '41', '50'],
        obrigar: []
      },
      icmsValorDestinoSt: {
        mostrar: [],
        obrigar: []
      },
      icmsValorRetidoSt: {
        mostrar: ['60', '500'],
        obrigar: []
      },
      icmsValorSt: {
        mostrar: ['10', '30', '70', '90', '201', '202', '203', '900'],
        obrigar: ['10', '30', '70', '201', '202', '203']
      },
      icmsValorSubstituto: {
        mostrar: ['60', '500'],
        obrigar: []
      },
      icmsValorDiferido: {
        mostrar: ['51'],
        obrigar: []
      },
      icmsValorOperacao: {
        mostrar: ['51'],
        obrigar: []
      },
      icmsPercentualDiferimento: {
        mostrar: ['51'],
        obrigar: []
      }
    },
    situacaoTributaria: Ember.computed('item', 'item.icmsSituacaoTributaria', function (key, value) {
      if (this.get('item') && this.get('item.icmsSituacaoTributaria')) {
        return this.get('item.icmsSituacaoTributaria');
      }

      if (arguments.length > 1) {
        return value;
      }

      return '';
    }),
    partilhaOrigemDestino: Ember.computed('item', 'item.icmsPartilhaOrigemDestino', function (key, value) {
      if (this.get('item') && this.get('item.icmsPartilhaOrigemDestino')) {
        return this.get('item.icmsPartilhaOrigemDestino');
      }

      if (arguments.length > 1) {
        return value;
      }

      return false;
    }),
    retencaoOrigemSt: Ember.computed('item', 'item.icmsRetencaoOrigemSt', function (key, value) {
      if (this.get('item') && this.get('item.icmsRetencaoOrigemSt')) {
        return this.get('item.icmsRetencaoOrigemSt');
      }

      if (arguments.length > 1) {
        return value;
      }

      return false;
    }),
    updateAcessos: Ember.observer('item', function () {
      return this.verificarAcessos();
    }),
    updateAcessosPartilha: function updateAcessosPartilha(st) {
      return this.verificarAcessos();
    },
    updateAcessosRetencao: function updateAcessosRetencao(st) {
      return this.verificarAcessos();
    },
    verificarAcessos: Ember.observer('situacaoTributaria', 'partilhaOrigemDestino', 'retencaoOrigemSt', function () {
      var partilhaOrigemDestino, retencaoOrigemSt, situacaoTributaria;
      situacaoTributaria = this.get('situacaoTributaria');
      partilhaOrigemDestino = this.get('partilhaOrigemDestino');
      retencaoOrigemSt = this.get('retencaoOrigemSt');
      this.set('mostrarAliquota', this.mostrar(situacaoTributaria, 'icmsAliquota'));
      this.set('obrigarAliquota', this.obrigar(situacaoTributaria, 'icmsAliquota'));
      this.set('mostrarAliquotaCreditoSimples', this.mostrar(situacaoTributaria, 'icmsAliquotaCreditoSimples'));
      this.set('obrigarAliquotaCreditoSimples', this.obrigar(situacaoTributaria, 'icmsAliquotaCreditoSimples'));
      this.set('mostrarAliquotaFinal', this.mostrar(situacaoTributaria, 'icmsAliquotaFinal'));
      this.set('obrigarAliquotaFinal', this.obrigar(situacaoTributaria, 'icmsAliquotaFinal'));
      this.set('mostrarAliquotaSt', this.mostrar(situacaoTributaria, 'icmsAliquotaSt'));
      this.set('obrigarAliquotaSt', this.obrigar(situacaoTributaria, 'icmsAliquotaSt'));
      this.set('mostrarBaseCalculo', this.mostrar(situacaoTributaria, 'icmsBaseCalculo'));
      this.set('obrigarBaseCalculo', this.obrigar(situacaoTributaria, 'icmsBaseCalculo'));
      this.set('mostrarBaseCalculoDestinoSt', this.mostrar(situacaoTributaria, 'icmsBaseCalculoDestinoSt'));
      this.set('obrigarBaseCalculoDestinoSt', this.obrigar(situacaoTributaria, 'icmsBaseCalculoDestinoSt'));
      this.set('mostrarBaseCalculoRetidoSt', this.mostrar(situacaoTributaria, 'icmsBaseCalculoRetidoSt'));
      this.set('obrigarBaseCalculoRetidoSt', this.obrigar(situacaoTributaria, 'icmsBaseCalculoRetidoSt'));
      this.set('mostrarBaseCalculoSt', this.mostrar(situacaoTributaria, 'icmsBaseCalculoSt'));
      this.set('obrigarBaseCalculoSt', this.obrigar(situacaoTributaria, 'icmsBaseCalculoSt'));
      this.set('mostrarModalidadeBaseCalculo', this.mostrar(situacaoTributaria, 'icmsModalidadeBaseCalculo'));
      this.set('obrigarModalidadeBaseCalculo', this.obrigar(situacaoTributaria, 'icmsModalidadeBaseCalculo'));
      this.set('mostrarModalidadeBaseCalculoSt', this.mostrar(situacaoTributaria, 'icmsModalidadeBaseCalculoSt'));
      this.set('obrigarModalidadeBaseCalculoSt', this.obrigar(situacaoTributaria, 'icmsModalidadeBaseCalculoSt'));
      this.set('mostrarMotivoDesoneracao', this.mostrar(situacaoTributaria, 'icmsMotivoDesoneracao'));
      this.set('obrigarMotivoDesoneracao', this.obrigar(situacaoTributaria, 'icmsMotivoDesoneracao'));
      this.set('mostrarPorcentualBaseCalculoOpPropria', this.mostrar(situacaoTributaria, 'icmsPorcentualBaseCalculoOpPropria'));
      this.set('obrigarPorcentualBaseCalculoOpPropria', this.obrigar(situacaoTributaria, 'icmsPorcentualBaseCalculoOpPropria'));
      this.set('mostrarPorcentualMargemValorAdicionadoSt', this.mostrar(situacaoTributaria, 'icmsPorcentualMargemValorAdicionadoSt'));
      this.set('obrigarPorcentualMargemValorAdicionadoSt', this.obrigar(situacaoTributaria, 'icmsPorcentualMargemValorAdicionadoSt'));
      this.set('mostrarPorcentualReducaoBaseCalculo', this.mostrar(situacaoTributaria, 'icmsPorcentualReducaoBaseCalculo'));
      this.set('obrigarPorcentualReducaoBaseCalculo', this.obrigar(situacaoTributaria, 'icmsPorcentualReducaoBaseCalculo'));
      this.set('mostrarPorcentualReducaoBaseCalculoSt', this.mostrar(situacaoTributaria, 'icmsPorcentualReducaoBaseCalculoSt'));
      this.set('obrigarPorcentualReducaoBaseCalculoSt', this.obrigar(situacaoTributaria, 'icmsPorcentualReducaoBaseCalculoSt'));
      this.set('mostrarSt', this.mostrar(situacaoTributaria, 'icmsSt'));
      this.set('obrigarUfSt', this.obrigar(situacaoTributaria, 'icmsUfSt'));
      this.set('mostrarUfSt', this.mostrar(situacaoTributaria, 'icmsUfSt'));
      this.set('obrigarValor', this.obrigar(situacaoTributaria, 'icmsValor'));
      this.set('mostrarValor', this.mostrar(situacaoTributaria, 'icmsValor'));
      this.set('obrigarValorCreditoSimples', this.obrigar(situacaoTributaria, 'icmsValorCreditoSimples'));
      this.set('mostrarValorCreditoSimples', this.mostrar(situacaoTributaria, 'icmsValorCreditoSimples'));
      this.set('obrigarValorDesonerado', this.obrigar(situacaoTributaria, 'icmsValorDesonerado'));
      this.set('mostrarValorDesonerado', this.mostrar(situacaoTributaria, 'icmsValorDesonerado'));
      this.set('obrigarValorDestinoSt', this.obrigar(situacaoTributaria, 'icmsValorDestinoSt'));
      this.set('mostrarValorDestinoSt', this.mostrar(situacaoTributaria, 'icmsValorDestinoSt'));
      this.set('obrigarValorRetidoSt', this.obrigar(situacaoTributaria, 'icmsValorRetidoSt'));
      this.set('mostrarValorRetidoSt', this.mostrar(situacaoTributaria, 'icmsValorRetidoSt'));
      this.set('obrigarValorSt', this.obrigar(situacaoTributaria, 'icmsValorSt'));
      this.set('mostrarValorSt', this.mostrar(situacaoTributaria, 'icmsValorSt'));
      this.set('obrigarValorSubstituto', this.obrigar(situacaoTributaria, 'icmsValorSubstituto'));
      this.set('mostrarValorSubstituto', this.mostrar(situacaoTributaria, 'icmsValorSubstituto'));
      this.set('mostrarValorDiferido', this.mostrar(situacaoTributaria, 'icmsValorDiferido'));
      this.set('obrigarValorDiferido', this.obrigar(situacaoTributaria, 'icmsValorDiferido'));
      this.set('mostrarValorOperacao', this.mostrar(situacaoTributaria, 'icmsValorOperacao'));
      this.set('obrigarValorOperacao', this.obrigar(situacaoTributaria, 'icmsValorOperacao'));
      this.set('mostrarPercentualDiferimento', this.mostrar(situacaoTributaria, 'icmsPercentualDiferimento'));
      this.set('obrigarPercentualDiferimento', this.obrigar(situacaoTributaria, 'icmsPercentualDiferimento'));

      if (situacaoTributaria === '10' && partilhaOrigemDestino) {
        this.set('mostrarPorcentualBaseCalculoOpPropria', true);
        this.set('mostrarPorcentualReducaoBaseCalculo', true);
        this.set('mostrarUfSt', true);
        this.set('obrigarPorcentualBaseCalculoOpPropria', false);
        this.set('obrigarPorcentualReducaoBaseCalculo', false);
        this.set('obrigarUfSt', true);
      }

      if (situacaoTributaria === '90' && partilhaOrigemDestino) {
        this.set('mostrarPorcentualBaseCalculoOpPropria', true);
        this.set('mostrarUfSt', true);
        this.set('obrigarAliquota', true);
        this.set('obrigarAliquotaSt', true);
        this.set('obrigarBaseCalculo', true);
        this.set('obrigarBaseCalculoSt', true);
        this.set('obrigarModalidadeBaseCalculo', true);
        this.set('obrigarModalidadeBaseCalculoSt', true);
        this.set('obrigarPorcentualBaseCalculoOpPropria', false);
        this.set('obrigarPorcentualMargemValorAdicionadoSt', true);
        this.set('obrigarPorcentualReducaoBaseCalculo', false);
        this.set('obrigarPorcentualReducaoBaseCalculoSt', true);
        this.set('obrigarUfSt', true);
        this.set('obrigarValor', true);
        this.set('obrigarValorSt', true);
      }

      if (situacaoTributaria === '41' && retencaoOrigemSt) {
        this.set('mostrarBaseCalculoDestinoSt', true);
        this.set('mostrarBaseCalculoRetidoSt', true);
        this.set('mostrarMotivoDesoneracao', false);
        this.set('mostrarSt', true);
        this.set('mostrarValor', false);
        this.set('mostrarValorDestinoSt', true);
        this.set('mostrarValorRetidoSt', true);
        this.set('obrigarBaseCalculoDestinoSt', true);
        this.set('obrigarBaseCalculoRetidoSt', true);
        this.set('obrigarMotivoDesoneracao', false);
        this.set('obrigarValor', false);
        this.set('obrigarValorDestinoSt', true);
        return this.set('obrigarValorRetidoSt', true);
      }
    })
  });

  _exports.default = _default;
});