define("api-dashboard/components/notas-fiscais/item-form", ["exports", "api-dashboard/utils/acesso-icms", "api-dashboard/utils/acesso-pis-cofins", "api-dashboard/utils/acesso-pis", "api-dashboard/utils/acesso-cofins", "api-dashboard/utils/acesso-ipi"], function (_exports, _acessoIcms, _acessoPisCofins, _acessoPis, _acessoCofins, _acessoIpi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    constants: Ember.inject.service(),
    acessoIcms: _acessoIcms.default.create(),
    acessoPis: _acessoPis.default.create(),
    acessoCofins: _acessoCofins.default.create(),
    acessoIpi: _acessoIpi.default.create(),
    acessoPisCofins: _acessoPisCofins.default.create(),
    firstTimeProductObserver: true,
    didReceiveAttrs: function didReceiveAttrs() {
      this.setInitialOptions();
      return this._super();
    },
    setInitialOptions: function setInitialOptions() {
      this.set('acessoIcms.item', this.get('model'));
      this.set('acessoIpi.item', this.get('model'));
      this.set('acessoPis.item', this.get('model'));
      this.set('acessoCofins.item', this.get('model'));
      window.nfitem = this.get('model');
      return window.itemctrl = this;
    },
    productObserver: Ember.observer('model.product', function () {
      var product;

      if (!this.get('model.isNew') && this.get('firstTimeProductObserver')) {
        this.set('firstTimeProductObserver', false);
        return;
      }

      product = this.get('model.product');
      return this.setarValor(product);
    }),
    setarValor: function setarValor(product) {
      var item, itemEntrada, price, taxablePrice;
      item = this.get('model');

      if (product != null) {
        price = new Big(product.get('price'));
        taxablePrice = new Big(product.get('taxablePrice'));

        if (price instanceof Big) {
          item.set('valorUnitarioComercial', price);
        }

        if (taxablePrice instanceof Big) {
          item.set('valorUnitarioTributavel', taxablePrice);
        }
      }

      if (this.get('model.notaFiscal.emitente.obterDadosEntrada') && this.get('model.notaFiscal.tipoDocumento') === 1) {
        itemEntrada = item.itemEntrada(product);

        if (itemEntrada != null) {
          item.set('detalhe', itemEntrada.get('detalhe'));
          return item.set('valorEntrada', new Big(itemEntrada.get('valorBruto')));
        } else {
          item.set('detalhe', "");
          return item.set('valorEntrada', null);
        }
      }
    },
    verificarObrigatoriedadeCamposIcms: Ember.observer('model.icmsSituacaoTributaria', 'model.icmsPartilhaOrigemDestino', 'model.icmsRetencaoOrigemSt', 'model.icmsModalidadeBaseCalculo', 'model.icmsBaseCalculo', 'model.icmsAliquota', 'model.icmsValor', 'model.icmsModalidadeBaseCalculoSt', 'model.icmsBaseCalculoSt', 'model.icmsAliquotaSt', 'model.icmsValorSt', 'model.icmsAliquotaCreditoSimples', 'model.icmsValorCreditoSimples', 'model.icmsValorDesonerado', 'model.icmsMotivoDesoneracao', function () {
      var partilha, retencao, st;
      st = this.get('model.icmsSituacaoTributaria');
      partilha = this.get('model.icmsPartilhaOrigemDestino');
      retencao = this.get('model.icmsRetencaoOrigemSt');

      if (st === '90' && !partilha || st === '900') {
        if (this.get('model.icmsModalidadeBaseCalculo') != null || this.get('model.icmsBaseCalculo') != null || this.get('model.icmsAliquota') != null || this.get('model.icmsValor') != null) {
          this.set('acessoIcms.obrigarModalidadeBaseCalculo', true);
          this.set('acessoIcms.obrigarBaseCalculo', true);
          this.set('acessoIcms.obrigarAliquota', true);
          this.set('acessoIcms.obrigarValor', true);
        } else {
          this.set('acessoIcms.obrigarModalidadeBaseCalculo', false);
          this.set('acessoIcms.obrigarBaseCalculo', false);
          this.set('acessoIcms.obrigarAliquota', false);
          this.set('acessoIcms.obrigarValor', false);
        }

        if (this.get('model.icmsModalidadeBaseCalculoSt') != null || this.get('model.icmsBaseCalculoSt') != null || this.get('model.icmsAliquotaSt') != null || this.get('model.icmsValorSt') != null) {
          this.set('acessoIcms.obrigarModalidadeBaseCalculoSt', true);
          this.set('acessoIcms.obrigarBaseCalculoSt', true);
          this.set('acessoIcms.obrigarAliquotaSt', true);
          this.set('acessoIcms.obrigarValorSt', true);
        } else {
          this.set('acessoIcms.obrigarModalidadeBaseCalculoSt', false);
          this.set('acessoIcms.obrigarBaseCalculoSt', false);
          this.set('acessoIcms.obrigarAliquotaSt', false);
          this.set('acessoIcms.obrigarValorSt', false);
        }

        if (st === '900') {
          if (this.get('model.icmsAliquotaCreditoSimples') != null || this.get('model.icmsValorCreditoSimples') != null) {
            this.set('acessoIcms.obrigarAliquotaCreditoSimples', true);
            return this.set('acessoIcms.obrigarValorCreditoSimples', true);
          } else {
            this.set('acessoIcms.obrigarAliquotaCreditoSimples', false);
            return this.set('acessoIcms.obrigarValorCreditoSimples', false);
          }
        }
      } else if (st === '40' || st === '50' || st === '41' && !retencao) {
        if (this.get('model.icmsValorDesonerado') != null || this.get('model.icmsMotivoDesoneracao') != null) {
          this.set('acessoIcms.obrigarValorDesonerado', true);
          return this.set('acessoIcms.obrigarMotivoDesoneracao', true);
        } else {
          this.set('acessoIcms.obrigarValorDesonerado', false);
          return this.set('acessoIcms.obrigarMotivoDesoneracao', false);
        }
      }
    }),
    verificarObrigatoriedadeCamposIpiTributado: Ember.observer('model.ipiBaseCalculo', 'model.ipiAliquota', 'model.ipiQuantidadeTotal', 'model.ipiValorPorUnidadeTributavel', function () {
      var a, bc, qt, vput;
      bc = this.get('model.ipiBaseCalculo');
      a = this.get('model.ipiAliquota');

      if (bc != null || a != null) {
        this.set('acessoIpi.obrigarBaseCalculo', true);
        this.set('acessoIpi.obrigarAliquota', true);
      } else {
        this.set('acessoIpi.obrigarBaseCalculo', false);
        this.set('acessoIpi.obrigarAliquota', false);
      }

      qt = this.get('model.ipiQuantidadeTotal');
      vput = this.get('model.ipiValorPorUnidadeTributavel');

      if (qt != null || vput != null) {
        this.set('acessoIpi.obrigarQuantidadeTotal', true);
        return this.set('acessoIpi.obrigarValorPorUnidadeTributavel', true);
      } else {
        this.set('acessoIpi.obrigarQuantidadeTotal', false);
        return this.set('acessoIpi.obrigarValorPorUnidadeTributavel', false);
      }
    }),
    verificarObrigatoriedadeCamposPisOutros: Ember.observer('model.pisSituacaoTributaria', 'model.pisBaseCalculo', 'model.pisAliquotaPorcentual', 'model.pisQuantidadeVendida', 'model.pisAliquotaValorDecimalInput', function () {
      var ap, av, bc, qv;

      if (this.get('acessoPis.situacoesTributariasOutros').indexOf(this.get('model.pisSituacaoTributaria')) !== -1) {
        bc = this.get('model.pisBaseCalculo');
        ap = this.get('model.pisAliquotaPorcentual');

        if (bc != null || ap != null) {
          this.set('acessoPis.obrigarBaseCalculo', true);
          this.set('acessoPis.obrigarAliquotaPorcentual', true);
        } else {
          this.set('acessoPis.obrigarBaseCalculo', false);
          this.set('acessoPis.obrigarAliquotaPorcentual', false);
        }

        qv = this.get('model.pisQuantidadeVendida');
        av = this.get('model.pisAliquotaValorDecimalInput');

        if (qv != null || av != null) {
          this.set('acessoPis.obrigarQuantidadeVendida', true);
          return this.set('acessoPis.obrigarAliquotaValor', true);
        } else {
          this.set('acessoPis.obrigarQuantidadeVendida', false);
          return this.set('acessoPis.obrigarAliquotaValor', false);
        }
      }
    }),
    verificarObrigatoriedadeCamposCofinsOutros: Ember.observer('model.cofinsSituacaoTributaria', 'model.cofinsBaseCalculo', 'model.cofinsAliquotaPorcentual', 'model.cofinsQuantidadeVendida', 'model.cofinsAliquotaValor', function () {
      var ap, av, bc, qv;

      if (this.get('acessoCofins.situacoesTributariasOutros').indexOf(this.get('model.cofinsSituacaoTributaria')) !== -1) {
        bc = this.get('model.cofinsBaseCalculo');
        ap = this.get('model.cofinsAliquotaPorcentual');

        if (bc != null || ap != null) {
          this.set('acessoCofins.obrigarBaseCalculo', true);
          this.set('acessoCofins.obrigarAliquotaPorcentual', true);
        } else {
          this.set('acessoCofins.obrigarBaseCalculo', false);
          this.set('acessoCofins.obrigarAliquotaPorcentual', false);
        }

        qv = this.get('model.cofinsQuantidadeVendida');
        av = this.get('model.cofinsAliquotaValor');

        if (qv != null || av != null) {
          this.set('acessoCofins.obrigarQuantidadeVendida', true);
          return this.set('acessoCofins.obrigarAliquotaValor', true);
        } else {
          this.set('acessoCofins.obrigarQuantidadeVendida', false);
          return this.set('acessoCofins.obrigarAliquotaValor', false);
        }
      }
    }),
    campoIiObrigatorio: Ember.computed('model.iiBaseCalculo', 'model.iiValorDespesasAduaneiras', 'model.iiValor', 'model.iiValorIof', function () {
      var bc, v, vda, viof;
      bc = this.get('model.iiBaseCalculo');
      vda = this.get('model.iiValorDespesasAduaneiras');
      v = this.get('model.iiValor');
      viof = this.get('model.iiValorIof');
      return bc != null || vda != null || v != null || viof != null;
    }),
    campoIssqnObrigatorio: Ember.computed('model.issqnIndicadorExigibilidade', 'model.issqnBaseCalculo', 'model.issqnAliquota', 'model.issqnValor', 'model.issqnMunicipio', 'model.issqnItemListaServico', function () {
      var a, bc, cls, m, st, v;
      st = this.get('model.issqnIndicadorExigibilidade');
      bc = this.get('model.issqnBaseCalculo');
      a = this.get('model.issqnAliquota');
      v = this.get('model.issqnValor');
      m = this.get('model.issqnMunicipio');
      cls = this.get('model.issqnItemListaServico');
      return st != null && st !== '' || bc != null || a != null || v != null || m != null || cls != null && cls !== '';
    }),
    cfopMessage: Ember.computed('model.cfop', 'model.notaFiscal', function () {
      var cfop, cfopMessage;
      cfop = this.get('model.cfop');

      if (cfop == null) {
        return false;
      }

      cfopMessage = cfop.validationMessage(this.get('model.notaFiscal'));

      if (cfopMessage == null || cfopMessage === '') {
        return false;
      }

      return cfopMessage;
    }),
    valorImpostoObserver: Ember.observer('model.icmsBaseCalculo', 'model.icmsAliquota', function () {
      var aliquota, baseCalculo, valorIcms;

      if (this.get('model.icmsAliquota')) {
        baseCalculo = new Big(this.get('model.icmsBaseCalculo'));
        aliquota = new Big(this.get('model.icmsAliquota') / 100);
        valorIcms = baseCalculo.times(aliquota).round(2);
        return this.set('model.icmsValor', parseFloat(valorIcms));
      }
    }),
    finish: function finish(item) {
      return this.get('router').transitionTo('notas-fiscais/edit', item.get('notaFiscal'));
    },
    actions: {
      save: function save() {
        var self;
        self = this;

        if (!this.get('model').get('isValid')) {
          this.get('model').send('becameValid');
        }

        return this.get('model').save().then(function (obj) {
          return self.finish(obj);
        }, function (reason) {
          return console.log(reason);
        });
      },
      cancel: function cancel() {
        if (confirm("Sair sem salvar alterações?")) {
          return this.finish(this.get('model'));
        }
      }
    }
  });

  _exports.default = _default;
});