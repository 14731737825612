define("api-dashboard/models/check", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    accountNum: _emberData.default.attr('string'),
    bankNum: _emberData.default.attr('string'),
    branchNum: _emberData.default.attr('string'),
    checkNum: _emberData.default.attr('string'),
    cnpjCpf: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    date: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    value: _emberData.default.attr('number'),
    posEntry: _emberData.default.belongsTo('pos-entry'),
    statusDescription: Ember.computed('status', function () {
      var elm, status;
      status = this.get('status');
      elm = this.get('constants.checkStatusValues').find(function (i) {
        return i.value === status;
      });

      if (elm != null) {
        return elm.description;
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});