define("api-dashboard/models/domain", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    branchActivity: _emberData.default.attr('string'),
    configsJson: _emberData.default.attr('json'),
    enabledModules: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    statusFunil: _emberData.default.attr('string'),
    trialEndsAt: _emberData.default.attr('date'),
    enableEcommerce: _emberData.default.attr('boolean'),
    usaCamposTaxasImportacao: _emberData.default.attr('boolean', {
      serialize: false
    }),
    fiscalEnabled: _emberData.default.attr('boolean', {
      serialize: false
    }),
    nfceEnabled: _emberData.default.attr('boolean', {
      serialize: false
    }),
    totalCustomers: _emberData.default.attr('number'),
    totalPosUsers: _emberData.default.attr('number'),
    allStores: _emberData.default.hasMany('f-store'),
    collections: _emberData.default.hasMany('collection'),
    departments: _emberData.default.hasMany('department'),
    dimensions: _emberData.default.hasMany('dimension'),
    emitentes: _emberData.default.hasMany('emitente'),
    labelModels: _emberData.default.hasMany('label-model'),
    parametros: _emberData.default.hasMany('parametro'),
    pos: _emberData.default.hasMany('pos'),
    prestadores: _emberData.default.hasMany('prestador'),
    productTypes: _emberData.default.hasMany('product-type'),
    specialConfigs: _emberData.default.attr('string'),
    suppliers: _emberData.default.hasMany('supplier'),
    visibleStores: _emberData.default.hasMany('f-store'),
    financialAlerts: _emberData.default.attr('array'),
    isNfe: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 1) !== 0;
    }),
    isOnlyNfe: Ember.computed('enabledModules', function () {
      return this.get('enabledModules') === 1;
    }),
    isWebNfe: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 1) !== 0;
    }),
    isLojas: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 2) !== 0;
    }),
    isErp: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 4) !== 0;
    }),
    isWebNfse: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 8) !== 0;
    }),
    isWebNfce: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 16) !== 0;
    }),
    isOnlyWebNfce: Ember.computed('enabledModules', function () {
      return this.get('enabledModules') === 16;
    }),
    isPainelApiNfe: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 32) !== 0;
    }),
    isOnlyCashier: Ember.computed('enabledModules', function () {
      return (this.get('enabledModules') & 64) !== 0;
    }),
    isNfeOrNfce: Ember.computed('isNfe', 'isWebNfce', function () {
      return this.get('isNfe') || this.get('isWebNfce') || this.get('application.currentDomain.nfceEnabled');
    }),
    isNfeOrWebNfce: Ember.computed('isNfe', 'isWebNfce', function () {
      return this.get('isNfe') || this.get('isWebNfce');
    }),
    isAcras: Ember.computed('id', function () {
      var id;
      id = this.get('id');
      return id === "123";
    }),
    isBranchActivityClothing: Ember.computed('branchActivity', function () {
      return this.get('branchActivity') === 'clothing';
    }),
    shouldRunTutorial: function shouldRunTutorial() {
      return this.get('tutorial') && !this.get('tutorial').isFinished();
    },
    multipleStores: Ember.computed('visibleStores', function () {
      return !!this.get('visibleStores') && this.get('visibleStores.length') > 1;
    }).property(),
    multipleEmitentes: Ember.computed('emitentes', function () {
      return !!this.get('emitentes') && this.get('emitentes.length') > 1;
    }),
    multiplePrestadores: Ember.computed('prestadores', function () {
      return !!this.get('prestadores') && this.get('prestadores.length') > 1;
    }),
    multiplePosUsers: Ember.computed('totalPosUsers', function () {
      return this.get('totalPosUsers') > 1;
    }),
    hasDimensions: Ember.computed('dimensions', function () {
      return !!this.get('dimensions') && this.get('dimensions.length') > 0;
    }),
    hasWebPos: Ember.computed('pos.@any.enableWebNfce', 'pos.@any.enableWebSale', function () {
      var webPoss;
      webPoss = this.get('pos').filter(function (p) {
        return p.get('enableWebSale') || p.get('enableWebNfce');
      });
      return webPoss.length > 0;
    })
  }, _defineProperty(_DS$Model$extend, "hasDimensions", Ember.computed('dimensions', function () {
    return this.get('dimensions') && this.get('dimensions.length') > 0;
  })), _defineProperty(_DS$Model$extend, "isTrial", Ember.computed('statusFunil', function () {
    return this.get('statusFunil') === 'TRIAL';
  })), _defineProperty(_DS$Model$extend, "trialEndsAtFormatted", Ember.computed('trialEndsAt', function () {
    var d;
    d = this.get('trialEndsAt');
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
  })), _defineProperty(_DS$Model$extend, "hasSpecialConfigNfeHtml", Ember.computed('specialConfigs', function () {
    return (this.get('specialConfigs') || "").toString().indexOf('nfeHtml') !== -1;
  })), _defineProperty(_DS$Model$extend, "hasSpecialConfigWaybillHtml", Ember.computed('specialConfigs', function () {
    return (this.get('specialConfigs') || "").toString().indexOf('waybillHtml') !== -1;
  })), _DS$Model$extend));

  _exports.default = _default;
});