define("api-dashboard/templates/components/notas-fiscais/nota-fiscal-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HGrser6+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Emissão de Nota Fiscal Eletrônica\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"cadastro-notas-fiscais\"],[10,\"class\",\"focus-general-container\"],[8],[0,\"\\n  \"],[15,\"components/notas-fiscais/-nota-fiscal-form\",[]],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/nota-fiscal-edit.hbs"
    }
  });

  _exports.default = _default;
});