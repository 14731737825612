define("api-dashboard/serializers/lancamento", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      accountEntry: {
        serialize: 'ids',
        deserialize: 'ids'
      },
      parcelasLancamento: {
        serialize: 'records',
        deserialize: 'records'
      },
      rateiosLancamento: {
        serialize: 'records',
        deserialize: 'records'
      },
      rps: {
        serialize: false,
        deserialize: 'records'
      },
      person: {
        serialize: 'id',
        deserialize: 'records'
      }
    },
    attr_map_serialize_belongsTo: {
      'costCenter': 'centro_custo'
    },
    serialize: function serialize(snapshot, options) {
      var json;
      json = this._super(snapshot, options);

      if (snapshot.record.get('isNew')) {
        json['baixado'] = snapshot.record.get('baixado');
      }

      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      json.attributes = json.attributes || {};
      return this._super(snapshot, json.attributes, key, attributes);
    },
    keyForRelationship: function keyForRelationship(key, relationshipType, method) {
      if (relationshipType === "belongsTo" && method === "serialize" && Ember.isPresent(this.get('attr_map_serialize_belongsTo')[key])) {
        key = this.get('attr_map_serialize_belongsTo')[key];
      }

      return this._super(key, relationshipType, method);
    }
  });

  _exports.default = _default;
});