define("api-dashboard/components/inputs/input-toggle-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actionOnToggle: null,
    items: [],
    displayField: 'description',
    valueField: 'value',
    _display: null,
    _value: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var i, j, o, ref;

      if (this.get('options') && this.get('options.length') > 0) {
        for (i = j = 0, ref = this.get('options.length'); 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
          o = this.get('options').objectAt(i);
          Ember.set(o, '_display', o[this.get('displayField')]);
        }
      }
    }
  });

  _exports.default = _default;
});