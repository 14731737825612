define("api-dashboard/templates/components/base/show-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UO8pKsKX",
    "block": "{\"symbols\":[\"err\"],\"statements\":[[4,\"unless\",[[24,[\"model\",\"isValid\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"errors\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[7,\"b\",true],[8],[1,[23,1,[\"attribute\"]],false],[0,\":\"],[9],[0,\" \"],[1,[23,1,[\"message\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/show-errors.hbs"
    }
  });

  _exports.default = _default;
});