define("api-dashboard/services/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    collapsed: true,
    resourceName: 'customers',
    stats: null,
    getStats: function getStats() {
      var self;
      self = this;
      return self.set('stats', null);
    },
    collapseDashboardCards: function collapseDashboardCards() {
      return this.set('collapsed', true);
    },
    expandDashboardCards: function expandDashboardCards() {
      return this.set('collapsed', false);
    }
  });

  _exports.default = _default;
});