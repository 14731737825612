define("api-dashboard/mixins/components/transicao-div", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ComponentsTransicaoDivMixin;
  ComponentsTransicaoDivMixin = Ember.Mixin.create({
    utilizarAnimacaoNaTransicao: true,
    arrayEtapasDiv: null,
    divAtual: null,
    divLoading: null,
    divAnterior: Ember.computed("arrayEtapasDiv", "divAtual", function () {
      var arrayEtapas, divAtual, indexEtapaAtual;
      arrayEtapas = this.get("arrayEtapasDiv");

      if (!arrayEtapas || arrayEtapas.length < 2) {
        return null;
      }

      divAtual = this.get("divAtual");

      if (!divAtual) {
        return null;
      }

      indexEtapaAtual = arrayEtapas.indexOf(divAtual);

      if (indexEtapaAtual === 0) {
        return null;
      }

      return arrayEtapas[indexEtapaAtual - 1];
    }),
    proximaDiv: Ember.computed("arrayEtapasDiv", "divAtual", function () {
      var arrayEtapas, divAtual, indexEtapaAtual;
      arrayEtapas = this.get("arrayEtapasDiv");

      if (!arrayEtapas || arrayEtapas.length === 0) {
        return null;
      }

      divAtual = this.get("divAtual");

      if (!divAtual) {
        return this.get(arrayEtapas[0]);
      }

      indexEtapaAtual = arrayEtapas.indexOf(divAtual);

      if (indexEtapaAtual === arrayEtapas.length - 1) {
        return null;
      }

      return arrayEtapas[indexEtapaAtual + 1];
    }),
    mapearDivsAtributos: function mapearDivsAtributos() {},
    inicializarEstadoDivs: function inicializarEstadoDivs() {},
    determinarArrayEtapasDiv: function determinarArrayEtapasDiv() {},
    alternarJanela: function alternarJanela(params, callbackOnAnimationComplete) {
      var divAtual, self, utilizarAnimacaoNaTransicao;

      if (callbackOnAnimationComplete == null) {
        callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
      }

      self = this;
      divAtual = this.get("divAtual");

      if (divAtual === params["proximaDiv"]) {
        if (!divAtual.is(":visible")) {
          divAtual.show();
        }

        return callbackOnAnimationComplete();
      }

      utilizarAnimacaoNaTransicao = this.get("utilizarAnimacaoNaTransicao");

      if (utilizarAnimacaoNaTransicao) {
        if (divAtual) {
          return divAtual.fadeOut("fast", function () {
            return params["proximaDiv"].fadeIn("fast", function () {
              var e;

              try {
                self.set("divAtual", params["proximaDiv"]);
              } catch (error) {
                e = error;
              }

              return callbackOnAnimationComplete();
            });
          });
        } else {
          return params["proximaDiv"].fadeIn("fast", function () {
            var e;

            try {
              self.set("divAtual", params["proximaDiv"]);
            } catch (error) {
              e = error;
            }

            return callbackOnAnimationComplete();
          });
        }
      } else {
        if (divAtual) {
          divAtual.hide();
        }

        params["proximaDiv"].show();
        this.set("divAtual", params["proximaDiv"]);
        return callbackOnAnimationComplete();
      }
    },
    alternarParaProximaDiv: function alternarParaProximaDiv(params, callbackOnAnimationComplete) {
      var proximaDiv;

      if (callbackOnAnimationComplete == null) {
        callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
      }

      proximaDiv = this.get("proximaDiv");
      params["proximaDiv"] = proximaDiv;

      if (proximaDiv) {
        return this.alternarJanela(params, callbackOnAnimationComplete);
      }
    },
    alternarParaDivAnterior: function alternarParaDivAnterior(params, callbackOnAnimationComplete) {
      var divAnterior;

      if (callbackOnAnimationComplete == null) {
        callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
      }

      divAnterior = this.get("divAnterior");
      params["proximaDiv"] = divAnterior;

      if (divAnterior) {
        return this.alternarJanela(params, callbackOnAnimationComplete);
      }
    },
    mostrarLoading: function mostrarLoading(params, callbackOnAnimationComplete) {
      if (callbackOnAnimationComplete == null) {
        callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
      }

      return this.alternarJanela({
        proximaDiv: this.get("divLoading")
      }, callbackOnAnimationComplete);
    }
  });
  var _default = ComponentsTransicaoDivMixin;
  _exports.default = _default;
});