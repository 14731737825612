define("api-dashboard/adapters/saas-contract", ["exports", "ember-data", "api-dashboard/adapters/application-panel", "api-dashboard/config/environment", "ember-inflector"], function (_exports, _emberData, _applicationPanel, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationPanel.default.extend({
    namespace: 'saas',
    pathForType: function pathForType(type) {
      if (type === 'saas-contract') {
        type = 'contract';
      }

      return Ember.String.underscore((0, _emberInflector.pluralize)(type.replace(/-/g, ' ')));
    }
  });

  _exports.default = _default;
});