define("api-dashboard/templates/components/base/notification-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Syo8xYwk",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[0,\"  \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"title\",\"Notificações\"],[10,\"data-toggle\",\"dropdown\"],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"typeClass\"]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showMessageCountBadge\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"painelFocus__menu--badge \",[22,\"counterClass\"]]]],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[22,\"messageCount\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"badge--title hidden-xs\"],[8],[7,\"span\",true],[8],[1,[22,\"title\"],false],[9],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"divider hidden-xs\"],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fas fa-caret-right\"],[8],[9],[0,\"\\n             \"],[1,[23,1,[]],true],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"badge--title hidden-xs\"],[8],[7,\"span\",true],[8],[1,[22,\"title\"],false],[9],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"divider hidden-xs\"],[8],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Tudo certo! Não há nada para mostrar.\"],[9],[9],[0,\"\\n      \"],[7,\"li\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/notification-badge.hbs"
    }
  });

  _exports.default = _default;
});