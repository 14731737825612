define("api-dashboard/templates/components/base/abstract-input-group-form-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jc3bJQ5n",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"nomeComponenteInput\"]]],[[\"id\",\"vazioValido\",\"placeholder\",\"value\",\"maxlength\",\"valueAsDateValue\",\"actionOnFimValidacao\",\"actionOnIniciarValidacaoAssincrona\",\"actionOnFinalizarValidacaoAssincrona\",\"actionOnRealizarValidacaoAssincrona\"],[[24,[\"idInput\"]],[24,[\"vazioValido\"]],[24,[\"placeholder\"]],[24,[\"valueInput\"]],[24,[\"maxlength\"]],[24,[\"valueAsDateValue\"]],\"actFimValidacao\",\"actInicioValidacaoAssincrona\",\"actFimValidacaoAssincrona\",\"actRealizarValidacaoAssincrona\"]]],false],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[22,\"icone\"]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"teste\"]]],null,{\"statements\":[[0,\"\\n\"],[2,\" Exemplo de implementação para os contextos externos:\\n\\n{{bs/inputs/input-container-generico\\n  valueInput=model.cnpj\\n  nomeComponenteInput=\\\"inputs/input-cnpj\\\"\\n  actionOnRealizarValidacaoAssincrona=\\\"actValidacaoCNPJComAjax\\\"\\n  actionOnFimValidacao=\\\"actFimValidacaoCNPJ\\\"}}\\n\\n\"],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/abstract-input-group-form-container.hbs"
    }
  });

  _exports.default = _default;
});