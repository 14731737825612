define("api-dashboard/models/saas-contract", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string', {
      defaultValue: 'A'
    }),
    activeFrom: _emberData.default.attr('date'),
    activeTo: _emberData.default.attr('date'),
    cancelAvoidable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    cancelMotive: _emberData.default.attr('string'),
    contractType: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    nfceEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    apiEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    customer: _emberData.default.belongsTo('customer'),
    emitentes: _emberData.default.hasMany('emitente'),
    stores: _emberData.default.hasMany('f_store'),
    fStore: Ember.computed('stores.length', {
      get: function get() {
        return this.get('stores').objectAt(0);
      },
      set: function set(key, value) {
        this.set('storeId', value.get('id'));
        return value;
      }
    }),
    emitentesText: Ember.computed('emitentes.length', function () {
      var text;
      text = [];
      this.get('emitentes').forEach(function (emitente) {
        var str;
        str = emitente.get('nome') + " [" + emitente.get('cnpj') + "]";
        return text.push(str);
      });
      return text.join('<br/>');
    }),
    isActive: Ember.computed('status', function () {
      var status;
      status = this.get('status');

      if (status !== 'A' && status !== 'S') {
        return false;
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});