define("api-dashboard/services/application", ["exports", "api-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    toaster: Ember.inject.service('toast'),
    currentDomain: null,
    currentUser: null,
    alertMessages: Ember.A(),
    notificationMessages: Ember.A(),
    domains: Ember.computed('session.data.authenticated.domains', function () {
      return this.get('session.data.authenticated.domains');
    }),
    isAcras: Ember.computed('currentDomain', function () {
      return this.get('currentDomain.isAcras');
    }),
    isSatEnabled: Ember.computed(function () {
      return false;
    }),
    isNfceEnabled: Ember.computed(function () {
      return false;
    }),
    isBoletoEnabled: function isBoletoEnabled() {
      return false;
    },
    loadingLoginInfo: Ember.computed('selecionandoUser', 'selecionandoDomain', function () {
      return this.get('selecionandoUser') || this.get('selecionandoDomain');
    }),
    configuracao: Ember.computed('currentDomain', function () {
      return this.get('currentDomain.configuracao');
    }),
    version: Ember.computed('', function () {
      return _environment.default.APP.version.split('+')[0];
    }),
    showAlert: function showAlert(message, type, timeOut) {
      var options;

      if (timeOut == null) {
        timeOut = 4000;
      }

      options = {
        timeOut: timeOut
      };

      switch (type) {
        case 'succcess':
          return this.get('toaster').success(message, '', options);

        case 'info':
          return this.get('toaster').info(message, '', options);

        case 'warning':
          return this.get('toaster').warning(message, '', options);

        case 'error':
          return this.get('toaster').error(message, '', options);

        default:
          return this.get('toaster').success(message, '', options);
      }
    },
    reloadDomain: function reloadDomain() {
      return this.get('store').findRecord('domain', this.get('currentDomain.id'), {
        reload: true
      });
    },
    setCurrentDomainId: function setCurrentDomainId(domainId, callback) {
      var self;
      self = this;

      if (domainId) {
        this.set('selecionandoDomain', true);
        this.get('session').set('data.currentDomainId', domainId);
        return this.get('store').findRecord('domain', domainId, {
          reload: true
        }).then(function (d) {
          self.set('currentDomain', d);
          self.set('selecionandoDomain', false);

          if (callback) {
            return callback(d);
          }
        });
      } else {
        self.set('currentDomain', null);
        return this.get('session').set('data.currentDomainId', null);
      }
    },
    setCurrentUserId: function setCurrentUserId(userId, callback) {
      var self;
      self = this;

      if (userId) {
        this.set('selecionandoUser', true);
        return this.get('store').findRecord('user', userId, {
          reload: true
        }).then(function (u) {
          var locale;
          self.set('currentUser', u);
          self.set('intl.locale', 'pt-br');

          if (u.get('locale')) {
            locale = Ember.String.dasherize(u.get('locale').toLowerCase());

            if (self.get('intl.locales').includes(locale)) {
              self.set('intl.locale', locale);
            }
          }

          self.set('selecionandoUser', false);

          if (callback) {
            return callback(u);
          }
        });
      } else {
        return self.set('currentUser', null);
      }
    },
    transitionToUserHome: function transitionToUserHome() {
      return this.transitionTo("index");
    },
    _addMessage: function _addMessage(type, msg) {
      var messages;
      messages = this.get(type + "Messages");
      return messages.unshiftObject(msg);
    },
    addAlertMessage: function addAlertMessage(msg) {
      return this._addMessage('alert', msg);
    },
    addNotificationMessage: function addNotificationMessage(msg) {
      return this._addMessage('notification', msg);
    },
    deepMerge: function deepMerge(original, updates) {
      var i, len, prop, ref, temp;
      temp = Ember.copy(original, true);

      if (_typeof(temp) !== 'object') {
        if (updates) {
          return updates;
        } else {
          return temp;
        }
      }

      ref = Object.keys(updates);

      for (i = 0, len = ref.length; i < len; i++) {
        prop = ref[i];
        temp[prop] = this.deepMerge(temp[prop], updates[prop]);
      }

      return temp;
    }
  });

  _exports.default = _default;
});