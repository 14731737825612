define("api-dashboard/components/base/abstract-form", ["exports", "api-dashboard/mixins/keyboard-utils"], function (_exports, _keyboardUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_keyboardUtils.default, {
    /*
    Passos para implementar.
    1-Criar um componente novo e herdar este componente.
    2-No template, colocar os elementos do formulário (inputs, labels, etc).
    3-Criar um botão submit: botão normal HTML com type='submit'
    4-Inserir o componente para exibição de mensagens de alerta. ***Manter o nome
      das variáveis.
      Ex:
        <div class="row">
        {{base/form-messages
          generalMessage=alertMessage
          messages=alertMessages
          spinner=alertSpinner
          type=alertMessageType}}
        </div>
    5-Implementar no utilizador do componente (rota, controlador, componente externo)
    #a action onSubmit.
    
    Opcionais:
    
    ***Validações no client-side***
    1-Sobreescrever o método validateClient.
    
    ***Botão 'voltar'
    1-Criar um novo botão HTML no componente e vincular a action "back".
    2-No utilizar do componente implementar a action "onBack".
    
    ***Botão 'cancelar'
    1-Criar um novo botão HTML no componente e vinvular a action "cancel".
    2-OPCIONAL: sobreescrever o método 'beforeCancel'.
    3-No utilizar do componente implementar a action "onCancel".
    
    Outros métodos úteis que podem ser sobreescritos:
    
    *** getFriendlyAttrName ***
    Método para fazer o 'de-para' do nome técnico do componente para um nome
    amigável para o usuário. Relevante apenas quando há erros de campos do model .
    
    *** buildModelErrors ***
    Monta o array de erros com os erros de campo do model.
    
    *** buildErrors ***
    Método principal da montagem das mensagens de erros. Monta tanto a mensagem
    principal quanto a lista de erros.
     */
    model: null,
    genericErrorMessage: "Ocorreu um erro ao salvar o registro.",
    loadingMessage: "Salvando o registro.",
    successMessage: "Registro salvo com sucesso.",
    showAlertSuccessMessage: true,
    alertMessage: null,
    alertMessages: [],
    alertSpinner: false,
    alertMessageType: null,
    tagName: "form",
    isSubmitting: false,
    attributeBindings: ['novalidate'],
    novalidate: 'novalidate',
    keyDown: function keyDown(e) {
      return this.keyDown_EnterAsTab(e, 'div.painelFocus__container');
    },
    submit: function submit(e) {
      var clientValidation, errorInformation, model, self, success;
      self = this;

      if (this.get("isSubmitting")) {
        return;
      }

      model = this.get("model");
      this.beforeValidate();
      clientValidation = this.validateClient();
      success = clientValidation[0];
      errorInformation = clientValidation[1];
      this.afterValidateClient(success, errorInformation);

      if (success) {
        if (model) {
          return self.handleModelSubmit(model);
        } else {
          return self.internalSubmit(errorInformation);
        }
      } else {
        return self.internalShowErrors(errorInformation);
      }
    },
    internalShowErrors: function internalShowErrors(options) {
      var errors;
      errors = this.buildErrors(options);
      return this.showErrors(errors, function () {});
    },
    internalSubmit: function internalSubmit(options) {
      this.showSuccessMessage();
      return this.onSubmit(options);
    },
    handleModelSubmit: function handleModelSubmit(model) {
      var self;
      self = this;
      return this.showLoadIndicator(function (onLoadComplete) {
        return model.save().then(function (solvedModel) {
          onLoadComplete();
          return self.internalSubmit(solvedModel);
        }, function (errors) {
          onLoadComplete();
          return self.internalShowErrors({
            modelError: true,
            errors: errors
          });
        });
      });
    },
    beforeValidate: function beforeValidate() {
      this.blockSubmit();
      this.set("alertMessage", null);
      return this.set("alertMessages", []);
    },
    validateClient: function validateClient() {
      return [true, {}];
    },
    afterValidateClient: function afterValidateClient(success, options) {
      if (options == null) {
        options = {};
      }

      if (!this.get("model") || !success) {
        return this.blockSubmit(false);
      }
    },
    beforeCancel: function beforeCancel(onComplete) {
      if (onComplete == null) {
        onComplete = function onComplete() {};
      }

      return onComplete();
    },
    buildErrors: function buildErrors(options) {
      var errorMessage, errorMessages, errorMesssages;

      if (options["errorMessage"]) {
        errorMessage = options["errorMessage"];
      } else {
        errorMessage = this.get("genericErrorMessage");
      }

      if (options["modelError"]) {
        errorMessages = this.buildModelErrors();

        if (options["errorMessages"]) {
          errorMesssages = errorMessages.concat(options["errorMessages"]);
        }
      } else {
        errorMessages = options["errorMessages"];
      }

      return {
        "errorMessage": errorMessage,
        "errorMessages": errorMessages
      };
    },
    buildModelErrors: function buildModelErrors() {
      var errorMessages, modelErrors, self;
      self = this;
      errorMessages = [];
      modelErrors = this.get("model.errors.content");
      modelErrors.forEach(function (r) {
        return errorMessages.push("Campo " + self.getFriendlyAttrName(r["attribute"]) + ": " + r["message"]);
      });
      return errorMessages;
    },
    blockSubmit: function blockSubmit(block) {
      if (block == null) {
        block = true;
      }

      this.set("isSubmitting", block);
      return this.$(":submit").attr("disabled", block);
    },
    getFriendlyAttrName: function getFriendlyAttrName(attrName) {
      return attrName;
    },
    showErrors: function showErrors(errors, onComplete) {
      if (errors == null) {
        errors = {};
      }

      if (onComplete == null) {
        onComplete = function onComplete() {};
      }

      if (this.get('model')) {
        this.setAlertData(errors["errorMessage"], errors["errorMessages"], "E");
        return onComplete();
      }
    },
    showLoadIndicator: function showLoadIndicator(onShown) {
      var self;

      if (onShown == null) {
        onShown = function onShown() {};
      }

      self = this;
      this.blockSubmit();
      this.setAlertData(this.get("loadingMessage"), [], "W", true);
      return onShown(function () {
        return self.blockSubmit(false);
      });
    },
    showSuccessMessage: function showSuccessMessage() {
      if (this.get('showAlertSuccessMessage')) {
        if (this.get('model')) {
          return this.setAlertData(this.get("successMessage"), [], "S");
        }
      } else {
        return this.setAlertData(null, [], "S");
      }
    },
    setAlertData: function setAlertData(genericMessage, messages, messageType, spinner) {
      if (spinner == null) {
        spinner = false;
      }

      this.set("alertMessage", genericMessage);
      this.set("alertMessages", messages);
      this.set("alertMessageType", messageType);
      return this.set("alertSpinner", spinner);
    },
    actions: {
      submit: function submit() {
        return this.$().trigger("submit");
      },
      back: function back() {
        return this.sendAction("onBack");
      },
      cancel: function cancel() {
        var self;
        self = this;
        return this.beforeCancel(function () {
          return self.sendAction("onCancel");
        });
      }
    }
  });

  _exports.default = _default;
});