define("api-dashboard/components/base/abstract-input-form-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    valueInput: null,
    nomeComponenteInput: null,
    actionOnRealizarValidacaoAssincrona: null,
    actionOnFimValidacao: null,
    classNameBindings: ['possuiErros:has-error'],
    possuiErros: false,
    indicarErro: function indicarErro(erro) {
      return this.set("possuiErros", erro);
    },
    mostrarSpinner: function mostrarSpinner(mostrar) {
      return this.$("*").attr("disabled", mostrar);
    },
    tratarFimValidacao: function tratarFimValidacao(params) {
      var callback;
      this.indicarErro(!params["valido"]);
      return this.sendAction("actionOnFimValidacao", params, callback = function callback(params) {});
    },
    realizarValidacaoAssincrona: function realizarValidacaoAssincrona(params, callbackOnValidacaoCompleta) {
      if (params == null) {
        params = {};
      }

      if (!this.get("actionOnRealizarValidacaoAssincrona")) {
        return callbackOnValidacaoCompleta({
          valido: true
        });
      }

      return this.sendAction("actionOnRealizarValidacaoAssincrona", params, callbackOnValidacaoCompleta);
    },
    actions: {
      actInicioValidacaoAssincrona: function actInicioValidacaoAssincrona() {
        return this.mostrarSpinner(true);
      },
      actFimValidacaoAssincrona: function actFimValidacaoAssincrona() {
        return this.mostrarSpinner(false);
      },
      actFimValidacao: function actFimValidacao(params) {
        return this.tratarFimValidacao(params);
      },
      actRealizarValidacaoAssincrona: function actRealizarValidacaoAssincrona(params, callbackOnValidacaoCompleta) {
        if (params == null) {
          params = {};
        }

        return this.realizarValidacaoAssincrona(params, callbackOnValidacaoCompleta);
      }
    }
  });

  _exports.default = _default;
});