define("api-dashboard/components/inputs/input-email", ["exports", "api-dashboard/mixins/utils", "api-dashboard/components/inputs/base-text-field"], function (_exports, _utils, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend(_utils.default, {
    type: "email",
    realizarValidacoesAdicionais: function realizarValidacoesAdicionais(callbackOnValidacaoCompleta) {
      if (!this.validarEmail(this.get("val"))) {
        return callbackOnValidacaoCompleta({
          valido: false
        });
      }

      return this._super(callbackOnValidacaoCompleta);
    }
  });

  _exports.default = _default;
});