define("api-dashboard/models/kit-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    _destroy: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    createdAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    price: _emberData.default.attr('big'),
    quantity: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('date'),
    version: _emberData.default.attr('number'),
    kit: _emberData.default.belongsTo('kit', {
      inverse: 'items'
    }),
    product: _emberData.default.belongsTo('product'),
    totalPrice: Ember.computed('price', 'quantity', function () {
      var price, quantity;

      if (!this.get('price')) {
        return null;
      }

      price = new Big(this.get('price'));
      quantity = new Big(this.get('quantity'));
      return price.times(quantity).round(2);
    })
  });

  _exports.default = _default;
});