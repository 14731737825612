define("api-dashboard/helpers/raw-to-user/iso-datetime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.rawToUserIsoDatetime = void 0;
  var RawToUserIsoDatetimeHelper, rawToUserIsoDatetime;
  _exports.rawToUserIsoDatetime = rawToUserIsoDatetime;

  _exports.rawToUserIsoDatetime = rawToUserIsoDatetime = function rawToUserIsoDatetime(params) {
    var date, dia, mes, retorno;
    console.warn("Helper raw-to-user/iso-date-time depreciado em favor de format/time");
    retorno = params[0];

    try {
      date = new Date(retorno);
      dia = date.getDate().toString();
      mes = (date.getMonth() + 1).toString();
      dia = dia.length === 2 ? dia : "0" + dia;
      mes = mes.length === 2 ? mes : "0" + mes;
      retorno = dia + "/" + mes + "/" + date.getFullYear();
    } catch (error) {}

    return retorno;
  };

  RawToUserIsoDatetimeHelper = Ember.Helper.helper(rawToUserIsoDatetime);
  var _default = RawToUserIsoDatetimeHelper;
  _exports.default = _default;
});