define("api-dashboard/templates/components/notas-fiscais/item-form/-impostos-importacao", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rf7bWrl9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"importacao\"],[10,\"class\",\"tab-pane fade In\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"situacao-importacao\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"inputs/input-money\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"required\",\"cols\"],[\"Base de cálculo\",[24,[\"model\",\"iiBaseCalculo\"]],2,[24,[\"errors\",\"iiBaseCalculo\"]],[24,[\"model\",\"isSaving\"]],[24,[\"campoIiObrigatorio\"]],3]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-money\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"required\",\"cols\"],[\"Despesas aduaneiras\",[24,[\"model\",\"iiValorDespesasAduaneiras\"]],2,[24,[\"errors\",\"iiValorDespesasAduaneiras\"]],[24,[\"model\",\"isSaving\"]],[24,[\"campoIiObrigatorio\"]],3]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-money\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"required\",\"cols\"],[\"Valor do imposto\",[24,[\"model\",\"iiValor\"]],2,[24,[\"errors\",\"iiValor\"]],[24,[\"model\",\"isSaving\"]],[24,[\"campoIiObrigatorio\"]],3]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-money\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"required\",\"cols\"],[\"Valor do IOF\",[24,[\"model\",\"iiValorIof\"]],2,[24,[\"errors\",\"iiValorIof\"]],[24,[\"model\",\"isSaving\"]],[24,[\"campoIiObrigatorio\"]],3]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/item-form/-impostos-importacao.hbs"
    }
  });

  _exports.default = _default;
});