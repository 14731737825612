define("api-dashboard/components/invoices/invoice-show", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    router: Ember.inject.service(),
    application: Ember.inject.service(),
    issueBankBillModal: null,
    currentBoleto: null,
    currentIssueBankBill: null,
    init: function init(args) {
      return this._super(args);
    },
    didRender: function didRender(args) {
      return this.$("#detail-invoice-modal").modal('show');
    },
    willDestroyElement: function willDestroyElement(args) {
      return this.$("#detail-invoice-modal").modal('hide');
    },
    didInsertElement: function didInsertElement(args) {
      var clickHandler, self;
      self = this;
      clickHandler = self.get('handleOutsideClick').bind(self);
      return self.$("#detail-invoice-modal").on('click', clickHandler);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var self, target;
      self = this;
      target = self.$(event.target);

      if (target.get(0).id === 'detail-invoice-modal') {
        return this.get('router').transitionTo('invoices/index');
      }
    },
    issue_bank_bill_internal: function issue_bank_bill_internal() {
      var ajaxParams, due_date, self;
      self = this;
      due_date = self.get('currentIssueBankBill.date') || new Date();
      ajaxParams = {
        method: 'POST',
        data: {
          new_due_date: due_date,
          apply_fine: self.get('currentIssueBankBill.applyFine'),
          discount_percent: self.get('currentIssueBankBill.discount'),
          boleto_id: self.get('currentBoleto.id'),
          motive: self.get('currentIssueBankBill.reason')
        },
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/boletos/" + self.get('currentBoleto.id') + "/recreate"
      }, function (success, textStatus, data) {
        var message, toastType;

        if (success) {
          toastType = 'success';
          message = "Boleto reemitido com sucesso!";
          self.get('issueBankBillModal').hide();
        } else {
          toastType = 'error';
          message = data.jqXHR.responseJSON.msg;
        }

        self.set('spin.buttonSpin', false);
        return self.get('application').showAlert(message, toastType, 30000, false);
      });
    },
    showInvoicePdf: function showInvoicePdf(boleto) {
      return window.open(boleto.get('pdfUrl'));
    },
    actions: {
      showBankBillModal: function showBankBillModal(boleto) {
        var self;
        self = this;
        self.set('currentBoleto', boleto);
        self.set('currentIssueBankBill', {
          date: new Date()
        });
        return self.get('issueBankBillModal').show();
      },
      onCancel: function onCancel() {
        return this.get('router').transitionTo('invoices/index');
      },
      onSaveBankBill: function onSaveBankBill() {
        var ajaxParams, due_date, maxDate, message, momentDueDate, self;
        self = this;
        due_date = self.get('currentIssueBankBill.date') || new Date();
        maxDate = moment().add(5, 'days');
        momentDueDate = moment(due_date);

        if (momentDueDate < moment() || momentDueDate > maxDate) {
          message = 'A nova data de vencimento não pode ser inferior a data atual ou superior a 5 dias úteis';
          return self.get('application').showAlert(message, 'error', 30000, false);
        } else {
          ajaxParams = {
            method: 'POST',
            data: {
              new_due_date: due_date,
              boleto_id: self.get('currentBoleto.id'),
              motive: self.get('currentIssueBankBill.reason')
            },
            global: false
          };
          return this.get('store').externalAjax(ajaxParams, {
            apiURL: "/web_api/boletos/" + self.get('currentBoleto.id') + "/recreate"
          }, function (success, textStatus, data) {
            var result, toastType;

            if (success) {
              toastType = 'success';
              message = "Boleto reemitido com sucesso!";
              self.get('issueBankBillModal').hide();
              self.get('store').pushPayload('boleto', data.data);
              result = self.get('store').peekRecord('boleto', data.data.boleto.id);
              self.showInvoicePdf(result);
              self.set('model', self.get('store').findRecord('lancamento', self.get('model.id')));
            } else {
              toastType = 'error';
              message = data.jqXHR.responseJSON.msg;
            }

            return self.get('application').showAlert(message, toastType, 30000, false);
          });
        }
      },
      onCancelBankBill: function onCancelBankBill(modal) {
        this.set('currentIssueBankBill', {});
        return modal.hide();
      }
    }
  });

  _exports.default = _default;
});