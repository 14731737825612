define("api-dashboard/components/back-to-top", ["exports", "api-dashboard/mixins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BackToTopComponent;
  BackToTopComponent = Ember.Component.extend(_utils.default, {
    classNames: ["back-to-top"],
    tagName: "a",
    didInsertElement: function didInsertElement() {
      this.mostrarOcultarScroll();
      return this.atribuirListenerScroll();
    },
    atribuirListenerScroll: function atribuirListenerScroll() {
      var self;
      self = this;
      return $(window).on("scroll", function () {
        return self.mostrarOcultarScroll();
      });
    },
    click: function click(e) {
      e.preventDefault();
      return this.moverScrollParaTopo();
    },
    touchstart: function touchstart(e) {
      e.preventDefault();
      return this.moverScrollParaTopo();
    },
    moverScrollParaTopo: function moverScrollParaTopo() {
      return this.moverScrollComAnimacao();
    },
    mostrarOcultarScroll: function mostrarOcultarScroll() {
      var scrollMinimo;
      scrollMinimo = 50;

      if ($(window).scrollTop() > scrollMinimo) {
        return this.$().fadeIn();
      } else {
        return this.$().fadeOut();
      }
    }
  });
  var _default = BackToTopComponent;
  _exports.default = _default;
});