define("api-dashboard/mixins/components/nota-fiscal-servico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    cancelNfSeModal: null,
    emailNfseRecipient: null,
    emailRecipients: [],
    emailNfSeModal: null,
    showCancelModal: function showCancelModal(selectedItem) {
      this.set('selectedNfse', selectedItem);
      return this.get('cancelNfSeModal').show();
    },
    hideCancelModal: function hideCancelModal() {
      this.set('selectedNfse', null);
      return this.get('cancelNfSeModal').hide();
    },
    showEmailModal: function showEmailModal(selectedItem) {
      this.set('selectedNfse', selectedItem);
      return this.get('emailNfSeModal').show();
    },
    hideEmailModal: function hideEmailModal() {
      this.set('selectedNfse', null);
      this.set('emailNfseRecipient', null);
      this.set('emailRecipients', []);
      return this.get('emailNfSeModal').hide();
    },
    cancelNfse: function cancelNfse(nfse) {
      var msg, self;
      self = this;
      self.set('buttonSpinning', true);
      self.set('loadingSomething', true);
      msg = self.get('intl').t('globals.messages.confirm_cancel_service_invoice', {
        invoice_number: Ember.get(nfse, 'numero')
      });

      if (confirm(msg)) {
        if (!Ember.get(nfse, 'cancelReason')) {
          msg = self.get('intl').t('globals.messages.cancel_reason_mandatory');
          self.get('application').showAlert(msg, 'error');
          return;
        }

        return nfse.cancelInvoice(function (result) {
          msg = self.get('intl').t('globals.messages.alert_success_cancel_service_invoice');
          self.get('application').showAlert(msg, 'success');
          self.set('buttonSpinning', false);
          self.set('loadingSomething', false);
          return self.hideCancelModal();
        }, function (error) {
          var error_msg;
          error_msg = error['mensagem'];
          msg = self.get('intl').t('globals.messages.alert_error_cancel_service_invoice', {
            error: error_msg
          });
          self.get('application').showAlert(msg, 'error');
          self.set('buttonSpinning', false);
          return self.set('loadingSomething', false);
        });
      }
    },
    addEmailRecipient: function addEmailRecipient() {
      var recipients;
      recipients = Array.from(this.get('emailRecipients'));
      recipients = recipients || [];

      if (!this.get('emailNfseRecipient')) {
        return;
      }

      recipients.push(this.get('emailNfseRecipient'));
      this.set('emailRecipients', recipients);
      return this.set('emailNfseRecipient', null);
    },
    removeEmailRecipient: function removeEmailRecipient(recipient) {
      var index, recipients;
      recipients = Array.from(this.get('emailRecipients'));
      index = recipients.indexOf(recipient);

      if (index > -1) {
        recipients.splice(index, 1);
      }

      return this.set('emailRecipients', recipients);
    },
    sendEmailNfse: function sendEmailNfse(nfse) {
      var self;
      self = this;
      self.set('buttonSpinning', true);
      self.set('loadingSomething', true);
      return nfse.emailInvoice(this.get('emailRecipients'), function (success) {
        var msg;
        msg = self.get('intl').t('globals.messages.alert_success_email_service_invoice');
        self.get('application').showAlert(msg, 'success');
        self.set('buttonSpinning', false);
        self.set('loadingSomething', false);
        return self.hideEmailModal();
      }, function (error) {
        var msg;
        msg = self.get('intl').t('globals.messages.alert_error_email_service_invoice');
        self.get('application').showAlert(msg, 'error');
        self.set('buttonSpinning', false);
        return self.set('loadingSomething', false);
      });
    }
  });

  _exports.default = _default;
});