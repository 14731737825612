define("api-dashboard/components/inputs/input-corporate-name", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend({
    cols: '6',
    label: 'Razão Social',
    maxlength: 50,
    placeholder: 'Razão Social'
  });

  _exports.default = _default;
});