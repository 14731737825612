define("api-dashboard/models/referencia", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Referencia;
  Referencia = _emberData.default.Model.extend({
    empresa: _emberData.default.belongsTo("empresa"),
    status: _emberData.default.attr(""),
    statusSefaz: _emberData.default.attr(""),
    mensagemSefaz: _emberData.default.attr(""),
    chaveNfe: _emberData.default.attr(""),
    numero: _emberData.default.attr(""),
    serie: _emberData.default.attr(""),
    caminhoXmlNotaFiscal: _emberData.default.attr(""),
    caminhoXmlCancelamento: _emberData.default.attr(""),
    caminhoDanfe: _emberData.default.attr(""),
    ref: _emberData.default.attr(""),
    tipo: _emberData.default.attr(""),
    nomeDestinatario: _emberData.default.attr(""),
    valorTotal: _emberData.default.attr(""),
    dataEmissao: _emberData.default.attr(""),
    dataEnvio: _emberData.default.attr(""),
    codigoVerificacao: _emberData.default.attr(""),
    uri: _emberData.default.attr(""),
    numeroRps: _emberData.default.attr(""),
    serieRps: _emberData.default.attr(""),
    chaveCfe: _emberData.default.attr(""),
    erros: _emberData.default.attr(""),
    cceNumeroSequencial: _emberData.default.attr(""),
    cceCaminhoXml: _emberData.default.attr(""),
    cceCaminhoPdf: _emberData.default.attr(""),
    nfPostErrorXml: _emberData.default.attr(""),
    nfPostErrorTrace: _emberData.default.attr(""),
    nfPostErrorParams: _emberData.default.attr(""),
    nfPostErrorQueryString: _emberData.default.attr(""),
    httpRequestQuery: _emberData.default.attr(""),
    httpRequestBody: _emberData.default.attr(""),
    httpRequestIp: _emberData.default.attr(""),
    httpRequestPath: _emberData.default.attr(""),
    httpRequestHost: _emberData.default.attr(""),
    httpRequestHeaders: _emberData.default.attr(""),
    httpRequestFullPath: Ember.computed("httpRequestHost", "httpRequestQuery", "httpRequestPath", function () {
      return this.get("httpRequestHost") + this.get("httpRequestPath") + "?" + this.get("httpRequestQuery");
    }),
    isNfe: Ember.computed("tipo", function () {
      return this.get("tipo") === "nfe";
    }),
    isNfse: Ember.computed("tipo", function () {
      return this.get("tipo") === "nfse";
    }),
    isNfce: Ember.computed("tipo", function () {
      return this.get("tipo") === "nfce";
    }),
    isCfe: Ember.computed("tipo", function () {
      return this.get("tipo") === "cfe";
    }),
    isErro: Ember.computed("tipo", function () {
      return this.get("tipo") === "erro";
    }),
    caminhoCompletoXmlNotaFiscal: Ember.computed("caminhoXmlNotaFiscal", function () {
      return this.get("store").adapterFor("application").get("hostNfe") + this.get("caminhoXmlNotaFiscal");
    }),
    caminhoCompletoDanfe: Ember.computed("caminhoDanfe", function () {
      return this.get("store").adapterFor("application").get("hostNfe") + this.get("caminhoDanfe");
    }),
    caminhoCompletoXmlCce: Ember.computed("cceCaminhoXml", function () {
      return this.get("store").adapterFor("application").get("hostNfe") + this.get("cceCaminhoXml");
    }),
    caminhoCompletoPdfCce: Ember.computed("cceCaminhoPdf", function () {
      return this.get("store").adapterFor("application").get("hostNfe") + this.get("cceCaminhoPdf");
    }),
    caminhoCompletoXmlCancelamento: Ember.computed("caminhoXmlCancelamento", function () {
      return this.get("store").adapterFor("application").get("hostNfe") + this.get("caminhoXmlCancelamento");
    }),
    possuiErrosNfse: Ember.computed("erros", function () {
      if (this.get("erros")) {
        return true;
      } else {
        return false;
      }
    })
  });
  var _default = Referencia;
  _exports.default = _default;
});