define("api-dashboard/templates/components/formularios/simple-tax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHBJ6Ge6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\"Tributação sobre produtos\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-hover table-striped\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Estado\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Tipo de Tributação\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Alíquota (%)\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tr\"]],[24,[\"in\"]],[24,[\"taxRates\"]]],null,{\"statements\":[[4,\"unless\",[[24,[\"tr\",\"_destroy\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[24,[\"tr\",\"siglaUf\"]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[28,\"inputs/input-select\",null,[[\"optionos\",\"value\"],[[24,[\"constants\",\"taxTypes\"]],[24,[\"tr\",\"taxType\"]]]]],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[28,\"inputs/input-text\",null,[[\"valueInput\"],[[24,[\"tr\",\"taxRate\"]]]]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/formularios/simple-tax.hbs"
    }
  });

  _exports.default = _default;
});