define("api-dashboard/components/inventories-balance/inventories-balance", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    application: Ember.inject.service(),
    router: Ember.inject.service(),
    stores: null,
    balances: null,
    init: function init(args) {
      this.set('stores', this.get('application.currentDomain.visibleStores'));
      return this._super(args);
    },
    didInsertElement: function didInsertElement(args) {
      var self;
      self = this;

      if (this.get('stores').length === 1) {
        return self.send('setStore', this.get('stores').objectAt(0));
      } else {
        return this.$("#store-list-modal").modal('show');
      }
    },
    willDestroyElement: function willDestroyElement(args) {
      this.$("#store-list-modal").modal('hide');
      return this.$("#inventory-balance").modal('hide');
    },
    actions: {
      setStore: function setStore(store) {
        var ajaxParams, self;
        self = this;
        ajaxParams = {
          type: "GET"
        };
        this.get('store').externalAjax(ajaxParams, {
          apiURL: "/products/" + this.get('model.mainId') + "/kardex?store_id=" + store.id
        }, function (sucesso, textStatus, data) {
          if (sucesso) {
            return self.set('balances', textStatus);
          } else {
            return self.set('balances', []);
          }
        });
        return this.$("#inventory-balance").modal('show');
      },
      onCancel: function onCancel() {
        return this.get('router').transitionTo('products/index');
      }
    }
  });

  _exports.default = _default;
});