define("api-dashboard/components/navbar-topo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actionOnChangeDomain: null,
    tagName: "nav",
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    rotaAtual: null,
    main_menu: [],
    cog_menu: [],
    __menu_main: Ember.computed(function () {
      return {
        nfse: {
          title: this.get('intl').t('navbar-topo.main_menu.invoices'),
          icon: 'fas fa-file-invoice-dollar sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens sidebar--link',
          href: 'notas-fiscais-servico/index',
          items: []
        },
        clientes: {
          title: this.get('intl').t('navbar-topo.main_menu.customers'),
          icon: 'fas fa-users               sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens sidebar--link',
          href: 'customers/index',
          items: []
        },
        relatorios: {
          title: 'Relatórios',
          icon: 'fas fa-folder              sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens sidebar--link',
          href: 'reports/index',
          items: []
        },
        vendas: {
          title: 'Vendas',
          icon: 'fas fa-cash-register       sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens sidebar--link',
          href: 'sales/index',
          items: []
        },
        operacoes: {
          title: 'Operações',
          icon: 'fas fa-th                  sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens',
          items: []
        },
        financeiro: {
          title: 'Financeiro',
          icon: 'fas fa-dollar-sign         sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens',
          items: []
        },
        cadastros: {
          title: 'Cadastros',
          icon: 'fas fa-users               sidebar--icons',
          cssClass: 'painelFocus__sidebar--itens',
          items: []
        }
      };
    }),
    __cog_menu: Ember.computed(function () {
      return {
        global_config: {
          title: 'Configurações Globais',
          icon: '',
          cssClass: '',
          href: 'system-config/edit',
          action: ''
        },
        stores_config: {
          title: 'Configurações das Lojas',
          icon: '',
          cssClass: '',
          href: 'stores-config/index',
          action: ''
        },
        file_download: {
          title: 'Download de Arquivos',
          icon: '',
          cssClass: '',
          href: 'download-files/index',
          action: ''
        },
        boleto_config: {
          title: 'Configurações de Boletos',
          icon: '',
          cssClass: '',
          href: '',
          action: ''
        },
        incricao_estadual: {
          title: 'Inscrição Estadual',
          icon: '',
          cssClass: '',
          href: '',
          action: ''
        },
        invoices: {
          title: this.get('intl').t('navbar-topo.receipts'),
          icon: '',
          cssClass: '',
          href: 'invoices/index',
          action: ''
        }
      };
    }),
    possivelAlterarDomain: Ember.computed("application.domains", function () {
      var retorno;
      retorno = false;

      try {
        retorno = this.get("application.domains.length") > 1;
      } catch (error) {}

      return retorno;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var oldRotaAtual, rotaAtual;
      rotaAtual = this.get("rotaAtual");
      oldRotaAtual = this.get("_oldRotaAtual");

      if (!(rotaAtual !== oldRotaAtual && this.$())) {
        return;
      }

      return this.atualizarStatusBotaoPorUrl();
    },
    didInsertElement: function didInsertElement() {
      return this.atualizarStatusBotaoPorUrl();
    },
    atualizarStatusBotaoPorUrl: function atualizarStatusBotaoPorUrl() {
      var rotaAtual;
      rotaAtual = window.location.pathname;
      this.$("li").removeClass("active");
      return this.$("a[href='" + rotaAtual + "']").closest("li").addClass("active");
    },
    sair: function sair() {
      return this.get("session").invalidate({}, function () {});
    },
    alterarDomain: function alterarDomain() {
      this.set("application.currentDomain", null);
      return this.sendAction("actionOnChangeDomain");
    },
    __addMenuItem: function __addMenuItem(itemsMenu, item) {
      var itemExists;
      itemExists = itemsMenu.find(function (i) {
        return i.uri === item.uri;
      });

      if (itemExists) {
        return;
      }

      return itemsMenu.addObject(item);
    },
    __clearMainMenu: function __clearMainMenu() {
      var k, ref, results, v;
      this.set('main_menu', []);
      ref = this.get('__menu_main');
      results = [];

      for (k in ref) {
        v = ref[k];
        results.push(this.set("__menu_main." + k + ".items", []));
      }

      return results;
    },
    __clearCogMenu: function __clearCogMenu() {
      return this.set('cog_menu', []);
    },
    __buildCogMenu: Ember.observer('application.currentDomain', 'application.currentUser', function () {
      var cog_menu, menu;

      this.__clearCogMenu();

      menu = this.get('cog_menu');
      cog_menu = this.get('__cog_menu');

      if (this.get('application.currentDomain.isLojas')) {
        menu.addObject(cog_menu.report_config);
      }

      menu.addObject(cog_menu.inscricao_estadual);
      menu.addObject(cog_menu.file_download);
      menu.addObject(cog_menu.invoices);
      return this.set('cog_menu', menu);
    }),
    __buildMainMenu: Ember.observer('application.currentDomain', function () {
      this.__clearMainMenu();

      this.__buildMainMenuAcras();

      this.__buildMainMenuOnlyCashier();

      this.__buildMainMenuLojas();

      this.__buildMainMenuNfe();

      this.__buildMainMenuWebNfse();

      this.__buildMainMenuPainelApiNfe();

      this.__buildMainMenuWebNfce();

      return this.__buildMainMenuErp();
    }),
    __buildMainMenuNfe: function __buildMainMenuNfe() {
      var menu, menu_cad, menu_ope;

      if (!this.get('application.currentDomain.isNfe')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      this.__addMenuItem(menu_ope.items, {
        title: 'Notas Fiscais',
        uri: 'notas-fiscais/index'
      });

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Produtos',
        uri: 'products/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (Retaguarda)',
        uri: 'users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Modelos de NF-e',
        uri: 'modelos-notas-fiscais/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Transportadoras',
        uri: 'transportadores/index'
      });

      menu.addObject(menu_ope);
      menu.addObject(menu_cad);
      menu.addObject(this.get('__menu_main.relatorios'));
      return this.set('main_menu', menu);
    },
    __buildMainMenuLojas: function __buildMainMenuLojas() {
      var menu, menu_cad, menu_fin, menu_ope;

      if (!this.get('application.currentDomain.isLojas')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      this.__addMenuItem(menu_ope.items, {
        title: 'Buscar Vendas',
        uri: 'sales/index'
      });

      if (this.get('application.currentDomain.hasWebPos')) {
        this.__addMenuItem(menu_ope.items, {
          title: 'Realizar Vendas',
          uri: 'sales/new'
        });
      }

      menu_fin = this.get('main_menu.financeiro') || this.get('__menu_main.financeiro');

      this.__addMenuItem(menu_ope.items, {
        title: 'Lançamentos',
        uri: 'lancamentos/index'
      });

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Centros de Custo',
        uri: 'cost-centers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (PDV)',
        uri: 'pos-users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (Retaguarda)',
        uri: 'users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Fornecedores',
        uri: 'suppliers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Modelos de Lançamentos',
        uri: 'modelos-lancamentos/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Modelos de NF-e',
        uri: 'modelos-notas-fiscais/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Planos de Conta',
        uri: 'planos-conta/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Produtos',
        uri: 'products/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Transportadoras',
        uri: 'transportadores/index'
      });

      menu.addObject(menu_ope);
      menu.addObject(menu_fin);
      menu.addObject(menu_cad);
      menu.addObject(this.get('__menu_main.relatorios'));
      return this.set('main_menu', menu);
    },
    __buildMainMenuErp: function __buildMainMenuErp() {
      var menu_cad;

      if (!this.get('application.currentDomain.isErp')) {
        return;
      }

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');
      return this.__addMenuItem(menu_cad.items, {
        title: 'Fornecedores',
        uri: 'suppliers/index'
      });
    },
    __buildMainMenuWebNfse: function __buildMainMenuWebNfse() {
      var menu, menu_cad, menu_ope;

      if (!this.get('application.currentDomain.isWebNfse')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      this.__addMenuItem(menu_ope.items, {
        title: this.get('intl').t('navbar-topo.main_menu.invoices'),
        icon: 'fas fa-file-invoice-dollar sidebar--icons',
        uri: 'notas-fiscais-servico/index'
      });

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (Retaguarda)',
        uri: 'users/index'
      });

      menu.addObject(menu_ope);
      menu.addObject(menu_cad);
      menu.addObject(this.get('__menu_main.relatorios'));
      return this.set('main_menu', menu);
    },
    __buildMainMenuWebNfce: function __buildMainMenuWebNfce() {
      var menu, menu_cad, menu_ope;

      if (!this.get('application.currentDomain.isWebNfce')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      if (this.get('application.currentDomain.hasWebPos')) {
        this.__addMenuItem(menu_ope.items, {
          title: 'Realizar Vendas',
          uri: 'sales/new'
        });
      }

      this.__addMenuItem(menu_ope.items, {
        title: 'Buscar Vendas',
        uri: 'sales/index'
      });

      menu_cad = this.get('menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Produtos',
        uri: 'products/index'
      });

      if (this.get('application.currentDomain.hasWebPos')) {
        menu.addObject(this.get('__menu_main.vendas'));
      }

      menu.addObject(menu_ope);
      menu.addObject(menu_cad);
      menu.addObject(this.get('__menu_main.relatorios'));
      return this.set('main_menu', menu);
    },
    __buildMainMenuPainelApiNfe: function __buildMainMenuPainelApiNfe() {
      var menu, menu_cad, menu_ope;

      if (!this.get('application.currentDomain.isPainelApiNfe')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      this.__addMenuItem(menu_ope.items, {
        title: 'Busca de Referências',
        uri: 'busca-referencia'
      });

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (Retaguarda)',
        uri: 'users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Empresas',
        uri: 'empresas/index'
      });

      menu.addObject(menu_ope);
      menu.addObject(menu_cad);
      return this.set('main_menu', menu);
    },
    __buildMainMenuAcras: function __buildMainMenuAcras() {
      var menu, menu_cad, menu_ope;

      if (!this.get('application.currentDomain.isAcras')) {
        return;
      }

      menu = this.get('main_menu');
      menu_ope = this.get('main_menu.operacoes') || this.get('__menu_main.operacoes');

      this.__addMenuItem(menu_ope.items, {
        title: this.get('intl').t('navbar-topo.main_menu.invoices'),
        icon: 'fas fa-file-invoice-dollar sidebar--icons',
        uri: 'notas-fiscais-servico/index'
      });

      this.__addMenuItem(menu_ope.items, {
        title: 'Lançamentos',
        uri: 'lancamentos/index'
      });

      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Centros de Custo',
        uri: 'cost-centers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Fornecedores',
        uri: 'suppliers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Planos de Conta',
        uri: 'planos-conta/index'
      });

      menu.addObject(menu_ope);
      menu.addObject(menu_cad);
      return this.set('main_menu', menu);
    },
    __buildMainMenuOnlyCashier: function __buildMainMenuOnlyCashier() {
      var menu, menu_cad, menu_fin;

      if (!this.get('application.currentDomain.isOnlyCashier')) {
        return;
      }

      menu = this.get('main_menu');
      menu_fin = this.get('main_menu.financeiro') || this.get('__menu_main.financeiro');
      menu.addObject(menu_fin);
      menu_cad = this.get('main_menu.cadastros') || this.get('__menu_main.cadastros');

      this.__addMenuItem(menu_cad.items, {
        title: 'Clientes',
        uri: 'customers/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (PDV)',
        uri: 'pos-users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Colaboradores (Retaguarda)',
        uri: 'users/index'
      });

      this.__addMenuItem(menu_cad.items, {
        title: 'Planos de Conta',
        uri: 'planos-conta/index'
      });

      menu.addObject(menu_cad);
      return this.set('main_menu', menu);
    },
    actions: {
      actSair: function actSair() {
        return this.sair();
      },
      actAlterarDomain: function actAlterarDomain() {
        return this.alterarDomain();
      },
      burgerMenuClick: function burgerMenuClick() {
        this.$('.navbar__list').toggleClass('expanded');
        return this.$('div .nav-toggle').toggleClass('expanded');
      }
    }
  });

  _exports.default = _default;
});