define("api-dashboard/components/inputs/input-cep", ["exports", "api-dashboard/components/inputs/base-masked-field", "api-dashboard/mixins/requests/util"], function (_exports, _baseMaskedField, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend(_util.default, {
    enderecoCep: null,
    label: 'CEP',
    pattern: '00000-000',
    placeholder: 'Código de End. Postal',
    fetchingObserver: Ember.observer('fetching', function () {
      var suffix;
      suffix = '';

      if (this.get('fetching')) {
        suffix = ' <i class="fas fa-spinner fa-spin"></i>';
      }

      return this.$('label').html(this.get('formattedLabel') + suffix);
    }),
    validarValorMascara: function validarValorMascara() {
      return this.validarCepInterno();
    },
    validarCepInterno: function validarCepInterno() {
      return this.validarCEP(this.obterValorSemMascara(this.$('input')));
    },
    metodoAssincronoValidacaoInterna: function metodoAssincronoValidacaoInterna(context, params, callbackOnComplete) {
      return context.validarCepWebservice(context, params, callbackOnComplete);
    },
    validarCepWebservice: function validarCepWebservice(context, params, callbackOnComplete) {
      var cep, self;
      self = this;
      cep = self.obterValorSemMascara(this.$('input'));
      return this.getAddressFromPostalCode(this, {
        cep: cep
      }, function (sucesso, dados, httpCode) {
        if (!sucesso) {
          return callbackOnComplete({
            valido: false
          }, function () {});
        }

        self.set("enderecoCep", dados);
        params['endereco'] = dados;
        params['valido'] = sucesso;

        if (self.get('addressType')) {
          params['addressType'] = self.get('addressType');
        } else {
          params['addressType'] = 'default';
        }

        return callbackOnComplete(params, function () {});
      });
    },
    paramsRetornoFimValidacao: function paramsRetornoFimValidacao(params) {
      params = this._super(params);

      if (params["valido"]) {
        params["endereco"] = this.get("enderecoCep");
      }

      return params;
    }
  });

  _exports.default = _default;
});