define("api-dashboard/components/base/omnisearch-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    placeholder: Ember.computed(function () {
      return this.get('intl').t('globals.labels.enter_search_here');
    }),
    omnisearchText: '',
    components: [],
    showFilters: false,
    actions: {
      toggleFilterSearch: function toggleFilterSearch() {
        return this.set('showFilters', !this.get('showFilters'));
      },
      search: function search() {
        return this.search(false, this.get('showFilters'));
      }
    }
  });

  _exports.default = _default;
});