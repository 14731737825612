define("api-dashboard/components/cost-centers/cost-center-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'cost-center',
    title: 'Centro de Custo',
    titlePlural: 'Centros de Custo',
    newRoute: 'cost-centers/new',
    editRoute: 'cost-centers/edit',
    partialStats: null,
    showRefinedSearch: false,
    showFilters: false,
    columns: [{
      field: 'descricao',
      label: 'Descrição'
    }, {
      field: 'rateavel',
      label: 'Rateável',
      format: 'simnao'
    }],
    searchParams: [],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});