define("api-dashboard/templates/components/base/spin-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Shsf6SiR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[22,\"className\"]],[12,\"id\",[22,\"id\"]],[12,\"disabled\",[22,\"disabled\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"callAction\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"spin\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"icon\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"   \"],[1,[22,\"buttonText\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/spin-button.hbs"
    }
  });

  _exports.default = _default;
});