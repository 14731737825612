define("api-dashboard/models/ac-filter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    constants: Ember.inject.service(),
    dataType: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    queryUser: _emberData.default.attr('boolean'),
    required: _emberData.default.attr('boolean'),
    requiredFrom: _emberData.default.attr('boolean'),
    requiredTo: _emberData.default.attr('boolean'),
    targetField: _emberData.default.attr('string'),
    targetModel: _emberData.default.attr('string'),
    acFilterDef: _emberData.default.belongsTo('ac-filter-def'),
    acFilterOptions: _emberData.default.hasMany('ac-filter-option'),
    isPeriod: Ember.computed('dataType', function () {
      return this.get('dataType') === 'period';
    }),
    isStorecombo: Ember.computed('dataType', function () {
      return this.get('dataType') === 'storecombo';
    })
  }, _defineProperty(_DS$Model$extend, "isPeriod", Ember.computed('dataType', function () {
    return this.get('dataType') === 'options';
  })), _defineProperty(_DS$Model$extend, "isAutocomplete", Ember.computed('dataType', function () {
    return this.get('dataType') === 'autocomplete';
  })), _defineProperty(_DS$Model$extend, "isTimezonePeriod", Ember.computed('dataType', function () {
    return this.get('dataType') === 'timezone_period';
  })), _defineProperty(_DS$Model$extend, "isCheckbox", Ember.computed('dataType', function () {
    return this.get('dataType') === 'checkbox';
  })), _defineProperty(_DS$Model$extend, "camelizedTargetField", Ember.computed('targetField', function () {
    return this.get('targetField').camelize();
  })), _defineProperty(_DS$Model$extend, "shouldDisplay", Ember.computed('dataType', 'queryUser', 'application.currentDomain.multipleStores', function () {
    if (this.get('isStorecombo') && !this.get('application.currentDomain.multipleStores')) {
      return false;
    } else {
      return this.get('queryUser');
    }
  })), _defineProperty(_DS$Model$extend, "inputComponent", Ember.computed('dataType', function () {
    if (this.get('dataType')) {
      switch (this.get('dataType')) {
        case 'period':
        case 'timezone_period':
          return this.get('constants.inputComponentTypes.PERIOD');

        case 'storecombo':
          return this.get('constants.inputComponentTypes.SELECT');

        case 'options':
          return this.get('constants.inputComponentTypes.SELECT');

        case 'autocomplete':
          return this.get('constants.inputComponentTypes.AUTOCOMPLETE');

        case 'checkbox':
          return this.get('constants.inputComponentTypes.TOGGLE');

        default:
          return this.get('constants.inputComponentTypes.STRING');
      }
    }
  })), _defineProperty(_DS$Model$extend, "dataSource", Ember.computed('targetModel', function () {
    var comboClass;
    comboClass = this.get('targetModel');

    if (comboClass === 'Store') {
      comboClass = 'fStore';
    }

    return this.get('store').findAll(comboClass);
  })), _defineProperty(_DS$Model$extend, "options", Ember.computed('acFilterOptions', function () {
    var i, j, o, opts, ref;

    if (this.get('acFilterOptions') && this.get('acFilterOptions').length > 0) {
      opts = [];

      for (i = j = 0, ref = this.get('acFilterOptions').length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
        o = this.get('acFilterOptions').objectAt(i);
        opts.push({
          description: o.get('label'),
          value: o.get('value')
        });
      }

      return opts;
    }
  })), _defineProperty(_DS$Model$extend, "name", Ember.computed('filterValues', function () {
    if (this.get('filterValues')) {
      return "ac_filter_" + this.get('id') + "_" + this.get('filterValues')[0];
    }

    return "ac_filter_" + this.get('id');
  })), _defineProperty(_DS$Model$extend, "storecomboLabelPath", Ember.computed('targetField', function () {
    return 'content.' + this.get('targetField');
  })), _defineProperty(_DS$Model$extend, "collectionName", Ember.computed('targetModel', function () {
    return this.get('targetModel');
  })), _defineProperty(_DS$Model$extend, "isFilled", Ember.computed('dataType', 'fromDate', 'toDate', 'value', function () {
    switch (this.get('dataType')) {
      case 'period':
      case 'timezone_period':
        return this.get('fromDate') != null || this.get('toDate') != null;

      case 'storecombo':
        return this.get('value') != null;

      case 'options':
        return this.get('value') != null;

      case 'checkbox':
        return this.get('value') != null;

      case 'autocomplete':
        return this.get('value') != null;

      default:
        return null;
    }
  })), _defineProperty(_DS$Model$extend, "filledValues", Ember.computed('dataType', 'fromDate', 'toDate', 'value', function () {
    switch (this.get('dataType')) {
      case 'period':
      case 'timezone_period':
        return {
          fromDate: this.get('fromDate'),
          toDate: this.get('toDate')
        };

      case 'storecombo':
        return {
          filter_value: this.get('value')
        };

      case 'options':
        return {
          filter_value: this.get('value')
        };

      case 'checkbox':
        return {
          filter_value: this.get('value')
        };

      case 'autocomplete':
        return {
          filter_value: this.get('value.id')
        };

      default:
        return null;
    }
  })), _defineProperty(_DS$Model$extend, "validateRequired", function validateRequired() {
    var dataType, retorno;
    retorno = true;
    dataType = this.get('dataType');

    if (dataType === 'period' || dataType === 'timezone_period') {
      if ((this.get('requiredFrom') || this.get('required')) && this.get('fromDate') == null) {
        retorno = false;
        this.get('errors').set('fromDate', [{
          message: 'não pode ficar em branco'
        }]);
        this.get('errors').set('value', [{
          message: 'não pode ficar em branco'
        }]);
      }

      if ((this.get('requiredTo') || this.get('required')) && this.get('toDate') == null) {
        retorno = false;
        this.get('errors').set('toDate', [{
          message: 'não pode ficar em branco'
        }]);
        this.get('errors').set('value', [{
          message: 'não pode ficar em branco'
        }]);
      }
    } else {
      if (this.get('required') && this.get('value') == null) {
        retorno = false;
        this.get('errors').set('value', [{
          message: 'não pode ficar em branco'
        }]);
      }
    }

    return retorno;
  }), _DS$Model$extend));

  _exports.default = _default;
});