define("api-dashboard/components/snippets/last-15-day-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var buildGraph, fc, fd, formatReal, getMoney;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self;
      self = this;
      return $.getScript('//cdnjs.cloudflare.com/ajax/libs/morris.js/0.5.1/morris.js', function (data, textStatus, jqxhr) {
        return self.getGraphData();
      });
    },
    getGraphData: function getGraphData() {
      var ajaxParams;
      ajaxParams = {
        method: 'GET'
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: '/snippets/last_15_day_sale'
      }, function (success, content, data) {
        if (success) {
          return buildGraph(data);
        }
      });
    }
  });

  _exports.default = _default;

  fd = function fd(x) {
    var _centralOffset, _date, _userOffset, d;

    d = new Date(x);
    _userOffset = d.getTimezoneOffset() * 60 * 1000;
    _centralOffset = 6 * 60 * 60 * 1000;
    _date = new Date(d.getTime() - _userOffset + _centralOffset);
    return _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear();
  };

  getMoney = function getMoney(str) {
    return parseInt(str.replace(/[\D]+/g, ''));
  };

  formatReal = function formatReal(int) {
    var tmp;
    tmp = int + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

    if (tmp.length > 6) {
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    return tmp;
  };

  fc = function fc(x) {
    var r;

    if (typeof x === "undefined") {
      r = '0,00';
    } else {
      r = formatReal(x);
    }

    return r;
  };

  buildGraph = function buildGraph(data) {
    var d, l, props, stores, y;
    d = eval(data.data);
    stores = d.shift();
    y = [];
    l = [];
    $.each(stores, function (k, v) {
      y.push(k);
      return l.push(v);
    });
    props = {
      element: 'graph',
      data: d,
      xkey: 'period',
      smooth: false,
      dateFormat: fd,
      xLabelFormat: fd,
      yLabelFormat: fc,
      ykeys: y,
      labels: l,
      preUnits: 'R$'
    };
    return Morris.Line(props);
  };
});