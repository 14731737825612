define("api-dashboard/components/inputs/input-period", ["exports", "api-dashboard/config/environment", "ember-inflector"], function (_exports, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    from: null,
    layoutName: 'components/inputs/input-period',
    period: null,
    periodOptions: [{
      value: 'today',
      description: 'Hoje'
    }, {
      value: 'currentWeek',
      description: 'Esta Semana'
    }, {
      value: 'currentMonth',
      description: 'Mês Atual'
    }, {
      value: 'pastMonth',
      description: 'Mês Anterior'
    }, {
      value: 'otherPeriod',
      description: 'Outro'
    }],
    to: null,
    periodChange: Ember.observer('period', function () {
      var from, to;

      switch (this.get('period')) {
        case 'today':
          from = moment().hour(0).minute(0).second(0)._d;
          to = moment().hour(23).minute(59).second(59)._d;
          break;

        case 'currentWeek':
          from = moment().weekday(0).hour(0).minute(0).second(0)._d;
          to = moment().weekday(6).hour(23).minute(59).second(59)._d;
          break;

        case 'currentMonth':
          from = moment().date(1).hour(0).minute(0).second(0)._d;
          to = moment().month(moment().month() + 1).date(1).hour(0).minute(0).second(0).add('s', -1)._d;
          break;

        case 'pastMonth':
          from = moment().month(moment().month() - 1).date(1).hour(0).minute(0).second(0)._d;
          to = moment().date(1).hour(0).minute(0).second(0).add('s', -1)._d;
          break;

        case 'otherPeriod':
          return;

        default:
          from = this.get('from');
          to = this.get('to');
      }

      this.set('from', moment(from).format('YYYY-MM-DD'));
      return this.set('to', moment(to).format('YYYY-MM-DD'));
    })
  });

  _exports.default = _default;
});