define("api-dashboard/templates/components/inputs/collapsable-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "crHdVmk3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"<div class=\\\"panel-group\\\" id=\\\"accordion\\\">\"],[0,\"\\n  \"],[2,\"<div class=\\\"panel panel-default\\\">\"],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"data-toggle\",\"collapse\"],[10,\"data-parent\",\"#accordion\"],[11,\"href\",[29,[\"#collapse-\",[22,\"elementId\"]]]],[8],[0,\"\\n        \"],[1,[22,\"title\"],true],[7,\"i\",true],[10,\"class\",\"fa fa-caret-down posReport__panel__list--icon\"],[8],[9],[9],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"id\",[29,[\"collapse-\",[22,\"elementId\"]]]],[10,\"class\",\"panel-collapse collapse\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[2,\"</div>\"],[0,\"\\n\"],[2,\"</div>\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/collapsable-panel.hbs"
    }
  });

  _exports.default = _default;
});