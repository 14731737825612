define("api-dashboard/models/emitente", ["exports", "ember-data", "api-dashboard/mixins/formatters"], function (_exports, _emberData, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_formatters.default, {
    cnpj: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    nome: _emberData.default.attr('string'),
    regimeTributario: _emberData.default.attr('number'),
    uf: _emberData.default.attr('string'),
    nameAndDocument: _emberData.default.attr('string'),
    mostrarTipoVenda: _emberData.default.attr('boolean', {
      serialize: false
    }),
    defaultInformacoesAdicionais: _emberData.default.attr('string', {
      serialize: false
    }),
    obterDadosEntrada: _emberData.default.attr('boolean', {
      serialize: false
    }),
    mostrarDetalheTelaPrincipal: _emberData.default.attr('boolean', {
      serialize: false
    }),
    classeCalculoImpostos: _emberData.default.attr('string', {
      serialize: false
    }),
    empresaVeiculos: _emberData.default.attr('boolean', {
      serialize: false
    }),
    contract: _emberData.default.belongsTo('contract'),
    documento: Ember.computed('cnpj', 'cpf', function () {
      if (this.get('cnpj')) {
        return this.get('cnpj');
      } else {
        return this.get('cpf');
      }
    }),
    description: Ember.computed('nome', 'cnpj', 'cpf', function () {
      var retVal;
      retVal = this.get('nome');

      if (this.get('cnpj')) {
        retVal += " - " + this.formatCnpj(this.get('cnpj'));
      } else {
        retVal += " - " + this.formatCpf(this.get('cpf'));
      }

      return retVal;
    }),
    regimeNormal: Ember.computed('regimeTributario', function () {
      return this.get('regimeTributario') === 3;
    }),
    simplesNacional: Ember.computed('regimeNormal', function () {
      return this.get('regimeTributario') === 1;
    })
  });

  _exports.default = _default;
});