define("api-dashboard/models/nota-fiscal", ["exports", "ember-data", "api-dashboard/mixins/formatters"], function (_exports, _emberData, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend(_formatters.default, (_DS$Model$extend = {
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    reloaded: false,
    shouldShowErrors: true,
    isIssuing: false,
    canDuplicate: true,
    bairroEntrega: _emberData.default.attr('string'),
    bairroRetirada: _emberData.default.attr('string'),
    baseCalculoIrrf: _emberData.default.attr('number'),
    calculoEmAndamento: _emberData.default.attr('boolean'),
    capatazia: _emberData.default.attr('big'),
    chaveNfe: _emberData.default.attr('string'),
    cnpjEntrega: _emberData.default.attr('string'),
    cnpjRetirada: _emberData.default.attr('string'),
    complementoEntrega: _emberData.default.attr('string'),
    complementoRetirada: _emberData.default.attr('string'),
    consumidorFinal: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    cpfEntrega: _emberData.default.attr('string'),
    cpfRetirada: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    dataEmissao: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return moment().format("YYYY-MM-DD");
      }
    }),
    dataEntradaSaida: _emberData.default.attr('date'),
    localDestino: _emberData.default.attr('number'),
    domainId: _emberData.default.attr('number'),
    execAfterAutorizada: _emberData.default.attr('boolean'),
    execAfterCancelada: _emberData.default.attr('boolean'),
    finalidadeEmissao: _emberData.default.attr('number'),
    formaPagamento: _emberData.default.attr('number'),
    formatoImpressao: _emberData.default.attr('number'),
    horaEntradaSaida: _emberData.default.attr('date'),
    icmsBaseCalculo: _emberData.default.attr('big'),
    icmsBaseCalculoSt: _emberData.default.attr('big'),
    icmsValorTotal: _emberData.default.attr('big'),
    icmsValorTotalDesonerado: _emberData.default.attr('big'),
    icmsValorTotalFcp: _emberData.default.attr('big'),
    icmsValorTotalSt: _emberData.default.attr('big'),
    icmsValorTotalUfDestino: _emberData.default.attr('big'),
    icmsValorTotalUfRemetente: _emberData.default.attr('big'),
    informacoesAdicionaisContribuinte: _emberData.default.attr('string'),
    inscricaoEstadualStEmitente: _emberData.default.attr('string'),
    justificativaCancelamento: _emberData.default.attr('string'),
    localDespacho: _emberData.default.attr('string'),
    localEmbarque: _emberData.default.attr('string'),
    logradouroEntrega: _emberData.default.attr('string'),
    logradouroRetirada: _emberData.default.attr('string'),
    mensagemSefaz: _emberData.default.attr('string'),
    modalidadeFrete: _emberData.default.attr('number'),
    modeloNotaFiscalId: _emberData.default.attr('number'),
    naoCalcularImpostos: _emberData.default.attr('boolean'),
    naturezaOperacao: _emberData.default.attr('string'),
    numero: _emberData.default.attr('string'),
    numeroEntrega: _emberData.default.attr('string'),
    numeroFatura: _emberData.default.attr('string'),
    numeroRetirada: _emberData.default.attr('string'),
    presencaComprador: _emberData.default.attr('number', {
      defaultValue: 9
    }),
    prevSocialBaseCalculo: _emberData.default.attr('number'),
    prevSocialValorRetido: _emberData.default.attr('big'),
    remoteReference: _emberData.default.attr('string'),
    revenda: _emberData.default.attr('boolean'),
    saleId: _emberData.default.attr('number'),
    serie: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    statusSefaz: _emberData.default.attr('number'),
    taxaSiscomex: _emberData.default.attr('big'),
    tipoDocumento: _emberData.default.attr('number'),
    tipoVenda: _emberData.default.attr('string'),
    transporteIcmsAliquota: _emberData.default.attr('number'),
    transporteIcmsBaseCalculo: _emberData.default.attr('big'),
    transporteIcmsServico: _emberData.default.attr('big'),
    transporteIcmsValor: _emberData.default.attr('big'),
    ufLocalEmbarque: _emberData.default.attr('string'),
    ultimaEtapa: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    valorCofins: _emberData.default.attr('big'),
    valorCofinsServicos: _emberData.default.attr('big'),
    valorDesconto: _emberData.default.attr('big'),
    valorDescontoFatura: _emberData.default.attr('big'),
    valorFrete: _emberData.default.attr('big'),
    valorIpi: _emberData.default.attr('big'),
    valorLiquidoFatura: _emberData.default.attr('big', {
      precision: 6
    }),
    valorOriginalFatura: _emberData.default.attr('big', {
      precision: 6
    }),
    valorOutrasDespesas: _emberData.default.attr('big'),
    valorPis: _emberData.default.attr('big'),
    valorPisServicos: _emberData.default.attr('big'),
    valorProdutos: _emberData.default.attr('big'),
    valorRetidoCofins: _emberData.default.attr('big'),
    valorRetidoCsll: _emberData.default.attr('big'),
    valorRetidoIrrf: _emberData.default.attr('big'),
    valorRetidoPis: _emberData.default.attr('big'),
    valorSeguro: _emberData.default.attr('big'),
    valorTotal: _emberData.default.attr('big', {
      precision: 6
    }),
    valorTotalBruto: _emberData.default.attr('big', {
      precision: 6
    }),
    valorTotalIi: _emberData.default.attr('big'),
    valorTotalServicos: _emberData.default.attr('big'),
    veiculoIdentificacaoBalsa: _emberData.default.attr('string'),
    veiculoIdentificacaoVagao: _emberData.default.attr('string'),
    veiculoPlaca: _emberData.default.attr('string'),
    veiculoRntc: _emberData.default.attr('string'),
    veiculoTipo: _emberData.default.attr('string'),
    veiculoUf: _emberData.default.attr('string'),
    issqn_base_calculo: _emberData.default.attr('big'),
    issqn_valor_total: _emberData.default.attr('big'),
    issqn_valor_total_deducao: _emberData.default.attr('big'),
    issqn_valor_total_outras_retencoes: _emberData.default.attr('big'),
    issqn_valor_total_desconto_incondicionado: _emberData.default.attr('big'),
    issqn_valor_total_desconto_condicionado: _emberData.default.attr('big'),
    issqn_valor_total_retencao: _emberData.default.attr('big'),
    valor_total_servicos: _emberData.default.attr('big'),
    duplicatas: _emberData.default.hasMany('duplicata', {
      inverse: 'notaFiscal',
      async: false
    }),
    emitente: _emberData.default.belongsTo('emitente'),
    integrationMappings: _emberData.default.hasMany('integration-mapping', {
      async: false
    }),
    items: _emberData.default.hasMany('item', {
      inverse: 'notaFiscal'
    }),
    municipioEntrega: _emberData.default.belongsTo('municipio'),
    municipioRetirada: _emberData.default.belongsTo('municipio'),
    person: _emberData.default.belongsTo('customer'),
    referenciadas: _emberData.default.hasMany('referenciada', {
      inverse: 'notaFiscal',
      async: false
    }),
    transporteIcmsCfop: _emberData.default.belongsTo('cfop'),
    transporteIcmsMunicipio: _emberData.default.belongsTo('municipio'),
    transporter: _emberData.default.belongsTo('transporter'),
    volumes: _emberData.default.hasMany('volume', {
      inverse: 'notaFiscal',
      async: false
    }),
    posEntries: _emberData.default.hasMany('pos-entry'),
    descricaoCompleta: _emberData.default.attr('string'),
    etapa: _emberData.default.attr('string'),
    proximaEtapa: _emberData.default.attr('string')
  }, _defineProperty(_DS$Model$extend, "ultimaEtapa", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "js", _emberData.default.attr('boolean', {
    defaultValue: true
  })), _defineProperty(_DS$Model$extend, "cancelar", function cancelar(justificativa, onSuccess, onError) {
    var ajaxParams;
    ajaxParams = {
      type: "PUT",
      data: {
        cancel_reason: justificativa
      },
      dataType: "json"
    };
    return this.get("store").externalAjax(ajaxParams, {
      apiURL: "/notas_fiscais/" + this.get('id') + "/cancel_invoice.json"
    }, function (sucesso, textStatus, data) {
      var res;
      res = data.jqXHR.responseJSON;

      if (sucesso && onSuccess) {
        onSuccess(res);
      }

      if (!sucesso && onError) {
        return onError(res);
      }
    });
  }), _defineProperty(_DS$Model$extend, "descricaoStatus", Ember.computed('status', function () {
    switch (this.get('status')) {
      case 'pendente':
        return 'Pendente';

      case 'enviando':
        return 'Enviando';

      case 'em_processamento':
        return 'Em processamento';

      case 'autorizada':
        return 'Autorizada';

      case 'enviando_cancelamento':
        return 'Enviando cancelamento';

      case 'processando_cancelamento':
        return 'Processando cancelamento';

      case 'cancelada':
        return 'Cancelada';

      case 'denegada':
        return 'Denegada';

      case 'erro_autorizacao':
        return 'Erro de autorização';

      case 'erro_cancelamento':
        return 'Erro de cancelamento';
    }
  })), _defineProperty(_DS$Model$extend, "descricaoFormaPagamento", Ember.computed('formaPagamento', function () {
    switch (this.get('formaPagamento')) {
      case 0:
        return 'À vista';

      case 1:
        return 'À prazo';

      case 2:
        return 'Outros';

      default:
        return '';
    }
  })), _defineProperty(_DS$Model$extend, "descricaoModalidadeFrete", Ember.computed('modalidadeFrete', function () {
    switch (this.get('modalidadeFrete')) {
      case 0:
        return "Por conta do emitente";

      case 1:
        return "Por conta do destinatário/remetente";

      case 2:
        return "Por conta de terceiros";

      case 9:
        return "Sem frete";

      default:
        return '';
    }
  })), _defineProperty(_DS$Model$extend, "descricaoDuplicatasAmmountObserver", Ember.observer('duplicatas.@each._destroy', function () {
    var duplicatas, quantidadeDuplicatas, strQtdDuplicatas;
    duplicatas = this.get('duplicatas');
    quantidadeDuplicatas = duplicatas.filter(function (d) {
      return !d.get('_destroy');
    }).length;

    if (quantidadeDuplicatas === 0) {
      this.set('descricaoDuplicatasAmmount', 'Nenhuma duplicata');
      return;
    }

    strQtdDuplicatas = quantidadeDuplicatas + ' duplicata' + (quantidadeDuplicatas > 1 ? 's' : '');
    return this.set('descricaoDuplicatasAmmount', strQtdDuplicatas);
  })), _defineProperty(_DS$Model$extend, "transporterDescription", Ember.computed('transporter', function () {
    var text;
    text = this.get('transporter.name');

    if (text != null && text !== '') {
      text = 'Transportadora ' + text;
    }

    return text;
  })), _defineProperty(_DS$Model$extend, "nomeDestinatario", Ember.computed('person', function () {
    if (this.get('person')) {
      return this.get('person.nomeOuRazaoSocial');
    } else {
      return '';
    }
  })), _defineProperty(_DS$Model$extend, "nomeEmitente", Ember.computed('emitente', function () {
    var emitente, ret, uf;
    ret = '';
    emitente = this.get('emitente');

    if (emitente) {
      ret = emitente.get('nome');
      uf = emitente.get('uf');

      if (uf) {
        ret += ' (' + uf + ')';
      }
    }

    return ret;
  })), _defineProperty(_DS$Model$extend, "informarIcmsUfDestino", Ember.computed('person', 'emitente', function () {
    var emitente, person;
    emitente = this.get('emitente');
    person = this.get('person');

    if (emitente != null && person != null && (person.get('legalType') === this.get('constants').person.LEGAL_TYPE_NATURAL || person.get('isentoInscricaoEstadual')) && person.get('nfeAddressUf') != null) {
      return this.get('consumidorFinal') && person.get('nfeAddressUf') !== emitente.get('uf');
    }
  })), _defineProperty(_DS$Model$extend, "canEdit", Ember.computed('status', function () {
    return ['pendente', 'erro_autorizacao'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "isErroSefaz", Ember.computed('status', function () {
    return ['erro_cancelamento', 'erro_autorizacao'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "canDelete", Ember.computed('status', function () {
    return ['pendente', 'erro_autorizacao'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "canCancel", Ember.computed('status', function () {
    return ['autorizada', 'erro_cancelamento'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "canEmitirCCe", Ember.computed('status', function () {
    return ['autorizada', 'erro_cancelamento'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "isAutorizada", Ember.computed('status', function () {
    return ['autorizada'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "isCancelada", Ember.computed('status', function () {
    return ['cancelada'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "isEmProcessamento", Ember.computed('status', function () {
    return this.get('isIssuing') || ['em_processamento', 'processando_cancelamento'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "nfUrl", Ember.computed('id', function () {
    return this.get("store").adapterFor("application").get("host") + '/notas_fiscais/' + this.get("id");
  })), _defineProperty(_DS$Model$extend, "canGetDanfe", Ember.computed('status', function () {
    return ['autorizada', 'erro_cancelamento'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "danfeUrl", Ember.computed('nfUrl', 'canGetDanfe', function () {
    return this.get('nfUrl') + '.pdf';
  })), _defineProperty(_DS$Model$extend, "canGetBoletos", Ember.computed('canGetDanfe', 'duplicatas', function () {
    return this.get('canGetDanfe') && this.get('duplicatas.length') > 0;
  })), _defineProperty(_DS$Model$extend, "canGetXml", Ember.computed('status', function () {
    return ['autorizada', 'erro_cancelamento', 'cancelada'].includes(this.get('status'));
  })), _defineProperty(_DS$Model$extend, "cannotIssue", Ember.computed('isIssuing', 'isSaving', 'status', function () {
    if (this.get('isIssuing')) {
      return true;
    }

    if (this.get('isSaving')) {
      return true;
    }

    if (this.get('isAutorizada')) {
      return true;
    }

    if (this.get('isEmProcessamento')) {
      return true;
    }

    if (this.get('persistedItems.length') < 1) {
      return true;
    }

    return false;
  })), _defineProperty(_DS$Model$extend, "canIssue", Ember.computed('canIssue', function () {
    return !this.get('cannotIssue');
  })), _defineProperty(_DS$Model$extend, "xmlUrl", Ember.computed('nfUrl', 'canGetXml', function () {
    return this.get('nfUrl') + '.xml';
  })), _defineProperty(_DS$Model$extend, "canDownload", Ember.computed('canGetDanfe', 'canGetBoletos', 'canGetXml', function () {
    return this.get('canGetDanfe') || this.get('canGetBoletos') || this.get('canGetXml');
  })), _defineProperty(_DS$Model$extend, "estadoIdentificacao", Ember.computed('naturezaOperacao', 'emitente', 'person', 'tipoDocumento', 'dataEmissao', 'errors.naturezaOperacao', 'errors.emitente', 'errors.person', 'errors.tipoDocumento', 'errors.dataEmissao', function () {
    var addressErrors;

    if (this.get('errors.naturezaOperacao') || this.get('errors.emitente') || this.get('errors.person') || this.get('errors.tipoDocumento') || this.get('errors.dataEmissao')) {
      return 'error';
    }

    addressErrors = this.get('person.invalidAddressesForNFe');
    addressErrors = addressErrors || this.get('person.invalidAddressesForNFe');

    if (addressErrors && addressErrors.length > 0) {
      return 'error';
    }

    if (!Ember.isEmpty(this.get('naturezaOperacao')) && this.get('emitente.id') && this.get('person.id') && !Ember.isEmpty(this.get('tipoDocumento')) && !Ember.isEmpty(this.get('dataEmissao'))) {
      return 'success';
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "estadoItens", Ember.computed('persistedItems.length', 'errors.itensETotais', function () {
    if (this.get('errors.itensETotais')) {
      return 'error';
    }

    if (this.get('persistedItems.length') > 0) {
      return 'success';
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "estadoImportacao", Ember.computed('persistedItems.length', 'errors.itensETotais', 'items.@each.isDirty', function () {
    var anyDirtyItem;
    anyDirtyItem = this.get('items').any(function (i) {
      return i.get('isDirty');
    }) || this.get('items.length') < 1;

    if (anyDirtyItem) {
      return '';
    }

    this.get('globalLookups').getDomainConfigValue('emissor_nfe.importacao.mostrar_aba_importacao', null, null).then(function (v) {
      if (v) {
        if (Boolean(parseInt(v.value))) {
          return 'success';
        }
      }

      return 'error';
    });
    return 'success';
  })), _defineProperty(_DS$Model$extend, "estadoCobranca", Ember.computed('formaPagamento', 'errors.formaPagamento', 'items.@each.isDirty', 'numeroFatura', 'valorOriginalFatura', 'valorLiquidoFatura', 'posEntries.length', function () {
    var anyDirtyItem;
    anyDirtyItem = this.get('items').any(function (i) {
      return i.get('isDirty');
    }) || this.get('items.length') < 1;

    if (anyDirtyItem) {
      return '';
    }

    if (this.get('errors.formaPagamento')) {
      return 'error';
    }

    if (!Ember.isEmpty(this.get('formaPagamento')) || this.get('posEntries.length') > 0) {
      return 'success';
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "estadoTransporte", Ember.computed('modalidadeFrete', 'errors.modalidadeFrete', 'items.@each.isDirty', function () {
    var anyDirtyItem;
    anyDirtyItem = this.get('items').any(function (i) {
      return i.get('isDirty');
    }) || this.get('items.length') < 1;

    if (anyDirtyItem) {
      return '';
    }

    if (this.get('errors.modalidadeFrete')) {
      return 'error';
    }

    if (this.get('modalidadeFrete') != null) {
      return 'success';
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "persistedItems", Ember.computed('items', 'items.@each.isNew', function () {
    return this.get('items').filter(function (item) {
      return !item.get('isNew');
    });
  })), _defineProperty(_DS$Model$extend, "presencaCompradorLabel", Ember.computed('presencaComprador', function () {
    var presencaComprador, self;
    self = this;
    presencaComprador = this.get('constants.presencaCompradorValues').find(function (e) {
      return e.value === self.get('presencaComprador');
    });

    if (presencaComprador) {
      return presencaComprador.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "finalidadeEmissaoLabel", Ember.computed('finalidadeEmissao', function () {
    var finalidadeEmissao, self;
    self = this;
    finalidadeEmissao = this.get('constants.finalidadeEmissaoValues').find(function (e) {
      return e.value === self.get('finalidadeEmissao');
    });

    if (finalidadeEmissao) {
      return finalidadeEmissao.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "tipoDocumentoLabel", Ember.computed('tipoDocumento', function () {
    var self, tipoDocumento;
    self = this;
    tipoDocumento = this.get('constants.tipoDocumentoValues').find(function (e) {
      return e.value === self.get('tipoDocumento');
    });

    if (tipoDocumento != null) {
      return tipoDocumento.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "formaPagamentoLabel", Ember.computed('formaPagamento', function () {
    var formaPagamento, self;
    self = this;
    formaPagamento = this.get('constants.formaPagamentoValues').find(function (e) {
      return e.value === self.get('formaPagamento');
    });

    if (formaPagamento != null) {
      return formaPagamento.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "modalidadeFreteLabel", Ember.computed('modalidadeFrete', function () {
    var modalidadeFrete, self;
    self = this;
    modalidadeFrete = this.get('constants.modalidadeFreteValues').find(function (e) {
      return e.value === self.get('modalidadeFrete');
    });

    if (modalidadeFrete != null) {
      return modalidadeFrete.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "veiculoTipoLabel", Ember.computed('veiculoTipo', function () {
    var self, veiculo;
    self = this;
    veiculo = this.get('constants.veiculoValues').find(function (e) {
      return e.value === self.get('veiculoTipo');
    });

    if (veiculo != null) {
      return veiculo.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "veiculoTipoIsVeiculo", Ember.computed('veiculoTipo', function () {
    return this.get('veiculoTipo') === 'veiculo';
  })), _defineProperty(_DS$Model$extend, "veiculoTipoIsVagao", Ember.computed('veiculoTipo', function () {
    return this.get('veiculoTipo') === 'vagao';
  })), _defineProperty(_DS$Model$extend, "veiculoTipoIsBalsa", Ember.computed('veiculoTipo', function () {
    return this.get('veiculoTipo') === 'balsa';
  })), _defineProperty(_DS$Model$extend, "tipoVendaLabel", Ember.computed('tipoVenda', function () {
    var self, tipoVenda;
    self = this;
    tipoVenda = this.get('constants.tipoVendaValues').find(function (e) {
      return e.value === self.get('tipoVenda');
    });

    if (tipoVenda != null) {
      return tipoVenda.description;
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "personFisica", Ember.computed('person', 'person.legalType', function () {
    return this.get('person.legalType') === this.get('constants').person.LEGAL_TYPE_NATURAL;
  })), _defineProperty(_DS$Model$extend, "personJuridica", Ember.computed('person', 'person.legalType', function () {
    return this.get('person.legalType') === this.get('constants').person.LEGAL_TYPE_LEGAL;
  })), _defineProperty(_DS$Model$extend, "dataEmissaoFmt", Ember.computed('dataEmissao', function () {
    return this.formatDate(this.get('dataEmissao'));
  })), _defineProperty(_DS$Model$extend, "createdAtFmt", Ember.computed('createdAt', function () {
    return this.formatDate(this.get('createdAt'));
  })), _defineProperty(_DS$Model$extend, "itensObserver", Ember.observer('items.[]', 'persistedItems.[]', function () {
    if (this.get('items.length') < 1 || this.get('persistedItems.length') < 1) {
      this.set('valorDesconto', null);
      this.set('valorDescontoFatura', null);
      this.set('valorLiquidoFatura', null);
      return this.set('valorOriginalFatura', null);
    }
  })), _defineProperty(_DS$Model$extend, "finalidadeDevolucao", Ember.computed('finalidadeEmissao', function () {
    return this.get('finalidadeEmissao') === 4;
  })), _DS$Model$extend));

  _exports.default = _default;
});