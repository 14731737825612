define("api-dashboard/templates/partials/components/inputs/autocomplete/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOKMAYeA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"align\",\"center\"],[10,\"class\",\"fond\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"spinner-wrap\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"spinner\"],[8],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/inputs/autocomplete/loading.hbs"
    }
  });

  _exports.default = _default;
});