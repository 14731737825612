define("api-dashboard/helpers/format-decimal.js", [], function () {
  "use strict";

  window.printNumber = function (value, decimals) {
    decimals = !isNaN(parseInt(decimals)) ? decimals : 2;

    if (!(value instanceof Big)) {
      value = parseFloat(value);

      if (isNaN(value)) {
        return '';
      }
    }

    return value.toFixed(decimals).replace('.', ',');
  };

  Ember.Helper.helper('format-decimal', function (value, options) {
    return printNumber(value, options.hash.decimals);
  });
});