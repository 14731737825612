define("api-dashboard/components/formularios/simple-tax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    className: 'form-group',
    tagName: 'div',
    taxRates: null,
    filteredTaxRates: function filteredTaxRates() {
      return this.get('taxRates').filter(function (item, index, self) {
        return !item.get('_destroy');
      });
    },
    didInsertElement: function didInsertElement() {
      var self, ufs;
      ufs = this.get('application.currentDomain.visibleStores').map(function (item) {
        return item.get('siglaUf');
      }).uniq();
      self = this;
      return ufs.forEach(function (item, index) {
        if (item !== null) {
          if (self.get('taxRates').findBy('siglaUf', item)) {
            return;
          }

          return self.get('taxRates').createRecord({
            siglaUf: item,
            taxType: 'I'
          });
        }
      });
    }
  });

  _exports.default = _default;
});