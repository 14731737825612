define("api-dashboard/components/inputs/input-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ["name", "type", "value", "checked:checked"],
    tagName: "input",
    type: "radio",
    click: function click() {
      return this.set("selection", this.$().val());
    },
    checked: Ember.computed(function () {
      return this.get("value") === this.get("selection");
    })
  });

  _exports.default = _default;
});