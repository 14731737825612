define("api-dashboard/controllers/labs", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formatters.default, {
    stats: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      console.log('init-labs');
      this.getData();
      return this._super.apply(this, arguments);
    },
    getData: function getData() {
      var a, columns, i, json_keys, key, len, stringfied_date;
      a = [{
        "contaContabil": {
          "nome": "1 - Receitas Operacionais",
          "tipo": "RECEITA",
          "id": 2,
          "folha": false,
          "children": [{
            "contaContabil": {
              "nome": "1.01 - Receita Sistemas",
              "tipo": "RECEITA",
              "id": 3,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "1.01.01 - Receitas Mensalidades",
                  "tipo": "RECEITA",
                  "id": 4,
                  "folha": true,
                  "children": []
                },
                "r20190301": "115017.71",
                "p20190301": 1.0,
                "r20190401": "124813.19",
                "p20190401": 1.0,
                "r_resumo": "239830.9",
                "p_resumo": 1.0
              }, {
                "contaContabil": {
                  "nome": "1.01.02 - Receitas Taxas de Instala\xE7\xE3o",
                  "tipo": "RECEITA",
                  "id": 5,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "1.01.03 - Receitas Comiss\xF5es",
                  "tipo": "RECEITA",
                  "id": 6,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "r20190301": "115017.71",
            "p20190301": 1.0,
            "r20190401": "124813.19",
            "p20190401": 1.0,
            "r_resumo": "239830.9",
            "p_resumo": 1.0
          }, {
            "contaContabil": {
              "nome": "1.02 - Receitas de Vendas de Servi\xE7os",
              "tipo": "RECEITA",
              "id": 7,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "1.02.01 - Receitas Consultorias",
                  "tipo": "RECEITA",
                  "id": 8,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "1.02.02 - Receitas Sistemas Fechados",
                  "tipo": "RECEITA",
                  "id": 9,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }]
        },
        "r20190301": "115017.71",
        "p20190301": 1.0,
        "r20190401": "124813.19",
        "p20190401": 1.0,
        "r_resumo": "239830.9",
        "p_resumo": 1.0
      }, {
        "contaContabil": {
          "nome": "2 - Despesas Operacionais",
          "tipo": "DESPESA",
          "id": 26,
          "folha": false,
          "children": [{
            "contaContabil": {
              "nome": "2.01 - Tributos e Impostos",
              "tipo": "DESPESA",
              "id": 27,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.01.01 - Simples",
                  "tipo": "DESPESA",
                  "id": 28,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-12652.64",
                "p20190301": 0.11000601559533743,
                "r20190401": "-14136.21",
                "p20190401": 0.1132589432254716,
                "r_resumo": "-26788.85",
                "p_resumo": 0.11169890952333499
              }, {
                "contaContabil": {
                  "nome": "2.01.02 - Outros gastos com impostos e tributos",
                  "tipo": "DESPESA",
                  "id": 29,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "r20190301": "-12652.64",
            "p20190301": 0.11000601559533743,
            "r20190401": "-14136.21",
            "p20190401": 0.1132589432254716,
            "r_resumo": "-26788.85",
            "p_resumo": 0.11169890952333499
          }, {
            "contaContabil": {
              "nome": "2.02 - Servi\xE7os de terceiros",
              "tipo": "DESPESA",
              "id": 30,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.02.01 - Programador",
                  "tipo": "DESPESA",
                  "id": 31,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.02.02 - Designers",
                  "tipo": "DESPESA",
                  "id": 32,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.02.03 - Consultorias T\xE9cnicas de SW",
                  "tipo": "DESPESA",
                  "id": 33,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.02.06 - Outros fornecedores",
                  "tipo": "DESPESA",
                  "id": 35,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.02.05 - Loca\xE7\xE3o de Software",
                  "tipo": "DESPESA",
                  "id": 31835,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-1313.51",
                "p20190301": 0.011420067396577449,
                "r20190401": "-1288.91",
                "p20190401": 0.01032671306614309,
                "r_resumo": "-2602.42",
                "p_resumo": 0.010851062144202436
              }]
            },
            "r20190301": "-1313.51",
            "p20190301": 0.011420067396577449,
            "r20190401": "-1288.91",
            "p20190401": 0.01032671306614309,
            "r_resumo": "-2602.42",
            "p_resumo": 0.010851062144202436
          }, {
            "contaContabil": {
              "nome": "2.03 - M\xE3o de Obra / Funcion\xE1rios",
              "tipo": "DESPESA",
              "id": 36,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.03.01 - Despesas com pessoal",
                  "tipo": "DESPESA",
                  "id": 37,
                  "folha": false,
                  "children": [{
                    "contaContabil": {
                      "nome": "2.03.01.01 - Sal\xE1rios",
                      "tipo": "DESPESA",
                      "id": 38,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-43024.77",
                    "p20190301": 0.37407082787511586,
                    "r20190401": "-43524.77",
                    "p20190401": 0.34871931404044715,
                    "r_resumo": "-86549.54",
                    "p_resumo": 0.3608773515005781
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.02 - Comiss\xF5es para funcion\xE1rios",
                      "tipo": "DESPESA",
                      "id": 39,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.03 - Horas Extras",
                      "tipo": "DESPESA",
                      "id": 40,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.04 - F\xE9rias",
                      "tipo": "DESPESA",
                      "id": 41,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "r20190401": "-981.34",
                    "p20190401": 0.00786247030462085,
                    "r_resumo": "-981.34",
                    "p_resumo": 0.004091799680524903
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.05 - D\xE9cimo Terceiro Sal\xE1rio",
                      "tipo": "DESPESA",
                      "id": 42,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.06 - Pens\xE3o aliment\xEDcia / Sal\xE1rio Educa\xE7\xE3o",
                      "tipo": "DESPESA",
                      "id": 43,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.07 - Abono / Gratifica\xE7\xF5es",
                      "tipo": "DESPESA",
                      "id": 44,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.08 - Rescis\xF5es",
                      "tipo": "DESPESA",
                      "id": 45,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.09 - Reclamat\xF3rias Trabalhistas",
                      "tipo": "DESPESA",
                      "id": 46,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.10 - Estagi\xE1rios",
                      "tipo": "DESPESA",
                      "id": 47,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "r20190401": "-50.0",
                    "p20190401": 0.0004005986867253373,
                    "r_resumo": "-50.0",
                    "p_resumo": 0.0002084802250252157
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.11 - EPI\xB4s",
                      "tipo": "DESPESA",
                      "id": 48,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.01.12 - Outras despesas com pessoal",
                      "tipo": "DESPESA",
                      "id": 49,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }]
                },
                "r20190301": "-43024.77",
                "p20190301": 0.37407082787511586,
                "r20190401": "-44556.11",
                "p20190401": 0.35698238303179336,
                "r_resumo": "-87580.88",
                "p_resumo": 0.36517763140612824
              }, {
                "contaContabil": {
                  "nome": "2.03.02 - Encargos de pessoal",
                  "tipo": "DESPESA",
                  "id": 50,
                  "folha": false,
                  "children": [{
                    "contaContabil": {
                      "nome": "2.03.02.01 - INSS Funcion\xE1rios e Pr\xF3-labore",
                      "tipo": "DESPESA",
                      "id": 51,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-1841.9",
                    "p20190301": 0.016014055574571952,
                    "r20190401": "-1495.4",
                    "p20190401": 0.011981105522581388,
                    "r_resumo": "-3337.3",
                    "p_resumo": 0.013915221099533047
                  }, {
                    "contaContabil": {
                      "nome": "2.03.02.02 - FGTS",
                      "tipo": "DESPESA",
                      "id": 52,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-1311.84",
                    "p20190301": 0.011405547893450494,
                    "r20190401": "-1311.84",
                    "p20190401": 0.01051042762387533,
                    "r_resumo": "-2623.68",
                    "p_resumo": 0.010939707935883157
                  }, {
                    "contaContabil": {
                      "nome": "2.03.02.03 - Benef\xEDcios",
                      "tipo": "DESPESA",
                      "id": 53,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.02.04 - Contribui\xE7\xE3o sindical",
                      "tipo": "DESPESA",
                      "id": 54,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.02.05 - Outras contribui\xE7\xF5es",
                      "tipo": "DESPESA",
                      "id": 55,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.02.06 - IRRF",
                      "tipo": "DESPESA",
                      "id": 41122,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-474.58",
                    "p20190301": 0.004126147182029619,
                    "r20190401": "-474.58",
                    "p20190401": 0.003802322494922211,
                    "r_resumo": "-949.16",
                    "p_resumo": 0.003957621807698674
                  }]
                },
                "r20190301": "-3628.32",
                "p20190301": 0.031545750650052065,
                "r20190401": "-3281.82",
                "p20190401": 0.02629385564137893,
                "r_resumo": "-6910.14",
                "p_resumo": 0.02881255084311488
              }, {
                "contaContabil": {
                  "nome": "2.03.03 - Benef\xEDcios de pessoal",
                  "tipo": "DESPESA",
                  "id": 56,
                  "folha": false,
                  "children": [{
                    "contaContabil": {
                      "nome": "2.03.03.01 - Vale Transporte",
                      "tipo": "DESPESA",
                      "id": 57,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-200.04",
                    "p20190301": 0.0017392104224645056,
                    "r20190401": "-346.08",
                    "p20190401": 0.0027727838700380945,
                    "r_resumo": "-546.12",
                    "p_resumo": 0.002277104409815416
                  }, {
                    "contaContabil": {
                      "nome": "2.03.03.02 - Vale Alimenta\xE7\xE3o",
                      "tipo": "DESPESA",
                      "id": 58,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-2659.75",
                    "p20190301": 0.02312469966581668,
                    "r20190401": "-2549.75",
                    "p20190401": 0.020428530029558575,
                    "r_resumo": "-5209.5",
                    "p_resumo": 0.02172155464537722
                  }, {
                    "contaContabil": {
                      "nome": "2.03.03.03 - Seguro de vida funcion\xE1rios",
                      "tipo": "DESPESA",
                      "id": 59,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.03.04 - Outros benef\xEDcios",
                      "tipo": "DESPESA",
                      "id": 60,
                      "folha": true,
                      "children": []
                    },
                    "p20190301": 0,
                    "r20190301": 0,
                    "p20190401": 0,
                    "r20190401": 0,
                    "p_resumo": 0,
                    "r_resumo": 0
                  }, {
                    "contaContabil": {
                      "nome": "2.03.03.05 - Plano de Sa\xFAde",
                      "tipo": "DESPESA",
                      "id": 49402,
                      "folha": true,
                      "children": []
                    },
                    "r20190301": "-698.82",
                    "p20190301": 0.006075759985136202,
                    "r20190401": "-1181.74",
                    "p20190401": 0.009468069841016,
                    "r_resumo": "-1880.56",
                    "p_resumo": 0.007841191439468391
                  }]
                },
                "r20190301": "-3558.61",
                "p20190301": 0.03093967007341739,
                "r20190401": "-4077.57",
                "p20190401": 0.03266938374061267,
                "r_resumo": "-7636.18",
                "p_resumo": 0.03183985049466103
              }]
            },
            "r20190301": "-50211.7",
            "p20190301": 0.43655624859858533,
            "r20190401": "-51915.5",
            "p20190401": 0.41594562241378497,
            "r_resumo": "-102127.2",
            "p_resumo": 0.42583003274390413
          }, {
            "contaContabil": {
              "nome": "2.04 - Despesas de Localiza\xE7\xE3o",
              "tipo": "DESPESA",
              "id": 61,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.04.01 - Alugu\xE9is",
                  "tipo": "DESPESA",
                  "id": 62,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-2829.01",
                "p20190301": 0.024596299126456264,
                "r20190401": "-2829.01",
                "p20190401": 0.02266595381465693,
                "r_resumo": "-5658.02",
                "p_resumo": 0.02359170565594342
              }, {
                "contaContabil": {
                  "nome": "2.04.02 - IPTU",
                  "tipo": "DESPESA",
                  "id": 63,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.04.03 - \xC1gua",
                  "tipo": "DESPESA",
                  "id": 64,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.04.04 - Energia El\xE9trica",
                  "tipo": "DESPESA",
                  "id": 65,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-425.86",
                "p20190301": 0.003702560240505571,
                "r20190401": "-329.99",
                "p20190401": 0.002643871212649881,
                "r_resumo": "-755.85",
                "p_resumo": 0.0031515955617061856
              }, {
                "contaContabil": {
                  "nome": "2.04.05 - Seguros",
                  "tipo": "DESPESA",
                  "id": 66,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.04.06 - Outras despesas de localiza\xE7\xE3o",
                  "tipo": "DESPESA",
                  "id": 67,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.04.07 - Condom\xEDnio",
                  "tipo": "DESPESA",
                  "id": 49460,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-1100.0",
                "p20190301": 0.009563744574639852,
                "r20190401": "-1100.0",
                "p20190401": 0.00881317110795742,
                "r_resumo": "-2200.0",
                "p_resumo": 0.00917312990110949
              }, {
                "contaContabil": {
                  "nome": "2.04.06 - Diarista",
                  "tipo": "DESPESA",
                  "id": 90,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-907.0",
                "p20190301": 0.007885742117453041,
                "r20190401": "-738.0",
                "p20190401": 0.005912836616065978,
                "r_resumo": "-1645.0",
                "p_resumo": 0.0068589994033295965
              }]
            },
            "r20190301": "-5261.87",
            "p20190301": 0.04574834605905473,
            "r20190401": "-4997.0",
            "p20190401": 0.04003583275133021,
            "r_resumo": "-10258.87",
            "p_resumo": 0.042775430522088695
          }, {
            "contaContabil": {
              "nome": "2.05 - Despesas de Comunica\xE7\xE3o",
              "tipo": "DESPESA",
              "id": 68,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.05.01 - Telefone Fixo",
                  "tipo": "DESPESA",
                  "id": 69,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.05.02 - Telefone M\xF3vel",
                  "tipo": "DESPESA",
                  "id": 70,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-200.0",
                "p20190301": 0.0017388626499345186,
                "r20190401": "-200.0",
                "p20190401": 0.0016023947469013492,
                "r_resumo": "-400.0",
                "p_resumo": 0.0016678418002017256
              }, {
                "contaContabil": {
                  "nome": "2.05.03 - Internet",
                  "tipo": "DESPESA",
                  "id": 71,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-199.9",
                "p20190301": 0.0017379932186095514,
                "r20190401": "-199.9",
                "p20190401": 0.0016015935495278986,
                "r_resumo": "-399.8",
                "p_resumo": 0.0016670078793016246
              }, {
                "contaContabil": {
                  "nome": "2.05.04 - Correio",
                  "tipo": "DESPESA",
                  "id": 72,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-13.6",
                "p20190301": 0.00011824266019554726,
                "r20190401": "-13.6",
                "p20190401": 0.00010896284278929174,
                "r_resumo": "-27.2",
                "p_resumo": 0.00011341324241371733
              }, {
                "contaContabil": {
                  "nome": "2.05.05 - Outras despesas de comunica\xE7\xE3o",
                  "tipo": "DESPESA",
                  "id": 73,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-116.0",
                "p20190301": 0.0010085403369620208,
                "p20190401": 0,
                "r20190401": 0,
                "r_resumo": "-116.0",
                "p_resumo": 0.0004836741220585004
              }]
            },
            "r20190301": "-529.5",
            "p20190301": 0.004603638865701638,
            "r20190401": "-413.5",
            "p20190401": 0.0033129511392185392,
            "r_resumo": "-943.0",
            "p_resumo": 0.003931937043975568
          }, {
            "contaContabil": {
              "nome": "2.07 - Despesas com Manuten\xE7\xF5es",
              "tipo": "DESPESA",
              "id": 74,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.07.02 - Manuten\xE7\xF5es de Inform\xE1tica",
                  "tipo": "DESPESA",
                  "id": 75,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.07.03 - Manut. Im\xF3veis",
                  "tipo": "DESPESA",
                  "id": 76,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-449.0",
                "p20190301": 0.0039037466491029945,
                "p20190401": 0,
                "r20190401": 0,
                "r_resumo": "-449.0",
                "p_resumo": 0.001872152420726437
              }, {
                "contaContabil": {
                  "nome": "2.07.04 - Outras Desp. De Manuten\xE7\xE3o ",
                  "tipo": "DESPESA",
                  "id": 77,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "r20190301": "-449.0",
            "p20190301": 0.0039037466491029945,
            "p20190401": 0,
            "r20190401": 0,
            "r_resumo": "-449.0",
            "p_resumo": 0.001872152420726437
          }, {
            "contaContabil": {
              "nome": "2.08 - Despesas de Comercializa\xE7\xE3o",
              "tipo": "DESPESA",
              "id": 78,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.08.01 - Comiss\xF5es Representantes",
                  "tipo": "DESPESA",
                  "id": 79,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-1130.83",
                "p20190301": 0.009831790252127258,
                "r20190401": "-30.0",
                "p20190401": 0.00024035921203520238,
                "r_resumo": "-1160.83",
                "p_resumo": 0.004840201992320423
              }, {
                "contaContabil": {
                  "nome": "2.08.02 - Comiss\xF5es diversas",
                  "tipo": "DESPESA",
                  "id": 80,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.08.05 - Marketing e Vendas",
                  "tipo": "DESPESA",
                  "id": 81,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.08.06 - Publicidade e Propaganda",
                  "tipo": "DESPESA",
                  "id": 82,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-2590.61",
                "p20190301": 0.022523574847734318,
                "r20190401": "-1627.0",
                "p20190401": 0.013035481266042474,
                "r_resumo": "-4217.61",
                "p_resumo": 0.017585765637372
              }, {
                "contaContabil": {
                  "nome": "2.08.07 - Outras Despesas Comerciais",
                  "tipo": "DESPESA",
                  "id": 83,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.08.08 - Confec\xE7\xE3o de Brindes",
                  "tipo": "DESPESA",
                  "id": 20979,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-233.99",
                "p20190301": 0.0020343823572908904,
                "p20190401": 0,
                "r20190401": 0,
                "r_resumo": "-233.99",
                "p_resumo": 0.0009756457570730045
              }, {
                "contaContabil": {
                  "nome": "2.08.09 - Viagens comerciais",
                  "tipo": "DESPESA",
                  "id": 49549,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-2074.94",
                "p20190301": 0.018040178334275653,
                "r20190401": "-2135.67",
                "p20190401": 0.01711093194557402,
                "r_resumo": "-4210.61",
                "p_resumo": 0.017556578405868467
              }]
            },
            "r20190301": "-6030.37",
            "p20190301": 0.05242992579142811,
            "r20190401": "-3792.67",
            "p20190401": 0.0303867724236517,
            "r_resumo": "-9823.04",
            "p_resumo": 0.0409581917926339
          }, {
            "contaContabil": {
              "nome": "2.09 - Despesas Administrativas",
              "tipo": "DESPESA",
              "id": 84,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.09.01 - Material de Expediente",
                  "tipo": "DESPESA",
                  "id": 85,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-128.85",
                "p20190301": 0.0011202622622203135,
                "r20190401": "-111.9",
                "p20190401": 0.0008965398608913049,
                "r_resumo": "-240.75",
                "p_resumo": 0.0010038322834964135
              }, {
                "contaContabil": {
                  "nome": "2.09.02 - Material de uso e consumo",
                  "tipo": "DESPESA",
                  "id": 86,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-446.89",
                "p20190301": 0.0038854016481461853,
                "r20190401": "-1032.18",
                "p20190401": 0.008269799049283173,
                "r_resumo": "-1479.07",
                "p_resumo": 0.006167136928560915
              }, {
                "contaContabil": {
                  "nome": "2.09.03 - Honor\xE1rios Contador",
                  "tipo": "DESPESA",
                  "id": 87,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-472.9",
                "p20190301": 0.004111540735770169,
                "r20190401": "-186.9",
                "p20190401": 0.0014974378909793108,
                "r_resumo": "-659.8",
                "p_resumo": 0.002751105049432746
              }, {
                "contaContabil": {
                  "nome": "2.09.04 - Consultorias e Acessorias",
                  "tipo": "DESPESA",
                  "id": 88,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-650.0",
                "p20190301": 0.005651303612287186,
                "p20190401": 0,
                "r20190401": 0,
                "r_resumo": "-650.0",
                "p_resumo": 0.002710242925327804
              }, {
                "contaContabil": {
                  "nome": "2.09.05 - Despesas de Viagem",
                  "tipo": "DESPESA",
                  "id": 89,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.09.07 - Retiradas e Pr\xF3-Labore S\xF3cios",
                  "tipo": "DESPESA",
                  "id": 91,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.09.08 - Despesas c/Cart\xF3rio",
                  "tipo": "DESPESA",
                  "id": 92,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "2.09.09 - Outras Despesas Administrativas",
                  "tipo": "DESPESA",
                  "id": 93,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-4752.44",
                "p20190301": 0.041319202060274014,
                "r20190401": "-2323.2",
                "p20190401": 0.01861341738000607,
                "r_resumo": "-7075.64",
                "p_resumo": 0.029502620387948344
              }, {
                "contaContabil": {
                  "nome": "2.09.10 - Treinamentos e Capacita\xE7\xF5es",
                  "tipo": "DESPESA",
                  "id": 1217,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "r20190401": "-482.65",
                "p20190401": 0.0038669791229596806,
                "r_resumo": "-482.65",
                "p_resumo": 0.002012459612168407
              }]
            },
            "r20190301": "-6451.08",
            "p20190301": 0.05608771031869787,
            "r20190401": "-4136.83",
            "p20190401": 0.03314417330411954,
            "r_resumo": "-10587.91",
            "p_resumo": 0.044147397186934625
          }, {
            "contaContabil": {
              "nome": "2.10 - DevOps",
              "tipo": "DESPESA",
              "id": 41128,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "2.10.04 - Servidores",
                  "tipo": "DESPESA",
                  "id": 34,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-3237.5",
                "p20190301": 0.02814783914581502,
                "r20190401": "-3600.0",
                "p20190401": 0.028843105444224283,
                "r_resumo": "-6837.5",
                "p_resumo": 0.028509670772198244
              }, {
                "contaContabil": {
                  "nome": "2.10.05 - Monitoramento",
                  "tipo": "DESPESA",
                  "id": 41129,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-77.3",
                "p20190301": 0.0006720704141996915,
                "r20190401": "-78.35",
                "p20190401": 0.0006277381420986035,
                "r_resumo": "-155.65",
                "p_resumo": 0.0006489989405034965
              }, {
                "contaContabil": {
                  "nome": "2.10.06 - Servi\xE7os transacionais (email, sms, etc)",
                  "tipo": "DESPESA",
                  "id": 41130,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-151.41",
                "p20190301": 0.0013164059691329274,
                "r20190401": "-131.38",
                "p20190401": 0.0010526131092394961,
                "r_resumo": "-282.79",
                "p_resumo": 0.001179122456697615
              }]
            },
            "r20190301": "-3466.21",
            "p20190301": 0.03013631552914764,
            "r20190401": "-3809.73",
            "p20190401": 0.030523456695562383,
            "r_resumo": "-7275.94",
            "p_resumo": 0.030337792169399354
          }]
        },
        "r20190301": "-86365.88",
        "p20190301": 0.7508920148036333,
        "r20190401": "-84490.35",
        "p20190401": 0.676934465019282,
        "r_resumo": "-170856.23",
        "p_resumo": 0.7124029055472002
      }, {
        "contaContabil": {
          "nome": "Resultado Operacional de Caixa",
          "tipo": null,
          "id": -1,
          "folha": true,
          "children": []
        },
        "r20190301": "28651.83",
        "p20190301": 0.2491079851963667,
        "r20190401": "40322.84",
        "p20190401": 0.32306553498071794,
        "r_resumo": "68974.67",
        "p_resumo": 0.28759709445279985
      }, {
        "contaContabil": {
          "nome": "3 - Receitas N\xE3o Operacionais",
          "tipo": "RECEITA",
          "id": 10,
          "folha": false,
          "children": [{
            "contaContabil": {
              "nome": "3.01 - Empr\xE9stimos e Financiamentos",
              "tipo": "RECEITA",
              "id": 11,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "3.01.01 - Emprestimos Capital de Giro - C.Prazo",
                  "tipo": "RECEITA",
                  "id": 12,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.01.02 - Cr\xE9dito Rotativo",
                  "tipo": "RECEITA",
                  "id": 13,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.01.03 - Cheque Especial",
                  "tipo": "RECEITA",
                  "id": 14,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.01.04 - Descontos de duplicatas e antecipa\xE7\xF5es",
                  "tipo": "RECEITA",
                  "id": 15,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.01.05 - Outras entradas de empr\xE9stimos e financiamentos",
                  "tipo": "RECEITA",
                  "id": 16,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }, {
            "contaContabil": {
              "nome": "3.02 - Receitas Financeiras",
              "tipo": "RECEITA",
              "id": 17,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "3.02.01 - Juros Recebidos de duplicatas/vendas",
                  "tipo": "RECEITA",
                  "id": 18,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.02.02 - Rendimentos de aplica\xE7\xF5es",
                  "tipo": "RECEITA",
                  "id": 19,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.02.03 - Outras receitas financeiras",
                  "tipo": "RECEITA",
                  "id": 20,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }, {
            "contaContabil": {
              "nome": "3.03 - Outros Recebimentos",
              "tipo": "RECEITA",
              "id": 21,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "3.03.01 - Recursos para Cap\xEDtaliza\xE7\xE3o/Integraliza\xE7\xE3o s\xF3cios",
                  "tipo": "RECEITA",
                  "id": 22,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.03.02 - Vendas de Bens Patrimoniais ",
                  "tipo": "RECEITA",
                  "id": 23,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "3.03.03 - Outros recebimentos n\xE3o operacionais",
                  "tipo": "RECEITA",
                  "id": 24,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }]
        },
        "p20190301": 0,
        "r20190301": 0,
        "p20190401": 0,
        "r20190401": 0,
        "p_resumo": 0,
        "r_resumo": 0
      }, {
        "contaContabil": {
          "nome": "4 - Despesas N\xE3o Operacionais",
          "tipo": "DESPESA",
          "id": 94,
          "folha": false,
          "children": [{
            "contaContabil": {
              "nome": "4.01 - Despesas Financeiras",
              "tipo": "DESPESA",
              "id": 99,
              "folha": false,
              "children": [{
                "contaContabil": {
                  "nome": "4.01.01 - Juros, Juros Banc\xE1rios e Encargos",
                  "tipo": "DESPESA",
                  "id": 100,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.02 - Taxas e Tarifas Banc\xE1rias",
                  "tipo": "DESPESA",
                  "id": 101,
                  "folha": true,
                  "children": []
                },
                "r20190301": "-1233.54",
                "p20190301": 0.010724783166001131,
                "r20190401": "-1201.56",
                "p20190401": 0.009626867160433924,
                "r_resumo": "-2435.1",
                "p_resumo": 0.010153403919178055
              }, {
                "contaContabil": {
                  "nome": "4.01.03 - Multas Contratuais",
                  "tipo": "DESPESA",
                  "id": 102,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.04 - Pagtos de juros e acr\xE9scimos",
                  "tipo": "DESPESA",
                  "id": 103,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.05 - Pgto de Cheques Devolvidos",
                  "tipo": "DESPESA",
                  "id": 104,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.06 - Amortiza\xE7\xE3o Financ. Cap.Giro",
                  "tipo": "DESPESA",
                  "id": 105,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.07 - Amortiza\xE7\xE3o Financ.Longo Prazo",
                  "tipo": "DESPESA",
                  "id": 106,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.08 - Outras Amortiza\xE7\xF5es",
                  "tipo": "DESPESA",
                  "id": 107,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }, {
                "contaContabil": {
                  "nome": "4.01.09 - Outras Despesas Financeiras ",
                  "tipo": "DESPESA",
                  "id": 108,
                  "folha": true,
                  "children": []
                },
                "p20190301": 0,
                "r20190301": 0,
                "p20190401": 0,
                "r20190401": 0,
                "p_resumo": 0,
                "r_resumo": 0
              }]
            },
            "r20190301": "-1233.54",
            "p20190301": 0.010724783166001131,
            "r20190401": "-1201.56",
            "p20190401": 0.009626867160433924,
            "r_resumo": "-2435.1",
            "p_resumo": 0.010153403919178055
          }]
        },
        "r20190301": "-1233.54",
        "p20190301": 0.010724783166001131,
        "r20190401": "-1201.56",
        "p20190401": 0.009626867160433924,
        "r_resumo": "-2435.1",
        "p_resumo": 0.010153403919178055
      }, {
        "contaContabil": {
          "nome": "Resultado N\xE3o Operacional de Caixa",
          "tipo": null,
          "id": -2,
          "folha": true,
          "children": []
        },
        "r20190301": "-1233.54",
        "p20190301": 0.010724783166001131,
        "r20190401": "-1201.56",
        "p20190401": 0.009626867160433924,
        "r_resumo": "-2435.1",
        "p_resumo": 0.010153403919178055
      }, {
        "contaContabil": {
          "nome": "5 - Investimentos",
          "tipo": "DESPESA",
          "id": 403,
          "folha": false,
          "children": [{
            "contaContabil": {
              "nome": "5.01 - Constru\xE7\xF5es e Reformas",
              "tipo": "DESPESA",
              "id": 96,
              "folha": true,
              "children": []
            },
            "r20190301": "-784.63",
            "p20190301": 0.0068218190050906066,
            "r20190401": "-292.8",
            "p20190401": 0.0023459059094635753,
            "r_resumo": "-1077.43",
            "p_resumo": 0.004492456976978363
          }, {
            "contaContabil": {
              "nome": "5.02 - M\xE1quinas e Equipamentos",
              "tipo": "DESPESA",
              "id": 97,
              "folha": true,
              "children": []
            },
            "r20190301": "-1897.68",
            "p20190301": 0.016499024367638688,
            "r20190401": "-1485.67",
            "p20190401": 0.011903149018144637,
            "r_resumo": "-3383.35",
            "p_resumo": 0.01410723138678127
          }, {
            "contaContabil": {
              "nome": "5.03 - Outros investimentos",
              "tipo": "DESPESA",
              "id": 98,
              "folha": true,
              "children": []
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }, {
            "contaContabil": {
              "nome": "5.04 - Aquisi\xE7\xE3o de Software",
              "tipo": "DESPESA",
              "id": 14747,
              "folha": true,
              "children": []
            },
            "p20190301": 0,
            "r20190301": 0,
            "p20190401": 0,
            "r20190401": 0,
            "p_resumo": 0,
            "r_resumo": 0
          }, {
            "contaContabil": {
              "nome": "5.05 - Aquisi\xE7\xE3o de m\xF3veis",
              "tipo": "DESPESA",
              "id": 22505,
              "folha": true,
              "children": []
            },
            "r20190301": "-6149.68",
            "p20190301": 0.053467244305246554,
            "r20190401": "-628.0",
            "p20190401": 0.005031519505270236,
            "r_resumo": "-6777.68",
            "p_resumo": 0.028260245030978078
          }]
        },
        "r20190301": "-8831.99",
        "p20190301": 0.07678808767797585,
        "r20190401": "-2406.47",
        "p20190401": 0.019280574432878447,
        "r_resumo": "-11238.46",
        "p_resumo": 0.04685993339473771
      }, {
        "contaContabil": {
          "nome": "Despesas n\xE3o classificadas",
          "tipo": "DESPESA",
          "id": -3,
          "folha": false,
          "children": []
        },
        "p20190301": 0,
        "r20190301": 0,
        "p20190401": 0,
        "r20190401": 0,
        "p_resumo": 0,
        "r_resumo": 0
      }, {
        "contaContabil": {
          "nome": "Receitas n\xE3o classificadas",
          "tipo": "RECEITA",
          "id": -4,
          "folha": false,
          "children": []
        },
        "p20190301": 0,
        "r20190301": 0,
        "p20190401": 0,
        "r20190401": 0,
        "p_resumo": 0,
        "r_resumo": 0
      }, {
        "contaContabil": {
          "nome": "Resultado Total",
          "tipo": null,
          "id": -5,
          "folha": false,
          "children": []
        },
        "r20190301": "18586.3",
        "p20190301": 0.1615951143523897,
        "r20190401": "36714.81",
        "p20190401": 0.29415809338740556,
        "r_resumo": "55301.11",
        "p_resumo": 0.23058375713888413
      }];
      this.set('labsDre', a);
      json_keys = Object.keys(a[0]);
      columns = [];
      columns.push({
        caption: 'Plano de Conta',
        dataField: 'contaContabil.nome'
      });

      for (i = 0, len = json_keys.length; i < len; i++) {
        key = json_keys[i];

        if (/^r\d/.test(key)) {
          stringfied_date = moment(key.substring(1), 'YYYYMMDD').format('MMM/YYYY');
          columns.push({
            caption: stringfied_date,
            columns: [{
              caption: 'Resultado',
              dataField: key
            }, {
              caption: 'AV',
              dataField: 'p' + key.slice(1)
            }]
          });
        }
      }

      columns.push({
        caption: 'Resumo',
        columns: [{
          caption: 'Resultado',
          dataField: 'r_resumo'
        }, {
          caption: 'AV',
          dataField: 'p_resumo'
        }]
      });
      return this.set('labsColumns', columns);
    }
  });

  _exports.default = _default;
});