define("api-dashboard/components/box-autenticacao", ["exports", "api-dashboard/mixins/components/transicao-div"], function (_exports, _transicaoDiv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transicaoDiv.default, {
    divEntrar: null,
    divRecuperarSenha: null,
    utilizarAnimacaoNaTransicao: false,
    didInsertElement: function didInsertElement() {
      this.mapearDivsAtributos();
      this.inicializarEstadoDivs();
      return this.determinarArrayEtapasDiv();
    },
    mapearDivsAtributos: function mapearDivsAtributos() {
      this.set("divEntrar", this.$("#box-login"));
      return this.set("divRecuperarSenha", this.$("#box-recuperacao-senha"));
    },
    inicializarEstadoDivs: function inicializarEstadoDivs() {
      return this.mostrarBoxLogin();
    },
    mostrarBoxLogin: function mostrarBoxLogin(callbackOnAnimationComplete) {
      if (callbackOnAnimationComplete == null) {
        callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
      }

      return this.alternarJanela({
        proximaDiv: this.get("divEntrar")
      });
    },
    mostrarBoxRecuperacao: function mostrarBoxRecuperacao(callbackOnAnimationComplete) {
      return this.alternarJanela({
        proximaDiv: this.get("divRecuperarSenha")
      });
    },
    actions: {
      actMostrarBoxLogin: function actMostrarBoxLogin(callbackOnAnimationComplete) {
        if (callbackOnAnimationComplete == null) {
          callbackOnAnimationComplete = function callbackOnAnimationComplete() {};
        }

        return this.mostrarBoxLogin(callbackOnAnimationComplete);
      },
      actMostrarBoxRecuperacao: function actMostrarBoxRecuperacao(callbackOnAnimationComplete) {
        return this.mostrarBoxRecuperacao(callbackOnAnimationComplete);
      }
    }
  });

  _exports.default = _default;
});