define("api-dashboard/components/supplier-categories/supplier-category-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});