define("api-dashboard/components/lancamentos/lancamento-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    alertMessage: null,
    alertMessages: [],
    alertSpinner: false,
    alertMessageType: null,
    intervalValues: [{
      value: 14,
      description: '14 dias'
    }, {
      value: 15,
      description: '15 dias'
    }, {
      value: 28,
      description: '28 dias'
    }, {
      value: 30,
      description: '30 dias'
    }],
    selectIntervalObserver: Ember.observer('selectInterval', function () {
      var dataCompetencia, parcelas;

      if (this.selectInterval) {
        parcelas = this.get('model.parcelasLancamento');
        dataCompetencia = this.get('model.dataCompetencia');
        this.updateDataCompetencia(dataCompetencia, parcelas, parseInt(this.selectInterval));
      }

      return this.selectInterval = null;
    }),
    asColumnsFornecedor: [{
      label: 'Nome',
      field: 'nomeOuRazaoSocial'
    }, {
      label: 'Documento',
      field: 'document'
    }],
    columnsInstalment: Ember.computed(function () {
      if (this.get('model.isNew')) {
        return ['Data de Vencimento', 'Valor'];
      }

      return ['Data de Vencimento', 'Valor', 'Data Realizado', 'Juros', 'Descontos', 'Ações'];
    }),
    showTable: Ember.computed('model.numParcelas', function () {
      return this.get('model.numParcelas') && this.get('model.numParcelas') > 1;
    }),
    hasntInstallments: Ember.computed('model.numParcelas', function () {
      return !(this.get('model.numParcelas') && this.get('model.numParcelas') > 1);
    }),
    isEditMode: Ember.computed('model.isNew', function () {
      return !this.get('model.isNew');
    }),
    numParcelasObserver: Ember.observer('model.numParcelas', function () {
      var oldValue, parcelas, value;
      value = this.get('model.numParcelas');
      oldValue = this.get('_oldNumParcelas');
      this.set('_oldNumParcelas', value);

      if (value && this.get('model.isNew')) {
        parcelas = [];
        this.updateNumParcelas(parcelas, value);
        return this.set('model.parcelasLancamento', parcelas);
      }
    }),
    valorObserver: Ember.observer('model.valor', function () {
      var oldValue, parcelas, value;
      value = parseFloat(this.get('model.valor'));
      oldValue = parseFloat(this.get('_oldValor'));

      if (!this.get('model.valorCorrigido')) {
        if (value !== oldValue) {
          parcelas = this.get('model.parcelasLancamento');
          this.set('_oldValor', value);
          return this.updateValorParcelas(value, parcelas);
        }
      }
    }),
    dataCompetenciaObserver: Ember.observer('model.dataCompetencia', function () {
      var oldValue, parcelas, value;
      value = this.get('model.dataCompetencia');
      oldValue = this.get('_oldDataCompetencia');

      if (value) {
        parcelas = this.get('model.parcelasLancamento');
        this.set('_oldDataCompetencia', value);
        return this.updateDataCompetencia(value, parcelas, 30);
      }
    }),
    valorJurosObserver: Ember.observer('model.valorTotalJuros', function () {
      var parcelas, valorJuros;
      valorJuros = this.get('model.valorTotalJuros');
      parcelas = this.get('model.parcelasLancamento');
      return parcelas.forEach(function (p, i) {
        return p.set('valorJuros', valorJuros);
      });
    }),
    valorDescontosObserver: Ember.observer('model.valorTotalDesconto', function () {
      var parcelas, valorDesconto;
      valorDesconto = this.get('model.valorTotalDesconto');
      parcelas = this.get('model.parcelasLancamento');
      return parcelas.forEach(function (p, i) {
        return p.set('valorDesconto', valorDesconto);
      });
    }),
    dataVencimentoObserver: Ember.observer('model.dataVencimento', function () {
      var date;
      date = this.get('model.dataVencimento');
      return this.get('model.parcelasLancamento').forEach(function (e) {
        return e.set('dataVencimento', date);
      });
    }),
    parcelasLancamentoObserver: Ember.computed('model.parcelasLancamento', function () {
      var parcelas, parcelasShow;
      parcelas = this.get('model.parcelasLancamento');
      parcelas = parcelas.map(function (element) {
        return element;
      });
      parcelas.sort(function (a, b) {
        return a.get('numParcela') - b.get('numParcela');
      });
      this.set('model.parcelasLancamento', parcelas);
      parcelasShow = parcelas.filter(function (element) {
        return !element.get('_destroy');
      });
      return parcelasShow;
    }),
    verifyTotal: Ember.observer('model.parcelasLancamento.@each.valor', function () {
      var parcelas, total;
      parcelas = this.get('model.parcelasLancamento');
      total = parcelas.reduce(function (acc, cur) {
        if (!cur.get('_destroy')) {
          acc += parseFloat(cur.get('valor'));
        }

        return acc;
      }, 0);

      if (total.toFixed(2) !== parseFloat(this.get('model.valor')).toFixed(2)) {
        return this.setAlertData('O valor das parcelas é R$' + total.toFixed(2) + ' e difere do valor total do lançamento R$' + this.get('model.valor') + ' . Ao salvar, o valor total será corrigido.', [], "E");
      } else {
        return this.setAlertData(null, [], "S");
      }
    }),
    isDespesa: Ember.computed('model.tipo', function () {
      var tipo;
      tipo = this.get('model.tipo');
      return tipo === 'DESPESA';
    }),
    baixadoObserver: Ember.observer('model.baixado', function () {
      var isBaixado;
      isBaixado = this.get('model.baixado');

      if (!isBaixado) {
        this.get('model.parcelasLancamento').forEach(function (p) {
          return p.set('dataRealizado', null);
        });
        return this.set('model.dataRealizado', null);
      } else {
        if (this.get('model.parcelasLancamento').length === 1) {
          return this.set('model.dataRealizado', new Date());
        }
      }
    }),
    showDataRealizado: Ember.computed('model.baixado', function () {
      return !!this.get('model.dataRealizado');
    }),
    updateNumParcelas: function updateNumParcelas(parcelas, numParcelas) {
      var i, j, parcelaLancamento, record, ref, ref1, results, totalParcelas, valorParcela, valorTotal;
      valorTotal = this.get('model.valor');
      valorParcela = parseFloat((valorTotal / numParcelas).toFixed(2));
      totalParcelas = 0;
      results = [];

      for (i = j = ref = parcelas.length, ref1 = numParcelas; ref <= ref1 ? j < ref1 : j > ref1; i = ref <= ref1 ? ++j : --j) {
        parcelaLancamento = {};
        parcelaLancamento.dataVencimento = (0, _moment.default)(this.get('model.dataCompetencia')).add(i + 1, 'M');
        parcelaLancamento.numParcela = i + 1;
        parcelaLancamento.lancamento = this.get('model');
        parcelaLancamento.domain = this.get('model.domain');

        if (i === numParcelas - 1) {
          parcelaLancamento.valor = valorTotal - totalParcelas;
        } else {
          parcelaLancamento.valor = valorParcela;
        }

        record = this.get('store').createRecord('parcela-lancamento', parcelaLancamento);
        parcelas.pushObject(record);
        results.push(totalParcelas += valorParcela);
      }

      return results;
    },
    updateValorParcelas: function updateValorParcelas(valorTotal, parcelas) {
      var numParcelas, numParcelasBaixadas, totalParcelas, valorParcela;
      numParcelas = this.get('model.numParcelas');
      totalParcelas = 0;
      numParcelasBaixadas = parcelas.reduce(function (acc, cur) {
        if (cur.get('dataRealizado')) {
          acc.qtd += 1;
          acc.total += parseFloat(cur.get('valor'));
        }

        return acc;
      }, {
        qtd: 0,
        total: 0
      });
      valorParcela = parseFloat(((valorTotal - numParcelasBaixadas.total) / (numParcelas - numParcelasBaixadas.qtd)).toFixed(2));
      return parcelas.forEach(function (p, i) {
        if (!p.get('_destroy')) {
          if (!p.get('dataRealizado')) {
            if (i === numParcelas - 1) {
              p.set('valor', valorTotal - totalParcelas);
            } else {
              p.set('valor', valorParcela);
            }

            return totalParcelas += valorParcela;
          } else {
            return totalParcelas += parseFloat(p.get('valor'));
          }
        }
      });
    },
    updateDataCompetencia: function updateDataCompetencia(dataCompetencia, parcelas, interval) {
      var timeParam, valueParam;
      timeParam = 'd';
      valueParam = interval;

      if (interval === 30) {
        timeParam = 'M';
        valueParam = 1;
      }

      return parcelas.forEach(function (p, i) {
        var d;

        if (!p.get('dataRealizado')) {
          d = (0, _moment.default)(dataCompetencia).add((i + 1) * valueParam, timeParam);
          return p.set('dataVencimento', d);
        }
      });
    },
    setAlertData: function setAlertData(genericMessage, messages, messageType, spinner) {
      if (spinner == null) {
        spinner = false;
      }

      this.set("alertMessage", genericMessage);
      this.set("alertMessages", messages);
      this.set("alertMessageType", messageType);
      return this.set("alertSpinner", spinner);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      insertInstallment: function insertInstallment() {
        var dataCompetencia, parcelaLancamento, parcelas, record;
        parcelas = this.get('model.parcelasLancamento');
        dataCompetencia = this.get('model.dataCompetencia');
        parcelaLancamento = {};
        parcelaLancamento.dataVencimento = (0, _moment.default)(dataCompetencia).add(parcelas.length + 1, 'M');
        parcelaLancamento.numParcela = parcelas.length + 1;
        parcelaLancamento.lancamento = this.get('model');
        parcelaLancamento.domain = this.get('model.domain');
        record = this.get('store').createRecord('parcela-lancamento', parcelaLancamento);
        parcelas.pushObject(record);
        this.set('model.numParcelas', parcelas.length);
        return this.updateValorParcelas(this.get('model.valor'), parcelas);
      },
      receiveInstallment: function receiveInstallment(installment) {
        return installment.set('dataRealizado', (0, _moment.default)());
      },
      undoInstallment: function undoInstallment(installment) {
        return installment.set('dataRealizado', '');
      },
      deleteInstallment: function deleteInstallment(installment) {
        var parcelas;
        parcelas = this.get('model.parcelasLancamento');
        parcelas.forEach(function (p) {
          if (p.numParcela === installment) {
            return p.set('_destroy', true);
          }
        });
        this.set('model.numParcelas', this.get('model.numParcelas') - 1);
        this.updateValorParcelas(this.get('model.valor'), parcelas);
        return this.set('model.parcelasLancamento', parcelas);
      }
    }
  });

  _exports.default = _default;
});