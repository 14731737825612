define("api-dashboard/models/boleto", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    accessKey: _emberData.default.attr('string'),
    aceite: _emberData.default.attr('string'),
    agencia: _emberData.default.attr('string'),
    bairroSacado: _emberData.default.attr('string'),
    carteira: _emberData.default.attr('string'),
    cepSacado: _emberData.default.attr('string'),
    checkoutUrl: _emberData.default.attr('string'),
    cidadeSacado: _emberData.default.attr('string'),
    cnpjCedente: _emberData.default.attr('string'),
    codigoBanco: _emberData.default.attr('string'),
    codigoCedente: _emberData.default.attr('string'),
    complementoSacado: _emberData.default.attr('string'),
    conta: _emberData.default.attr('string'),
    cpfCnpjSacado: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    dadoAdicional1: _emberData.default.attr('string'),
    dadoAdicional2: _emberData.default.attr('string'),
    dadoAdicional3: _emberData.default.attr('string'),
    dataEmissao: _emberData.default.attr('date'),
    dataProcessamento: _emberData.default.attr('date'),
    dataVencimento: _emberData.default.attr('date'),
    descricao: _emberData.default.attr('string'),
    especieDocumento: _emberData.default.attr('string'),
    estadoSacado: _emberData.default.attr('string'),
    instrucoes1: _emberData.default.attr('string'),
    instrucoes2: _emberData.default.attr('string'),
    instrucoes3: _emberData.default.attr('string'),
    linhaDigitavel: _emberData.default.attr('string')
  }, _defineProperty(_DS$Model$extend, "linhaDigitavel", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "logradouroSacado", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "mensagemBanco", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "motivoCancelamento", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "nomeCedente", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "nomeSacado", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "nossoNumero", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "numero", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "numeroDocumento", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "numeroSacado", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "paymentGateway", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "pdfUrl", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "status", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "ultimoCodOcorrenciaRetorno", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "valor", _emberData.default.attr('big')), _defineProperty(_DS$Model$extend, "invoiceIdIuguId", _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, "banco", _emberData.default.belongsTo('banco')), _defineProperty(_DS$Model$extend, "configuracaoBoleto", _emberData.default.belongsTo('configuracao-boleto')), _defineProperty(_DS$Model$extend, "domain", _emberData.default.belongsTo('domain')), _defineProperty(_DS$Model$extend, "notaFiscalServico", _emberData.default.belongsTo('nota-fiscal-servico')), _defineProperty(_DS$Model$extend, "parcelaLancamento", _emberData.default.belongsTo('parcelaLancamento', {
    inverse: 'boletos'
  })), _defineProperty(_DS$Model$extend, "parcelasLancamento", _emberData.default.hasMany('parcelaLancamento', {
    inverse: 'boletos2'
  })), _defineProperty(_DS$Model$extend, "person", _emberData.default.belongsTo('person')), _defineProperty(_DS$Model$extend, "retornoBoleto", _emberData.default.belongsTo('retorno-boleto')), _defineProperty(_DS$Model$extend, "usuarioCancelamento", _emberData.default.belongsTo('user')), _defineProperty(_DS$Model$extend, "statusDescricao", Ember.computed('status', function () {
    switch (this.get('status')) {
      case 'cancelado':
        return 'Cancelado';

      case 'registro_enviado':
        return 'Registro enviado - aguardando retorno do banco';

      case 'registrado':
        return 'Registrado';

      case 'baixado':
        return 'Baixado';

      case 'emitido':
        return 'Emitido';

      default:
        return '-';
    }
  })), _defineProperty(_DS$Model$extend, "lateDays", Ember.computed('dataVencimento', function () {
    var currentDate, dataVencimento;
    dataVencimento = (0, _moment.default)(this.get('dataVencimento')).format('YYYY-MM-DD');
    currentDate = (0, _moment.default)();
    return currentDate.diff((0, _moment.default)(dataVencimento), 'days');
  })), _defineProperty(_DS$Model$extend, "isLate", Ember.computed('lateDays', function () {
    return this.get('lateDays') > 0;
  })), _defineProperty(_DS$Model$extend, "canUpdate", Ember.computed('lateDays', function () {
    return this.get('podeReemitir') && this.get('lateDays') > 10;
  })), _defineProperty(_DS$Model$extend, "podeReemitir", Ember.computed('status', function () {
    var ref;
    return (ref = this.get('status')) !== 'baixado' && ref !== 'cancelado';
  })), _defineProperty(_DS$Model$extend, "canCancel", Ember.computed('status', function () {
    var ref;
    return (ref = this.get('status')) !== 'baixado' && ref !== 'cancelado';
  })), _defineProperty(_DS$Model$extend, "linkBaixar", Ember.computed('pdfUrl', 'status', function () {
    var status;
    status = this.get('status');

    if (!(status == null || status === 'cancelado')) {
      return "<a href=\"" + this.get('pdfUrl') + "\" target=\"_blank\">[Imprimir]</a>";
    }

    return '';
  })), _defineProperty(_DS$Model$extend, "isCanceled", Ember.computed('status', function () {
    return this.get('status') === 'cancelado';
  })), _DS$Model$extend));

  _exports.default = _default;
});