define("api-dashboard/helpers/show-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.showIf = void 0;
  var showIf;
  _exports.showIf = showIf;

  _exports.showIf = showIf = function showIf(params, namedParams) {
    var condition, string;
    condition = params[0];
    string = params[1];

    if (condition != null) {
      return string;
    } else {
      return '';
    }
  };

  var _default = Ember.Helper.helper(showIf);

  _exports.default = _default;
});