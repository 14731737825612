define("api-dashboard/serializers/parcela-lancamento", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      boletos: {
        embedded: "always"
      },
      boletos2: {
        embedded: "always"
      },
      lancamento: {
        serialize: 'ids',
        deserialize: 'records'
      },
      ultimoBoletoValido: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});