define("api-dashboard/components/inputs/input-phone", ["exports", "api-dashboard/components/inputs/base-masked-field", "api-dashboard/mixins/utils"], function (_exports, _baseMaskedField, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend(_utils.default, {
    label: 'Telefone',
    pattern: '00 0000-00000',
    placeholder: 'Telefone',
    type: 'tel',
    validate: function validate() {
      return this.validatePhoneInternal();
    },
    validatePhoneInternal: function validatePhoneInternal() {
      return this.validarTelefone(this.obterValorSemMascara());
    }
  });

  _exports.default = _default;
});