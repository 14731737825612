define("api-dashboard/templates/components/inscricao-estadual/inscricao-estadual-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lDI84lvE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"Nova\"]],\"parameters\":[]},null],[0,\" Inscrição Estadual\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"base/base-form\",null,[[\"model\",\"searching\"],[[24,[\"model\"]],[24,[\"searching\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"selectedRecord\",\"modelName\",\"suggestionField\",\"onSelect\",\"cols\"],[\"Emitente\",[24,[\"model\",\"emitente\"]],\"emitente\",\"nome\",[24,[\"selectEmitente\"]],6]]],false],[0,\"\\n    \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxLength\",\"cols\"],[\"Inscricao Estadual\",[24,[\"model\",\"inscricaoEstadual\"]],14,6]]],false],[0,\"\\n    \"],[1,[28,\"inputs/input-select\",null,[[\"label\",\"value\",\"options\",\"cols\"],[\"Estado\",[24,[\"model\",\"uf\"]],[24,[\"constants\",\"ufValues\"]],6]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inscricao-estadual/inscricao-estadual-form.hbs"
    }
  });

  _exports.default = _default;
});