define("api-dashboard/helpers/format/cnpj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatCnpj = void 0;
  var formatCnpj;
  _exports.formatCnpj = formatCnpj;

  _exports.formatCnpj = formatCnpj = function formatCnpj(params, namedParams) {
    var value;
    value = params[0];

    if (!Ember.isEmpty(value)) {
      value = value.toString().replace(/[_\.\/ -]/g, '');
      return value.substr(0, 2) + "." + value.substr(2, 3) + "." + value.substr(5, 3) + "/" + value.substr(8, 4) + "-" + value.substr(12, 2);
    } else {
      return '';
    }
  };

  var _default = Ember.Helper.helper(formatCnpj);

  _exports.default = _default;
});