define("api-dashboard/components/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ToggleButtonComponent;
  ToggleButtonComponent = Ember.Component.extend({
    classNames: ["switch"],
    tagName: "label",
    estadoInicial: false,
    actionOnToggle: null,
    value: false,
    didInsertElement: function didInsertElement() {
      this.atribuirListenerOnChange();
      return this.tratarEstadoInicial();
    },
    tratarEstadoInicial: function tratarEstadoInicial() {
      if (this.get("estadoInicial")) {
        return this.$("input").trigger("click");
      }
    },
    atribuirListenerOnChange: function atribuirListenerOnChange() {
      var self;
      self = this;
      return this.$("input").change(function (e) {
        var value;
        value = self.$("input").prop("checked");

        if (self.get("actionOnToggle")) {
          self.desabilitar(true);
          return self.sendAction("actionOnToggle", value, function (params) {
            if (params == null) {
              params = {};
            }

            self.desabilitar(false);

            if (params.hasOwnProperty("value")) {
              return self.set("value", params["value"]);
            }
          });
        }
      });
    },
    desabilitar: function desabilitar(_desabilitar) {
      return this.$("*").attr("disabled", _desabilitar);
    }
  });
  var _default = ToggleButtonComponent;
  _exports.default = _default;
});