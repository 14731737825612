define("api-dashboard/helpers/format/big", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_formatters.default, {
    compute: function compute(arg, options) {
      var scale, value;
      value = arg[0], scale = arg[1];
      return this.formatBig(value, scale);
    }
  });

  _exports.default = _default;
});