define("api-dashboard/components/reports/report-z-reduction-page", ["exports", "ember-data", "api-dashboard/mixins/formatters", "api-dashboard/mixins/utils", "jquery"], function (_exports, _emberData, _formatters, _utils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _utils.default, {
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    classNames: ['reportDre__content'],
    zReductionModal: null,
    params: null,
    page: 1,
    didReceiveAttrs: function didReceiveAttrs() {
      return this.getData(1);
    },
    getData: function getData(page) {
      var ajaxParams, params, self;
      self = this;
      this.set('application.searchingReport', true);
      params = this.getParams();
      ajaxParams = {
        method: 'GET',
        data: {
          page: page,
          store_id: params.store_id,
          data_movimento_from: params.data_movimento_from,
          data_movimento_to: params.data_movimento_to
        }
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: '/z_reductions'
      }).then(function (o) {
        if (page === 1) {
          self.get('store').unloadAll('z-reduction');
        }

        self.get('store').pushPayload('z-reduction', o);
        self.set('nextRecordsVisible', o.meta.recordsRemaining > 0);
        self.set('zReductions', self.get('store').peekAll('z-reduction'));
        self.set('spin.buttonSpin', false);
        self.set('application.searchingReport', false);
        return self.scrollToElement(this.$('.painelFocus__content'), this.$('#zReductionDiv'), 1);
      });
    },
    getParams: function getParams() {
      var data_movimento_from, data_movimento_to, params, store_id;
      params = this.get('params');
      store_id = data_movimento_from = data_movimento_to = '';

      if (params.store_id) {
        store_id = params.store_id;
      }

      if (params.data_movimento_from) {
        data_movimento_from = this.formatDate(params.data_movimento_from, {
          format: 'YYYY-MM-DD 00:00:00'
        });
      }

      if (params.data_movimento_to) {
        data_movimento_to = this.formatDate(params.data_movimento_to, {
          format: 'YYYY-MM-DD 23:59:59'
        });
      }

      return {
        store_id: store_id,
        data_movimento_from: data_movimento_from,
        data_movimento_to: data_movimento_to
      };
    },
    actions: {
      openZReductionModal: function openZReductionModal(zReduction) {
        var self;
        self = this;
        self.get('zReductionModal').show();
        self.set('searchingZReductions', true);
        self.set('zReduction', null);
        return this.get('store').findRecord('z-reduction', zReduction.get('id'), {
          reload: true
        }).then(function (o) {
          self.set('zReduction', o);
          return self.set('searchingZReductions', false);
        });
      },
      closeZReductionModal: function closeZReductionModal(modal) {
        this.set('zReduction', null);
        return modal.hide();
      },
      loadMore: function loadMore() {
        this.set('page', this.get('page') + 1);
        return this.getData(this.get('page'));
      }
    }
  });

  _exports.default = _default;
});