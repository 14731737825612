define("api-dashboard/components/customers/saas-commercial-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    spin: Ember.inject.service(),
    model: null,
    customer: null,
    init: function init() {
      var self;
      self = this;
      this.set('searchingSaasCommercialConfig', true);
      this.get('store').queryRecord('saas-commercial-config', {
        customer_id: self.get('customer.id')
      }).then(function (obj) {
        self.set('model', obj);
        return self.set('searchingSaasCommercialConfig', false);
      });
      return this._super.apply(this, arguments);
    },
    actions: {
      saveSaasCommercialConfig: function saveSaasCommercialConfig() {
        var self;
        self = this;
        self.set('searchingSaasCommercialConfig', true);
        return this.get('model').save().then(function (obj) {
          var message, toastType;
          toastType = 'success';
          message = 'Condição Comercial salva com sucesso!';
          self.set('searchingSaasCommercialConfig', false);
          return self.get('application').showAlert(message, toastType);
        }, function (obj) {
          var message, toastType;
          toastType = 'error';
          message = 'Erro ao salvar Condição Comercial!';
          self.set('searchingSaasCommercialConfig', false);
          return self.get('application').showAlert(message, toastType);
        });
      }
    }
  });

  _exports.default = _default;
});