define("api-dashboard/serializers/customer", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attr_map_serialize: {
      'installmentCredit': 'installment_credit_attributes'
    },
    attrs: {
      addresses: {
        embedded: "always"
      },
      contactInfos: {
        embedded: "always"
      },
      relatedPeople: {
        embedded: "always"
      },
      installmentCredit: {
        embedded: "always"
      },
      contracts: {
        deserialize: "records"
      }
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if (relationship === "belongsTo" && method === "serialize" && Ember.isPresent(this.attr_map_serialize[key])) {
        return this.attr_map_serialize[key];
      }

      return this._super(key, relationship, method);
    }
  });

  _exports.default = _default;
});