define("api-dashboard/components/notas-fiscais/nota-fiscal-show", ["exports", "api-dashboard/mixins/utils", "api-dashboard/mixins/components/nota-fiscal"], function (_exports, _utils, _notaFiscal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, _notaFiscal.default, {
    downloadingDanfe: false,
    downloadingXml: false,
    dataHoraEntradaSaidaLabel: Ember.computed('model.tipoDocumento', function () {
      var tipoDocumento;
      tipoDocumento = this.get('model.tipoDocumento');

      switch (tipoDocumento) {
        case 0:
          return 'Data/Hora de entrada';

        case 1:
          return 'Data/Hora de saída';

        default:
          return 'Data/Hora de entrada/saída';
      }
    }),
    actions: {
      toggleItemDetails: function toggleItemDetails(item) {
        return item.set('showDetails', !item.get('showDetails'));
      },
      downloadXml: function downloadXml(obj) {
        var reportName, self, url;
        self = this;
        this.set('downloadingXml', true);
        url = "/notas_fiscais/" + obj.get('id') + ".xml";
        reportName = this.get('model.chaveNfe') + ".xml";
        return this.downloadURL(url, reportName, function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadXmlCancelamento: function downloadXmlCancelamento(obj) {
        var reportName, self, url;
        self = this;
        this.set('downloadingXml', true);
        url = "/notas_fiscais/" + obj.get('id') + ".xml?cancelamento=1";
        reportName = this.get('model.chaveNfe') + "-can.xml";
        return this.downloadURL(url, reportName, function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadDanfe: function downloadDanfe(obj) {
        var reportName, self, url;
        self = this;
        this.set('downloadingDanfe', true);
        url = "/notas_fiscais/" + obj.get('id') + ".pdf";
        reportName = this.get('model.chaveNfe') + ".pdf";
        return this.downloadURL(url, reportName, function (_this) {
          return function () {
            return self.set('downloadingDanfe', false);
          };
        }(this));
      },
      goBack: function goBack() {
        return this.sendAction('cancelAction');
      }
    }
  });

  _exports.default = _default;
});