define("api-dashboard/components/charts/line", ["exports", "api-dashboard/components/charts/chart"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chart.default.extend({
    height: 100,
    options: Ember.computed(function () {
      var opts;
      opts = {
        size: {
          height: this.get('height')
        },
        title: {
          text: "Novos Clientes"
        }
      };
      return this.get('application').deepMerge(this.get('baseOptions'), opts);
    })
  });

  _exports.default = _default;
});