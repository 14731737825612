define("api-dashboard/services/global-lookups", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    bankAccounts: Ember.computed(function () {
      return this.getModelList('conta-bancaria');
    }),
    cardBrands: Ember.computed(function () {
      return this.getModelList('card-brand');
    }),
    cardCompanies: Ember.computed(function () {
      return this.getModelList('card-company');
    }),
    collections: Ember.computed(function () {
      return this.getModelList('collection');
    }),
    configuracao: Ember.computed(function () {
      return this.getModelList('configuracao');
    }),
    costCenters: Ember.computed(function () {
      return this.getModelList('cost-center');
    }),
    departments: Ember.computed(function () {
      return this.getModelList('department');
    }),
    domain: Ember.computed(function () {
      return this.getModelList('domain');
    }),
    emitentes: Ember.computed(function () {
      return this.getModelList('emitente');
    }),
    labelModels: Ember.computed(function () {
      return this.getModelList('label-model');
    }),
    productTypes: Ember.computed(function () {
      return this.getModelList('product-type');
    }),
    relationshipTypes: Ember.computed(function () {
      return this.getModelList('relationship-type');
    }),
    salesPeople: Ember.computed(function () {
      return this.getModelList('pos-user');
    }),
    saleTypes: Ember.computed(function () {
      return this.getModelList('sale-type');
    }),
    stores: Ember.computed(function () {
      return this.get('application.currentDomain.visibleStores');
    }),
    webPdvPos: Ember.computed(function () {
      var params, promise;
      params = {
        enable_web_sale: true,
        active: true
      };
      promise = this.queryModelList('pos', params);
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    webNfcePos: Ember.computed(function () {
      var params;
      params = {
        enable_web_nfce_or_sale: true,
        active: true
      };
      return this.queryModelList('pos', params);
    }),
    cardInfos: function cardInfos(posId) {
      var params;
      params = {
        domain_id: this.get('application.currentDomain.id'),
        pos_id: posId,
        active: true
      };
      return this.get('store').query('card-info', params);
    },
    getModelList: function getModelList(model) {
      var _model, promise, self;

      self = this;
      _model = this.__getModelPropertyName(model);

      if (this.get(_model)) {
        return _emberData.default.PromiseObject.create({
          promise: self.get(_model)
        });
      }

      promise = this.get('store').findAll(model).then(function (obj) {
        return self.set(_model, obj);
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    },
    queryModelList: function queryModelList(model, params) {
      var _model, promise, self;

      self = this;
      _model = this.__getModelPropertyName(model);

      if (this.get(_model)) {
        return _emberData.default.PromiseObject.create({
          promise: Promise.resolve(self.get(_model))
        });
      }

      promise = this.get('store').query(model, params).then(function (obj) {
        return self.set(_model, obj);
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    },
    getDomainConfigValue: function getDomainConfigValue(configCode, fStore, emitente) {
      var params;
      params = {};
      params['code'] = configCode;

      if (!!fStore) {
        params['store_id'] = fStore.get('id');
      }

      if (!!emitente) {
        params['emitente_id'] = emitente.get('id');
      }

      return this.get('store').queryRecord('config-definition', params);
    },
    __getModelPropertyName: function __getModelPropertyName(model, params) {
      var i, j, propName, ref;
      propName = "_" + model + "s";

      if (!this.get('params')) {
        return propName;
      }

      propName += '_query';

      for (i = j = 0, ref = params.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
        propName += "_" + params[i][1];
      }

      return propName;
    },
    isValidBarcode: function isValidBarcode(gtin) {
      var barcode_digits, barcode_length, gtin_maths, i, j, k, modifier, multiplied_barcode_digits, ref, ref1, temp_check_digit, temp_checksum;

      if (!this.isValidBarcodeLength(gtin)) {
        return false;
      }

      multiplied_barcode_digits = [];
      modifier = 17 - (gtin.length - 1);
      barcode_digits = gtin.split('');
      barcode_length = gtin.length;

      for (i = j = 0, ref = barcode_length - 1; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        multiplied_barcode_digits[modifier + i] = barcode_digits[i];
      }

      temp_checksum = 0;
      gtin_maths = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];

      for (i = k = ref1 = modifier; ref1 <= 17 ? k < 17 : k > 17; i = ref1 <= 17 ? ++k : --k) {
        temp_checksum += multiplied_barcode_digits[i] * gtin_maths[i];
      }

      temp_check_digit = Math.ceil(temp_checksum / 10) * 10 - parseInt(temp_checksum);

      if (parseInt(gtin.slice(-1)) === parseInt(temp_check_digit)) {
        return true;
      }

      return false;
    },
    isValidBarcodeLength: function isValidBarcodeLength(gtin) {
      var m;

      if (!gtin) {
        return false;
      }

      if (gtin.length !== 8 && gtin.length !== 12 && gtin.length !== 13 && gtin.length !== 14) {
        return false;
      }

      m = gtin.match(/\d+/);

      if (m === null || isNaN(m[0])) {
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});