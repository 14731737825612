define("api-dashboard/services/conectividade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ConectividadeService;
  ConectividadeService = Ember.Service.extend({
    isOnline: true,
    init: function init() {
      var atualizarStatusConexao, self;

      atualizarStatusConexao = function atualizarStatusConexao() {
        return self.set("isOnline", window.navigator.onLine);
      };

      self = this;
      window.addEventListener("offline", atualizarStatusConexao);
      window.addEventListener("online", atualizarStatusConexao);
      atualizarStatusConexao();
      return this._super();
    }
  });
  var _default = ConectividadeService;
  _exports.default = _default;
});