define("api-dashboard/models/documento-importacao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cnpj: _emberData.default.attr('string'),
    codigoExportador: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    dataDesembaracoAduaneiro: _emberData.default.attr('date'),
    dataRegistro: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    formaIntermedio: _emberData.default.attr('number'),
    localDesembaracoAduaneiro: _emberData.default.attr('string'),
    numero: _emberData.default.attr('string'),
    ufDesembaracoAduaneiro: _emberData.default.attr('string'),
    ufTerceiro: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    valorAfrmm: _emberData.default.attr('big'),
    viaTransporte: _emberData.default.attr('number'),
    adicoes: _emberData.default.hasMany('adicao', {
      inverse: 'documentoImportacao'
    }),
    item: _emberData.default.belongsTo('item', {
      inverse: 'documentosImportacao'
    }),
    viaTransporteMaritima: Ember.computed('viaTransporte', function () {
      return this.get('viaTransporte') === 1;
    }),
    formaIntermedioNaoContaPropria: Ember.computed('formaIntermedio', function () {
      var fi;
      fi = this.get('formaIntermedio');
      return fi != null && fi !== 1;
    })
  });

  _exports.default = _default;
});