define("api-dashboard/templates/components/inputs/input-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AiyyT+MZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[22,\"formattedLabel\"],false],[0,\" \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\"*\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"prefix\"]],\"input-group\",\"\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"prefix\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[1,[22,\"prefix\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"inputs/input-number\",null,[[\"val\",\"scale\",\"allowEmpty\",\"disabled\",\"min\",\"max\",\"acceptsSign\"],[[24,[\"val\"]],[24,[\"scale\"]],[24,[\"allowEmpty\"]],[24,[\"disabled\"]],[24,[\"min\"]],[24,[\"max\"]],[24,[\"acceptsSign\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-big.hbs"
    }
  });

  _exports.default = _default;
});