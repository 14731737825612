define("api-dashboard/templates/components/base/abstract-input-form-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gns8hXfL",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"nomeComponenteInput\"]]],[[\"id\",\"placeholder\",\"val\",\"valorInicial\",\"maxlength\",\"actionOnFimValidacao\",\"actionOnIniciarValidacaoAssincrona\",\"actionOnFinalizarValidacaoAssincrona\",\"actionOnRealizarValidacaoAssincrona\"],[[24,[\"idInput\"]],[24,[\"placeholder\"]],[24,[\"valueInput\"]],[24,[\"valorInicial\"]],[24,[\"maxlength\"]],\"actFimValidacao\",\"actInicioValidacaoAssincrona\",\"actFimValidacaoAssincrona\",\"actRealizarValidacaoAssincrona\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"teste\"]]],null,{\"statements\":[[0,\"\\n\"],[2,\" Exemplo de implementação para os contextos externos:\\n\\n{{bs/inputs/input-container-generico\\n  valueInput=model.cnpj\\n  nomeComponenteInput=\\\"inputs/input-cnpj\\\"\\n  actionOnRealizarValidacaoAssincrona=\\\"actValidacaoCNPJComAjax\\\"\\n  actionOnFimValidacao=\\\"actFimValidacaoCNPJ\\\"}}\\n\\n\"],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/abstract-input-form-container.hbs"
    }
  });

  _exports.default = _default;
});