define("api-dashboard/models/cfop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    codigo: _emberData.default.attr('string'),
    descricao: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    transporte: _emberData.default.attr('boolean'),
    devolucao: _emberData.default.attr('boolean'),
    items: _emberData.default.hasMany('item'),
    localDestino: Ember.computed('codigo', function () {
      var codigo;
      codigo = parseInt(this.get('codigo')[0]);

      if (codigo != null) {
        if ([1, 5].includes(codigo)) {
          return this.get('constants.cfop.LOCAL_DESTINO_INTERNA');
        }

        if ([2, 6].includes(codigo)) {
          return this.get('constants.cfop.LOCAL_DESTINO_INTERESTADUAL');
        }

        if ([3, 7].includes(codigo)) {
          return this.get('constants.cfop.LOCAL_DESTINO_EXTERIOR');
        }
      }

      return null;
    }),
    entrada: Ember.computed('codigo', function () {
      var codigo;
      codigo = this.get('codigo');
      return codigo != null && (codigo.startsWith('1') || codigo.startsWith('2') || codigo.startsWith('3'));
    }),
    validationMessage: function validationMessage(notaFiscal) {
      var ufDest, ufEmit;

      if (notaFiscal.get('tipoDocumento') === 0 && !this.get('entrada')) {
        return 'O tipo da nota é de entrada, mas aparentemente o CFOP escolhido é de saída (não começa com o número 1, 2 ou 3)';
      }

      if (notaFiscal.get('tipoDocumento') === 1 && this.get('entrada')) {
        return 'O tipo da nota é de saida, mas aparentemente o CFOP escolhido é de entrada (começa com o número 1, 2 ou 3)';
      }

      if (notaFiscal.get('finalidadeEmissao') === 5 && !this.get('devolucao')) {
        return 'A finalidade de emissão da nota é de devolução, mas aparentemente o CFOP escolhido não é de devolução.\n Verifique com seu contador o CFOP correto.';
      }

      if (notaFiscal.get('finalidadeEmissao') !== 4 && this.get('devolucao')) {
        return "O CFOP escolhido é de devolução, mas a nota não tem finalidade de emissão de devolução.\n Talvez você precise trocar a finalidade de emissão da nota na aba \"Identificação\".";
      }

      ufDest = notaFiscal.get('person.municipio.siglaUf');
      ufEmit = notaFiscal.get('emitente.uf');

      if (!ufDest || !ufEmit || ufDest === '' || ufEmit === '') {
        return '';
      }

      if (notaFiscal.get('localDestino') === 0) {
        if (ufDest === ufEmit && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_INTERNA')) {
          return 'Para operações dentro do estado, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '1' : '5');
        }

        if (ufDest !== ufEmit && ufDest !== 'EX' && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_INTERESTADUAL')) {
          return 'Para operações fora do estado, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '2' : '6');
        }

        if (ufDest !== ufEmit && ufDest === 'EX' && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_EXTERIOR')) {
          return 'Para operações para o exterior, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '3' : '7');
        }
      } else {
        if (notaFiscal.get('localDestino') === this.get('constants.cfop.LOCAL_DESTINO_INTERNA') && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_INTERNA')) {
          return 'Para operações dentro do estado, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '1' : '5');
        }

        if (notaFiscal.get('localDestino') === this.get('constants.cfop.LOCAL_DESTINO_INTERESTADUAL') && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_INTERESTADUAL')) {
          return 'Para operações fora do estado, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '2' : '6');
        }

        if (notaFiscal.get('localDestino') === this.get('constants.cfop.LOCAL_DESTINO_EXTERIOR') && this.get('localDestino') !== this.get('constants.cfop.LOCAL_DESTINO_EXTERIOR')) {
          return 'Para operações para o exterior, o CFOP deve começar com ' + (notaFiscal.get('tipoDocumento') === 0 ? '3' : '7');
        }
      }

      return '';
    }
  });

  _exports.default = _default;
});