define("api-dashboard/templates/components/inputs/input-form-generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gl+vharJ",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[28,\"base/abstract-input-form-container\",null,[[\"nomeComponenteInput\",\"placeholder\",\"valueInput\",\"vazioValido\"],[[24,[\"nomeComponenteInput\"]],[24,[\"label\"]],[24,[\"valueInput\"]],[24,[\"allowEmpty\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"\\n        \"],[7,\"small\",true],[10,\"class\",\"text-danger\"],[8],[1,[23,1,[\"message\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-form-generic.hbs"
    }
  });

  _exports.default = _default;
});