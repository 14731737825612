define("api-dashboard/templates/components/notas-fiscais/index-modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEdDhj7U",
    "block": "{\"symbols\":[],\"statements\":[[4,\"base/modals/modal-generico\",null,[[\"title\",\"componentReference\",\"onCancel\"],[\"Cartas de Correção\",[24,[\"attrsHash\",\"cartaCorrecao\",\"modalComponent\"]],[24,[\"attrsHash\",\"cartaCorrecao\",\"onCancelModal\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"cartas-correcao/carta-correcao-form\",null,[[\"attrsHash\"],[[24,[\"attrsHash\",\"cartaCorrecao\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/index-modals.hbs"
    }
  });

  _exports.default = _default;
});