define("api-dashboard/models/z-reduction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dataMovimento: _emberData.default.attr('date'),
    modo: _emberData.default.attr('string'),
    contReinicioOperacao: _emberData.default.attr('string'),
    contReducaoZ: _emberData.default.attr('string'),
    contOrdemOperacaoFmt: _emberData.default.attr('string'),
    contGeralOperNaoFiscais: _emberData.default.attr('string'),
    contCupomFiscal: _emberData.default.attr('string'),
    contGeralRelGer: _emberData.default.attr('string'),
    contFitaDetalheEmitida: _emberData.default.attr('string'),
    contOperNaoFiscaisCanceladas: _emberData.default.attr('string'),
    contCupomFiscalCancelados: _emberData.default.attr('string'),
    contOperacaoesNaoFiscais: _emberData.default.attr('string'),
    contEspecificosRelGer: _emberData.default.attr('string'),
    contCompDebCred: _emberData.default.attr('string'),
    contCompDebCredNaoEmitido: _emberData.default.attr('string'),
    contCompDebCredCancelados: _emberData.default.attr('string'),
    totGeralFmt: _emberData.default.attr('number'),
    totParciaisTrib: _emberData.default.attr('string'),
    totIsencaoIcms: _emberData.default.attr('string'),
    totNaoIncidenciaIcms: _emberData.default.attr('string'),
    totSubstTribIcms: _emberData.default.attr('string'),
    totIsencaoIssqn: _emberData.default.attr('string'),
    totNaoIncidenciaIssqn: _emberData.default.attr('string'),
    totSubstTribIssqn: _emberData.default.attr('string'),
    totDescontosIcmsFmt: _emberData.default.attr('number'),
    totDescontosIssqn: _emberData.default.attr('string'),
    totAcrescimosIcms: _emberData.default.attr('string'),
    totAcrescimosIssqn: _emberData.default.attr('string'),
    totCancelamentosIcmsFmt: _emberData.default.attr('number'),
    totCancelamentosIssqn: _emberData.default.attr('string'),
    totParcNaoSujeitosIcms: _emberData.default.attr('string'),
    totSangria: _emberData.default.attr('string'),
    totSuprimento: _emberData.default.attr('string'),
    totCancelamentosNaoFiscais: _emberData.default.attr('string'),
    totDescontosNaoFiscais: _emberData.default.attr('string'),
    totAcrescimosNaoFiscais: _emberData.default.attr('string'),
    aliqTrib: _emberData.default.attr('string'),
    dataHoraReducao: _emberData.default.attr('date'),
    fingerprint: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    mapaFiscalId: _emberData.default.attr('number'),
    vendaBruta: _emberData.default.attr('number'),
    emitiuRegistrosPaf: _emberData.default.attr('boolean'),
    partialLoad: _emberData.default.attr('boolean'),
    contOrdemOperacaoAnterior: _emberData.default.attr('string'),
    totGeralAnterior: _emberData.default.attr('string'),
    infoAliquotasText: _emberData.default.attr('string'),
    pos: _emberData.default.belongsTo('pos')
  });

  _exports.default = _default;
});