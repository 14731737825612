define("api-dashboard/components/pos-users/pos-user-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    constants: Ember.inject.service(),
    model: null,
    searchModelName: 'pos-user',
    title: 'Colaborador (PDV)',
    titlePlural: 'Colaboradores (PDV)',
    newRoute: 'pos-users/new',
    editRoute: 'pos-users/edit',
    partialStats: null,
    allowDelete: false,
    columns: [{
      field: 'code',
      label: 'Código'
    }, {
      field: 'name',
      label: 'Nome'
    }, {
      field: 'statusLocalized',
      label: 'Status'
    }],
    searchParams: Ember.computed(function () {
      return [{
        component: 'inputs/input-toggle',
        field: 'search_inactives',
        label: 'Mostrar Inativos',
        param: 'search_inactives',
        value: false
      }];
    }),
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});