define("api-dashboard/components/sales/sales-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    globalLookups: Ember.inject.service(),
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    model: null,
    searchModelName: 'sale',
    title: 'Venda',
    titlePlural: 'Vendas',
    editRoute: 'sales/edit',
    partialStats: null,
    showFilters: true,
    showRefinedSearch: true,
    allowDelete: false,
    onlyShowItem: true,
    columns: Ember.computed(function () {
      var columns;
      columns = [];
      columns.addObject({
        field: 'soldAt',
        label: 'Data da Venda',
        format: 'date',
        options: {
          format: 'DD/MM/YYYY'
        }
      });
      columns.addObject({
        field: 'fStore.name',
        label: 'Loja'
      });
      columns.addObject({
        field: 'number',
        label: 'Número'
      });

      if (this.get('application.currentDomain.fiscalEnabled')) {
        columns.addObject({
          field: 'coo',
          label: 'N. Cupom'
        });
        columns.addObject({
          field: 'numeroNfceFormatted',
          label: 'N. NFCe'
        });
      }

      columns.addObject({
        field: 'statusFormatted',
        label: 'Status'
      });

      if (this.get('application.currentDomain.multiplePosUsers')) {
        columns.addObject({
          field: 'posUser.name',
          label: 'Vendedor'
        });
      }

      columns.addObject({
        field: 'customerNameOrConsumer',
        label: 'Cliente'
      });
      columns.addObject({
        field: 'totalPriceSold',
        label: 'Total Vendido',
        format: 'currency'
      });
      columns.addObject({
        field: 'discount',
        label: 'Desconto',
        format: 'currency'
      });
      columns.addObject({
        field: 'credit',
        label: 'Crédito',
        format: 'currency'
      });
      columns.addObject({
        field: 'totalPriceReturned',
        label: 'Total Devoluções',
        format: 'currency'
      });
      columns.addObject({
        field: 'netValue',
        label: 'Valor Líquido',
        format: 'currency'
      });
      return columns;
    }),
    metaLabels: [{
      label: 'Vendas: ',
      field: 'total',
      type: ''
    }, {
      label: 'Total Vendido: ',
      field: 'total_price_sold',
      type: 'currency'
    }, {
      label: 'Total Liquido: ',
      field: 'net_value',
      type: 'currency'
    }, {
      label: 'Total Devolvido: ',
      field: 'total_price_returned',
      type: 'currency'
    }, {
      label: 'Total de Descontos: ',
      field: 'discount',
      type: 'currency'
    }, {
      label: 'Total de Créditos: ',
      field: 'credit',
      type: 'currency'
    }],
    searchParams: Ember.computed(function () {
      return [{
        label: 'Loja',
        field: 'id',
        param: 'store_id',
        component: 'inputs/input-select',
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        value: null
      }, {
        label: 'Número',
        field: 'number',
        param: 'number',
        component: 'inputs/base-text-field',
        value: '',
        clearFix: true
      }, {
        label: 'Vendedor',
        field: 'id',
        param: 'pos_user_id',
        component: 'inputs/input-autocomplete',
        modelName: 'posUser',
        suggestionField: 'name',
        selectedRecord: null,
        value: null,
        visible: this.get('application.currentDomain.multiplePosUsers')
      }, {
        label: 'Cliente',
        field: 'id',
        param: 'customer_id',
        component: 'inputs/input-autocomplete',
        modelName: 'customer',
        suggestionField: 'name',
        selectedRecord: null,
        value: null,
        clearFix: true
      }, {
        label: 'Data De',
        field: 'soldAt',
        param: 'date_from',
        component: 'inputs/input-date'
      }, {
        label: 'Data Até',
        field: 'soldAt',
        param: 'date_to',
        component: 'inputs/input-date',
        clearFix: true
      }, {
        label: 'Valor De',
        field: 'totalPriceSold',
        param: 'total_price_sold_from',
        component: 'inputs/input-number'
      }, {
        label: 'Valor Até',
        field: 'totalPriceSold',
        param: 'total_price_sold_to',
        component: 'inputs/input-number',
        clearFix: true
      }, {
        label: 'Status',
        field: 'status',
        param: 'status',
        component: 'inputs/input-select',
        displayField: 'description',
        valueField: 'value.value',
        dataSource: this.get('constants.saleStatusValues'),
        value: null
      }, {
        label: 'Somente Sem Cliente',
        field: 'only_without_customer',
        param: 'only_without_customer',
        component: 'inputs/input-toggle',
        value: false,
        cols: 7
      }, {
        label: 'Somente Com Devolução',
        field: 'only_with_returned_items',
        param: 'only_with_returned_items',
        component: 'inputs/input-toggle',
        value: false,
        cols: 5
      }, {
        label: 'Somente Com SAT',
        field: 'only_with_sat',
        param: 'only_with_sat',
        component: 'inputs/input-toggle',
        value: false,
        cols: 7,
        visible: this.get('application.currentDomain.fiscalEnabled')
      }, {
        label: 'Somente Sem NFCe',
        field: 'only_without_nfce',
        param: 'only_without_nfce',
        component: 'inputs/input-toggle',
        value: false,
        cols: 5,
        visible: this.get('application.currentDomain.fiscalEnabled')
      }, {
        label: 'Somente Com NFCe',
        field: 'only_with_nfce',
        param: 'only_with_nfce',
        component: 'inputs/input-toggle',
        value: false,
        cols: 7,
        visible: this.get('application.currentDomain.fiscalEnabled')
      }, {
        label: 'Somente Com NFCe Emitida em Contingência',
        field: 'only_with_nfce_cont',
        param: 'only_with_nfce_cont',
        component: 'inputs/input-toggle',
        value: false,
        cols: 5,
        visible: this.get('application.currentDomain.fiscalEnabled')
      }];
    }),
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});