define("api-dashboard/components/botao-ver-mais-registros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BotaoVerMaisRegistrosComponent;
  BotaoVerMaisRegistrosComponent = Ember.Component.extend({
    tagName: "button",
    totalRegistrosCarregados: null,
    totalRegistrosPossiveis: null,
    quantRegistrosPorCarregamento: 20,
    actionOnClick: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      return this.mostrarEsconderBotao();
    },
    didInsertElement: function didInsertElement() {
      return this.mostrarEsconderBotao();
    },
    contProximosRegistros: Ember.computed("totalRegistrosCarregados", "totalRegistrosPossiveis", "quantRegistrosPorCarregamento", function () {
      var quantRegistrosPorCarregamento, registrosRestantes, retorno, totalRegistrosCarregados, totalRegistrosPossiveis;
      retorno = 0;

      try {
        totalRegistrosPossiveis = this.get("totalRegistrosPossiveis");
        totalRegistrosCarregados = this.get("totalRegistrosCarregados");
        quantRegistrosPorCarregamento = this.get("quantRegistrosPorCarregamento");
        registrosRestantes = totalRegistrosPossiveis - totalRegistrosCarregados;

        if (registrosRestantes > quantRegistrosPorCarregamento) {
          retorno = quantRegistrosPorCarregamento;
          return retorno;
        } else {
          retorno = registrosRestantes;
          return retorno;
        }
      } catch (error) {}

      return retorno;
    }),
    deveMostrarBotao: Ember.computed("totalRegistrosPossiveis", "totalRegistrosCarregados", function () {
      return !(this.get("totalRegistrosPossiveis") === this.get("totalRegistrosCarregados") || this.get("contProximosRegistros") <= 0);
    }),
    click: function click(e) {
      var self;
      self = this;
      this.indicarLoading(true);
      this.sendAction("actionOnClick", function (data) {
        if (data == null) {
          data = {};
        }

        return self.indicarLoading(false);
      });
      return e.preventDefault();
    },
    indicarLoading: function indicarLoading(indicar) {
      if (indicar == null) {
        indicar = true;
      }

      return this.$().attr("disabled", indicar);
    },
    mostrarEsconderBotao: function mostrarEsconderBotao() {
      if (!this.$() || this.$().length === 0) {
        return;
      }

      if (this.get("deveMostrarBotao")) {
        return this.$().show();
      } else {
        return this.$().hide();
      }
    }
  });
  var _default = BotaoVerMaisRegistrosComponent;
  _exports.default = _default;
});