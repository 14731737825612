define("api-dashboard/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    admin: _emberData.default.attr('boolean'),
    isDomainAdmin: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permBasics: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permNfe: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permSales: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permFin: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permStock: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    permStockTransfer: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    password: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    userStores: _emberData.default.hasMany('user_store')
  });

  _exports.default = _default;
});