define("api-dashboard/components/formularios/tax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    className: 'form-group',
    tagName: 'div',
    taxRates: null,
    showNewStateInput: false,
    taxTypeNullChecked: Ember.computed('taxRates.length', 'taxRates.@any._destroy', function () {
      return this.get('taxRates.length') === 0 || this.get('taxRates').every(function (item) {
        return item.get('_destroy');
      });
    }),
    taxTypeSetChecked: Ember.computed('taxTypeNullChecked', function () {
      return !this.get('taxTypeNullChecked');
    }),
    filteredTaxRates: function filteredTaxRates() {
      return this.get('taxRates').filter(function (item, index, self) {
        return !item.get('_destroy');
      });
    },
    actions: {
      deleteTaxRate: function deleteTaxRate(item) {
        return item.set("_destroy", true);
      },
      addNewTaxRate: function addNewTaxRate() {
        var taxRates;
        taxRates = this.get("taxRates");

        if (taxRates !== null) {
          return taxRates.createRecord();
        }
      },
      radioClicked: function radioClicked(value) {
        if (value === 'isento') {
          return this.send('disableTaxForm');
        } else if (value === 'definido') {
          return this.send('enableTaxForm');
        }
      },
      enableTaxForm: function enableTaxForm() {
        var self, ufs;
        self = this;
        ufs = self.get('application.currentDomain.visibleStores').map(function (item) {
          return item.get('siglaUf');
        }).uniq();
        return ufs.forEach(function (item, index) {
          if (item !== null) {
            if (self.get('taxRates').findBy('siglaUf', item)) {
              return;
            }

            return self.get('taxRates').createRecord({
              siglaUf: item,
              taxType: 'I'
            });
          }
        });
      },
      disableTaxForm: function disableTaxForm() {
        var i, item, ref, results, self;
        self = this;
        ref = this.get('taxRates');
        results = [];

        for (i = ref.length - 1; i >= 0; i += -1) {
          item = ref[i];

          if (item) {
            if (item.get('isNew')) {
              results.push(self.get('taxRates').removeObject(item));
            } else {
              results.push(item.set('_destroy', true));
            }
          } else {
            results.push(void 0);
          }
        }

        return results;
      }
    }
  });

  _exports.default = _default;
});