define("api-dashboard/templates/components/customers/address-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ihgb8g9A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-cep\",null,[[\"label\",\"val\",\"actionOnFimValidacao\",\"maxlength\",\"cols\",\"fetching\",\"addressType\"],[\"CEP\",[24,[\"model\",\"cep\"]],[28,\"action\",[[23,0,[]],[24,[\"fillAddressFields\"]]],null],7,\"3\",[24,[\"fetchingCep\"]],[24,[\"addressType\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"label\",\"cols\",\"placeholder\",\"disabled\"],[\"Logradouro\",[24,[\"model\",\"logradouro\"]],100,\"Logradouro\",\"6\",\"Rua, Av., Travessa, etc\",[24,[\"logradouroDisabled\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\"],[\"Número\",[24,[\"model\",\"numero\"]],6,\"3\",\"Número\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\"],[\"Complemento\",[24,[\"model\",\"complemento\"]],40,\"4\",\"Informações Complementares\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"label\",\"val\",\"maxlength\",\"cols\",\"placeholder\",\"disabled\"],[\"Bairro\",[24,[\"model\",\"bairro\"]],40,\"4\",\"Bairro\",[24,[\"bairroDisabled\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"modelName\",\"suggestionField\",\"selectedRecord\",\"cols\",\"placeholder\",\"disabled\"],[\"Cidade\",\"municipio\",\"nomeAndUf\",[24,[\"model\",\"municipio\"]],\"4\",\"Cidade ou Município\",[24,[\"cidadeDisabled\"]]]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"modelName\",\"suggestionField\",\"selectedRecord\",\"cols\"],[\"País\",\"pais\",\"nome\",[24,[\"model\",\"pais\"]],\"4\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/customers/address-form.hbs"
    }
  });

  _exports.default = _default;
});