define("api-dashboard/routes/notas-fiscais/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var modelPromise, self;
      self = this;
      modelPromise = [this.get('store').findRecord('nota-fiscal', params['id'], {
        reload: true
      })];
      return Ember.RSVP.hashSettled(modelPromise).then(function (settledPromises) {
        var model;
        model = settledPromises[0].value;

        if (model.get('isAutorizada') || model.get('isCancelada')) {
          return self.transitionTo('notas-fiscais/show', model);
        } else {
          return model;
        }
      });
    },
    actions: {
      editDocImportacao: function editDocImportacao(doc) {
        if (doc.get('isNew')) {
          return this.transitionTo("documentos-importacao/new", {
            queryParams: {
              itemId: doc.get('item.id')
            }
          });
        } else {
          return this.transitionTo("documentos-importacao/edit", doc);
        }
      },
      cancel: function cancel() {
        return this.transitionTo("notas-fiscais/index");
      }
    }
  });

  _exports.default = _default;
});