define("api-dashboard/templates/components/inputs/input-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n+9ZMwPH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[10,\"for\",\"selectBoxDiv\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n    \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\" * \"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"id\",\"selectBoxDiv\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-select.hbs"
    }
  });

  _exports.default = _default;
});