define("api-dashboard/templates/components/sales/details-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MRE+hHU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"Observações:\"],[9],[0,\"\\n      \"],[1,[28,\"textarea\",null,[[\"value\",\"rows\",\"cols\",\"class\"],[[24,[\"model\",\"comment\"]],\"4\",\"30\",\"form-control\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"isCanceled\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Venda cancelada em:\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"format/date\",[[24,[\"model\",\"cancelTimestamp\"]]],[[\"format\"],[\"DD/MM/YYYY HH:mm:ss\"]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Usuário que cancelou:\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"cancelPosUser\",\"login\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"Motivo do cancelamento:\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"cancelMotive\"]],false],[9],[0,\"\\n  \"],[9],[0,\"  \\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/sales/details-tab.hbs"
    }
  });

  _exports.default = _default;
});