define("api-dashboard/components/lancamentos/lancamento-index", ["exports", "api-dashboard/components/base/base-index", "api-dashboard/components/base/omnisearch-index"], function (_exports, _baseIndex, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    constants: Ember.inject.service(),
    editLancamentoModal: null,
    allowDelete: false,
    editRoute: null,
    model: null,
    newRoute: 'lancamentos/new',
    partialActions: 'components/lancamentos/lancamentos-index-actions',
    searchFieldsComponent: 'lancamentos/lancamentos-filters',
    searchModelName: 'parcela-lancamento',
    showNoRecordsLayout: false,
    title: 'Lançamento',
    titlePlural: 'Lançamentos',
    showDownloadCsv: true,
    columnData: 'Data de Vencimento',
    fieldData: 'dataVencimento',
    columns: Ember.computed('columnData', function () {
      return [{
        field: this.fieldData,
        label: this.columnData,
        format: 'date'
      }, {
        field: 'lancamento.tipoDescription',
        label: 'Tipo'
      }, {
        field: 'status',
        label: 'Situação'
      }, {
        field: 'lancamento.person.nomeOuRazaoSocial',
        label: 'Cliente/Fornecedor'
      }, {
        field: 'descricaoFormatted',
        label: 'Descrição'
      }, {
        field: 'posEntry.typeDescriptionNFCe',
        label: 'Forma de Pgto.'
      }, {
        field: 'valorLiquido',
        label: 'Valor',
        format: 'big'
      }, {
        field: 'lancamento.accountEntry.completeDescription',
        label: 'Plano de Conta'
      }];
    }),
    metaLabels: [{
      label: 'Lançamentos: ',
      field: 'total',
      type: ''
    }, {
      label: 'Total Liquido: ',
      field: 'total_liquido',
      type: 'currency'
    }, {
      label: 'Total de Pagamentos: ',
      field: 'total_pagamento',
      type: 'currency'
    }, {
      label: 'Total de Recebimentos: ',
      field: 'total_recebimento',
      type: 'currency'
    }],
    searchParams: Ember.computed(function () {
      var monthEnd, monthIni, self;
      self = this;
      monthIni = moment().startOf('month').format('YYYY/MM/DD');
      monthEnd = moment().endOf('month').format('YYYY/MM/DD');
      return [{
        label: 'Tipo de Lançamento',
        field: '',
        param: 'tipo',
        component: 'inputs/input-select',
        options: self.get('constants.lancamentoSearchTypes'),
        displayField: 'description',
        valueField: 'value',
        value: ''
      }, {
        label: 'Situacao',
        field: '',
        param: 'tipo_data_vencimento',
        component: 'inputs/input-select',
        options: self.get('constants.lancamentoSearchSituacao'),
        displayField: 'description',
        valueField: 'value',
        value: '383'
      }, {
        label: 'Filtrar Por',
        field: '',
        param: 'tipo_data',
        component: 'inputs/input-select',
        options: self.get('constants.lancamentoSearchDateTypes'),
        displayField: 'description',
        valueField: 'value',
        value: 'data_vencimento',
        prompt: 'Escolha o filtro para qual a data se refere',
        cols: 6
      }, {
        label: 'De',
        component: 'inputs/input-date',
        field: 'periodo_de',
        param: 'periodo_de',
        value: monthIni,
        cols: 3,
        format: 'date'
      }, {
        label: 'Até',
        component: 'inputs/input-date',
        field: 'periodo_ate',
        param: 'periodo_ate',
        value: monthEnd,
        cols: 3,
        format: 'date'
      }, {
        label: 'Cliente/Fornecedor',
        field: 'personId',
        param: 'person_id',
        component: 'inputs/input-autocomplete',
        modelName: 'person',
        suggestionField: 'name',
        selectedRecord: null,
        value: null
      }, {
        param: 'tipo_periodo',
        value: 'especificar',
        visible: false
      }, {
        label: 'Forma de Pagamento',
        field: '',
        param: 'pos_entry_type',
        component: 'inputs/input-select',
        options: self.get('constants.lancamentoSearchPaymentType'),
        displayField: 'description',
        valueField: 'value',
        value: ''
      }];
    }),
    moreSearchParams: [{
      label: 'Plano de Conta',
      field: '',
      param: 'conta_contabil_id',
      component: 'inputs/input-autocomplete',
      modelName: 'conta-contabil',
      suggestionField: 'descricao',
      selectedRecord: null
    }, {
      label: 'Centro de Custo',
      field: '',
      param: 'centro_custo_id',
      component: 'inputs/input-autocomplete',
      modelName: 'cost-center',
      suggestionField: 'descricao',
      selectedRecord: null
    }, {
      label: 'Descrição',
      field: '',
      param: 'descricao',
      component: 'inputs/base-text-field',
      val: ''
    }, {
      label: 'Núm. do Documento',
      field: '',
      param: 'num_documento',
      component: 'inputs/base-text-field',
      val: ''
    }, {
      label: 'Vendedor',
      field: '',
      param: 'pos_user_id',
      component: 'inputs/input-autocomplete',
      modelName: 'pos-user',
      suggestionField: 'name',
      selectedRecord: null
    }, {
      label: 'Cheque em nome de',
      field: '',
      param: 'check_name',
      component: 'inputs/base-text-field',
      val: ''
    }, {
      label: 'Valor de',
      field: '',
      param: 'valor_de',
      component: 'inputs/input-money',
      val: ''
    }, {
      label: 'Valor até',
      field: '',
      param: 'valor_ate',
      component: 'inputs/input-money',
      val: '',
      clearFix: true,
      clearDiv: 15
    }, {
      label: 'Mostrar Apenas Lançamentos Sem Plano de Conta Atribuído',
      field: '',
      param: 'sem_conta_contabil',
      component: 'inputs/input-toggle',
      value: false,
      cols: 12
    }],
    init: function init(args) {
      return this._super(args);
    },
    updateColumnLabel: function updateColumnLabel() {
      var params;
      params = this.get('searchParams');

      if (params[2].value === 'data_competencia') {
        this.set('columnData', 'Data de Competência');
        return this.set('fieldData', 'lancamento.dataCompetencia');
      } else if (params[2].value === 'data_vencimento') {
        this.set('columnData', 'Data de Vencimento');
        return this.set('fieldData', 'dataVencimento');
      } else {
        this.set('columnData', 'Data de Pagamento/Recebimento');
        return this.set('fieldData', 'dataBaixado');
      }
    },
    actions: {
      search: function search(append, useRefinedSearch) {
        var moreSearchParams;
        this.updateColumnLabel();
        append = append === void 0 || append === null ? false : append;
        useRefinedSearch = useRefinedSearch || true;
        moreSearchParams = this.get('showMoreFilters') ? this.get('moreSearchParams') : null;
        return this._super(append, useRefinedSearch, moreSearchParams);
      },
      downloadCsv: function downloadCsv(append, useRefinedSearch, customParams) {
        var moreSearchParams;
        this.updateColumnLabel();
        append = append === void 0 || append === null ? false : append;
        useRefinedSearch = useRefinedSearch || true;
        moreSearchParams = this.get('showMoreFilters') ? this.get('moreSearchParams') : null;
        return this._super(append, useRefinedSearch, moreSearchParams);
      },
      "delete": function _delete(item) {
        var lancamento;

        if (confirm('Tem certeza que deseja apagar esse registro?')) {
          lancamento = item.get('lancamento.content');
          lancamento.deleteRecord();
          lancamento.save().then(function () {
            return alert("Registro apagado com sucesso");
          }, function (reason) {
            return alert(reason["errors"][0]["detail"]["base"]);
          });

          if (this.afterDelete) {
            return this.afterDelete(item);
          }
        }
      }
    }
  });

  _exports.default = _default;
});