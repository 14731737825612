define("api-dashboard/templates/components/base/form-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tEhPiBPG",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[4,\"if\",[[24,[\"internalSpinner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[8],[9],[0,\"  \\n\"]],\"parameters\":[]},null],[7,\"b\",true],[8],[1,[22,\"internalGeneralMessage\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"internalMessages\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"internalMessages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/form-messages.hbs"
    }
  });

  _exports.default = _default;
});