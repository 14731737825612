define("api-dashboard/components/suppliers/supplier-form", ["exports", "api-dashboard/components/customers/customer-form"], function (_exports, _customerForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerForm.default.extend({
    createInstallmentCredit: function createInstallmentCredit() {
      return null;
    },
    actions: {
      onSubmit: function onSubmit(supplier) {
        return this.sendAction('afterSave', supplier);
      },
      onCancel: function onCancel(supplier) {
        if (supplier == null) {
          supplier = null;
        }

        return this.sendAction('onCancel', supplier);
      }
    }
  });

  _exports.default = _default;
});