define("api-dashboard/routes/supplier-categories/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = RotaAutenticavelRoute.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').findRecord('supplier-category', params['id'], {
        reload: true
      });
    },
    actions: {
      afterSave: function afterSave(record) {
        return this.transitionTo('supplier-categories/index');
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('supplier-categories/index');
      }
    }
  });

  _exports.default = _default;
});