define("api-dashboard/routes/products/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    model: function model() {
      var m;
      m = this.get('store').createRecord('product');
      m.fillParametros();
      return m;
    },
    actions: {
      afterSave: function afterSave(product) {
        return this.transitionTo('products/index');
      },
      onCancel: function onCancel(product) {
        if (product == null) {
          product = null;
        }

        return this.transitionTo('products/index');
      }
    }
  });

  _exports.default = _default;
});