define("api-dashboard/components/supplier-categories/supplier-category-index", ["exports", "api-dashboard/components/base/base-index"], function (_exports, _baseIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseIndex.default.extend({
    model: null,
    title: 'Categoria de Fornecedor',
    titlePlural: 'Categorias de Fornecedor',
    newRoute: 'supplier-categories/new',
    editRoute: 'supplier-categories/edit',
    layoutColumns: 6,
    columns: [{
      field: 'name',
      label: 'Nome'
    }]
  });

  _exports.default = _default;
});