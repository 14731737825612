define("api-dashboard/models/volume", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    _destroy: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    domainId: _emberData.default.attr('number'),
    especie: _emberData.default.attr('string'),
    marca: _emberData.default.attr('string'),
    numeracao: _emberData.default.attr('string'),
    pesoBruto: _emberData.default.attr('big'),
    pesoLiquido: _emberData.default.attr('big'),
    quantidade: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('date'),
    notaFiscal: _emberData.default.belongsTo('notaFiscal')
  });

  _exports.default = _default;
});