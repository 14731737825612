define("api-dashboard/templates/components/suppliers/config-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mvB7/xFB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"modelName\",\"componentReference\",\"suggestionField\",\"selectedRecord\",\"cols\"],[\"Departamento Padrão\",\"department\",[24,[\"departments\"]],\"name\",[24,[\"model\",\"department\"]],\"4\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"label\",\"modelName\",\"componentReference\",\"suggestionField\",\"selectedRecord\",\"cols\"],[\"Tipo de Produto Padrão\",\"product-type\",[24,[\"productTypes\"]],\"name\",[24,[\"model\",\"productType\"]],\"4\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"inputs/input-percentage\",null,[[\"label\",\"val\",\"cols\"],[\"Markup Padrão\",[24,[\"model\",\"markup\"]],\"6\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/input-percentage\",null,[[\"label\",\"val\",\"cols\"],[\"Comissão\",[24,[\"model\",\"commission\"]],\"6\"]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"active\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--danger\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"deactivate\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye-slash\"],[8],[9],[0,\"\\n       Desativar este Fornecedor\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"activate\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[8],[9],[0,\"\\n       Ativar este Fornecedor\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/suppliers/config-form.hbs"
    }
  });

  _exports.default = _default;
});