define("api-dashboard/components/base/form-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["alert"],
    classNameBindings: ["success:alert-success", "error:alert-danger", "warning:alert-warning", "info:alert-info"],
    success: Ember.computed.equal("type", "S"),
    error: Ember.computed.equal("type", "E"),
    warning: Ember.computed.equal("type", "W"),
    info: Ember.computed.equal("type", "I"),
    type: "E",
    spinner: false,
    shouldShow: Ember.computed("generalMessage", "messages.length", function () {
      return this.get("generalMessage") || this.get("messages.length") > 0;
    }),
    didInsertElement: function didInsertElement() {
      return this.$().hide();
    },
    didRender: function didRender() {
      var me, self, shouldShow;
      self = this;
      me = this.$();
      shouldShow = this.get("shouldShow");

      if (shouldShow) {
        this.updateInternalValues();
        return this.$().fadeIn("fast");
      } else {
        return this.$().fadeOut("fast", function () {
          return self.updateInternalValues();
        });
      }
    },
    updateInternalValues: function updateInternalValues() {
      this.set("internalMessages", this.get("messages"));
      this.set("internalGeneralMessage", this.get("generalMessage"));
      return this.set("internalSpinner", this.get("spinner"));
    }
  });

  _exports.default = _default;
});