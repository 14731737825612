define("api-dashboard/routes/supplier-categories/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isUserAuthorized: function isUserAuthorized(user) {
      return this.application.get('currentUser.permBasics');
    },
    model: function model(params, transition) {
      return this.get('store').findAll('supplierCategory');
    }
  });

  _exports.default = _default;
});