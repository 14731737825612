define("api-dashboard/helpers/sim-ou-nao", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.simOuNao = void 0;
  var simOuNao;
  _exports.simOuNao = simOuNao;

  _exports.simOuNao = simOuNao = function simOuNao(params, namedParams) {
    var value;
    value = params[0];

    if (value) {
      return 'Sim';
    } else {
      return 'Não';
    }
  };

  var _default = Ember.Helper.helper(simOuNao);

  _exports.default = _default;
});