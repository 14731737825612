define("api-dashboard/components/inputs/product/product-list-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    field: null,
    item: null,
    editable: true,
    promotionDiscountType: null,
    promotionDiscountRate: null,
    classNames: [],
    colClasses: Ember.computed('colType', function () {
      var classes, colType;
      colType = this.get('colType');
      classes = [];

      if (colType === 'big' || colType === 'input-int') {
        classes.push('text-right');
      }

      if (colType !== 'input-int' || !this.get('editable')) {
        classes.push('ps-column-pad');
      }

      return classes.join(" ");
    }),
    colType: Ember.computed('field', function () {
      var field;
      field = this.get('field');

      switch (field) {
        case 'quantity':
          return 'input-int';

        case 'itemPrice':
        case 'price':
        case 'totalPrice':
        case 'averageCost':
        case 'totalAverageCost':
          return 'big';

        case 'priceWithoutDiscount':
        case 'priceWithDiscount':
          return 'big';

        case 'priceEditable':
          return 'bigEditable';

        default:
          return '';
      }
    }),
    modelField: Ember.computed('field', function () {
      var field;
      field = this.get('field');

      switch (field) {
        case 'brand':
          return 'product.supplier.name';

        case 'code':
          return 'product.labelCode';

        case 'description':
          return 'product.descriptionWithDimensions';

        case 'price':
          return 'product.price';

        case 'priceEditable':
          return 'product.price';

        case 'quantity':
          return 'quantity';

        case 'totalPrice':
          return 'totalPrice';

        case 'averageCost':
          return 'product.averageCost';

        case 'totalAverageCost':
          return 'totalAverageCost';

        case 'itemPrice':
          return 'price';

        case 'priceWithoutDiscount':
          return 'priceWithoutDiscount';

        case 'priceWithDiscount':
          return 'priceWithDiscount';

        case 'promotionItemLabel':
          return 'label';

        default:
          return '';
      }
    }),
    value: Ember.computed('modelField', 'item', 'item.quantity', 'promotionDiscountType', 'promotionDiscountRate', 'item.price', function (key, value) {
      var item, modelField;
      item = this.get('item');
      modelField = this.get('modelField');

      if (modelField === '' || item == null) {
        return;
      }

      if (arguments.length > 1) {
        return Ember.set(item, 'modelField', value);
      } else {
        return item.get(modelField);
      }
    }),
    isBig: Ember.computed('colType', function () {
      return this.get('colType') === 'big';
    }),
    isBigEditable: Ember.computed('colType', function () {
      return this.get('colType') === 'bigEditable';
    }),
    isInputInt: Ember.computed('colType', function () {
      return this.get('colType') === 'input-int';
    }),
    isDefaultType: Ember.computed('colType', function () {
      return this.get('colType') === '';
    })
  });

  _exports.default = _default;
});