define("api-dashboard/components/base/dynamic-paginator", ["exports", "api-dashboard/mixins/utils", "jquery"], function (_exports, _utils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    tagName: "ul",
    actionOnClick: null,
    mostrarProximoAnterior: true,
    totalPaginas: null,
    paginaAtual: null,
    labelBtnAnterior: "Anterior",
    labelBtnProximo: "Próximo",
    totalPaginasArray: Ember.computed("totalPaginas", function () {
      return this.intForArray(this.get("totalPaginas"));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.atualizarEstadoPaginador();
      return this._super();
    },
    didInsertElement: function didInsertElement() {
      return this.atualizarEstadoPaginador();
    },
    atualizarEstadoPaginador: function atualizarEstadoPaginador() {
      if (!this.$()) {
        return;
      }

      this.indicarPaginaAtual();
      return this.habilitarDesabilitarBotoesProximoAnterior();
    },
    indicarPaginaAtual: function indicarPaginaAtual() {
      var botaoPaginaAtual, paginaAtual;
      paginaAtual = this.get("paginaAtual");
      this.$(".active").removeClass("active");
      botaoPaginaAtual = this.$(".li-pg-" + paginaAtual);

      if (botaoPaginaAtual.length === 0) {
        return setTimeout(function () {
          botaoPaginaAtual = this.$(".li-pg-" + paginaAtual);
          return botaoPaginaAtual.addClass("active");
        }, 1);
      } else {
        return botaoPaginaAtual.addClass("active");
      }
    },
    habilitarDesabilitarBotoesProximoAnterior: function habilitarDesabilitarBotoesProximoAnterior() {
      var liAnt, liProx, paginaAtual, totalPaginas;

      if (!this.get("mostrarProximoAnterior")) {
        return;
      }

      paginaAtual = parseInt(this.get("paginaAtual"));
      totalPaginas = parseInt(this.get("totalPaginas"));
      liProx = this.$(".li-pg-prox");
      liAnt = this.$(".li-pg-ant");

      if (paginaAtual === 1) {
        liAnt.addClass("disabled");
      } else {
        liAnt.removeClass("disabled");
      }

      if (paginaAtual === totalPaginas) {
        return liProx.addClass("disabled");
      } else {
        return liProx.removeClass("disabled");
      }
    },
    cliquePaginador: function cliquePaginador(numeroPagina) {
      var callbackSucessoMudancaPagina, paginaAtual, self, totalPaginas;
      self = this;
      paginaAtual = parseInt(this.get("paginaAtual"));
      totalPaginas = parseInt(this.get("totalPaginas"));

      if (numeroPagina === paginaAtual || numeroPagina > totalPaginas || numeroPagina < 1) {
        return;
      }

      return this.sendAction("actionOnClick", numeroPagina, callbackSucessoMudancaPagina = function callbackSucessoMudancaPagina(sucessoMudancaPagina) {
        if (sucessoMudancaPagina) {
          self.set("paginaAtual", numeroPagina);
          return self.atualizarEstadoPaginador();
        }
      });
    },
    actions: {
      actCliqueBotaoNumerico: function actCliqueBotaoNumerico(numeroPagina) {
        return this.cliquePaginador(numeroPagina);
      },
      actCliqueBotaoAnterior: function actCliqueBotaoAnterior() {
        return this.cliquePaginador(parseInt(this.get("paginaAtual")) - 1);
      },
      actCliqueBotaoProximo: function actCliqueBotaoProximo() {
        return this.cliquePaginador(parseInt(this.get("paginaAtual")) + 1);
      }
    }
  });

  _exports.default = _default;
});