define("api-dashboard/components/formularios/formulario-generico", ["exports", "api-dashboard/mixins/components/bs-form-alert"], function (_exports, _bsFormAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bsFormAlert.default, {
    tagName: "form",
    actionOnSubmeterFormulario: null,
    actionOnFormularioSubmetido: null,
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      return callbackOnValidacaoCompleta(true);
    },
    submit: function submit(e) {
      var self;
      self = this;
      e.preventDefault();
      return this.validarFormulario(function (valido) {
        if (valido) {
          return self.submeterFormulario();
        }
      });
    },
    submeterFormulario: function submeterFormulario() {
      this.sendAction("actionOnFormularioSubmetido", {});
      return true;
    }
  });

  _exports.default = _default;
});