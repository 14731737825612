define("api-dashboard/components/inputs/base-masked-field", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Define um componente base para os inputs de texto com máscara, e provê os métodos necessários para
    que os campos do tipo 'input texto' com máscara funcionem corretamente.
  
    * TODOS os componentes do tipo 'input texto' devem herdar de BaseTextField, sem exceção. *
  
    Para herdar esse componente, basta importá-lo e estender-lo no componente a ser criado:
  
        import BaseMaskedField from '(...)/inputs/base-masked-field'
        export default BaseMaskedField.extend ...
  
    Parâmetros:
        @see BaseTextField
  
        - pattern:
            Define qual a máscara a ser utilizada no campo.
   */
  var _default = _baseTextField.default.extend({
    pattern: "",
    unmaskedValue: null,
    isValid: false,
    didInsertElement: function didInsertElement() {
      this.aplicarMascara();
      return this._super();
    },
    didRender: function didRender() {
      return this.handleInitVal();
    },
    handleInitVal: function handleInitVal() {
      this.set("isValid", this.validarValorMascara());
      return this.set("wasInit", true);
    },
    aplicarMascara: function aplicarMascara() {
      return this.$('input').mask(this.get("pattern"));
    },
    validarValorMascara: function validarValorMascara() {
      return true;
    },
    obterValorInput: function obterValorInput() {
      return this.obterValorSemMascara();
    },
    obterValorSemMascara: function obterValorSemMascara(component) {
      var cleanValue;
      cleanValue = component ? this.$(component).cleanVal() : this.$('input').cleanVal();
      this.aplicarMascara();
      this.set("val", cleanValue);
      return cleanValue;
    },
    change: function change() {
      this._super();

      return this.setUnmaskedValue();
    },
    setUnmaskedValue: function setUnmaskedValue() {
      this.set("unmaskedValue", this.obterValorSemMascara());
      return this.set("isValid", this.validarValorMascara());
    },
    realizarValidacoesAdicionais: function realizarValidacoesAdicionais(callbackOnValidacaoCompleta) {
      if (!this.validarValorMascara()) {
        return callbackOnValidacaoCompleta({
          valido: false
        });
      }

      return this._super(callbackOnValidacaoCompleta);
    },
    paramsParaValidacaoAssincrona: function paramsParaValidacaoAssincrona() {
      return {
        valueComMascara: this.get("val"),
        valueSemMascara: this.obterValorSemMascara()
      };
    },
    paramsRetornoFimValidacao: function paramsRetornoFimValidacao(params) {
      params = this._super(params);
      params["valueSemMascara"] = this.obterValorSemMascara();
      return params;
    }
  });

  _exports.default = _default;
});