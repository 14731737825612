define("api-dashboard/mixins/utils-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UtilsRouteMixin;
  UtilsRouteMixin = Ember.Mixin.create({
    store: Ember.inject.service(),
    unloadModelSeNaoSalvo: function unloadModelSeNaoSalvo(context, options, callback) {
      var model;

      if (callback == null) {
        callback = function callback() {};
      }

      model = context.controllerFor(options["controllerName"]).get("model");

      if (model && model.get("isNew")) {
        this.get("store").unloadRecord(model);
      }

      return callback();
    },
    resetModelSeNaoEditado: function resetModelSeNaoEditado(context, options, callback) {
      var model;

      if (callback == null) {
        callback = function callback() {};
      }

      model = context.controllerFor(options["controllerName"]).get("model");

      if (model && model.get("hasDirtyAttributes")) {
        model.rollbackAttributes();
      }

      return callback();
    }
  });
  var _default = UtilsRouteMixin;
  _exports.default = _default;
});