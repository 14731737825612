define("api-dashboard/helpers/entry-description", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_formatters.default, {
    constants: Ember.inject.service(),
    compute: function compute(params) {
      var k, ref, v;

      if (params[0] === 'Cartão') {
        if (params[1] === 'C') {
          return 'Cartão de Crédito';
        } else if (params[1] === 'D') {
          return 'Cartão de Débito';
        }
      }

      ref = this.constants.get('nfePaymentTypes');

      for (k in ref) {
        v = ref[k];

        if (v.value === params[0]) {
          return v.description;
        }
      }

      return params[0];
    }
  });

  _exports.default = _default;
});