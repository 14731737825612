define("api-dashboard/models/duplicata", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    editing: false,
    numero: _emberData.default.attr('string'),
    dataVencimento: _emberData.default.attr('date'),
    valor: _emberData.default.attr('big'),
    domainId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    _destroy: _emberData.default.attr('boolean'),
    notaFiscal: _emberData.default.belongsTo('notaFiscal')
  });

  _exports.default = _default;
});