define("api-dashboard/helpers/format/time", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_formatters.default, {
    compute: function compute(arg, options) {
      var value;
      value = arg[0];
      return this.formatTime(value, options);
    }
  });

  _exports.default = _default;
});