define("api-dashboard/components/modelos-lancamentos/modelos-lancamento-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    init: function init() {
      return this._super.apply(this, arguments);
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      }
    }
  });

  _exports.default = _default;
});