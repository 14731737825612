define("api-dashboard/components/notas-fiscais-servico/notas-fiscais-servico-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/components/nota-fiscal-servico", "ember-inflector"], function (_exports, _omnisearchIndex, _notaFiscalServico, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_notaFiscalServico.default, {
    constants: Ember.inject.service(),
    intl: Ember.inject.service(),
    application: Ember.inject.service(),
    searchModelName: 'nota-fiscal-servico',
    title: Ember.computed(function () {
      return this.get('intl').t('notas-fiscais-servico.title');
    }),
    titlePlural: Ember.computed(function () {
      return this.get('intl').t('notas-fiscais-servico.title_plural');
    }),
    newRoute: 'notas-fiscais-servico/new',
    editRoute: 'notas-fiscais-servico/edit',
    partialActions: 'components/notas-fiscais-servico/index-actions',
    hideDefaultActionButtons: true,
    nextRecordsVisible: true,
    columns: Ember.computed(function () {
      var creationDateLabel, customerNameLabel, issueDateLabel, numberLabel, statusLabel;
      numberLabel = this.get('intl').t('globals.labels.number');
      customerNameLabel = this.get('intl').t('notas-fiscais-servico.columns.customer');
      statusLabel = this.get('intl').t('globals.labels.status');
      issueDateLabel = this.get('intl').t('globals.labels.issue_date');
      creationDateLabel = this.get('intl').t('globals.labels.creation_date');
      return [{
        field: 'descricaoNumero',
        label: numberLabel
      }, {
        field: 'tomador.name',
        label: customerNameLabel
      }, {
        field: 'descricaoStatus',
        label: statusLabel,
        options: {
          intlKey: 'globals.status',
          key: 'status'
        }
      }, {
        field: 'dataEmissao',
        label: issueDateLabel,
        format: 'date',
        options: {
          format: 'DD/MM/YYYY'
        }
      }, {
        field: 'createdAt',
        label: creationDateLabel,
        format: 'date',
        options: {
          format: 'DD/MM/YYYY'
        }
      }];
    }),
    searchParams: Ember.computed(function () {
      var issueDateFromLabel, issueDateToLabel, recipientLabel, statusLabel;
      recipientLabel = this.get('intl').t('globals.labels.recipient');
      issueDateFromLabel = this.get('intl').t('notas-fiscais-servico.search_fields.issue_date_from');
      issueDateToLabel = this.get('intl').t('notas-fiscais-servico.search_fields.issue_date_to');
      statusLabel = this.get('intl').t('globals.labels.status');
      return [{
        label: recipientLabel,
        field: 'id',
        param: 'tomador_id',
        component: 'inputs/input-autocomplete',
        modelName: 'customer',
        suggestionField: 'name',
        selectedRecord: null,
        value: null
      }, {
        label: issueDateFromLabel,
        field: 'dataEmissao',
        param: 'data_emissao_ini',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        },
        hours: '00:00:00',
        component: 'inputs/input-date'
      }, {
        label: issueDateToLabel,
        field: 'dataEmissao',
        param: 'data_emissao_fim',
        format: 'date',
        options: {
          format: 'YYYY-MM-DD'
        },
        hours: '23:59:59',
        component: 'inputs/input-date'
      }, {
        label: statusLabel,
        field: 'status',
        param: 'status',
        component: 'inputs/input-select',
        displayField: 'description',
        valueField: 'value',
        options: this.get('constants.nfseStatus')
      }];
    }),
    showNoRecordsLayout: false,
    search: function search() {
      return this.get('actions.search').call(this, false, false, [{
        param: 'status',
        value: 'pendente'
      }]);
    },
    afterDelete: function afterDelete() {
      return this.search();
    },
    init: function init() {
      this.search();
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});