define("api-dashboard/components/inputs/input-number", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseTextField.default.extend({
    classNames: [''],
    type: "number",
    step: 'any',
    acceptsSign: false,
    keyDown: function keyDown(evt) {
      var acceptsSign, regex;

      if ([8, 9, 13, 16, 17, 18, 20, 27, 35, 36, 37, 38, 39, 40, 46, 110, 188, 190].includes(evt.keyCode)) {
        return;
      }

      acceptsSign = this.get('acceptsSign');

      if (!acceptsSign) {
        regex = /[-|\+.|e.]/;

        if (regex.test(evt.key)) {
          evt.preventDefault();
        }
      }

      return this._super(arguments);
    }
  });

  _exports.default = _default;
});