define("api-dashboard/components/busca-referencias/form-busca-referencias", ["exports", "api-dashboard/components/formularios/formulario-generico"], function (_exports, _formularioGenerico) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formularioGenerico.default.extend({
    actionOnSubmeterFormulario: null,
    dataInicial: null,
    dataInicialValida: true,
    dataFinal: null,
    dataFinalValida: true,
    empresa: null,
    referencia: null,
    referenciaValida: true,
    numero: null,
    numeroValido: true,
    rps: null,
    rpsValido: true,
    situacaoAutorizada: false,
    situacaoCancelada: false,
    situacaoErro: false,
    divMensagensFormulario: null,
    parametrosBusca: Ember.computed("dataInicial", "dataFinal", "empresa", "referencia", "numero", "rps", "situacaoAutorizada", "situacaoCancelada", "situacaoErro", function () {
      var arrayStatus, parametros;
      parametros = {};

      if (this.get("empresa") && this.get("empresa.id")) {
        parametros["empresa_id"] = this.get("empresa.id");
      }

      if (this.get("dataInicialValida") && this.get("dataInicial")) {
        parametros["periodo_de"] = moment(this.get("dataInicial")).format('YYYY-MM-DD');
      }

      if (this.get("dataFinalValida") && this.get("dataFinal")) {
        parametros["periodo_ate"] = moment(this.get("dataFinal")).format('YYYY-MM-DD');
      }

      if (this.get("referenciaValida") && this.get("referencia")) {
        parametros["ref"] = this.get("referencia");
      }

      if (this.get("numeroValido") && this.get("numero")) {
        parametros["numero"] = this.get("numero");
      }

      if (this.get("rpsValido") && this.get("rps")) {
        parametros["numero_rps"] = this.get("rps");
      }

      arrayStatus = [];

      if (this.get("situacaoAutorizada")) {
        arrayStatus.push("autorizada");
      }

      if (this.get("situacaoCancelada")) {
        arrayStatus.push("cancelada");
      }

      if (this.get("situacaoErro")) {
        arrayStatus.push("erro");
      }

      if (arrayStatus.length > 0) {
        parametros["status"] = arrayStatus;
      }

      if (Object.keys(parametros).length === 0) {
        parametros["n"] = true;
      }

      return parametros;
    }),
    didInsertElement: function didInsertElement() {
      return this.set("divMensagensFormulario", this.$(".alert"));
    },
    validarFormulario: function validarFormulario(callbackOnValidacaoCompleta) {
      var mensagem, mensagensErro, self;
      self = this;
      mensagensErro = [];

      if (!this.get("dataInicialValida")) {
        mensagensErro.push("A data inicial não é válida.");
      }

      if (!this.get("dataFinalValida")) {
        mensagensErro.push("A data final não é válida.");
      }

      if (mensagensErro.length > 0) {
        mensagem = this.arrayMensagensToUlLiTag(mensagensErro);
        return this.mostrarMensagemErro({
          mensagem: mensagem
        }, function () {
          return callbackOnValidacaoCompleta(false);
        });
      } else {
        return this.esconderMensagens(function () {
          return callbackOnValidacaoCompleta(true);
        });
      }
    },
    submeterFormulario: function submeterFormulario() {
      var parametrosBusca, self;
      self = this;
      parametrosBusca = this.get("parametrosBusca");

      if (!parametrosBusca.hasOwnProperty("empresa_id")) {
        this.$("#txt-autocomplete-empresa").find("input").val("");
      }

      return this.indicarEstadoLoading({
        indicar: true
      }, function () {
        return self.sendAction("actionOnSubmeterFormulario", parametrosBusca, function (sucesso, params) {
          return self.indicarEstadoLoading({
            indicar: false
          }, function () {
            if (!sucesso) {
              return self.mostrarMensagemErro({
                mensagem: params["mensagem"]
              }, function () {});
            }
          });
        });
      });
    },
    esconderMensagens: function esconderMensagens(callbackOnAnimacaoCompleta) {
      return this.esconderMensagensEmExibicao(this.get("divMensagensFormulario"), {}, callbackOnAnimacaoCompleta);
    },
    mostrarMensagemErro: function mostrarMensagemErro(params, callbackOnAnimacaoCompleta) {
      params["tipoMensagem"] = "erro";
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), params, callbackOnAnimacaoCompleta);
    },
    mostrarMensagemLoading: function mostrarMensagemLoading(callbackOnAnimacaoCompleta) {
      var params;
      params = {};
      params["tipoMensagem"] = "aviso";
      params["mensagem"] = "Buscando notas fiscais...";
      return this.mostrarMensagemAlerta(this.get("divMensagensFormulario"), params, callbackOnAnimacaoCompleta);
    },
    indicarEstadoLoading: function indicarEstadoLoading(params, callbackOnAnimationComplete) {
      this.$(":submit").attr("disabled", params["indicar"]);

      if (params["indicar"]) {
        return callbackOnAnimationComplete();
      } else {
        return this.esconderMensagens(function () {
          return callbackOnAnimationComplete();
        });
      }
    },
    actions: {
      actAtribuirDataInicial: function actAtribuirDataInicial(params, callback) {
        return this.set("dataInicialValida", params["valido"]);
      },
      actAtribuirDataFinal: function actAtribuirDataFinal(params, callback) {
        return this.set("dataFinalValida", params["valido"]);
      },
      actAtribuirEmpresa: function actAtribuirEmpresa(params, callback) {
        return this.set("empresaValida", params["valido"]);
      },
      actAtribuirReferencia: function actAtribuirReferencia(params, callback) {
        return this.set("referenciaValida", params["valido"]);
      },
      actAtribuirNumero: function actAtribuirNumero(params, callback) {
        return this.set("numeroValido", params["valido"]);
      },
      actAtribuirRps: function actAtribuirRps(params, callback) {
        return this.set("rpsValido", params["valido"]);
      },
      actValidarEmpresa: function actValidarEmpresa(params, callback) {
        return this.set("empresaValida", params["valido"]);
      },
      actSelecionarEmpresa: function actSelecionarEmpresa(params) {
        return this.set("empresa", params["record"]);
      }
    }
  });

  _exports.default = _default;
});