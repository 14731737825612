define("api-dashboard/components/base/notification-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: 'notification',
    tagName: 'li',
    classNames: ['painelFocus__menu--icon'],
    messages: Ember.A(),
    typeClass: Ember.computed('type', function () {
      if (this.get('type') === 'alert') {
        return 'fa-exclamation-triangle';
      } else {
        return 'fa-bell';
      }
    }),
    counterClass: Ember.computed('type', function () {
      if (this.get('type') === 'alert') {
        return 'badge--warning';
      } else {
        return '';
      }
    }),
    messageCount: Ember.computed('messages.length', function () {
      return this.get('messages.length');
    }),
    showMessageCountBadge: Ember.computed('messageCount', function () {
      return this.get('messageCount') > 0;
    })
  });

  _exports.default = _default;
});