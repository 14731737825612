define("api-dashboard/utils/acesso-ipi", ["exports", "api-dashboard/utils/acesso"], function (_exports, _acesso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _acesso.default.extend({
    item: null,
    mostrarAliquota: false,
    obrigarAliquota: false,
    mostrarBaseCalculo: false,
    obrigarBaseCalculo: false,
    mostrarClasseEnquadramento: false,
    obrigarClasseEnquadramento: false,
    mostrarCnpjProdutor: false,
    obrigarCnpjProdutor: false,
    mostrarCodigoEnquadramentoLegal: false,
    obrigarCodigoEnquadramentoLegal: false,
    mostrarCodigoSeloControle: false,
    obrigarCodigoSeloControle: false,
    mostrarQuantidadeSeloControle: false,
    obrigarQuantidadeSeloControle: false,
    mostrarQuantidadeTotal: false,
    obrigarQuantidadeTotal: false,
    mostrarValor: false,
    obrigarValor: false,
    mostrarValorPorUnidadeTributavel: false,
    obrigarValorPorUnidadeTributavel: false,
    mostrarOutrasInformacoes: false,
    acessos: Ember.computed('acessoIpiTodos', function () {
      return {
        ipiAliquota: {
          mostrar: this.get('acessoIpiTributados'),
          obrigar: []
        },
        ipiBaseCalculo: {
          mostrar: this.get('acessoIpiTributados'),
          obrigar: []
        },
        ipiClasseEnquadramento: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: []
        },
        ipiCnpjProdutor: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: []
        },
        ipiCodigoEnquadramentoLegal: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: this.get('acessoIpiTodos')
        },
        ipiCodigoSeloControle: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: []
        },
        ipiOutrasInformacoes: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: []
        },
        ipiQuantidadeSeloControle: {
          mostrar: this.get('acessoIpiTodos'),
          obrigar: []
        },
        ipiQuantidadeTotal: {
          mostrar: this.get('acessoIpiTributados'),
          obrigar: []
        },
        ipiValor: {
          mostrar: this.get('acessoIpiTributados'),
          obrigar: this.get('acessoIpiTributados')
        },
        ipiValorPorUnidadeTributavel: {
          mostrar: this.get('acessoIpiTributados'),
          obrigar: []
        }
      };
    }),
    acessoIpiTributados: ['00', '49', '50', '99'],
    acessoIpiNaoTributados: ['01', '02', '03', '04', '05', '51', '52', '53', '54', '55'],
    acessoIpiTodos: Ember.computed('acessoIpiTributados', 'acessoIpiNaoTributados', function () {
      return this.get('acessoIpiTributados').concat(this.get('acessoIpiNaoTributados'));
    }),
    situacaoTributaria: Ember.computed('item', 'item.ipiSituacaoTributaria', function (key, value) {
      if (this.get('item') && this.get('item.ipiSituacaoTributaria')) {
        return this.get('item.ipiSituacaoTributaria');
      }

      if (arguments.length > 1) {
        return value;
      }

      return '';
    }),
    updateAcessos: Ember.observer('item', 'situacaoTributaria', function () {
      var situacaoTributaria;
      situacaoTributaria = this.get('situacaoTributaria');
      this.set('mostrarAliquota', this.mostrar(situacaoTributaria, 'ipiAliquota'));
      this.set('obrigarAliquota', this.obrigar(situacaoTributaria, 'ipiAliquota'));
      this.set('mostrarBaseCalculo', this.mostrar(situacaoTributaria, 'ipiBaseCalculo'));
      this.set('obrigarBaseCalculo', this.obrigar(situacaoTributaria, 'ipiBaseCalculo'));
      this.set('mostrarClasseEnquadramento', this.mostrar(situacaoTributaria, 'ipiClasseEnquadramento'));
      this.set('obrigarClasseEnquadramento', this.obrigar(situacaoTributaria, 'ipiClasseEnquadramento'));
      this.set('mostrarCnpjProdutor', this.mostrar(situacaoTributaria, 'ipiCnpjProdutor'));
      this.set('obrigarCnpjProdutor', this.obrigar(situacaoTributaria, 'ipiCnpjProdutor'));
      this.set('mostrarCodigoEnquadramentoLegal', this.mostrar(situacaoTributaria, 'ipiCodigoEnquadramentoLegal'));
      this.set('obrigarCodigoEnquadramentoLegal', this.obrigar(situacaoTributaria, 'ipiCodigoEnquadramentoLegal'));
      this.set('mostrarCodigoSeloControle', this.mostrar(situacaoTributaria, 'ipiCodigoSeloControle'));
      this.set('obrigarCodigoSeloControle', this.obrigar(situacaoTributaria, 'ipiCodigoSeloControle'));
      this.set('mostrarQuantidadeSeloControle', this.mostrar(situacaoTributaria, 'ipiQuantidadeSeloControle'));
      this.set('obrigarQuantidadeSeloControle', this.obrigar(situacaoTributaria, 'ipiQuantidadeSeloControle'));
      this.set('mostrarQuantidadeTotal', this.mostrar(situacaoTributaria, 'ipiQuantidadeTotal'));
      this.set('obrigarQuantidadeTotal', this.obrigar(situacaoTributaria, 'ipiQuantidadeTotal'));
      this.set('mostrarValor', this.mostrar(situacaoTributaria, 'ipiValor'));
      this.set('obrigarValor', this.obrigar(situacaoTributaria, 'ipiValor'));
      this.set('mostrarValorPorUnidadeTributavel', this.mostrar(situacaoTributaria, 'ipiValorPorUnidadeTributavel'));
      this.set('obrigarValorPorUnidadeTributavel', this.obrigar(situacaoTributaria, 'ipiValorPorUnidadeTributavel'));
      return this.set('mostrarOutrasInformacoes', this.mostrar(situacaoTributaria, 'ipiOutrasInformacoes'));
    })
  });

  _exports.default = _default;
});