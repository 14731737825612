define("api-dashboard/templates/partials/components/inputs/autocomplete/suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EIQOIR/k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",false],[12,\"tabindex\",\"0\"],[12,\"class\",[28,\"if\",[[24,[\"s\",\"ps\"]],\"suggestion-key-on\",\"\"],null]],[3,\"action\",[[23,0,[]],\"selectItem\",[24,[\"s\"]]]],[8],[0,\"\\n  \"],[1,[24,[\"s\",\"suggestionDescription\"]],true],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/inputs/autocomplete/suggestions.hbs"
    }
  });

  _exports.default = _default;
});