define("api-dashboard/routes/users/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    isUserAuthorized: function isUserAuthorized(user) {
      return this.application.get('currentUser.isDomainAdmin');
    },
    model: function model() {
      return this.get('store').createRecord('user');
    },
    actions: {
      afterSave: function afterSave(record) {
        return this.transitionTo('users/index');
      },
      onCancel: function onCancel(record) {
        return this.transitionTo('users/index');
      }
    }
  });

  _exports.default = _default;
});