define("api-dashboard/authenticators/application", ["exports", "ember-simple-auth/authenticators/base", "api-dashboard/mixins/requests/autenticacao"], function (_exports, _base, _autenticacao) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_autenticacao.default, {
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    restore: function restore(data) {
      var self, token;
      self = this;
      token = data.token;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (token === null || token === void 0) {
          return reject();
        }

        return self.verificarTokenAindaValido(self, {
          token: token
        }, function (sucesso, dados, codigoHttp) {
          if (sucesso) {
            return resolve(data);
          } else {
            return reject(dados);
          }
        });
      });
    },
    authenticate: function authenticate(args) {
      var r, self;
      self = this;
      r = self.fazerLogin(this, args);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var dados, solvedData;

        if (r['success']) {
          dados = r['data'];
          solvedData = {
            token: dados["auth_token"],
            domains: dados["domains"],
            userId: dados['user']['id']
          };

          if (dados["domains"].length === 1) {
            solvedData["currentDomainId"] = dados["domains"][0];
          }

          return resolve(solvedData);
        } else {
          return reject(r['message']);
        }
      });
    },
    invalidate: function invalidate(data) {
      var self;
      self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return self.fazerLogout(self, {}, function (isSucesso, dados, codigoHttp) {
          if (isSucesso) {
            self.get('application').setCurrentUserId(null);
            self.get('application').setCurrentDomainId(null);
            return resolve();
          } else {
            return reject();
          }
        });
      });
    }
  });

  _exports.default = _default;
});