define("api-dashboard/serializers/saas-contract", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      emitentes: {
        embedded: "always"
      }
    }
  });

  _exports.default = _default;
});