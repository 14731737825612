define("api-dashboard/components/inputs/tree-node", ["exports", "api-dashboard/mixins/dev-express", "ember-data", "jquery"], function (_exports, _devExpress, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['treeNodePlus'],
    classNames: ['treenode-node'],
    attributeBindings: ['style'],
    node: null,
    is_expanded: false,
    onRowClick: null,
    children: [],
    _show_children: Ember.computed('node', 'node.children', function () {
      var child, children, i, j, len;

      if (this.get('node.children') && this.get('node.children.length') > 0) {
        children = this.get('node.children');

        for (i = j = 0, len = children.length; j < len; i = ++j) {
          child = children[i];

          if (children.objectAt(i) && children.objectAt(i).children.length > 0) {
            return true;
          }
        }
      }

      return false;
    }),
    style: Ember.computed('node', 'node.indent', function () {
      var styles;
      styles = [];
      styles.push("margin-left: " + this.get('node.indent') + "0px");
      return styles.join(';');
    }),
    visible: Ember.computed('node', function () {
      var children;
      children = this.get('node.children');

      if (children && children.length > 0) {
        return true;
      }

      return false;
    }),
    treeNodePlus: Ember.computed('_show_children', function () {
      return this.get('_show_children');
    }),
    expand: function expand() {
      if (this.get('_show_children')) {
        return this.set('is_expanded', true);
      } else {
        return this.collapse();
      }
    },
    collapse: function collapse() {
      return this.set('is_expanded', false);
    },
    selectTreeNodes: function selectTreeNodes() {
      this.$().addClass('treenode-selected');
      this.$().parents('.tree-node-child').addClass('treenode-selected');
      return this.$().parents('.tree-node-principal').addClass('treenode-selected');
    },
    clearSelected: function clearSelected() {
      return $('.treenode-selected').removeClass('treenode-selected');
    },
    click: function click(e) {
      this.clearSelected();

      if (this.get('onRowClick')) {
        this.sendAction('onRowClick', e, this.get('node'));
      }

      if (this.get('is_expanded')) {
        this.collapse();
      } else {
        this.expand();
      }

      this.selectTreeNodes();
      return e.stopPropagation();
    }
  });

  _exports.default = _default;
});