define("api-dashboard/templates/components/box-autenticacao", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jaV4P9eI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"boxLogin\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12 no-padding-login\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"boxLogin__logo text-center\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/imagens/logo/logo-focus-nfe.png\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"box-login\"],[10,\"style\",\"display:none;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12 no-padding-login\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"boxLogin__content\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[28,\"formularios/login-principal\",null,[[\"actionOnClickEsqueciSenha\",\"class\"],[\"actMostrarBoxRecuperacao\",\"form-login\"]]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"box-recuperacao-senha\"],[10,\"style\",\"display:none;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-12 no-padding-login\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"boxLogin__content\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[28,\"formularios/recuperacao-senha\",null,[[\"actionOnCliqueVoltar\",\"class\"],[\"actMostrarBoxLogin\",\"form-login\"]]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/box-autenticacao.hbs"
    }
  });

  _exports.default = _default;
});