define("api-dashboard/models/pos-user-store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    isCashDrawerOperator: _emberData.default.attr('boolean'),
    isManager: _emberData.default.attr('boolean'),
    isSalesperson: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    _destroy: _emberData.default.attr('boolean'),
    posUser: _emberData.default.belongsTo('pos-user'),
    fStore: _emberData.default.belongsTo('f-store')
  });

  _exports.default = _default;
});