define("api-dashboard/components/dashboards/total-customers-card", ["exports", "api-dashboard/components/dashboards/dashboard-card"], function (_exports, _dashboardCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dashboardCard.default.extend({
    stats: Ember.inject.service(),
    title: 'Total de Clientes',
    argumentField: 'name',
    valueField: 'total',
    init: function init() {
      return this._super(arguments);
    },
    dataSource: Ember.computed('chartComponent', 'stats.stats.total_customers', function () {
      return this.get('stats.stats.total_customers');
    }),
    chartComponent: Ember.computed('multiStore', function () {
      if (this.get('multiStore') === true) {
        return 'charts/bar';
      }

      return 'charts/-info';
    })
  });

  _exports.default = _default;
});