define("api-dashboard/components/base/base-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
  Descrição: Cria uma tela de index.
  
  Parâmetros:
    @model: Conjunto de dados que será mostrado. Este parâmetro pode ser informado caso a tela
            deva aparecer já com registros. Caso se faça uso da busca ele pode ser ignorado.
    @title: Título da tela, será usado em alguns locais (texto do botão de novo, título H1, etc)
    @titlePlural: Título da tela no plural
    @newRoute: rota para criar um novo registros
    @newDropdownRoutes: rotas para o botão do tipo dropdown, no qual o Usuário terá várias opções.
    @editRoute: rota para editar um registro
    @allowDelete: indica se deve ou não permitir a exclusão
    @onlyShowItem: indica que os registros não serão editados, e mostra apenas um botão para visualização.
                  A página de visualização segue a mesma rota de Edit, por enquanto
                   * FIXME: Criar uma rota para visualização
    @columns: array com definição das colunas, cada item é um objeto com as seguintes propriedades
       - field: nome do campo
       - label: label a ser mostrado
       - width: texto a ser passado para a propriedade width do TD desta coluna
    @layoutName: nome do layout a ser utilizado, caso queira sobrescrever o padrão por algum motivo
    @layoutNoRecords e @showNoRecordsLayout: Estas duas propriedades definem, em conjunto o comportamento
      para quando queremos mostrar um index diferente caso o usuário ainda não tenha realizado cadastros
      daquela entidade. Se showNoRecordsLayout for true iremos renderizar o @layoutNoRecords
    @layoutColumns: determina quantas colunas (dentro do layout Bootstrap de 12 colunas) a tabela de resultados
    @partialActions: nome do partial para adicionar mais ações na coluna de ações
    @hideDefaultActionButtons: esconde os botões padrão da coluna 'Ações'. Sendo possíveis adicionar
      botões apenas no template usado em @partialActions
  
    == Parâmetros relacionados à busca
    @searchModelName: Nome do model a ser usado na busca
    @searchParamBindMode: indica o modo com que a busca deve fazer o binding dos valores
       - property: Conta com os valores da busca presentes no hash @searchParamValues
       - value: Conta com os valores da busca presentes na propriedade value dentro do prórpio array searchParams
    @searchParams: array de hashes. Cada hash deve conter duas chaves:
                     field: Nome do atributo cujo valor deve ser passado como parâmetro da busca
                     param: Nome do parâmetro da busca em si
    @limit: Valor padrão 10. Número limite de registros a serem buscados.
    @searchFieldsComponent: Nome de um Component que deverá conter os inputs a serem usados na busca
   */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    layoutColumns: 12,
    layoutColumnOffset: Ember.computed('numColumns', function () {
      return (12 - this.get('layoutColumns')) / 2;
    }),
    model: null,
    title: '',
    titlePlural: '',
    layoutNoRecords: 'base/base-index-no-records',
    showNoRecordsLayout: false,
    layoutName: 'components/base/base-index',
    newRoute: null,
    newDropdownRoutes: null,
    editRoute: null,
    allowDelete: true,
    onlyShowItem: false,
    columns: [],
    partialActions: null,
    modalsComponent: null,
    modelName: null,
    hideDefaultActionButtons: false,
    allowShowInventory: false,
    searchModelName: '',
    searchParamBindMode: 'value',
    searchParamValues: {},
    searchParams: [],
    searchFieldsComponent: null,
    limit: 10,
    offset: 0,
    meta: null,
    metaLabels: [],
    haveResults: Ember.computed('model.length', function () {
      return this.get('model.length') > 0;
    }),
    haveMeta: Ember.computed('meta', function () {
      return this.get('meta') && this.get('metaLabels').length > 0;
    }),
    nextRecordsVisible: false,
    totalRecords: 0,
    resultsAvailable: false,
    searching: false,
    remainingRecordsCount: Ember.computed('totalRecords', 'limit', 'offset', function () {
      return this.get('totalRecords') - this.get('limit') - this.get('offset');
    }),
    nextRecordsMessage: Ember.computed('remainingRecordsCount', function () {
      var limit, nextLoad, remaining;
      remaining = this.get('remainingRecordsCount');
      limit = this.get('limit');
      nextLoad = remaining > limit ? limit : remaining;

      if (remaining === 0) {
        return '';
      } else if (remaining === 1) {
        return 'Carregar próximo registro';
      } else {
        return 'Próximos ' + nextLoad.toString() + ' registros';
      }
    }),
    init: function init() {
      return this._super.apply(this, arguments);
    },
    "delete": function _delete(item) {
      var self;
      self = this;

      if (confirm('Tem certeza que deseja excluir esse registro?')) {
        item.deleteRecord();
        return item.save().then(function () {
          alert("Registro excluído com sucesso");

          if (self.afterDelete) {
            return self.afterDelete();
          }
        }, function (reason) {
          return alert(reason["errors"][0]["detail"]["base"]);
        });
      }
    },
    actions: {
      "delete": function _delete(item) {
        return this["delete"](item);
      },
      loadMore: function loadMore() {
        this.set('offset', this.get('offset') + this.get('limit'));
        return this.send('search', true, true);
      }
    }
  });

  _exports.default = _default;
});