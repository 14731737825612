define("api-dashboard/components/spinner-default-aplicacao", ["exports", "api-dashboard/components/spinners/bootstrap-pulse"], function (_exports, _bootstrapPulse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SpinnerDefaultAplicacaoComponent;
  SpinnerDefaultAplicacaoComponent = _bootstrapPulse.default.extend({
    layoutName: "components/spinners/bootstrap-pulse"
  });
  var _default = SpinnerDefaultAplicacaoComponent;
  _exports.default = _default;
});