define("api-dashboard/templates/components/notas-fiscais/item-form/-impostos-devolvidos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZaT+P4G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"imposto_devolvido\"],[10,\"class\",\"tab-pane fade in\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[1,[28,\"inputs/input-percentage\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"max\",\"required\",\"cols\"],[\"Percentual da mercadoria devolvida\",[24,[\"model\",\"percentualDevolvido\"]],2,[24,[\"errors\",\"percentualDevolvido\"]],[24,[\"model\",\"isSaving\"]],100,false,3]]],false],[0,\"\\n      \"],[1,[28,\"inputs/input-money\",null,[[\"label\",\"val\",\"scale\",\"error\",\"disabled\",\"required\",\"cols\"],[\"Valor do IPI devolvido\",[24,[\"model\",\"valorIpiDevolvido\"]],2,[24,[\"errors\",\"valorIpiDevolvido\"]],[24,[\"model\",\"isSaving\"]],false,3]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/item-form/-impostos-devolvidos.hbs"
    }
  });

  _exports.default = _default;
});