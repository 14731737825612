define("api-dashboard/templates/components/inputs/tree-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7WNd0Vet",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"id\",\"treeview-nodes\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"dataSource\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inputs/tree-node\",null,[[\"node\",\"onRowClick\",\"class\"],[[23,1,[]],[24,[\"onRowClick\"]],\"tree-node-principal\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/tree-view.hbs"
    }
  });

  _exports.default = _default;
});