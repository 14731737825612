define("api-dashboard/components/reports/report-index", ["exports", "api-dashboard/components/base/base-index", "api-dashboard/mixins/utils", "api-dashboard/mixins/formatters", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _baseIndex, _utils, _formatters, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseIndex.default.extend(_utils.default, _formatters.default, _fileSaver.default, {
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    model: null,
    report: null,
    ac_filter_defs: null,
    search_params: [],
    init: function init(args) {
      var store;

      this._super(args);

      this.set('showDreReport', false);
      this.set('showReducaoZReport', false);
      this.set('multipleStores', this.get('application.currentDomain.multipleStores'));
      store = this.get('application.currentDomain.visibleStores').objectAt(0);

      if (store) {
        this.set('singleStoreId', store.get('id'));
      } else {
        this.set('singleStoreId', null);
      }

      return this.get_all_reports();
    },
    get_all_reports: function get_all_reports() {
      var self;
      self = this;
      return this.get('store').findAll('report_template').then(function (obj) {
        return self.set('ac_filter_defs', obj.map(function (f) {
          return f.get('acFilterDef');
        }));
      });
    },
    reportParameters: function reportParameters() {
      var i, is_filled, j, l, len, len1, o, params, ref, ref1, sp;
      params = {};
      ref = this.get('search_params');

      for (j = 0, len = ref.length; j < len; j++) {
        sp = ref[j];

        if (sp.filter && sp.filter.queryUser) {
          is_filled = !!(sp.value || sp.fieldFrom || sp.fieldTo || sp.selectedRecord);
          params[sp.name + "_is_filled"] = is_filled;

          if (sp.value) {
            if (sp.valueField && sp.value.get) {
              params[sp.name + "_filter_value"] = sp.value.get(sp.valueField);
            } else {
              params[sp.name + "_filter_value"] = sp.value;
            }
          }

          if (sp.fieldFrom || sp.fieldTo) {
            params[sp.name + "_fromDate"] = this.formatDate(sp.fieldFrom, {
              format: 'YYYY-MM-DD'
            });
            params[sp.name + "_toDate"] = this.formatDate(sp.fieldTo, {
              format: 'YYYY-MM-DD'
            });
          }

          if (sp.selectedRecord) {
            params[sp.name + "_filter_value"] = sp.selectedRecord.get('id');
          }
        } else {
          if (sp.value && sp.component === this.get('constants.inputComponentTypes.DATE')) {
            params[sp.name] = moment(sp.value).format('YYYY-MM-DD');
          } else if (sp.component === this.get('constants.inputComponentTypes.PERIOD')) {
            params[sp.nameFrom] = sp.fieldFrom;
            params[sp.nameTo] = sp.fieldTo;
          } else {
            if (sp.name === 'entry_types') {
              params[sp.name + "[]"] = [];
              ref1 = sp.options;

              for (i = l = 0, len1 = ref1.length; l < len1; i = ++l) {
                o = ref1[i];

                if (o.value) {
                  params[sp.name + "[]"].push(o.name);
                }
              }
            }

            if (sp.modelName && sp.selectedRecord) {
              params[sp.name] = sp.selectedRecord.get('id');
            }

            if (sp.value) {
              if (sp.valueField && sp.value.get) {
                params[sp.name] = sp.value.get(sp.valueField);
              } else {
                params[sp.name] = sp.value;
              }
            }
          }
        }
      }

      return params;
    },
    validateRequiredParameters: function validateRequiredParameters() {
      var filters, required_not_filled, self;
      self = this;
      filters = this.get('report.acFilterDef.acFilters');

      if (filters) {
        filters.forEach(function (filter, filter_key) {
          if (!filter.validateRequired()) {
            return false;
          }
        });
      } else {
        if (this.get('search_params')) {
          required_not_filled = this.get('search_params').filter(function (i) {
            return i.required === true && !self._validateRequiredParametersByFieldType(i);
          });
          return !required_not_filled || required_not_filled.length === 0;
        }
      }

      return true;
    },
    _validateRequiredParametersByFieldType: function _validateRequiredParametersByFieldType(param) {
      switch (param.component) {
        case this.get('constants.inputComponentTypes.TOGGLE'):
        case this.get('constants.inputComponentTypes.TOGGLE_GROUP'):
        case this.get('constants.inputComponentTypes.STRING'):
        case this.get('constants.inputComponentTypes.NUMBER'):
        case this.get('constants.inputComponentTypes.PERCENTAGE'):
        case this.get('constants.inputComponentTypes.MONEY'):
        case this.get('constants.inputComponentTypes.DATE'):
        case this.get('constants.inputComponentTypes.TIME'):
        case this.get('constants.inputComponentTypes.RADIO'):
        case this.get('constants.inputComponentTypes.CPF'):
        case this.get('constants.inputComponentTypes.CNPJ'):
        case this.get('constants.inputComponentTypes.CEP'):
        case this.get('constants.inputComponentTypes.EMAIL'):
        case this.get('constants.inputComponentTypes.PHONE'):
        case this.get('constants.inputComponentTypes.SELECT'):
          return param.required && !!param.value;

        case this.get('constants.inputComponentTypes.PERIOD'):
          return param.required && !!param.fieldFrom && !!param.fieldTo;

        case this.get('constants.inputComponentTypes.AUTOCOMPLETE'):
          return param.required && !!param.selectedRecord;

        default:
          return true;
      }
    },
    clearReportMenuSelected: function clearReportMenuSelected() {
      return this.$('ul.posReport__links li span.selectedItem').removeClass('selectedItem');
    },
    addSelectedMenuClass: function addSelectedMenuClass(target) {
      return this.$(target).addClass('selectedItem');
    },
    actions: {
      openReport: function openReport(report, event) {
        var k, r, v;
        this.set('showDreReport', false);
        this.set('showReducaoZReport', false);
        this.clearReportMenuSelected();
        this.addSelectedMenuClass(event.target);
        r = this.get("constants.reports." + report);
        report = this.get('store').peekAll('report_template').filterBy('reportType', r.type)[0];

        if (!report) {
          report = r;
        } else {
          for (k in r) {
            v = r[k];
            Ember.set(report, k, v);
          }
        }

        this.set('report', report);
        return this.get(report.param).call(this);
      },
      download_report: function download_report(format) {
        var params, report_name, report_url, self, url;
        self = this;
        self.set('buttonSpin', true);

        if (this.validateRequiredParameters()) {
          params = this.reportParameters();
          params['report_template_id'] = this.get('report.id');
          params['format'] = format;

          if (this.get('report.acFilterDef.id')) {
            params['filter_def_id'] = this.get('report.acFilterDef.id');
          }

          report_url = this.get('report.controllerName') ? this.get('report.controllerName') : this.get('report.url');
          url = '/';
          url += report_url;
          url += '?';
          url += jQuery.param(params);
          report_name = this.get('report.title') + '_' + moment(new Date()).format('DD-MM-YYYY') + '.' + format;
          return this.downloadURL(url, report_name, function () {
            return self.set('buttonSpin', false);
          });
        } else {
          alert('Por favor, preencha os parâmetros obrigatórios.');
          return self.set('buttonSpin', false);
        }
      },
      show_dre_report: function show_dre_report() {
        var dre_params;

        if (!this.get('search_params') || this.get('search_params.length') < 1 || !this.get('search_params')[0] || !this.get('search_params')[0].fieldFrom || !this.get('search_params')[0].fieldTo) {
          alert('Por favor, preencha os parâmetros obrigatórios.');
          this.set('buttonSpin', false);
          return;
        }

        dre_params = {
          period: this.get('search_params')[0],
          centro_custo_id: this.get('search_params')[1],
          ocultar_zerados: this.get('search_params')[2]
        };
        this.set('dre_report_params', dre_params);
        return this.set('showDreReport', true);
      },
      export_dre_report_csv: function export_dre_report_csv() {
        var dateFrom, dateTo, dre_params, params, self, url;
        self = this;

        if (!this.get('search_params') || this.get('search_params.length') < 1 || !this.get('search_params')[0] || !this.get('search_params')[0].fieldFrom || !this.get('search_params')[0].fieldTo) {
          alert('Por favor, preencha os parâmetros obrigatórios.');
          return;
        }

        self.set('application.searchingReport', true);
        dre_params = {
          period: this.get('search_params')[0],
          centro_custo_id: this.get('search_params')[1],
          ocultar_zerados: this.get('search_params')[2]
        };
        dateFrom = this.formatDate(dre_params.period.fieldFrom, {
          format: 'YYYY-MM-DD'
        });
        dateTo = this.formatDate(moment(dre_params.period.fieldTo).endOf('month'), {
          format: 'YYYY-MM-DD'
        });
        params = ['ocultar_zerados=' + Ember.String.htmlSafe(dre_params.hideEmptyLancamento.value), 'periodo_de=' + Ember.String.htmlSafe(dateFrom), 'periodo_ate=' + Ember.String.htmlSafe(dateTo), !!dre_params.centro_custo_id.value ? 'centro_custo_id=' + Ember.String.htmlSafe(dre_params.centro_custo_id.value) : void 0];
        url = '/relatorios/dre.csv?' + params.join('&');
        return this.downloadURL(url, "dre-" + dateFrom + "-" + dateTo + ".csv", function () {
          return self.set('application.searchingReport', false);
        });
      },
      show_reducao_z_report: function show_reducao_z_report() {
        var store_id, z_reduction_params;
        z_reduction_params = {
          store_id: null,
          data_movimento_from: null,
          data_movimento_to: null
        };

        if (this.get('search_params') || this.get('search_params.length') > 1) {
          store_id = this.get('search_params')[0].value ? this.get('search_params')[0].value.id : '';
          z_reduction_params = {
            store_id: store_id,
            data_movimento_from: this.get('search_params')[1].fieldFrom,
            data_movimento_to: this.get('search_params')[1].fieldTo
          };
        }

        this.set('z_reduction_report_params', z_reduction_params);
        return this.set('showReducaoZReport', true);
      }
    },
    get_filters_params: function get_filters_params() {
      var f, filters, i, j, p, params, ref;
      params = [];
      filters = this.get('report.acFilterDef.acFilters');

      for (i = j = 0, ref = filters.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
        f = filters.objectAt(i);
        p = {
          component: f.get('inputComponent'),
          name: f.get('name'),
          label: f.get('label'),
          value: f.get('value'),
          cols: 12,
          visible: f.get('inputComponent') != null,
          filter: f
        };

        if (f.get('inputComponent') === this.get('constants.inputComponentTypes.PERIOD')) {
          Ember.set(p, 'fieldFrom', f.get('fromDate') || null);
          Ember.set(p, 'fieldto', f.get('toDate') || null);
          Ember.set(p, 'nameFrom', 'fromDate');
          Ember.set(p, 'nameTo', 'toDate');
        }

        if (f.get('targetModel')) {
          Ember.set(p, 'modelName', f.get('targetModel'));
          Ember.set(p, 'dataSource', f.get('dataSource'));
          Ember.set(p, 'displayField', f.get('targetField'));
          Ember.set(p, 'valueField', 'id');
          Ember.set(p, 'suggestionField', f.get('targetField'));
          Ember.set(p, 'selectedRecord', null);

          if (f.get('targetModel') === 'Store') {
            Ember.set(p, 'dataSource', this.get('globalLookups.stores'));
            Ember.set(p, 'value', this.get('multipleStores') ? null : this.get('singleStoreId'));
            Ember.set(p, 'visible', this.get('multipleStores'));
          }
        }

        if (f.get('dataType') === 'options') {
          Ember.set(p, 'options', f.get('options'));
          Ember.set(p, 'displayField', 'description');
          Ember.set(p, 'valueField', 'value');
        }

        params.push(p);
      }

      params;
      return this.set('search_params', params);
    },
    notas_fiscais_consumidor_params: function notas_fiscais_consumidor_params() {
      var dataSource, params;
      params = [];
      dataSource = this.get('globalLookups.stores').then(function (obj) {
        return obj.filter(function (o) {
          return !!o.get('cnpj');
        });
      });
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: dataSource,
        displayField: 'nameAndCnpj',
        valueField: 'cnpj',
        label: 'Loja',
        name: 'document',
        value: '',
        cols: 12,
        required: true
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'from',
        nameTo: 'to',
        value: null,
        required: true
      });
      params.push({
        visible: false,
        name: 'modelo',
        value: 65,
        required: true
      });
      Ember.set(this.get('report'), 'params', params);
      return this.set('search_params', params);
    },
    notas_fiscais_params: function notas_fiscais_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.emitentes'),
        displayField: 'description',
        valueField: 'documento',
        label: 'Emitente',
        name: 'document',
        value: null,
        cols: 12,
        required: true,
        isSelect: true
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'from',
        nameTo: 'to',
        value: null,
        required: true
      });
      params.push({
        visible: false,
        name: 'modelo',
        value: 55,
        required: true
      });
      return this.set('search_params', params);
    },
    inventory_params: function inventory_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Estoque',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.AUTOCOMPLETE'),
        modelName: 'supplier',
        suggestionField: 'name',
        label: 'Fornecedor',
        name: 'supplier_id',
        selectedRecord: null,
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.TOGGLE'),
        label: 'Incluir itens zerados ou negativos',
        value: false,
        name: 'negative_items',
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.STRING'),
        label: 'Descrição contendo',
        name: 'description',
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.AUTOCOMPLETE'),
        modelName: 'product',
        suggestionField: 'description',
        constraint: 'mainProduct',
        label: 'Produto',
        name: 'product_id',
        selectedRecord: null,
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.departments'),
        displayField: 'name',
        valueField: 'id',
        label: 'Departamento',
        name: 'department_id',
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.productTypes'),
        displayField: 'name',
        valueField: 'id',
        label: 'Tipo de Produto',
        name: 'product_type_id',
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.collections'),
        displayField: 'name',
        valueField: 'id',
        label: 'Coleção',
        name: 'collection_id',
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período de Criação do Produto',
        nameFrom: 'created_from',
        nameTo: 'created_to',
        value: null
      });
      params.push({
        visible: false,
        name: 'format',
        value: 'pdf'
      });
      return this.set('search_params', params);
    },
    labels_params: function labels_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.labelModels'),
        displayField: 'name',
        valueField: 'id',
        label: 'Modelo de Etiqueta',
        name: 'label_model_id',
        value: null,
        cols: 12,
        required: true
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PRODUCT_LIST'),
        name: 'product_quantities',
        items: [],
        selectedRecord: null
      });
      return this.set('search_params', params);
    },
    pos_entries_params: function pos_entries_params() {
      var options, params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Loja',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        required: true,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'date_from',
        nameTo: 'date_to',
        value: null,
        required: true
      });
      options = this.get('constants.paymentTypes');
      options.forEach(function (o) {
        return o.name = o.value;
      });
      params.push({
        component: this.get('constants.inputComponentTypes.TOGGLE_GROUP'),
        options: options,
        displayField: 'description',
        valueField: 'value',
        label: 'Tipo de Recebimento',
        name: 'entry_types',
        value: null,
        cols: 12
      });
      return this.set('search_params', params);
    },
    cat52_params: function cat52_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Loja',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'date_from',
        nameTo: 'date_to',
        value: null
      });
      params.push({
        component: this.get('constants.inputComponentTypes.TOGGLE'),
        label: 'Gerar um arquivo por dia',
        value: false,
        name: 'daily_file',
        cols: 12
      });
      return this.set('search_params', params);
    },
    sintegra_params: function sintegra_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Loja',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        required: true,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'date_from',
        nameTo: 'date_to',
        value: null,
        required: true
      });
      params.push({
        component: this.get('constants.inputComponentTypes.TOGGLE'),
        label: 'Validate',
        value: true,
        name: 'validate',
        cols: 12,
        visible: false
      });
      return this.set('search_params', params);
    },
    sped_params: function sped_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Loja',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        required: true,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERCENTAGE'),
        valueField: 'id',
        label: 'PIS',
        name: 'pis',
        value: null,
        cols: 3
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERCENTAGE'),
        valueField: 'id',
        label: 'COFINS',
        name: 'cofins',
        value: null,
        cols: 3
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'date_from',
        nameTo: 'date_to',
        value: null,
        required: true
      });
      return this.set('search_params', params);
    },
    dre_gerencial_params: function dre_gerencial_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'periodo_de',
        nameTo: 'periodo_ate',
        value: null,
        displayFormat: 'MMMM yyyy',
        maxZoomLevel: 'year',
        required: true
      });
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.costCenters'),
        displayField: 'descricao',
        valueField: 'id',
        label: 'Centro de Custo',
        name: 'centro_custo_id',
        value: null,
        cols: 12
      });
      params.push({
        component: this.get('constants.inputComponentTypes.TOGGLE'),
        label: 'Ocultar Lançamentos zerados',
        value: false,
        name: 'ocultar_zerados',
        cols: 12
      });
      return this.set('search_params', params);
    },
    reducao_z_params: function reducao_z_params() {
      var params;
      params = [];
      params.push({
        component: this.get('constants.inputComponentTypes.SELECT'),
        dataSource: this.get('globalLookups.stores'),
        displayField: 'name',
        valueField: 'id',
        label: 'Loja',
        name: 'store_id',
        value: this.get('multipleStores') ? null : this.get('singleStoreId'),
        cols: 12,
        visible: this.get('multipleStores')
      });
      params.push({
        component: this.get('constants.inputComponentTypes.PERIOD'),
        fieldFrom: null,
        fieldTo: null,
        label: 'Período',
        nameFrom: 'data_movimento_from',
        nameTo: 'data_movimento_to',
        value: null
      });
      return this.set('search_params', params);
    }
  });

  _exports.default = _default;
});