define("api-dashboard/templates/components/base/toast-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bdtCQ7QI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"dx-toast-template\"],[10,\"style\",\"display:none;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"alert alert-\",[22,\"typeBs\"],\" alert-dismissible\"]]],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"alert\"],[10,\"aria-label\",\"close\"],[8],[0,\"×\"],[9],[0,\"\\n    \"],[1,[22,\"message\"],true],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/toast-alert.hbs"
    }
  });

  _exports.default = _default;
});