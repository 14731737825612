define("api-dashboard/utils/acesso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    situacaoTributaria: '',
    item: null,
    acessos: {},
    mostrar: function mostrar(st, campo) {
      var o;
      o = this.get('acessos.' + campo);
      return o.mostrar.indexOf(st) !== -1;
    },
    obrigar: function obrigar(st, campo) {
      var o;
      o = this.get('acessos.' + campo);
      return o.obrigar.indexOf(st) !== -1;
    }
  });

  _exports.default = _default;
});