define("api-dashboard/components/inputs/input-title", ["exports", "api-dashboard/components/inputs/base-text-field"], function (_exports, _baseTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    renders a input with a special layout
  
    Herdado de InputFormGeneric, verificar a classe básica para ter as opções
   */
  var _default = _baseTextField.default.extend({
    cssClasses: ['col-xs-6', 'form-control', 'titleFormGroup__box']
  });

  _exports.default = _default;
});