define("api-dashboard/templates/components/base/dynamic-paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ob7HwO8O",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[24,[\"mostrarProximoAnterior\"]]],null,{\"statements\":[[7,\"li\",true],[10,\"class\",\"li-pg-ant\"],[8],[0,\"\\n\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"actCliqueBotaoAnterior\"]],[8],[0,\"\\n    \"],[1,[22,\"labelBtnAnterior\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"totalPaginasArray\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[11,\"class\",[29,[\"li-pg-\",[23,1,[]]]]],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"actCliqueBotaoNumerico\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"mostrarProximoAnterior\"]]],null,{\"statements\":[[7,\"li\",true],[10,\"class\",\"li-pg-prox\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"actCliqueBotaoProximo\"]],[8],[0,\"\\n    \"],[1,[22,\"labelBtnProximo\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/dynamic-paginator.hbs"
    }
  });

  _exports.default = _default;
});