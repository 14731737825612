define("api-dashboard/templates/components/inputs/base-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4SmcqEkU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[22,\"formattedLabel\"],false],[0,\"\\n    \"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[0,\" * \"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"input\",null,[[\"classNames\",\"type\",\"max\",\"maxlength\",\"min\",\"name\",\"placeholder\",\"required\",\"size\",\"step\",\"value\",\"readonly\",\"disabled\"],[[24,[\"cssClasses\"]],[24,[\"type\"]],[24,[\"max\"]],[24,[\"maxLength\"]],[24,[\"min\"]],[24,[\"name\"]],[24,[\"placeholder\"]],[24,[\"isRequired\"]],[24,[\"size\"]],[24,[\"step\"]],[24,[\"val\"]],[24,[\"readonly\"]],[24,[\"disabled\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/base-text-field.hbs"
    }
  });

  _exports.default = _default;
});