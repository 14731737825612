define("api-dashboard/components/charts/chart", ["exports", "api-dashboard/components/charts/base-chart"], function (_exports, _baseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseChart.default.extend({
    application: Ember.inject.service(),
    tagName: 'span',
    classNames: 'dashboard__card--graphic',
    argumentField: void 0,
    showLegend: false,
    type: void 0,
    chartFunction: 'line',
    baseOptions: Ember.computed(function () {
      return {
        adaptiveLayout: {
          keepLabels: true,
          height: 80
        },
        title: {
          text: this.get('title'),
          margin: {
            bottom: 30
          }
        },
        argumentAxis: {
          label: {
            visible: false
          }
        },
        dataSource: this.get('dataSource'),
        legend: {
          horizontalAlignment: "right",
          margin: 0,
          verticalAlignment: "top",
          visible: this.get('showLegend')
        },
        panes: {
          backgroundColor: "none",
          border: {
            color: "#d3d3d3",
            dashStyle: "dot",
            top: true,
            right: true,
            bottom: true,
            left: true,
            visible: false,
            width: 2
          }
        },
        series: {
          argumentField: this.get('argumentField'),
          label: {
            visible: true,
            customizeText: function customizeText(point) {
              return point.argumentText + ": " + point.valueText;
            }
          },
          valueField: this.get('valueField')
        }
      };
    }),
    getChartType: function getChartType() {
      return this.set('chartFunction', function () {
        switch (this.get('type')) {
          case 'doughnut':
            return 'dxPieChart';

          case 'pie':
            return 'dxPieChart';

          default:
            return 'dxChart';
        }
      }.call(this));
    },
    buildComponent: function buildComponent() {
      return $(this.element)[this.getChartType()](this.get('options'));
    }
  });

  _exports.default = _default;
});