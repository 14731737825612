define("api-dashboard/routes/rotas-abstratas/rota-generica", ["exports", "api-dashboard/mixins/utils-route", "api-dashboard/mixins/utils"], function (_exports, _utilsRoute, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_utilsRoute.default, _utils.default, {
    routeName: null,
    beforeModel: function beforeModel(transition) {
      return this.set("routeName", transition.targetName);
    },
    activate: function activate() {
      return this.moverScrollComAnimacao();
    }
  });

  _exports.default = _default;
});