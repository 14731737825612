define("api-dashboard/initializers/overrides", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;
  var OverridesInitializer, initialize;
  _exports.initialize = initialize;

  _exports.initialize = initialize = function initialize() {};

  OverridesInitializer = {
    name: 'overrides',
    initialize: function initialize() {
      Ember.Component.reopen({
        didRender: function didRender() {
          this._super();

          return this.set("rendered", true);
        }
      });

      _emberData.default.EmbeddedRecordsMixin.reopen({
        serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
          var config;
          config = this.get("attrs");

          if (config && Object.keys(config).indexOf(relationship.key) > -1) {
            config = config[relationship.key];

            if (config["embedded"] === "always" || config["serialize"] === "records") {
              snapshot.record.setHasManyToClear(relationship.key);
            }
          }

          if (snapshot.record.get(relationship.key + ".length") > 0) {
            return this._super(snapshot, json, relationship);
          }
        }
      });

      return _emberData.default.Model.reopen({
        hasManyToClear: [],
        setHasManyToClear: function setHasManyToClear(hasManyRelationshipName) {
          var array;
          array = this.get("hasManyToClear");

          if (array.indexOf(hasManyRelationshipName) < 0) {
            return array.pushObject(hasManyRelationshipName);
          }
        },
        clearHasManyToClear: function clearHasManyToClear() {
          return this.set("hasManyToClear", []);
        },
        clearDirtyRelationships: function clearDirtyRelationships(onCleared) {
          var h, recordsToUnload, self, store;

          if (onCleared == null) {
            onCleared = function onCleared() {};
          }

          self = this;
          store = this.get("store");
          h = this.get("hasManyToClear");
          recordsToUnload = [];
          this.get("hasManyToClear").forEach(function (relationshipName) {
            if (self.get(relationshipName)) {
              return self.get(relationshipName).forEach(function (record) {
                if (record && record.get("isNew")) {
                  return recordsToUnload.pushObject(record);
                }
              });
            }
          });
          recordsToUnload.forEach(function (r) {
            return store.unloadRecord(r);
          });
          this.clearHasManyToClear();
          return onCleared();
        },
        save: function save(options) {
          var self;
          self = this;
          return new Ember.RSVP.Promise(function (resolve, reject) {
            return self._super(options).then(function (data) {
              return self.clearDirtyRelationships(function () {
                return resolve(data);
              });
            }, function (errors) {
              return reject(errors);
            });
          });
        }
      });
    }
  };
  var _default = OverridesInitializer;
  _exports.default = _default;
});