define("api-dashboard/translations/pt-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "constants": {
      "nfseNaturezaOperacaoValues": {
        "abrasf": {
          "1": "Tributação no Município",
          "2": "Tributação Fora do Município",
          "3": "Isenção",
          "4": "Imune",
          "5": "Exigibilidade Suspensa por Decisão Judicial",
          "6": "Exigibilidade Suspensa por Procedimento Administrativo"
        },
        "iss_sp": {
          "f": "Operação isenta ou não tributável pelo Município de São Paulo, executada em outro Município",
          "i": "Operação isenta ou não tributável, executadas no Município de São Paulo",
          "j": "ISS Suspenso por Decisão Judicial",
          "t": "Operação normal (tributação conforme documento emitido)"
        }
      }
    },
    "globals": {
      "empty": "vazio",
      "labels": {
        "actions": "Ações",
        "back": "Voltar",
        "cancel": "Cancelar",
        "cancel_nfse": "Cancelar NFSe",
        "cancel_reason": "Motivo do Cancelamento",
        "close": "Fechar",
        "cnae_code": "Código CNAE",
        "cofins_value": "Valor COFINS",
        "creation_date": "Data de Criação",
        "csll_value": "Valor CSLL",
        "customer": "Cliente",
        "deduction_value": "Valor das Deduções",
        "delete": "Excluir",
        "description": "Discriminação",
        "edit": "Editar",
        "email": "Email",
        "email_nfse": "Enviar Nota Fiscal de Serviço por E-mail",
        "enter_search_here": "Digite aqui a sua busca",
        "fiscal_nature": "Natureza da Operação",
        "inss_value": "Valor INSS",
        "ir_value": "Valor IR",
        "issue": "Emitir",
        "issue_date": "Data de Emissão",
        "issuer": "Prestador",
        "nfse": "NFSE",
        "number": "Número",
        "operation_cannot_be_undone": "Essa operação não pode ser desfeita!",
        "pis_value": "Valor PIS",
        "please_wait": "Aguarde",
        "recipient": "Destinatário",
        "recipients": "Destinatários",
        "save": "Salvar",
        "search": "Consultar",
        "send": "Enviar",
        "service_code": "Código do Serviço",
        "service_value": "Valor Serviços",
        "show": "Mostrar",
        "start_typing_search": "Comece a digitar para buscar",
        "status": "Status",
        "tax_rate": "Alíquota",
        "type_more_letters": "Digite mais {minletters, plural, =1 {# letra} other {# letras}} para iniciar a busca",
        "verification_code": "Código de Verificação",
        "witheld_iss_tax": "ISS Retido"
      },
      "messages": {
        "alert_error_cancel_service_invoice": "Erro ao Cancelar a Nota Fiscal de Serviço: {error}",
        "alert_error_email_service_invoice": "Erro ao enviar os e-mails. Por favor contate suporte@acras.com.br!",
        "alert_error_send_service_invoice": "Erro ao Emitir a Nota Fiscal de Serviço: {error}",
        "alert_success_cancel_service_invoice": "Nota Fiscal de Serviço cancelada com sucesso!",
        "alert_success_email_service_invoice": "Emails enviados com sucesso!",
        "alert_success_send_service_invoice": "Nota Fiscal de Serviço enviada com sucesso!",
        "cancel_reason_mandatory": "É obrigatório informar um motivo para o cancelamento!",
        "confirm_cancel_service_invoice": "Tem certeza que deseja CANCELAR a Nota {invoice_number}?",
        "confirm_send_service_invoice": "Tem certeza que deseja ENVIAR essa Nota Fiscal de Serviço?",
        "service_invoice_null_number": "A ser gerado no momento da autorização",
        "service_invoice_null_verification_code": "A ser gerado no momento da autorização"
      },
      "models": {
        "NotaFiscal": "Nota Fiscal",
        "NotaFiscalServico": "Nota Fiscal de Serviço"
      },
      "status": {
        "agendada": "Agendada",
        "autorizada": "Autorizada",
        "cancelada": "Cancelada",
        "denegada": "Denegada",
        "em_processamento": "Em Processamento",
        "enviando": "Enviando",
        "erro": "Erro",
        "erro_autorizacao": "Erro de Autorização",
        "erro_de_autorizacao": "Erro de Autorização",
        "pendente": "Pendente"
      }
    },
    "navbar-topo": {
      "change_domain": "Alterar",
      "logout": "Sair",
      "main_menu": {
        "customers": "Clientes",
        "invoices": "Notas de Serviço (NFSe)"
      },
      "receipts": "Faturas"
    },
    "notas-fiscais-servico": {
      "columns": {
        "customer": "Tomador",
        "number": "Número"
      },
      "errors": {
        "nfe_cancelada": "Essa Nota Fiscal de Serviço já está cancelada!",
        "town_hall_msg": "A Prefeitura retornou o seguinte erro"
      },
      "messages": {
        "imported_from_text": "NFSe Importada de",
        "remote_id_text": "ID Remoto"
      },
      "new": "Nova",
      "search_fields": {
        "issue_date_from": "Emissão De",
        "issue_date_to": "Emissão Até"
      },
      "title": "Nota Fiscal de Serviço",
      "title_plural": "Notas Fiscais de Serviço"
    },
    "notifications": {
      "show": "Mostrar",
      "to_customer": "para tomador"
    },
    "waybill-item-quantity": {
      "explained": "{items, plural, =1 {# item} other {# itens}} - {dimensionDescription} - na loja {storeName}"
    }
  };
  _exports.default = _default;
});