define("api-dashboard/transforms/big", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      var e, precision;

      try {
        precision = options['precision'] || 2;
        return new Big(serialized).toFixed(precision);
      } catch (error) {
        e = error;
        return null;
      }
    },
    serialize: function serialize(deserialized, options) {
      var precision;

      if (deserialized === 0) {
        deserialized = "0";
      }

      if (!deserialized) {
        return null;
      }

      precision = options['precision'] || 2;
      return new Big(deserialized).toFixed(precision);
    }
  });

  _exports.default = _default;
});