define("api-dashboard/templates/components/notas-fiscais/-index-cancel-nfe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CV1vQOQD",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"notas-fiscais/nota-fiscal-cancel\",null,[[\"componentReference\"],[[24,[\"cancelModal\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/-index-cancel-nfe.hbs"
    }
  });

  _exports.default = _default;
});