define("api-dashboard/templates/components/products/none-registered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kotZp8ga",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Nenhum Produto cadastrado\"],[9],[0,\"\\n    O que seria legal mostrar nesta tela?\\n\\n    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"A própria tela de quando existem clientes cadastrados porém borrada, pro cara ver como fica\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"Um vídeo do Youtube mostrando como usar os clientes\"],[9],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"Por que e o que pode ser feito com um bom cadastro de clientes\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/products/none-registered.hbs"
    }
  });

  _exports.default = _default;
});