define("api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/principal/ativar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qbx5tlcR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"actCancelar\"]],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[9],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[0,\"Ativação de Empresa\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n\\n    Deseja ativar a empresa \"],[1,[24,[\"empresa\",\"nome\"]],false],[0,\"?\\n\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"actCancelar\"]],[8],[0,\"Cancelar\"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"actConfirmar\"]],[8],[0,\"Sim\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/principal/ativar-empresa.hbs"
    }
  });

  _exports.default = _default;
});