define("api-dashboard/mixins/dev-express", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
  Este mixin será utilizado para conversões entre ember data e DevExpress
  
  Função: convertEmberData2DevExpress
  Parâmetros:
    @promise: A promise que será resolvida em um conjunto de objetos EmberData
    @fieldNames: Array contendo os nomes dos fields que deverão ser expostos
                 no dataset
  
  Retorno:
    Será retornado um DevExpress DataSource contendo objetos. Cada objeto deste
    dataSource sempre irá conter:
      rec: O objeto EmberData que ele representa
      id:  O id deste registro
    Adicionalmente serão inseridas as chaves recebidas no parâmetro @fieldNames
   */
  var _default = Ember.Mixin.create({
    convertEmberData2DevExpress: function convertEmberData2DevExpress(promise, fieldNames) {
      var _buildDevExpressObject, ds;

      _buildDevExpressObject = function _buildDevExpressObject(rec) {
        var fn, i, j, len, len1, res;

        if (rec.get) {
          res = {
            value: rec,
            id: rec.get('id')
          };

          for (i = 0, len = fieldNames.length; i < len; i++) {
            fn = fieldNames[i];
            res[fn] = rec.get(fn);
          }
        } else {
          res = {
            value: rec,
            id: rec.id
          };

          for (j = 0, len1 = fieldNames.length; j < len1; j++) {
            fn = fieldNames[j];
            res[fn] = rec[fn];
          }
        }

        return res;
      };

      ds = new DevExpress.data.DataSource({
        key: 'rec',
        store: new DevExpress.data.CustomStore({
          byKey: function byKey(k) {
            var r;
            r = new $.Deferred();
            r.resolve(_buildDevExpressObject(k));
            return r;
          },
          load: function load() {
            var r;
            r = $.Deferred();

            if (promise.then) {
              promise.then(function (records) {
                var mappedRecords;
                mappedRecords = records.map(function (record) {
                  return _buildDevExpressObject(record);
                });
                return r.resolve(mappedRecords);
              }, function (err) {
                return r.reject(err);
              });
            } else {
              r = promise.map(function (record) {
                return _buildDevExpressObject(record);
              });
            }

            return r;
          }
        })
      });
      return ds;
    }
  });

  _exports.default = _default;
});