define("api-dashboard/models/trade", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    comment: _emberData.default.attr('string'),
    entryDate: _emberData.default.attr('date'),
    kind: _emberData.default.attr('string', {
      serialize: false
    }),
    description: _emberData.default.attr('string', {
      serialize: false
    }),
    originStore: _emberData.default.belongsTo('f-store'),
    destinationStore: _emberData.default.belongsTo('f-store'),
    items: _emberData.default.hasMany('trade-item', {
      inverse: 'trade'
    }),
    validItems: Ember.computed('items.length', 'items.@each._destroy', function () {
      var items, l;
      l = 0;
      items = this.get('items');
      items.forEach(function (item) {
        if (!item.get('_destroy')) {
          return l += 1;
        }
      });
      return l;
    }),
    ready: function ready() {
      if (!this.get('items')) {
        return this.set('items', Ember.ArrayProxy.create({
          content: Ember.A()
        }));
      }
    },
    decreaseInStock: function decreaseInStock(store) {
      return this.get('kind') === '2' || this.get('kind') === '3' && this.get('originStore') === store;
    },
    increaseInStock: function increaseInStock(store) {
      return this.get('kind') === '1' || this.get('kind') === '3' && this.get('destinationStore') === store;
    }
  });

  _exports.default = _default;
});