define("api-dashboard/components/inputs/input-ie", ["exports", "api-dashboard/components/inputs/base-masked-field"], function (_exports, _baseMaskedField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend({
    label: 'IE',
    maxlength: 14,
    pattern: '99999999999999',
    placeholder: 'Inscrição Estadual'
  });

  _exports.default = _default;
});