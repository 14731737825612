define("api-dashboard/models/ratio-criterion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    proporcao: _emberData.default.attr('number'),
    domainId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    centroCustoOrigemId: _emberData.default.attr('number'),
    centroCustoDestinoId: _emberData.default.attr('number'),
    sourceCostCenter: _emberData.default.belongsTo('cost-center', {
      inverse: 'destinationRatioCriterions'
    }),
    destinationCostCenter: _emberData.default.belongsTo('cost-center', {
      inverse: 'sourceRatioCriterions'
    }),
    sourceCostCenterObserver: Ember.observer('sourceCostCenter', function () {
      return this.set('centroCustoOrigemId', this.get('sourceCostCenter.id'));
    }),
    destinationCostCenterObserver: Ember.observer('destinationCostCenter', function () {
      return this.set('centroCustoDestinoId', this.get('destinationCostCenter.id'));
    })
  });

  _exports.default = _default;
});