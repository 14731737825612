define("api-dashboard/components/base/base-form", ["exports", "api-dashboard/components/base/abstract-form"], function (_exports, _abstractForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  Componente herdado do BaseAbstractForm
  
  Callbacks:
    @onCancel: Action a ser chamada quando a inserção ou edição é cancelada
  
  Objetivo: Criar um form de cadastro contendo as operações mais básicas e permitindo a renderização
    apenas dos campos.
    Adiciona as mensagens de erro e botões de salvar e cancelar, quando há rota de cancelamento
    definida.
   */
  var _default = _abstractForm.default.extend({
    store: Ember.inject.service(),
    partialActions: null,
    actions: {
      cancel: function cancel() {
        if (this.get('model.isNew')) {
          this.get('model').send('becameInvalid');
          this.get('store').unloadRecord(this.model);
        } else {
          this.get('model').rollbackAttributes();
        }

        return this.sendAction("onCancel");
      }
    }
  });

  _exports.default = _default;
});