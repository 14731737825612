define("api-dashboard/templates/components/base/btn-form-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPrpCNp1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[[24,[\"nomeComponenteInput\"]]],[[\"val\",\"actionOnFimValidacao\",\"actionOnIniciarValidacaoAssincrona\",\"actionOnFinalizarValidacaoAssincrona\",\"actionOnRealizarValidacaoAssincrona\"],[[24,[\"valueInput\"]],\"actFimValidacao\",\"actInicioValidacaoAssincrona\",\"actFimValidacaoAssincrona\",\"actRealizarValidacaoAssincrona\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group-btn\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn--default btn--secondary\"],[10,\"type\",\"button\"],[8],[7,\"i\",true],[10,\"class\",\"far fa-copy\"],[8],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/btn-form-copy.hbs"
    }
  });

  _exports.default = _default;
});