define("api-dashboard/transforms/date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.DateTransform.extend({
    serialize: function serialize(value) {
      var date;
      date = new Date((0, _moment.default)(value));

      if (date instanceof Date && !isNaN(date)) {
        return (0, _moment.default)(date).format();
      }

      return null;
    }
  });

  _exports.default = _default;
});