define("api-dashboard/components/formularios/formulario-cadastro-edicao-generico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FormulariosFormularioCadastroEdicaoGenericoComponent;
  FormulariosFormularioCadastroEdicaoGenericoComponent = Ember.Component.extend();
  var _default = FormulariosFormularioCadastroEdicaoGenericoComponent;
  _exports.default = _default;
});