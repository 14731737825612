define("api-dashboard/serializers/address", ["exports", "ember-data", "api-dashboard/serializers/application", "api-dashboard/config/environment"], function (_exports, _emberData, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      customer: {
        serialize: 'false'
      }
    }
  });

  _exports.default = _default;
});