define("api-dashboard/templates/upload-certificado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KqPDFTb8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"principal-tit text-center\"],[8],[0,\"\\n\\t\"],[7,\"h1\",true],[8],[0,\"Enviar Certificado\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\\n\"],[1,[28,\"formularios/upload-certificado\",null,[[\"empresa\",\"class\"],[[24,[\"model\"]],\"container box-upload-certificado animated fadeInUp\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/upload-certificado.hbs"
    }
  });

  _exports.default = _default;
});