define("api-dashboard/routes/selecao-domain", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    application: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.get("application.currentDomain")) {
        return this.transitionTo("index");
      }
    },
    model: function model() {
      return this.get("application.domains");
    },
    actions: {
      willTransition: function willTransition(transition) {
        transition.abort();
        return window.location.href = this.get('router').urlFor(transition.targetName);
      }
    }
  });

  _exports.default = _default;
});