define("api-dashboard/templates/components/inputs/input-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MBYHOHWI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"inputs/input-big\",null,[[\"prefix\",\"val\",\"label\",\"scale\",\"allowEmpty\",\"cols\",\"class\"],[[24,[\"prefix\"]],[24,[\"val\"]],[24,[\"label\"]],[24,[\"scale\"]],[24,[\"allowEmpty\"]],[24,[\"cols\"]],\"w--100 p--0\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-percentage.hbs"
    }
  });

  _exports.default = _default;
});