define("api-dashboard/routes/cost-centers/index", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    isUserAuthorized: function isUserAuthorized(user) {
      return this.application.get('currentUser.permBasics');
    }
  });

  _exports.default = _default;
});