define("api-dashboard/models/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _templateObject() {
    var data = _taggedTemplateLiteral(["big"]);

    _templateObject = function _templateObject() {
      return data;
    };

    return data;
  }

  function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    showDocsImportacao: 'gone',
    cofinsAliquotaPorcentual: _emberData.default.attr('big'),
    cofinsAliquotaPorcentualSt: _emberData.default.attr('big'),
    cofinsAliquotaValor: _emberData.default.attr('big', {
      precision: 4
    }),
    cofinsAliquotaValorSt: _emberData.default.attr('big'),
    cofinsBaseCalculo: _emberData.default.attr('big'),
    cofinsBaseCalculoSt: _emberData.default.attr('big'),
    cofinsQuantidadeVendida: _emberData.default.attr('big', {
      precision: 4
    }),
    cofinsQuantidadeVendidaSt: _emberData.default.attr('big', {
      precision: 4
    }),
    cofinsSituacaoTributaria: _emberData.default.attr('string', {
      defaultValue: null
    }),
    cofinsValor: _emberData.default.attr('big'),
    cofinsValorSt: _emberData.default.attr('big'),
    createdAt: _emberData.default.attr('date'),
    detalhe: _emberData.default.attr('string'),
    domainId: _emberData.default.attr('number'),
    icmsAliquota: _emberData.default.attr('big'),
    icmsAliquotaCreditoSimples: _emberData.default.attr('big'),
    icmsAliquotaFinal: _emberData.default.attr(_templateObject()),
    icmsAliquotaInterestadual: _emberData.default.attr('big'),
    icmsAliquotaInternaUfDestino: _emberData.default.attr('big', {
      precision: 4
    }),
    icmsAliquotaSt: _emberData.default.attr('big'),
    icmsBaseCalculo: _emberData.default.attr('big'),
    icmsBaseCalculoDestinoSt: _emberData.default.attr('big'),
    icmsBaseCalculoRetidoSt: _emberData.default.attr('big'),
    icmsBaseCalculoSt: _emberData.default.attr('big'),
    icmsBaseCalculoUfDestino: _emberData.default.attr('big'),
    icmsModalidadeBaseCalculo: _emberData.default.attr('number'),
    icmsModalidadeBaseCalculoSt: _emberData.default.attr('number'),
    icmsMotivoDesoneracao: _emberData.default.attr('number'),
    icmsOrigem: _emberData.default.attr('number', {
      defaultValue: null
    }),
    icmsPartilhaOrigemDestino: _emberData.default.attr('boolean'),
    icmsPercentualDiferimento: _emberData.default.attr('big', {
      precision: 4
    }),
    icmsPercentualFcp: _emberData.default.attr('big', {
      precision: 4
    }),
    icmsPercentualPartilha: _emberData.default.attr('big'),
    icmsPorcentualBaseCalculoOpPropria: _emberData.default.attr('big'),
    icmsPorcentualMargemValorAdicionadoSt: _emberData.default.attr('big'),
    icmsPorcentualReducaoBaseCalculo: _emberData.default.attr('big'),
    icmsPorcentualReducaoBaseCalculoSt: _emberData.default.attr('big'),
    icmsRetencaoOrigemSt: _emberData.default.attr('boolean'),
    icmsSituacaoTributaria: _emberData.default.attr('string', {
      defaultValue: null
    }),
    icmsUfSt: _emberData.default.attr('string'),
    icmsValor: _emberData.default.attr('big'),
    icmsValorCreditoSimples: _emberData.default.attr('big'),
    icmsValorDesonerado: _emberData.default.attr('big'),
    icmsValorDestinoSt: _emberData.default.attr('big'),
    icmsValorDiferido: _emberData.default.attr('big'),
    icmsValorFcp: _emberData.default.attr('big'),
    icmsValorOperacao: _emberData.default.attr('big'),
    icmsValorRetidoSt: _emberData.default.attr('big'),
    icmsValorSt: _emberData.default.attr('big'),
    icmsValorSubstituto: _emberData.default.attr('big'),
    icmsValorUfDestino: _emberData.default.attr('big'),
    icmsValorUfRemetente: _emberData.default.attr('big'),
    iiBaseCalculo: _emberData.default.attr('big'),
    iiValor: _emberData.default.attr('big'),
    iiValorDespesasAduaneiras: _emberData.default.attr('big'),
    iiValorIof: _emberData.default.attr('big'),
    incluiNoTotal: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    ipiAliquota: _emberData.default.attr('big'),
    ipiBaseCalculo: _emberData.default.attr('big'),
    ipiClasseEnquadramento: _emberData.default.attr('string'),
    ipiCnpjProdutor: _emberData.default.attr('string'),
    ipiCodigoEnquadramentoLegal: _emberData.default.attr('string'),
    ipiCodigoSeloControle: _emberData.default.attr('string'),
    ipiQuantidadeSeloControle: _emberData.default.attr('string'),
    ipiQuantidadeTotal: _emberData.default.attr('big', {
      precision: 4
    }),
    ipiSituacaoTributaria: _emberData.default.attr('string'),
    ipiValor: _emberData.default.attr('big'),
    ipiValorPorUnidadeTributavel: _emberData.default.attr('big', {
      precision: 4
    }),
    issqnAliquota: _emberData.default.attr('big'),
    issqnBaseCalculo: _emberData.default.attr('big'),
    issqnCodigoServico: _emberData.default.attr('string'),
    issqnIndicadorExigibilidade: _emberData.default.attr('number'),
    issqnIndicadorIncentivo: _emberData.default.attr('boolean'),
    issqnItemListaServico: _emberData.default.attr('string'),
    issqnMunicipio: _emberData.default.belongsTo('municipio'),
    issqnMunicipioIncidencia: _emberData.default.belongsTo('municipio'),
    issqnNumeroProcesso: _emberData.default.attr('string'),
    issqnPais: _emberData.default.belongsTo('pais'),
    issqnValor: _emberData.default.attr('big'),
    issqnValorDeducao: _emberData.default.attr('big'),
    issqnValorDescontoCondicionado: _emberData.default.attr('big'),
    issqnValorDescontoIncondicionado: _emberData.default.attr('big'),
    issqnValorOutrasRetencoes: _emberData.default.attr('big'),
    issqnValorRetencao: _emberData.default.attr('big'),
    numero: _emberData.default.attr('number'),
    numeroItemPedidoCompra: _emberData.default.attr('string'),
    pedidoCompra: _emberData.default.attr('string'),
    pisAliquotaPorcentual: _emberData.default.attr('big'),
    pisAliquotaPorcentualSt: _emberData.default.attr('big'),
    pisAliquotaValor: _emberData.default.attr('big', {
      precision: 4
    }),
    pisAliquotaValorSt: _emberData.default.attr('big'),
    pisBaseCalculo: _emberData.default.attr('big'),
    pisBaseCalculoSt: _emberData.default.attr('big'),
    pisQuantidadeVendida: _emberData.default.attr('big', {
      precision: 4
    }),
    pisQuantidadeVendidaSt: _emberData.default.attr('big', {
      precision: 4
    }),
    pisSituacaoTributaria: _emberData.default.attr('string', {
      defaultValue: null
    }),
    pisValor: _emberData.default.attr('big'),
    pisValorSt: _emberData.default.attr('big'),
    quantidadeComercial: _emberData.default.attr('big', {
      precision: 4
    }),
    quantidadeTributavel: _emberData.default.attr('big', {
      precision: 4
    }),
    updatedAt: _emberData.default.attr('date'),
    valorBruto: _emberData.default.attr('big', {
      precision: 6
    }),
    valorDesconto: _emberData.default.attr('big'),
    valorEntrada: _emberData.default.attr('big'),
    valorFrete: _emberData.default.attr('big'),
    valorOutrasDespesas: _emberData.default.attr('big'),
    valorSeguro: _emberData.default.attr('big'),
    valorUnitarioComercial: _emberData.default.attr('big', {
      precision: 6
    }),
    valorUnitarioTributavel: _emberData.default.attr('big', {
      precision: 6
    }),
    veiculoAnoFabricacao: _emberData.default.attr('string'),
    veiculoAnoModelo: _emberData.default.attr('string'),
    veiculoChassi: _emberData.default.attr('string'),
    veiculoCm3: _emberData.default.attr('string'),
    veiculoCmt: _emberData.default.attr('string'),
    veiculoCodigoCor: _emberData.default.attr('string'),
    veiculoCodigoCorDenatran: _emberData.default.attr('number'),
    veiculoCodigoMarcaModelo: _emberData.default.attr('string'),
    veiculoCodigoVin: _emberData.default.attr('string'),
    veiculoCondicao: _emberData.default.attr('number'),
    veiculoDescricaoCor: _emberData.default.attr('string'),
    veiculoDistanciaEixos: _emberData.default.attr('string'),
    veiculoEspecie: _emberData.default.attr('number'),
    veiculoLotacao: _emberData.default.attr('string'),
    veiculoNumeroMotor: _emberData.default.attr('string'),
    veiculoPesoBruto: _emberData.default.attr('string'),
    veiculoPesoLiquido: _emberData.default.attr('string'),
    veiculoPotenciaMotor: _emberData.default.attr('string'),
    veiculoRestricao: _emberData.default.attr('number'),
    veiculoSerie: _emberData.default.attr('string'),
    veiculoTipo: _emberData.default.attr('number'),
    veiculoTipoCombustivel: _emberData.default.attr('number'),
    veiculoTipoOperacao: _emberData.default.attr('number'),
    veiculoTipoPintura: _emberData.default.attr('string'),
    valorIpiDevolvido: _emberData.default.attr('big'),
    percentualDevolvido: _emberData.default.attr('big'),
    notaFiscal: _emberData.default.belongsTo('nota_fiscal', {
      inverse: 'items'
    }),
    product: _emberData.default.belongsTo('product', {
      async: false
    }),
    cfop: _emberData.default.belongsTo('cfop', {
      async: false
    }),
    documentosImportacao: _emberData.default.hasMany('documento-importacao', {
      async: false
    }),
    showDetails: false,
    itemEntrada: function itemEntrada(product) {
      var item, notaFiscal;
      item = null;
      notaFiscal = this.get('notaFiscal');

      if (notaFiscal != null && product != null) {
        if (notaFiscal.get('referenciadas.length') > 0) {
          notaFiscal.get('referenciadas').forEach(function (ref) {
            if (item == null) {
              return ref.get('referenciada.items').forEach(function (refItem) {
                if (refItem.get('product.id') === product.get('id')) {
                  return item = product;
                }
              });
            }
          });
        }
      }

      return item;
    },
    ValorBrutoObserver: Ember.observer('quantidadeComercial', 'valorUnitarioComercial', function () {
      var quantidade, valorUnitario;
      quantidade = this.get('quantidadeComercial');
      valorUnitario = this.get('valorUnitarioComercial');

      if (!isNaN(quantidade) && !isNaN(valorUnitario) && !this.get('isDeleted')) {
        this.set('valorBruto', new Big(valorUnitario).times(quantidade));
      }

      this.set('quantidadeTributavel', this.get('quantidadeComercial'));
      return this.set('valorUnitarioTributavel', this.get('valorUnitarioComercial'));
    }),
    icmsOrigemLabel: Ember.computed('icmsOrigem', function () {
      var hash, self;
      self = this;
      hash = this.get('constants.icmsOrigemValues').find(function (e) {
        return e.data === self.get('icmsOrigem');
      });

      if (hash != null) {
        return hash.label;
      }

      return '';
    }),
    icmsModalidadeBaseCalculoStLabel: Ember.computed('icmsModalidadeBaseCalculoSt', function () {
      var hash, self;
      self = this;
      hash = this.get('constants.icmsModalidadeBaseCalculoStValues').find(function (e) {
        return e.data === self.get('icmsModalidadeBaseCalculoSt');
      });

      if (hash != null) {
        return hash.label;
      }

      return '';
    })
  });

  _exports.default = _default;
});