define("api-dashboard/models/parametro", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    descricao: _emberData.default.attr('string'),
    nome: _emberData.default.attr('string'),
    tipo: _emberData.default.attr('string'),
    valorDefault: _emberData.default.attr('string'),
    isBoolean: Ember.computed('tipo', function () {
      return this.get('tipo') === 'boolean';
    }),
    isDecimal: Ember.computed('tipo', function () {
      return this.get('tipo') === 'decimal';
    }),
    isIcmsOrigem: Ember.computed('tipo', function () {
      return this.get('tipo') === 'icms_origem';
    }),
    isIcmsSituacaoTributaria: Ember.computed('tipo', function () {
      return this.get('tipo') === 'icms_situacao_tributaria';
    }),
    isString: Ember.computed('tipo', function () {
      return this.get('tipo') === 'string';
    })
  });

  _exports.default = _default;
});