define("api-dashboard/models/waybill", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    timeoutId: null,
    entryDate: _emberData.default.attr('date'),
    comment: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    status: _emberData.default.attr('string'),
    roundOption: _emberData.default.attr('string', {
      defaultValue: 'ROUNDUP'
    }),
    supplierDiscount: _emberData.default.attr('number'),
    markup: _emberData.default.attr('number', {
      defaultValue: 100
    }),
    totalValue: _emberData.default.attr('big'),
    totalCost: _emberData.default.attr('big'),
    itemCount: _emberData.default.attr('number'),
    fStore: _emberData.default.belongsTo('f-store'),
    supplier: _emberData.default.belongsTo('supplier'),
    collection: _emberData.default.belongsTo('collection'),
    domain: _emberData.default.belongsTo('domain'),
    items: _emberData.default.hasMany('waybill-item', {
      async: false
    }),
    distinctItemCount: Ember.computed('items', function () {
      var count;
      count = 0;
      this.get('items').forEach(function (i) {
        if (!i.get('mainWaybillItem.id') && !i.get('isNew')) {
          return count++;
        }
      });
      return count;
    }),
    mainItems: Ember.computed('items', function () {
      return this.get('items').filter(function (i) {
        return !i.get('mainWaybillItem.id') && !i.get('isNew');
      });
    }),
    itemCountCalc: Ember.computed('items.@each.quantity', function () {
      var count;
      count = 0;
      this.get('items').forEach(function (i) {
        if (!i.get('isNew') && !i.get('mainWaybillItem.id')) {
          if (!i.get('isNew')) {
            return count += parseInt(i.get('quantity'));
          }
        }
      });
      return count;
    }),
    totalCostCalc: Ember.computed('items.@each.totalCost', function () {
      var sum;
      sum = new Big(0);
      this.get('items').forEach(function (wi) {
        var totalCost;
        totalCost = isNaN(wi.get('totalCost')) ? 0 : Number(wi.get('totalCost'));
        return sum = sum.plus(totalCost);
      });
      return sum;
    }),
    totalValueCalc: Ember.computed('items.@each.totalPrice', function () {
      var sum;
      sum = new Big(0);
      this.get('items').forEach(function (wi) {
        var totalPrice;
        totalPrice = isNaN(wi.get('totalPrice')) ? 0 : Number(wi.get('totalPrice'));
        return sum = sum.plus(totalPrice);
      });
      return sum;
    }),
    statusDescription: Ember.computed('status', function () {
      var elm, status;
      status = this.get('status');
      elm = this.get('constants.waybillStatusValues').find(function (element) {
        return element.value === status;
      });

      if (elm != null) {
        return elm.description;
      } else {
        return '';
      }
    }),
    isOngoing: Ember.computed('status', function () {
      return this.get('status') === 'ONGOING';
    }),
    isUnfinished: Ember.computed('status', function () {
      return this.get('status') === 'UNFINISHED';
    }),
    canEditItems: Ember.computed('status', function () {
      return !['COMPLETED', 'CANCELED'].includes(this.get('status'));
    }),
    canDelete: Ember.computed('status', function () {
      return ['COMPLETED', 'CANCELED'].includes(this.get('status'));
    }),
    hasItems: Ember.computed('items', 'items.length', function () {
      return this.get('items.length') > 0 && !(this.get('hasMultipleItems') && this.get('items.firstObject.isNew'));
    }),
    hasMultipleItems: Ember.computed('items', 'items.length', function () {
      return this.get('items.length') > 1;
    }),
    save: function save() {
      if (!this.get('isDeleted')) {
        this.updateWithCalculated();
      }

      this.__removeSubItems();

      return this._super();
    },
    updateWithCalculated: function updateWithCalculated() {
      this.set('totalValue', this.get('totalValueCalc'));
      return this.set('totalCost', this.get('totalCostCalc'));
    },
    __removeSubItems: function __removeSubItems() {
      return this.set('items', this.get('items').filter(function (i) {
        return !i.get('mainWaybillItem');
      }));
    }
  });

  _exports.default = _default;
});