define("api-dashboard/components/base/base-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    constants: Ember.inject.service(),
    _searchParams: [],
    searchParams: [],
    didReceiveAttrs: function didReceiveAttrs() {
      var i, is_visible, label, len, p, results, searchParams;
      this.set('_searchParams', []);
      searchParams = this.get('searchParams');
      results = [];

      for (i = 0, len = searchParams.length; i < len; i++) {
        p = searchParams[i];
        label = p.required && p.label ? p.label.concat(' *') : p.label;
        Ember.set(p, 'cols', p.cols || 6);
        is_visible = p.visible === null || p.visible === void 0 ? true : p.visible;
        Ember.set(p, 'visible', is_visible);
        Ember.set(p, 'label', label);
        this.get_search_param_component(p);
        results.push(this.get('_searchParams').push(p));
      }

      return results;
    },
    get_search_param_component: function get_search_param_component(search_param) {
      switch (search_param.component) {
        case this.get('constants.inputComponentTypes.TOGGLE'):
          return Ember.set(search_param, 'isToggle', true);

        case this.get('constants.inputComponentTypes.DATE'):
          return Ember.set(search_param, 'isDate', true);

        case this.get('constants.inputComponentTypes.AUTOCOMPLETE'):
          return Ember.set(search_param, 'isAutocomplete', true);

        case this.get('constants.inputComponentTypes.SELECT'):
          return Ember.set(search_param, 'isSelect', true);

        case this.get('constants.inputComponentTypes.PERIOD'):
          return Ember.set(search_param, 'isPeriod', true);

        case this.get('constants.inputComponentTypes.PRODUCT_LIST'):
          return Ember.set(search_param, 'isProductList', true);
      }
    }
  });

  _exports.default = _default;
});