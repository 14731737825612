define("api-dashboard/components/suppliers/supplier-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'supplier',
    title: 'Fornecedor',
    titlePlural: 'Fornecedores',
    layoutColumns: 6,
    layoutNoRecords: 'suppliers/none-registered',
    newRoute: 'suppliers/new',
    editRoute: 'suppliers/edit',
    partialAfterTitle: null,
    customSearchButton: false,
    refinementsSearch: false,
    allowDelete: false,
    columns: [{
      field: 'name',
      label: 'Nome'
    }, {
      field: 'supplierCategory.name',
      label: 'Categoria'
    }, {
      field: 'department.name',
      label: 'Departamento'
    }, {
      field: 'productType.name',
      label: 'Tipo de Produto'
    }],
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Cadastro De',
        param: 'created_at_from'
      }, {
        component: 'inputs/input-date',
        field: 'createdAt',
        label: 'Cadastro Até',
        param: 'created_at_to'
      }, {
        component: 'inputs/input-toggle',
        field: 'active',
        label: 'Incluir Inativos',
        param: 'incluir_inativos',
        value: false
      }];
    }),
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});