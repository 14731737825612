define("api-dashboard/models/installment-credit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    customer: _emberData.default.belongsTo('customer'),
    placeOfBirth: _emberData.default.attr('string'),
    stateOfBirth: _emberData.default.attr('string'),
    maritalStatus: _emberData.default.attr('string'),
    fatherName: _emberData.default.attr('string'),
    motherName: _emberData.default.attr('string'),
    residenceType: _emberData.default.attr('string'),
    residenceTime: _emberData.default.attr('string'),
    rentValue: _emberData.default.attr('number'),
    lastResidence1: _emberData.default.attr('string'),
    lastResidence2: _emberData.default.attr('string'),
    lastResidence3: _emberData.default.attr('string'),
    workplaceAddress: _emberData.default.attr('string'),
    workplaceAddressNumber: _emberData.default.attr('string'),
    workplaceAddressCompl: _emberData.default.attr('string'),
    workplaceNeighborhood: _emberData.default.attr('string'),
    workplaceCity: _emberData.default.attr('string'),
    workplaceState: _emberData.default.attr('string'),
    workplaceCep: _emberData.default.attr('string'),
    workplacePhone: _emberData.default.attr('string'),
    workRecordNum: _emberData.default.attr('string'),
    workTime: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    workSalary: _emberData.default.attr('number'),
    otherIncomes: _emberData.default.attr('number'),
    spouseName: _emberData.default.attr('string'),
    spouseRg: _emberData.default.attr('string'),
    spouseCpf: _emberData.default.attr('string'),
    spouseBirthdate: _emberData.default.attr('date'),
    spouseWorkplace: _emberData.default.attr('string'),
    spouseWorkplaceAddress: _emberData.default.attr('string'),
    spouseWorkplaceAddressNum: _emberData.default.attr('string'),
    spouseWorkplaceAddressCompl: _emberData.default.attr('string'),
    spouseWorkplaceNeighborhood: _emberData.default.attr('string'),
    spouseWorkplaceCity: _emberData.default.attr('string'),
    spouseWorkplaceState: _emberData.default.attr('string'),
    spouseWorkplaceCep: _emberData.default.attr('string'),
    spouseWorkplacePhone: _emberData.default.attr('string'),
    spouseWorkRecordNum: _emberData.default.attr('string'),
    spouseWorkTime: _emberData.default.attr('string'),
    spouseJobTitle: _emberData.default.attr('string'),
    spouseWorkSalary: _emberData.default.attr('number'),
    comercialInfo: _emberData.default.attr('string'),
    personalInfo: _emberData.default.attr('string'),
    legalPhone: _emberData.default.attr('string'),
    workplace: _emberData.default.attr('string')
  });

  _exports.default = _default;
});