define("api-dashboard/components/users/user-form", ["exports", "api-dashboard/mixins/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, {
    constants: Ember.inject.service(),
    userSession: Ember.inject.service(),
    store: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    application: Ember.inject.service(),
    multipleStores: Ember.computed('globalLookups.stores', function () {
      return this.get('globalLookups.stores.length') > 0;
    }),
    init: function init() {
      this.set('searchingToggles', true);
      this.set('searchingStores', true);
      this.getStores();
      return this._super.apply(this, arguments);
    },
    getStores: function getStores() {
      var self;
      self = this;
      return this.get('globalLookups.stores').then(function (objs) {
        var stores, userStores;
        userStores = self.get('model.userStores');
        stores = [];
        objs.forEach(function (o) {
          var store_opt, userStore;
          userStore = userStores.find(function (store) {
            return parseInt(store.get('fStore.id')) === parseInt(o.get('id'));
          });

          if (!userStore) {
            userStore = self.get('store').createRecord('user-store', {
              user: self.get('model'),
              fStore: o
            });
          }

          store_opt = {};
          Ember.set(store_opt, 'id', o.get('id'));
          Ember.set(store_opt, 'name', o.get('name'));
          Ember.set(store_opt, 'value', !!userStore && !userStore.get('isNew'));
          Ember.set(store_opt, 'store', o);
          Ember.set(store_opt, 'userStore', userStore);
          return stores.push(store_opt);
        });
        self.set('stores', stores);
        return self.set('searchingStores', false);
      });
    },
    didRender: function didRender() {
      this.$("button[data-toggle='popover']").popover({
        animated: 'fade',
        placement: 'bottom',
        trigger: 'hover'
      });
      return this.set('searchingToggles', !this.get('application.currentDomain'));
    },
    actions: {
      onSubmit: function onSubmit(record) {
        return this.sendAction('afterSave', record);
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      beforeValidate: function beforeValidate() {
        var i, j, new_stores, opt, ref, selected_stores, user_store;
        new_stores = [];
        selected_stores = this.get('stores');

        for (i = j = 0, ref = selected_stores.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
          opt = selected_stores.objectAt(i);

          if (opt.store) {
            user_store = opt.userStore;
            Ember.set(user_store, '_destroy', !opt.value);
          } else {
            user_store = this.get('store').createRecord('user-store');
            Ember.set(user_store, 'user', this.get('model'));
            Ember.set(user_store, 'fStore', opt.store);
          }

          if (!!user_store) {
            new_stores.push(user_store);
          }
        }

        return this.set('model.userStores', new_stores);
      }
    }
  });

  _exports.default = _default;
});