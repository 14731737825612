define("api-dashboard/components/inscricao-estadual/inscricao-estadual-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'inscricao-estadual',
    title: 'Inscrição Estadual',
    titlePlural: 'Inscrições Estaduais',
    layoutNoRecords: null,
    newRoute: null,
    editRoute: 'inscricao-estadual/edit',
    partialStats: null,
    customSearchButton: false,
    columns: [{
      field: 'emitente.nome',
      label: 'Emitente'
    }, {
      field: 'inscricaoEstadual',
      label: 'Inscrição Estadual'
    }, {
      field: 'uf',
      label: 'Estado'
    }],
    searchParams: [],
    showRefinedSearch: false,
    init: function init(args) {
      return this._super(args);
    },
    showNoRecordsLayout: false
  });

  _exports.default = _default;
});