define("api-dashboard/components/spinners/circle-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SpinnersCircleLoaderComponent;
  SpinnersCircleLoaderComponent = Ember.Component.extend();
  var _default = SpinnersCircleLoaderComponent;
  _exports.default = _default;
});