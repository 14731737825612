define("api-dashboard/models/customer", ["exports", "ember-data", "api-dashboard/models/person"], function (_exports, _emberData, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _person.default.extend({
    application: Ember.inject.service(),
    enableInstallmentCredit: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    contracts: _emberData.default.hasMany('contract', {
      inverse: 'customer'
    }),
    receivables: _emberData.default.hasMany('receivable', {
      inverse: 'customer'
    }),
    installmentCredit: _emberData.default.belongsTo('installment-credit', {
      inverse: 'customer'
    }),
    saasPrice: _emberData.default.belongsTo('saas-price', {
      inverse: 'customers'
    }),
    addresses: _emberData.default.hasMany('address', {
      inverse: 'customer',
      async: false
    }),
    loadingIndexAction: false,
    showConfig: Ember.computed('application.currentDomain', function () {
      return this.get('application.currentDomain.isErp') || this.get('application.currentDomain.isAcras') || this.get('application.currentDomain.isLojas');
    }),
    hasBillingAddress: Ember.computed('addresses', function () {
      var hasAddress;
      hasAddress = false;
      this.get('addresses').forEach(function (a) {
        if (a.get('addressType') === 'billing') {
          return hasAddress = true;
        }
      });
      return hasAddress;
    }),
    hasShippingAddress: Ember.computed('addresses', function () {
      var hasAddress;
      hasAddress = false;
      this.get('addresses').forEach(function (a) {
        if (a.get('addressType') === 'shipping') {
          return hasAddress = true;
        }
      });
      return hasAddress;
    }),
    addNewRelatedPerson: function addNewRelatedPerson() {
      return this.get('relatedPeople').addObject(this.store.createRecord('relatedPerson'));
    },
    createInstallmentCredit: function createInstallmentCredit() {
      if (!this.belongsTo('installmentCredit').id()) {
        return this.set('installmentCredit', this.store.createRecord('installmentCredit'));
      }
    }
  });

  _exports.default = _default;
});