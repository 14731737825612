define("api-dashboard/components/contador-dinamico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    didReceiveAttrs: function didReceiveAttrs() {
      var numeroInicial;

      if (this.$() && this.$().length > 0) {
        numeroInicial = 0;

        if (args["oldAttrs"] && args["oldAttrs"]["numeroFinal"]) {
          numeroInicial = parseInt(args["oldAttrs"]["numeroFinal"]["value"]);
        }

        this.atualizarContador(numeroInicial);
      }

      return this._super();
    },
    didInsertElement: function didInsertElement() {
      return this.atualizarContador();
    },
    atualizarContador: function atualizarContador(numeroInicial) {
      var numeroFinal;

      if (numeroInicial == null) {
        numeroInicial = 0;
      }

      numeroFinal = this.get("numeroFinal");

      if (!numeroFinal) {
        numeroFinal = 0;
      }

      return this.$()[0].innerHTML = numeroFinal;
    }
  });

  _exports.default = _default;
});