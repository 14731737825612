define("api-dashboard/routes/rotas-abstratas/rota-publica", ["exports", "api-dashboard/routes/rotas-abstratas/rota-generica"], function (_exports, _rotaGenerica) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaGenerica.default.extend();

  _exports.default = _default;
});