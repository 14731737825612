define("api-dashboard/routes/nova-empresa", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NovaEmpresaRoute;
  NovaEmpresaRoute = _rotaAutenticavel.default.extend({
    model: function model() {
      return this.get("store").createRecord("empresa");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return controller.set("modoEdicao", false);
    },
    deactivate: function deactivate() {
      return this.unloadModelSeNaoSalvo(this, {
        controllerName: "nova-empresa"
      });
    },
    actions: {
      actRedirecionarAposNovaEmpresa: function actRedirecionarAposNovaEmpresa() {
        return this.transitionTo("cadastro-empresa");
      }
    }
  });
  var _default = NovaEmpresaRoute;
  _exports.default = _default;
});