define("api-dashboard/templates/partials/components/grid-empresas/mensagens-erro/erro-nao-esperado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VngEjE8v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[8],[0,\"\\n  \"],[7,\"strong\",true],[8],[0,\"Ocorreu um erro não esperado!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/grid-empresas/mensagens-erro/erro-nao-esperado.hbs"
    }
  });

  _exports.default = _default;
});