define("api-dashboard/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberController$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend((_EmberController$ext = {
    queryParams: ['embedded'],
    embedded: false,
    application: Ember.inject.service(),
    bayeux: Ember.inject.service(),
    store: Ember.inject.service(),
    conectividade: Ember.inject.service()
  }, _defineProperty(_EmberController$ext, "application", Ember.inject.service()), _defineProperty(_EmberController$ext, "router", Ember.inject.service()), _defineProperty(_EmberController$ext, "financialAlerts", []), _defineProperty(_EmberController$ext, "showBayeuxError", Ember.observer("application.currentDomain.isNfe", function () {
    var self;
    self = this;

    if (!self.get('bayeux.initOk')) {
      return self.get('application.alertMessages').pushObject('Ocorreu um erro ao conectar ao servidor. Você poderá emitir notas fiscais normalmente mas não receberá as notificações de autorização em tempo real. Contate o suporte técnico para solucionar este problema.');
    }
  })), _defineProperty(_EmberController$ext, "showMessageAlert", Ember.observer("application.currentDomain.financialAlerts", function () {
    var self;
    self = this;

    if (self.get('application.currentDomain.financialAlerts')) {
      return self.get('application.currentDomain.financialAlerts').forEach(function (r) {
        if (r.level === 3) {
          return self.get('financialAlerts').pushObject({
            level: 'danger',
            message: r.message,
            id: r.id
          });
        } else if (r.level === 2) {
          return self.get('financialAlerts').pushObject({
            level: 'warning',
            message: r.message,
            id: r.id
          });
        } else {
          return self.get('financialAlerts').pushObject({
            level: 'success',
            message: r.message,
            id: r.id
          });
        }
      });
    }
  })), _defineProperty(_EmberController$ext, "init", function init() {
    if (this.get('application.currentDomain.isNfe')) {
      this.get('bayeux');
    }

    return this._super.apply(this, arguments);
  }), _defineProperty(_EmberController$ext, "currentPath", Ember.computed(function () {
    return this.get('router.currentPath');
  })), _defineProperty(_EmberController$ext, "actions", {
    actChangeDomain: function actChangeDomain() {
      return this.transitionToRoute("selecao-domain");
    }
  }), _EmberController$ext));

  _exports.default = _default;
});