define("api-dashboard/components/planos-conta/planos-conta-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    store: Ember.inject.service(),
    contasContabeis: null,
    editContaContabilModal: null,
    errors: [],
    rawData: null,
    getColumns: function getColumns(parentDataSource) {
      var self;
      self = this;
      return [{
        dataField: 'codigo',
        caption: 'Código'
      }, {
        dataField: 'nome',
        caption: 'Nome'
      }, {
        dataField: 'contaContabilPai',
        caption: 'Pai',
        visible: false,
        lookup: {
          dataSource: {
            store: parentDataSource,
            sort: 'nome'
          },
          valueExpr: 'id',
          displayExpr: 'nome'
        },
        validationRules: [{
          type: 'required'
        }]
      }, {
        type: 'buttons',
        buttons: [{
          hint: 'Editar',
          icon: 'fas fa-edit',
          onClick: function onClick(e) {
            return self.get('__openEditModal').call(self, e);
          }
        }, {
          hint: 'Excluir',
          icon: 'fas fa-trash',
          onClick: function onClick(e) {
            return self.get('__deletePlanoDeConta').call(self, e);
          }
        }, {
          hint: 'Recriar Código das Contas Filhas',
          icon: 'fas fa-stream',
          onClick: function onClick(e) {
            return self.get('__recreateChildrenCodes').call(self, e);
          }
        }]
      }];
    },
    getData: function getData() {
      var domainId, self;
      self = this;
      self.set('loading', true);
      domainId = this.get('application.currentDomain.id');
      return self.get('store').query('conta-contabil', {
        domain_id: domainId
      }).then(function (objs) {
        self.get('__reloadDataSource').call(self);
        return self.set('loading', false);
      });
    },
    __reloadDataSource: function __reloadDataSource() {
      var contasContabeis, dataSource, self;
      self = this;
      contasContabeis = self.get('store').peekAll('conta-contabil').filter(function (cc) {
        return cc.get('domain.id') === self.get('application.currentDomain.id');
      });
      dataSource = self.get('__transformRawDataToJson').call(self, contasContabeis);
      self.set('contasContabeis', dataSource);
      return self.set('columns', self.getColumns(dataSource));
    },
    __transformRawDataToJson: function __transformRawDataToJson(rawData) {
      var transformedData;
      transformedData = [];
      rawData.forEach(function (o) {
        return transformedData.addObject(o.toJSON({
          includeId: true
        }));
      });
      return transformedData;
    },
    __openEditModal: function __openEditModal(e) {
      var obj;
      obj = e.row.data;
      this.set('selectedContaContabil', this.get('store').peekRecord('conta-contabil', obj.id));
      return this.get('editContaContabilModal').show();
    },
    __deletePlanoDeConta: function __deletePlanoDeConta(e) {
      var contaContabil, obj, self;
      self = this;
      obj = e.row.data;
      contaContabil = self.get('store').peekRecord('conta-contabil', obj.id);

      if (confirm("Tem certeza que deseja excluir A Conta Contábil " + contaContabil.get('completeDescription') + "?")) {
        return contaContabil.destroyRecord().then(function (obj) {
          self.get('__reloadDataSource').call(self);
          self.set('selectedContaContabil', null);
          self.get('application').showAlert('Conta Contábil excluída com sucesso!', 'success');
          return self.get('editContaContabilModal').hide();
        }, function (error) {
          return self.get('application').showAlert('Erro ao excluir a Conta Contábil!', 'error');
        });
      }
    },
    __recreateChildrenCodes: function __recreateChildrenCodes(e) {
      var ajaxParams, obj, self;
      self = this;
      obj = e.row.data;
      ajaxParams = {
        type: 'PUT',
        dataType: 'json'
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/contas_contabeis/" + obj.id + "/recriar_codigo.json"
      }, function (sucesso, data, jqXHR) {
        return self.get('getData').call(self);
      });
    },
    init: function init() {
      this.get('getData').call(this);
      return this._super.apply(this, arguments);
    },
    actions: {
      newContaContabil: function newContaContabil() {
        this.set('errors', []);
        this.set('selectedContaContabil', null);
        this.set('selectedContaContabil', this.get('store').createRecord('conta-contabil'));
        return this.get('editContaContabilModal').show();
      },
      closeEditContaContabilModal: function closeEditContaContabilModal(modal) {
        if (!this.get('selectedContaContabil.isNew')) {
          this.get('selectedContaContabil').reload();
        }

        this.set('selectedContaContabil', null);
        return this.get('editContaContabilModal').hide();
      },
      saveContaContabil: function saveContaContabil() {
        var self;
        self = this;
        self.set('errors', []);
        self.set('loadingModal', true);
        return self.get('selectedContaContabil').save().then(function (obj) {
          self.get('__reloadDataSource').call(self);
          self.get('application').showAlert('Conta Contábil salva com sucesso!', 'success');
          self.set('loadingModal', false);
          return self.send('closeEditContaContabilModal');
        }, function (reason) {
          var errors;
          errors = reason.errors;
          errors.forEach(function (e) {
            var field;
            field = e.source.pointer.split('/').pop();
            return self.get('errors').addObject(Ember.String.capitalize(field) + ":  " + e.detail);
          });
          self.get('application').showAlert('Erro ao Salvar a Conta Contábil!', 'error');
          return self.set('loadingModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});