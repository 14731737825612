define("api-dashboard/components/empresas/empresa-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/requests/empresa"], function (_exports, _omnisearchIndex, _empresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_empresa.default, {
    application: Ember.inject.service(),
    model: null,
    searchModelName: 'empresa',
    title: 'Empresa',
    titlePlural: 'Empresas',
    layoutNoRecords: 'empresas/none-registered',
    newRoute: 'empresas/new',
    editRoute: 'empresas/edit',
    partialStats: 'empresas/stats',
    partialActions: 'empresas/actions',
    customSearchButton: true,
    showRefinedSearch: false,
    allowDelete: true,
    showNoRecordsLayout: false,
    columns: [{
      field: 'nome',
      label: 'Nome'
    }, {
      field: 'nomeFantasia',
      label: 'Nome Fantasia'
    }, {
      field: 'documento',
      label: 'CNPJ/CPF',
      width: '165'
    }, {
      field: 'dataUltimaEmissao',
      label: 'Última Emissão',
      format: 'date',
      options: {
        whenNull: 'Nenhuma emissão até o momento'
      }
    }],
    searchParams: [],
    allowedExtensions: ["PFX", "P12"],
    searching: false,
    currentEmpresa: null,
    uploadCertificateModal: null,
    downloadFilesModal: null,
    init: function init(args) {
      this.set('senhaCertificado', '');
      return this._super(args);
    },
    validateUploadCertificateFields: function validateUploadCertificateFields() {
      if (!this.get('arquivoCertificado')) {
        this.get('application').showAlert('O Certificado deve ser enviado', 'error');
        return false;
      }

      if (!this.get('senhaCertificado')) {
        this.get('application').showAlert('A senha deve ser preenchida', 'error');
        return false;
      }

      return true;
    },
    afterDelete: function afterDelete(item) {
      return this.get('model').removeObject(item);
    },
    actions: {
      showUploadCertificateModal: function showUploadCertificateModal(obj) {
        this.set('currentEmpresa', obj);
        this.set('arquivoCertificadoDescription', '');
        this.set('senhaCertificado', '');
        return this.get('uploadCertificateModal').show();
      },
      selectCertificate: function selectCertificate(file) {
        var description, fileName, lastModifiedDate;
        this.set('arquivoCertificado', file);
        fileName = file.name;
        lastModifiedDate = moment(file.lastModifiedDate).format('DD/MM/YYYY');
        description = "Arquivo: <b>" + fileName + "</b><br/>Última Modificação: <b>" + lastModifiedDate + "</b>";
        return this.set('arquivoCertificadoDescription', description);
      },
      "delete": function _delete(item) {
        var self;

        if (confirm("Você tem certeza que deseja excluir esta empresa? Esta operação não é reversível\n" + item.nome + " (" + item.documento + ")")) {
          item.deleteRecord();
          self = this;
          return item.save().then(function () {
            alert("Empresa cancelada com sucesso");
            return self.afterDelete(item);
          }, function (reason) {
            return alert(reason["errors"][0]["detail"]["base"]);
          });
        }
      },
      onSaveUploadCertificate: function onSaveUploadCertificate() {
        var params, self;
        self = this;

        if (this.validateUploadCertificateFields()) {
          this.set('searching', true);
          params = {};
          params['arquivo'] = this.get('arquivoCertificado');
          params['senha'] = this.get('senhaCertificado');
          params['empresa'] = this.get('currentEmpresa');
          return this.fazerUploadCertificado(self, params, function (success, data, httpCode) {
            if (success) {
              self.get('application').showAlert('Certificado enviado com sucesso!', 'success');
            } else {
              self.get('application').showAlert(data['mensagemUsuario'], 'error');
            }

            return self.set('searching', false);
          });
        }
      },
      onCancelUploadCertificate: function onCancelUploadCertificate() {
        this.set('currentEmpresa', null);
        return this.get('uploadCertificateModal').hide();
      },
      showDownloadFilesModal: function showDownloadFilesModal(empresa) {
        var self;
        self = this;
        self.get('downloadFilesModal').show();
        self.set('searching', true);
        return this.obterBackups(self, {
          empresa_id: empresa.get('id')
        }, function (success, data) {
          if (success) {
            self.set('backups', data);
          } else {
            self.get('application').showAlert('Erro ao buscar os arquivos', 'error');
          }

          return self.set('searching', false);
        });
      },
      onCancelDownloadFilesModal: function onCancelDownloadFilesModal(modal) {
        this.set('currentEmpresa', null);
        return modal.hide();
      }
    }
  });

  _exports.default = _default;
});