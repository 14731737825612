define("api-dashboard/templates/notas-fiscais/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fXMAUuHG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"notas-fiscais/nota-fiscal-edit\",null,[[\"model\",\"cancelAction\",\"editItemAction\",\"editDocImportacaoAction\"],[[24,[\"model\"]],\"cancel\",\"editItem\",\"editDocImportacao\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/notas-fiscais/edit.hbs"
    }
  });

  _exports.default = _default;
});