define("api-dashboard/routes/products/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      if (model.get('mainProduct.id')) {
        this.get('router').transitionTo('products/edit', model.get('mainProduct.id'));
      }

      model.fillParametros();
      return this._super(controller, model);
    },
    model: function model(params) {
      return this.get('store').findRecord('product', params['id'], {
        reload: true
      });
    },
    actions: {
      afterSave: function afterSave(product) {
        return this.transitionTo('products/index');
      },
      onCancel: function onCancel(product) {
        return this.transitionTo('products/index');
      }
    }
  });

  _exports.default = _default;
});