define("api-dashboard/components/dashboards/new-customers-last-30-days", ["exports", "api-dashboard/components/dashboards/dashboard-card"], function (_exports, _dashboardCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dashboardCard.default.extend({
    stats: Ember.inject.service(),
    title: 'Novos Clientes nos Últimos 30 Dias',
    argumentField: 'name',
    valueField: 'total',
    init: function init() {
      return this._super(arguments);
    },
    dataSource: Ember.computed('chartComponent', 'stats.stats.new_customers_last_30_days_by_store', function () {
      return this.get('stats.stats.new_customers_last_30_days_by_store');
    }),
    chartComponent: Ember.computed('multiStore', function () {
      return 'charts/bar';
    })
  });

  _exports.default = _default;
});