define("api-dashboard/helpers/datetime/dd-mm-yyyy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.datetimeDdMmYyyy = void 0;
  var DatetimeDdMmYyyyHelper, datetimeDdMmYyyy;
  _exports.datetimeDdMmYyyy = datetimeDdMmYyyy;

  _exports.datetimeDdMmYyyy = datetimeDdMmYyyy = function datetimeDdMmYyyy(params, namedParams) {
    var datetime, day, finalVal, month, year;
    console.warn("Helper datetime/dd-mm-yyyy depreciado em favor de format/date");
    finalVal = "";

    try {
      finalVal = namedParams["datetime"];
      datetime = new Date(namedParams["datetime"]);
      day = datetime.getDate().toString();
      month = (datetime.getMonth() + 1).toString();
      year = datetime.getFullYear().toString();
      month = month.length === 1 ? "0" + month : month;
      day = day.length === 1 ? "0" + day : day;
      finalVal = day + "/" + month + "/" + year;
    } catch (error) {}

    return finalVal;
  };

  DatetimeDdMmYyyyHelper = Ember.Helper.helper(datetimeDdMmYyyy);
  var _default = DatetimeDdMmYyyyHelper;
  _exports.default = _default;
});