define("api-dashboard/routes/products/inventories", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      var ajaxParams;
      ajaxParams = {
        type: "GET"
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/products/" + params['id'] + "/inventories.json"
      }, function (sucesso, textStatus, data) {
        if (sucesso) {
          return [textStatus, params['id']];
        } else {
          return [];
        }
      });
    }
  });

  _exports.default = _default;
});