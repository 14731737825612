define("api-dashboard/components/modelos-lancamentos/modelos-lancamento-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    globalLookups: Ember.inject.service(),
    model: null,
    searchModelName: 'modelo-lancamento',
    title: 'Modelo de Lançamento',
    titlePlural: 'Modelos de Lançamento',
    newRoute: 'modelos-lancamentos/new',
    editRoute: 'modelos-lancamentos/edit',
    partialStats: null,
    columns: [{
      field: 'nome',
      label: 'Nome do Modelo'
    }, {
      field: 'descricaoTipo',
      label: 'Tipo'
    }, {
      field: 'descricao',
      label: 'Descrição'
    }, {
      field: 'contaContabil.nome',
      label: 'Plano de Conta'
    }, {
      field: 'customer.name',
      label: 'Destinatário'
    }, {
      field: 'costCenter.descricao',
      label: 'Centro de Custo'
    }, {
      field: 'dataVencimentoHoje',
      label: 'Considerar data de vencimento como a data de lançamento',
      format: 'simnao'
    }, {
      field: 'dataCompetenciaHoje',
      label: 'Considerar data de competência como a data de vencimento',
      format: 'simnao'
    }],
    searchParams: [],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});