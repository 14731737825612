define("api-dashboard/app", ["exports", "ember-load-initializers", "api-dashboard/resolver", "api-dashboard/config/environment", "ember-inflector"], function (_exports, _emberLoadInitializers, _resolver, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App;
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _emberInflector.default.inflector.irregular("valor_parametro", "valores_parametros");

  _emberInflector.default.inflector.irregular("valorParametro", "valoresParametros");

  _emberInflector.default.inflector.irregular("nota_fiscal", "notas-fiscais");

  _emberInflector.default.inflector.irregular("saas_price", "saas_prices");

  _emberInflector.default.inflector.irregular("carta_correcao", "cartas_correcao");

  _emberInflector.default.inflector.irregular("modelo nota fiscal", "modelos notas fiscais");

  _emberInflector.default.inflector.irregular("retorno boleto", "retornos boletos");

  _emberInflector.default.inflector.irregular("nota-fiscal-servico", "notas-fiscais-servico");

  _emberInflector.default.inflector.irregular("modelo-nota-fiscal", "modelos-notas-fiscais");

  _emberInflector.default.inflector.irregular("retorno-boleto", "retornos-boletos");

  _emberInflector.default.inflector.irregular("parcela lancamento", "parcelas lancamento");

  _emberInflector.default.inflector.irregular("valor parametro", "valores parametros");

  _emberInflector.default.inflector.irregular("nota fiscal", "notas fiscais");

  _emberInflector.default.inflector.irregular("documento importacao", "documentos importacao");

  _emberInflector.default.inflector.irregular("totalizador", "totalizadores");

  _emberInflector.default.inflector.irregular("cnpj", "cnpj");

  _emberInflector.default.inflector.irregular("nfe", "nfe");

  _emberInflector.default.inflector.irregular("configuracao boleto", "configuracoes boletos");
  /*Keep this above 'boletos2"*/


  _emberInflector.default.inflector.irregular("inscricao estadual", "inscricoes estaduais");

  _emberInflector.default.inflector.irregular("conta bancaria", "contas bancarias");

  _emberInflector.default.inflector.irregular("label models", "label models");

  _emberInflector.default.inflector.irregular("cash drawer", "cash drawers");

  _emberInflector.default.inflector.irregular("modelo lancamento", "modelos lancamento");

  _emberInflector.default.inflector.irregular("lancamento", "lancamentos");
  /* Keep this below modelo-lancamento*/


  _emberInflector.default.inflector.irregular("conta contabil", "contas contabeis");

  _emberInflector.default.inflector.irregular("nota fiscal servico", "notas fiscais servico");

  _emberInflector.default.inflector.irregular("item lista servico", "itens lista servico");

  _emberInflector.default.inflector.irregular("saas price", "saas prices");

  _emberInflector.default.inflector.irregular("carta correcao", "cartas correcao");

  _emberInflector.default.inflector.irregular("modelo nota fiscal", "modelos notas fiscais");

  _emberInflector.default.inflector.irregular("rateio lancamento", "rateios-lancamento");

  _emberInflector.default.inflector.irregular("retorno boleto lancamento", "retornos-boletos");

  _emberInflector.default.inflector.irregular("referencia", "referencias");

  _emberInflector.default.inflector.irregular("pais", "paises");

  _emberInflector.default.inflector.irregular("duplicata", "duplicatas");

  _emberInflector.default.inflector.irregular("configuracao", "configuracoes");

  _emberInflector.default.inflector.irregular("adicao", "adicoes");

  _emberInflector.default.inflector.irregular("pos", "pos");

  _emberInflector.default.inflector.irregular("boleto", "boletos");

  _emberInflector.default.inflector.irregular("fiscal", "fiscais");

  _emberInflector.default.inflector.irregular("nota", "nota");

  _emberInflector.default.inflector.irregular("nota", "notas");

  _emberInflector.default.inflector.irregular("prestador", "prestadores");

  _emberInflector.default.inflector.irregular("price", "prices");

  _emberInflector.default.inflector.irregular("carta", "cartas");

  _emberInflector.default.inflector.irregular("aliquota", "aliquotas");

  _emberInflector.default.inflector.irregular("valor", "valores");

  _emberInflector.default.inflector.irregular("transportador", "transportadores");

  _emberInflector.default.inflector.irregular("conta", "contas");

  _emberInflector.default.inflector.irregular("contabil", "contabeis");

  _emberInflector.default.inflector.irregular("rateio", "rateios");

  _emberInflector.default.inflector.irregular("retorno", "retornos");

  _emberInflector.default.inflector.uncountable("saas");

  _emberInflector.default.inflector.uncountable('lista');

  moment.locale('pt_BR', {
    months: ["Janeiro", "fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "outubro", "Novembro", "Dezembro"],
    monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    weekdays: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    weekdaysMin: ["D", "S", "T", "Q", "Q", "S", "S"]
  });
  moment.locale('pt_BR');
  DevExpress.localization.locale('pt-br');
  var _default = App;
  _exports.default = _default;
});