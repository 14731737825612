define("api-dashboard/models/supplier-return", ["exports", "ember-data", "api-dashboard/models/trade"], function (_exports, _emberData, _trade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trade.default.extend({
    entryDate: _emberData.default.attr('date'),
    comment: _emberData.default.attr('string')
  });

  _exports.default = _default;
});