define("api-dashboard/components/base/abstract-input-group-form-container", ["exports", "api-dashboard/components/base/abstract-input-form-container"], function (_exports, _abstractInputFormContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractInputFormContainer.default.extend({
    classNames: ["input-group"],
    icone: null
  });

  _exports.default = _default;
});