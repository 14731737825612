define("api-dashboard/templates/components/base/modals/modal-confirmacao-generica", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9gWOIPa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[10,\"aria-labelledby\",\"myModalLabel\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[10,\"role\",\"document\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content modal-confirmacao-inicial\"],[8],[0,\"\\n      \"],[15,[24,[\"partialMensagemConfirmacaoPrincipal\"]],[]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content modal-confirmacao-cancelada\"],[8],[0,\"\\n      \"],[15,[24,[\"partialMensagemConfirmacaoCancelada\"]],[]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content modal-confirmacao-spinner\"],[8],[0,\"\\n      \"],[15,[24,[\"partialIndicadorLoading\"]],[]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content modal-confirmacao-erro\"],[8],[0,\"\\n      \"],[15,[24,[\"partialMensagemErro\"]],[]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-content modal-confirmacao-sucesso\"],[8],[0,\"\\n      \"],[15,[24,[\"partialMensagemSucessoConfirmacao\"]],[]],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/modals/modal-confirmacao-generica.hbs"
    }
  });

  _exports.default = _default;
});