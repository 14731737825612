define("api-dashboard/components/stores-dashboard/stores-dashboard-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      return this.buildComponent();
    },
    buildComponent: function buildComponent() {
      var self;
      self = this;
      this.loadData.bind(self)();
      return this.set('remainingToNextReload', 0);
    },
    loadData: function loadData() {
      var self;
      self = this;

      if (this.get('remainingToNextReload') === 0) {
        self.sendAction('reloadData');
        self.set('remainingToNextReload', 120);
      } else {
        self.set('remainingToNextReload', this.get('remainingToNextReload') - 1);
      }

      return setTimeout(this.loadData.bind(this), 1000);
    }
  });

  _exports.default = _default;
});