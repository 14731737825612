define("api-dashboard/helpers/raw-to-user/cnpj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.rawToUserCnpj = void 0;
  var RawToUserCnpjHelper, rawToUserCnpj;
  _exports.rawToUserCnpj = rawToUserCnpj;

  _exports.rawToUserCnpj = rawToUserCnpj = function rawToUserCnpj(params) {
    var retorno;
    console.warn("Helper raw-to-user/cnpj depreciado em favor de format/cnpj");
    retorno = params[0];

    try {
      retorno = retorno.substr(0, 2) + "." + retorno.substr(2, 3) + "." + retorno.substr(5, 3) + "/" + retorno.substr(8, 4) + "-" + retorno.substr(12, 2);
    } catch (error) {}

    return retorno;
  };

  RawToUserCnpjHelper = Ember.Helper.helper(rawToUserCnpj);
  var _default = RawToUserCnpjHelper;
  _exports.default = _default;
});