define("api-dashboard/components/saas-prices/saas-price-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'saas-price',
    title: 'Tabela de Preços',
    titlePlural: 'Tabelas de Preços',
    newRoute: 'saas-prices/new',
    editRoute: 'saas-prices/edit',
    partialStats: null,
    columns: [{
      field: 'name',
      label: 'Nome'
    }],
    searchParams: [{
      field: 'name',
      param: 'name',
      label: 'Nome'
    }, {
      component: 'inputs/input-toggle',
      field: 'active',
      label: 'Incluir Inativos',
      param: 'show_inactive',
      value: false
    }],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});