define("api-dashboard/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "constants": {
      "nfseNaturezaOperacaoValues": {
        "abrasf": {
          "1": "Taxation inside municipality",
          "2": "Taxation outside municipality",
          "3": "Free of Tax",
          "4": "Immune",
          "5": "Taxation Suspended by Court Decision",
          "6": "Taxation Sspended by Administrative Procedure"
        },
        "iss_sp": {
          "f": "Operation Free of Tax in São Paulo, service provided outside São Paulo",
          "i": "Operation Free of Tax in São Paulo, service provided in São Paulo",
          "j": "ISS (Tax On Service) Suspended by Court Decision",
          "t": "Normal Operation (taxation as issued document)"
        }
      }
    },
    "globals": {
      "empty": "empty",
      "labels": {
        "actions": "Actions",
        "back": "Back",
        "cancel": "Cancel",
        "cancel_nfse": "Cancel Service Invoice",
        "cancel_reason": "Cancel Reason",
        "close": "Close",
        "cnae_code": "CNAE Code ",
        "cofins_value": "COFINS Value",
        "creation_date": "Creation Date",
        "csll_value": "CSLL Value",
        "customer": "Customer",
        "deduction_value": "Value of All Deductions",
        "delete": "Delete",
        "description": "Description",
        "edit": "Edit",
        "email": "Email",
        "email_nfse": "Email Service Invoice",
        "enter_search_here": "Enter your search here",
        "fiscal_nature": "Fiscal Nature",
        "inss_value": "INSS Value",
        "ir_value": "IR Value",
        "issue": "Issue",
        "issue_date": "Issue Date",
        "issuer": "Issuer",
        "nfse": "Invoice",
        "number": "Number",
        "operation_cannot_be_undone": "This Action Cannot Be Undone!",
        "pis_value": "PIS Value",
        "please_wait": "Please Wait",
        "recipient": "Recipient",
        "recipients": "Recipients",
        "save": "Save",
        "search": "Search",
        "send": "Send",
        "service_code": "Service Code",
        "service_value": "Service Value",
        "show": "Show",
        "start_typing_search": "Start typing to search",
        "status": "Status",
        "tax_rate": "Tax Rate",
        "type_more_letters": "Enter {minletters, plural, =1 {# more letter} other {# more letters}} to start the search",
        "verification_code": "Verification Code",
        "witheld_iss_tax": "Witheld ISS Tax"
      },
      "messages": {
        "alert_error_cancel_service_invoice": "Error cancelling the Service Invoice: {error}",
        "alert_error_email_service_invoice": "Error sending emails. Please contact suporte@acras.com.br!",
        "alert_error_send_service_invoice": "Error when issuing Service Invoice: {error}",
        "alert_success_cancel_service_invoice": "Service Invoice canceled successfully!",
        "alert_success_email_service_invoice": "Emails sent successfully!",
        "alert_success_send_service_invoice": "Service Invoice sent successfully!",
        "cancel_reason_mandatory": "It's mandatory to inform a cancel reason!",
        "confirm_cancel_service_invoice": "Are you sure to CANCEL the Service Invoice {invoice_number}?",
        "confirm_send_service_invoice": "Are you sure to SEND this Service Invoice?",
        "service_invoice_null_number": "Generated when authorized",
        "service_invoice_null_verification_code": "Generated when authorized"
      },
      "models": {
        "NotaFiscal": "Invoice",
        "NotaFiscalServico": "Service Invoice"
      },
      "status": {
        "agendada": "Scheduled",
        "autorizada": "Authorized",
        "cancelada": "Canceled",
        "denegada": "Denied",
        "em_processamento": "Processing",
        "enviando": "Sending",
        "erro": "Error",
        "erro_autorizacao": "Authorization Error",
        "erro_de_autorizacao": "Authorization Error",
        "pendente": "Pending"
      }
    },
    "navbar-topo": {
      "change_domain": "Change",
      "logout": "Logout",
      "main_menu": {
        "customers": "Customers",
        "invoices": "Invoices"
      },
      "receipts": "Receipts"
    },
    "notas-fiscais-servico": {
      "columns": {
        "customer": "Customer",
        "number": "Number"
      },
      "errors": {
        "nfe_cancelada": "This Service Invoice is already canceled!",
        "town_hall_msg": "The town hall returned the error"
      },
      "messages": {
        "imported_from_text": "Service Invoice Imported from",
        "remote_id_text": "Remote ID"
      },
      "new": "New",
      "search_fields": {
        "issue_date_from": "Issue Date From",
        "issue_date_to": "Issue Date To"
      },
      "title": "Service Invoice",
      "title_plural": "Service Invoices"
    },
    "notifications": {
      "show": "Show",
      "to_customer": "for customer"
    },
    "waybill-item-quantity": {
      "explained": "{items, plural, =1 {# item} other {# itens}} - {dimensionDescription} - in {storeName}"
    }
  };
  _exports.default = _default;
});