define("api-dashboard/routes/inscricao-estadual/edit", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').findRecord('inscricao-estadual', params['inscricao-estadual_id'], {
        reload: true
      });
    },
    actions: {
      afterSave: function afterSave(config) {
        return this.transitionTo('inscricao-estadual/index');
      },
      onCancel: function onCancel(config) {
        return this.transitionTo('inscricao-estadual/index');
      }
    }
  });

  _exports.default = _default;
});