define("api-dashboard/routes/download-files/index", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    store: Ember.inject.service(),
    model: function model(params, transition) {
      var ajaxParams;
      ajaxParams = {
        contentType: "application/json; charset=utf-8"
      };
      return this.get("store").externalAjax(ajaxParams, {
        apiURL: "/download_arquivos.json"
      }, function (sucesso, data, callbackParams) {
        data.download_arquivos.forEach(function (item) {
          item.headingId = "heading" + item.cnpj;
          item.collapseId = "collapse" + item.cnpj;
          item.collapseHref = "#collapse" + item.cnpj;

          if (item.backups) {
            return item.backups.forEach(function (backup) {
              var objDate;
              objDate = new Date(backup.mes.substr(0, 4) + "-" + backup.mes.substr(4, 2) + "-02");
              backup.monthDescription = objDate.toLocaleString('pt-BR', {
                month: "long"
              });
              return backup.monthDescription += " de " + objDate.getFullYear();
            });
          }
        });
        return data.download_arquivos;
      });
    }
  });

  _exports.default = _default;
});