define("api-dashboard/templates/components/snippets/last-nfe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+tD50tL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-lg-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-heading panel-heading-fundo-notas\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"titulo-branco\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-barcode fa-fw\"],[8],[0,\" \"],[9],[0,\" Últimas Notas Autorizadas\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-body\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n        \"],[7,\"table\",true],[10,\"class\",\"table table-bordered table-hover table-striped\"],[8],[0,\"\\n          \"],[7,\"thead\",true],[8],[0,\"\\n          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Número\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Destinatário\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Data\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Valor\"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"tbody\",true],[10,\"id\",\"ultimas_notas\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/snippets/last-nfe.hbs"
    }
  });

  _exports.default = _default;
});