define("api-dashboard/utils/acesso-pis", ["exports", "api-dashboard/utils/acesso-pis-cofins"], function (_exports, _acessoPisCofins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _acessoPisCofins.default.extend({
    acessoType: 'pis'
  });

  _exports.default = _default;
});