define("api-dashboard/templates/components/base/form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TaAP0dCz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-dismissible\"],[10,\"role\",\"alert\"],[10,\"style\",\"display:none;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"mostrarBotaoFechar\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"type\",\"button\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"form-alert-content\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[0,\"Hello World!\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/form-alert.hbs"
    }
  });

  _exports.default = _default;
});