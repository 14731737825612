define("api-dashboard/routes/labs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      /*
      json =
        {'emitentes': [ {
          'id': 462
          'nome': 'NIVATIZ COMERCIAL LTDA'
          'cnpj': '14450006000175'
          'uf': 'SP'
          'mostrar_tipo_venda': false
          'default_informacoes_adicionais': 'DOCUMENTO EMITIDO POR ME OU EPP OPTANTE PELO SIMPLES NACIONAL; NÃO GERA DIREITO A CRÉDITO FISCAL DE IPI.'
          'obter_dados_entrada': false
          'mostrar_detalhe_tela_principal': true
          'regime_tributario': 1
          'classe_calculo_impostos': 'CalculoImpostosSimples102'
          'empresa_veiculos': false
          'contract_id': 3308
        } ]
        'customer': [ {
          'id': 588096
          'active': true
          'cep': null
          'cnpj': ''
          'cnpj_emissao': ''
          'comment': null
          'contact_name': ''
          'cpf': '18294662836'
          'inscricao_estadual': ''
          'inscricao_estadual_emissao': ''
          'inscricao_municipal': ''
          'inscricao_suframa': ''
          'isento_inscricao_estadual': false
          'isento_inscricao_estadual_emissao': false
          'legal_type': 'NATURAL'
          'name': 'Josefa Mendes da Silva'
          'razao_social': ''
          'regime_simples_nacional': false
          'rg': ''
          'web_address': null
          'f_store_id': null
          'birthday': null
          'special': false
          'operator_message': ''
          'blocked': false
          'reason_blocked': ''
          'gender': ''
          'enable_installment_credit': false
          'nfe_address_uf': 'SP'
          'contact_info_ids': [ 804091 ]
          'address_ids': [ 837622 ]
          'sale_type_id': null
          'store_id': null
          'installment_credit_id': null
          'related_person_ids': []
        } ]
        'contact_infos': [ {
          'id': 804091
          'value': ''
          'comment': null
          'contact_type': 'phone'
        } ]
        'addresses': [ {
          'id': 837622
          'logradouro': 'Rua Cassiano Ricardo 322'
          'numero': '0'
          'complemento': null
          'bairro': 'Maua'
          'cep': '09330680'
          'address_type': 'default'
          'pais_id': 30
          'municipio_id': 3890
        } ]
        'pais': [ {
          'id': 30
          'nome': 'BRASIL'
        } ]
        'municipio': [ {
          'id': 3890
          'nome_uf': 'São Paulo'
          'sigla_uf': 'SP'
          'nome_municipio': 'Mauá'
          'nome_and_uf': 'Mauá - SP'
        } ]
        'installment_credits': []
        'related_people': []
        'duplicatas': []
        'referenciadas': []
        'items': [ {
          'id': 1576295
          'nota_fiscal_id': 635896
          'numero': 1
          'detalhe': null
          'cfop_id': 251
          'quantidade_comercial': '1.0'
          'quantidade_tributavel': '1.0'
          'valor_unitario_comercial': '99.9'
          'valor_unitario_tributavel': '99.9'
          'valor_bruto': '99.9'
          'valor_entrada': null
          'valor_frete': '18.67'
          'valor_seguro': null
          'valor_outras_despesas': null
          'valor_desconto': null
          'inclui_no_total': true
          'pedido_compra': null
          'numero_item_pedido_compra': null
          'icms_origem': 0
          'icms_situacao_tributaria': '102'
          'icms_partilha_origem_destino': false
          'icms_retencao_origem_st': false
          'icms_modalidade_base_calculo': null
          'icms_base_calculo': null
          'icms_porcentual_base_calculo_op_propria': null
          'icms_porcentual_reducao_base_calculo': null
          'icms_aliquota': null
          'icms_valor': null
          'icms_motivo_desoneracao': null
          'icms_aliquota_credito_simples': null
          'icms_valor_credito_simples': null
          'icms_modalidade_base_calculo_st': null
          'icms_base_calculo_st': null
          'icms_porcentual_margem_valor_adicionado_st': null
          'icms_porcentual_reducao_base_calculo_st': null
          'icms_aliquota_st': null
          'icms_valor_st': null
          'icms_uf_st': null
          'icms_base_calculo_retido_st': null
          'icms_valor_retido_st': null
          'icms_base_calculo_destino_st': null
          'icms_valor_destino_st': null
          'ii_base_calculo': null
          'ii_valor_despesas_aduaneiras': null
          'ii_valor': null
          'ii_valor_iof': null
          'pis_situacao_tributaria': '99'
          'pis_base_calculo': '0.0'
          'pis_aliquota_porcentual': '0.0'
          'pis_quantidade_vendida': null
          'pis_aliquota_valor': null
          'pis_valor': '0.0'
          'pis_base_calculo_st': null
          'pis_aliquota_porcentual_st': null
          'pis_quantidade_vendida_st': null
          'pis_aliquota_valor_st': null
          'pis_valor_st': null
          'cofins_situacao_tributaria': '99'
          'cofins_base_calculo': '0.0'
          'cofins_aliquota_porcentual': '0.0'
          'cofins_quantidade_vendida': null
          'cofins_aliquota_valor': null
          'cofins_valor': '0.0'
          'cofins_base_calculo_st': null
          'cofins_aliquota_porcentual_st': null
          'cofins_quantidade_vendida_st': null
          'cofins_aliquota_valor_st': null
          'cofins_valor_st': null
          'ipi_situacao_tributaria': null
          'ipi_base_calculo': null
          'ipi_aliquota': null
          'ipi_quantidade_total': null
          'ipi_valor_por_unidade_tributavel': null
          'ipi_valor': null
          'ipi_classe_enquadramento': null
          'ipi_cnpj_produtor': null
          'ipi_codigo_selo_controle': null
          'ipi_quantidade_selo_controle': null
          'ipi_codigo_enquadramento_legal': null
          'issqn_indicador_exigibilidade': null
          'issqn_base_calculo': null
          'issqn_aliquota': null
          'issqn_valor': null
          'issqn_municipio_id': null
          'issqn_item_lista_servico': null
          'issqn_indicador_incentivo': null
          'issqn_valor_deducao': null
          'issqn_valor_outras_retencoes': null
          'issqn_valor_desconto_incondicionado': null
          'issqn_valor_desconto_condicionado': null
          'issqn_valor_retencao': null
          'issqn_codigo_servico': null
          'issqn_municipio_incidencia_id': null
          'issqn_pais_id': null
          'issqn_numero_processo': null
          'veiculo_tipo_operacao': null
          'veiculo_chassi': null
          'veiculo_codigo_cor': null
          'veiculo_descricao_cor': null
          'veiculo_codigo_cor_denatran': null
          'veiculo_potencia_motor': null
          'veiculo_cm3': null
          'veiculo_peso_liquido': null
          'veiculo_peso_bruto': null
          'veiculo_serie': null
          'veiculo_tipo_combustivel': null
          'veiculo_numero_motor': null
          'veiculo_cmt': null
          'veiculo_distancia_eixos': null
          'veiculo_ano_modelo': null
          'veiculo_ano_fabricacao': null
          'veiculo_tipo_pintura': null
          'veiculo_tipo': null
          'veiculo_especie': null
          'veiculo_codigo_marca_modelo': null
          'veiculo_codigo_vin': null
          'veiculo_condicao': null
          'veiculo_restricao': null
          'veiculo_lotacao': null
          'domain_id': 2495
          'created_at': '2017-04-24T01:24:33-03:00'
          'updated_at': '2017-04-24T01:24:33-03:00'
          'product_id': 1303700
          'icms_valor_desonerado': null
          'icms_valor_diferido': null
          'icms_percentual_diferimento': null
          'icms_valor_operacao': null
          'icms_base_calculo_uf_destino': null
          'icms_percentual_fcp': null
          'icms_aliquota_interna_uf_destino': null
          'icms_aliquota_interestadual': null
          'icms_percentual_partilha': null
          'icms_valor_fcp': null
          'icms_valor_uf_destino': null
          'icms_valor_uf_remetente': null
          'documento_importacao_ids': []
        } ]
        'product': [ {
          'id': 1303700
          'code': 'AFPSCASC/33'
          'barcode': null
          'description': 'Sandália Jane Trancinha Caramelo'
          'average_cost': '0.0'
          'price': '99.9'
          'use_dimensions': false
          'main_product_id': null
          'product_type_id': null
          'codigo_ncm': '64051090'
          'active': true
          'full_description': 'AFPSCASC/33 - Sandália Jane Trancinha Caramelo (R$99,90)'
        } ]
        'cfops': [ {
          'id': 251
          'codigo': '5102'
          'descricao': '5102 - Venda de mercadoria adquirida ou recebida de terceiros'
          'created_at': '2017-01-26T17:13:46-02:00'
          'updated_at': '2017-01-26T17:13:46-02:00'
          'transporte': false
          'devolucao': false
        } ]
        'documento_importacao': []
        'volumes': []
        'transporters': []
        'cfop': []
        'nota_fiscal':
          'id': 635896
          'chave_nfe': 'NFe35170414450006000175550010000120821210429800'
          'numero': '12082'
          'serie': '1'
          'status': 'autorizada'
          'status_sefaz': 100
          'mensagem_sefaz': 'Autorizado o uso da NF-e'
          'justificativa_cancelamento': null
          'emitente_id': 462
          'inscricao_estadual_st_emitente': null
          'tipo_venda': null
          'natureza_operacao': 'Venda'
          'forma_pagamento': 0
          'data_emissao': '2017-04-24T12:00:00-03:00'
          'data_entrada_saida': '2017-04-24T12:00:00-03:00'
          'hora_entrada_saida': null
          'tipo_documento': 1
          'formato_impressao': 1
          'finalidade_emissao': 1
          'cnpj_entrega': null
          'cpf_entrega': null
          'logradouro_entrega': null
          'numero_entrega': null
          'complemento_entrega': null
          'bairro_entrega': null
          'municipio_entrega_id': null
          'cnpj_retirada': null
          'cpf_retirada': null
          'logradouro_retirada': null
          'numero_retirada': null
          'complemento_retirada': null
          'bairro_retirada': null
          'municipio_retirada_id': null
          'icms_base_calculo': '0.0'
          'icms_valor_total': '0.0'
          'icms_base_calculo_st': '0.0'
          'icms_valor_total_st': '0.0'
          'valor_produtos': '99.9'
          'valor_frete': '18.67'
          'valor_seguro': '0.0'
          'valor_desconto': '0.0'
          'valor_total_ii': '0.0'
          'valor_ipi': '0.0'
          'valor_pis': '0.0'
          'valor_cofins': '0.0'
          'valor_outras_despesas': '0.0'
          'valor_total_bruto': null
          'valor_total': '118.57'
          'valor_total_servicos': null
          'issqn_base_calculo': null
          'issqn_valor_total': null
          'valor_pis_servicos': null
          'valor_cofins_servicos': null
          'valor_retido_pis': null
          'valor_retido_cofins': null
          'valor_retido_csll': null
          'base_calculo_irrf': null
          'valor_retido_irrf': null
          'prev_social_base_calculo': null
          'prev_social_valor_retido': null
          'modalidade_frete': 1
          'veiculo_tipo': null
          'veiculo_placa': null
          'veiculo_uf': null
          'veiculo_rntc': null
          'veiculo_identificacao_vagao': null
          'veiculo_identificacao_balsa': null
          'transporte_icms_municipio_id': null
          'transporte_icms_cfop_id': null
          'transporte_icms_servico': null
          'transporte_icms_base_calculo': null
          'transporte_icms_aliquota': null
          'transporte_icms_valor': null
          'numero_fatura': null
          'valor_original_fatura': null
          'valor_desconto_fatura': null
          'valor_liquido_fatura': null
          'informacoes_adicionais_contribuinte': 'Trib. aprox. R$: 13,44 Federal e  17,98 Estadual Fonte: IBPT W7m9E1'
          'revenda': false
          'calculo_em_andamento': false
          'ultima_etapa': 'identificacao'
          'domain_id': 2495
          'created_at': '2017-04-24T01:24:25-03:00'
          'updated_at': '2017-04-24T01:24:35-03:00'
          'capatazia': null
          'taxa_siscomex': null
          'uf_local_embarque': null
          'local_embarque': null
          'nao_calcular_impostos': false
          'modelo_nota_fiscal_id': null
          'transporter_id': null
          'person_id': 588096
          'exec_after_autorizada': true
          'sale_id': null
          'remote_reference': '79353121'
          'exec_after_cancelada': false
          'consumidor_final': true
          'presenca_comprador': 9
          'local_despacho': null
          'icms_valor_total_desonerado': '0.0'
          'icms_valor_total_fcp': null
          'icms_valor_total_uf_destino': null
          'icms_valor_total_uf_remetente': null
          'descricao_completa': '12082/1 - emit. NIVATIZ COMERCIAL LTDA - CNPJ: 14.450.006/0001-75 (SP), dest. Josefa Mendes da Silva'
          'etapa': null
          'testiculo': 'IHAAA'
          'duplicata_ids': []
          'referenciada_ids': []
          'item_ids': [ 1576295 ]
          'volume_ids': []}
      
      @get("store").pushPayload("nota-fiscal", json)
      return @get("store").peekRecord("nota-fiscal", 635896)
       */
    }
  });

  _exports.default = _default;
});