define("api-dashboard/models/dimension-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    value: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    domain_id: _emberData.default.attr('number'),
    _destroy: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    dimension: _emberData.default.belongsTo('dimension', {
      inverse: 'values'
    }),
    valueLabel: Ember.computed('value', function () {
      return this.get('value');
    })
  });

  _exports.default = _default;
});