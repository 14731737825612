define("api-dashboard/models/card-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    acceptsDebit: _emberData.default.attr('boolean'),
    acceptsInstallmentCredit: _emberData.default.attr('boolean'),
    acceptsInstallmentDebit: _emberData.default.attr('boolean'),
    acceptsRevolvingCredit: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean'),
    cardCompanyConfigId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    debitInterest: _emberData.default.attr('number'),
    installmentCreditInterest: _emberData.default.attr('number'),
    installmentCreditUniqueInterest: _emberData.default.attr('boolean'),
    installmentDebitInterest: _emberData.default.attr('number'),
    maxNumInstallments: _emberData.default.attr('number'),
    revolvingCreditInterest: _emberData.default.attr('number'),
    storeId: _emberData.default.attr('number'),
    updatedAt: _emberData.default.attr('date'),
    version: _emberData.default.attr('number'),
    cardBrand: _emberData.default.belongsTo('card-brand'),
    cardCompany: _emberData.default.belongsTo('card-company'),
    domain: _emberData.default.belongsTo('domain'),
    completeDescription: Ember.computed('acceptsDebit', 'acceptsInstallmentCredit', 'acceptsInstallmentDebit', 'acceptsRevolving_credit', function () {
      var description;
      description = this.get('cardBrand.name');

      if (this.get('acceptsDebit') || this.get('acceptsInstallmentDebit')) {
        description += ' | Débito';
      }

      if (this.get('acceptsInstallmentCredit') || this.get('acceptsRevolving_credit')) {
        description += ' | Crédito';
      }

      return description;
    }),
    cardType: Ember.computed('acceptsDebit', 'acceptsInstallmentCredit', 'acceptsInstallmentDebit', 'acceptsRevolving_credit', function () {
      var type;
      type = null;

      if (this.get('acceptsDebit') || this.get('acceptsInstallmentDebit')) {
        type = 'D';
      }

      if (this.get('acceptsInstallmentCredit') || this.get('acceptsRevolving_credit')) {
        type = 'C';
      }

      return type;
    })
  });

  _exports.default = _default;
});