define("api-dashboard/models/referenciada", ["exports", "ember-data", "api-dashboard/mixins/utils"], function (_exports, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_utils.default, {
    tipo: _emberData.default.attr('string'),
    chaveExterna: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    inscricaoEstadual: _emberData.default.attr('string'),
    isentoInscricaoEstadual: _emberData.default.attr('boolean'),
    uf: _emberData.default.attr('string'),
    modelo: _emberData.default.attr('string'),
    numero: _emberData.default.attr('string'),
    serie: _emberData.default.attr('string'),
    ano: _emberData.default.attr('number'),
    mes: _emberData.default.attr('number'),
    numeroEcf: _emberData.default.attr('string'),
    numeroCoo: _emberData.default.attr('string'),
    domainId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    _destroy: _emberData.default.attr('boolean'),
    notaFiscal: _emberData.default.belongsTo('nota-fiscal'),
    referenciada: _emberData.default.belongsTo('nota-fiscal'),
    sale: _emberData.default.belongsTo('sale'),
    editing: false,
    firstEdit: Ember.computed('isNew', function () {
      return this.get('isNew');
    }),
    descricao: Ember.computed('cnpj', 'cpf', 'uf', 'numeroEcf', 'numeroCoo', 'modelo', 'mes', 'ano', 'serie', 'tipo', 'numero', 'referenciada', 'sale', function () {
      var cnpj, cpf, desc, tipo;
      desc = this.get('descricaoTipo');

      if (desc !== '') {
        desc += ': ';
      }

      tipo = this.get('tipo');
      cnpj = this.get('cnpj');
      cpf = this.get('cpf');

      if (tipo === 'nfe_interna') {
        desc += this.get('referenciada.descricaoCompleta') || '';
      } else if (tipo === 'nfe_externa' || tipo === 'cte') {
        desc += this.get('chaveExterna') || '';
      } else if (tipo === 'nf_um_ou_um_a') {
        desc += this.get('numero') + "/" + this.get('serie');
        desc += ", CNPJ " + formatCnpj(cnpj);
        desc += ", " + this.get('uf');
        desc += ", mês/ano " + this.get('mes') + "/" + this.get('ano');
      } else if (tipo === 'nf_produtor') {
        desc += this.get('numero') + "/" + this.get('serie');

        if (cnpj != null && cnpj.replace(/[_\.\/ -]/g, '') !== '') {
          desc += ", CNPJ " + formatCnpj(cnpj);
        } else if (cpf != null && cpf.replace(/[_\.\/ -]/g, '') !== '') {
          desc += ", CPF " + formatCpf(cpf);
        }

        desc += ", " + this.get('uf');
        desc += ", mês/ano " + this.get('mes') + "/" + this.get('ano');
        desc += ", mod. " + this.get('modelo');
      } else if (tipo === 'cupom_fiscal') {
        desc += " seq. ECF " + this.get('numeroEcf');
        desc += ", num. COO " + this.get('numeroCoo');
        desc += ", mod. " + this.get('modelo');
      } else if (tipo === 'nfce') {
        desc += this.get('sale.descricaoNfce') || '';
      }

      return desc;
    }),
    descricaoTipo: Ember.computed('tipo', function () {
      switch (this.get('tipo')) {
        case 'nfe_interna':
          return 'NFe interna';

        case 'nfe_externa':
          return 'NFe externa';

        case 'nf_um_ou_um_a':
          return 'NF modelo 1/1A';

        case 'nf_produtor':
          return 'NF de produtor';

        case 'cte':
          return 'Conhecimento de transporte eletrônico (CTe)';

        case 'cupom_fiscal':
          return 'Cupom fiscal';

        case 'nfce':
          return 'Venda NFCe';
      }
    }),
    valido: Ember.computed('tipo', 'chaveExterna', 'cnpj', 'cpf', 'inscricaoEstadual', 'isentoInscricaoEstadual', 'uf', 'modelo', 'numero', 'serie', 'ano', 'mes', 'numeroEcf', 'numeroCoo', 'notaFiscal', 'referenciada', 'sale', function () {
      if (!this.get('tipo')) {
        return false;
      }

      switch (this.get('tipo')) {
        case 'nfe_interna':
          return !!this.get('referenciada.id');

        case 'nfe_externa':
          return this.validarChaveNfe(this.get('chaveExterna'));

        case 'nf_um_ou_um_a':
          return this.validarCNPJ(this.get('cnpj')) && !Ember.isEmpty(this.get('uf')) && !Ember.isEmpty(this.get('modelo')) && !Ember.isEmpty(this.get('numero')) && !Ember.isEmpty(this.get('serie')) && !Ember.isEmpty(this.get('ano')) && !Ember.isEmpty(this.get('mes'));

        case 'nf_produtor':
          return (this.validarCNPJ(this.get('cnpj')) || this.validarCPF(this.get('cpf'))) && !Ember.isEmpty(this.get('uf')) && !Ember.isEmpty(this.get('inscricaoEstadual')) && !Ember.isEmpty(this.get('modelo')) && !Ember.isEmpty(this.get('numero')) && !Ember.isEmpty(this.get('serie')) && !Ember.isEmpty(this.get('ano')) && !Ember.isEmpty(this.get('mes'));

        case 'cte':
          return this.validarChaveNfe(this.get('chaveExterna'));

        case 'cupom_fiscal':
          return !Ember.isEmpty(this.get('numeroCoo')) && !Ember.isEmpty(this.get('numeroEcf')) && !Ember.isEmpty(this.get('modelo'));

        case 'nfce':
          return !!this.get('sale.id');
      }
    }),
    invalido: Ember.computed('valido', function () {
      return !this.get('valido');
    }),
    className: Ember.computed('valido', 'editing', function () {
      if (this.get('editing')) {
        return 'editing';
      } else if (this.get('invalido')) {
        return 'invalido';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});