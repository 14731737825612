define("api-dashboard/templates/components/products/product-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0Hcw/lk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"application\",\"currentDomain\",\"isErp\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"inputs/input-autocomplete\",null,[[\"modelName\",\"componentReference\",\"suggestionField\",\"selectedRecord\",\"label\",\"cols\"],[\"supplier\",[24,[\"suppliers\"]],\"name\",[24,[\"model\",\"supplier\"]],\"Fornecedor\",\"12\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"code\"]],\"Código\",\"6\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"barcode\"]],\"Código de Barras\",\"6\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-money\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"averageCost\"]],\"Custo\",\"3\"]]],false],[0,\"\\n  \"],[1,[28,\"inputs/input-money\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"price\"]],\"Preço\",\"3\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/products/product-tab.hbs"
    }
  });

  _exports.default = _default;
});