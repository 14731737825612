define("api-dashboard/templates/components/inputs/tree-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i5KtqKVa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--secondary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"hidePercentageColumns\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye-slash\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Percentuais\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--secondary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"showHiddenColumns\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n     Percentuais\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--secondary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"expandAll\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n     Linhas\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--secondary\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"collapseAll\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye-slash\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n     Linhas\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"dx-viewport\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"treegridDiv\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"clear-20\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/tree-grid.hbs"
    }
  });

  _exports.default = _default;
});