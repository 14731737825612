define("api-dashboard/templates/components/inputs/input-radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSPnzRah",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[4,\"each\",[[24,[\"computedOptions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"inputs/input-radio\",null,[[\"name\",\"selection\",\"value\"],[[24,[\"name\"]],[24,[\"field\"]],[23,1,[\"value\"]]]]],false],[0,\"\\n    \"],[1,[23,1,[\"description\"]],false],[0,\"  \\n  \"],[9],[0,\"\\n  \"],[4,\"if\",[[24,[\"oneliner\"]]],null,{\"statements\":[[7,\"br\",true],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/inputs/input-radio-group.hbs"
    }
  });

  _exports.default = _default;
});