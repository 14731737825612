define("api-dashboard/components/base/spin-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    buttonText: "Salvar",
    className: "btn btn--default btn--success",
    disabled: false,
    icon: 'fa-download',
    actions: {
      callAction: function callAction() {
        if (typeof this.get('action') === 'function') {
          return this.get('action')();
        } else {
          return this.sendAction('action');
        }
      }
    }
  });

  _exports.default = _default;
});