define("api-dashboard/components/reports/report-dre-gerencial-page", ["exports", "ember-data", "api-dashboard/mixins/formatters", "api-dashboard/mixins/utils", "jquery"], function (_exports, _emberData, _formatters, _utils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formatters.default, _utils.default, {
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    classNames: ['reportDre__content'],
    columns: [],
    lancamentosModal: null,
    params: null,
    parcelas_lancamentos: null,
    didReceiveAttrs: function didReceiveAttrs() {
      return this.getData();
    },
    getData: function getData() {
      var params, self, url;
      self = this;
      this.set('application.searchingReport', true);
      this.set('columns', []);
      params = this.getParams();
      url = '/relatorios/dre.json?' + params.join('&');
      return this.get('store').externalAjax({}, {
        apiURL: url
      }).then(function (o) {
        var column_date, columns, i, json_keys, key, len, stringfied_date;
        self.set('data', o.relatorios);
        json_keys = Object.keys(o.relatorios[0]);
        columns = [];
        columns.push({
          caption: 'Plano de Conta',
          dataField: 'contaContabil.nome',
          allowReordering: false,
          allowSorting: false,
          allowHiding: false,
          allowHeaderFiltering: false,
          allowColumnResizing: false,
          allowEditing: false,
          allowFiltering: false,
          cellTemplate: function cellTemplate(element, info) {
            return self._getCellTemplate(element, info);
          }
        });

        for (i = 0, len = json_keys.length; i < len; i++) {
          key = json_keys[i];

          if (/^r\d/.test(key)) {
            column_date = moment(key.substring(1), 'YYYYMMDD');
            stringfied_date = column_date.format('MMM/YYYY');
            columns.push({
              caption: stringfied_date,
              allowReordering: false,
              allowSorting: false,
              allowHiding: false,
              allowHeaderFiltering: false,
              allowColumnResizing: false,
              allowEditing: false,
              allowFiltering: false,
              columns: [{
                caption: 'Resultado',
                dataField: key,
                dataType: 'number',
                alignment: 'right',
                allowReordering: false,
                allowSorting: false,
                allowHiding: false,
                allowHeaderFiltering: false,
                allowColumnResizing: false,
                allowEditing: false,
                allowFiltering: false,
                format: 'R$ #,##0.00',
                cellTemplate: function cellTemplate(element, info) {
                  return self._getCellTemplate(element, info);
                },
                periodo_de: moment(column_date).startOf('month'),
                periodo_ate: moment(column_date).endOf('month'),
                clickable: true
              }, {
                caption: 'AV',
                dataField: 'p' + key.slice(1),
                dataType: 'number',
                alignment: 'right',
                allowReordering: false,
                allowSorting: false,
                allowHiding: true,
                allowHeaderFiltering: false,
                allowColumnResizing: false,
                allowEditing: false,
                allowFiltering: false,
                format: '#0.00%',
                cellTemplate: function cellTemplate(element, info) {
                  return self._getCellTemplate(element, info);
                }
              }]
            });
          }
        }

        columns.push({
          caption: 'Resumo',
          allowReordering: false,
          allowSorting: false,
          allowHiding: false,
          allowHeaderFiltering: false,
          allowColumnResizing: false,
          allowEditing: false,
          allowFiltering: false,
          columns: [{
            caption: 'Resultado',
            dataField: 'r_resumo',
            dataType: 'number',
            alignment: 'right',
            allowReordering: false,
            allowSorting: false,
            allowHiding: false,
            allowHeaderFiltering: false,
            allowColumnResizing: false,
            allowEditing: false,
            allowFiltering: false,
            format: 'R$ #,##0.00',
            cellTemplate: function cellTemplate(element, info) {
              return self._getCellTemplate(element, info);
            }
          }, {
            caption: 'AV',
            dataField: 'p_resumo',
            dataType: 'number',
            alignment: 'right',
            allowReordering: false,
            allowSorting: false,
            allowHiding: true,
            allowHeaderFiltering: false,
            allowColumnResizing: false,
            allowEditing: false,
            allowFiltering: false,
            format: '#0.00%',
            cellTemplate: function cellTemplate(element, info) {
              return self._getCellTemplate(element, info);
            }
          }]
        });
        self.set('columns', columns);
        self.set('application.searchingReport', false);
        return self.scrollToElement(Ember.$('.painelFocus__content'), Ember.$('#treegridDiv'), 1);
      });
    },
    getParams: function getParams() {
      var dateFrom, dateTo, params;
      params = this.get('params');
      dateFrom = this.formatDate(params.period.fieldFrom, {
        format: 'YYYY-MM-DD'
      });
      dateTo = this.formatDate(moment(params.period.fieldTo).endOf('month'), {
        format: 'YYYY-MM-DD'
      });
      return ['ocultar_zerados=' + Ember.String.htmlSafe(params.ocultar_zerados.value), 'periodo_de=' + Ember.String.htmlSafe(dateFrom), 'periodo_ate=' + Ember.String.htmlSafe(dateTo), !!params.centro_custo_id.value ? 'centro_custo_id=' + Ember.String.htmlSafe(params.centro_custo_id.value) : void 0];
    },
    _getCellTemplate: function _getCellTemplate(element, info) {
      if (info.data.contaContabil.folha || info.data.contaContabil.id < 0) {
        if (!info.data.contaContabil.tipo) {
          element.append("<div class='reportDre__content--cell reportDre__content--results'>" + info.text + "</div>");
          return;
        }

        if (parseInt(info.value) !== 0 && info.column.clickable && info.data.contaContabil.tipo) {
          element.append("<div class='reportDre__content--cell'><a href='#'>" + info.text + "</a></div>");
          return;
        }
      }

      return element.append("<div class='reportDre__content--cell'>" + info.text + "</div>");
    },
    actions: {
      onCellClick: function onCellClick(previousValue, newValue, component, e) {
        var conta_contabil, params, queryParams, self;
        self = this;
        params = this.getParams();

        if (e.data) {
          conta_contabil = e.data.contaContabil;

          if (conta_contabil && conta_contabil.folha && e.column.clickable && e.data.contaContabil.id > 0) {
            this.set('searchingLancamentos', true);
            this.get('lancamentosModal').show();
            queryParams = {
              tipo_data: 'data_competencia',
              tipo_periodo: 'especificar',
              conta_contabil_id: conta_contabil.id,
              periodo_de: e.column.periodo_de.format('D/M/YYYY'),
              periodo_ate: e.column.periodo_ate.format('D/M/YYYY')
            };

            if (parseInt(self.get('params.centro_custo_id.value')) > 0) {
              queryParams['centro_custo_id'] = parseInt(self.get('params.centro_custo_id.value'));
            }

            return this.get('store').query('parcela-lancamento', queryParams).then(function (obj) {
              self.set('parcelas_lancamentos', null);

              if (obj && obj.content) {
                self.set('parcelas_lancamentos', obj);
              } else {
                self.get('application').showAlert('Erro ao buscar os Lançamentos do Período.', 'error');
                this.get('lancamentosModal').hide();
              }

              return self.set('searchingLancamentos', false);
            });
          }
        }
      },
      closeLancamentosModal: function closeLancamentosModal(modal) {
        return modal.hide();
      }
    }
  });

  _exports.default = _default;
});