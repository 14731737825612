define("api-dashboard/components/sales/sales-form", ["exports", "api-dashboard/mixins/utils", "api-dashboard/config/environment"], function (_exports, _utils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_utils.default, {
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    withoutCoo: false,
    nfeValidationErrors: [],
    totalPriceSoldVisible: Ember.computed('model.isCanceled', function () {
      return !this.get('model.isCanceled');
    }),
    totalPriceReturnedVisible: Ember.computed('model.returnedItems', 'model.isCanceled', function () {
      return this.get('model.returnedItems.length') > 0 && !this.get('model.isCanceled');
    }),
    discountVisible: Ember.computed('model.discount', 'model.isCanceled', function () {
      if (this.get('model.discount')) {
        return new Big(this.get('model.discount')).gt(0) && !this.get('model.isCanceled');
      }
    }),
    creditVisible: Ember.computed('model.canceled', 'model.isCanceled', function () {
      if (this.get('model.credit')) {
        return new Big(this.get('model.credit')).gt(0) && !this.get('model.isCanceled');
      }
    }),
    totalPaidVisible: Ember.computed('model.isCanceled', function () {
      return !this.get('model.isCanceled');
    }),
    netValueVisible: Ember.computed('model.isCanceled', function () {
      return !this.get('model.isCanceled');
    }),
    totalCanceledVisible: Ember.computed('model.isCanceled', function () {
      return this.get('model.isCanceled');
    }),
    nfeDevolucaoVisible: Ember.computed('model.isCanceled', function () {
      return this.get('model.returnedItems.length') > 0 && this.get('model.returnedItems').any(function (r) {
        return r.get('returnSaleId');
      });
    }),
    cleanCoo: Ember.observer('withoutCoo', function () {
      if (this.get('withoutCoo')) {
        return this.set('coo', null);
      }
    }),
    fetchParams: function fetchParams() {
      return {
        date_from: this.get('model.dateFrom'),
        date_to: this.get('model.dateTo'),
        total_price_sold_from: this.get('model.totalPriceSoldFrom'),
        total_price_sold_to: this.get('model.totalPriceSoldTo') ? this.get('model.totalPriceSoldTo') : null,
        pos_user_id: this.get('model.posUser.id'),
        customer_id: this.get('model.customer.id'),
        number: this.get('model.number'),
        coo: this.get('withoutCoo') ? 'null' : this.get('model.coo'),
        store_id: this.get('model.fStore.id'),
        pos_id: this.get('model.pos.id'),
        status: this.get('model.status'),
        only_without_customer: this.get('model.onlyWithoutCustomer'),
        only_with_returned_items: this.get('model.onlyWithReturnedItems'),
        numero_nfce: this.get('model.numeroNfce'),
        only_with_nfce: this.get('model.onlyWithNfce'),
        only_with_nfce_cont: this.get('model.onlyWithNfceCont'),
        only_without_nfce: this.get('model.onlyWithoutNfce'),
        only_with_sat: this.get('model.onlyWithSat')
      };
    },
    actions: {
      onSubmit: function onSubmit(record) {
        var errors, self;
        self = this;

        if (!record.validate()) {
          errors = record.get('errors');
          return errors.forEach(function (err) {
            return self.get('application').showAlert(err.message, 'error');
          });
        } else {
          return this.sendAction('afterSave', record);
        }
      },
      onCancel: function onCancel(record) {
        if (record == null) {
          record = null;
        }

        return this.sendAction('onCancel', record);
      },
      downloadXml: function downloadXml() {
        var url;
        url = "/notas_fiscais_consumidor/" + this.get('model.id') + ".xml";
        return this.downloadURL(url);
      },
      downloadXmlCancelamento: function downloadXmlCancelamento() {
        var url;
        url = "/notas_fiscais_consumidor/" + this.get('model.id') + "-can.xml";
        return this.downloadURL(url);
      },
      viewDanfe: function viewDanfe() {
        var url;
        url = _environment.default.hostNfe + "/notas_fiscais_consumidor/" + this.get('model.chaveNfce') + ".html";
        return window.open(url, '_blank');
      },
      generateBoleto: function generateBoleto(parcela) {
        var self;
        self = this;
        parcela.set('gerou', true);
        return $.ajax({
          url: "/boletos",
          type: 'POST',
          dataType: 'json',
          controller: this,
          data: {
            boleto: {
              parcela_lancamento_id: parcela.id
            }
          },
          success: function success(r) {
            alert('Boleto gerado com sucesso! Ele será mostrado em outra janela.');
            self.get('store').findRecord('boleto', r.boleto.id).then(function (boleto) {
              return parcela.set('ultimoBoletoValido', boleto);
            });
            return window.open('/boletos/' + r.boleto.numero + '?access_key=' + r.boleto.access_key);
          },
          error: function error() {
            return alert('erro');
          }
        });
      },
      createNfe: function createNfe() {
        var ajaxParams, self;
        self = this;
        ajaxParams = {
          type: "PUT"
        };
        return self.get("store").externalAjax(ajaxParams, {
          apiURL: "/sales/" + this.get('model.id') + "/create_nfe.json"
        }, function (sucesso, data, ajaxCallbackParams) {
          var errors, response;

          if (sucesso) {
            self.sendAction('afterCreateNfe', data.nota_fiscal.id);
            return self.set('spin', false);
          } else {
            errors = '';
            response = ajaxCallbackParams.jqXHR.responseJSON;

            if (!response) {
              response = {
                errors: {
                  base: ['Ocorreu um erro ao gerar a nota fiscal, por favor contate o suporte técnico']
                }
              };
            }

            self.get('application').showAlert(response.errors.base.join('. '), 'error');
            return self.set('spin', false);
          }
        });
      },
      createNfeDevolucao: function createNfeDevolucao() {
        var ajaxParams, self;
        self = this;
        ajaxParams = {
          type: "PUT"
        };
        return self.get("store").externalAjax(ajaxParams, {
          apiURL: "/sales/" + this.get('model.id') + "/create_nfe_devolucao.json"
        }, function (sucesso, data, ajaxCallbackParams) {
          var errors, response;

          if (sucesso) {
            self.sendAction('afterCreateNfe', data.nota_fiscal.id);
            return self.set('spin', false);
          } else {
            errors = '';
            response = ajaxCallbackParams.jqXHR.responseJSON;

            if (!response) {
              response = {
                errors: {
                  base: ['Ocorreu um erro ao gerar a nota fiscal, por favor contate o suporte técnico']
                }
              };
            }

            self.get('application').showAlert(response.errors.base.join('. '), 'error');
            return self.set('spin', false);
          }
        });
      },
      cancelSale: function cancelSale() {
        var msg, sale, self;
        self = this;
        msg = 'Tem certeza que deseja cancelar esta venda? Esta operação não poderá ser desfeita.';

        if (confirm(msg)) {
          self.set('spin', true);
          sale = this.get('model');
          sale.rollbackAttributes();
          sale.set('status', 'canceled');
          return sale.save().then(function (obj) {
            if (obj.get('isCanceled')) {
              self.get('application').showAlert('Venda cancelada com sucesso!', 'success');
            } else {
              self.get('application').showAlert('Erro ao cancelar a venda. Por favor contate o suporte técnico!', 'error');
            }

            self.set('spin', false);
            return self.get('router').transitionTo('sales/edit', obj.get('id'));
          }, function () {
            self.get('application').showAlert('Erro ao cancelar a venda. Por favor contate o suporte técnico', 'error');
            return self.set('spin', false);
          });
        }
      },
      print: function print() {
        var url;
        url = '/sales/' + this.get('model.id') + '/report';
        return this.downloadURL(url, "venda_" + this.get("model.number") + ".pdf");
      }
    }
  });

  _exports.default = _default;
});