define("api-dashboard/models/prestador", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nome: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    inscricaoMunicipal: _emberData.default.attr('string'),
    nfseClassName: _emberData.default.attr('string'),
    aliquota: _emberData.default.attr('number'),
    municipio: _emberData.default.belongsTo('municipio'),
    notaFiscalServico: _emberData.default.belongsTo('nota-fiscal-servico', {
      inverse: 'prestador'
    }),
    description: Ember.computed('nome', 'municipio', function () {
      return this.get('nome') + " - Município: " + this.get('municipio.nomeMunicipio');
    })
  });

  _exports.default = _default;
});