define("api-dashboard/models/pos-report-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    reportType: _emberData.default.attr('string'),
    domainId: _emberData.default.attr('number'),
    filename: _emberData.default.attr('string'),
    md5: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    isDefault: _emberData.default.attr('boolean'),
    active: _emberData.default.attr('boolean'),
    posReportFileConfig: _emberData.default.belongsTo('posReportFileConfig'),
    panelClass: Ember.computed('active', function () {
      var pclass;
      pclass = "panel panel-pos-report-file ";

      if (this.get('active')) {
        pclass += 'panel-success';
      } else {
        pclass += 'panel-default';
      }

      return pclass;
    })
  });

  _exports.default = _default;
});