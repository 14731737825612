define("api-dashboard/components/notas-fiscais/nota-fiscal-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    componentReference: null,
    modalReference: null,
    nf: null,
    processandoCancelamento: false,
    saveCaption: 'Cancelar Nota Fiscal',
    cancelCaption: 'Não Cancelar',
    didInsertElement: function didInsertElement() {
      return this.set('componentReference', this);
    },
    cancelarNota: function cancelarNota(nf) {
      this.set('nf', nf);
      return this.get('modalReference').show();
    },
    actions: {
      beforeShow: function beforeShow() {
        this.set('erros', null);
        this.set('processandoCancelamento', false);
        this.set('urlXmlCancelamento', null);
        return this.set('justificativa', '');
      },
      hide: function hide() {
        return this.get('modalReference').hide();
      },
      onSave: function onSave() {
        var self;
        self = this;
        self.set('erros', null);
        this.set('processandoCancelamento', true);
        return this.get('nf').cancelar(this.get('justificativa'), function (res) {
          return self.get('nf').reload().then(function () {
            self.set('processandoCancelamento', false);
            return self.set('isCancelled', true);
          });
        }, function (res) {
          self.set('erros', res.mensagem);
          return self.set('processandoCancelamento', false);
        });
      }
    }
  });

  _exports.default = _default;
});