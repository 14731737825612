define("api-dashboard/models/modelo-lancamento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    nome: _emberData.default.attr('string'),
    tipo: _emberData.default.attr('string'),
    descricao: _emberData.default.attr('string'),
    dataVencimentoHoje: _emberData.default.attr('boolean'),
    dataCompetenciaHoje: _emberData.default.attr('boolean'),
    valor: _emberData.default.attr('number'),
    preencherDataCompetencia: _emberData.default.attr('boolean'),
    dataCompetenciaMesAnterior: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domain: _emberData.default.belongsTo('domain'),
    supplier: _emberData.default.belongsTo('supplier'),
    customer: _emberData.default.belongsTo('customer'),
    costCenter: _emberData.default.belongsTo('cost-center'),
    contaContabil: _emberData.default.belongsTo('conta-contabil'),
    isRecebimento: false,
    tiposPossiveis: Ember.computed(function () {
      return this.get('constants.modelosLancamentoTypes');
    }),
    dataCompetenciaMesAnteriorValues: Ember.computed(function () {
      return this.get('constants.modeloLancamentoDataCompetenciaMesAnteriorValues');
    }),
    descricaoTipo: Ember.computed('tipo', function () {
      if (this.get('tipo') === 'RECEITA') {
        return 'Receber';
      } else {
        return 'Pagar';
      }
    }),
    isRecebimentoObserver: Ember.observer('tipo', function () {
      return this.set('isRecebimento', this.get('tipo') === 'RECEITA');
    }),
    ready: function ready() {
      return this.set('isRecebimento', this.get('tipo') === 'RECEITA');
    }
  });

  _exports.default = _default;
});