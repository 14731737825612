define("api-dashboard/components/inputs/input-toggle", ["exports", "api-dashboard/components/base/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    A button that has two states: on and off
  
      @value: binding to true or false
   */
  var _default = _baseComponent.default.extend({
    classNames: ["switch toggle--button"],
    classNameBindings: ['colsClassName'],
    layoutName: 'components/inputs/input-toggle',
    tagName: "span",
    actionOnToggle: null,
    actionOnClick: null,
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    didInsertElement: function didInsertElement() {
      this.set('checked', Boolean(this.get('value')));

      if (this.get('value')) {
        return this.sendAction('actionOnToggle', this.get('value'), null);
      }
    },
    change: function change() {
      if (this.get('actionOnToggle')) {
        return this.sendAction('actionOnToggle', this.get('value'), null);
      }
    },
    click: function click() {
      if (this.get('actionOnClick')) {
        return this.sendAction('actionOnClick', this.get('value'), null);
      }
    }
  });

  _exports.default = _default;
});