define("api-dashboard/mixins/components/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deactivateCustomer: function deactivateCustomer(customer) {
      var self;
      self = this;

      if (confirm('Confirma a DESATIVAÇÃO do Cliente?')) {
        Ember.set(customer, 'active', false);
        Ember.set(customer, 'loadingIndexAction', true);
        return customer.save().then(function (obj) {
          self.get('application').showAlert("Cliente " + customer.get('name') + " desativado com sucesso!", 'success');
          return Ember.set(customer, 'loadingIndexAction', false);
        }, function () {
          self.get('application').showAlert("Erro ao desativar Cliente " + customer.get('name') + "!", 'error');
          return Ember.set(customer, 'loadingIndexAction', false);
        });
      }
    },
    activateCustomer: function activateCustomer(customer) {
      var self;
      self = this;

      if (confirm('Confirma a ATIVAÇÃO do Cliente?')) {
        Ember.set(customer, 'active', true);
        Ember.set(customer, 'loadingIndexAction', true);
        return customer.save().then(function (obj) {
          self.get('application').showAlert("Cliente " + customer.get('name') + " ativado com sucesso!", 'success');
          return Ember.set(customer, 'loadingIndexAction', false);
        }, function () {
          self.get('application').showAlert("Erro ao ativar Cliente " + customer.get('name') + "!", 'error');
          return Ember.set(customer, 'loadingIndexAction', false);
        });
      }
    },
    showBlockCustomerModal: function showBlockCustomerModal(customer) {
      this.set('selectedCustomer', customer);
      return this.get('blockCustomerModal').show();
    },
    blockCustomer: function blockCustomer() {
      var customer, self;
      self = this;

      if (!!this.get('selectedCustomer.reasonBlocked')) {
        customer = self.get('selectedCustomer');
        Ember.set(customer, 'blocked', true);
        Ember.set(customer, 'loadingIndexAction', true);
        customer.save().then(function (_this) {
          return function (obj) {
            self.get('application').showAlert("Cliente " + customer.get('name') + " bloqueado com sucesso!", 'success');
            return Ember.set(customer, 'loadingIndexAction', false);
          };
        }(this), function (_this) {
          return function () {
            self.get('application').showAlert("Erro ao bloquear Cliente " + customer.get('name') + "!", 'error');
            return Ember.set(customer, 'loadingIndexAction', false);
          };
        }(this));
        return this.get('blockCustomerModal').hide();
      }
    },
    unblockCustomer: function unblockCustomer(customer) {
      var self;
      self = this;

      if (confirm('Confirma o DESBLOQUEIO do Cliente?')) {
        Ember.set(customer, 'blocked', false);
        Ember.set(customer, 'loadingIndexAction', true);
        return customer.save().then(function (_this) {
          return function (obj) {
            self.get('application').showAlert("Cliente " + customer.get('name') + " desbloqueado com" + "sucesso!", 'success');
            return Ember.set(customer, 'loadingIndexAction', false);
          };
        }(this), function (_this) {
          return function () {
            self.get('application').showAlert("Erro ao desbloquear Cliente " + customer.get('name') + "!", 'error');
            return Ember.set(customer, 'loadingIndexAction', false);
          };
        }(this));
      }
    }
  });

  _exports.default = _default;
});