define("api-dashboard/templates/components/products/ecommerce-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZBLmw5R7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"div-vazia-35\"],[8],[9],[0,\"\\n  \"],[1,[28,\"inputs/input-toggle\",null,[[\"value\",\"cols\",\"label\"],[[24,[\"model\",\"enableEcommerce\"]],\"4\",\"Habilita este produto para E-Commerce\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/input-money\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"ecommercePrice\"]],\"Preço para E-Commerce\",\"4\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  * Se este valor for zero será considerado o valor do produto.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/products/ecommerce-tab.hbs"
    }
  });

  _exports.default = _default;
});