define("api-dashboard/routes/itens/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavel.default.extend({
    queryParams: {
      notaFiscalId: {
        refreshModel: true
      },
      productId: {
        refreshModel: true
      },
      cfopId: {
        refreshModel: true
      },
      detalhe: {
        refreshModel: true
      },
      quantidadeComercial: {
        refreshModel: true
      },
      valorUnitarioComercial: {
        refreshModel: true
      },
      valorEntrada: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var cfopPromise, nfPromise, productPromise, promises, self;
      self = this;
      nfPromise = null;
      productPromise = null;
      cfopPromise = null;
      nfPromise = this.get('store').findRecord('nota-fiscal', params['notaFiscalId']);

      if (params['productId']) {
        productPromise = this.get('store').findRecord('product', params['productId']);
      }

      if (params['cfopId']) {
        cfopPromise = this.get('store').findRecord('cfop', params['cfopId']);
      }

      promises = {
        nfPromise: nfPromise,
        productPromise: productPromise,
        cfopPromise: cfopPromise
      };
      return Ember.RSVP.hashSettled(promises).then(function (settledPromises) {
        var cfop, notaFiscal, product;
        notaFiscal = settledPromises.nfPromise.value;
        product = settledPromises.productPromise.value;
        cfop = settledPromises.cfopPromise.value;
        return self.get('store').createRecord('item', {
          notaFiscal: notaFiscal,
          product: product,
          cfop: cfop,
          detalhe: params['detalhe'],
          quantidadeComercial: params['quantidadeComercial'],
          quantidadeTributavel: params['quantidadeComercial'],
          valorUnitarioComercial: params['valorUnitarioComercial'],
          valorUnitarioTributavel: params['valorUnitarioComercial'],
          valorEntrada: params['valorEntrada']
        });
      });
    },
    actions: {
      finish: function finish(item) {
        return this.transitionTo("notas-fiscais/edit", item.get('notaFiscal'));
      }
    }
  });

  _exports.default = _default;
});