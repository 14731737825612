define("api-dashboard/models/label-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    className: _emberData.default.attr('string'),
    configXml: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    customField1ClassName: _emberData.default.attr('string'),
    fileExtension: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    domain: _emberData.default.belongsTo('domain')
  });

  _exports.default = _default;
});