define("api-dashboard/templates/components/cancel/cancel-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CwbsLBhm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Cancelar Contrato do Sistema\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"base/base-form\",null,[[\"model\",\"onSubmit\",\"onCancel\",\"searching\"],[[24,[\"model\"]],[24,[\"onSubmit\"]],\"onCancel\",[24,[\"searching\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n      Mensagem sobre o cancelamento do contrato...\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"Motivo do cancelamento:\"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"textarea\",null,[[\"value\",\"rows\",\"cols\"],[[24,[\"cancelreason\"]],\"5\",\"120\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/cancel/cancel-form.hbs"
    }
  });

  _exports.default = _default;
});