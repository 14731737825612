define("api-dashboard/models/inventory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    fStore: _emberData.default.belongsTo('f_store'),
    product: _emberData.default.belongsTo('product'),
    inStockQuantity: _emberData.default.attr('number'),
    soldQuantity: _emberData.default.attr('number')
  });

  _exports.default = _default;
});