define("api-dashboard/services/store", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Store.extend({
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    historicoFindAll: [],
    findAll: function findAll(modelName, options) {
      this.get('historicoFindAll').push(modelName);
      return this._super(modelName, options);
    },
    unloadAll: function unloadAll(modelName) {
      this.get('historicoFindAll').removeObject(modelName);
      return this._super(modelName);
    },
    getAuthHeaders: function getAuthHeaders(callback) {
      return this.get('session').get('store').restore().then(function (storedData) {
        return callback(true, storedData);
      }, function (error) {
        return callback(false, error);
      });
    },
    externalAjax: function externalAjax(ajaxParams, options, callback) {
      var run, self;

      if (ajaxParams == null) {
        ajaxParams = {};
      }

      if (options == null) {
        options = {};
      }

      self = this;

      run = function run(ajaxParams) {
        if (callback) {
          return $.ajax(ajaxParams).then(function (data, textStatus, jqXHR) {
            return callback(true, data, {
              data: data,
              textStatus: textStatus,
              jqXHR: jqXHR
            });
          }, function (jqXHR, textStatus, errorThrown) {
            return callback(false, textStatus, {
              jqXHR: jqXHR,
              textStatus: textStatus,
              errorThrown: errorThrown
            });
          });
        } else {
          return $.ajax(ajaxParams);
        }
      };

      self = this;

      if (options.hasOwnProperty('useEmberDataHostHeaders') && !options['useEmberDataHostHeaders']) {} else {
        return this.getAuthHeaders(function (success, data) {
          var prefix;
          ajaxParams['headers'] = '';
          ajaxParams['url'] = self.adapterFor('application').get('host') + options['apiURL'];

          if (self.get('session.isAuthenticated')) {
            prefix = ajaxParams['url'].indexOf('?') === -1 ? '?' : '&';
            ajaxParams['url'] = "" + ajaxParams['url'] + prefix + "domain_id=" + self.get('application.currentDomain.id');
          }

          if (success) {
            ajaxParams['headers'] = {
              'Authorization': "Token token=" + data['authenticated']['token'],
              'Client-App': 'NewEmber'
            };
          }

          return run(ajaxParams);
        });
      }
    }
  });

  _exports.default = _default;
});