define("api-dashboard/components/inputs/input-cnpj-cpf", ["exports", "api-dashboard/components/inputs/base-masked-field", "api-dashboard/mixins/utils"], function (_exports, _baseMaskedField, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend(_utils.default, {
    pattern: '00000000000000',
    aplicarMascara: function aplicarMascara() {
      var length, patternCnpj, patternCpf;
      length = $('input').length;
      patternCpf = '000.000.000-00';
      patternCnpj = '00.000.000/0000-00';

      if (length <= 11) {
        return this.$('input').mask(patternCpf);
      }

      return this.$('input').mask(patternCnpj);
    },
    validarValorMascara: function validarValorMascara() {
      var length, value;
      value = this.obterValorSemMascara();

      if (!value) {
        return false;
      }

      length = new String(value).length;

      if (length <= 11) {
        return this.validarCPF(value);
      }

      return this.validarCNPJ(value);
    },
    paramsRetornoFimValidacao: function paramsRetornoFimValidacao(params) {
      params = this._super(params);

      if (params["valido"]) {
        if (params["valueSemMascara"].length === 11) {
          params["tipo"] = "cpf";
        } else {
          params["tipo"] = "cnpj";
        }
      }

      return params;
    }
  });

  _exports.default = _default;
});