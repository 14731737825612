define("api-dashboard/routes/empresas/editar", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel"], function (_exports, _rotaAutenticavel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmpresasEditarRoute;
  EmpresasEditarRoute = _rotaAutenticavel.default.extend({
    templateName: "nova-empresa",
    controllerName: "nova-empresa",
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return controller.set("modoEdicao", true);
    },
    deactivate: function deactivate() {
      return this.resetModelSeNaoEditado(this, {
        controllerName: "empresas.editar"
      });
    }
  });
  var _default = EmpresasEditarRoute;
  _exports.default = _default;
});