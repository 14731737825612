define("api-dashboard/templates/components/notas-fiscais/item-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPnt8doI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"apiPanel__tit\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Editar Item\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"Novo Item\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"cadastro-items\"],[10,\"class\",\"focus-general-container\"],[8],[0,\"\\n      \"],[15,\"components/notas-fiscais/item-form/-form\",[]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/notas-fiscais/item-form.hbs"
    }
  });

  _exports.default = _default;
});