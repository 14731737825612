define("api-dashboard/components/inputs/input-select", ["exports", "api-dashboard/mixins/dev-express", "ember-data"], function (_exports, _devExpress, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
  Descrição: Cria um drop down contendo opções para seleção. A fonte das opções
    para este drop down pode ser um array de objetos ou uma coleção de registros
    EmberData.
  
  Parâmetros obrigatórios:
    @dataSource ou @options:
      @dataSource: Uma promise de um emberData que irá resolver em um conjunto de
                 registros.
      @options: Um array de objetos contendo as chaves value e description. value será
                a chave que irá retornar no value e description é o que irá ser mostrado
                ao usuário.
  
  Parâmetros opcionais:
    @displayField: (valor padrão: description)
      Determina o nome do atributo que será utilizado para mostrar o registro
      na lista
    @value: O registro atualmente selecionado. Esta propriedade será bindada
      e quando o registro selecionado for alterado ela será alterada.
    @showClearButton: Indica se deve ser mostrado o botão para apagar o
      registro selecionado
    @prompt: Prompt quando não nada selecionado (valor padrão: "Selecione")
  
  Atributos de retorno / binding:
    @value: Irá conter o registro atualmente selecionado
  
  Actions:
    onChange: Disparada quando o registro selecionado é alterado
      previousValue: o valor setado antes da mudança
      value: o valor depois da mudança (atual)
      e: O Evento disparado no componente. ATENÇÃO: não é recomendado utilizar
         este evento, uma vez que o componente utilizado nesta implementação
         poderá ser alterado futuramente.
  
  Exemplo de uso:
  
    {{inputs/input-select
      dataSource=globalLookups.emitentes
      value=model.emitente
      displayField="nome"
      onChange=(action "onValueChanged")
    }}
  
  
  TODO:
   - implementar parâmetro "disabled"
   - implementar indicador de erro "error" como havia no ember antigo
   - se options é atualizado, select não atualiza
   */
  var _default = Ember.Component.extend(_devExpress.default, {
    classNameBindings: ['colsClassName'],
    classNames: [''],
    componentReference: null,
    dataSource: null,
    debugName: 'input-select',
    displayField: 'description',
    label: null,
    layoutName: 'components/inputs/input-select',
    onChange: null,
    prompt: 'Selecione',
    showClearButton: true,
    value: null,
    valueField: 'value',
    disabled: false,
    width: 'auto',
    optionsTypes: {
      dataSource: 'DataSource',
      options: 'Options'
    },
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    dxDataSource: Ember.computed('dataSource', function () {
      var valueField;
      valueField = '';

      if (!(this.get('valueField') === 'value')) {
        valueField = this.get('valueField');
      }

      return this.convertEmberData2DevExpress(this.get('dataSource'), [this.get('displayField'), valueField]);
    }),
    onSetValue: Ember.observer('value', function () {
      var comp, self, val;
      self = this;
      val = this.get('value');
      comp = self.get('componentReference');

      if (val && comp) {
        if (val.then) {
          val.then(function (o) {
            if (!!o && comp) {
              return comp.option('value', o);
            }
          });
        } else {
          comp.option('value', val);
        }
      }

      if (comp && !val && val !== 0) {
        return comp.reset();
      }
    }),
    effectiveDataSource: Ember.computed('dataSource', 'options', function () {
      var self;
      self = this;

      switch (self.get('optionsType')) {
        case self.get('optionsTypes.options'):
          return self.get('options');

        case self.get('optionsTypes.dataSource'):
          return self.get('dxDataSource');
      }
    }),
    optionsType: Ember.computed('dataSource', 'options', function () {
      if (this.get('dataSource') || this.get('dataSource.then')) {
        return this.get('optionsTypes.dataSource');
      } else if (this.get('options')) {
        return this.get('optionsTypes.options');
      }
    }),
    dataSourceObserver: Ember.observer('dataSource', 'options', function () {
      if (!this.get('options') || this.get('options.length') < 1 || this.get('dataSource.length') < 1) {
        this.set('value', null);
      }

      this.get('componentReference').option('dataSource', this.get('effectiveDataSource'));
      return this.buildComponent();
    }),
    disabledObserver: Ember.observer('disabled', function () {
      return this.buildComponent();
    }),
    didInsertElement: function didInsertElement() {
      var val;
      val = this.get('value.content') === void 0 ? this.get('value') : this.get('value.content');
      this.set('value', val);
      return this.buildComponent();
    },
    didReceiveAttrs: function didReceiveAttrs() {},
    internalOnChange: function internalOnChange(e) {
      var self;
      self = this;

      if (self.get('onBeforeChange')) {
        self.get('onBeforeChange')(e.previousValue, e.value, e);
      }

      if (e.previousValue !== e.value) {
        this.set('value', e.value);
      }

      if (self.get('onChange')) {
        return self.get('onChange')(e.previousValue, e.value, e);
      }
    },
    buildComponent: function buildComponent() {
      var comp, opt, self;
      self = this;
      DevExpress.ui.dxOverlay.baseZIndex(99999);
      opt = {
        value: self.get('value'),
        valueExpr: function valueExpr(item) {
          if (self.get('valueField') === 'this') {
            return item;
          }

          if (item) {
            return Ember.get(item, self.get('valueField'));
          }

          return item;
        },
        displayExpr: function displayExpr(item) {
          var displayExpr;

          if (item) {
            displayExpr = Ember.get(item, self.get('displayField'));
          }

          return displayExpr || '';
        },
        dataSource: self.get('effectiveDataSource'),
        showClearButton: self.get('showClearButton'),
        placeholder: self.get('prompt'),
        noDataText: 'Não há dados',
        disabled: this.get('disabled'),
        width: this.get('width'),
        wrapItemText: false,
        onValueChanged: function onValueChanged(e) {
          return self.get('internalOnChange').call(self, e);
        }
      };
      comp = $(this.element).find('#selectBoxDiv').dxSelectBox(opt).dxSelectBox('instance');
      comp.on('mouseWheel', {
        passive: false
      });
      return this.set('componentReference', comp);
    }
  });

  _exports.default = _default;
});