define("api-dashboard/adapters/application-panel", ["exports", "ember-data", "api-dashboard/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var ret;
      ret = this._super.apply(this, arguments);
      ret = ret.replace(modelName, "panel_api/" + modelName);
      return ret;
    }
  });

  _exports.default = _default;
});