define("api-dashboard/components/busca-referencias/busca-referencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    parametrosBusca: null,
    actions: {
      actFazerNovaBusca: function actFazerNovaBusca(params, callbackOnLoadComplete) {
        if (callbackOnLoadComplete == null) {
          callbackOnLoadComplete = function callbackOnLoadComplete(sucesso, params) {};
        }

        this.set("parametrosBusca", params);
        return callbackOnLoadComplete(true, {});
      }
    }
  });

  _exports.default = _default;
});