define("api-dashboard/models/saas-price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nfceEmissorPrice: _emberData.default.attr('number'),
    nfceApiPrice: _emberData.default.attr('number'),
    erpPrice: _emberData.default.attr('number'),
    customPrice: _emberData.default.attr('number'),
    flStorePrice: _emberData.default.attr('number'),
    flPosPrice: _emberData.default.attr('number'),
    flFiscalPrice: _emberData.default.attr('number'),
    validFrom: _emberData.default.attr('date'),
    validUntil: _emberData.default.attr('date'),
    active: _emberData.default.attr('boolean'),
    "default": _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    customDescription: _emberData.default.attr('string'),
    revendaNfeFixedPrice: _emberData.default.attr('number'),
    revendaNfeNewCnpjPrice: _emberData.default.attr('number'),
    cnpjPriceMethod: _emberData.default.attr('string'),
    nfeBandMethod: _emberData.default.attr('string'),
    groupEmitentes: _emberData.default.attr('boolean'),
    nfe: _emberData.default.attr('boolean'),
    nfse: _emberData.default.attr('boolean'),
    nfce_sat: _emberData.default.attr('boolean'),
    cte: _emberData.default.attr('boolean'),
    mde: _emberData.default.attr('boolean'),
    mdfe: _emberData.default.attr('boolean'),
    contracts: _emberData.default.hasMany('contract', {
      inverse: 'saasPrice',
      async: false
    }),
    customers: _emberData.default.hasMany('customer', {
      inverse: 'saasPrice',
      async: false
    }),
    saasNfeBandPrices: _emberData.default.hasMany('saas-nfe-band-price', {
      async: false
    }),
    saasCnpjBandPrices: _emberData.default.hasMany('saas-cnpj-band-price', {
      async: false
    }),
    person: _emberData.default.belongsTo('person')
  });

  _exports.default = _default;
});