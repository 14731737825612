define("api-dashboard/components/inputs/base-text-field", ["exports", "api-dashboard/components/base/base-component", "api-dashboard/mixins/utils"], function (_exports, _baseComponent, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Define um componente base para os campos input de texto, e provê todos os métodos genéricos
    para que os campos do tipo 'input texto' funcionem corretamente.
  
    * TODOS os componentes do tipo 'input texto' devem herdar de BaseTextField, sem exceção. *
  
    Para herdar esse componente, basta importá-lo e estender-lo no componente a ser criado:
  
        import BaseTextField from '(...)/inputs/base-text-field'
        export default BaseTextField.extend ...
  
    Parâmetros:
        - allowEmpty:
            Define se o valor do campo input dentro desse componente pode ser ou não vazio
        - minLength:
            Define o número mínimo de caracteres que o valor deve ter. 'null' significa infinito
        - max:
            Define o valor máximo para o campo, normalmente usado quando o campo é numérico
        - min:
            Define o valor mínimo para o campo, normalmente usado quando o campo é numérico
        - prefix:
            Define o prefixo do campo, normalmente usado em campos de moeda
        - size:
            Define a largura máxima do campo input dentro desse componente
        - readonly:
            Define se o input será editável ou não
        - val:
            O valor do campo input dentro desse componente, que será utilizado como o valor principal
   */
  var _default = _baseComponent.default.extend(Ember.TextSupport, _utils.default, {
    allowEmpty: true,
    classNameBindings: ['colsClassName', 'errorsClassName'],
    classNames: [''],
    cols: '',
    cssClasses: ['col-xs-6', 'ember-text-field', 'form-control'],
    errors: null,
    input: $('input'),
    isRequired: false,
    layoutName: 'components/inputs/base-text-field',
    maxLength: null,
    minLength: null,
    max: null,
    min: null,
    prefix: '',
    scale: 2,
    size: null,
    readonly: false,
    val: null,
    actionOnFimValidacao: null,
    actionOnIniciarValidacaoAssincrona: null,
    actionOnFinalizarValidacaoAssincrona: null,
    actionOnRealizarValidacaoAssincrona: null,
    metodoAssincronoValidacaoInterna: null,
    type: Ember.computed(function () {
      return {
        get: function get() {
          return 'text';
        },
        set: function set(_key, value) {
          var type;
          type = value;
          return type;
        }
      };
    }),
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    errorsClassName: Ember.computed('errors', function () {
      if (this.get('errors')) {
        return 'has-error';
      }
    }),
    possuiMetodoAssincronoValidacaoInterna: Ember.computed("metodoAssincronoValidacaoInterna", function () {
      if (!this.get("metodoAssincronoValidacaoInterna")) {
        return false;
      }

      return true;
    }),
    validarLengthMaximo: Ember.computed("maxLength", function () {
      if (!this.get("maxLength")) {
        return false;
      }

      return true;
    }),
    validarLengthMinimo: Ember.computed("minLength", function () {
      if (!this.get("minLength")) {
        return false;
      }

      return true;
    }),
    validarAssincronamente: Ember.computed("actionOnRealizarValidacaoAssincrona", function () {
      if (!this.get("actionOnRealizarValidacaoAssincrona")) {
        return false;
      }

      return true;
    }),
    didInsertElement: function didInsertElement() {
      this.$('input').removeClass('ember-view');
      return this._super();
    },
    change: function change() {
      var self;
      self = this;
      return this.validarCampo(function (params) {
        var callbackAposRetornoValidacao;
        return self.sendAction("actionOnFimValidacao", params, callbackAposRetornoValidacao = function callbackAposRetornoValidacao(args) {
          if (args == null) {
            args = {};
          }
        });
      });
    },
    validarCampo: function validarCampo(callbackResultadoValidacao) {
      if (callbackResultadoValidacao == null) {
        callbackResultadoValidacao = function callbackResultadoValidacao() {};
      }

      if (!this.validarTamanhoCampo()) {
        return callbackResultadoValidacao({
          valido: false
        });
      }

      return this.realizarValidacoesAdicionais(function (params) {
        return callbackResultadoValidacao(params);
      });
    },
    realizarValidacoesAdicionais: function realizarValidacoesAdicionais(callbackOnValidacaoCompleta) {
      var finalizarValidacao, self;

      finalizarValidacao = function finalizarValidacao(context, parameters) {
        if (!context.get("validarAssincronamente")) {
          return callbackOnValidacaoCompleta(parameters);
        }

        context.sendAction("actionOnIniciarValidacaoAssincrona");
        return context.sendAction("actionOnRealizarValidacaoAssincrona", context.paramsParaValidacaoAssincrona(), function (params) {
          if (params == null) {
            params = parameters;
          }

          context.sendAction("actionOnFinalizarValidacaoAssincrona");
          params = context.paramsRetornoFimValidacao(params);
          return callbackOnValidacaoCompleta(params);
        });
      };

      self = this;

      if (this.get("possuiMetodoAssincronoValidacaoInterna")) {
        this.sendAction("actionOnIniciarValidacaoAssincrona");
        return this.get("metodoAssincronoValidacaoInterna")(this, {}, function (params, callbackOnComplete) {
          self.sendAction("actionOnFinalizarValidacaoAssincrona");
          callbackOnComplete(params);

          if (params["valido"]) {
            return finalizarValidacao(self, params);
          }

          return callbackOnValidacaoCompleta(params);
        });
      } else {
        return finalizarValidacao(self);
      }
    },
    paramsParaValidacaoAssincrona: function paramsParaValidacaoAssincrona() {
      return {
        value: this.obterValorInput()
      };
    },
    paramsRetornoFimValidacao: function paramsRetornoFimValidacao(params) {
      params["value"] = this.obterValorInput();
      return params;
    },
    validarTamanhoCampo: function validarTamanhoCampo() {
      var allowEmpty, campoString, length, maxLength, minLength, validarLengthMaximo, validarLengthMinimo, value;
      value = this.obterValorInput();
      allowEmpty = this.get("allowEmpty");
      validarLengthMinimo = this.get("validarLengthMinimo");
      validarLengthMaximo = this.get("validarLengthMaximo");
      minLength = this.get("minLength");
      maxLength = this.get('maxLength');

      if (!value && !allowEmpty) {
        return false;
      }

      campoString = new String(value);

      if (this.get("type") === "password") {
        length = campoString.length;
      } else {
        length = campoString.trim().length;
      }

      if (!allowEmpty && length === 0) {
        return false;
      }

      if (validarLengthMinimo && length < parseInt(minLength)) {
        return false;
      }

      if (validarLengthMaximo && length > parseInt(maxLength)) {
        return false;
      }

      return true;
    },
    obterValorInput: function obterValorInput(component) {
      var value;
      value = component ? this.$(component).val() : this.$('input').val();
      this.set("val", value);
      return value;
    },
    keyDown: function keyDown(evt) {
      var fieldLength;

      if (this.get('maxLength')) {
        fieldLength = parseInt(this.$('input').val().length);

        if (fieldLength <= parseInt(this.get('maxLength'))) {
          return true;
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});