define("api-dashboard/helpers/format-date-only", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.formatDateOnly = void 0;

  var formatDateOnly = function formatDateOnly(params) {
    return (0, _moment.default)(new Date(params)).format('DD/MM/YYYY');
  };

  _exports.formatDateOnly = formatDateOnly;

  var _default = Ember.Helper.helper(formatDateOnly);

  _exports.default = _default;
});