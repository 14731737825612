define("api-dashboard/components/inputs/input-id", ["exports", "api-dashboard/components/inputs/base-masked-field"], function (_exports, _baseMaskedField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend({
    label: 'RG',
    pattern: '#0.000.000-0',
    placeholder: 'Registro Geral',
    aplicarMascara: function aplicarMascara() {
      return this.$('input').mask(this.get("pattern"), {
        reverse: true
      });
    }
  });

  _exports.default = _default;
});