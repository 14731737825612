define("api-dashboard/models/integration-mapping", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    integratedId: _emberData.default.attr('string'),
    integratedType: _emberData.default.attr('string'),
    remoteId: _emberData.default.attr('string'),
    domain: _emberData.default.belongsTo('domain'),
    integratedCompany: _emberData.default.belongsTo('integrated-company')
  });

  _exports.default = _default;
});