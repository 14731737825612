define("api-dashboard/components/base/omnisearch-index", ["exports", "api-dashboard/components/base/base-index", "api-dashboard/mixins/formatters", "ember-inflector", "api-dashboard/mixins/utils"], function (_exports, _baseIndex, _formatters, _emberInflector, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseIndex.default.extend(_formatters.default, _utils.default, {
    searchFieldsComponent: 'base/omnisearch-box',
    showRefinedSearch: true,
    showDownloadCsv: false,
    getSearchText: function getSearchText() {
      if (this.$('input[name=searchText]')) {
        return this.$('input[name=searchText]').val();
      }

      return '';
    },
    formatSearchParam: function formatSearchParam(param) {
      var formattedValue, originalValue;
      originalValue = param['val'] || param['value'] || '';
      formattedValue = Object.assign(originalValue);

      if (param['format']) {
        formattedValue = this.format([formattedValue, param['format'], param['options']]);
        formattedValue = param['hours'] ? formattedValue + " " + param['hours'] : formattedValue;
      } else {
        if (param['isAutocomplete']) {
          if (!!param['selectedRecord']) {
            formattedValue = Object.assign(param['selectedRecord'].get('id'));
          } else {
            formattedValue = null;
          }
        }
      }

      return Ember.set(param, 'formattedValue', String(formattedValue));
    },
    buildParams: function buildParams(useRefinedSearch, params) {
      var builtParams, self;
      self = this;
      builtParams = {};

      if (useRefinedSearch) {
        params.forEach(function (sp) {
          if (Ember.isEmpty(sp['value']) && Ember.isEmpty(sp['val']) && Ember.isEmpty(sp['selectedRecord'])) {
            return;
          }

          if (sp['value'] === false) {
            return;
          }

          if (self.get('searchParamBindMode') === 'property') {
            return builtParams[sp['param']] = self.get('searchParamValues.' + sp['field']);
          } else {
            self.formatSearchParam(sp);
            return builtParams[sp['param']] = sp['formattedValue'];
          }
        });
      }

      return builtParams;
    },
    pathForType: function pathForType(type) {
      return "/" + Ember.String.underscore((0, _emberInflector.pluralize)(type.replace(/-/g, ' ')));
    },
    actions: {
      search: function search(append, useRefinedSearch, customParams) {
        var k, params, params2, self, v;
        self = this;
        append = arguments[0];
        useRefinedSearch = arguments[1];
        params = {
          offset: this.get('offset'),
          limit: this.get('limit'),
          order_column: this.get('orderColumn'),
          order_ascending: this.get('orderAscending'),
          searchText: this.getSearchText()
        };

        if (params.searchText === '') {
          delete params.searchText;
        }

        params = Object.assign(params, self.buildParams(true, self.get('searchParams')));
        params2 = {};

        if (customParams && useRefinedSearch) {
          params2 = self.buildParams(true, customParams);
        }

        for (k in params2) {
          v = params2[k];
          params[k] = v;
        }

        if (!append) {
          this.set('offset', 0);
          this.set('resultsAvailable', false);
        }

        self.set('searching', true);
        return this.get('store').query(this.get('searchModelName'), params).then(function (models) {
          var meta, objects;
          self.set('searching', false);
          self.set('resultsAvailable', true);
          self.set('nextRecordsVisible', models.get('length') === self.get('limit'));
          objects = self.get('model');

          if (!append) {
            self.set('model', models.toArray());
          } else {
            models.forEach(function (model) {
              return objects.pushObject(model);
            });
          }

          meta = models.get('meta');
          self.set('meta', meta);
          return self.set('totalRecords', meta.total);
        }, function (reason) {
          self.set('searching', false);
          return alert('Ocorreu um erro na busca');
        });
      },
      downloadCsv: function downloadCsv(append, useRefinedSearch, customParams) {
        var k, params, params2, self, v;
        self = this;
        append = arguments[0];
        useRefinedSearch = arguments[1];
        params = {
          searchText: this.getSearchText()
        };
        params = Object.assign(params, self.buildParams(useRefinedSearch, self.get('searchParams')));
        params2 = {};

        if (customParams && useRefinedSearch) {
          params2 = self.buildParams(true, customParams);
        }

        for (k in params2) {
          v = params2[k];
          params[k] = v;
        }

        self.set('searching', true);
        return this.downloadURLWithParams(this.pathForType(this.get('searchModelName')) + ".csv", params, "alterar.csv", function () {
          return self.set('searching', false);
        });
      }
    }
  });

  _exports.default = _default;
});