define("api-dashboard/models/carta-correcao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    correcao: _emberData.default.attr('string'),
    dataCorrecao: _emberData.default.attr('date'),
    mensagemSefaz: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    statusSefaz: _emberData.default.attr('string'),
    versao: _emberData.default.attr('number'),
    domain: _emberData.default.belongsTo('domain'),
    notaFiscal: _emberData.default.belongsTo('nota-fiscal'),
    correcaoTruncated: Ember.computed('correcao', function () {
      var text;
      text = this.get('correcao').substring(0, 105);

      if (this.get('correcao').length > 105) {
        text += '...';
      }

      return text;
    }),
    isAutorizada: Ember.computed('status', function () {
      return ['autorizada'].includes(this.get('status'));
    }),
    cartaCorrecaoUrl: Ember.computed('id', function () {
      return '/cartas_correcao/' + this.get("id");
    }),
    xmlUrl: Ember.computed(function () {
      return this.get('cartaCorrecaoUrl') + ".xml";
    }),
    pdfUrl: Ember.computed(function () {
      return this.get('cartaCorrecaoUrl') + ".pdf";
    })
  });

  _exports.default = _default;
});