define("api-dashboard/services/user-session", ["exports", "api-dashboard/mixins/requests/autenticacao"], function (_exports, _autenticacao) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_autenticacao.default, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    authenticate: function authenticate(options, callback) {
      var r, self;
      self = this;
      r = this.get('session').authenticate('authenticator:application', options);
      return r.then(function () {
        var domainId;

        if (self.get('session.isAuthenticated')) {
          self.get('application').setCurrentUserId(self.get('session.data.authenticated.userId'));

          if (self.get('session.data.authenticated.domains.length') === 1) {
            domainId = self.get('session.data.authenticated.domains')[0]['id'];
            return self.get('application').setCurrentDomainId(domainId);
          }
        }
      });
    },
    recoverPassword: function recoverPassword(options, callback) {
      return this.enviarEmailRecuperacaoSenha(this, options, callback);
    },
    updateAdapterToken: function updateAdapterToken() {
      return this.get("store").adapterFor("application").set("token", this.get('token'));
    }
  });

  _exports.default = _default;
});