define("api-dashboard/models/card-company", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    bairro: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    codMun: _emberData.default.attr('number'),
    codPais: _emberData.default.attr('number'),
    compl: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    end: _emberData.default.attr('string'),
    inscricaoEstadual: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    num: _emberData.default.attr('string'),
    suframa: _emberData.default.attr('string'),
    tefParameter: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date'),
    cardCompanyBrands: _emberData.default.hasMany('card-company-brand', {
      inverse: 'cardCompany'
    }),
    cardCompanyConfigs: _emberData.default.hasMany('card-company-config', {
      inverse: 'cardCompany'
    }),
    cardInfos: _emberData.default.hasMany('card-info'),
    cardBrands: Ember.computed('cardCompanyBrands.@each.relationshipsLoaded', function () {
      return this.get('cardCompanyBrands').getEach('cardBrand').sortBy('name');
    })
  });

  _exports.default = _default;
});