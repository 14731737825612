define("api-dashboard/components/base/modals/modal-generico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalId: 'modal-generico',
    title: 'Modal',
    buttonCaption: null,
    saveCaption: "Salvar",
    cancelCaption: "Cancelar",
    modalSeletorJquery: Ember.computed('modalId', function () {
      return this.$('#' + this.get('modalId'));
    }),
    didInsertElement: function didInsertElement() {
      return this.set('componentReference', this);
    },
    show: function show(options) {
      if (options == null) {
        options = null;
      }

      if (options === null) {
        options = {
          backdrop: "static",
          keyboard: false
        };
      }

      if (this.get('beforeShow')) {
        this.get('beforeShow')();
      }

      return this.get('modalSeletorJquery').modal(options);
    },
    hide: function hide() {
      return this.get('modalSeletorJquery').modal('hide');
    },
    cancel: function cancel() {
      if (this.get('onCancel')) {
        return this.get('onCancel')();
      }
    },
    save: function save() {
      if (this.get('onSave')) {
        return this.get('onSave')();
      }
    },
    actions: {
      show: function show() {
        return this.show();
      },
      close: function close() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }

        return this.hide();
      },
      cancel: function cancel() {
        return this.cancel();
      },
      save: function save() {
        return this.save();
      }
    }
  });

  _exports.default = _default;
});