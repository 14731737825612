define("api-dashboard/models/adicao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    numero: _emberData.default.attr('string'),
    numeroSequencial: _emberData.default.attr('string'),
    codigoFabricanteEstrangeiro: _emberData.default.attr('string'),
    valorDesconto: _emberData.default.attr('big'),
    domainId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    documentoImportacao: _emberData.default.belongsTo('documento-importacao', {
      inverse: 'adicoes'
    }),
    editing: Ember.computed('isNew', function () {
      return this.get('isNew');
    }),
    requiredFieldsFilled: Ember.computed('numero', 'numeroSequencial', 'codigoFabricanteEstrangeiro', function () {
      return !Ember.isEmpty(this.get('numero')) && !Ember.isEmpty(this.get('numeroSequencial')) && !Ember.isEmpty(this.get('codigoFabricanteEstrangeiro'));
    })
  });

  _exports.default = _default;
});