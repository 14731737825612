define("api-dashboard/models/card-company-brand", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    _checked: false,
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    cardCompany: _emberData.default.belongsTo('card-company'),
    cardBrand: _emberData.default.belongsTo('card-brand'),
    relationshipsLoaded: Ember.computed('cardCompany.isLoaded', 'cardBrand.isLoaded', function () {
      return this.get('cardCompany.isLoaded') && this.get('cardBrand.isLoaded');
    }),
    checked: Ember.computed('', {
      get: function get(key) {
        return this.get('_checked');
      },
      set: function set(key, value) {
        return this.set('_checked', value);
      }
    })
  });

  _exports.default = _default;
});