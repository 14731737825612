define("api-dashboard/components/base/btn-attach-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    extensoesPermitidas: [],
    actionOnAnexadoSucesso: null,
    actionOnTipoArquivoNaoPermitido: null,
    nomeComponenteInputFile: "inputs/input-file",
    actions: {
      actClicarInputFile: function actClicarInputFile() {
        return this.$("input").trigger("click");
      },
      actOnAnexadoSucesso: function actOnAnexadoSucesso(arquivo, callback) {
        return this.sendAction("actionOnAnexadoSucesso", arquivo, callback);
      },
      actOnTipoArquivoNaoPermitido: function actOnTipoArquivoNaoPermitido(callback) {
        return this.sendAction("actionOnTipoArquivoNaoPermitido", callback);
      }
    }
  });

  _exports.default = _default;
});