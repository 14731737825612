define("api-dashboard/serializers/pos-user", ["exports", "api-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      posUserStores: {
        embedded: 'always'
      }
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var payloadKey, transform, type, value;

      if (this._canSerialize(key)) {
        type = attribute.type;
        value = snapshot.attr(key);

        if (type) {
          transform = this.transformFor(type);
          value = transform.serialize(value, attribute.options);
        }

        payloadKey = this._getMappedKey(key, snapshot.type);

        if (payloadKey === key && this.keyForAttribute) {
          payloadKey = this.keyForAttribute(key, 'serialize');
        }

        return json[payloadKey] = value;
      }
    }
  });

  _exports.default = _default;
});