define("api-dashboard/components/modelos-notas-fiscais/modelo-nota-fiscal-index", ["exports", "api-dashboard/components/base/omnisearch-index", "api-dashboard/mixins/utils"], function (_exports, _omnisearchIndex, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend(_utils.default, {
    router: Ember.inject.service(),
    application: Ember.inject.service(),
    constants: Ember.inject.service(),
    title: 'Modelo de Nota Fiscal Eletrônica (NFe)',
    titlePlural: 'Modelos de Notas Fiscais Eletrônicas (NFe)',
    newRoute: 'modelos-notas-fiscais/new',
    editRoute: 'modelos-notas-fiscais/edit',
    layoutColumns: 12,
    searchModelName: 'modelo-nota-fiscal',
    columns: Ember.computed(function () {
      return [{
        field: 'nome',
        label: 'Nome'
      }, {
        field: 'typeDescription',
        label: 'Tipo'
      }, {
        field: 'naturezaOperacao',
        label: 'Natureza Operação'
      }];
    }),
    searchParams: Ember.computed(function () {
      var self;
      self = this;
      return [{
        label: 'Tipo',
        field: 'tipo',
        param: 'tipo',
        displayField: 'description',
        valueField: 'value',
        component: 'inputs/input-select',
        options: self.get('constants.tipoDocumentoValues'),
        value: null,
        cols: 6
      }, {
        label: 'Natureza Operação',
        field: 'naturezaOperacao',
        param: 'naturezaOperacao',
        cols: 6
      }];
    })
  });

  _exports.default = _default;
});