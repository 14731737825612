define("api-dashboard/components/inputs/input-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.TextSupport, {
    store: Ember.inject.service(),
    classNames: ['ember-text-field', 'text-right', 'form-control'],
    tagName: "input",
    attributeBindings: ['type', 'value', 'size', 'pattern', 'name', 'min'],
    type: "text",
    size: null,
    pattern: null,
    min: null,
    promises: [],
    onProductFound: null,
    formQuantity: 1,
    value: '',
    keyDown: function keyDown(event, view) {
      if (event.keyCode === 13) {
        return this.searchProduct(this.get('value'));
      }
    },
    removeSearchedFromPromises: function removeSearchedFromPromises(searched) {
      return this.get('promises').removeObject(searched);
    },
    searchProduct: function searchProduct(value) {
      var formQuantity, prom, searchedObject, self;
      self = this;
      searchedObject = Ember.Object.create();
      searchedObject.set('barcode', value);
      searchedObject.set('status', 'Buscando...');
      self.get('promises').pushObject(searchedObject);
      formQuantity = parseInt(this.get('formQuantity'));
      self.set('formQuantity', 1);
      prom = self.get('store').query('product', {
        barcode: value
      }).then(function (result) {
        if (result.get('length') > 0) {
          self.removeSearchedFromPromises(searchedObject);
          return self.sendAction('onProductFound', result.objectAt(0), formQuantity);
        } else {
          return searchedObject.set('status', 'Não encontrado');
        }
      }, function (value) {
        return searchedObject.set('status', 'Erro ao adicionar produto');
      });
      searchedObject.set('promise', prom);
      return self.set('value', '');
    }
  });

  _exports.default = _default;
});