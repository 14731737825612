define("api-dashboard/initializers/libs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.initialize = void 0;
  var LibsInitializer, initialize;
  _exports.initialize = initialize;

  _exports.initialize = initialize = function initialize() {};

  LibsInitializer = {
    name: 'libs',
    initialize: function initialize() {
      return new WOW().init();
    }
  };
  var _default = LibsInitializer;
  _exports.default = _default;
});