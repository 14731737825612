define("api-dashboard/templates/components/base/btn-attach-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MCASZp7B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"action\",[[23,0,[]],\"actClicarInputFile\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"actClicarInputFile\"]],[8],[0,\"Upload\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"style\",\"display:none;\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"extensoesPermitidas\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[24,[\"nomeComponenteInputFile\"]]],[[\"extensoesPermitidas\",\"actionOnAnexadoSucesso\",\"actionOnTipoArquivoNaoPermitido\"],[[24,[\"extensoesPermitidas\"]],\"actOnAnexadoSucesso\",\"actOnTipoArquivoNaoPermitido\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[24,[\"nomeComponenteInputFile\"]]],[[\"actionOnAnexadoSucesso\",\"actionOnTipoArquivoNaoPermitido\"],[\"actOnAnexadoSucesso\",\"actOnTipoArquivoNaoPermitido\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/btn-attach-file.hbs"
    }
  });

  _exports.default = _default;
});