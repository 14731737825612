define("api-dashboard/components/inputs/input-cpf", ["exports", "api-dashboard/components/inputs/base-masked-field", "api-dashboard/mixins/utils"], function (_exports, _baseMaskedField, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseMaskedField.default.extend(_utils.default, {
    label: 'CPF',
    pattern: '000.000.000-00',
    placeholder: 'Cadastro Nacional de Pessoa Física',
    validarValorMascara: function validarValorMascara() {
      return this.validateCPFInternal();
    },
    validateCPFInternal: function validateCPFInternal() {
      return this.validarCPF(this.obterValorSemMascara());
    }
  });

  _exports.default = _default;
});