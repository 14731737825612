define("api-dashboard/components/invoices/config/company/company-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    constants: Ember.inject.service(),
    application: Ember.inject.service(),
    address: null,
    init: function init(args) {
      return this._super(args);
    },
    didRender: function didRender(args) {
      return this.$("#company-form-modal").modal('show');
    },
    willDestroyElement: function willDestroyElement(args) {
      return this.$("#company-form-modal").modal('hide');
    },
    didInsertElement: function didInsertElement(args) {
      var clickHandler, self;
      self = this;
      clickHandler = self.get('handleOutsideClick').bind(self);
      return self.$("#company-form-modal").on('click', clickHandler);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var self, target;
      self = this;
      target = self.$(event.target);

      if (target.get(0).id === 'company-form-modal') {
        return this.get('router').transitionTo('invoices/index');
      }
    },
    updateCompany: function updateCompany() {
      var ajaxParams, contactInfos, emptyValue, message, qtdEmailType, self;
      self = this;
      qtdEmailType = self.get('model.contactInfos').filter(function (c) {
        return c.get('contactType') === 'email';
      });
      emptyValue = self.get('model.contactInfos').filter(function (c) {
        return c.get('contactType') === 'email' && c.get('value') === '';
      });

      if (qtdEmailType.length === emptyValue.length) {
        message = 'É necessário ter ao menos um e-mail cadastrado.';
        self.get('application').showAlert(message, 'error', 30000, false);
        return;
      }

      contactInfos = self.get('model.contactInfos').map(function (c) {
        return {
          id: c.get('id'),
          value: c.get('value'),
          comment: c.get('comment'),
          contact_type: c.get('contactType')
        };
      });
      message = '';

      if (self.get('model.razaoSocial') === '') {
        message += 'Razão sozial não pode ser vazio\n';
      }

      if (self.get('model.cnpj') === '' && self.get('model.cpf') === '') {
        message += 'CPF/CNPJ não pode ser vazio\n';
      }

      if (self.get('model.defaultAddress.logradouro') === '' || self.get('model.defaultAddress.numero') === '' || self.get('model.defaultAddress.bairro') === '' || self.get('model.defaultAddress.cep') === '' || self.get('model.defaultAddress.municipio') === '' || self.get('model.defaultAddress.pais') === '') {
        message += 'Endereço completo não pode ser vazio';
      }

      if (message !== '') {
        self.get('application').showAlert(message, 'error', 30000, false);
        return;
      }

      ajaxParams = {
        method: 'PUT',
        data: {
          name: self.get('model.name'),
          cnpj: self.get('model.cnpj'),
          cpf: self.get('model.cpf'),
          razao_social: self.get('model.razaoSocial'),
          endereco: {
            logradouro: self.get('model.defaultAddress.logradouro'),
            numero: self.get('model.defaultAddress.numero'),
            complemento: self.get('model.defaultAddress.complemento'),
            bairro: self.get('model.defaultAddress.bairro'),
            cep: self.get('model.defaultAddress.cep'),
            municipio_id: self.get('model.defaultAddress.municipio.id'),
            pais_id: self.get('model.defaultAddress.pais.id')
          },
          contact_infos: contactInfos
        },
        global: false
      };
      return this.get('store').externalAjax(ajaxParams, {
        apiURL: "/web_api/customers/" + self.get('model.id')
      }, function (success, textStatus, data) {
        var toastType;

        if (success) {
          toastType = 'success';
          message = "Dados de empresa atualizados com sucesso";
          self.get('router').transitionTo('invoices/index');
        } else {
          toastType = 'error';
          message = data.jqXHR.responseJSON.msg;
        }

        return self.get('application').showAlert(message, toastType, 30000, false);
      });
    },
    actions: {
      submit: function submit() {
        return this.updateCompany();
      },
      onCancel: function onCancel() {
        return this.get('router').transitionTo('invoices/index');
      },
      addContact: function addContact() {
        return this.get('model.addNewContactInfo').call(this.get('model'));
      },
      fillAddressFields: function fillAddressFields(params) {
        var codigoMunicipio, self;

        if (params['valido']) {
          self = this;
          self.set('fetchingCep', true);
          codigoMunicipio = params['endereco']['ibge'];
          return this.get('store').queryRecord('municipio', {
            codigo_municipio: codigoMunicipio
          }).then(function (obj) {
            var bairro, cidade, logradouro, pais;
            cidade = obj;
            logradouro = params['endereco']['logradouro'];
            bairro = params['endereco']['bairro'];
            pais = params['endereco']['país'];

            if (params['addressType'] === 'default') {
              self.set('model.defaultAddress.municipio', cidade);
              self.set('model.defaultAddress.logradouro', logradouro);
              self.set('model.defaultAddress.bairro', bairro);

              if (!!pais) {
                self.set('model.defaultAddress.pais', pais);
              }

              self.set('model.defaultAddress.numero', '');
              self.set('model.defaultAddress.complemento', '');
              self.set('cidadeDefaultDisabled', !!cidade);
              self.set('logradouroDefaultDisabled', !!logradouro);
              self.set('bairroDefaultDisabled', !!bairro);
              return self.set('fetchingCep', false);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});