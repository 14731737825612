define("api-dashboard/components/tag-toggle-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TagToggleClassComponent;
  TagToggleClassComponent = Ember.Component.extend({
    classToToggle: "active",
    target: null,
    toggleClass: function toggleClass() {
      var component, target;
      target = this.get("target");

      if (!target) {
        component = this.$();
      } else {
        component = this.$(target);
      }

      return component.toggleClass(this.get("classToToggle"));
    },
    actions: {
      actToggleClass: function actToggleClass() {
        return this.toggleClass();
      }
    }
  });
  var _default = TagToggleClassComponent;
  _exports.default = _default;
});