define("api-dashboard/models/sale-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    priceType: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    priceTypeDescription: Ember.computed('priceType', function () {
      return this.get('constants').getPriceTypeLabel(this.get('priceType'));
    })
  });

  _exports.default = _default;
});