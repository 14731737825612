define("api-dashboard/templates/components/lancamentos/lancamentos-index-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "famHOIqw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"meta\",\"total_recebimento\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"box__background__border\"],[10,\"style\",\"padding-top: 6px;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"meta\",\"total_recebimento\"]]],null,{\"statements\":[[0,\"          \"],[7,\"label\",true],[8],[0,\"Total Recebimentos:\"],[9],[0,\"\\n          R$ \"],[1,[28,\"format/big\",[[24,[\"meta\",\"total_recebimento\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"meta\",\"total_pagamento\"]]],null,{\"statements\":[[0,\"          \"],[7,\"label\",true],[8],[0,\"Total Pagamentos:\"],[9],[0,\"\\n          R$ -\"],[1,[28,\"format/big\",[[24,[\"meta\",\"total_pagamento\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-xs-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"meta\",\"total_liquido\"]]],null,{\"statements\":[[0,\"          \"],[7,\"label\",true],[8],[0,\"Total Líquido:\"],[9],[0,\"\\n          R$ \"],[1,[28,\"format/big\",[[24,[\"meta\",\"total_liquido\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/lancamentos/lancamentos-index-stats.hbs"
    }
  });

  _exports.default = _default;
});