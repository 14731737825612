define("api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/cancelada/ativar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dStf1uVm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\n    Esta div é opcional. Será exibida se o usuário optar em mostrar uma div\\n    informativa após o usuário cancelar a confirmação.\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/partials/components/bs-modal-confirmacao-generica/cancelada/ativar-empresa.hbs"
    }
  });

  _exports.default = _default;
});