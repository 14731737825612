define("api-dashboard/templates/components/suppliers/contact-info-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CHgXhIAi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"inputs/input-select\",null,[[\"value\",\"allowEmpty\",\"options\",\"displayField\",\"cols\"],[[24,[\"model\",\"contactType\"]],false,[24,[\"constants\",\"contactInfo\",\"typesWithLabel\"]],\"description\",\"3\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/base-text-field\",null,[[\"placeholder\",\"val\",\"cols\"],[[24,[\"label\"]],[24,[\"model\",\"value\"]],\"4\"]]],false],[0,\"\\n    \"],[1,[28,\"inputs/base-text-field\",null,[[\"placeholder\",\"val\",\"cols\"],[\"Observação\",[24,[\"model\",\"comment\"]],\"4\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/suppliers/contact-info-form.hbs"
    }
  });

  _exports.default = _default;
});