define("api-dashboard/components/dashboards/dashboard-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutName: 'components/dashboards/dashboard-card',
    classNames: ['col-xs-12', 'col-sm-6', 'col-md-4', 'col-lg-3'],
    dataSource: null,
    title: null,
    multiStore: Ember.computed(function () {
      return this.get('application.currentDomain.multipleStores');
    }),
    init: function init() {
      return this._super.apply(this, arguments);
    },
    internal_reload: function internal_reload(params) {},
    internal_zoom: function internal_zoom(params) {},
    actions: {
      reload: function reload() {
        return this.internal_reload();
      },
      zoom: function zoom() {
        return this.internal_zoom();
      }
    }
  });

  _exports.default = _default;
});