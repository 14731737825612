define("api-dashboard/templates/components/products/configuration-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c3dVsw1t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[1,[28,\"inputs/base-text-field\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"measurementUnit\"]],\"Unidade de Medida\",\"6\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"application\",\"currentDomain\",\"isNfeOrNfce\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inputs/base-text-field\",null,[[\"val\",\"label\",\"cols\"],[[24,[\"model\",\"taxableMeasurementUnit\"]],\"Unidade de Medida Tributável\",\"6\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12\"],[8],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"active\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--danger\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"deactivate\"]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye-slash\"],[8],[9],[0,\"\\n         Desativar este produto\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--success\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"activate\"]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-eye\"],[8],[9],[0,\"\\n         Ativar este produto\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/products/configuration-tab.hbs"
    }
  });

  _exports.default = _default;
});