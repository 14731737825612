define("api-dashboard/models/pos-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    code: _emberData.default.attr('number'),
    cpf: _emberData.default.attr('string'),
    isAdmin: _emberData.default.attr('boolean'),
    login: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    originalId: _emberData.default.attr('number'),
    domain: _emberData.default.belongsTo('domain'),
    posUserStores: _emberData.default.hasMany('pos-user-store', {
      async: false
    }),
    statusLocalized: Ember.computed('active', function () {
      if (this.get('active')) {
        return 'Ativo';
      } else {
        return 'Inativo';
      }
    }),
    label: Ember.computed('code', 'name', function () {
      return this.get('code') + "  - " + this.get('name');
    })
  });

  _exports.default = _default;
});