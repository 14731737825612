define("api-dashboard/models/empresa", ["exports", "ember-data", "api-dashboard/mixins/formatters"], function (_exports, _emberData, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend(_formatters.default, (_DS$Model$extend = {
    nome: _emberData.default.attr(""),
    nomeFantasia: _emberData.default.attr(""),
    inscricaoEstadual: _emberData.default.attr(""),
    inscricaoMunicipal: _emberData.default.attr(""),
    bairro: _emberData.default.attr(""),
    cargoResponsavel: _emberData.default.attr(""),
    cep: _emberData.default.attr(""),
    cnpj: _emberData.default.attr(""),
    codigoMunicipio: _emberData.default.attr(""),
    codigoPais: _emberData.default.attr(""),
    codigoUf: _emberData.default.attr(""),
    complemento: _emberData.default.attr(""),
    cpfCnpjContabilidade: _emberData.default.attr(""),
    cpfResponsavel: _emberData.default.attr(""),
    discriminaImpostos: _emberData.default.attr(""),
    email: _emberData.default.attr(""),
    enviarEmailDestinatario: _emberData.default.attr(""),
    habilitaNfce: _emberData.default.attr(""),
    habilitaNfe: _emberData.default.attr(""),
    habilitaNfse: _emberData.default.attr(""),
    logradouro: _emberData.default.attr(""),
    municipio: _emberData.default.attr(""),
    nomeResponsavel: _emberData.default.attr(""),
    numero: _emberData.default.attr(""),
    pais: _emberData.default.attr(""),
    regimeTributario: _emberData.default.attr(""),
    telefone: _emberData.default.attr(""),
    uf: _emberData.default.attr(""),
    cscNfceProducao: _emberData.default.attr(""),
    idTokenNfceProducao: _emberData.default.attr(""),
    cscNfceHomologacao: _emberData.default.attr(""),
    idTokenNfceHomologacao: _emberData.default.attr(""),
    proximoNumeroNfeProducao: _emberData.default.attr(""),
    proximoNumeroNfeHomologacao: _emberData.default.attr(""),
    serieNfeProducao: _emberData.default.attr(""),
    serieNfeHomologacao: _emberData.default.attr(""),
    proximoNumeroNfseProducao: _emberData.default.attr(""),
    proximoNumeroNfseHomologacao: _emberData.default.attr(""),
    serieNfseProducao: _emberData.default.attr(""),
    serieNfseHomologacao: _emberData.default.attr(""),
    tokenProducao: _emberData.default.attr(""),
    tokenHomologacao: _emberData.default.attr(""),
    certificadoValidoAte: _emberData.default.attr(""),
    certificadoValidoDe: _emberData.default.attr(""),
    certificadoCnpj: _emberData.default.attr("")
  }, _defineProperty(_DS$Model$extend, "habilitaNfe", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "habilitaNfce", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "habilitaNfse", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfseHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfseProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "serieNfeHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "serieNfeProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfeProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfeHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfseHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfseProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "cscNfceProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "cscNfceHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfceProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "proximoNumeroNfceHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "serieNfceProducao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "serieNfceHomologacao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "dataUltimaEmissao", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "caminhoLogo", _emberData.default.attr("")), _defineProperty(_DS$Model$extend, "cpf", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "isNatural", _emberData.default.attr('boolean', {
    defaultValue: false
  })), _defineProperty(_DS$Model$extend, "ready", function ready() {
    if (this.get('cpf')) {
      return this.set('isNatural', true);
    }
  }), _defineProperty(_DS$Model$extend, "documento", Ember.computed('isNatural', 'cpf', 'cnpj', function () {
    if (this.get('isNatural')) {
      return this.formatCpf(this.get('cpf'));
    } else {
      return this.formatCnpj(this.get('cnpj'));
    }
  })), _defineProperty(_DS$Model$extend, "caminhoCompletoLogo", Ember.computed("caminhoLogo", function () {
    var caminhoLogo, host;
    caminhoLogo = this.get("caminhoLogo");

    if (!caminhoLogo) {
      return null;
    }

    host = this.get("store").adapterFor("application").get("hostNfe");
    return (host + caminhoLogo).replace("/panel_api", "");
  })), _defineProperty(_DS$Model$extend, "possuiLogo", Ember.computed("caminhoLogo", function () {
    if (!this.get("caminhoLogo")) {
      return false;
    } else {
      return true;
    }
  })), _DS$Model$extend));

  _exports.default = _default;
});