define("api-dashboard/components/conta-bancaria/conta-bancaria-index", ["exports", "api-dashboard/components/base/omnisearch-index"], function (_exports, _omnisearchIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _omnisearchIndex.default.extend({
    model: null,
    searchModelName: 'conta-bancaria',
    title: 'Conta Bancária',
    titlePlural: 'Contas Bancárias',
    newRoute: 'conta-bancaria/new',
    editRoute: 'conta-bancaria/edit',
    partialStats: null,
    customSearchButton: false,
    columns: [{
      field: 'agencia',
      label: 'Agência'
    }, {
      field: 'conta',
      label: 'Conta'
    }, {
      field: 'nomeCedente',
      label: 'Nome Cedente'
    }],
    searchParams: [],
    showNoRecordsLayout: false,
    init: function init(args) {
      return this._super(args);
    }
  });

  _exports.default = _default;
});