define("api-dashboard/mixins/requests/referencia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    obterUltimasEmissoes: function obterUltimasEmissoes(context, arg, callback) {
      arg;
      return this.get("store").query("referencia", {}).then(function (emissoes) {
        return callback(true, emissoes);
      }, function (errors) {
        return callback(false, errors);
      });
    },
    carregarMaisEmissoesOffset: function carregarMaisEmissoesOffset(context, lastParamsWithOffset, callback) {
      return this.get("store").query("referencia", lastParamsWithOffset).then(function (emissoes) {
        return callback(true, emissoes);
      }, function (errors) {
        return callback(false, errors);
      });
    },
    buscarEmissoes: function buscarEmissoes(context, queryParams, callback) {
      return this.get("store").query("referencia", queryParams).then(function (referencias) {
        return callback(true, referencias);
      }, function (errors) {
        return callback(false, errors);
      });
    }
  });

  _exports.default = _default;
});