define("api-dashboard/routes/notas-fiscais/new", ["exports", "api-dashboard/routes/rotas-abstratas/rota-autenticavel-token"], function (_exports, _rotaAutenticavelToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rotaAutenticavelToken.default.extend({
    queryParams: {
      template: {
        refreshModel: true
      }
    },
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model(params) {
      var m, promises, self;
      m = this.get('store').createRecord('nota-fiscal');
      self = this;
      promises = [];
      promises.addObject(this.get('globalLookups.emitentes').then(function (emitentes) {
        if (emitentes.get('length') > 0) {
          m.set('emitente', emitentes.objectAt(0));
          return m.save().then(function (_this) {
            return function (m) {
              return self.transitionTo('notas-fiscais/edit', m);
            };
          }(this), function () {});
        }
      }));

      if (params['template']) {
        promises.addObject(this.get('store').findRecord('modelo-nota-fiscal', params['template']).then(function (obj) {
          m.set('naturezaOperacao', obj.get('naturezaOperacao'));
          m.set('tipoDocumento', obj.get('tipo'));
          return m.set('informacoesAdicionaisContribuinte', obj.get('informacoesAdicionaisContribuinte'));
        }));
      }

      return Ember.RSVP.hashSettled(promises).then(function (settledPromises) {
        return m;
      });
    }
  });

  _exports.default = _default;
});