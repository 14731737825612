define("api-dashboard/components/busca-referencias/grid-referencias", ["exports", "api-dashboard/mixins/requests/referencia"], function (_exports, _referencia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_referencia.default, {
    store: Ember.inject.service(),
    carregarUltimasEmissoes: false,
    parametrosBusca: null,
    referencias: [],
    totalRegistrosPossiveis: null,
    lastQueryParams: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (!this.get("carregarUltimasEmissoes") && this.get("rendered")) {
        return this.fazerNovaBusca();
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get("carregarUltimasEmissoes")) {
        return this.buscarUltimasEmissoes();
      }
    },
    buscarUltimasEmissoes: function buscarUltimasEmissoes(arg) {
      var self;
      arg;
      self = this;
      this.mostrarEstadoCarregamento();
      return this.obterUltimasEmissoes(this, {}, function (sucesso, dados) {
        if (sucesso) {
          self.set("referencias", dados);

          if (dados.get("length") > 0) {
            self.set("totalRegistrosPossiveis", dados.meta.total);
            return self.mostrarJanelaDados();
          } else {
            return self.mostrarJanelaSemDados();
          }
        } else {
          return self.mostrarJanelaErros();
        }
      });
    },
    fazerNovaBusca: function fazerNovaBusca(params) {
      var parametrosBusca, self;

      if (params == null) {
        params = {};
      }

      self = this;
      parametrosBusca = this.get("parametrosBusca");
      this.mostrarEstadoCarregamento();
      return this.buscarEmissoes(this, parametrosBusca, function (sucesso, dados) {
        if (sucesso) {
          self.set("lastQueryParams", parametrosBusca);
          self.set("referencias", dados);

          if (dados.get("length") > 0) {
            self.set("totalRegistrosPossiveis", dados.meta.total);
            return self.mostrarJanelaDados();
          } else {
            return self.mostrarJanelaSemDados();
          }
        } else {
          return self.mostrarJanelaErros();
        }
      });
    },
    mostrarEstadoCarregamento: function mostrarEstadoCarregamento(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      this.$("#grid-busca-referencias-tabela").hide();
      this.$("#grid-busca-referencias-sem-dados").hide();
      this.$("#grid-busca-referencias-erro").hide();
      return this.$("#grid-busca-referencias-spinner").show();
    },
    mostrarJanelaDados: function mostrarJanelaDados(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      this.$("#grid-busca-referencias-tabela").fadeIn();
      this.$("#grid-busca-referencias-sem-dados").hide();
      this.$("#grid-busca-referencias-erro").hide();
      return this.$("#grid-busca-referencias-spinner").hide();
    },
    mostrarJanelaSemDados: function mostrarJanelaSemDados(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      this.$("#grid-busca-referencias-tabela").hide();
      this.$("#grid-busca-referencias-sem-dados").show();
      this.$("#grid-busca-referencias-erro").hide();
      return this.$("#grid-busca-referencias-spinner").hide();
    },
    mostrarJanelaErros: function mostrarJanelaErros(params, callback) {
      if (params == null) {
        params = {};
      }

      if (callback == null) {
        callback = function callback(a) {
          if (a == null) {
            a = {};
          }
        };
      }

      this.$("#grid-busca-referencias-tabela").hide();
      this.$("#grid-busca-referencias-sem-dados").hide();
      this.$("#grid-busca-referencias-erro").show();
      return this.$("#grid-busca-referencias-spinner").hide();
    },
    carregarMaisReferencias: function carregarMaisReferencias(callbackOnLoadComplete) {
      var parametros, self;
      self = this;

      if (this.get("carregarUltimasEmissoes")) {
        parametros = {};
        parametros["offset"] = this.get("referencias").get("length");
      } else {
        parametros = this.get("lastQueryParams");
        parametros["offset"] = this.get("referencias").get("length");
      }

      return this.carregarMaisEmissoesOffset(this, parametros, function (sucesso, dados) {
        callbackOnLoadComplete();

        if (sucesso) {
          self.set("totalRegistrosPossiveis", dados.meta.total);
          return self.set("referencias", self.get("referencias").toArray().pushObjects(dados.toArray()));
        }
      });
    },
    atualizar: function atualizar() {
      var metodo, params, referencias, self;
      self = this;
      referencias = this.get("referencias");

      if (!referencias) {
        referencias = [];
      } else {
        referencias = referencias.toArray();
      }

      this.mostrarEstadoCarregamento();

      if (this.get("carregarUltimasEmissoes")) {
        metodo = this.obterUltimasEmissoes;
        params = {};
      } else {
        metodo = this.buscarEmissoes;
        params = this.get("lastQueryParams");
      }

      return metodo.call(this, this, params, function (sucesso, dados) {
        var shouldUpdateAttr;

        if (sucesso) {
          if (dados.get("length") === 0) {
            if (referencias.get("length") === 0) {
              self.mostrarJanelaSemDados();
            } else {
              self.mostrarJanelaDados();
            }

            return;
          }

          shouldUpdateAttr = false;
          dados.forEach(function (registro, index) {
            if (!referencias.findBy("id", registro.get("id"))) {
              referencias.pushObject(registro);
              return shouldUpdateAttr = true;
            }
          });

          if (shouldUpdateAttr) {
            self.set("totalRegistrosPossiveis", dados.meta.total);
            self.set("referencias", referencias);
          }

          return self.mostrarJanelaDados();
        } else {
          return self.mostrarJanelaErros();
        }
      });
    },
    actions: {
      actFazerNovaBusca: function actFazerNovaBusca(params, callbackOnLoadComplete) {
        if (callbackOnLoadComplete == null) {
          callbackOnLoadComplete = function callbackOnLoadComplete(sucesso, params) {};
        }

        return this.fazerNovaBusca(params, callbackOnLoadComplete);
      },
      actCarregarMaisReferencias: function actCarregarMaisReferencias(callbackOnLoadComplete) {
        return this.carregarMaisReferencias(callbackOnLoadComplete);
      },
      actAtualizar: function actAtualizar() {
        return this.atualizar();
      },
      actShowContent: function actShowContent(referencia, index, campo, className) {
        $("#precode-" + index).show();
        $("#code-" + index).removeClass();
        $("#code-" + index).addClass(className);
        $("#code-" + index).text(referencia.get(campo));
        return $("#code-" + index).each(function (i, block) {
          return hljs.highlightBlock(block);
        });
      },
      actFormatRequestBody: function actFormatRequestBody(index) {
        return $("#request-" + index).each(function (i, block) {
          return hljs.highlightBlock(block);
        });
      }
    }
  });

  _exports.default = _default;
});