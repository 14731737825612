define("api-dashboard/helpers/raw-to-user/float-to-monetario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.rawToUserFloatToMonetario = void 0;
  var RawToUserFloatToMonetarioHelper, rawToUserFloatToMonetario;
  _exports.rawToUserFloatToMonetario = rawToUserFloatToMonetario;

  _exports.rawToUserFloatToMonetario = rawToUserFloatToMonetario = function rawToUserFloatToMonetario(params, namedArgs) {
    var moeda, retorno, seperadorDecimal;
    console.warn("Helper raw-to-user/float-to-monetario depreciado em favor de format/big");
    retorno = "";

    try {
      moeda = "R$";
      seperadorDecimal = ",";

      if (namedArgs["moeda"]) {
        moeda = namedArgs["moeda"];
      }

      if (namedArgs["seperadorDecimal"]) {
        moeda = namedArgs["seperadorDecimal"];
      }

      retorno = moeda + parseFloat(params[0]).toFixed(2).toString().replace(".", seperadorDecimal);
    } catch (error) {}

    return retorno;
  };

  RawToUserFloatToMonetarioHelper = Ember.Helper.helper(rawToUserFloatToMonetario);
  var _default = RawToUserFloatToMonetarioHelper;
  _exports.default = _default;
});