define("api-dashboard/components/cartas-correcao/carta-correcao-form", ["exports", "api-dashboard/components/default", "api-dashboard/mixins/utils"], function (_exports, _default2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend(_utils.default, {
    store: Ember.inject.service(),
    searching: false,
    notaFiscal: null,
    nfeCartasCorrecao: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.initializeModel(true);
      return this.getNfeCartasCorrecao();
    },
    initializeModel: function initializeModel(forceUpdate) {
      var self;

      if (forceUpdate == null) {
        forceUpdate = false;
      }

      self = this;

      if (!self.get('model.id') || forceUpdate) {
        self.set('model', self.get('store').createRecord('carta-correcao'));
        self.set('model.notaFiscal', self.get('notaFiscal'));
        self.set('model.status', 'pendente');
        return self.set('nfeCartasCorrecao', null);
      }
    },
    getNfeCartasCorrecao: function getNfeCartasCorrecao() {
      var self;
      self = this;

      if (this.get('notaFiscal.id')) {
        self.set('searchingCCe', true);
        return self.get('store').query('carta-correcao', {
          nota_fiscal_id: this.get('notaFiscal.id')
        }).then(function (objs) {
          self.set('nfeCartasCorrecao', objs);
          return self.set('searchingCCe', false);
        });
      }
    },
    actions: {
      onSubmit: function onSubmit() {
        this.initializeModel(true);
        return this.getNfeCartasCorrecao();
      },
      onCancel: function onCancel() {
        this.get('model').rollbackAttributes();
        this.get('modalComponent').onCancel();
        return this.get('modalComponent').hide();
      },
      downloadXml: function downloadXml(cce) {
        var self;
        self = this;
        this.set('downloadingXml', true);
        return this.downloadURL(cce.xmlUrl, cce.get('id') + ".xml", function (_this) {
          return function () {
            return self.set('downloadingXml', false);
          };
        }(this));
      },
      downloadPdf: function downloadPdf(cce) {
        var self;
        self = this;
        this.set('downloadingPdf', true);
        return this.downloadURL(cce.pdfUrl, cce.get('id') + ".pdf", function (_this) {
          return function () {
            return self.set('downloadingPdf', false);
          };
        }(this));
      }
    }
  });

  _exports.default = _default;
});