define("api-dashboard/models/pos-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    constants: Ember.inject.service(),
    detalhesOpen: false,
    paymentMethod: null,
    associatedPersonName: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    authNumber: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    bankSlipBarcode: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    cardType: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    changeValue: _emberData.default.attr('number', {
      alwaysDirty: true
    }),
    comments: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    createdAt: _emberData.default.attr('date', {
      alwaysDirty: true
    }),
    customerRegistration: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    date: _emberData.default.attr('date', {
      alwaysDirty: true
    }),
    description: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    entryType: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    maturityDate: _emberData.default.attr('date', {
      alwaysDirty: true
    }),
    number: _emberData.default.attr('number', {
      alwaysDirty: true
    }),
    numInstallments: _emberData.default.attr('number', {
      alwaysDirty: true
    }),
    returnedCheckCmc7: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    status: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    systemMessages: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    tefNsu: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    typeDescription: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    typeValueDescription: _emberData.default.attr('string', {
      alwaysDirty: true
    }),
    updatedAt: _emberData.default.attr('date', {
      alwaysDirty: true
    }),
    value: _emberData.default.attr('big', {
      alwaysDirty: true
    }),
    cardBrand: _emberData.default.belongsTo('card-brand', {
      alwaysDirty: true
    }),
    cardCompany: _emberData.default.belongsTo('card-company', {
      alwaysDirty: true
    }),
    cashDrawer: _emberData.default.belongsTo('cash-drawer'),
    check: _emberData.default.hasMany('check'),
    contaContabil: _emberData.default.belongsTo('conta-contabil'),
    lancamento: _emberData.default.belongsTo('lancamento'),
    pos: _emberData.default.belongsTo('pos'),
    sale: _emberData.default.belongsTo('sale'),
    notaFiscal: _emberData.default.belongsTo('nota-fiscal'),
    statusFormatted: Ember.computed('status', function () {
      switch (this.get('status')) {
        case 'realized':
          return 'Realizado';

        case 'canceled':
          return 'Cancelado';
      }
    }),
    typeDescriptionNFCe: Ember.computed('entryType', 'cardType', function () {
      switch (this.get('entryType')) {
        case 'Dinheiro':
          return 'Dinheiro';

        case 'Cartão':
          if (this.get('cardType') === 'D') {
            return 'Cartão de Débito';
          } else if (this.get('cardType') === 'C') {
            return 'Cartão de Crédito';
          } else {
            return 'Cartão';
          }

          break;

        case 'Cartão-C':
          return 'Cartão de Crédito';

        case 'Cartão-D':
          return 'Cartão de Débito';

        case 'TEF':
          return 'TEF';

        case 'Cheque':
          return 'Cheque';

        case 'Carnê':
          return 'Carnê';

        case 'Saldo':
          return 'Saldo';

        case 'Entradas':
          return 'Entradas';

        case 'Outros (imp.)':
          return 'Outros';

        case 'Fechamento':
          return 'Fechamento';

        case 'DepositoReembolso':
          return 'Depósito de Reembolso';

        case 'ValeRefeicao':
          return 'Vale Refeição';

        case 'ValeAlimentacao':
          return 'Vale Alimentação';

        case 'ValeCombustivel':
          return 'Vale Combustível';

        case 'ValePresente':
          return 'Vale Presente';
      }
    }),
    isCarnet: Ember.computed('entryType', function () {
      return ['Carnê'].includes(this.get('entryType'));
    }),
    isCard: Ember.computed('entryType', function () {
      return ['Cartão', 'TEF', 'CartãoCredito', 'CartãoDebito'].includes(this.get('entryType'));
    }),
    isCash: Ember.computed('entryType', function () {
      return ['Dinheiro'].includes(this.get('entryType'));
    }),
    isCheck: Ember.computed('entryType', function () {
      return ['Cheque'].includes(this.get('entryType'));
    }),
    formattedDescription: Ember.computed(function () {
      var desc, lastCardDigits, maturityDate;
      desc = this.get('typeDescriptionNFCe');

      if (this.get('isCarnet')) {
        maturityDate = moment(this.get('maturityDate')).format('DD/MM/YYYY');
        desc += ", " + this.get('numInstallments') + "x, " + maturityDate;
      }

      if (this.get('isCard')) {
        lastCardDigits = '****';
        desc += ", " + this.get('numInstallments') + ", final " + lastCardDigits;
      }

      return desc;
    }),
    paymentMethodObserver: Ember.observer('paymentMethod', function () {
      this.set('cardBrand', null);
      this.set('cardType', null);

      if (this.get('paymentMethod.cardInfo.id')) {
        this.set('cardBrand', this.get('paymentMethod.cardInfo.cardBrand'));
        return this.set('cardType', this.get('paymentMethod.cardType'));
      }
    }),
    validate: function validate() {
      var errors;
      errors = this.get('errors');
      errors.clear();

      if (!this.get('value') || this.get('value') <= 0) {
        errors.add('value', 'O valor recebido precisa ser maior do que 0!');
      }

      switch (this.get('entryType')) {
        case 'Dinheiro':
          this.__validateCash();

          break;

        case 'Cartão':
          this.__validateCard();

          break;

        case 'Carnê':
          this.__validateCarnet();

          break;

        case 'Cheque':
          this.__validateMoneyCheck();

      }

      return errors.length === 0;
    },
    __validateCash: function __validateCash() {
      var errors;
      errors = this.get('errors');
      return errors.length === 0;
    },
    __validateCard: function __validateCard() {
      var errors, numInstallments;

      if (this.get('isCard')) {
        this.set('entryType', 'Cartão');
      }

      errors = this.get('errors');
      numInstallments = parseInt(this.get('numInstallments'));

      if (!(numInstallments >= 1)) {
        errors.add('numInstallments', 'O pagamento com Cartão deve ter ao menos 1 parcela!');
      }

      if (!(this.get('authNumber') != null && this.get('authNumber').trim() !== '')) {
        errors.add('authNumber', 'O Número de Autorização precisa ser preenchido!');
      }

      if (this.get('cardBrand') == null) {
        errors.add('cardBrand', 'A Bandeira do Cartão precisa ser preenchida!');
      }

      return errors.length === 0;
    },
    __validateCarnet: function __validateCarnet() {
      var errors, maturityDate, numInstallments;
      errors = this.get('errors');
      numInstallments = parseInt(this.get('numInstallments'));
      maturityDate = this.get('maturityDate');

      if (!(numInstallments >= 1)) {
        errors.add('numInstallments', 'O pagamento com Carnê precisa ter ao menos 1 parcela!');
      }

      if (!(numInstallments <= 30)) {
        errors.add('numInstallments', 'O pagamento com Carnê pode ter no máximo 30 parcelas!');
      }

      if (maturityDate == null && moment(maturityDate).isBefore(moment().hour(0).minute(0).second(0).millisecond(0)._d)) {
        errors.add('maturityDate', 'A data da primeira parcela do Carnê precisa ser válida!');
      }

      return errors.length === 0;
    },
    __validateMoneyCheck: function __validateMoneyCheck() {
      var check, checks, errors, maturityDate;
      errors = this.get('errors');
      maturityDate = this.get('maturityDate');

      if (maturityDate == null && moment(maturityDate).isBefore(moment().hour(0).minute(0).second(0).millisecond(0)._d)) {
        errors.add('maturityDate', 'A data do Cheque precisa ser válida!');
      }

      checks = this.get('check');
      check = checks.objectAt(0);

      if (checks.length < 1) {
        errors.add('checks', 'Não há cheques preenchidos!');
        return;
      }

      if (!(check.get('name') != null && check.get('name').trim() !== '')) {
        check.get('errors').add('name', 'O Nome do Cheque precisa ser preenchido!');
      }

      if (!(check.get('bankNum') != null && !isNaN(check.get('bankNum')) && check.get('bankNum') !== 0)) {
        check.get('errors').add('bankNum', 'O Número do Banco precisa ser preenchido!');
      }

      if (!(check.get('branchNum') != null && check.get('branchNum').trim() !== '')) {
        check.get('errors').add('branchNum', 'O número da agência do banco precisa ser preenchido!');
      }

      if (!(check.get('accountNum') != null && check.get('accountNum').trim() !== '')) {
        check.get('errors').add('accountNum', 'O número da conta do banco precisa ser preenchido!');
      }

      if (!(check.get('checkNum') != null && !isNaN(check.get('checkNum')) && check.get('checkNum') !== 0)) {
        check.get('errors').add('checkNum', 'O número do Cheque precisa ser preenchido!');
      }

      if (!(check.get('cnpjCpf') != null && check.get('cnpjCpf').trim() !== '')) {
        check.get('errors').add('cnpjCpf', 'O CPF ou CNPJ do proprietário do cheque precisa ser preenchido!');
      }

      checks.forEach(function (c) {
        if (!c.get('value') || c.get('value') === 0) {
          c.get('errors').add('value', 'O Valor do Cheque precisa ser preenchido!');
        }

        if (!c.get('date') || moment(c.get('date')).isBefore(moment().hour(0).minute(0).second(0).millisecond(0)._d)) {
          c.get('errors').add('date', 'A Data do Cheque precisa ser válida!');
        }

        return errors.addObjects(c.get('errors'));
      });
      return errors.length === 0;
    }
  });

  _exports.default = _default;
});