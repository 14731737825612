define("api-dashboard/mixins/components/bs-form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ComponentsBsFormAlertMixin;
  ComponentsBsFormAlertMixin = Ember.Mixin.create({
    mostrarMensagemAlerta: function mostrarMensagemAlerta(divAlerta, options, callbackOnAnimationComplete) {
      var classeCor, mensagem, tipoMensagem;
      mensagem = options["mensagem"];
      tipoMensagem = options["tipoMensagem"];
      classeCor = "alert-";

      switch (tipoMensagem) {
        case "erro":
          classeCor = classeCor + "danger";
          break;

        case "aviso":
          classeCor = classeCor + "warning";
          break;

        case "sucesso":
          classeCor = classeCor + "success";
      }

      return divAlerta.fadeOut("fast", function () {
        divAlerta.removeClass("alert-danger alert-warning alert-success");
        divAlerta.find(".form-alert-content").html(mensagem);
        divAlerta.addClass(classeCor);
        return divAlerta.fadeIn("fast", function () {
          return callbackOnAnimationComplete();
        });
      });
    },
    arrayMensagensToUlLiTag: function arrayMensagensToUlLiTag(arrayMensagens) {
      var stringFinal;
      stringFinal = "";
      arrayMensagens.forEach(function (iString) {
        return stringFinal = stringFinal + "<li>" + iString + "</li>";
      });
      return "<ul>" + stringFinal + "</ul>";
    },
    esconderMensagensEmExibicao: function esconderMensagensEmExibicao(divAlert, params, callbackOnAnimationComplete) {
      if (params == null) {
        params = {};
      }

      return divAlert.fadeOut("fast", function () {
        return callbackOnAnimationComplete();
      });
    }
  });
  var _default = ComponentsBsFormAlertMixin;
  _exports.default = _default;
});