define("api-dashboard/components/inputs/input-autocomplete", ["exports", "api-dashboard/components/base/base-component", "api-dashboard/mixins/components/autocomplete-advanced-search-config", "ember-data", "api-dashboard/config/environment", "ember-inflector"], function (_exports, _baseComponent, _autocompleteAdvancedSearchConfig, _emberData, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Campo do tipo 'autocomplete', no qual o usuário digita um número mínimo de caracteres, e o campo
    começa a buscar termos parecidos com o que o usuário digitou.
  
    Parâmetros:
        - classNames:
            Nome das classes CSS utilizadas nesse componente. Classes adicionadas no handlebars
            serão concatenadas às que já estão definidas aqui (e não substituídas).
        - classNameBindings:
            'binda' as classes descritas nessa propriedade a outras propriedades da classe, para que
            certos comportamentos possam ser melhor manipulados.
        - cols:
            Número de colunas que serão utilizadas no layout bootstrap.
        - constraints:
            null #string, comma-separated constraints (ex: 'transporte,alimentacao' )
        - currentAjax:
            null #variable to hold the current Ajax call
        - hasErrors:
            Define se há erros nesse campo.
        - label:
            O label a ser mostrado no layout.
        - layoutName:
            O layout utilizado para esse componente
        - loadingTemplate:
            O layout de 'loading' utilizado para esse componente
        - minLetters:
            Número mínimo de letras que o Usuário precisa digitar antes de começar a buscar
        - modelName:
            O nome do Model utilizado para buscar os termos digitados pelo Usuário.
        - newRecordTemplate:
            Template para novos registros.
        - nothingFound:
            Define se nada foi encontrado.
        - nothingFoundTemplate:
            O template para quando não há registros encontrados.
        - onDeselect:
            Action que será executada quando o Usuário limpar o campo.
        - onSelect:
            Action que será executada quando o Usuário selecionar um registro na lista de sugestões.
        - placeholder:
            Placeholder que aparecerá no layout
        - preSelectedIndex:
            -1 #holds the item selected by the arrows
        - refIndex:
            null
        - searchModalAction:
            null #if received as parameter, shows search button that calls this action
        - selectedItem:
            O item selecionado pelo Usuário na lista de sugestões.
        - selectedRecord:
            O record (ember data) referente ao item selecionado pelo Usuário na lista de sugestões.
        - showingLoading:
            Define se o gif de loading está sendo mostrado no momento.
        - showingMinLetters:
            Define se o tamanho mínimo de caracteres será mostrado ao Usuário.
        - showingSuggestions:
            Define se as sugestões estão sendo mostradas no momento.
        - showNewLink:
            define se o link de 'Novo' (model) será mostrado
        - suggestionField:
            Define o campo [do modelName] que será mostrado nas sugestões.
        - suggestions:
            Array com as sugestões que voltaram da busca pelo termo digitado pelo Usuário.
        - suggestionTemplate:
            Template para a lista de sugestões.
        - textValue:
            null
        - timeout:
            Tempo de espera em milisegundos para que as sugestões sejam buscadas.
        - to:
            null #variable to hold timeout information
        - asColumns:
            Colunas que irão aparecer para o Usuário quando executada uma busca refinada.
            Essa propriedade também define SE o botão "Pesquisar" irá aparecer.
            Essa propriedade deve ser passada como parâmetro por qualquer tela que a utilize, e deve
            ter a seguinte implementação:
            [ {label: 'Titulo da coluna', field : 'Propriedade do `modelName`'} ]
            !! Nota Importante: !! Esse parâmmetro difere do parâmetro `searchModalAction`, sendo um
            Modal (como um todo) mais genérico, e a funcionalidade do `searchModalAction` é um Modal
            mais específico (com relação a onde é utilizado).
   */
  var _default = _baseComponent.default.extend(_autocompleteAdvancedSearchConfig.default, {
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    classNameBindings: ['colsClassName'],
    classNames: ['input-autocomplete'],
    constraints: null,
    currentAjax: null,
    hasErrors: false,
    layoutName: 'components/inputs/input-autocomplete',
    loadingTemplate: 'partials/components/input-autocomplete/loading',
    minLetters: 3,
    modelName: 'empresa',
    newRecordTemplate: 'partials/components/input-autocomplete/new-record',
    nothingFound: false,
    nothingFoundTemplate: 'partials/components/input-autocomplete/nothing-found',
    onDeselect: null,
    onSelect: null,
    preSelectedIndex: -1,
    refIndex: null,
    searchModalAction: null,
    selectedItem: null,
    selectedRecord: null,
    showConfigMenu: false,
    showingLoading: false,
    showingMinLetters: true,
    showingSuggestions: false,
    showNewLink: false,
    suggestionField: 'nome_empresa',
    suggestions: Ember.ArrayProxy.create({
      content: Ember.A([])
    }),
    suggestionTemplate: 'partials/components/input-autocomplete/suggestion',
    timeout: 500,
    to: null,
    allowEdit: true,
    recordModal: null,
    __modalOpened: false,
    __asModal: null,
    __asTerm: null,
    __asSearching: false,
    asColumns: null,
    __asResults: [],
    __asOffset: 0,
    __asNextRecordsVisible: false,
    __asRecordLimit: 20,
    colsClassName: Ember.computed('cols', function () {
      return "col-sm-" + this.get('cols');
    }),
    textValue: Ember.computed('selectedRecord', {
      get: function get(key) {
        if (this.get('selectedRecord')) {
          return this.set('__textValue', this.get('selectedRecord').get(this.get('suggestionField')));
        }

        return this.get('__textValue');
      },
      set: function set(key, value) {
        this.set('__textValue', value);
        return this.get('__textValue');
      }
    }),
    placeholder: Ember.computed({
      get: function get(key) {
        this.set('__placeHolder', this.get('intl').t('globals.labels.start_typing_search'));
        return this.get('__placeHolder');
      },
      set: function set(key, value) {
        this.set('__placeHolder', value);
        return this.get('__placeHolder');
      }
    }),
    init: function init() {
      var self;
      self = this;

      if (this.get('selectedRecord')) {
        Ember.addObserver(this.get('selectedRecord'), this.get('suggestionField'), this.get('selectedRecord'), function () {
          return self.set('textValue', self.get('selectedRecord').get(self.get('suggestionField')));
        });
      }

      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super();

      return this.set('componentReference', this);
    },
    setFocus: function setFocus() {
      if (this.$('.autocomplete-input')) {
        return this.$('.autocomplete-input').focus();
      }
    },
    getSuggestionsArray: function getSuggestionsArray() {
      return this.get('suggestions').get('content');
    },
    showNothingFound: Ember.computed('nothingFound', 'showingLoading', 'showingMinLetters', function () {
      return this.get('nothingFound') && !this.get('showingLoading') && !this.get("showingMinLetters");
    }),
    lettersRemaining: Ember.computed('textValue', function () {
      var textLength;
      textLength = this.get('textValue') ? this.get('textValue').length : 0;
      return this.get('minLetters') - textLength;
    }),
    filled: Ember.computed('selectedRecord', 'selectedItem', function () {
      return this.get('selectedRecord.id') || this.get('selectedItem');
    }),
    selectedRecordObserver: Ember.observer('selectedRecord', function () {
      var record, self;
      self = this;
      record = self.get('selectedRecord');

      if (record) {
        if (record.then) {
          return record.then(function (r) {
            if (r) {
              return self.set('textValue', r.get(self.get('suggestionField').camelize()));
            } else {
              return self.clearSelected(true);
            }
          });
        } else if (record instanceof _emberData.default.Model) {
          return self.set('textValue', record.get(self.get('suggestionField').camelize()));
        }
      } else {
        return self.clearSelected(true);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      return this.prepareInitialRecord();
    },
    prepareInitialRecord: function prepareInitialRecord() {
      var id, record, self;
      self = this;
      record = this.get("initialRecord") || this.get('selectedRecord');

      if (!record) {
        return;
      }

      if (!record.get("isLoaded")) {
        id = record.get("id");

        if (!id) {
          return;
        }

        return this.get("store").findRecord(this.get("modelName"), record.get("id")).then(function (r) {
          return self.setSelectedRecord(r);
        }, function (e) {});
      } else {
        return this.setSelectedRecord(record);
      }
    },
    showLoading: function showLoading() {
      if (!this.get("showingLoading")) {
        this.set('showingLoading', true);
        return this.showSuggestions();
      }
    },
    clearSuggestions: function clearSuggestions() {
      this.get('suggestions').clear();
      return this.set('showingLoading', false);
    },
    showSuggestions: function showSuggestions() {
      return this.set('showingSuggestions', true);
    },
    hideSuggestions: function hideSuggestions() {
      this.clearSuggestions();
      return this.set('showingSuggestions', false);
    },
    valueObserver: Ember.observer('textValue', function () {
      if (!this.get('filled')) {
        Ember.run.cancel(this.get("to"));
        this.set("to", null);

        if (Ember.isEmpty(this.get('textValue'))) {
          return this.cancelCurrentAjax();
        } else {
          this.showSuggestions();

          if (this.get('textValue').length < this.get('minLetters')) {
            this.get('suggestions').clear();
            return this.set('showingMinLetters', true);
          } else {
            this.set('showingMinLetters', false);
            return this.set("to", Ember.run.later(this.get("searchSuggestions").bind(this), this.get('timeout')));
          }
        }
      }
    }),
    searchSuggestions: function searchSuggestions() {
      var adapter, ajaxUrl, authParam, domainId, s, searchTerm;
      s = this;

      if (!this.get('__modalOpened')) {
        this.cancelCurrentAjax();
        domainId = this.get("application.session.data.currentDomainId");

        if (this.get('router.currentRouteName') === 'selecao-domain') {
          authParam = "token=" + this.get('application.session.data.authenticated.token');
        } else {
          authParam = "domain_id=" + domainId;
        }

        ajaxUrl = '';
        searchTerm = s.get('textValue');

        if (['customer', 'emitente'].includes(s.get('modelName'))) {
          searchTerm = searchTerm.replace(/[\.|,|\-|\/]/gi, '');
        }

        if (s.get('modelName') === 'empresa') {
          ajaxUrl = "/panel_api/s";
        } else {
          ajaxUrl = "/s";
        }

        ajaxUrl += "?m=" + s.get('modelName') + "&q=" + searchTerm + "&" + authParam;

        if (this.get("constraints") != null) {
          ajaxUrl += '&c=' + this.get("constraints");
        }

        if (this.get("foreignKey") != null) {
          ajaxUrl += '&f=' + this.get("foreignKey");
        }

        adapter = this.get("store").adapterFor("application");
        ajaxUrl = adapter.get("host") + ajaxUrl;
        this.set("currentAjax", $.ajax({
          url: ajaxUrl,
          global: false,
          headers: adapter.get('headers'),
          beforeSend: function beforeSend(xhr) {
            return s.handleAjaxBeforeSend(xhr);
          },
          success: function success(data, textStatus, jqXHR) {
            return s.handleAjaxDone(data);
          },
          error: function error(jqXHR, textStatus, errorThrown) {}
        }));
        return this.set('__modalOpened', false);
      }
    },
    cancelCurrentAjax: function cancelCurrentAjax() {
      if (this.get("currentAjax") !== null) {
        this.get("currentAjax").abort();
        return this.set("currentAjax", null);
      }
    },
    handleAjaxBeforeSend: function handleAjaxBeforeSend(xhr) {
      return this.showLoading();
    },
    handleAjaxDone: function handleAjaxDone(data) {
      var key, payload, payloadKey;
      this.set("currentAjax", null);
      this.showSuggestions();
      this.set('preSelectedIndex', -1);
      key = _emberInflector.default.inflector.pluralize(this.get("modelName"));
      payloadKey = key;

      if (!data[key]) {
        key = 'suggestions';
      }

      if (data[key].length > 0) {
        payload = {};
        payload[payloadKey] = data[key];
        this.get("store").pushPayload(payload);
        this.handleSuggestions(data[key]);
      } else {
        this.handleNoSuggestions();
      }

      if (this.$('.autocomplete-input:focus').length === 0) {
        if (data[key].length === 1) {
          this.selectFirstSuggestion();
        } else {
          return this.$('.autocomplete-input').focus();
        }
      }
    },
    handleNoSuggestions: function handleNoSuggestions() {
      this.set('nothingFound', true);
      return this.clearSuggestions();
    },
    handleSuggestions: function handleSuggestions(recs) {
      var s;
      s = this;
      this.clearSuggestions();
      this.set('nothingFound', false);
      return $.each(recs, function (index, value) {
        var highlightedText, text;

        if (s.get('suggestionField') !== '') {
          text = value[s.get('suggestionField')];
          highlightedText = s.highlight(text, s.get('textValue'));
          value.suggestionDescription = highlightedText;
          value.ps = false;
        }

        return s.get('suggestions').pushObject(value);
      });
    },
    clearSelectedRunning: false,
    clearSelected: function clearSelected(silent) {
      if (this.get('clearSelectedRunning')) {
        return;
      }

      this.set('clearSelectedRunning', true);
      this.setSelectedRecord(null);
      this.set('selectedItem', null);
      this.set('textValue', '');

      if (silent) {
        this.set('showingSuggestions', false);
        this.set('showingMinLetters', false);
      } else {
        this.set('showingSuggestions', true);
        this.set('showingMinLetters', true);

        if (this.$('.autocomplete-input')) {
          this.$('.autocomplete-input').focus();
        }
      }

      return this.set('clearSelectedRunning', false);
    },
    onSelectedRecordChange: Ember.observer('selectedRecord', function () {
      var onDeselect, onSelect, selectedRecord;
      selectedRecord = this.get('selectedRecord');

      if (selectedRecord === null) {
        this.clearSelected();

        if (this.get('onDeselect')) {
          onDeselect = this.get('onDeselect');
          return onDeselect({
            record: null,
            refIndex: this.get("refIndex")
          });
        }
      } else {
        if (this.get('onSelect')) {
          onSelect = this.get('onSelect');
          return onSelect({
            record: selectedRecord,
            refIndex: this.get("refIndex")
          });
        }
      }
    }),
    focusIn: function focusIn(event, view) {
      if (!this.get('selectedRecord')) {
        this.set('showingSuggestions', true);
        return this.set('showingMinLetters', this.get('lettersRemaining') > 0);
      }
    },
    lostFocus: Ember.on('focusOut', function () {
      return Em.run.later(this, function () {
        var focussedElement, hideSuggestionsCallback, isFocussedOut;
        focussedElement = document.activeElement;
        isFocussedOut = this.$().has(focussedElement).length === 0 && !this.$().is(focussedElement);

        if (isFocussedOut) {
          hideSuggestionsCallback = function hideSuggestionsCallback() {
            var e;

            try {
              return this.set('showingSuggestions', false);
            } catch (error) {
              e = error;
            }
          };

          setTimeout(hideSuggestionsCallback.bind(this), 150);

          if (!this.get('selectedRecord')) {
            return this.selectPreSelected();
          }
        }
      }, 0);
    }),
    keyDown: function keyDown(event, view) {
      if (this.get('showingSuggestions')) {
        if (event.keyCode === 38) {
          this.selectUp();
        }

        if (event.keyCode === 40) {
          this.selectDown();
        }

        if (event.keyCode === 13) {
          this.selectPreSelected();
        }

        if (event.keyCode === 27) {
          this.set('textValue', '');
        }
      }

      if (this.get('selectedRecord') && this.get("selectedRecord").get("content")) {
        if (event.keyCode === 8) {
          this.clearSelected();
        }
      }

      return true;
    },
    selectPreSelected: function selectPreSelected() {
      var item, preSelectedIndex, s;
      preSelectedIndex = this.get('preSelectedIndex');

      if (preSelectedIndex !== -1) {
        s = this.getSuggestionsArray();
        item = s[preSelectedIndex];
        return this.setSelectedItem(item);
      } else if (this.get('suggestions.length') === 1) {
        return this.selectFirstSuggestion();
      }
    },
    selectFirstSuggestion: function selectFirstSuggestion() {
      var suggestions;
      suggestions = this.get('suggestions');
      return this.setSelectedItem(suggestions.objectAt(0), true);
    },
    clearPreSelection: function clearPreSelection() {
      var item, s;

      if (this.get('preSelectedIndex') >= 0) {
        s = this.getSuggestionsArray();
        item = s[this.get('preSelectedIndex')];
        return Ember.set(item, 'ps', false);
      }
    },
    preSelecionObserver: Ember.observer('preSelectedIndex', function () {
      var item, s;

      if (this.get('preSelectedIndex') >= 0) {
        s = this.getSuggestionsArray();
        item = s[this.get('preSelectedIndex')];
        return Ember.set(item, 'ps', true);
      }
    }),
    selectUp: function selectUp() {
      if (this.get('preSelectedIndex') > 0) {
        this.clearPreSelection();
        return this.set('preSelectedIndex', this.get('preSelectedIndex') - 1);
      }
    },
    selectDown: function selectDown() {
      if (this.get('preSelectedIndex') < this.get('suggestions').content.length - 1) {
        this.clearPreSelection();
        return this.set('preSelectedIndex', this.get('preSelectedIndex') + 1);
      }
    },
    selectedItemObserver: Ember.observer('selectedItem', function () {
      var rec, s, that;
      s = this.get('store');
      that = this;

      if (this.get('selectedItem') && this.get('selectedItem').id) {
        rec = s.peekRecord(this.get('modelName'), this.get('selectedItem').id);
        return that.setSelectedRecord(rec);
      } else {
        return that.setSelectedRecord(null);
      }
    }),
    setSelectedItem: function setSelectedItem(item, silent) {
      var old;
      old = this.get("selectedItem");

      if (this.checkRecordEquality(old, item)) {
        return;
      }

      this.set('selectedItem', item);
      this.set('textValue', item[this.get('suggestionField')] || "");

      if (!silent) {
        this.$('.autocomplete-input').focus();
      }

      return this.hideSuggestions();
    },
    highlight: function highlight(text, toHighlight) {
      var index, res, textUpper, toHighlightUpper;

      if (text) {
        textUpper = text.toUpperCase();
        toHighlightUpper = toHighlight.toUpperCase();
        index = textUpper.indexOf(toHighlightUpper);
        res = text;

        if (index >= 0) {
          res = text.substring(0, index) + "<span class='highlight'>" + text.substring(index, index + toHighlight.length) + "</span>" + text.substring(index + toHighlight.length);
        }

        return res;
      } else {
        return '';
      }
    },
    setSelectedRecord: function setSelectedRecord(newRecord) {
      var oldRecord;
      oldRecord = this.get("selectedRecord");

      if (!this.checkRecordEquality(oldRecord, newRecord)) {
        return this.set("selectedRecord", newRecord);
      }
    },
    checkRecordEquality: function checkRecordEquality(obj1, obj2) {
      var c;
      c = [];
      [obj1, obj2].forEach(function (reg, index) {
        var id, success;
        id = null;
        success = false;

        try {
          id = reg.get("id");
        } catch (error) {}

        if (!id) {
          try {
            id = reg["id"];
          } catch (error) {}
        }

        if (!id) {
          try {
            id = reg.id;
          } catch (error) {}
        }

        if (id) {
          if (!isNaN(id)) {
            id = parseInt(id);
          }

          return c.pushObject(id);
        } else {
          return c.pushObject(null);
        }
      });
      return c[0] === c[1];
    },
    editOptions: Ember.computed('selectedRecord.id', function () {
      var opts;
      opts = [];

      if (!!this.get('searchModalAction')) {
        opts.addObject({
          label: 'Buscar',
          act: 'openSearchModal'
        });
      }

      if (this.get('showConfigMenu')) {
        if (!this.get('searchModalAction') && this.get('asColumns.length') > 0) {
          opts.addObject({
            label: 'Pesquisar',
            act: '__asOpenModal'
          });
        }

        if (this.shouldShowNewRecordLink()) {
          opts.addObject({
            label: 'Novo',
            act: 'openEditModal'
          });
        }

        if (this.get('allowEdit')) {
          if (this.shouldShowEditRecordLink()) {
            opts.addObject({
              label: 'Editar',
              act: 'openEditModal'
            });
          }
        }
      }

      if (opts.length === 0) {
        return null;
      }

      return opts;
    }),
    getModalComponentPath: function getModalComponentPath() {
      var componentPath, modelName;
      modelName = this.get('modelName');
      componentPath = (0, _emberInflector.pluralize)(modelName) + "/" + modelName + "-form";

      if (Ember.getOwner(this).hasRegistration("component:" + componentPath)) {
        return componentPath;
      }

      return null;
    },
    shouldShowNewRecordLink: function shouldShowNewRecordLink() {
      if (this.get('selectedRecord.id')) {
        return false;
      }

      return !!this.getModalComponentPath();
    },
    shouldShowEditRecordLink: function shouldShowEditRecordLink() {
      if (!this.get('selectedRecord.id')) {
        return false;
      }

      return !!this.getModalComponentPath();
    },
    instantiateModalForm: function instantiateModalForm() {
      var componentPath, modelName, selectedRecordId, self;
      self = this;
      componentPath = this.getModalComponentPath();

      if (componentPath) {
        modelName = this.get('modelName');
        selectedRecordId = this.get('selectedRecord.id');

        if (selectedRecordId) {
          return this.get('store').findRecord(modelName, selectedRecordId, {
            reload: true
          }).then(function (obj) {
            self.set('viewName', componentPath);
            self.set('viewModel', obj);
            return self.set('showingLoadingModal', false);
          });
        } else {
          this.set('viewName', componentPath);
          this.set('viewModel', this.get('store').createRecord(modelName));
          return this.set('showingLoadingModal', false);
        }
      }
    },
    __asCloseModal: function __asCloseModal() {
      this.set('__asTerm', null);
      this.set('__asSearching', false);
      this.set('__asOffset', 0);
      this.set('__asNextRecordsVisible', false);
      this.set('__asResults', []);
      return this.get('__asModal').hide();
    },
    actions: {
      clearPreSelected: function clearPreSelected() {
        return this.clearPreSelection();
      },
      selectItem: function selectItem(item) {
        return this.setSelectedItem(item);
      },
      clearSelection: function clearSelection() {
        return this.clearSelected();
      },
      openSearchModal: function openSearchModal(evt) {
        this.get('searchModalAction').call(this);
        return this.get('recordModal').hide();
      },
      openEditModal: function openEditModal(evt) {
        this.set('showingLoadingModal', true);
        this.instantiateModalForm();
        this.get('recordModal').show();
        return this.set('__modalOpened', true);
      },
      onCancelModel: function onCancelModel() {
        this.set('viewName', null);
        this.set('__modalOpened', false);
        return this.get('recordModal').hide();
      },
      onSubmitModel: function onSubmitModel(savedModel) {
        var onSelect;
        this.set('selectedRecord', savedModel);

        if (this.get('onSelect')) {
          onSelect = this.get('onSelect');
          onSelect({
            record: savedModel,
            refIndex: this.get("refIndex")
          });
        }

        this.set('viewName', null);
        this.set('__modalOpened', false);
        return this.get('recordModal').hide();
      },
      __asOpenModal: function __asOpenModal() {
        return this.get('__asModal').show();
      },
      __asCloseModal: function __asCloseModal() {
        return this.__asCloseModal();
      },
      __asDoSearch: function __asDoSearch(append) {
        var self;

        if (!this.get('asColumns')) {
          return;
        }

        self = this;

        if (!append) {
          self.set('__asOffset', 0);
        }

        self.set('__asSearching', true);
        return self.get('store').query(self.get('modelName'), {
          search: self.get('__asTerm'),
          active: true,
          limit: self.get('__asRecordLimit'),
          offset: self.get('__asOffset')
        }).then(function (objs) {
          self.set('__asNextRecordsVisible', objs.get('length') === self.get('__asRecordLimit'));

          if (!append) {
            self.set('__asResults', []);
          }

          self.get('__asResults').addObjects(objs);
          return self.set('__asSearching', false);
        });
      },
      __asChoose: function __asChoose(selectedRecord) {
        this.set('selectedRecord', selectedRecord);
        return this.send('__asCloseModal');
      },
      __asLoadMoreRecords: function __asLoadMoreRecords() {
        this.set('__asOffset', this.get('__asOffset') + this.get('__asRecordLimit'));
        return this.send('__asDoSearch', true);
      },
      __asKeyDown: function __asKeyDown(event) {
        var searchTerm;

        if (event.keyCode === 13) {
          searchTerm = this.$("#" + this.get('elementId') + "__asTerm input").val();
          this.set('__asTerm', searchTerm);
          return this.send('__asDoSearch', false);
        }
      }
    }
  });

  _exports.default = _default;
});