define("api-dashboard/components/system-config/system-config-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    store: Ember.inject.service(),
    constants: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    changed_configs: {},
    config_tree: null,
    config_tree_save: [],
    tree_edit_elements: [],
    searching_tree: false,
    bt_save_classes: 'fa fa-save',
    bt_save_disabled: '',
    _breadcrumbs: [],
    concernedObjectId: null,
    concernedObjectClass: null,
    model: null,
    breadcrumbs: Ember.computed('_breadcrumbs', function () {
      var separator;

      if (this.get('_breadcrumbs.length') > 1) {
        separator = '  >  ';
        this.get('_breadcrumbs').pop();
        return this.get('_breadcrumbs').join(separator).concat(separator);
      }
    }),
    init: function init() {
      if (this.get('model')) {
        this.set('concernedObjectId', this.get('model.id'));
        this.set('concernedObjectClass', this.get('model.constructor.modelName'));
      }

      this.get_configs();
      return this._super(arguments);
    },
    get_configs: function get_configs() {
      var params, self;
      this.set('searching_tree', true);
      self = this;
      params = {};

      if (this.get('model')) {
        params = {
          concerned_object_id: this.get('concernedObjectId'),
          concerned_object_class: this.get('concernedObjectClass')
        };
      }

      return this.get('store').query('config_definition', params).then(function (obj) {
        var config_tree;
        config_tree = obj.filter(function (i) {
          return i !== null && (i.parent === null || i.parent.content === null);
        });
        self.set('config_tree', config_tree);
        return self.set('searching_tree', false);
      });
    },
    on_row_click: function on_row_click(e, data) {
      this.set('searching_edit', true);
      this.build_edit_area(data, e);
      return this.set('searching_edit', false);
    },
    build_edit_area: function build_edit_area(data) {
      this.set('tree_edit_elements', []);
      this.set('_breadcrumbs', []);

      this._build_breadcrumbs(data);

      return this._build_children_edit(data);
    },
    _build_breadcrumbs: function _build_breadcrumbs(data) {
      if (data.get('parent')) {
        this._build_breadcrumbs(data.get('parent'));
      }

      if (data.get('isVariantOrNode')) {
        return this.get('_breadcrumbs').push("<strong>" + data.get('title') + "</strong>");
      }
    },
    _build_children_edit: function _build_children_edit(data) {
      var self, tree_element;
      self = this;
      tree_element = data;

      if (tree_element.get('isVariantOrNode')) {
        Ember.set(tree_element, 'component', "<h3>" + tree_element.get('title') + "</h3>");
      } else {
        self.get_edit_element_component(tree_element);
      }

      this.get('tree_edit_elements').push(tree_element);

      if (tree_element.get('children')) {
        return tree_element.get('children').forEach(function (i) {
          if (i.get('children.length') < 1) {
            return self._build_children_edit(i);
          }
        });
      }
    },
    get_edit_element_component: function get_edit_element_component(element) {
      var component, dataSource, modelName, self, suggestionField;
      self = this;

      if (element.get('configType')) {
        switch (element.get('configType')) {
          case 'BOOLEAN':
            Ember.set(element, 'isBoolean', true);
            break;

          case 'INTEGER':
          case 'FLOAT':
          case 'MINUTES':
          case 'HOURS':
            component = 'inputs/input-number';
            break;

          case 'PERCENTAGE':
            component = 'inputs/input-percentage';
            break;

          case 'DATE':
            component = 'inputs/input-date';
            break;

          case 'LONG_STRING':
            component = 'textarea';
            Ember.set(element, 'isTextArea', true);
            break;

          case 'AUTOCOMPLETE_CITY':
            component = 'inputs/input-autocomplete';
            modelName = 'municipio';
            suggestionField = 'nomeAndUf';
            Ember.set(element, 'isAutocomplete', true);
            break;

          case 'AUTOCOMPLETE_STORE':
            component = 'inputs/input-autocomplete';
            modelName = "f_store";
            suggestionField = "nameWithDomain";
            Ember.set(element, 'isAutocomplete', true);
            break;

          case 'AUTOCOMPLETE_COLLECTION':
            component = 'inputs/input-autocomplete';
            modelName = 'collection';
            suggestionField = 'name';
            Ember.set(element, 'isAutocomplete', true);
            break;

          case 'AUTOCOMPLETE_SALESPEOPLE':
            component = 'inputs/input-select';
            modelName = 'posUser';
            suggestionField = 'name';
            Ember.set(element, 'isAutocomplete', true);
            break;

          case 'AUTOCOMPLETE_COST_CENTER':
            component = 'inputs/input-select';
            modelName = 'centroCusto';
            suggestionField = 'descricao';
            Ember.set(element, 'isAutocomplete', true);
            break;

          case 'LOOKUP_ROLES':
            component = 'inputs/input-select';
            dataSource = this.get('constants.accessPermissions');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_ENTITIES':
            component = 'inputs/input-select';
            dataSource = this.get('constants.institutions');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_PRODUCT_ORIGIN':
            component = 'inputs/input-select';
            dataSource = this.get('constants.icmsOrigemValues');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_LIST_PERFIL_PAF':
            component = 'inputs/input-select';
            dataSource = this.get('constants.pafPerfils');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_ORIGIN_ACCOUNT_GROUP':
            component = 'inputs/input-select';
            dataSource = this.get('constants.accountOrigins');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_CASHIER_INPUT_METHOD':
            component = 'inputs/input-select';
            dataSource = this.get('constants.pdvCodeInputMethods');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_CREDIT_METHOD_PURCHASE':
            component = 'inputs/input-select';
            dataSource = this.get('constants.customerRewardsModes');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_ACCOUNT_TYPE_INDICATOR':
            component = 'inputs/input-select';
            dataSource = this.get('constants.accountTypeIndicators');
            Ember.set(element, 'isLookup', true);
            break;

          case 'LOOKUP_SAT_MODELS':
            component = 'inputs/input-select';
            dataSource = this.get('constants.satModels');
            Ember.set(element, 'isLookup', true);
            break;

          default:
            component = 'inputs/base-text-field';
        }
      } else {
        component = 'inputs/base-text-field';
      }

      Ember.set(element, 'component', component);
      Ember.set(element, 'dataSource', dataSource);
      Ember.set(element, 'modelName', modelName);
      return Ember.set(element, 'suggestionField', suggestionField);
    },
    onChangeTreeElement: function onChangeTreeElement(treeElement, event) {
      var value;
      value = treeElement.get('value');
      value || (value = treeElement.get('valueObject'));

      if (event && event.record) {
        value || (value = event.record.get('id'));
      }

      if (event && event.target && event.target.value !== value) {
        value = event.target.value;
      }

      return this.get('changed_configs')[treeElement.get('code')] = value;
    },
    actions: {
      onSubmit: function onSubmit() {
        var ajaxParams, self;
        this.set('bt_save_classes', 'fa fa-refresh fa-spin');
        this.set('bt_save_disabled', 'disabled');
        self = this;
        ajaxParams = {
          method: 'POST',
          data: {
            configs: this.get('changed_configs'),
            concerned_object_id: this.get('concernedObjectId'),
            concerned_object_class: this.get('concernedObjectClass')
          },
          global: false
        };
        return this.get('store').externalAjax(ajaxParams, {
          apiURL: '/config_definitions/save'
        }, function (success, textStatus, data) {
          var message, toastType;

          if (success) {
            toastType = 'success';
            message = 'Configurações salvas com sucesso';
            self.set('changed_configs', {});
          } else {
            toastType = 'error';
            message = data.jqXHR.responseJSON.msg;
          }

          self.get('application').showAlert(message, toastType);
          self.set('bt_save_classes', 'fa fa-save');
          return self.set('bt_save_disabled', false);
        });
      },
      onCancel: function onCancel() {
        return this.sendAction('cancelAction');
      }
    }
  });

  _exports.default = _default;
});