define("api-dashboard/templates/components/base/base-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wyYtDxhy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-xs-12 text-right\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"clear-10\"],[8],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn--default btn--primary\"],[3,\"action\",[[23,0,[]],\"onClick\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/base/base-button.hbs"
    }
  });

  _exports.default = _default;
});