define("api-dashboard/templates/components/totais-nf-cnpj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwEy7B3M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-sm-4 col-lg-4 text-center nfs-emitidas\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"contador-dinamico\",null,[[\"numeroFinal\"],[[24,[\"totaisNotasEmitidas\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Notas Emitidas \"],[7,\"br\",true],[8],[9],[7,\"small\",true],[8],[0,\"nos últimos 30 dias\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--default btn--info\",\"busca-referencia\"]],{\"statements\":[[0,\" Visualizar emissões \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-sm-4  col-lg-4 text-center nfs-canceladas\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"contador-dinamico\",null,[[\"numeroFinal\"],[[24,[\"totaisNotasCanceladas\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Notas Canceladas \"],[7,\"br\",true],[8],[9],[7,\"small\",true],[8],[0,\"nos últimos 30 dias\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xs-12 col-sm-4  col-lg-4 text-center cnpj\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"contador-dinamico\",null,[[\"numeroFinal\"],[[24,[\"totaisCnpjAtivos\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      CNPJs Ativos \"],[7,\"br\",true],[8],[9],[0,\" \\n    \"],[9],[0,\"\\n    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn--default btn--info\",\"empresas/index\"]],{\"statements\":[[0,\"Visualizar empresas\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "api-dashboard/templates/components/totais-nf-cnpj.hbs"
    }
  });

  _exports.default = _default;
});