define("api-dashboard/models/f-store", ["exports", "ember-data", "api-dashboard/mixins/formatters", "ember-data-copyable"], function (_exports, _emberData, _formatters, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, _formatters.default, {
    name: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    siglaUf: _emberData.default.attr('string', {
      serialize: false
    }),
    activePoss: _emberData.default.attr('number', {
      serialize: false
    }),
    globalOrder: _emberData.default.attr('number'),
    optanteSimples: _emberData.default.attr('boolean', {
      serialize: false
    }),
    nameWithDomain: _emberData.default.attr('string', {
      serialize: false
    }),
    cnpj: _emberData.default.attr('string'),
    domain: _emberData.default.belongsTo('domain', {
      inverse: 'allStores'
    }),
    pos: _emberData.default.hasMany('pos'),
    contract: _emberData.default.belongsTo('contract', {
      inverse: 'stores'
    }),
    multipleActivePoss: Ember.computed('activePoss', function () {
      return this.get('activePoss') > 1;
    }),
    abbrName: Ember.computed('name', 'shortName', function () {
      var abbreviatedName, i, name, shortName, spl;
      shortName = this.get('shortName');
      name = this.get('name');
      abbreviatedName = shortName ? shortName : name;

      if (abbreviatedName.length > 4) {
        spl = abbreviatedName.trim().split(" ");
        i = 0;
        abbreviatedName = '';

        while (i < spl.length) {
          if (i === spl.length - 1) {
            abbreviatedName += spl[i].substr(0, 3);
          } else {
            abbreviatedName += spl[i][0] + '.';
          }

          i++;
        }
      }

      return abbreviatedName;
    }),
    nameAndCnpj: Ember.computed('name', 'cnpj', function () {
      return this.get('name') + " - " + this.formatCnpj(this.get('cnpj'));
    })
  });

  _exports.default = _default;
});