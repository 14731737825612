define("api-dashboard/components/notas-fiscais/nota-fiscal-payments-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    application: Ember.inject.service(),
    globalLookups: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    constants: Ember.inject.service(),
    posEntry: null,
    cardCompanies: null,
    cardBrands: null,
    editing: false,
    init: function init() {
      this.getCardCompanies();
      this.getCardBrands();
      this.createNewPosEntry();
      return this._super.apply(this, arguments);
    },
    getCardCompanies: function getCardCompanies() {
      var self;
      self = this;
      return this.get('store').findAll('card-company').then(function (objs) {
        var cardCompanies;
        cardCompanies = [];
        objs.map(function (o) {
          return cardCompanies.addObject({
            value: o,
            description: o.name
          });
        });
        return self.set('cardCompanies', cardCompanies);
      });
    },
    getCardBrands: function getCardBrands() {
      var self;
      self = this;
      return this.get('store').findAll('card-brand').then(function (objs) {
        var cardBrands;
        cardBrands = [];
        objs.map(function (o) {
          return cardBrands.addObject({
            value: o,
            description: o.name
          });
        });
        return self.set('cardBrands', cardBrands);
      });
    },
    paymentTypes: Ember.computed(function () {
      var k, paymentTypes, ref, v;
      paymentTypes = [];
      ref = this.get('constants.nfePaymentTypes');

      for (k in ref) {
        v = ref[k];
        paymentTypes.addObject(v);
      }

      return paymentTypes;
    }),
    createNewPosEntry: function createNewPosEntry() {
      var posEntry;
      posEntry = this.get('store').createRecord('pos-entry');
      return this.set('posEntry', posEntry);
    },
    actions: {
      addPayment: function addPayment() {
        var posEntry;
        posEntry = this.get('posEntry');
        posEntry.set('date', this.get('model.dataEmissao'));

        if (posEntry.get('entryType') === 'CartãoCredito') {
          posEntry.set('entryType', 'Cartão');
          posEntry.set('cardType', 'C');
        } else if (posEntry.get('entryType') === 'CartãoDebito') {
          posEntry.set('entryType', 'Cartão');
          posEntry.set('cardType', 'D');
        }

        this.get('model.posEntries').pushObject(posEntry);
        posEntry.save();
        this.createNewPosEntry();
        return this.set('editing', false);
      },
      editPayment: function editPayment(posEntry) {
        if (posEntry.get('entryType') === 'Cartão') {
          if (posEntry.get('cardType') === 'C') {
            posEntry.set('entryType', 'CartãoCredito');
          } else if (posEntry.get('cardType') === 'D') {
            posEntry.set('entryType', 'CartãoDebito');
          }
        }

        this.set('posEntry', posEntry);
        return this.set('editing', true);
      },
      removePayment: function removePayment(paymentMethod) {
        if (this.get('model.posEntries.length') > 1) {
          this.get('model.posEntries').removeObject(paymentMethod);
          return paymentMethod.destroyRecord();
        }
      },
      addMoneyCheck: function addMoneyCheck(paymentMethod) {
        var moneyCheck;
        moneyCheck = this.get('store').createRecord('check');
        moneyCheck.set('posEntry', paymentMethod);
        return paymentMethod.get('check').addObject(moneyCheck);
      }
    }
  });

  _exports.default = _default;
});